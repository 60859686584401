import React from 'react';
import { Version } from 'consts';

const Footer = (props) => (
    <>
        <footer className="sticky-footer">
            <div className="container d-flex align-items-center justify-content-center">
                <div className="copyright">
                    <span>Copyright &copy; 2024 Livefire v{Version}</span>
                </div>
            </div>
        </footer>
    </>
);

export default Footer;

import { Components } from 'consts';
import { getScripts } from 'services/automation';
import { CleanupInfo } from '../components/CleanupInfo';

export const configCleanup = {
    description: (
        <>
            Cleanup all previously removed environments / game version from configs configs
            <br />
            Only environments / game versions listed in settings will remain in configs.
        </>
    ),
    color: '#4bab4b',
    icon: 'fas fa-hand-sparkles',
    options: [
        {
            id: 'components',
            name: 'Component',
            type: 'select_button',
            placeholder: 'Select component',
            size: 12,
            value: 'all',
            options: [],
            isMulti: true,
            asyncLoad: async (gameId) => {
                const scripts = await getScripts(gameId);
                const script = scripts.find((_script) => _script.id === 'configCleanup');

                const scriptComponents = script ? script.components : [];

                let componentOptions = scriptComponents.reduce((prev, val) => {
                    const component = Components.find((_component) => _component.id === val);

                    prev.push({ value: val, label: component.name });

                    return prev;
                }, []);

                return componentOptions;
            }
        },
        {
            id: 'cleanup_info',
            render: (props) => <CleanupInfo {...props} />
        }
    ],
    // customComponent: CleanupInfo,
    name: 'Cleanup Environmental Config',
    validate: function (values) {
        if (!values.components || values.components.length === 0)
            throw new Error('Component should be specified');

        return true;
    }
};

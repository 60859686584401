import { SettingsNavigation } from './Navigation';
import { ComponentHeaderSimple, useGame } from 'components';

export const OpenAPISettings = () => {
    const game = useGame();

    const gameId = game.id;
    const loaded = !!gameId;

    if (!loaded) return null;

    return (
        <>
            <SettingsNavigation gameId={gameId} tab="open-api" />
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-8">
                    <h6 className="fw-bold text-muted mb-3">Keys</h6>
                    <div className="card shadow">
                        <div className="card-body">
                            <div>
                                <span className="fw-bold">API Key</span> : {game.api_key}
                            </div>
                            <div className="mt-2">
                                <span className="fw-bold">API Secret</span> : {game.api_secret}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

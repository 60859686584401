/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { CollectionModel } from './CollectionModel';
import { getStorageFileVersions } from 'services/storage';
import { Alert, TooltipWrapper } from 'components';
import { Modal, Spinner } from '@gpg-web/react';
import { utils } from '@gpg-web/utils';

function noop() {}

const BrowseStorage = ({
    gameId,
    initialPath,
    foldersOnly,
    requiredFileVersion,
    isFolderSelect,
    onSelect,
    onCancel,
    simplified,
    modal,
    asStringPath,
    accept
}) => {
    const [error, setError] = useState(null);
    const [selectedObject, setSelectedObject] = useState(null);
    const [selectedFileVersions, setSelectedFileVersions] = useState(null);
    const [selectedFileVersion, setSelectedFileVersion] = useState('');
    const [storage, setStorage] = useState(null);
    let [path, setPath] = useState(initialPath || '');

    if (path) path = path + '/';

    const clickObject = (e) => {
        if (e.mime === 'Folder') {
            if (!foldersOnly && !isFolderSelect) {
                setPath(e.path + e.name);
            } else {
                let _path = e.path + e.name;
                if (_path) _path = _path + '/';

                setSelectedObject(_path);
            }
        } else {
            if (!foldersOnly) {
                setSelectedObject(e.path + e.name);
            }
        }
    };

    useEffect(() => {
        if (foldersOnly) {
            setSelectedObject(null);
        }
    }, [path, foldersOnly]);

    useEffect(() => {
        if (!selectedObject || requiredFileVersion === false || selectedObject.endsWith('/')) return;

        setSelectedFileVersions(null);
        setSelectedFileVersion('');
        setError(null);

        getStorageFileVersions(gameId, selectedObject)
            .then((versions) => {
                if (!versions.length) return;
                setSelectedFileVersions(versions);
                setSelectedFileVersion(versions[0].id);
            })
            .catch(setError);
    }, [gameId, selectedObject, requiredFileVersion, isFolderSelect]);

    const onOpen = (object) => {
        if (requiredFileVersion && !selectedFileVersion) return setError('Please, select file version');

        object = object || selectedObject;

        if (onSelect) {
            if (asStringPath) {
                if (selectedFileVersion) {
                    object = utils.generateStoragePath(object, selectedFileVersion);
                }
                onSelect(object);
            } else {
                onSelect({
                    path: object,
                    version: selectedFileVersion
                });
            }
        }

        onCancel && onCancel();
    };

    const dbClickObject = (e) => {
        if (e.mime === 'Folder') {
            setPath(e.path + e.name);
        } else {
            //onOpen(e.path + e.name);
        }
    };

    const pathesParts = path.split('/').slice(0, -1);

    return (
        <>
            <div className="card">
                <div className="card-header modal-header text-primary fw-bold px-3 py-2">
                    Browse Storage
                    {modal && (
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    )}
                </div>
                <div className="card-body">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb small">
                            <li className="breadcrumb-item active">
                                {path !== '' ? (
                                    <span role="button" className="btn-link" onClick={(e) => setPath('')}>
                                        Home
                                    </span>
                                ) : (
                                    'Home'
                                )}
                            </li>
                            {pathesParts.map((e, index) => (
                                <li key={e} className="breadcrumb-item active">
                                    {index === pathesParts.length - 1 ? (
                                        e
                                    ) : (
                                        <span
                                            role="button"
                                            className="btn-link"
                                            onClick={(e) =>
                                                setPath(pathesParts.slice(0, index + 1).join('/'))
                                            }
                                        >
                                            {e}
                                        </span>
                                    )}
                                </li>
                            ))}
                        </ol>
                    </nav>

                    <CollectionModel
                        smallTable
                        simplified={simplified}
                        filter={accept}
                        foldersOnly={foldersOnly}
                        storage={
                            storage && [
                                {
                                    virtual: true,
                                    mime: 'Folder',
                                    directory: true,
                                    displayName: '__root__',
                                    name: '',
                                    path: '',
                                    lastVersionId: '-',
                                    id: ''
                                },
                                ...storage
                            ]
                        }
                        setStorage={setStorage}
                        path={path}
                        gameId={gameId}
                        onDoubleClick={dbClickObject}
                        onClick={clickObject}
                    />
                </div>

                <div className="card-footer">
                    {error && <Alert text={error} />}
                    <div className="d-flex align-items-center" title="Select file & version">
                        {selectedObject !== null && (
                            <TooltipWrapper icon={false} content={selectedObject}>
                                <span
                                    className="small text-primary text-break text-truncate"
                                    style={{ maxWidth: '400px' }}
                                >
                                    {selectedObject || '/'}
                                </span>
                            </TooltipWrapper>
                        )}

                        <span className="ms-3">
                            {selectedFileVersions !== null && (
                                <select
                                    onChange={(e) => setSelectedFileVersion(e.target.value)}
                                    value={selectedFileVersion}
                                    className="form-select form-select-sm"
                                >
                                    <option value="" disabled>
                                        Select Version
                                    </option>
                                    {selectedFileVersions.map((e) => (
                                        <option key={e.id} value={e.id}>
                                            #{e.id} - {e.message}
                                        </option>
                                    ))}
                                </select>
                            )}

                            {selectedObject !== null &&
                                !foldersOnly &&
                                selectedFileVersions === null &&
                                requiredFileVersion !== false && (
                                    <span>
                                        <Spinner /> &nbsp;Loading versions
                                    </span>
                                )}
                        </span>
                        <button
                            onClick={onCancel || noop}
                            data-bs-dismiss={modal ? 'modal' : ''}
                            className="btn btn-secondary ms-auto"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={(e) => onOpen()}
                            data-bs-dismiss={modal ? 'modal' : ''}
                            disabled={
                                selectedObject === null || (requiredFileVersion && selectedFileVersion === '')
                            }
                            className="btn btn-outline-primary ms-2"
                        >
                            {!foldersOnly && isFolderSelect ? 'Select' : 'Open'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const BrowseStorageModal = (props) => {
    return (
        <Modal
            size="xl"
            header={null}
            position="top"
            show={props.show}
            onHide={props.onHide}
            onBeforeClose={props.onBeforeClose}
        >
            {props.show && (
                <BrowseStorage {...props} modal>
                    {props.children}
                </BrowseStorage>
            )}
        </Modal>
    );
};

export { BrowseStorage, BrowseStorageModal };

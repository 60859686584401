const Components = [
    {
        id: 'gameBalance',
        version: '2',
        name: 'Game Balance',
        url: 'game-balances',
        fileMime: 'application/json',
        contentType: 'json',
        fileExtension: '.json'
    },
    {
        id: 'store',
        version: '2',
        name: 'Store',
        url: 'stores',
        fileMime: 'application/json',
        contentType: 'livefire.store',
        fileExtension: '.json'
    },
    {
        id: 'tle',
        version: '2',
        name: 'Time Limited Event',
        url: 'time-limited-events',
        fileMime: 'application/json',
        contentType: 'livefire.tle',
        fileExtension: '.json',
        triggers: ['time', 'conditions']
    },
    {
        id: 'tlo',
        version: '2',
        name: 'Time Limited Offer',
        url: 'time-limited-offers',
        fileMime: 'application/json',
        contentType: 'livefire.tlo',
        fileExtension: '.json',
        triggers: ['time', 'conditions']
    },
    {
        id: 'popup',
        version: '2',
        name: 'Popup',
        url: 'popups',
        fileMime: 'application/json',
        contentType: 'livefire.popup',
        fileExtension: '.json',
        triggers: ['conditions']
    },
    {
        id: 'patch',
        version: '2',
        name: 'Patch Config',
        url: 'patches',
        fileMime: 'application/json',
        contentType: 'livefire.patch',
        fileExtension: '.json'
    },
    {
        id: 'dailyReward',
        version: '2',
        name: 'Daily Reward',
        url: 'daily-rewards',
        fileMime: 'application/json',
        contentType: 'livefire.daily-reward',
        fileExtension: '.json'
    },
    {
        id: 'piggyBank',
        version: '2',
        name: 'Piggy Bank',
        url: 'piggy-banks',
        fileMime: 'application/json',
        contentType: 'livefire.piggy-bank',
        fileExtension: '.json'
    },
    {
        id: 'translation',
        version: '2',
        name: 'Translation',
        url: 'translations',
        fileMime: 'text/plain',
        contentType: 'livefire.translation',
        fileExtension: '.txt'
    },
    {
        id: 'storage',
        version: '1',
        name: 'Storage',
        url: 'storage'
    },
    {
        id: 'experiment',
        version: '2',
        name: 'Experiment',
        url: 'experiments'
    },
    {
        id: 'entity',
        version: '2',
        name: 'Entity',
        url: 'entities'
    },
    {
        id: 'product',
        version: '2',
        name: 'Product',
        url: 'products'
    }
    // storage: 'Storage',
    // store: 'Store',
    // storeBundle: 'Store Bundles',
    // consumable: 'Store Consumables',
    // nonConsumable: 'Store Non-Consumables',
    // experiment: 'A/B Testing',
    // translations: 'Localization',
    // playerSave: 'Save Types',
    // popup: 'Popup',
    // tle: 'TLE',
    // dailyReward: 'Daily Reward',
    // piggyBank: 'Piggy Bank'
];

const ComponentsMap = Components.reduce((prev, val) => {
    prev[val.id] = val;
    return prev;
}, {});

export { Components, ComponentsMap };

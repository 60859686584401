/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { searchAll, loadNext } from 'services/search';
import { organizationContext } from 'services/organization';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import Types from './Types';
import { ComponentsMap } from 'consts';

export const SearchPage = (props) => {
    const [searchParams] = useSearchParams();
    const [result, setResult] = useState(null);
    const [nextToken, setNextToken] = useState(null);
    const [tryAgain, setTrayAgain] = useState(0);
    const [totalCount, setTotalCount] = useState({});
    const [loadingMore, setLoadingMore] = useState(false);

    const history = useNavigate();
    const query = searchParams.get('q');

    useEffect(() => {
        if (!query || query.length < 2) return;

        setResult(null);

        // setResult([
        //     {
        //         name: 'dev/file.json',
        //         type: 'Project',
        //         category: 'res',
        //         projectId: 'livefire-333617',
        //         game: '1VQk67UHrwXG6FIDZ10H',
        //         path: '/storage/collection/file/test'
        //     }
        // ]);

        searchAll({ query })
            .then((res) => {
                if (res.fail) {
                    setTimeout(() => {
                        setTrayAgain((e) => e + 1);
                    }, 5000);
                } else {
                    if (res.list) setResult(res.list);
                    setTotalCount({
                        total: res.total,
                        docs: res.docs,
                        res: res.res
                    });
                    setNextToken(res.nextToken);
                }
            })
            .catch(utils.hintError);
    }, [query, tryAgain]);

    function _loadNext() {
        setLoadingMore(true);
        loadNext(nextToken)
            .then((res) => {
                if (res.fail) return history('/search?q=' + query);

                if (res.list) setResult(result.concat(res.list));
                setNextToken(res.nextToken);
                setLoadingMore(false);
            })
            .catch(utils.hintError);
    }

    let docsResult, resourceResult;

    if (result) {
        docsResult = result.filter((e) => e.type === 'Documentation');
        resourceResult = result.filter((e) => e.type !== 'Documentation');
    }

    return (
        <>
            <div className="container-lg">
                <ul className="nav nav-tabs mb-3">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link active"
                            id="search-result-all-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#search-result-all-tab-pane"
                            type="button"
                        >
                            ALL
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="search-result-docs-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#search-result-docs-tab-pane"
                            type="button"
                        >
                            DOCUMENTATION & TUTORIALS
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="search-result-resources-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#search-result-resources-tab-pane"
                            type="button"
                        >
                            RESOURCES
                        </button>
                    </li>
                </ul>
                <div>
                    <h4>Search results</h4>
                </div>

                <div className="tab-content col-sm-12 col-md-8">
                    <div className="tab-pane fade show active" id="search-result-all-tab-pane">
                        {result && (
                            <>
                                <div className="text-muted">
                                    Showing {result.length} of {totalCount.total} results for "{query}".
                                </div>
                                <div className="mt-3">
                                    {result.map((e, index) => (
                                        <SearchItem key={e.name + index} value={e} />
                                    ))}
                                </div>
                            </>
                        )}

                        <div className="my-2">
                            {nextToken && !loadingMore && (
                                <button onClick={_loadNext} className="btn btn-primary btn-sm">
                                    LOAD MORE RESULTS
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="search-result-docs-tab-pane">
                        {docsResult && (
                            <>
                                <div className="text-muted">
                                    Showing {docsResult.length} of {totalCount.docs} results for "{query}".
                                </div>
                                <div className="mt-3">
                                    {docsResult.map((e, index) => (
                                        <SearchItem key={e.name + index} value={e} />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>

                    <div className="tab-pane fade" id="search-result-resources-tab-pane">
                        {resourceResult && (
                            <>
                                <div className="text-muted">
                                    Showing {resourceResult.length} of {totalCount.res} results for "{query}".
                                </div>
                                <div className="mt-3">
                                    {resourceResult.map((e, index) => (
                                        <SearchItem key={e.name + index} value={e} />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {(!result || loadingMore) && (
                    <div className="mt-4">
                        <Skeleton className="m-2" width="100%" height={60} />
                        <Skeleton className="m-2" width="100%" height={60} />
                        <Skeleton className="m-2" width="100%" height={60} />
                        <Skeleton className="m-2" width="100%" height={60} />
                        <Skeleton className="m-2" width="100%" height={60} />
                    </div>
                )}
            </div>
        </>
    );
};

const SearchItem = (props) => {
    const value = props.value;

    const { games, gameGroups } = useContext(organizationContext);

    let foundGame = null;
    let foundGameName = '';
    if (games && gameGroups && value.game) {
        foundGame = games.find((e) => e.id === value.game);
        if (foundGame) {
            foundGameName =
                (gameGroups.find((e) => e.id === foundGame.group)?.name || 'Unknown') +
                ' - ' +
                foundGame.name;
        }
    }

    return (
        <div className="d-flex mb-4 border-bottom pb-2">
            <div className="me-3 mt-1">
                <i className={Types[value.component] || 'fas fa-question-circle'} />
            </div>
            <div className="text-break w-100">
                <div>
                    {value.path && foundGame && (
                        <Link to={'/game/' + foundGame.id + value.path}>{value.name}</Link>
                    )}
                    {value.url && (
                        <a target="_blank" rel="noreferrer" href={value.url}>
                            {value.name}{' '}
                            <span className="small">
                                <i className="fas ms-2 fa-sm fa-external-link-alt" />
                            </span>
                        </a>
                    )}
                    <span className="small ms-1 text-secondary fw-light">- {value.weight} matches</span>
                </div>
                <div className="mt-1 mb-2">{value.description}</div>
                {value.type && (
                    <div className="mt-1">
                        <span className="me-2">Type:</span>
                        <span className="fw-light">{value.type}</span>
                    </div>
                )}
                {foundGame && !value.projectId && (
                    <div className="mt-1">
                        <span className="me-2">Project:</span>
                        <span className="fw-light">{foundGameName}</span>
                    </div>
                )}
                {value.component && (
                    <div className="mt-1">
                        <span className="me-2">Component:</span>
                        <span className="fw-light">
                            {ComponentsMap[value.component]
                                ? ComponentsMap[value.component].name
                                : value.component}
                        </span>
                    </div>
                )}
                {value.projectId && foundGame && (
                    <div className="mt-1">
                        <div>
                            <span className="me-2">Project ID:</span>
                            <span className="fw-light">{value.projectId}</span>
                        </div>
                        <div>
                            <Link to={'/game/' + foundGame.id + '/settings'} className="small me-2">
                                View settings
                            </Link>
                            ·
                            <Link to={'/game/' + foundGame.id + '/iam-admin'} className="small mx-2">
                                View permissions
                            </Link>
                            ·
                            <Link to={'/game/' + foundGame.id + '/remote-config'} className="small mx-2">
                                View Game Balance
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getPlayerSave, addPlayerSave, updatePlayerSave } from 'services/players';
import { IAMButton, Input, TextArea, usePageBlocker, useGame } from 'components';
import Skeleton from 'react-loading-skeleton';
import { utils, date } from '@gpg-web/utils';
import NavTab from '../players/NavTab';
import KeyOptions from './KeyOptions';

const EditPlayerSave = (props) => {
    const game = useGame();
    const [data, setData] = useState(null);

    const { saveTypeId } = useParams();

    const editing = saveTypeId !== 'add-new';

    const gameId = game.id;

    const history = useNavigate();

    const [updateInitalData, unblockRedirect] = usePageBlocker(data);

    useEffect(() => {
        if (!gameId) return;

        if (editing) {
            getPlayerSave(gameId, saveTypeId)
                .then((res) => {
                    setData(updateInitalData(res));
                })
                .catch(utils.hintError);
        } else {
            setData(updateInitalData({}));
        }
    }, [gameId, saveTypeId, editing, updateInitalData]);

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    };

    const addOrUpdate = () => {
        data.name = data.name.trim();
        if (!data.name) return utils.hintError('Save Type Name is required.');

        utils.confirm('Are you sure you want to save changes?', function (yes) {
            if (yes) {
                utils.popup('saving');

                const fn = editing ? updatePlayerSave : addPlayerSave;

                fn(gameId, data)
                    .then((result) => {
                        utils.popup('hide');

                        if (!editing) {
                            unblockRedirect();
                            history('/game/' + gameId + '/save-type/' + result.id);
                        } else {
                            setData(updateInitalData(result));
                        }
                    })
                    .catch(utils.hintError);
            }
        });
    };

    return (
        <>
            <NavTab tab="save-type" gameId={gameId} />

            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4>Save Types</h4>

                    {data && (
                        <div className="ms-auto">
                            <IAMButton
                                permissions={[
                                    editing ? 'component.playersave.update' : 'component.playersave.create',
                                    'firebase.template.update'
                                ]}
                            >
                                <button
                                    onClick={addOrUpdate}
                                    className="btn btn-sm btn-success shadow-sm ms-auto"
                                >
                                    <i className="fa fa-sm fa-save me-1" /> Save
                                </button>
                            </IAMButton>
                        </div>
                    )}
                </div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small">
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId}>Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId + '/save-type'}>Save Types</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {data ? data.name || 'New Save Type' : 'loading ...'}
                        </li>
                    </ol>
                </nav>

                {data && (
                    <div className="mt-4">
                        <div className="card shadow mb-4">
                            <div className="row card-body">
                                <div className="col-lg-6 col-md-12">
                                    <Input
                                        name="name"
                                        title="Name"
                                        value={data.name}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        name="updatedAt"
                                        disabled={true}
                                        title="Last Modified"
                                        value={date(data.updatedAt)}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <TextArea
                                        name="description"
                                        rows={5}
                                        title="Description"
                                        value={data.description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <KeyOptions
                            className="shadow mb-4"
                            list={data.keyOptions}
                            setList={(list) => setData({ ...data, keyOptions: list })}
                        />

                        <div className="card shadow mb-4">
                            <div className="card-header">
                                <h6 className="m-0 fw-bold text-primary">Migrations</h6>
                            </div>
                            <div className="card-body text-warning">WIP</div>
                        </div>
                    </div>
                )}

                {!data && (
                    <div className="mt-4">
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={150}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={300}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={80}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={80}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={80}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default EditPlayerSave;

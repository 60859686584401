/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { updateProfilePlayerSave } from 'services/players';
import { Alert, BasicJsonTextEditor, IAMButton, useModalBlocker, useGame } from 'components';
import Skeleton from 'react-loading-skeleton';
import { utils, date, str } from '@gpg-web/utils';
import { Modal, Spinner } from '@gpg-web/react';

const PlayersData = (props) => {
    const { profile, saveTypes, saves, setSaves } = props;

    const [editSaveData, setEditSaveData] = useState('');
    const [editSaveType, setEditSaveType] = useState({});
    const [editSave, setEditSave] = useState(null);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [updateInitalData, onBeforeClose] = useModalBlocker(editSaveData);

    const game = useGame();

    const gameId = game.id;

    const resetModal = () => {
        setEditing(false);
        setSaving(false);
        setError(null);
    };

    const editConfig = (saveType, value) => {
        setEditSaveType(saveType);
        setEditSave(value);

        if (value.data !== '')
            setEditSaveData(updateInitalData(JSON.stringify(JSON.parse(value.data), null, 3)));
        else setEditSaveData(updateInitalData(''));

        setEditing(true);
    };

    const saveConfig = () => {
        setError(null);
        let content;

        try {
            content = JSON.parse(editSaveData);
            content = JSON.stringify(content);
        } catch (err) {
            return setError('JSON is invalid!');
        }

        utils.confirm('Are you sure you want to save changes?', function (yes) {
            if (yes) {
                setSaving(true);

                editSave.data = content;

                updateProfilePlayerSave(gameId, profile.id, editSaveType.id, editSave)
                    .then((result) => {
                        Object.assign(saves[editSaveType.id], result);
                        setSaves({ ...saves });
                        // if (editCallback) editCallback(result);
                        setEditing(false);
                    })
                    .catch((err) => {
                        setError(err);
                        setSaving(false);
                    });
            }
        });
    };

    return (
        <div>
            {!!saveTypes && (
                <div className="datatable">
                    <div className="datatable-row">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Save Name</th>
                                    <th style={{ width: '45%' }} scope="col">
                                        Save Value
                                    </th>
                                    <th scope="col">Version</th>
                                    <th scope="col">Last modified</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {saveTypes.map((e, i) => (
                                    <ConfigItem
                                        key={e.id}
                                        onEdit={editConfig}
                                        save={saves[e.id]}
                                        index={i}
                                        saveType={e}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            <Modal
                title={'Edit ' + editSaveType.name}
                show={editing}
                onHide={resetModal}
                onBeforeClose={onBeforeClose}
            >
                <div className="modal-body">
                    <div>
                        <BasicJsonTextEditor
                            onlyObjectsAllowed={true}
                            name="saveContent"
                            rows={15}
                            title="Player Save Content"
                            value={editSaveData}
                            onChange={(e) => setEditSaveData(e.target.value)}
                        />
                    </div>

                    {error && <Alert text={error} />}
                </div>

                <div className="modal-footer">
                    <button disabled={saving} className="btn btn-secondary" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button disabled={saving} onClick={saveConfig} className="btn btn-success">
                        {saving ? <Spinner /> : <i className="fa fa-sm fa-check me-1" />} Save
                    </button>
                </div>
            </Modal>

            {!saveTypes && (
                <div className="mt-4">
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={30} />
                </div>
            )}

            <div className="mt-3">
                <Link to={'/game/' + gameId + '/save-type'}>Setup list</Link>
            </div>
        </div>
    );
};

const ConfigItem = (props) => {
    let { save, saveType, index, onEdit } = props;

    // const [save, setSave] = useState(null);
    // const [error, setError] = useState("");

    // useEffect(() => {

    //     getProfilePlayerSave(gameId, profileId, saveType.id).then(setSave).catch(setError);
    //     // if (profile["save_" + saveType.id]) setSave(profile["save_" + saveType.id]);

    // }, [profileId, saveType.id, gameId]);

    let error = null;
    if (typeof save === 'string') {
        error = save;
        save = null;
    }

    return (
        <tr className="align-middle">
            <th scope="row">{index + 1}</th>
            <td>{saveType.name}</td>
            <td>
                {!!error ? (
                    <div className="text-danger">{error}</div>
                ) : save === undefined ? (
                    <div>
                        <Spinner /> Downloading
                    </div>
                ) : (
                    <div className="texdt-nowrap">
                        {save.data === '' ? (
                            <span className="text-muted">No data</span>
                        ) : save.data.length > 100 ? (
                            utils.bytesToStr(save.data.length)
                        ) : (
                            <pre className="small font-monospace overflow-hidden">
                                {str.trim(save.data, 70)}
                            </pre>
                        )}
                    </div>
                )}
            </td>
            <td>{(save && save.version) || '-'}</td>
            <td>{save && date(save.updatedAt)}</td>
            <td>
                <IAMButton permissions={['component.player.update']}>
                    <button
                        disabled={save === undefined}
                        onClick={() => onEdit(saveType, save)}
                        title="Edit save"
                        className="btn btn-sm"
                    >
                        <i className="fas fa-edit me-1"></i> Edit
                    </button>
                </IAMButton>
            </td>
        </tr>
    );
};

export default PlayersData;

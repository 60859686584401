/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect } from 'react';
import { JSONOption } from 'components';

const defaultData = () => ({
    customData: ''
});

export const TLEView = (props) => {
    const { value, editing, onChange } = props;

    // const game = useGame();
    // const gameId = game.id;

    /**
     * Validation start
     */
    useEffect(() => {
        if (!value) onChange(defaultData());
        else if (!value.customData) onChange(defaultData());
    }, [value, onChange]);
    /**
     * Validation end
     */

    function handleChange(fieldName, fieldValue) {
        value[fieldName] = fieldValue;
        onChange({ ...value });
    }

    return (
        <div className="mt-4">
            <JSONOption
                title="Custom data"
                name="customData"
                readOnly={!editing}
                value={value.customData}
                onChange={handleChange}
            />
        </div>
    );
};

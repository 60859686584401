export const BoolOption = ({ title, name, value, onChange, readOnly }) => {
    return (
        <div className="d-flex align-items-center my-1">
            <div className="form-check">
                <input
                    className="form-check-input"
                    checked={!!value}
                    type="checkbox"
                    disabled={readOnly}
                    onChange={(e) => onChange(name, e.target.checked)}
                />
            </div>
            <div className="me-2 text-nowrap">{title}</div>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect, useState } from 'react';
import { utils } from '@gpg-web/utils';
import { useGame, TooltipWrapper } from 'components';
import { Expression, CONDITION_ELEMENTS, validateConditions } from '../../components/Conditions';
import className from 'classnames';

const NEW_USERS_CONDITION_ID = 'authTime';

// const DISABLED_CONDITIONS = [NEW_USERS_CONDITION_ID];

const EXPERIMENT_CONDITION_ELEMENTS = CONDITION_ELEMENTS;
// .filter(
//     ({ id }) => DISABLED_CONDITIONS.indexOf(id) === -1
// );

export const ConditionsTrigger = (props) => {
    const { data, setData, onError, readOnly, errorId, canUpdateLive } = props;
    const [error, setError] = useState(null);
    const game = useGame();

    const conditions = data.conditions;

    const gameId = game.id;

    useEffect(() => {
        function _setError(err) {
            setError(err);
            onError && onError(errorId, err);
        }

        if (data.newUsers) {
            if (!data.time.data.start || !data.time.data.end) {
                return _setError('Please specify "Start on" and "End on" dates on time trigger');
            }
        }

        if (!data.conditions.some((e) => !e.id || !e.op || !e.val)) {
            let validError = validateConditions(data.conditions);

            if (validError) {
                return _setError(validError);
            } else {
                return _setError(null);
            }
        } else {
            return _setError('Please fill the condition');
        }
    }, [data, errorId, onError]);

    function changeExpression(index, condition) {
        if (condition.id === 'authTime' && data.newUsers && !readOnly) return;

        Object.assign(conditions[index], condition);
        setData({ ...data });
    }

    function addNewExpression() {
        conditions.push({ id: '', op: '', val: '' });
        setData({ ...data });
    }

    function removeExpression(index) {
        conditions.splice(index, 1);
        setData({ ...data });
    }

    return (
        <div className={utils.className('card position-static', props.className)}>
            <div className="card-header py-3 d-flex align-items-center">
                <div className="fw-bold text-primary">Conditions trigger</div>
            </div>

            <div className="card-body">
                <div className="conditions">
                    <div className="text-muted small mb-3">Applies if...</div>
                    {conditions.map((condition, index) => {
                        return (
                            <Expression
                                gameId={gameId}
                                disabled={
                                    condition.id === 'authTime' && data.newUsers && readOnly && !canUpdateLive
                                }
                                readOnly={readOnly}
                                canUpdateLive={canUpdateLive}
                                isAllValid={!error}
                                onRemove={removeExpression}
                                onAdd={addNewExpression}
                                conditions={conditions}
                                onChange={changeExpression}
                                key={condition.id + index}
                                index={index}
                                expression={condition}
                                customElements={EXPERIMENT_CONDITION_ELEMENTS}
                            />
                        );
                    })}
                    {conditions.length === 0 && (
                        <div className="col-4 mb-3">
                            <button
                                onClick={addNewExpression}
                                className="btn btn-primary btn-sm"
                                disabled={readOnly}
                            >
                                <i className="fas fa-plus me-1" /> Add new condition
                            </button>
                        </div>
                    )}
                    {!readOnly && (
                        <div className="mt-3 d-flex">
                            <NewUserCondition data={data} setData={setData} readOnly={readOnly} />
                        </div>
                    )}
                </div>

                {error && (
                    <div className="alert alert-danger mt-3" role="alert">
                        <i className="fas fa-exclamation-circle me-2" />
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
};

const NewUserCondition = ({ data, setData, readOnly }) => {
    const scheludeData = data.time.data || {};

    const newUsers = data.newUsers;

    useEffect(() => {
        if (readOnly) return;
        if (newUsers) {
            if (scheludeData.start && scheludeData.end) {
                setData((data) => {
                    const conditions = data.conditions.filter((e) => e.id !== NEW_USERS_CONDITION_ID);

                    /**
                     * Put val as first item and id as last, because
                     * Firebase returing exatly this order.
                     * So each time user open page
                     * and try to leave it without changes -
                     * it asked to savechanges
                     */
                    conditions.splice(0, 0, { val: scheludeData.end, op: '<=', id: NEW_USERS_CONDITION_ID });
                    conditions.splice(0, 0, { val: scheludeData.start, op: '>', id: NEW_USERS_CONDITION_ID });

                    data.conditions = conditions;

                    return { ...data };
                });
            } else {
                setData((data) => {
                    const conditions = data.conditions.filter((e) => e.id !== NEW_USERS_CONDITION_ID);

                    data.conditions = conditions;

                    return { ...data };
                });
            }
        }
    }, [setData, newUsers, scheludeData.start, scheludeData.end, readOnly]);

    return (
        <div
            role="button"
            className={className('p-1 px-2 d-flex', { disabled: readOnly })}
            style={{ fontSize: '0.875rem' }}
            onClick={(e) => {
                e.stopPropagation();
                data.newUsers = !data.newUsers;
                setData({ ...data });
            }}
        >
            <input
                className="form-check-input pe-none me-2"
                checked={data.newUsers}
                readOnly={true}
                disabled={readOnly}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                type="checkbox"
                id="target-newUsersOnly"
            />
            <TooltipWrapper
                icon={false}
                content='Synchronize timer "Starts on" date with "Auth Time" after condition, and timer "Ends on" date with "Auth Time" before condition'
            >
                <label
                    role="button"
                    className="form-check-label user-select-none"
                    htmlFor="target-newUsersOnly"
                >
                    Synchronize experiment dates with "Auth Time" conditions
                </label>
            </TooltipWrapper>
        </div>
    );
};

import { useUserIAM } from '../hooks/UseUserIAM';
import { PopoverWrapper } from '../tooltips/PopoverWrapper';

import { Link } from 'react-router-dom';

const IamTooltipContent = ({ permissions, gameId }) => {
    return (
        <div className="card shadow-sm border-0 small px-2">
            <div className="p-2">
                You are missing the following permissions to perform this action:
                <pre className="m-0">
                    <ul className="text-monospace m-0 mt-1 fw-bold">
                        {permissions.map((p, i) => (
                            <li key={i}>{p}</li>
                        ))}
                    </ul>
                </pre>
                <div className="d-flex mt-1  justify-content-end">
                    <Link
                        to={'/game/' + gameId + '/missing-permissions?permissions=' + permissions.join(',')}
                        className="btn btn-link btn-sm"
                    >
                        Learn more
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const IAMButton = (props) => {
    const permissions = props.permissions || [];
    const { userPermissions, gameId } = useUserIAM();

    if (!userPermissions) return null;

    let neededPermissions = [];

    if (permissions.length === 1 && userPermissions.indexOf(permissions[0]) === -1) {
        neededPermissions = [permissions[0]];
    } else {
        neededPermissions = permissions.filter((p) => userPermissions.indexOf(p) === -1);
    }

    if (neededPermissions.length === 0) {
        return props.children;
    }

    if (props.hide) return;

    return (
        <PopoverWrapper
            {...props}
            content={<IamTooltipContent permissions={neededPermissions} gameId={gameId} />}
            icon="fas fa-exclamation-circle"
            color="secondary"
            className="disabled"
        />
    );
};

export const IAMButtonSmall = (props) => <IAMButton {...props} top={20} left={80} size={'xs'} />;

const types = {
    storage: 'fas fa-folder-open',
    Project: 'fas fa-sitemap',
    gameBalance: 'fas fa-code',
    tle: 'fas fa-calendar-minus',
    tlo: 'fas fa-calendar-minus',
    popup: 'fas fa-window-restore',
    store: 'fas fa-store',
    builtIn: 'fas fa-grip-horizontal',
    entity: 'fas fa-apple-alt',
    product: 'fas fa-shopping-basket',
    translation: 'fas fa-language',
    experiment: 'fas fa-flask',
    dailyReward: 'fas fa-coins',
    piggyBank: 'fas fa-piggy-bank',
    Documentation: 'fas fa-file-alt'
};

export default types;

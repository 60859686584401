import { useState, useEffect } from 'react';
import { getEnvironments, getVersions } from 'services/settings';
import { useGame, useStoredState } from 'components';
import { utils } from '@gpg-web/utils';

const cache_getEnvironmentalData = {};

window.addEventListener('livefire_versions_update', function (event) {
    if (event.detail.gameId) {
        delete cache_getEnvironmentalData[event.detail.gameId];
    }
});

window.addEventListener('livefire_envs_update', function (event) {
    if (event.detail.gameId) {
        delete cache_getEnvironmentalData[event.detail.gameId];
    }
});

const getEnvironmentalData = (gameId) => {
    let cached = cache_getEnvironmentalData[gameId];

    if (!cached)
        cached = cache_getEnvironmentalData[gameId] = getEnvironments(gameId).then((environments) => {
            return getVersions(gameId).then((versions) => {
                return {
                    versions: versions,
                    environments: environments
                };
            });
        });

    return cached;
};

export function useEnvironmental(id) {
    const [environments, setEnvironments] = useState(null);
    const [gameVersions, setGameVersions] = useState(null);

    const [selectedEnvironment, setSelectedEnvironment] = useStoredState(
        'config_default_selected_environment'
    );
    const [selectedGameVersion, setSelectedGameVersion] = useStoredState(
        'config_default_selected_game_version'
    );

    const game = useGame();
    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getEnvironmentalData(gameId)
            .then((result) => {
                if (result.versions.length > 0 && result.environments.length > 0) {
                    setGameVersions(result.versions);
                    setEnvironments(result.environments);

                    setSelectedEnvironment((defaultSelectedEnvironment) => {
                        if (
                            !(
                                defaultSelectedEnvironment &&
                                result.environments.find((e) => e.id === defaultSelectedEnvironment)
                            )
                        ) {
                            defaultSelectedEnvironment = result.environments[0].id;
                        }
                        return defaultSelectedEnvironment;
                    });
                    setSelectedGameVersion((defaultSelectedGameVersion) => {
                        if (
                            !(
                                defaultSelectedGameVersion &&
                                result.versions.find((e) => e.id === defaultSelectedGameVersion)
                            )
                        ) {
                            defaultSelectedGameVersion = result.versions[0].id;
                        }
                        return defaultSelectedGameVersion;
                    });
                }
            })
            .catch(utils.hintError);
    }, [gameId, setSelectedEnvironment, setSelectedGameVersion]);

    let selectedPath = null;
    if (selectedEnvironment && selectedGameVersion) {
        selectedPath = selectedEnvironment + selectedGameVersion + '/';
    }

    return {
        environments,
        gameVersions,
        selectedEnvironment,
        setSelectedEnvironment,
        selectedGameVersion,
        setSelectedGameVersion,
        selectedPath
    };
}

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getProcess } from 'services/automation';
import { organizationContext } from 'services/organization';
import Skeleton from 'react-loading-skeleton';
import { utils, date } from '@gpg-web/utils';
import { ComponentsMap } from 'consts';
import { UserBadge, useGame } from 'components';
import { Statuses, getStatus } from './Statuses';
import socket from 'services/socket';
import { getScriptData } from './scripts/data';

const inputOptionsValues = {
    true: <span className="text-success">Yes</span>,
    false: <span className="text-danger">No</span>,
    ignore: 'Ignore',
    merge: 'Merge',
    keep: 'Keep',
    replace: 'Replace',
    skip: 'Skip',
    throw_error: (
        <>
            <i className="fas fa-times-circle text-danger" /> Break the process
        </>
    )
};

function displayOptionValue(value) {
    if (inputOptionsValues[value]) {
        return inputOptionsValues[value];
    } else if (Array.isArray(value)) {
        return value.join(', ');
    } else if (typeof value === 'object') {
        return JSON.stringify(value, undefined, 2);
    }

    return value;
}

export const ProcessDetails = (props) => {
    const game = useGame();
    const [data, setData] = useState(null);
    const [script, setScript] = useState(null);

    const { processId } = useParams();
    const history = useNavigate();
    const { games } = useContext(organizationContext);

    const gameId = game.id;

    useEffect(() => {
        if (!gameId || !processId) return;

        function socketProcessUpdate(data) {
            if (data.game === gameId && data.id === processId && data.finished) {
                setData((d) => {
                    Object.assign(d, data);
                    return { ...d };
                });
            }
        }

        getProcess(gameId, processId)
            .then((data) => {
                setData(data);

                const scripts = getScriptData();

                const scriptId = Object.keys(scripts).find(
                    (scriptId) => scripts[scriptId].name === data.name
                );

                setScript(scripts[scriptId]);

                socket.on('automation-process', socketProcessUpdate);
            })
            .catch(utils.hintError);

        return () => {
            socket.off('automation-process', socketProcessUpdate);
        };
    }, [gameId, processId]);

    // const component = data ? ComponentsMap[data.component] : null;
    let components = [];

    if (data) {
        components = data.components || [data.component];
    }

    components = components.map((c) => (ComponentsMap[c] ? ComponentsMap[c].name : c));

    return (
        <div className="container-lg">
            <div className="d-flex align-items-center">
                <h4>Process Details</h4>
            </div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb small">
                    <li className="breadcrumb-item">
                        <Link to={'/game/' + gameId}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={'/game/' + gameId + '/automation/processes'}>Processes</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {data ? data.name : 'loading ...'}
                    </li>
                </ol>
            </nav>

            {data && (
                <div className="mt-4">
                    <h5 className="d-flex align-items-center">
                        {data.name} <span className="ms-2">{Statuses[getStatus(data)]}</span>
                    </h5>
                    <div className="text-muted fw-light">{components.join(', ')}</div>

                    <div className="row mt-4">
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <h6 className="fw-bold">Started By</h6>
                                    <div className="fw-light">
                                        <UserBadge
                                            email={data.user}
                                            border={false}
                                            onClick={(e) => history(e.url)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <h6 className="fw-bold">Started At</h6>
                                    <div className="fw-light">{date(data.createdAt)}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <h6 className="fw-bold">{data.finished ? 'Finished' : 'Updated'} At</h6>
                                    <div className="fw-light">{date(data.updatedAt)}</div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <h6 className="fw-bold">Component</h6>
                                    <div className="fw-light">{components.join(', ')}</div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="card shadow-sm mb-3">
                        <div className="card-header fw-bold">Options</div>
                        <div className="card-body">
                            <div className="row">
                                <div>
                                    <ul className="list-group">
                                        {Object.keys(data.options).map((e) => {
                                            if (script) {
                                                const option = script.options.find((opt) => opt.id === e);

                                                if (option) {
                                                    return (
                                                        <li key={e} className="list-group-item">
                                                            <span>{option.name || e} - </span>
                                                            {option.view ? (
                                                                <option.view
                                                                    {...option}
                                                                    value={data.options[e]}
                                                                />
                                                            ) : (
                                                                <span className="fw-bold">
                                                                    {displayOptionValue(data.options[e])}
                                                                </span>
                                                            )}
                                                        </li>
                                                    );
                                                }
                                            }

                                            if (e === 'gameSrc') {
                                                const foundGame = games.find(
                                                    (game) => game.id === data.options[e]
                                                ) || { name: 'Unknown' };

                                                return (
                                                    <div className="list-group-item" key={e}>
                                                        <span>Source Game - </span>
                                                        <Link
                                                            className="fw-bold"
                                                            to={'/game/' + data.options[e]}
                                                        >
                                                            {foundGame.name}
                                                        </Link>
                                                    </div>
                                                );
                                            }

                                            return (
                                                <li key={e} className="list-group-item">
                                                    <span>{e} - </span>
                                                    <span className="fw-bold">
                                                        {displayOptionValue(data.options[e])}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {data.errors.length > 0 && (
                        <div className="card shadow-sm mb-3">
                            <div className="card-header fw-bold text-danger">
                                <i className="fas fa-exclamation-circle me-1" /> Errors
                            </div>

                            <div className="card-body">
                                {data.errors.map((e, index) => (
                                    <div key={index} className="text-danger mb-2 border-bottom">
                                        - {e.message}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {data.warnings.length > 0 && (
                        <div className="card shadow-sm mb-3">
                            <div className="card-header fw-bold text-warning-emphasis">
                                <i className="fas fa-exclamation-triangle me-1 text-warning" /> Warnings
                            </div>

                            <div className="card-body">
                                {data.warnings.map((e, index) => (
                                    <div key={index} className="text-warning-emphasis mb-2 border-bottom">
                                        - {e.message}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {data.added.length > 0 && (
                        <div className="card shadow-sm mb-3">
                            <div className="card-header fw-bold">
                                <i className="fas fa-plus fa-xs text-white rounded-1 me-1 bg-success p-1" />{' '}
                                Added
                            </div>

                            <div className="card-body">
                                {data.added.map((e, i) => (
                                    <div className="mb-2 pb-1 border-bottom" key={e.tag + e.name + i}>
                                        {e.tag && (
                                            <span className="badge bg-secondary rounded-pill me-2">
                                                {e.tag}
                                            </span>
                                        )}
                                        <Link to={e.url && '/game/' + gameId + e.url}>{e.name}</Link>
                                        {e.message && <i className="ms-2 text-secondary">- {e.message}</i>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {data.modified.length > 0 && (
                        <div className="card shadow-sm mb-3">
                            <div className="card-header fw-bold">
                                <i className="fas fa-edit fa-xs text-white rounded-1 bg-success me-1 p-1" />{' '}
                                Modified
                            </div>

                            <div className="card-body">
                                {data.modified.map((e, i) => {
                                    const tags = e.tags || [e.tag];

                                    return (
                                        <div
                                            className="mb-2 pb-1 border-bottom"
                                            key={tags.join('_') + e.name + i}
                                        >
                                            {tags.map((tag) => (
                                                <span className="badge bg-secondary rounded-pill me-2">
                                                    {tag}
                                                </span>
                                            ))}
                                            {e.url ? (
                                                <Link to={e.url && '/game/' + gameId + e.url}>{e.name}</Link>
                                            ) : (
                                                <span>{e.name}</span>
                                            )}
                                            {e.message && (
                                                <i className="ms-2 text-secondary">- {e.message}</i>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {data.added.length === 0 && data.modified.length === 0 && (
                        <div className="alert alert-warning">
                            <i className="fas fa-exclamation-triangle me-2" />
                            Nothing has been added or modified
                        </div>
                    )}
                </div>
            )}

            {!data && (
                <div className="mt-4">
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={150} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={300} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={80} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={80} />
                    <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" width="100%" height={80} />
                </div>
            )}
        </div>
    );
};

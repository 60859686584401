/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getPlayerSaves, removePlayerSave, copyPlayerSave } from 'services/players';
import { Table } from '@gpg-web/react';
import Skeleton from 'react-loading-skeleton';
import { utils, date } from '@gpg-web/utils';
import Migration from './Migration';
import NavTab from '../players/NavTab';
import { CellLink, IAMButton, IAMButtonSmall, MigrateFromButton, useGame } from 'components';

const columns = [
    {
        Header: '#',
        accessor: 'index'
    },
    {
        Header: 'Name',
        accessor: 'name',
        Cell: CellLink
    },
    {
        Header: 'Last modified',
        accessor: 'displayUpdatedAt',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.updatedAt > rowB.original.updatedAt ? 1 : -1;
        }
    },
    {
        accessor: 'actions',
        disableSortBy: true
    }
];

const PlayerSaves = (props) => {
    // let { game, setGame } = useContext(gameContext);
    const [playerSaves, setPlayerSaves] = useState(null);
    const [importing, setImporting] = useState(false);
    const game = useGame();
    // const history = useNavigate();

    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getPlayerSaves(gameId).then(setPlayerSaves).catch(utils.hintError);
    }, [gameId]);

    const data = useMemo(() => {
        if (!playerSaves) return [];

        const _removePlayerSave = (e) => {
            e.stopPropagation();

            utils.confirm('Are you sure want to remove this type?', (yes) => {
                if (yes) {
                    utils.popup('removing');

                    const playerSaveId = e.target.getAttribute('data-id');
                    const playerSave = playerSaves.find((e) => e.id === playerSaveId);

                    removePlayerSave(gameId, playerSave.id, playerSave.name)
                        .then(() => {
                            utils.remove(playerSaves, (e) => e.id === playerSaveId);

                            setPlayerSaves(playerSaves.slice());

                            utils.popup('hide');
                        })
                        .catch(utils.hintError);
                }
            });
        };

        const _copyPlayerSave = (e) => {
            e.stopPropagation();

            const playerSaveId = e.target.getAttribute('data-id');
            const playerSaveName = e.target.getAttribute('data-name');

            utils.promt(
                'Please specify a new Save Type name',
                (name) => {
                    if (name === false) return;

                    if (!name) return utils.hintError('Save Type Name is required.');

                    utils.popup('Cloning ...');

                    copyPlayerSave(gameId, { id: playerSaveId, name: name })
                        .then((result) => {
                            playerSaves.push(result);

                            setPlayerSaves(playerSaves.slice());

                            utils.popup('hide');
                        })
                        .catch(utils.hintError);
                },
                {
                    value: 'Copy of ' + playerSaveName,
                    label: 'New Save Type name',
                    placeholder: 'Name *'
                }
            );
        };

        return playerSaves.map((e, i) => ({
            _rowAttributes: {
                title: e.id
            },
            id: e.id,
            index: i + 1,
            name: e.name,
            name_link: '/game/' + gameId + '/save-type/' + e.id,
            description: e.description,
            updatedAt: new Date(e.updatedAt),
            displayUpdatedAt: date(e.updatedAt, 'ago-1'),
            actions: (
                <>
                    <IAMButtonSmall permissions={['component.playersave.create', 'firebase.template.update']}>
                        <button
                            onClick={_copyPlayerSave}
                            data-name={e.name}
                            data-id={e.id}
                            title="Duplicate object"
                            className="btn btn-sm"
                        >
                            <i className="fa fa-copy pe-none" />
                        </button>
                    </IAMButtonSmall>
                    <IAMButtonSmall permissions={['component.playersave.delete']}>
                        <button
                            onClick={_removePlayerSave}
                            data-id={e.id}
                            title="Delete object"
                            className="btn btn-sm"
                        >
                            <i className="fa fa-trash pe-none" />
                        </button>
                    </IAMButtonSmall>
                </>
            )
        }));
    }, [playerSaves, gameId]);

    return (
        <>
            <NavTab tab="save-type" gameId={gameId} />

            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4>Save Types</h4>

                    <div className="ms-auto">
                        <IAMButton permissions={['component.playersave.create', 'firebase.template.update']}>
                            <Link
                                to={'/game/' + gameId + '/save-type/add-new'}
                                className="btn btn-sm btn-success shadow-sm"
                            >
                                <i className="fa fa-sm fa-plus" /> Add New
                            </Link>
                        </IAMButton>
                    </div>
                    <div className="ms-3">
                        <IAMButton permissions={['component.playersave.migrate']}>
                            <MigrateFromButton component="playerSave" onClick={setImporting} />
                        </IAMButton>
                    </div>
                </div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small">
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Save Types
                        </li>
                    </ol>
                </nav>

                <div className="mt-4">
                    {!!playerSaves && (
                        <Table
                            saveStateId="player-save-types-list"
                            sortBy={[{ id: 'displayUpdatedAt', desc: true }]}
                            // onClick={(e) => {
                            //     history('/game/' + gameId + '/save-type/' + e.id);
                            // }}
                            columns={columns}
                            data={data}
                            className="table-striped"
                        />
                    )}
                </div>

                <Migration
                    show={importing}
                    onFinish={() => {
                        setPlayerSaves(null);
                        getPlayerSaves(gameId).then(setPlayerSaves).catch(utils.hintError);
                    }}
                    onHide={() => setImporting(false)}
                    game={game}
                />

                {!playerSaves && (
                    <div className="mt-4">
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default PlayerSaves;

import Tippy from '@tippyjs/react';
import { utils } from '@gpg-web/utils';
import 'tippy.js/animations/shift-away.css';

const layouts = {
    'default': 'position-absolute translate-middle',
    'inline-start': 'ms-2',
    'inline-end': 'ms-1'
};

export const PopoverWrapper = ({
    children,
    content,
    tooltipOptions,
    className,
    icon,
    size,
    color,
    left,
    top,
    layout,
    wrapperClassName
}) => {
    icon = icon || 'fas fa-info-circle';
    size = size || 'sm';
    color = color || 'dark';

    left = typeof left === 'undefined' ? 95 : left;
    top = typeof top === 'undefined' ? 10 : top;

    const component = (
        <i
            className={`${icon} fa-${size} ${
                layouts[layout || 'default']
            } top-${top} start-${left} border border-2 border-white bg-white text-${color} rounded-circle pe-none`}
        />
    );

    return (
        <Tippy
            appendTo={document.body}
            interactive={true}
            offset={[0, 5]}
            placement="auto"
            delay={[500, 0]}
            animation="shift-away"
            {...(tooltipOptions || {})}
            content={content}
        >
            <div className={utils.className('position-relative d-inline-block', wrapperClassName)}>
                {layout === 'inline-start' && component}
                <div className={className + ' d-inline-block'}>{children}</div>
                {layout !== 'inline-start' && component}
            </div>
        </Tippy>
    );
};

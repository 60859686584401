import React from 'react';
import axios from 'axios';
import profileIcon from '../img/undraw_profile.svg';

export const organizationContext = React.createContext({ members: [], games: [] });

let members = null;

export const getMembers = () => {
    if (members) {
        return Promise.resolve(members);
    }

    return axios.get('/api/organization/members').then((data) => {
        members = data;
        return data;
    });
};


export function getMember(memberEmail, id) {

    if (!members) return {
        name: memberEmail,
        email: memberEmail,
        picture: profileIcon,
        url: "/member-not-found"
    } 

    if (!memberEmail && !id) return null;

    let member;

    if (id) {
        member = members.find((e) => e.id === id);
    } else {
        member = members.find((e) => e.email === memberEmail);
    }

    if (!member) member = { name: memberEmail, email: memberEmail, url: "/member-not-found" };
    else member.url = "/profile/" + member.id;

    member.picture = member.picture || profileIcon;

    return member;
}
import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getGame, gameContext } from 'services/game';
import { utils } from '@gpg-web/utils';
import { getRoles } from 'services/iam';
import { getProfile } from 'services/user';

const GAMES_CACHE = {};

const getCachedGame = (gameId) => {
    if (GAMES_CACHE[gameId]) return GAMES_CACHE[gameId];

    GAMES_CACHE[gameId] = getGame(gameId);

    return GAMES_CACHE[gameId];
};

const ROLES_CACHE = {};

const getCachedRoles = (gameId) => {
    if (ROLES_CACHE[gameId]) return ROLES_CACHE[gameId];

    ROLES_CACHE[gameId] = getRoles(gameId);

    return ROLES_CACHE[gameId];
};

export function useGame() {
    const { game, setGame } = useContext(gameContext);
    const { id } = useParams();
    const _id = game.id;

    useEffect(() => {
        if (_id === id) return;

        if (!id) {
            setGame({});
            return;
        }

        utils.popup('loading');

        let valid = true;
        document.body.style.setProperty('--lf-project-color-1', utils.hashColor(id, 20));
        document.body.style.setProperty('--lf-project-color-2', utils.hashColor(id, 15));
        document.body.style.setProperty('--lf-project-color-3', utils.hashColor(id, 10));
        document.body.style.setProperty('--lf-project-color-4', utils.hashColor(id, 5));

        getCachedGame(id)
            .then((game) => {
                getCachedRoles(id).then((roles) => {
                    getProfile().then((user) => {
                        const userIams = game.iam.filter((o) => o.principal === user.email);

                        const userPermissions = [];

                        userIams.forEach((o) => userPermissions.push(...roles[o.role].permissions));

                        if (valid) {
                            utils.popup('hide');
                            setGame({ ...game, userPermissions: userPermissions });
                        }
                    });
                });
            })
            .catch(utils.hintError);

        return () => {
            valid = false;
        };
    }, [setGame, id, _id]);

    if (id !== _id) return {};

    return game;
}

export default useGame;

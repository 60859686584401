import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SimpleTable } from 'components';
import Tippy from '@tippyjs/react';
import { EditContentCell } from './EditContentCell';
import { VersionSelectPopup } from './VersionSelectPopup';
import { StorageFileModal } from '../storage/FileModal';
import { PromotePopup } from './PromotePopup';

let popoverParent = null;

export const EditTable = (props) => {
    const { environments, versions, view, selected, component, gameId, configs, updateConfigs, initConfigs } =
        props;

    const [selectingVersion, setSelectingVersion] = useState(false);
    const [selectingVersionModal, setSelectingVersionModal] = useState(false);
    const [promoting, setPromoting] = useState(false);

    const data = useMemo(() => {
        return (configs || []).map((config, i) => {
            const initConfig = (initConfigs || [])[i] || {};

            const content = config.content || {};

            const path = content.path || '';
            const row = {
                name: config.id,
                displayName: (
                    <div>
                        <Link
                            className="text-decoration-none"
                            to={'/game/' + gameId + '/config/' + component.url + '/view/' + config.id}
                        >
                            {config.id}
                        </Link>
                        <div className="small text-muted">
                            {(path.length > 60 ? '...' : '') + path.substr(Math.max(0, path.length - 60 - 3))}
                        </div>
                    </div>
                ),
                fileVersionsMap: {}
            };

            const initContent = initConfig.content || {};

            row.path = content.path;
            row.gameId = gameId;
            row.acceptFile = component.contentType !== 'json' ? component.contentType : component.fileMime;

            const initContentVersions = initContent.versions || {};
            const contentVersions = content.versions || {};

            const isVersionView = view === 'version';

            const list = isVersionView ? environments : versions;
            for (let item of list) {
                const key = isVersionView ? item.id + selected + '/' : selected + item.id + '/';

                const colKey = item.id.replaceAll('.', '/');

                const environment = isVersionView ? item.id : selected;
                const version = isVersionView ? selected : item.id;

                row[colKey] = {
                    version: null,
                    status: null,
                    init: {
                        ...initContentVersions[key]
                    },
                    params: {
                        configId: config.id,
                        environment,
                        version
                    },
                    ...contentVersions[key]
                };
            }
            //
            return row;
        });
    }, [configs, selected, view, environments, versions, component, gameId, initConfigs]);

    const isVersionView = view === 'version';

    const onChangeConfig = (id, path, name, value) => {
        const config = (configs || []).find((c) => c.id === id);

        config.content.versions[path] = {
            ...config.content.versions[path],
            [name]: value
        };

        updateConfigs(configs.slice());
    };

    const columns = useMemo(() => {
        const list = isVersionView ? environments : versions;

        const onShowVersionSelection = ({ ref, value }) => {
            if (ref) {
                ref.style.outline = `3px solid rgba(78, 99, 223, 0.3)`;
            }

            setSelectingVersion({ ...value.params, target: ref });
        };

        return [
            {
                Header: 'Config',
                accessor: 'displayName',
                cellClass: 'text-nowrap',
                size: 50,
                sortType: (rowA, rowB) => {
                    return rowA.original.name > rowB.original.name ? -1 : 1;
                }
            },
            ...list.map((item) => ({
                label: isVersionView ? item.id : 'v' + item.id,
                accessor: item.id.replaceAll('.', '/'),
                cellClass:
                    'p-0 btn btn-sm border-0 rounded-0 animation-none d-table-cell align-start border-end',
                headerClass: 'pe-0 d-table-cell text-start',
                disableSortBy: true,
                size: 100,
                Cell: EditContentCell,
                Header: EditContentHeader,
                onPromote: (state) =>
                    setPromoting({
                        ...state,
                        environment: isVersionView ? item.id : selected,
                        version: isVersionView ? selected : item.id
                    }),
                onCellClick: onShowVersionSelection
            }))
        ];
    }, [selected, isVersionView, environments, versions]);

    const onHideVersionSelection = () => {
        if (selectingVersion && selectingVersion.target) {
            selectingVersion.target.style.outline = '';
        }
        setSelectingVersion(false);
    };

    const onShowSelectingFileModal = () => {
        if (selectingVersion) {
            setSelectingVersionModal({
                configId: selectingVersion.configId,
                version: selectingVersion.version,
                environment: selectingVersion.environment
            });

            onHideVersionSelection();
        }
    };

    // useEffect(() => {
    //     const scrollParent = document.getElementById('page-content');

    //     const hideOnScroll = () => {
    //         setSelectingVersion(false);
    //         setSelectingVersionModal(false);
    //     };

    //     scrollParent.addEventListener('scroll', hideOnScroll);

    //     return () => {
    //         scrollParent.removeEventListener('scroll', hideOnScroll);
    //     };
    // }, []);

    if (!popoverParent) popoverParent = document.getElementById('page-content');

    return (
        <>
            <SimpleTable
                loading={!configs}
                columns={columns}
                sortBy={[{ id: 'name', desc: false }]}
                data={data}
                className="table-bordered table-striped table-overview"
            />

            <VersionSelectModal
                show={!!selectingVersionModal}
                onHide={(e) => setSelectingVersionModal(false)}
                onSelect={onChangeConfig}
                configs={configs}
                {...(selectingVersionModal || {})}
            />

            <Tippy
                appendTo={popoverParent}
                interactive={true}
                reference={selectingVersion ? selectingVersion.target : null}
                offset={[0, 5]}
                visible={!!selectingVersion}
                placement="bottom"
                delay={[300, 0]}
                animation="shift-away"
                zIndex={1}
                onClickOutside={onHideVersionSelection}
                onHide={onHideVersionSelection}
                content={
                    selectingVersion ? (
                        <VersionSelectPopup
                            environments={environments}
                            versions={versions}
                            configs={configs}
                            onChangeConfig={onChangeConfig}
                            version={selectingVersion && selectingVersion.version}
                            environment={selectingVersion && selectingVersion.environment}
                            configId={selectingVersion && selectingVersion.configId}
                            onShowModal={onShowSelectingFileModal}
                        />
                    ) : null
                }
            />

            <Tippy
                appendTo={popoverParent}
                interactive={true}
                reference={promoting ? promoting.target : null}
                offset={[0, 5]}
                visible={!!promoting}
                placement="bottom"
                delay={[300, 0]}
                animation="shift-away"
                onClickOutside={() => setPromoting(null)}
                onHide={() => setPromoting(null)}
                zIndex={1}
                content={
                    promoting ? (
                        <PromotePopup
                            environments={environments}
                            versions={versions}
                            configs={configs}
                            isVersionView={isVersionView}
                            environment={promoting && promoting.environment}
                            version={promoting && promoting.version}
                            onChange={updateConfigs}
                            onHide={() => setPromoting(null)}
                        />
                    ) : null
                }
            />
        </>
    );
};

const VersionSelectModal = ({ configs, version, environment, configId, show, onHide, onSelect }) => {
    let config = null;
    let path = null;
    let fileVersion = null;

    if (configs && configId) {
        config = configs.find((c) => c.id === configId);
        path = config.content?.path;
        fileVersion = config.content?.versions[environment + version + '/']?.version;
    }

    return (
        <StorageFileModal
            show={show}
            onHide={onHide}
            onSelect={({ version: v }) => onSelect(config.id, environment + version + '/', 'version', v)}
            gameVersion={version}
            environment={environment}
            version={fileVersion}
            path={path}
            readOnly={false}
        />
    );
};

const EditContentHeader = (props) => {
    const { column } = props;

    const ref = useRef();

    return (
        <div className="text-nowrap position-relative pe-4" ref={ref} style={{ minWidth: '100px' }}>
            {column.label}
            <span className="position-absolute end-0 me-1 ms-2">
                <button
                    className="btn btn p-2 py-0 text-muted small"
                    title="Promote Version"
                    onClick={() => column.onPromote({ target: ref.current })}
                >
                    <i className="fas fa-random fa-sm" />
                </button>
            </span>
        </div>
    );
};

import { useEffect, useState } from 'react';
import { getRoles } from 'services/iam';
import { utils } from '@gpg-web/utils';

const ROLES_CACHE = {};

const getCachedRoles = (gameId) => {
    if (ROLES_CACHE[gameId]) return ROLES_CACHE[gameId];

    ROLES_CACHE[gameId] = getRoles(gameId);

    return ROLES_CACHE[gameId];
};

export const useRoles = (gameId) => {
    const [roles, setRoles] = useState(null);

    useEffect(() => {
        if (!gameId) return;

        getCachedRoles(gameId)
            .then((_roles) => setRoles(_roles))
            .catch(utils.hintError);
    }, [gameId]);

    return roles;
};

import scriptsData from './data';
import { ComponentsMap } from 'consts';
import { str } from '@gpg-web/utils';

const Card = (props) => {
    const { id, name, components, onSetup } = props;

    const data = scriptsData[id] || {};
    const _components = components || [];

    return (
        <div className="card shadow-sm h-100">
            <div className="card-header pt-3 d-flex align-items-start">
                <div className="service-icon me-3" style={{ backgroundColor: data.color }}>
                    <i className={data.icon || 'fas fa-robot fa-fw'}></i>
                </div>
                <div>
                    <h6 className="card-title m-0 fw-bold">{name}</h6>
                    <div className="fw-light lh-sm" style={{ fontSize: '12px' }}>
                        {_components
                            .slice(0, 2)
                            .map((c) => (ComponentsMap[c] ? ComponentsMap[c].name : c))
                            .join(', ')}
                        {_components.length > 2 && '...'}
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="small">{data.description}</div>
            </div>
            <div className="border-top p-2 px-4 text-end">
                <button onClick={() => onSetup(id)} className="btn btn-primary btn-sm">
                    <i className="fas fa-sm fa-cogs me-2" />
                    Setup
                </button>
            </div>
        </div>
    );
};

export default Card;

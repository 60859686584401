/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createConfig } from 'services/config';
import { Input, TextArea, ComponentHeader, ProcessBlockedWrapper, useGame, usePageBlocker } from 'components';
import { utils } from '@gpg-web/utils';
import { TimeTrigger } from './Time';
import { ConditionsTrigger } from './Conditions';
import { VariantsSetup } from './Variants';
import { ConfigsSetup } from './Configs';
import { ContentSetup } from './Content';
import { Navigation } from '../Navigation';
import { useComponent } from '../hooks';

export const ExperimentCreate = (props) => {
    const game = useGame();
    const component = useComponent('experiment');
    const [data, setData] = useState({
        id: '',
        time: { global: false, mode: 'default', data: {} },
        conditions: [],
        newUsers: true,
        variants: [
            { id: 'Baseline', weight: 1, description: '' },
            { id: 'Variant A', weight: 1, description: '' }
        ],
        configs: [],
        versions: {}
    });
    const [errors, setErrors] = useState([]);

    const gameId = game.id;

    const history = useNavigate();

    const [, unBlockRedirect, ,] = usePageBlocker(data);

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    };

    const handleSave = async () => {
        try {
            if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

            let id = data.id.trim();

            if (!id) return utils.hintError('ID field is required');

            if (!utils.isValidId(id)) {
                return utils.hintError('ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed');
            }

            for (let variant of data.variants) {
                if (!variant.weight) {
                    return utils.hintError('You should provide weight for every Variant');
                }
            }

            const yes = await utils.confirm('Are you sure you want to create experiment?');

            if (!yes) return;

            data.id = id;
            utils.popup('saving');
            const result = await createConfig(gameId, component.url, data);
            utils.popup('hide');

            unBlockRedirect();
            history('/game/' + gameId + '/config/' + component.url + '/view/' + result.id);
        } catch (err) {
            utils.hintError(err);
        }
    };

    const onError = useCallback((id, err) => {
        setErrors((errors) => {
            errors[id] = err;
            return errors.slice();
        });
    }, []);

    return (
        <>
            <Navigation gameId={gameId} tab="experiment" />

            <div className="container-lg">
                <ComponentHeader
                    gameId={gameId}
                    url={'config/' + component.url}
                    id={component.id}
                    breadcrumb={[{ name: 'New Experiment' }]}
                >
                    <ProcessBlockedWrapper component={component.id}>
                        <button onClick={handleSave} className="btn btn-success rounded-pill px-4 shadow">
                            Create
                        </button>
                    </ProcessBlockedWrapper>
                </ComponentHeader>

                <div className="mt-4">
                    <div className="card shadow mb-4">
                        <div className="row card-body">
                            <div className="col-lg-6 col-md-12">
                                <Input
                                    required
                                    name="id"
                                    title="ID (key)"
                                    tooltip={
                                        <>
                                            REQUIRED
                                            <br />
                                            This is the key you'll pass to the Experiment component.
                                            <br />
                                            This key should be unique.
                                        </>
                                    }
                                    value={data.id}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <Input
                                    name="name"
                                    title="Display Name"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                                <TextArea
                                    name="description"
                                    rows={4}
                                    title="Description"
                                    value={data.description}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <TimeTrigger
                        onError={onError}
                        errorId={0}
                        mode="default"
                        className="shadow mt-4"
                        data={data}
                        setData={setData}
                    />

                    <ConditionsTrigger
                        onError={onError}
                        errorId={1}
                        className="shadow mt-4"
                        data={data}
                        setData={setData}
                    />

                    <VariantsSetup className="shadow mt-4" data={data} setData={setData} />
                    <ConfigsSetup className="shadow mt-4" data={data} setData={setData} />
                    <ContentSetup className="shadow mt-4" data={data} setData={setData} />
                </div>
            </div>
        </>
    );
};

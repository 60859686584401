import axios from 'axios';

export const getFileUsage = (gameId, filePath) => {
    return axios.get('/api/usage/' + gameId + '/storage/file?path=' + filePath);
};
export const getFolderUsage = (gameId, folderPath) => {
    return axios.get('/api/usage/' + gameId + '/storage/folder?path=' + folderPath);
};

export const getFilePathsUsage = (gameId, filePaths) => {
    return axios.get('/api/usage/' + gameId + '/storage?paths=' + filePaths.join(','));
};

export const getConfigUsage = (gameId, componentURL, configId) => {
    return axios.get('/api/usage/' + gameId + '/config/' + componentURL + '?id=' + configId);
};

export const getBundleUsage = (gameId, bundleId) => {
    return axios.get('/api/usage/ ' + gameId + ' /store-bundle?id=' + bundleId);
};

export const getConsumableUsage = (gameId, consumableId) => {
    return axios.get('/api/usage/ ' + gameId + ' /consumable?id=' + consumableId);
};
export const getNonConsumableUsage = (gameId, nonConsumableId) => {
    return axios.get('/api/usage/ ' + gameId + ' /non-consumable?id=' + nonConsumableId);
};

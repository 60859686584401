/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect, useState } from 'react';
import { utils } from '@gpg-web/utils';
import { Expression, CONDITION_ELEMENTS, validateConditions } from '../../components/Conditions';
import { Alert, useGame } from 'components';

const NEW_USERS_CONDITION_ID = 'authTime';

// const DISABLED_CONDITIONS = [NEW_USERS_CONDITION_ID];

const EXPERIMENT_CONDITION_ELEMENTS = CONDITION_ELEMENTS;
// .filter(
//     ({ id }) => DISABLED_CONDITIONS.indexOf(id) === -1
// );

export const ConditionsTrigger = (props) => {
    const { data, setData, onError, readOnly } = props;
    const [error, setError] = useState(null);
    const game = useGame();

    const conditions = data.conditions || null;

    const gameId = game.id;

    useEffect(() => {
        onError && onError(error);
        console.log(error);
    }, [error]);

    // const isExpressionsValid = !conditions.some((e) => !e.id || !e.op || !e.val);

    function validate(_conditions = conditions) {
        if (_conditions) {
            if (!_conditions.some((e) => !e.id || !e.op || !e.val)) {
                let validError = validateConditions(_conditions);

                if (validError) {
                    setError(validError);
                } else {
                    setError(null);
                }
            } else {
                setError('Please fill the condition');
            }
        }
    }

    function changeExpression(index, condition) {
        Object.assign(conditions[index], condition);
        setData({ ...data });
        validate();
    }

    function addNewExpression() {
        conditions.push({ id: '', op: '', val: '' });
        setData({ ...data });
        validate();
    }

    function removeExpression(index) {
        if (conditions.length === 1) return;
        conditions.splice(index, 1);
        setData({ ...data });
        validate();
    }

    return (
        <div className={utils.className('card position-static', props.className)}>
            <div className="card-header py-3 d-flex align-items-center">
                <h6 className="m-0 fw-bold text-primary">Conditions trigger</h6>
                <div className="form-check form-switch ms-auto form-check-reverse">
                    <input
                        className="form-check-input"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                utils.confirm(
                                    'Are you sure you want to disable conditions trigger? The current setup will be reset.',
                                    (yes) => {
                                        if (yes) {
                                            setData({ ...data, conditions: null });
                                            setError(null);
                                        }
                                    }
                                );
                            } else {
                                const emptyConditions = [{ id: '', op: '', val: '' }];
                                setData({ ...data, conditions: emptyConditions });
                                validate(emptyConditions);
                            }
                        }}
                        disabled={readOnly}
                        checked={!!data.conditions}
                        type="checkbox"
                        id="conditions-trigger-enabled"
                    />
                    <label className="form-check-label me-2" htmlFor="conditions-trigger-enabled">
                        Enable
                    </label>
                </div>
            </div>

            {!!data.conditions && (
                <div className="card-body">
                    <div className="conditions">
                        {conditions.map((condition, index) => {
                            return (
                                <Expression
                                    gameId={gameId}
                                    disabled={condition.id === 'authTime' && data.newUsers}
                                    readOnly={readOnly}
                                    isAllValid={!error}
                                    onRemove={removeExpression}
                                    onAdd={addNewExpression}
                                    conditions={conditions}
                                    onChange={changeExpression}
                                    key={condition.id + index}
                                    index={index}
                                    expression={condition}
                                    customElements={EXPERIMENT_CONDITION_ELEMENTS}
                                    canRemove={conditions.length > 1}
                                />
                            );
                        })}
                        {conditions.length === 0 && (
                            <div className="col-4 mb-3">
                                <button
                                    onClick={addNewExpression}
                                    className="btn btn-primary btn-sm"
                                    disabled={readOnly}
                                >
                                    <i className="fas fa-plus me-1" /> Add new condition
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="mx-2 mt-4">{!!error && <Alert text={error} />}</div>
                </div>
            )}
        </div>
    );
};

import { utils } from '@gpg-web/utils';
import { AddressableFileOption } from './AddressableFileOption';
import { useGame } from 'components';
import DisabledConfigLabel from 'pages/game/components/DisabledConfigLabel';
import { useEffect, useState } from 'react';

const SHOW_LIMIT = 7;

export const UnityAddressablesView = (props) => {
    const { value, fileName, path, onChange } = props;

    const uploadPath = path.slice(0, path.indexOf(fileName));

    const game = useGame();
    const gameId = game.id;

    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (!value) onChange([]);
        else if (value === '::disabled') return;
        else if (typeof value !== 'object') onChange([]);
        else if (typeof value === 'object' && !Array.isArray(value)) onChange([]);
    }, [value, onChange]);

    const onChangeOption = (index, val) => {
        const prevValue = value || [];

        const nextValue = prevValue.slice();

        nextValue[index] = val;

        onChange(nextValue);
    };

    if (value === '::disabled') return <DisabledConfigLabel />;

    const files = value || [];

    const catalogIndex = files.findIndex(
        (file) => file.path.includes('catalog') && file.path.endsWith('.json')
    );

    const catalogHashIndex = files.findIndex(
        (file) => file.path.includes('catalog') && file.path.endsWith('.hash')
    );

    const hasCatalog = catalogIndex > -1 && catalogHashIndex > -1;

    const hasMore = files.length > SHOW_LIMIT;

    return (
        <div className={utils.className('mt-2 p-2', !props.fluid && 'storage-view-box')}>
            <AddressableFileOption
                name={catalogIndex}
                gameId={gameId}
                title={
                    <>
                        <i className="text-primary fas fa-clipboard-list me-2" />
                        Catalog JSON
                    </>
                }
                value={files[catalogIndex]}
                uploadPath={uploadPath}
                onChange={onChangeOption}
                readOnly={true}
            />
            <AddressableFileOption
                name={catalogHashIndex}
                gameId={gameId}
                title={
                    <>
                        <i className="text-primary fas fa-hashtag me-2" />
                        Catalog Hash
                    </>
                }
                value={files[catalogHashIndex]}
                uploadPath={uploadPath}
                onChange={onChangeOption}
                readOnly={true}
            />

            {hasCatalog && (
                <div className="mt-3">
                    <div>
                        <i className="text-primary fas fa-boxes me-2" />
                        Bundles
                    </div>
                    <div className="m-2 me-0">
                        {files.length > 2 ? (
                            files.slice(0, showMore ? files.length : SHOW_LIMIT).map((file, i) => {
                                if (i === catalogIndex || i === catalogHashIndex) return null;

                                // const title = utils.baseName(file.path);

                                return (
                                    <AddressableFileOption
                                        key={file.path}
                                        name={i}
                                        gameId={gameId}
                                        // title={title}
                                        canBrowse={false}
                                        value={file}
                                        readOnly={true}
                                        onChange={onChangeOption}
                                    />
                                );
                            })
                        ) : (
                            <span className="tex-muted">There are bundle files</span>
                        )}
                    </div>
                </div>
            )}
            {hasMore && !showMore && (
                <div className="btn btn-sm btn-light" onClick={() => setShowMore(true)}>
                    And {files.length - SHOW_LIMIT} more bundles...
                </div>
            )}
        </div>
    );
};

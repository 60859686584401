import { Tooltip } from './Tooltip';
import { utils } from '@gpg-web/utils';

const types = {
    new: (
        <span className="position-absolute bottom-50 end-0 mb-2 badge bg-dark p-1 border border-white pe-none">
            New
        </span>
    ),
    none: <></>
};

const layouts = {
    'default': 'z-6 position-absolute top-0 start-95 translate-middle',
    'inline-start': 'me-1',
    'inline-end': 'ms-1'
};

export const TooltipWrapper = (props) => {
    if (!props.content) return props.children;

    if (props.icon === false) {
        return (
            <Tooltip
                trigger={props.trigger || 'hover'}
                content={props.content}
                className={props.tooltipClassName}
            >
                {props.children}
            </Tooltip>
        );
    }

    const wrapperClasses = props.wrapperClasses || '';
    const layout = props.layout || 'default';
    const icon =
        props.icon || 'fas fa-info-circle border border-2 border-white bg-white text-dark rounded-circle';
    const size = props.size || 'sm';

    const component = types[props.type] || <i className={`${icon} fa-${size} ${layouts[layout]} pe-none`} />;

    return (
        <Tooltip
            trigger={props.trigger || 'hover'}
            content={props.content}
            className={props.tooltipClassName}
        >
            <div className={'position-relative d-inline-block ' + wrapperClasses}>
                {layout === 'inline-start' && component}
                <div className={utils.className(props.className, 'd-inline-block')}>{props.children}</div>
                {layout !== 'inline-start' && component}
            </div>
        </Tooltip>
    );
};

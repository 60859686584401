import { useCallback, useEffect, useState } from 'react';

import { unstable_useBlocker as useBlocker } from 'react-router-dom';

const message = 'Changes you made may not be saved.';

let allowRedirect = false;

const unlock = () => {
    allowRedirect = true;
};

const onBeforeUnload = (e) => {
    e.returnValue = message;
    return message;
};

export const usePageBlocker = (data) => {
    const [initialData, setInitialData] = useState(JSON.stringify(data));

    const isBlocked = JSON.stringify(data) !== initialData;

    const blocker = useBlocker(isBlocked);

    useEffect(() => {
        if (blocker.state === 'blocked' && !isBlocked) {
            blocker.reset();
        }

        if (blocker.state === 'blocked' && isBlocked) {
            if (allowRedirect || window.confirm(message)) {
                blocker.proceed();
                allowRedirect = false;
            }
        }
    }, [blocker, isBlocked]);

    useEffect(() => {
        if (isBlocked) {
            window.addEventListener('beforeunload', onBeforeUnload);
        } else {
            window.removeEventListener('beforeunload', onBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [isBlocked]);

    const updateInitialData = useCallback((data) => {
        setInitialData(JSON.stringify(data));
        return data;
    }, []);

    return [updateInitialData, unlock, isBlocked, JSON.parse(initialData), initialData];
};

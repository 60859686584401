import { utils, M } from '@gpg-web/utils';

import Extends from './Extends';
import TimeTrigger from './TimeTrigger';
import Loader from './Loader';
import Popup from './Popup';
import Hint from './Hint';
import Preview from './Preview';
import Confirm from './Confirm';
import Promt from './Promt';
import Alert from './Alert';

Object.assign(utils, Extends);

Object.assign(utils, {
    load: Loader,
    popup: Popup,
    hint: Hint,
    timeTrigger: TimeTrigger,
    preview: Preview,
    confirm: Confirm,
    promt: Promt,
    alert: Alert,
    hintError: function (err) {
        Hint(err, { alert: 'danger' });
    },
    hintOk: function (err) {
        Hint(err, { alert: 'success' });
    }
});

export default utils;

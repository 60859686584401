import { useContext } from 'react';
import Sidebar from 'react-sidebar';
import { Link } from 'react-router-dom';
import { gameContext } from 'services/game';
import gameIcon from '../img/game-icon.png';
import { str } from '@gpg-web/utils';

const AppSidebar = (props) => {
    let { game } = useContext(gameContext);

    return (
        <Sidebar
            sidebar={
                <ul
                    className="navbar-nav sidebar sidebar-dark accordion sidebar-navigation"
                    id="accordionSidebar"
                >
                    <hr className="sidebar-divider my-0" />

                    <li className="nav-item">
                        {game.id && (
                            <Link className="nav-link" to={'/game/' + game.id}>
                                <img
                                    className="icon-4 rounded-3"
                                    alt="App icon"
                                    src={game.group.picture || gameIcon}
                                />
                                <span className="sidebar-game-title ml-2">
                                    <div>{str.trim(game.group.name, 19)}</div>
                                    <div className="small">
                                        {game.name}
                                        {game.isProduction && (
                                            <div className="ms-2 badge text-dark bg-dark-subtle">
                                                Production
                                            </div>
                                        )}
                                    </div>
                                </span>
                            </Link>
                        )}
                    </li>

                    <li className="nav-item">
                        <div
                            className="nav-link collapsed"
                            type="button"
                            role="tab"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsePlayers"
                            aria-expanded="true"
                            aria-controls="collapsePlayers"
                        >
                            <i className="fas fa-fw fa-users"></i>&nbsp;
                            <span>Players</span>
                        </div>
                        <div
                            id="collapsePlayers"
                            className="collapse"
                            aria-labelledby="headingAssets"
                            data-bs-parent="#accordionSidebar"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to={'/game/' + game.id + '/players'}>
                                    Players
                                </Link>
                                <Link className="collapse-item" to={'/game/' + game.id + '/save-type'}>
                                    Save Types
                                </Link>
                                <h6 className="collapse-header">Configs:</h6>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/built-in/view/forced-update'}
                                >
                                    Forced Update
                                </Link>
                                <Link className="collapse-item" to={'/game/' + game.id + '/config/popups'}>
                                    Popup
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/daily-rewards'}
                                >
                                    Daily Reward
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/piggy-banks'}
                                >
                                    Piggy Bank
                                </Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <div
                            className="nav-link collapsed"
                            type="button"
                            role="tab"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseConfig"
                            aria-expanded="true"
                            aria-controls="collapseConfig"
                        >
                            <i className="fas fa-fw fa-random"></i>&nbsp;
                            <span>Configs</span>
                        </div>
                        <div
                            id="collapseConfig"
                            className="collapse"
                            aria-labelledby="headingAssets"
                            data-bs-parent="#accordionSidebar"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to={'/game/' + game.id + '/config/overview'}>
                                    Overview
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/experiments'}
                                >
                                    Experiment
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/game-balances'}
                                >
                                    Game Balance
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/time-limited-events'}
                                >
                                    Time Limite Event
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/time-limited-offers'}
                                >
                                    Time Limited Offer
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/translations'}
                                >
                                    Translation
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/config/built-in/view/unity-addressables'}
                                >
                                    Unity Addressables
                                </Link>
                                <Link className="collapse-item" to={'/game/' + game.id + '/config/patches'}>
                                    Patch
                                </Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <div
                            className="nav-link collapsed"
                            type="button"
                            role="tab"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseStore"
                            aria-expanded="true"
                            aria-controls="collapseStore"
                        >
                            <i className="fas fa-fw fa-store"></i>&nbsp;
                            <span>Economy</span>
                        </div>
                        <div
                            id="collapseStore"
                            className="collapse"
                            aria-labelledby="headingAssets"
                            data-bs-parent="#accordionSidebar"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to={'/game/' + game.id + '/config/stores'}>
                                    Store
                                </Link>
                                <Link className="collapse-item" to={'/game/' + game.id + '/config/products'}>
                                    Products
                                </Link>
                                <Link className="collapse-item" to={'/game/' + game.id + '/config/entities'}>
                                    Entities
                                </Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to={'/game/' + game.id + '/storage'}>
                            <i className="fas fa-fw fa-folder-open"></i>&nbsp;
                            <span>Storage</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <div
                            className="nav-link collapsed"
                            type="button"
                            role="tab"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseAutomation"
                            aria-expanded="true"
                            aria-controls="collapseAutomation"
                        >
                            <i className="fas fa-fw fa-robot"></i>&nbsp;
                            <span>Automation</span>
                        </div>
                        <div
                            id="collapseAutomation"
                            className="collapse"
                            aria-labelledby="headingAssets"
                            data-bs-parent="#accordionSidebar"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/automation/scripts'}
                                >
                                    Scripts Library
                                </Link>
                                <Link
                                    className="collapse-item"
                                    to={'/game/' + game.id + '/automation/processes'}
                                >
                                    Processes
                                </Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <div
                            className="nav-link collapsed"
                            type="button"
                            role="tab"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseAdminSettings"
                            aria-expanded="true"
                            aria-controls="collapseAdminSettings"
                        >
                            <i className="fas fa-fw fa-cogs"></i>&nbsp;
                            <span>Settings</span>
                        </div>
                        <div
                            id="collapseAdminSettings"
                            className="collapse"
                            aria-labelledby="headingAssets"
                            data-bs-parent="#accordionSidebar"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">Project:</h6>
                                <Link className="collapse-item" to={'/game/' + game.id + '/settings/game'}>
                                    General
                                </Link>
                                <h6 className="collapse-header">Admin:</h6>
                                <Link className="collapse-item" to={'/game/' + game.id + '/settings/iam'}>
                                    Users and permissions
                                </Link>
                                <Link className="collapse-item" to={'/game/' + game.id + '/settings/roles'}>
                                    Roles
                                </Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <div
                            className="nav-link collapsed"
                            type="button"
                            role="tab"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOther"
                            aria-expanded="true"
                            aria-controls="collapseOther"
                        >
                            <i className="fas fa-fw fa-ellipsis-h"></i>&nbsp;
                            <span>Other</span>
                        </div>
                        <div
                            id="collapseOther"
                            className="collapse"
                            aria-labelledby="headingAssets"
                            data-bs-parent="#accordionSidebar"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">Watch:</h6>
                                <Link className="collapse-item" to={'/game/' + game.id + '/logs'}>
                                    History Logs
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul>
            }
            open={props.open}
            docked={props.docked}
            onSetOpen={props.onSetOpen}
            sidebarClassName="sidebar-parent topbar-offset"
            contentClassName="topbar-offset"
            contentId="page-wrapper"
        >
            {props.children}
        </Sidebar>
    );
};

export default AppSidebar;

import { StorageView } from './StorageView';

export const CreateConfigView = ({ value }) => {
    return (
        <>
            <StorageView value={value.path} />
            <div className="mt-2 d-flex">
                Game Balance Config To Create -
                <div className="d-flex flex-column ms-2">
                    {Object.keys(value.configs).map((filePath) => {
                        const config = value.configs[filePath];

                        if (!config.create) return null;

                        return (
                            <span className="">
                                <span className="fw-bold">{config.name}</span> -{' '}
                                <span className="font-monospace small">{filePath}</span>
                            </span>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';
import { read as readSheet, utils as XLSXUtils } from 'xlsx';
import { fromJSON, toJSON, getInfo, InvalidKeyError } from './Utils';

function parseJSON(srcContent) {
    let content = {};

    const parsed = JSON.parse(srcContent);

    if (typeof parsed === 'object') {
        if (Array.isArray(parsed)) {
            for (let srcObj of parsed) {
                if (srcObj['Key']) {
                    const validatedKey = srcObj['Key'].trim();

                    if (!utils.isValidKeyName(validatedKey)) throw new InvalidKeyError(srcObj['Key']);

                    content[validatedKey] = {};

                    for (let k in srcObj) {
                        const langCode = utils.languageCode(k);

                        if (langCode) content[validatedKey][langCode] = srcObj[k];
                    }
                }
            }
        } else {
            for (let key in parsed) {
                if (typeof parsed[key] === 'object') {
                    const validatedKey = key.trim();

                    if (!utils.isValidKeyName(validatedKey)) throw new InvalidKeyError(key);

                    content[validatedKey] = {};

                    for (let lang in parsed[key]) {
                        if (typeof parsed[key][lang] === 'string') {
                            content[validatedKey][lang] = parsed[key][lang];
                        } else {
                            return null;
                        }
                    }
                } else {
                    return null;
                }
            }
        }
    }

    return content;
}

function parseSheet(result, source, prefix) {
    const { data, metadata } = result;

    for (let srcObj of source) {
        if (srcObj['Key']) {
            let key = srcObj['Key'];

            if (prefix) key = prefix + key;

            const validatedKey = key.trim();

            if (!utils.isValidKeyName(validatedKey)) throw new InvalidKeyError(key);

            data[validatedKey] = {};

            for (let k in srcObj) {
                if (k === 'Description') {
                    metadata[validatedKey] = { description: srcObj[k] };
                } else {
                    const langCode = utils.languageCode(k);

                    if (langCode) data[validatedKey][langCode] = srcObj[k];
                }
            }
        }
    }
}

async function parseSpreadSheet(file) {
    const content = {
        data: {},
        metadata: {}
    };

    const ab = await file.arrayBuffer();

    const wb = readSheet(ab);

    for (let sheetName of wb.SheetNames) {
        const ws = wb.Sheets[sheetName];
        const data = XLSXUtils.sheet_to_json(ws); // generate objects

        let prefix;
        if (wb.SheetNames.length > 1) prefix = sheetName + '/';

        parseSheet(content, data, prefix);
    }

    return content;
}

export async function FileImporter(file) {
    if (file instanceof FileList) file = file[0];

    if (!file) throw new Error('Please provide a file');

    let json,
        _content,
        metadata = {};

    if (file.type === 'application/json') {
        _content = await utils.load.file(file, 'text');
        json = parseJSON(_content);
    } else if (file.type === 'text/plain') {
        _content = await utils.load.file(file, 'text');
        json = toJSON(_content);
    } else if (file.type === 'text/csv not working, bad encoding') {
        _content = await parseSpreadSheet(file);
        json = _content.data;
        metadata = _content.metadata;
    } else if (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.oasis.opendocument.spreadsheet' ||
        file.name.endsWith('.ods')
    ) {
        _content = await parseSpreadSheet(file);
        json = _content.data;
        metadata = _content.metadata;
    } else {
        throw new Error('Unsupported file format');
    }

    if (!json)
        throw new Error(
            "Can't find any translation in this file, please check this file format with developer again."
        );

    const keys = Object.keys(json);

    if (keys.length === 0)
        throw new Error(
            "Can't find any translation in this file, please check this file format with developer again."
        );

    const info = getInfo(json);
    const content = fromJSON(json);

    return { content, info, json, metadata };
}

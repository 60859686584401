/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { migrateStorage } from 'services/storage';
import { getGames } from 'services/game';
import { Alert, Checkbox } from 'components';
import { BrowseStorage } from './BrowseStorage';
import { utils } from '@gpg-web/utils';
import { Modal, Spinner } from '@gpg-web/react';
import AutomationProcessModal from '../../../modals/AutomationProcess';

const ImportData = (props) => {
    let { game, show, onHide, onFinish, dstPath } = props;

    const [games, setGames] = useState(null);
    const [gameSrc, setGameSrc] = useState('');
    const [browsingStorage, setBrowsingStorage] = useState(false);
    const [srcPath, setSrcPath] = useState('');
    const [lastVersionsOnly, setLastVersionsOnly] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sameFileAction, setSameFileAction] = useState('ignore');
    const [error, setError] = useState(null);
    const [yesContinue, setYesContinue] = useState(false);

    const groupId = game.group && game.group.id;
    const gameId = game.id;

    const catchError = (err) => {
        utils.hintError(err);
        setLoading(false);
    };

    useEffect(() => {
        // if (games) return;

        if (groupId) {
            setLoading(true);
            getGames(groupId)
                .then((_games) => {
                    _games = _games.filter((e) => e.id !== gameId);

                    setGames(_games);
                    // setGameSrc(_games[0] && _games[0].id);
                    setLoading(false);
                })
                .catch(catchError);
        }
    }, [groupId, gameId]);

    const migrate = () => {
        setError(null);

        if (!gameSrc) return setError('Please select source game stage!');

        utils.popup('Importing ...');

        const options = {
            gameSrc: gameSrc,
            lastVersionsOnly: lastVersionsOnly,
            sameFileAction: sameFileAction,
            dstPath,
            srcPath
        };

        migrateStorage(gameId, options)
            .then((result) => {
                utils.popup('hide');

                _onHide();
                onFinish();

                result.gameId = gameId;
                result.name = 'Migration';
                AutomationProcessModal.show(result);
            })
            .catch(setError);
    };

    const _onHide = () => {
        setLoading(false);
        setError(null);
        setSrcPath('');
        setYesContinue(false);
        setLastVersionsOnly(false);
        setSameFileAction('ignore');
        onHide();
    };

    return (
        <Modal show={show} size="xl" onHide={_onHide} title="Importing options">
            <div className="modal-body px-4">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <label className="mb-1" htmlFor="migrate-select-env">
                            Select Source Stage
                        </label>
                        <select
                            disabled={!games || games.length === 0}
                            id="migrate-select-env"
                            value={gameSrc}
                            onChange={(e) => setGameSrc(e.target.value)}
                            className="form-select"
                        >
                            <option disabled value="">
                                Select Source Stage
                            </option>
                            {games &&
                                games.map((e) => (
                                    <option key={e.id} value={e.id}>
                                        {e.name}
                                    </option>
                                ))}

                            {!!games && games.length === 0 && <option value="null">List is empty</option>}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-6"></div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-6">
                        <label className="mb-1">From where to copy (Source folder)</label>
                        <div className="d-flex align-items-center">
                            <input className="form-control" placeholder="__root__" disabled value={srcPath} />
                            <button
                                disabled={!gameSrc}
                                onClick={(e) => setBrowsingStorage(!browsingStorage)}
                                className="btn text-warning btn-sm ms-2 me-3"
                            >
                                <i className="fas fa-folder-open" />
                            </button>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div>
                            <label className="mb-1">Where to copy (Destination folder)</label>
                            <input className="form-control" placeholder="__root__" disabled value={dstPath} />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    {browsingStorage && (
                        <div className="mb-5 shadow">
                            <BrowseStorage
                                foldersOnly
                                onSelect={(e) => setSrcPath(e.path)}
                                onCancel={(e) => setBrowsingStorage(false)}
                                gameId={gameSrc}
                            />
                        </div>
                    )}
                </div>
                <div className="row mt-3 py-3 border-bottom">
                    <div className="col-sm-12 col-md-6">
                        <Checkbox
                            title="Import only last file version"
                            value={lastVersionsOnly}
                            onChange={(e) => setLastVersionsOnly(e.target.checked)}
                        />
                    </div>
                </div>

                <div className="row py-3 border-bottom">
                    <div className="mb-2">What to do with Files of the same name?</div>
                    <div className="col-md-2 col-sm-12">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                name="same_file_ignore_check"
                                type="radio"
                                id="same_file_ignore_check"
                                onChange={(e) => setSameFileAction('ignore')}
                                checked={sameFileAction === 'ignore'}
                            />
                            <label className="form-check-label small" htmlFor="same_file_ignore_check">
                                Ignore them
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                name="same_file_merge_check"
                                type="radio"
                                id="same_file_merge_check"
                                onChange={(e) => setSameFileAction('merge')}
                                checked={sameFileAction === 'merge'}
                            />
                            <label className="form-check-label small" htmlFor="same_file_merge_check">
                                Merge them
                            </label>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    {loading && (
                        <div>
                            <Spinner /> Loading ...
                        </div>
                    )}
                    {!loading && !!gameSrc && !srcPath && (
                        <div className="alert alert-warning" role="alert">
                            <h5 className="alert-heading">
                                <i className="fas fa-exclamation-triangle me-2" />
                                Selected root folder as source folder
                            </h5>
                            <hr />
                            <p className="mb-3">
                                You are trying to migrate the entire Storage from Source Environment
                                <br />
                                This operation is considered as heavy one.
                                <br />
                                Are you sure you want to continue?
                            </p>
                            <Checkbox
                                title="Yes, i want migrate the entire Storage"
                                value={yesContinue}
                                onChange={(e) => setYesContinue(e.target.checked)}
                            />
                        </div>
                    )}
                    {error && <Alert text={error} />}
                </div>
            </div>
            <div className="modal-footer">
                <button data-bs-dismiss="modal" className="btn btn-secondary btn-sm">
                    Cancel
                </button>
                <button
                    disabled={loading || !gameSrc || (!!gameSrc && !srcPath && !yesContinue)}
                    onClick={migrate}
                    className="btn btn-success btn-sm"
                >
                    Start importing
                </button>
            </div>
        </Modal>
    );
};

export default ImportData;

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { createConfig } from 'services/config';
import {
    InputOption,
    Alert,
    useModalBlocker,
    BoolOption,
    useGame,
    Spinner,
    ImagePathOption,
    JSONOption,
    TextOption,
    TooltipWrapper
} from 'components';
import { useComponent } from '../hooks/UseComponent';
import { utils } from '@gpg-web/utils';
import { Modal } from '@gpg-web/react';
import { useQueryClient } from '@tanstack/react-query';
import CreatableSelect from 'react-select/creatable';
import { DefinedEntityTypes } from 'consts';

const defaultData = () => ({
    id: '',
    type: '',
    isConsumable: true,
    name: '',
    description: '',
    icon: '',
    customData: ''
});

export const EntityCreateModal = (props) => {
    const { onHide, show } = props;
    const [data, setData] = useState(defaultData());
    const component = useComponent('entity');

    const [saving, setSaving] = useState(false);
    const [saveBlock, setSaveBlock] = useState('00');
    const [error, setError] = useState(null);
    const [, onBeforeClose] = useModalBlocker(data);
    const queryClient = useQueryClient();
    const game = useGame();
    const gameId = game.id;

    const reset = () => {
        setError(null);
        setSaving(false);
        setData(defaultData());
        setSaveBlock('00');

        if (show) {
            onHide();
        }
    };

    const handleChange = (name, value) => setData({ ...data, [name]: value });

    async function handleSave() {
        try {
            // if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

            let id = data.id.trim();

            if (!id) return setError('ID field is required');

            if (!utils.isValidId(id)) {
                return setError('ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed');
            }

            data.id = id;
            setSaving(true);
            await createConfig(gameId, component.url, data);

            queryClient.refetchQueries({
                queryKey: [component.id, gameId],
                exact: true
            });

            onHide();
        } catch (err) {
            setSaving(false);
            setError(err);
        }
    }

    return (
        <Modal title="New entity" show={show} onHide={reset} onBeforeClose={onBeforeClose}>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <InputOption
                            id="entity-create-id"
                            name="id"
                            required
                            tooltip="REQUIRED: specify unique entity identifier"
                            title="ID"
                            value={data.id}
                            onChange={handleChange}
                        />
                        <div className="mb-4">
                            <TooltipWrapper
                                layout="inline-start"
                                size="xs"
                                content="Visible to Livefire only"
                            >
                                <label className="mb-1 small">Type</label>
                            </TooltipWrapper>

                            <CreatableSelect
                                value={{ value: data.type, label: data.type }}
                                name="type"
                                isClearable={false}
                                placeholder="Select or create entity type"
                                onChange={(e) => handleChange('type', e.value)}
                                options={DefinedEntityTypes.map((e) => ({ value: e, label: e }))}
                                className="react-select"
                                classNamePrefix="select"
                            />
                        </div>
                        <BoolOption
                            id="entity-create-isconsumable"
                            tooltip="Is entity consumable (either by amount or time) or permanent such as NoAds"
                            name="isConsumable"
                            className="mb-3"
                            title="Is consumable"
                            value={data.isConsumable}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <InputOption
                            id="entity-create-name"
                            name="name"
                            title="Display name"
                            tooltip="Visible to Livefire only"
                            value={data.name}
                            onChange={handleChange}
                        />
                        <TextOption
                            id="entity-create-description"
                            name="description"
                            title="Description"
                            tooltip="Visible to Livefire only"
                            value={data.description}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-12 mt-1">
                        <ImagePathOption
                            key={show}
                            onBlock={(e) => setSaveBlock(saveBlock[0] + Number(e))}
                            name="icon"
                            gameId={gameId}
                            tooltip="Visible to Livefire only"
                            title="Display icon"
                            value={data.icon}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div>
                    <JSONOption
                        key={show}
                        onBlock={(e) => setSaveBlock(Number(e) + saveBlock[1])}
                        value={data.customData}
                        title="Custom data"
                        name="customData"
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="modal-footer py-3">
                {error && <Alert text={error} />}
                <button
                    disabled={saving}
                    className="btn rounded-pill ms-auto shadow btn-light px-3"
                    data-bs-dismiss="modal"
                >
                    Cancel
                </button>

                <button
                    disabled={saving || saveBlock !== '00'}
                    onClick={handleSave}
                    className="ms-3 btn rounded-pill shadow btn-success px-4"
                >
                    {saving && <Spinner className="me-2" />}Create
                </button>
            </div>
        </Modal>
    );
};

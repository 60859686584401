import { Link } from 'react-router-dom';
import { useMember } from 'components';
import { date } from '@gpg-web/utils';

export const ConfigReadOnlyInfo = ({ data }) => {
    const updatedBy = useMember(data && data.updatedBy);
    const createdBy = useMember(data && data.createdBy);

    return (
        <>
            <div className="mb-3 border-bottom font-monospace pb-2">ID: {data.id}</div>
            {data.updatedAt && (
                <div className="mt-4 small">
                    <span>Last Modified - </span>
                    <span>{date(data.updatedAt)}</span>
                    {updatedBy && (
                        <div className="text-muted mt-2 d-flex align-items-center">
                            by
                            <img
                                alt=""
                                className="ms-2 me-1 rounded-circle"
                                src={updatedBy.picture}
                                width="20"
                            />
                            <Link to={updatedBy.url} className="text-muted text-decoration-none">
                                {updatedBy.name}
                            </Link>
                        </div>
                    )}
                </div>
            )}
            {data.createdAt && (
                <div className="mt-4 small">
                    <span>Created - </span>
                    <span>{date(data.createdAt)}</span>
                    {createdBy && (
                        <div className="text-muted mt-2 d-flex align-items-center">
                            by
                            <img
                                alt=""
                                className="ms-2 me-1 rounded-circle"
                                src={createdBy.picture}
                                width="20"
                            />
                            <Link to={createdBy.url} className="text-muted text-decoration-none">
                                {createdBy.name}
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

import { Navigate, useParams } from 'react-router-dom';
import { useUserIAM } from 'components';
import Skeleton from 'react-loading-skeleton';
import { useContext } from 'react';
import { organizationContext } from 'services/organization';

export const MissingPermissionsPage = () => {
    const params = useParams();

    const isGameSelected = params.id;

    const searchParams = new URLSearchParams(window.location.search);

    const missingPermissionsStr = searchParams.get('permissions') || '';

    const missingPermissions = missingPermissionsStr.split(',');

    const { userPermissions, iam, roles } = useUserIAM();

    const { members } = useContext(organizationContext);

    if (missingPermissions.length === 0 || missingPermissions[0].length === 0) {
        return <Navigate to={'/missing-permissions-not-found'} />;
    }

    if (isGameSelected && (!userPermissions || !roles)) return <PageLoadingSkeleton />;

    let neededPermissions = missingPermissions;

    const neededRoles = [];

    let owners = [];

    let membersList = members || [];

    if (isGameSelected) {
        neededPermissions = missingPermissions.filter((p) => userPermissions.indexOf(p) === -1);

        for (let roleKey in roles) {
            const role = roles[roleKey];

            if (neededPermissions.every((p) => role.permissions.indexOf(p) > -1)) {
                neededRoles.push(roleKey);
            }
        }

        if (neededRoles.length === 0) {
            return <Navigate to={'/missing-permissions-not-found'} />;
        }

        owners = iam.filter((o) => o.role === 'owner').map((o) => o.principal);
    }

    return (
        <div className="container-lg p-3">
            <div className="alert alert-danger d-flex align-items-center px-4 mb-3" role="alert">
                <i className="fas fa-exclamation-triangle" />
                <div className="ms-3">You do not have sufficient permissions to perform this action</div>
            </div>

            <div className="p-1 mb-3">
                <h5>You are missing the following required permissions:</h5>
                <ul>
                    {missingPermissions.map((p, i) => (
                        <li key={i}>
                            <pre className="m-0">{p}</pre>
                        </li>
                    ))}
                </ul>
            </div>
            {isGameSelected && (
                <>
                    <div className="p-1 mb-3">
                        <h5>You need to have one of these roles:</h5>
                        <ul>
                            {neededRoles.map((p, i) => (
                                <li key={i}>{roles[p].title}</li>
                            ))}
                        </ul>
                    </div>

                    <div className="p-1 mb-3">
                        <h5>You can contact one of the following people to obtain needed role:</h5>
                        <ul>
                            {owners.map((p, i) => {
                                const member = membersList.find((m) => m.email === p);

                                return (
                                    <li key={i}>
                                        {member ? `${member.name} - ` : ''}
                                        <a href={`mailto: ${p}`}>{p}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

const PageLoadingSkeleton = () => {
    return (
        <div className="container-lg p-3">
            <Skeleton className="service-card shadow-sm mb-3" width="100%" height={50} />
            <Skeleton className="service-card shadow-sm mb-3" width="100%" height={25} />

            <ul>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
            </ul>
            <Skeleton className="service-card shadow-sm mb-3" width="100%" height={25} />

            <ul>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
            </ul>
            <Skeleton className="service-card shadow-sm mb-3" width="100%" height={25} />

            <ul>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
                <li>
                    <Skeleton className="service-card shadow-sm " width="25%" />
                </li>
            </ul>
        </div>
    );
};

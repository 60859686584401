export const getStatus = (data) => {
    let status = 'ongoing';

    if (data.finished) {
        if ((data.errors && data.errors.length > 0) || data.hasErrors) status = 'errors';
        else if ((data.warnings && data.warnings.length > 0) || data.hasWarnings) status = 'warnings';
        else status = 'finished';
    }

    return status;
};

export const Statuses = {
    ongoing: (
        <span className="badge text-secondary-emphasis d-flex align-items-center lh-base">
            <span className="spinner-border spinner-border-sm me-2" role="status" /> Ongoing
        </span>
    ),
    finished: (
        <span className="badge bg-success">
            <i className="fas fa-check me-1" /> Finished
        </span>
    ),
    errors: (
        <span
            title="Finished with errors"
            className="badge bg-danger-subtle text-danger-emphasis border border-danger-subtle"
        >
            <i className="fas fa-exclamation-circle me-1" /> Finished
        </span>
    ),
    warnings: (
        <span
            title="Finished with warnings"
            className="badge bg-warning-subtle text-warning-emphasis border border-warning-subtle"
        >
            <i className="fas fa-exclamation-triangle me-1" /> Finished
        </span>
    )
};

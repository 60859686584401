/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import axios from 'axios';

export const getDocs = () => {
    return axios.get('/api/docs/all');
};

export const addDoc = (doc) => {
    return axios.post('/api/docs', doc);
};

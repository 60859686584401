/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import { Status } from 'consts';

const StatusSetup = (props) => {
    const { value, onChange, disabled, hide } = props;

    return (
        <div className="d-flex">
            {Object.keys(Status).map((e) => {
                if (hide && hide[e]) return null;
                return (
                    <span key={e} className="mx-1">
                        <input
                            disabled={disabled && disabled[e]}
                            checked={value === e}
                            onChange={(el) => {
                                if (el.target.checked) {
                                    onChange(e);
                                }
                            }}
                            type="checkbox"
                            className="btn-check"
                            id={'variation_admin_status_' + e}
                            autoComplete="off"
                        />

                        <label
                            className="btn btn-sm btn-outline-primary"
                            htmlFor={'variation_admin_status_' + e}
                        >
                            {Status[e]}
                        </label>
                    </span>
                );
            })}
        </div>
    );
};

export { StatusSetup };

import { DailyRewardView } from './DailyReward';
import { JSONView } from './JSON';
import { PiggyBankView } from './PiggyBank';
import { PopupView } from './Popup';
import { StoreView } from './Store';
import { TLEView } from './TLE';
import { TextView } from './Text';
import { HTMLView } from './HTML';
import { TranslationView } from './Translation';
import { PatchView } from './Patch';
import { UnityAddressablesView } from './UnityAddressables';

export const Views = {
    'json': JSONView,
    'text': TextView,
    'html': HTMLView,
    'livefire.store': StoreView,
    'livefire.daily-reward': DailyRewardView,
    'livefire.piggy-bank': PiggyBankView,
    'livefire.popup': PopupView,
    'livefire.tle': TLEView,
    'livefire.tlo': TLEView,
    'livefire.translation': TranslationView,
    'livefire.patch': PatchView,
    'livefire.unity-addressables': UnityAddressablesView
};

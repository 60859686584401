/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect, useState, useRef, useMemo } from 'react';

import DisabledConfigLabel from '../../components/DisabledConfigLabel';
import {
    EntitiesUsageOption,
    EntityUsage,
    useGame,
    useEnvironmental,
    TooltipWrapper,
    JSONOption,
    useValidation
} from 'components';

import { NumberOption, StorageOption, BoolOption, ComponentOption } from '../../components/contentOptions';
import { ComponentsMap } from 'consts';
import { getConfig, getConfigs } from '../../../../services/config';
import { utils } from '@gpg-web/utils';
import { getStorageFileContent } from '../../../../services/storage';
import { toJSON } from './Translation/Utils';
import Select, { components as selectComponents } from 'react-select';
import Skeleton from 'react-loading-skeleton';

export const DefaultContentValue = JSON.stringify({
    texts: {},
    priority: 1,
    notShowAgain: true,
    discardButton: true,
    assets: null,
    showCount: 1,
    showCountEnabled: true,
    rewards: [],
    customData: ''
});

const ContentValidationConfig = {
    rewards: {
        custom: {
            isValid: (val) => {
                const rewards = val || [];
                for (let reward of rewards) {
                    if (reward.isConsumable) {
                        if (typeof reward.amount === 'undefined' && typeof reward.time === 'undefined') {
                            return false;
                        }
                        if (typeof reward.amount !== 'undefined' && reward.amount <= 0) {
                            return false;
                        }
                        if (typeof reward.time !== 'undefined' && reward.time <= 0) {
                            return false;
                        }
                    }
                }

                return true;
            },
            message: 'Reward amount and/or time should be a positive number'
        }
    }
};

const getDefaultValue = () => JSON.parse(DefaultContentValue);

const component = ComponentsMap['translation'];

const PopupView = (props) => {
    const { value, editing, onChange, gameVersion, environment, onError } = props;

    const game = useGame();
    const gameId = game.id;
    const { errors, validate } = useValidation(ContentValidationConfig);
    /**
     * Validation start
     */
    useEffect(() => {
        if (!value) onChange(getDefaultValue());
        else if (value === '::disabled') return;
        else if (typeof value !== 'object') onChange(getDefaultValue());
        else if (typeof value === 'object' && Object.keys(value).length === 0) onChange(getDefaultValue());
        else if (typeof value === 'object' && !Array.isArray(value.rewards))
            onChange({ ...value, rewards: [] });
        else if (value) {
            const isValid = validate(value);
            onError(!isValid);
        }
    }, [value, onChange, validate, onError]);

    const [translationConfig, setTranslationConfig] = useState(null);
    const [translationData, setTranslationData] = useState({});

    const translationId = value && value.translationId ? value.translationId : null;

    const gameVersionRef = useRef(gameVersion);

    const environmentRef = useRef(environment);

    const {
        environments,
        gameVersions,
        selectedEnvironment,
        selectedGameVersion,
        setSelectedEnvironment,
        setSelectedGameVersion
    } = useEnvironmental();

    if (gameVersion) {
        gameVersionRef.current = gameVersion;
    } else {
        gameVersionRef.current = selectedGameVersion;
    }

    if (environment) {
        environmentRef.current = environment;
    } else {
        environmentRef.current = selectedEnvironment;
    }

    useEffect(() => {
        if (!gameId) return;

        setTranslationConfig(null);

        if (translationId) {
            getConfigs(gameId, component.url)
                .then((configs) => {
                    if (configs.find((c) => c.id === translationId)) {
                        getConfig(gameId, component.url, translationId)
                            .then((config) => setTranslationConfig(config))
                            .catch(utils.hintError);
                    }
                })
                .catch(utils.hintError);
        }
    }, [gameId, translationId]);

    useEffect(() => {
        if (!gameId) return;

        const ver = gameVersionRef.current;
        const env = environmentRef.current;

        if (translationConfig && ver && env) {
            setTranslationData(null);

            const content = translationConfig.content;
            if (
                content.path &&
                content.versions[env + ver + '/'] &&
                content.versions[env + ver + '/'].version
            ) {
                getStorageFileContent(gameId, content.path, content.versions[env + ver + '/'].version)
                    .then((fileContent) => {
                        try {
                            setTranslationData(toJSON(fileContent, {}));
                        } catch (err) {
                            setTranslationData({});
                            utils.hintError('Translation file parsing error');
                        }
                    })
                    .catch((err) => {
                        utils.hintError(err);
                        setTranslationData({});
                    });
            } else {
                setTranslationData({});
            }
        }
    }, [gameId, gameVersionRef, environmentRef, translationConfig]);

    /**
     * Validation end
     */
    if (value === '::disabled') return <DisabledConfigLabel />;

    const onChangeOption = (key, val) => {
        const prevValue = value || getDefaultValue();

        const nextValue = { ...prevValue, [key]: val };

        const isValid = validate(nextValue);

        onError(!isValid);

        onChange(nextValue);
    };

    let {
        texts,
        priority,
        notShowAgain,
        discardButton,
        assets,
        showCount,
        showCountEnabled,
        customData,
        rewards
    } = value || {};
    texts = texts || {};

    rewards = Array.isArray(rewards) ? rewards : [];

    const versionDataReady = environments && gameVersions;

    return (
        <div className="mt-4 pt-2 mx-2">
            <div className="d-flex mb-3 mt-2">
                <ComponentOption
                    title={'Translation config'}
                    placeholder={'Select translation'}
                    name={'translationId'}
                    value={translationId}
                    readOnly={!editing}
                    gameId={gameId}
                    componentId={component.id}
                    onChange={onChangeOption}
                />

                {!gameVersion || !environment ? (
                    <div className="ms-auto">
                        <TooltipWrapper
                            content={
                                'This is an optional field. It is used to display proper translation for text fields when viewing popup file outside of component scope'
                            }
                        >
                            <div className="d-flex my-1 align-items-center text-muted">
                                <select
                                    value={environmentRef.current}
                                    className="form-select form-select-sm w-150px"
                                    onChange={(e) => setSelectedEnvironment(e.target.value)}
                                >
                                    {versionDataReady ? (
                                        environments.map((e) => (
                                            <option key={e.id} value={e.id}>
                                                {e.name || e.id}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>
                                            Loading...
                                        </option>
                                    )}
                                </select>
                                <select
                                    value={gameVersionRef.current}
                                    className="form-select form-select-sm ms-3 w-150px"
                                    onChange={(e) => setSelectedGameVersion(e.target.value)}
                                >
                                    {versionDataReady ? (
                                        gameVersions.map((e) => (
                                            <option key={e.id} value={e.id}>
                                                {e.id}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>
                                            Loading...
                                        </option>
                                    )}
                                </select>
                            </div>
                        </TooltipWrapper>
                    </div>
                ) : null}
            </div>
            {!!translationConfig && !!translationData && Object.keys(translationData).length === 0 && (
                <div className="text-warning small align-items-center d-flex ms-2">
                    {' '}
                    <i className="fas fa-exclamation-triangle me-1" /> Translation is not setup for current
                    environment and version
                </div>
            )}
            <div className="my-5 mx-2 d-flex justify-content-between flex-wrap">
                <div className="my-3 mx-auto z-2">
                    <h6 className="fw-bold text-center">Popup</h6>
                    <div className="card" style={{ width: '400px' }}>
                        <div className="card-header d-flex align-items-center py-2">
                            <i className="fas fa-info-circle text-primary fa-lg"></i>
                            <div className="mx-3 w-100">
                                <TranslationTextOption
                                    title={'Title'}
                                    name={'title'}
                                    value={texts.title}
                                    onChange={(name, val) =>
                                        onChangeOption('texts', { ...texts, [name]: val })
                                    }
                                    readOnly={!editing}
                                    translationData={translationData}
                                />
                            </div>
                            <i className="fas fa-times-circle fa-lg"></i>
                        </div>
                        <div className="card-body">
                            <TranslationTextOption
                                title={'Text'}
                                name={'text'}
                                value={texts.text}
                                onChange={(name, val) => onChangeOption('texts', { ...texts, [name]: val })}
                                readOnly={!editing}
                                translationData={translationData}
                            />
                            <Skeleton enableAnimation={false} count={5}></Skeleton>
                            <div className="p-2">
                                <div className="small fw-bold text-primary mb-2">Rewards</div>
                                {rewards.length === 0 ? (
                                    <div className="small text-gray-500">Nothing specified</div>
                                ) : (
                                    <>
                                        {rewards.map((e, index) => (
                                            <EntityUsage
                                                key={e.id + index}
                                                className="mt-2"
                                                gameId={gameId}
                                                value={e}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                                <div className="custom-btn" style={{ minWidth: '300px' }}>
                                    <TranslationTextOption
                                        title={'Button'}
                                        name={'button'}
                                        value={texts.button}
                                        onChange={(name, val) =>
                                            onChangeOption('texts', { ...texts, [name]: val })
                                        }
                                        readOnly={!editing}
                                        translationData={translationData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-3 mx-auto z-1">
                    <h6 className="fw-bold text-center">Info</h6>
                    <div className="card border-primary-subtle" style={{ width: '400px' }}>
                        <div className="card-header border-primary-subtle d-flex align-items-center py-2">
                            <div className="mx-5 w-100">
                                <TranslationTextOption
                                    title={'Title'}
                                    value={texts.info_title}
                                    name={'info_title'}
                                    onChange={(name, val) =>
                                        onChangeOption('texts', { ...texts, [name]: val })
                                    }
                                    readOnly={!editing}
                                    translationData={translationData}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            <TranslationTextOption
                                title={'Text'}
                                name={'info_text'}
                                value={texts.info_text}
                                onChange={(name, val) => onChangeOption('texts', { ...texts, [name]: val })}
                                readOnly={!editing}
                                translationData={translationData}
                            />
                            <Skeleton enableAnimation={false} count={3}></Skeleton>
                        </div>
                    </div>
                </div>
            </div>

            {editing && (
                <div className="my-4">
                    <EntitiesUsageOption
                        title="Rewards"
                        gameId={gameId}
                        name="rewards"
                        readOnly={!editing}
                        value={rewards}
                        error={errors['rewards']}
                        onChange={onChangeOption}
                    />
                </div>
            )}
            <div className="mt-5">
                <div className="my-4">
                    <StorageOption
                        gameId={gameId}
                        title={'Pop-up Art'}
                        name={'assets'}
                        value={assets}
                        readOnly={!editing}
                        onChange={onChangeOption}
                    />
                </div>

                <div className="d-flex justify-content-between my-2">
                    <BoolOption
                        title={'Discard Button'}
                        name="discardButton"
                        value={discardButton}
                        readOnly={!editing}
                        onChange={onChangeOption}
                    />
                    <BoolOption
                        title={"Don't show again"}
                        name="notShowAgain"
                        value={notShowAgain}
                        readOnly={!editing}
                        onChange={onChangeOption}
                    />
                    <NumberOption
                        title={'Priority'}
                        name="priority"
                        onChange={onChangeOption}
                        value={priority}
                        readOnly={!editing}
                    />
                </div>

                <div className="d-flex justify-content-end my-2">
                    <NumberOption
                        title={'Max Show Count'}
                        name="showCount"
                        onChange={onChangeOption}
                        value={showCount}
                        readOnly={!editing}
                        disabled={!showCountEnabled}
                    >
                        <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            name="showCountEnabled"
                            disabled={!editing}
                            checked={!!showCountEnabled}
                            onChange={(e) => onChangeOption('showCountEnabled', e.target.checked)}
                        />
                    </NumberOption>
                </div>

                <div className="mt-2">
                    <JSONOption
                        value={customData}
                        onChange={onChangeOption}
                        readOnly={!editing}
                        title="Custom Data"
                        name="customData"
                    />
                </div>
            </div>
        </div>
    );
};

export { PopupView };

const CustomInput = (props) => {
    const { inputValue, translation, error, title } = props.selectProps;

    const showTranslation = translation && !!inputValue.length;

    const hasTranslation = showTranslation && translation[inputValue];

    return (
        <>
            <div style={{ gridArea: '1/1/1/3' }} className={'fw-bold'}>
                {title}
            </div>
            <selectComponents.Input {...props} isHidden={false} isDisabled={false} />
            {showTranslation && (
                <div className={'small ' + (hasTranslation ? 'text-muted fw-bold' : 'text-warning')}>
                    {hasTranslation ? (
                        'Translation: ' + translation[inputValue]['en']
                    ) : (
                        <>
                            <i className="fas fa-exclamation-triangle me-1" />
                            {error ? error : 'No translation for this key'}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

const CustomMenuList = ({ children, ...props }) => {
    return (
        <selectComponents.MenuList {...props}>
            {Array.isArray(children) ? children.slice(0, props.selectProps.maxOptions) : children}
        </selectComponents.MenuList>
    );
};

export const TranslationTextOption = ({ title, name, value, onChange, readOnly, translationData }) => {
    const hasValue = !!value;

    const options = useMemo(() => {
        let list = Object.keys(translationData || {});

        // if (hasValue && value !== '') {
        //     list = list.filter((opt) => opt !== value);
        // }

        list = list.map((opt) => ({ value: opt, label: opt }));

        return list;
    }, [translationData]);

    return (
        <div className="my-1">
            <div className={'small w-100'}>
                <div className="w-100">
                    <Select
                        title={title}
                        isLoading={!translationData}
                        options={options}
                        isSearchable={true}
                        value={null}
                        placeholder={'No translations key selected'}
                        classNamePrefix="react-select"
                        escapeClearsValue={false}
                        inputValue={hasValue ? value : ''}
                        noOptionsMessage={() => null}
                        translation={translationData}
                        isDisabled={readOnly}
                        // isOptionDisabled={readOnly}
                        styles={{
                            menu: (base) => ({ ...base, zIndex: 999999 }),
                            input: (base, props) => ({ ...base, gridArea: '2/1/3/3' }),
                            placeholder: (base, props) => ({ ...base, gridArea: '2/1/3/3' })
                        }}
                        maxOptions={20}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: () => null,
                            Input: CustomInput,
                            MenuList: CustomMenuList
                        }}
                        onChange={(e) => onChange(name, e.value)}
                        onInputChange={(query, { action }) => {
                            if (action === 'input-change') {
                                onChange(name, query.length ? query : undefined);

                                return query;
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

import { ProcessBlockedWrapper } from '../wrappers';

export const MigrateFromButton = (props) => {
    return (
        <ProcessBlockedWrapper component={props.component}>
            <button onClick={() => props.onClick(true)} className="btn btn-sm btn-primary px-3 shadow-sm">
                <i className="fas fa-angle-double-down me-1" /> Migrate From
            </button>
        </ProcessBlockedWrapper>
    );
};

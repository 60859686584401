/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect, useMemo } from 'react';

import DisabledConfigLabel from '../../components/DisabledConfigLabel';
import { useGame, EntityListItem } from 'components';
import { utils } from '@gpg-web/utils';
import Select from 'react-select';

import { RadioOption } from '../../components/contentOptions';
import { getConfigs } from 'services/config';
import { ComponentsMap } from 'consts';
import { useQuery } from '@tanstack/react-query';

const DailyRewardTypes = {
    A: 'Daily Reward resets if the player misses a day OR reaches the last reward',
    B: 'Daily Reward only resets if the player misses a day',
    C: 'Daily Reward only resets if the player reaches the last reward'
};

export const DefaultContentValue = JSON.stringify({
    type: 'A',
    rewards: []
    // disabled: false
});

const getDefaultValue = () => JSON.parse(DefaultContentValue);

const DailyRewardView = (props) => {
    const { value, editing, fileName, onChange, onError } = props;

    const game = useGame();
    const gameId = game.id;
    /**
     * Validation start
     */
    useEffect(() => {
        if (!value) onChange(getDefaultValue());
        else if (value === '::disabled') return;
        else if (typeof value !== 'object') onChange(getDefaultValue());
        else if (typeof value === 'object' && Object.keys(value).length === 0) onChange(getDefaultValue());
        else if (!Array.isArray(value.rewards)) onChange({ ...value, rewards: getDefaultValue().rewards });
        else if (typeof value.type !== 'string' || Object.keys(DailyRewardTypes).indexOf(value.type) === -1)
            onChange({ ...value, type: getDefaultValue().type });
    }, [value, onChange]);
    /**
     * Validation end
     */

    if (value === '::disabled') return <DisabledConfigLabel />;

    const onChangeOption = (key, val) => {
        const prevValue = value || getDefaultValue();

        const nextValue = { ...prevValue, [key]: val };

        onError(!!nextValue.rewards.some((r) => !r));

        onChange(nextValue);
    };

    const { rewards, type } = value || {};

    return (
        <div className={utils.className('mt-4 p-2', !props.fluid && 'storage-view-box')}>
            <div className="mx-2 mb-4">
                <div className="mb-2">
                    <RadioOption
                        title={'Daily Rewards Type'}
                        name="type"
                        value={type}
                        readOnly={!editing}
                        onChange={onChangeOption}
                        options={Object.keys(DailyRewardTypes).map((o) => ({
                            value: o,
                            key: fileName + o + editing,
                            label: o,
                            description: DailyRewardTypes[o]
                        }))}
                    />
                </div>
            </div>
            <RewardsOption
                title={'Daily Rewards'}
                name="rewards"
                onChange={onChangeOption}
                value={rewards}
                readOnly={!editing}
                gameId={gameId}
            />
        </div>
    );
};

const RewardsOption = ({ title, name, value, onChange, readOnly, gameId }) => {
    const rewards = value || [];

    const addMilestone = (e) => {
        onChange(name, [...rewards, false]);
    };

    return (
        <div className="my-1">
            <div className="d-flex border-top justify-content-between">
                <div className="mx-2 mt-1 text-nowrap">{title}</div>
            </div>
            {rewards.length > 0 ? (
                <div className="mx-4 mt-2 small">
                    <div className="row fw-bold text-muted">
                        <div className="col-1"></div>
                        <div className="col-4">Item</div>
                        <div className="col-2">Quantity</div>
                        <div className="col-3">Ad Multiplier</div>
                        <div className="col-2"></div>
                    </div>
                    {rewards.map((v, i) => (
                        <div className="row align-items-center my-2" key={i}>
                            <DailyRewardSelect
                                list={rewards}
                                value={v}
                                index={i}
                                gameId={gameId}
                                readOnly={readOnly}
                                onChange={(val) => onChange(name, val)}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-muted small fw-bold opacity-3 mx-2 mt-2 mb-0">
                    No milestone rewards added
                </p>
            )}
            {!readOnly && rewards.length < 31 && (
                <div className="btn btn-sm btn-outline-primary fw-bold w-100 mt-2" onClick={addMilestone}>
                    <i className="fas fa-plus-circle me-1" />
                    Add milestone
                </div>
            )}
        </div>
    );
};

const DailyRewardSelect = (props) => {
    const { value, list, index, onChange, readOnly, gameId } = props;

    const component = ComponentsMap['entity'];

    let { data: entities, isFetching } = useQuery({
        queryKey: [component.id, gameId],
        queryFn: () => getConfigs(gameId, component.url),
        enabled: !!gameId
    });

    const options = useMemo(() => {
        const result = [];

        if (!entities) return result;

        return entities.map((e) => ({
            label: <EntityListItem gameId={gameId} entity={e} />,
            value: e.id
        }));
    }, [entities, gameId]);

    let selectedValue = null;
    let selectedEntity = null;
    if (entities && value) {
        selectedEntity = entities.find((item) => item.id === value.id);

        if (selectedEntity) {
            selectedValue = {
                value: selectedEntity.id,
                label: <EntityListItem gameId={gameId} entity={selectedEntity} />
            };
        } else {
            selectedValue = {
                value: value.id,
                label: <span className="text-danger fw-bold">Can't get entity with id '{value.id}'</span>
            };
        }
    }

    const canInputAmount = selectedEntity && selectedEntity.isConsumable;

    return (
        <>
            <div className="col-1 px-0 text-end fw-bold text-muted">Day {index + 1}</div>
            <div className="col-4">
                <Select
                    value={selectedValue}
                    // isMulti
                    name="daily-rewards-select"
                    isClearable={false}
                    placeholder={<span className="text-muted fw-bold">Select Reward</span>}
                    isLoading={isFetching}
                    isDisabled={readOnly}
                    onChange={(selected) => {
                        const newList = list.slice();

                        if (selected.value) {
                            const entity = entities.find((e) => e.id === selected.value);

                            newList[index] = {};

                            newList[index].id = selected.value;

                            newList[index].amount = typeof value.amount === 'number' ? value.amount : 1;

                            newList[index].canMultiply =
                                typeof value.canMultiply === 'boolean' ? value.canMultiply : false;

                            newList[index].adMultiplier =
                                typeof value.adMultiplier === 'number' ? value.adMultiplier : 1;

                            if (!entity.isConsumable) {
                                newList[index] = { id: selected.value };
                            }
                        } else {
                            newList[index] = false;
                        }

                        onChange(newList);
                    }}
                    menuShouldScrollIntoView={false}
                    options={
                        value
                            ? [
                                  {
                                      label: 'No reward',
                                      value: false
                                  },
                                  ...options
                              ]
                            : options
                    }
                    className="react-select-sm"
                    classNamePrefix="select"
                />
            </div>

            <div className="col-2">
                <input
                    type="number"
                    disabled={readOnly || !canInputAmount}
                    className="form-control "
                    min="1"
                    title="Reward item quantity"
                    value={value && typeof value.amount === 'number' ? value.amount : ''}
                    style={{ paddingTop: '2px', paddingBottom: '2px' }}
                    onChange={(e) => {
                        const newList = list.slice();

                        newList[index] = value || {};

                        newList[index].amount = Number(e.target.value);

                        onChange(newList);
                    }}
                />
            </div>
            <div className="col-3">
                <div className="input-group">
                    <div className="input-group-text" style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                        <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            disabled={readOnly || !canInputAmount}
                            checked={
                                value && typeof value.canMultiply === 'boolean' ? value.canMultiply : false
                            }
                            style={{ paddingTop: '2px', paddingBottom: '2px' }}
                            onChange={(e) => {
                                const newList = list.slice();

                                newList[index] = value || {};

                                newList[index].canMultiply = e.target.checked;

                                onChange(newList);
                            }}
                        />
                    </div>
                    <input
                        disabled={readOnly || !canInputAmount || !value.canMultiply}
                        type="number"
                        className="form-control"
                        min="1"
                        value={value && typeof value.adMultiplier === 'number' ? value.adMultiplier : ''}
                        onChange={(e) => {
                            const newList = list.slice();

                            newList[index] = value || {};

                            newList[index].adMultiplier = Number(e.target.value);

                            onChange(newList);
                        }}
                        style={{ paddingTop: '2px', paddingBottom: '2px' }}
                    />
                </div>
            </div>
            <div className="col-2 flex-nowrap text-start px-0">
                {!readOnly && (
                    <>
                        <div
                            className="btn text-secondary btn-sm p-0 px-1"
                            title="Remove milestone"
                            onClick={() => {
                                const newList = list.slice();

                                newList.splice(index, 1);

                                onChange(newList);
                            }}
                        >
                            <i className="fas fa-times-circle" />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export { DailyRewardView };

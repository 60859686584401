import axios from 'axios';

export const getConfigs = (gameId, componentURL) => {
    return axios.get('/api/config/' + gameId + '/' + componentURL);
};

export const getConfigsWithContent = (gameId, componentURL) => {
    return axios.get('/api/config/' + gameId + '/' + componentURL + '/content');
};

export const getConfigsPaths = (gameId, componentURL) => {
    return axios.get('/api/config/' + gameId + '/' + componentURL + '/paths');
};

export const getConfig = (gameId, componentURL, id) => {
    return axios.get('/api/config/' + gameId + '/' + componentURL + '/details/' + id);
};

export const createConfig = (gameId, componentURL, data) => {
    return axios.post('/api/config/' + gameId + '/' + componentURL, data);
};

export const updateConfig = (gameId, componentURL, data) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL, data);
};
export const updateLiveConfig = (gameId, componentURL, data) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/update-live', data);
};

export const archiveConfig = (gameId, componentURL, id) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/archive', { id });
};

export const restoreConfig = (gameId, componentURL, id) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/restore', { id });
};

export const updateConfigStatus = (gameId, componentURL, id, status) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/status', { id, status });
};

export const removeConfig = (gameId, componentURL, id) => {
    return axios.delete('/api/config/' + gameId + '/' + componentURL + '/' + id);
};

export const duplicateConfig = (gameId, componentURL, options) => {
    return axios.post('/api/config/' + gameId + '/' + componentURL + '/duplicate', options);
};

export const migrateConfig = (gameId, options, scriptId = 'configMigrate') => {
    return axios.post('/api/config/' + gameId + '/migrate?scriptId=' + scriptId, options);
};

export const archiveConfigBatch = (gameId, componentURL, configIds) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/batch/archive', { ids: configIds });
};

export const restoreConfigBatch = (gameId, componentURL, configIds) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/batch/restore', { ids: configIds });
};

export const updateConfigBatch = (gameId, componentURL, updateConfigs) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/batch/update', updateConfigs);
};

export const removeConfigBatch = (gameId, componentURL, configIds) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/batch/delete', { ids: configIds });
};

export const duplicateConfigBatch = (gameId, componentURL, configIds, prefix) => {
    return axios.put('/api/config/' + gameId + '/' + componentURL + '/batch/duplicate', {
        ids: configIds,
        prefix
    });
};

export const checkExperimentOverlap = (gameId, componentURL, experiment) => {
    return axios.post('/api/config/' + gameId + '/' + componentURL + '/overlap', experiment);
};

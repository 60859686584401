/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useContext } from 'react';
import {
    getStorageAsset,
    downloadStorageFile,
    uploadStorageFile,
    uploadStorageFileDraft,
    deleteStorageFileDraft
} from 'services/storage';
import { userContext } from 'services/user';
import { TextMimes } from 'consts';
import { BaseView } from './views';
import { FileComparison } from './FileComparison';
import { utils } from '@gpg-web/utils';
import { useGame, TooltipWrapper } from 'components';
import { Spinner, DropZone } from '@gpg-web/react';
import { FileNoPreview } from './FileNoPreview';
import { VersionSmall } from './FileVersions';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const FileView = (props) => {
    const { version, onSelect, hasChanges, readOnly, editInModal } = props;
    /**
     * because of useQuery: path.split function
     */
    const path = props.path || '';

    const user = useContext(userContext);
    const game = useGame();
    const [filterVersions, setFilterVersions] = useState('');
    const [comparing, setComparing] = useState(null);
    const [editing, setEditing] = useState(false);
    const gameId = game.id;
    const queryClient = useQueryClient();
    const {
        isFetching,
        error,
        data: file
    } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/')],
        queryFn: () => getStorageAsset(gameId, path),
        enabled: !!(gameId && path)
    });

    let previewType = null;

    if (file) {
        if (file.mime === 'text/html') previewType = 'html';
        else if (file.mime === 'application/json') previewType = 'json';
        else if (TextMimes.includes(file.mime)) previewType = 'text';
    }

    const setSelectedVersion = (version) => {
        onSelect({
            path: path,
            version: version.id
        });
    };

    const uploadNewVersion = (newContent, cb) => {
        utils.promt(
            'Are you sure you want to upload a new version of ' + file.name,
            function (message) {
                if (message !== false) {
                    utils.popup('uploading');

                    const blob = new Blob([newContent], { type: file.mime });

                    uploadStorageFile(gameId, blob, {
                        path: file.path,
                        name: file.name,
                        message,
                        authorEmail: user.email,
                        authorName: user.name
                    })
                        .then((data) => {
                            utils.popup('hide');

                            queryClient.removeQueries({
                                queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)],
                                exact: true
                            });

                            Object.assign(file, data);

                            setSelectedVersion(file.versions[0]);
                            // setFile();

                            utils.hintOk(file.name + ' file history updated');

                            cb && cb();
                        })
                        .catch(utils.hintError);
                }
            },
            { value: '', label: 'Version description', placeholder: file.name + ' updated' }
        );
    };

    const selectedVersion = file && file.versions && file.versions.find((v) => v.id === version);

    const uploadDraftVersion = (newContent, cb) => {
        const uploadContent = (draftId) => {
            utils.popup('uploading');

            const blob = new Blob([newContent], { type: file.mime });

            uploadStorageFileDraft(gameId, blob, {
                path: file.path,
                name: file.name,
                id: draftId,
                // message,
                authorEmail: user.email,
                authorName: user.name
            })
                .then((data) => {
                    utils.popup('hide');

                    queryClient.removeQueries({
                        queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)],
                        exact: true
                    });

                    Object.assign(file, data);
                    setSelectedVersion(file.versions.find((v) => v.id === draftId));

                    utils.hintOk(file.name + ' file history updated');

                    cb && cb();
                })
                .catch(utils.hintError);
        };

        if (selectedVersion && selectedVersion.isDraft) {
            const draftId = selectedVersion.id;

            uploadContent(draftId);
        } else {
            utils.promt(
                'Are you sure you want to upload a draft version of ' + file.name,
                function (draftId) {
                    if (draftId === false) return;

                    if (utils.isValidId(draftId)) {
                        uploadContent('draft.' + draftId);
                    } else {
                        utils.hintError(
                            'Draft ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed'
                        );
                    }
                },
                { value: '', label: 'Draft ID', placeholder: '' }
            );
        }
    };

    const deleteDraftVersion = (draftVersion, cb) => {
        const deleteDraft = (draftId) => {
            utils.popup('removing');

            deleteStorageFileDraft(gameId, {
                path: file.path,
                name: file.name,
                authorEmail: user.email,
                authorName: user.name,
                id: draftId
            })
                .then((data) => {
                    utils.popup('hide');

                    queryClient.removeQueries({
                        queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)],
                        exact: true
                    });
                    queryClient.invalidateQueries({
                        queryKey: ['storage', gameId, ...path.split('/'), draftId, 'content'],
                        exact: true
                    });

                    Object.assign(file, data);
                    setEditing(false);

                    const nextSelectedVersion =
                        selectedVersion &&
                        selectedVersion.id &&
                        file.versions.find((v) => v.id === selectedVersion.id);

                    setSelectedVersion(nextSelectedVersion ? nextSelectedVersion : {});

                    utils.hintOk(file.name + ' file history updated');

                    cb && cb();
                })
                .catch(utils.hintError);
        };

        utils.confirm(
            'Are you sure you want to delete draft - "' + draftVersion.id.replace('draft.', '') + '"?',
            (yes) => {
                if (yes) {
                    deleteDraft(draftVersion.id);
                }
            }
        );

        // if (selectedVersion && selectedVersion.isDraft) {
        // const draftId = selectedVersion.id.replace('draft_', '');

        // } else {
        // utils.promt(
        //     'Are you sure you want to upload a draft version of ' + file.name,
        //     function (draftId) {
        //         if (draftId !== false && utils.isValidId(draftId)) {
        //             uploadContent(draftId);
        //         } else {
        //             utils.hintError('Draft ID is not valid');
        //         }
        //     },
        //     { value: '', label: 'Draft ID', placeholder: '' }
        // );
        // }
    };

    const downloadVersion = (versionId) => {
        versionId = versionId !== undefined ? versionId : version;
        downloadStorageFile(gameId, file.path + file.name, versionId);
    };

    const downloadCurrentVersion = () => {
        downloadVersion(version);
    };

    const acceptNewFile = async (files) => {
        const newFile = files[0];

        if (!newFile) return utils.hintError('Something went wrong');

        if (newFile.type !== file.mime) {
            return utils.hintError('Wrong file type selected. Please select ' + file.mime);
        }

        if (newFile.type !== 'application/json') {
            return utils.hintError('Only JSONs files are allowed to be upload');
        }

        let content;

        try {
            if (newFile.type === 'application/json') {
                const text = await utils.load.file(newFile, 'text');
                const parsed = JSON.parse(text);
                /**
                 * In case you want just to open file in current editor
                 * setFileContent(content);
                 * _setEditing(true);
                 */
                content = JSON.stringify(parsed);
            }
        } catch (err) {
            utils.hintError('File you are trying to open should have a valid JSON format content.');
        }

        uploadNewVersion(content);
    };

    let versions = [];
    // let selectedVersion = null;

    if (!isFetching && !!file) {
        versions = file.versions;

        versions = versions.filter(
            (e) => (e.id + e.author.name + e.message).toLowerCase().indexOf(filterVersions.toLowerCase()) > -1
        );

        // selectedVersion = versions.find((v) => v.id === version);

        versions = versions.filter((e) => !e.archived);
    }

    const versionProps = {
        canCompare: previewType !== null,
        onSelect: setSelectedVersion,
        onDownload: downloadVersion,
        onCompare: setComparing,
        onDeleteDraft: deleteDraftVersion,
        canSelect: !readOnly
    };

    return (
        <>
            <div>
                {!isFetching && !!file && !!comparing && (
                    <>
                        <div className="mb-3">
                            <button onClick={() => setComparing(null)} className="btn btn-light">
                                <i className="fas fa-chevron-left me-2" /> Back
                            </button>
                        </div>
                        <FileComparison
                            gameId={gameId}
                            fileName={file.path + file.name}
                            versions={file.versions}
                            parseJSON={previewType === 'json'}
                            nextVersionId={comparing.id}
                            prevVersionId={comparing.parentId}
                            onCancel={() => setComparing(null)}
                        />
                    </>
                )}

                {!isFetching && !!file && !comparing && (
                    <div className="row">
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            {version && (
                                <div className="py-3">
                                    {previewType !== null && (
                                        <BaseView
                                            json={previewType === 'json'}
                                            editInModal={editInModal}
                                            gameVersion={props.gameVersion}
                                            environment={props.environment}
                                            uploadNewVersion={uploadNewVersion}
                                            uploadDraftVersion={uploadDraftVersion}
                                            isDraft={selectedVersion && selectedVersion.isDraft}
                                            onEditing={setEditing}
                                            editing={editing}
                                            gameId={gameId}
                                            path={file.path + file.name}
                                            versionId={version}
                                            hasChanges={hasChanges}
                                            readOnly={readOnly}
                                            renderAs={file.contentType || previewType}
                                        />
                                    )}

                                    {previewType === null && (
                                        <FileNoPreview
                                            file={file}
                                            version={version}
                                            onDownload={downloadCurrentVersion}
                                        />
                                    )}
                                </div>
                            )}

                            {!version && (
                                <div className="d-flex text-center justify-content-center storage-view-box flex-column px-5">
                                    <div>
                                        <i className="fas fa-search fa-sm me-2" /> Please select a file
                                        version
                                    </div>
                                    {file.contentType === 'json' ||
                                        (!file.contentType && (
                                            <>
                                                <div className="my-3">- or -</div>
                                                <div style={{ height: '100px' }}>
                                                    <DropZone
                                                        accept={{ [file.mime]: [] }}
                                                        onAccept={acceptNewFile}
                                                    />
                                                </div>
                                            </>
                                        ))}
                                </div>
                            )}
                        </div>

                        <div
                            className={
                                'col-xl-3 col-lg-4 col-md-12 border-start' + (editing ? ' disabled' : '')
                            }
                        >
                            <input
                                className="form-control form-control-sm mb-3"
                                type="search"
                                placeholder="Search..."
                                value={filterVersions}
                                onChange={(e) => setFilterVersions(e.target.value)}
                            />
                            <div className="storage-view-box" id="file-version-list">
                                {selectedVersion && (
                                    <div className="border rounded-pill mb-2 d-flex align-items-center px-2 py-1">
                                        {selectedVersion.isDraft ? (
                                            <span
                                                className={utils.className(
                                                    game.isProduction ? 'text-danger' : 'text-primary'
                                                )}
                                            >
                                                <i
                                                    className={utils.className(
                                                        'fa-sm mx-2',
                                                        game.isProduction
                                                            ? 'fas fa-exclamation-triangle'
                                                            : 'fas fa-edit'
                                                    )}
                                                />
                                                Draft selected
                                            </span>
                                        ) : (
                                            <>
                                                <div>#{selectedVersion.id} selected</div>
                                            </>
                                        )}

                                        <TooltipWrapper icon={false} content="Discard">
                                            <button
                                                onClick={() => versionProps.onSelect({})}
                                                className={utils.className(
                                                    'btn btn-sm ms-auto px-2 border-0',
                                                    readOnly ? 'd-none' : ''
                                                )}
                                            >
                                                <i className="fas fa-times text-danger" />
                                            </button>
                                        </TooltipWrapper>
                                    </div>
                                )}
                                {versions.map((e, index) => {
                                    let selected = version === e.id;
                                    let showed = version === e.id;

                                    let parentId = null;
                                    if (index < versions.length - 1) parentId = versions[index + 1].id;

                                    return (
                                        <VersionSmall
                                            {...versionProps}
                                            key={e.id}
                                            version={e}
                                            showed={showed}
                                            selected={selected}
                                            parentId={parentId}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}

                {isFetching && (
                    <div className="d-flex my-5 align-items-center justify-content-center">
                        <Spinner size="100px" width="8px" />
                    </div>
                )}

                {error && <div className="alert alert-danger">{error}</div>}
            </div>
        </>
    );
};

export { FileView };

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { getStorageFileContent } from 'services/storage';
import { utils } from '@gpg-web/utils';
import { Spinner } from '@gpg-web/react';
import { IAMButton } from 'components';

export const TextView = (props) => {
    const { value, editing, fileName, onChange, onError } = props;

    return (
        <div>
            {!editing && (
                <div className="card">
                    <div className="card-body">
                        <pre style={{ height: '67vh' }}>{value}</pre>
                    </div>
                </div>
            )}

            {editing && (
                <textarea
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    className="form-control"
                    rows="20"
                    placeholder="Edit content ..."
                />
            )}
        </div>
    );
};

export const TextFullView = (props) => {
    const { gameId, path, versionId, uploadNewVersion, editing, onEditing, hasChanges, readOnly } = props;

    const [fileContent, setFileContent] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);
    // const [editing, setEditing] = useState(false);
    const [editContent, setEditContent] = useState('');

    const _setEditing = (value) => {
        // setEditing(value);
        onEditing(value);

        if (!value) {
            hasChanges && hasChanges(false);
        }
    };

    useEffect(() => {
        if (gameId && path && versionId) {
            setContentLoading(true);

            getStorageFileContent(gameId, path, versionId, [])
                .then((content) => {
                    setFileContent(content);
                    setContentLoading(false);
                })
                .catch(utils.hintError);
        }
    }, [gameId, path, versionId]);

    useEffect(() => {
        if (editing && fileContent) {
            setEditContent(fileContent);
        } else {
            setEditContent('');
        }
    }, [fileContent, editing]);

    const pushNewVersion = () => {
        uploadNewVersion(editContent, () => {
            _setEditing(false);
        });
    };

    const onEditContentChange = (val) => {
        setEditContent(val);

        hasChanges && hasChanges(fileContent !== val);
    };

    return (
        <>
            {contentLoading && (
                <div className="d-flex my-5 align-items-center justify-content-center">
                    <Spinner size="70px" width="7px" />
                </div>
            )}

            {fileContent !== null && !contentLoading && (
                <>
                    {!editing && (
                        <>
                            <div className="text-end mb-2">
                                {!readOnly && (
                                    <IAMButton permissions={['component.storage.upload']}>
                                        <button
                                            onClick={() => _setEditing(true)}
                                            className="btn btn-sm btn-primary"
                                        >
                                            <i className="fa fa-sm fa-edit me-1" /> Edit
                                        </button>
                                    </IAMButton>
                                )}
                            </div>

                            <div className="card shadow-sm">
                                <div className="card-body">
                                    {!props.html && <pre style={{ height: '67vh' }}>{fileContent}</pre>}
                                    {props.html && (
                                        <iframe
                                            style={{ height: '500px' }}
                                            className="w-100"
                                            srcDoc={fileContent}
                                            title="HTMLPreview"
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {editing && (
                        <>
                            <div className="mb-2 text-end">
                                <button onClick={pushNewVersion} className="btn ms-auto btn-sm btn-success">
                                    <i className="fa fa-sm fa-upload" /> Save & Upload
                                </button>
                                <button
                                    onClick={() => _setEditing(false)}
                                    className="ms-2 btn btn-sm btn-secondary"
                                >
                                    <i className="fa fa-sm fa-times" /> Cancel
                                </button>
                            </div>

                            <textarea
                                onChange={(e) => onEditContentChange(e.target.value)}
                                value={editContent}
                                className="form-control"
                                rows="20"
                                placeholder="Edit content ..."
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

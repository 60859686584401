/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';
import { utils as XLSXUtils, writeFileXLSX } from 'xlsx';
import { saveAs } from 'file-saver';
import { fromJSON } from './Utils';

export function FileExporter(jsonContent, format, fileName) {
    fileName = fileName || 'Translation Export';
    let blob, content, ws, wb;

    const tableJSON = [];

    for (let key in jsonContent) {
        const obj = {
            Key: key
        };

        for (let lang in jsonContent[key]) {
            obj[utils.languageName(lang)] = jsonContent[key][lang];
        }

        tableJSON.push(obj);
    }

    switch (format) {
        case 'json':
            blob = new Blob([JSON.stringify(jsonContent)], { type: 'application/json' });
            saveAs(blob, fileName + '.json');
            break;
        case 'json-table':
            blob = new Blob([JSON.stringify(tableJSON)], { type: 'application/json' });
            saveAs(blob, fileName + '.json');
            break;
        case 'xlsx':
            ws = XLSXUtils.json_to_sheet(tableJSON);
            wb = XLSXUtils.book_new();
            XLSXUtils.book_append_sheet(wb, ws, 'Main');
            writeFileXLSX(wb, fileName + '.xlsx');
            break;
        case 'ods':
            ws = XLSXUtils.json_to_sheet(tableJSON);
            wb = XLSXUtils.book_new();
            XLSXUtils.book_append_sheet(wb, ws, 'Main');
            writeFileXLSX(wb, fileName + '.ods', {
                bookType: 'ods'
            });
            break;
        case 'csv':
            ws = XLSXUtils.json_to_sheet(tableJSON);
            wb = XLSXUtils.book_new();
            XLSXUtils.book_append_sheet(wb, ws, 'Main');
            content = XLSXUtils.sheet_to_csv(ws);
            blob = new Blob([content], { type: 'text/csv' });
            saveAs(blob, fileName + '.csv');
            break;
        case 'html':
            ws = XLSXUtils.json_to_sheet(tableJSON);
            wb = XLSXUtils.book_new();
            XLSXUtils.book_append_sheet(wb, ws, 'Main');
            content = XLSXUtils.sheet_to_html(ws);
            blob = new Blob([content], { type: 'text/html' });
            saveAs(blob, fileName + '.html');
            break;
        case 'txt':
        default:
            content = fromJSON(jsonContent);

            blob = new Blob([content], { type: 'text/plain' });
            saveAs(blob, fileName + '.txt');
    }
}

import { TooltipWrapper } from 'components';

export const RadioOption = ({ title, name, value, onChange, readOnly, options }) => {
    return (
        <div className="d-flex align-items-center my-1">
            <div className="me-2 text-nowrap">{title}</div>
            <div className="d-flex">
                {options.map((option, i) => (
                    <div className="me-4" key={i}>
                        <TooltipWrapper className="" content={option.description ? option.description : null}>
                            <div className="form-check form-check-inline me-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id={option.key + name + option.value}
                                    name={option.key + name}
                                    value={option.value}
                                    checked={option.value === value}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            onChange(name, e.target.value);
                                        }
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={option.key + name + option.value}
                                >
                                    {option.label || option.value}
                                </label>
                            </div>
                        </TooltipWrapper>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';

const statusTexts = {
    live: 'Live',
    test: 'Test',
    pretest: 'Pretest',
    draft: 'Draft',
    disabled: 'Disabled',
    ongoing: 'Ongoing',
    pending: 'Pending',
    planned: 'Planned',
    finished: 'Finished',
    stopped: 'Stopped',
    hold: 'Hold',
    error: 'Error'
};
const statusColors = {
    live: 'bg-success',
    test: 'bg-warning text-dark',
    pretest: 'bg-warning text-dark',
    draft: 'bg-secondary',
    disabled: 'bg-danger',
    ongoing: 'bg-success',
    pending: 'bg-info-subtle text-info-emphasis border border-info-subtle',
    planned: 'bg-info-subtle text-info-emphasis border border-info-subtle',
    finished: 'bg-success-subtle text-success-emphasis border border-success-subtle',
    stopped: 'bg-danger-subtle text-danger-emphasis border border-danger-subtle',
    hold: 'bg-danger-subtle text-danger-emphasis border border-danger-subtle',
    error: 'bg-danger'
};

const StatusLabel = (props) => {
    const { value } = props;

    return (
        <span className={'badge ' + (statusColors[value] || 'bg-secondary')}>
            {statusTexts[value] || value}
        </span>
    );
};

export { StatusLabel };

const ActionsColor = {
    'Add': 'success',
    'Update': 'info',
    'Delete': 'danger',
    'Disable': 'danger bg-danger-subtle text-danger-emphasis border-danger-subtle',
    'Enable': 'success bg-success-subtle text-success-emphasis border-success-subtle',
    'Archive': 'warning bg-warning-subtle text-warning-emphasis border-warning-subtle',
    'Restore': 'success bg-success-subtle text-success-emphasis border-success-subtle',
    'Update:audience': 'info bg-info-subtle text-info-emphasis border-info-subtle',
    'Update:scheduler': 'info bg-info-subtle text-info-emphasis border-info-subtle',
    'Update:title': 'info bg-info-subtle text-info-emphasis border-info-subtle',
    'Update:status': 'info bg-info-subtle text-info-emphasis border-info-subtle',
    'Automation': 'primary'
};

export const ActionLabel = (props) => {
    return (
        <span className={'badge badge-lg bg-' + (ActionsColor[props.value] || 'secondary')}>
            {props.value || 'Unknown action'}
        </span>
    );
};

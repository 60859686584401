/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { migratePlayerSave } from 'services/players';
import { getGames } from 'services/game';
import { Alert } from 'components';
import { utils } from '@gpg-web/utils';
import { Modal, Spinner } from '@gpg-web/react';
import AutomationProcessModal from '../../../modals/AutomationProcess';

const ImportData = (props) => {
    let { game, show, onHide, onFinish } = props;

    const [games, setGames] = useState(null);
    const [gameSrc, setGameSrc] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [sameNameAction, setSameNameAction] = useState('merge');

    const groupId = game.group && game.group.id;
    const gameId = game.id;

    const catchError = (err) => {
        utils.hintError(err);
        setLoading(false);
    };

    useEffect(() => {
        if (show && groupId) {
            setLoading(true);
            getGames(groupId)
                .then((games) => {
                    games = games.filter((e) => e.id !== gameId);

                    setGames(games);
                    setLoading(false);
                })
                .catch(catchError);
        }
    }, [show, groupId, gameId]);

    const migrate = () => {
        setError(null);

        if (!gameSrc) return setError('Please select source game stage!');

        utils.popup('Importing ...');

        const options = {
            gameSrc: gameSrc,
            sameNameAction: sameNameAction
        };

        migratePlayerSave(gameId, options)
            .then((result) => {
                utils.popup('hide');

                _onHide();
                onFinish();

                result.gameId = gameId;
                result.name = 'Migration';
                AutomationProcessModal.show(result);
            })
            .catch(setError);
    };

    const _onHide = () => {
        setLoading(false);
        setError(null);
        onHide();
    };

    return (
        <Modal show={show} onHide={_onHide} title="Importing options">
            <div className="modal-body px-4">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <label className="mb-1" htmlFor="migrate-select-env">
                            Select Source Stage
                        </label>
                        <select
                            disabled={!games || games.length === 0}
                            id="migrate-select-env"
                            value={gameSrc}
                            onChange={(e) => setGameSrc(e.target.value)}
                            className="form-select"
                        >
                            <option disabled value="">
                                Select Source Stage
                            </option>
                            {games &&
                                games.map((e) => (
                                    <option key={e.id} value={e.id}>
                                        {e.name}
                                    </option>
                                ))}

                            {!!games && games.length === 0 && <option value="null">List is empty</option>}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-6"></div>
                </div>
                <div className="row py-3 border-bottom">
                    <div className="mb-2">What to do with Save Types of the same name?</div>
                    <div className="col-md-4 col-sm-12">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                name="same-save-type-name-action"
                                type="radio"
                                id="same-save-type-name-ignore"
                                onChange={(e) => setSameNameAction('ignore')}
                                checked={sameNameAction === 'ignore'}
                            />
                            <label className="form-check-label small" htmlFor="same-save-type-name-ignore">
                                Ignore them
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                name="same-save-type-name-action"
                                type="radio"
                                id="same-save-type-name-merge"
                                onChange={(e) => setSameNameAction('merge')}
                                checked={sameNameAction === 'merge'}
                            />
                            <label className="form-check-label small" htmlFor="same-save-type-name-merge">
                                Merge
                            </label>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    {loading && (
                        <div>
                            <Spinner /> Loading ...
                        </div>
                    )}
                    {error && <Alert text={error} />}
                </div>
            </div>
            <div className="modal-footer">
                <button data-bs-dismiss="modal" className="btn btn-secondary btn-sm">
                    Cancel
                </button>
                <button disabled={loading || !gameSrc} onClick={migrate} className="btn btn-success btn-sm">
                    Start importing
                </button>
            </div>
        </Modal>
    );
};

export default ImportData;

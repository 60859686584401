/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useMemo, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '@gpg-web/react';
import { gameContext } from 'services/game';
import { setIAMPolicy, getRoles } from 'services/iam';
import EditIAMModal from './EditIAMModal';
import { utils } from '@gpg-web/utils';
import { IAMButton, IAMButtonSmall, UserBadge, useGame } from 'components';
import { SettingsNavigation } from '../Navigation';

const columns = [
    {
        Header: '#',
        accessor: 'index'
    },
    {
        Header: 'Principal',
        accessor: 'principal'
    },
    {
        Header: 'Name',
        accessor: 'name',
        sortType: (rowA, rowB) => {
            return rowA.original.principal > rowB.original.principal ? -1 : 1;
        }
    },
    {
        Header: 'Role',
        accessor: 'displayRoles',
        sortType: (rowA, rowB) => {
            return rowA.original._roles > rowB.original._roles ? -1 : 1;
        }
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true
    }
];

export const IAMSettings = (props) => {
    const { setGame } = useContext(gameContext);
    const [editing, setEditing] = useState(false);
    const [roles, setRoles] = useState(null);
    const game = useGame();
    const history = useNavigate();

    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getRoles(gameId).then(setRoles).catch(utils.hintError);
    }, [gameId]);

    const data = useMemo(() => {
        if (!(game && game.iam && roles)) return null;

        const usersSet = {};

        for (let binding of game.iam) {
            const user = binding.principal;

            usersSet[user] = usersSet[user] || [];
            usersSet[user].push(binding.role);
        }

        const deletePrincipal = (principal) => {
            utils.confirm(
                'Are you sure you want to delete ' + principal + ' from this project?',
                function (yes) {
                    if (yes) {
                        utils.popup('deleting');

                        const newIAM = game.iam.filter((e) => e.principal !== principal);

                        setIAMPolicy(game.id, newIAM)
                            .then(() => {
                                game.iam = newIAM;

                                setGame({ ...game });

                                utils.popup('hide');
                            })
                            .catch(utils.hintError);
                    }
                }
            );
        };

        return Object.keys(usersSet).map((e, index) => {
            const user = e;
            const rolesList = usersSet[e];

            return {
                index: index + 1,
                principal: user,
                name: <UserBadge email={user} onClick={(e) => history(e.url)} />,
                displayRoles: (
                    <>
                        {rolesList.map((e) => (
                            <div className="mb-3" key={e}>
                                {roles[e] ? roles[e].title : <code>{e}</code>}
                            </div>
                        ))}
                    </>
                ),
                _roles: rolesList.join(),
                actions: (
                    <>
                        <IAMButtonSmall permissions={['resourcemanager.projects.setiampolicy']}>
                            <button onClick={() => setEditing(user)} className="btn btn-sm">
                                <i className="fa fa-edit" />
                            </button>
                        </IAMButtonSmall>
                        <IAMButtonSmall permissions={['resourcemanager.projects.setiampolicy']}>
                            <button onClick={() => deletePrincipal(user)} className="btn btn-sm">
                                <i className="fa fa-trash" />
                            </button>
                        </IAMButtonSmall>
                    </>
                )
            };
        });
    }, [game, roles, setGame, history]);

    return (
        <>
            <SettingsNavigation gameId={gameId} tab="iam" />
            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4 className="mb-0 me-3">IAM</h4>
                    <IAMButton permissions={['resourcemanager.projects.setiampolicy']}>
                        <button onClick={() => setEditing('')} className="btn btn-light text-primary">
                            <i className="fa fa-user-plus me-1" /> ADD
                        </button>
                    </IAMButton>
                </div>

                <div className="mt-4">
                    {!!data && <Table columns={columns} data={data} className="table-striped" />}
                </div>

                {!!data && (
                    <EditIAMModal
                        inputData={editing}
                        roles={roles}
                        show={editing !== false}
                        onHide={() => setEditing(false)}
                        game={game}
                    />
                )}
            </div>
        </>
    );
};

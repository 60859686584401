import { StatusLabel } from 'components';
import { loadEnvironments, loadGameVersions, loadStages } from '../loaders';
import { EnvironmentView, GameVersionView, GameView, StatusView } from '../optionViews';

export const configMigrate = {
    description: <>Config migration</>,
    color: '#4e63df',
    icon: 'fas fa-angle-double-down',
    hideInLibrary: true,
    options: [
        {
            id: 'sourceGame',
            type: 'select',
            name: 'Source Stage',
            options: [],
            placeholder: 'Select stage',
            asyncLoad: loadStages,
            size: 6,
            view: GameView
        },
        {
            id: 'newOnly',
            value: false,
            type: 'checkbox',
            name: 'Migrate new configs only',
            setupProps: {
                className: 'mt-4',
                tooltip: 'All existing configs will be ignored'
            }
        },
        {
            id: 'liveOnly',
            value: false,
            type: 'checkbox',
            name: (
                <>
                    Migrate <StatusLabel value="live" /> configs only
                </>
            ),
            setupProps: {
                className: 'mt-2'
            }
        },
        {
            id: 'environments',
            name: 'Environments',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: [],
            asyncLoad: async (gameId) => {
                const result = await loadEnvironments(gameId);
                return [{ value: 'all', label: 'All Environments' }, ...result];
            },
            view: EnvironmentView
        },
        {
            id: 'versions',
            name: 'Game Versions',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: [],
            asyncLoad: async (gameId) => {
                const result = await loadGameVersions(gameId);
                return [{ value: 'all', label: 'All Versions' }, ...result];
            },
            view: GameVersionView
        },
        {
            id: 'setStatus',
            name: 'Set Status for every added or modified config',
            value: 'test',
            type: 'select_button',
            options: [
                { value: '', label: "Don't change" },
                { value: 'live', label: 'Live' },
                { value: 'test', label: 'Test' }
            ],
            view: StatusView
        },
        {
            id: 'info1',
            type: 'info',
            text: 'Make sure, all the selected environments and game versions are exists on the source stage.'
        },
        {
            id: 'info2',
            type: 'info',
            text: 'Take into account that triggers will NOT be migrated. You should setup time, conditions, etc. by hand.'
        },
        {
            id: 'info3',
            type: 'info',
            text: 'Archived configs will be ignored.'
        }
    ],
    validate: async function (values, gameId) {
        const { environments, versions } = values;
        if (!values.sourceGame) throw new Error('Source game should be specified');

        if (!environments || environments.length === 0)
            throw new Error('At least one Environment should be selected');
        if (!versions || versions.length === 0)
            throw new Error('At least one Game Version should be selected');
    },
    name: 'Migration'
};

import { Status } from 'consts';
import { TooltipWrapper, useFileVersions, useGame } from 'components';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
// import {  date as dateUtils } from '@gpg-web/utils';

export const VersionSelectPopup = (props) => {
    const { configs, version, environment, configId, environments, versions, onChangeConfig, onShowModal } =
        props;

    const config = (configs || []).find((c) => c.id === configId) || {};

    const content = config.content || {};
    const contentVersions = content.versions || {};

    const hasFile = !!content.path;

    const key = environment + version + '/';

    const { versions: fileVersions, error, isFetching } = useFileVersions(content.path);

    const { version: selectedFileVersionId, status } = contentVersions[key] || {};

    const selectedVersion = fileVersions.find((v) => v.id === selectedFileVersionId);

    const env = (environments || []).find((e) => e.id === environment) || {};
    const ver = (versions || []).find((e) => e.id === version) || {};

    const onSelectVersion = (v) => onChangeConfig(configId, key, 'version', v ? v.id : undefined);

    const { isProduction } = useGame();

    // if (!!error)
    //     return (
    //         <div className="alert alert-warning px-2 py-1 small">
    //             <i className="fas fa-exclamation-triangle me-2" />
    //             Error loading file - {error}
    //         </div>
    //     );

    return hasFile ? (
        <div className="card">
            <div className="card-header p-1 px-3 fw-bold">
                <div className="modal-title">
                    {config.id} - {env.name || env.id} - {ver.id}
                </div>
            </div>
            <div className="card-body p-0">
                <div className="border-bottom p-2 px-3">
                    <div className="small text-muted fw-bold">Set status</div>
                    {Object.keys(Status).map((e) => (
                        <span key={e} className="mx-1">
                            <input
                                checked={status === e}
                                onChange={(el) => {
                                    if (el.target.checked) {
                                        onChangeConfig(config.id, key, 'status', e);
                                    }
                                }}
                                type="checkbox"
                                className="btn-check"
                                id={'overview-status-popup_' + e}
                                autoComplete="off"
                            />

                            <label
                                className="btn btn-sm btn-outline-primary small p-1 px-1 py-0"
                                htmlFor={'overview-status-popup_' + e}
                            >
                                {Status[e]}
                            </label>
                        </span>
                    ))}
                </div>
                <div className="border-bottom">
                    <div>
                        <div className="d-flex align-items-center p-2 px-3">
                            <div className="small text-muted fw-bold">Set version</div>
                            <button onClick={onShowModal} className="btn btn-sm ms-auto px-2 border-0">
                                <i className="fas fa-expand" />
                            </button>
                        </div>

                        {isFetching && (
                            <div className="px-2 py-2">
                                <Skeleton height={75} />
                                <Skeleton height={75} />
                                <Skeleton height={75} />
                            </div>
                        )}

                        {!!error && (
                            <div className="alert alert-warning px-2 py-1 small m-2">
                                <i className="fas fa-exclamation-triangle me-2" />
                                Error loading file - {error}
                            </div>
                        )}

                        {!isFetching && (
                            <ul className="list-group">
                                {selectedVersion && (
                                    <div className="border rounded-pill m-2 d-flex align-items-center px-2 py-1">
                                        <div>
                                            {selectedVersion.isDraft ? (
                                                <span
                                                    className={utils.className(
                                                        isProduction ? 'text-danger' : 'text-primary'
                                                    )}
                                                >
                                                    <i
                                                        className={utils.className(
                                                            'mx-2 fa-sm',
                                                            isProduction
                                                                ? 'fas fa-exclamation-triangle'
                                                                : 'fas fa-edit'
                                                        )}
                                                    />
                                                    Draft selected
                                                </span>
                                            ) : (
                                                <>#{selectedFileVersionId} selected</>
                                            )}
                                        </div>

                                        <TooltipWrapper icon={false} content="Discard">
                                            <button
                                                onClick={() => onSelectVersion({})}
                                                className="btn btn-sm ms-auto px-2 border-0"
                                            >
                                                <i className="fas fa-times text-danger" />
                                            </button>
                                        </TooltipWrapper>
                                    </div>
                                )}
                                <ul
                                    className="overflow-y-auto list-group list-group-flush"
                                    style={{ maxHeight: '245px' }}
                                >
                                    {fileVersions.map((e, index) => {
                                        let selected = selectedFileVersionId === e.id;

                                        return (
                                            <VersionListItem
                                                key={e.id}
                                                version={e}
                                                selected={selected}
                                                onSelect={onSelectVersion}
                                            />
                                        );
                                    })}
                                </ul>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="alert alert-warning px-2 py-1 small">
            <i className="fas fa-exclamation-triangle me-2" />
            Please select file path first
        </div>
    );
};

const VersionListItem = (props) => {
    const { selected, onSelect, version } = props;

    const { id, message } = version;

    const { isProduction } = useGame();

    if (version.isDraft && isProduction) return null;

    return (
        <button
            className={utils.className(
                'list-group-item list-group-item-action',
                selected ? ' list-group-item-success' : '',
                version.isDraft && isProduction ? 'pe-none disabled' : ''
            )}
            onClick={() => (selected ? onSelect({}) : onSelect(version))}
        >
            <span className="small">
                {version.isDraft ? (
                    <span className={utils.className(isProduction ? 'text-danger' : 'text-primary')}>
                        <i
                            className={utils.className(
                                'me-2 fa-sm',
                                isProduction ? 'fas fa-exclamation-triangle' : 'fas fa-edit'
                            )}
                        />
                        Draft - {id.replace('draft.', '')}
                    </span>
                ) : (
                    <>
                        #{id} - <span className="fw-light fst-italic">"{message}"</span>
                    </>
                )}
            </span>
        </button>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { uploadStorageFile, createStorageFolder } from 'services/storage';
import { userContext } from 'services/user';
import { ButtonFileInput, IAMButton, MigrateFromButton, ComponentHeader, useGame } from 'components';
import { utils } from '@gpg-web/utils';
import { Collection } from './Collection';
import { File } from './File';

export const StoragePage = (props) => {
    const user = useContext(userContext);
    const [assets, setAssets] = useState(null);
    const [migrating, setMigrating] = useState(false);

    const game = useGame();

    const gameId = game.id;

    let params = useParams();
    let path = params['*'];

    let isCollection = path.endsWith('/') || path === '';

    useEffect(() => {
        return () => {
            setAssets(null);
        };
    }, [isCollection]);

    const checkAndApplyJSON = async (file) => {
        if (file.type === 'application/json') {
            const content = await utils.load.file(file, 'text');

            try {
                const parsed = JSON.parse(content);

                if (typeof parsed !== 'object') throw new Error('JSON file is invalid');

                const compressed = JSON.stringify(parsed);

                file = new Blob([compressed], { type: file.type });
            } catch (err) {
                throw new Error('JSON file is invalid');
            }
        }

        return file;
    };

    const uploadMultipleFiles = async (files) => {
        const message = await utils.promt('Are you sure you want to upload ' + files.length + ' files', {
            value: '',
            label: 'Versions description',
            placeholder: 'Initial version'
        });

        if (message === false) return;

        utils.popup('uploading');
        const uploadedFilesPaths = [];

        // const uploadedNewConfigFiles = [];

        for (let file of files) {
            const fileName = file.name;
            try {
                file = await checkAndApplyJSON(file);
                const data = await uploadStorageFile(gameId, file, {
                    path: path,
                    name: fileName,
                    message,
                    authorEmail: user.email,
                    authorName: user.name
                });

                const found = assets.find((e) => e.name === data.name);
                if (found) {
                    Object.assign(found, data);
                    utils.hintOk(data.name + ' file history updated');
                    uploadedFilesPaths.push({ path: data.path + data.name, version: data.lastVersionId });
                } else {
                    assets.push(data);
                    utils.hintOk(fileName + ' was successfully uploaded!');

                    // data.name.endsWith('.json') && uploadedNewConfigFiles.push(data);
                }
            } catch (err) {
                utils.popup('uploading');

                utils.hintError(fileName + ': ' + err);

                continue;
            }
        }

        setAssets(assets.slice(0));

        utils.popup('hide');
        // if (uploadedFilesPaths.length) setPromotingLocalEnvironments(uploadedFilesPaths);
        // else if (uploadedNewConfigFiles.length) {
        //     const createNew = await utils.confirm(
        //         'Do you want to create new Game Balance configs with files you uploaded?'
        //     );

        //     createNew && setNewFileConfigs(uploadedNewConfigFiles);
        // }
    };

    const acceptNewFile = async (files) => {
        try {
            if (files.length > 1) {
                await uploadMultipleFiles(files);
                return;
            }

            let file = files[0];

            if (!file) return;

            const fileName = file.name;

            const message = await utils.promt('Are you sure you want to upload a new ' + fileName, {
                value: '',
                label: 'Version description',
                placeholder: 'Initial version'
            });

            if (message === false) return;

            file = await checkAndApplyJSON(file);

            utils.popup('uploading');

            const data = await uploadStorageFile(gameId, file, {
                path: path,
                name: fileName,
                message,
                authorEmail: user.email,
                authorName: user.name
            });

            utils.popup('hide');

            const found = assets.find((e) => e.name === data.name);

            if (found) {
                Object.assign(found, data);
                setAssets(assets.slice(0));
                utils.hintOk(data.name + ' file history updated');
                // setPromotingLocalEnvironments([{ path: data.path + data.name, version: data.lastVersionId }]);
            } else {
                assets.push(data);
                setAssets(assets.slice(0));

                utils.hintOk('File was successfully uploaded!');

                // if (data.name.endsWith('.json')) {
                //     const createNew = await utils.confirm(
                //         'Do you want to create new Game Balance configs with files you uploaded?'
                //     );

                //     createNew && setNewFileConfigs([data]);
                // }
            }
        } catch (err) {
            utils.hintError(err);
            utils.popup('hide');
        }
    };

    const createFolder = async () => {
        try {
            const message = await utils.promt('Please, enter a folder name', {
                value: '',
                label: 'Folder name'
            });

            if (message === false) return;

            if (!message) return utils.hintError("Folder name can't be empty!");

            const folderName = message.trim().split('/').shift();

            if (!utils.isValidFolderName(folderName)) return utils.hintError('Folder name is not valid');

            const exists = assets.find((e) => e.name === folderName);

            if (exists) return utils.hintError('This folder already exists!');

            utils.popup('saving');

            const data = await createStorageFolder(user, gameId, path, folderName);

            utils.popup('hide');

            assets.splice(0, 0, data);
            setAssets(assets.slice(0));

            utils.hintOk('Folder was successfully created!');
        } catch (err) {
            utils.hintError(err);
        }
    };

    const pathesParts = path.split('/');

    if (isCollection) pathesParts.splice(pathesParts.length - 1, 1);

    return (
        <>
            <div>
                <ComponentHeader
                    gameId={gameId}
                    id="storage"
                    url="storage"
                    breadcrumb={pathesParts.map((e) => ({ url: e, suffix: '/' }))}
                >
                    {isCollection && (
                        <>
                            <div>
                                <IAMButton permissions={['component.storage.upload']}>
                                    <ButtonFileInput multiple onAccept={acceptNewFile} disabled={!assets} />
                                </IAMButton>
                            </div>
                            <div className="ms-3">
                                <IAMButton permissions={['component.storage.createfolder']}>
                                    <button
                                        disabled={!assets}
                                        onClick={createFolder}
                                        className="btn btn-secondary btn-sm shadow"
                                    >
                                        <i className="fas fa-folder-plus me-1" /> Create Folder
                                    </button>
                                </IAMButton>
                            </div>
                            <div className="ms-3">
                                <IAMButton permissions={['component.storage.migrate']}>
                                    <MigrateFromButton component="storage" onClick={setMigrating} />
                                </IAMButton>
                            </div>
                        </>
                    )}
                </ComponentHeader>

                {isCollection ? (
                    <Collection
                        assets={assets}
                        setAssets={setAssets}
                        path={path}
                        acceptNewFile={acceptNewFile}
                        migrating={migrating}
                        setMigrating={setMigrating}
                    />
                ) : (
                    <File path={path} />
                )}
            </div>
        </>
    );
};

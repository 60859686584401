import { getEnvironments, getVersions } from 'services/settings';
import { getGames } from 'services/game';
import { semver } from '@gpg-web/utils';

import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({});

export const loadEnvironments = async (gameId) => {
    const data = await queryClient.fetchQuery({
        queryKey: [gameId, 'environments'],
        queryFn: () => getEnvironments(gameId)
    });

    return data.map((e) => ({
        value: e.id,
        label: e.id // e.name ? `${e.id} - ${e.name}` : e.id
    }));
};

export const loadGameVersions = async (gameId) => {
    const data = await queryClient.fetchQuery({
        queryKey: [gameId, 'versions'],
        queryFn: () => getVersions(gameId)
    });
    return data
        .map((e) => ({
            value: e.id,
            label: e.id // e.name ? `${e.id} - ${e.name}` : e.id
        }))
        .sort((a, b) => (semver.gt(a.value, b.value) ? -1 : 1));
};

export const loadStages = async (gameId, opt) => {
    const { game } = opt;

    const data = await queryClient.fetchQuery({
        queryKey: [gameId, game.group.id, 'stages'],
        queryFn: () => getGames(game.group.id)
    });

    return data
        .filter((e) => e.id !== gameId)
        .map((e) => ({
            value: e.id,
            label: e.name
        }));
};

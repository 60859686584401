import { useParams } from 'react-router-dom';
import { useMember } from 'components';
import Skeleton from 'react-loading-skeleton';
import profileIcon from '../img/undraw_profile.svg';

export const ProfilePage = (props) => {
    let { id } = useParams();

    const user = useMember(null, id);

    return (
        <>
            <div className="container">
                <div className="main-body">
                    {!user && (
                        <div className="row gutters-sm">
                            <div className="col-md-4 mb-3">
                                <Skeleton height={300} />

                                <Skeleton className="mt-3" height={50} />

                                <Skeleton className="mt-3" height={50} />
                            </div>

                            <div className="col-md-8">
                                <Skeleton className="my-2" count={5} height={50} />
                            </div>
                        </div>
                    )}
                    {!!user && (
                        <div className="container-lg text-center mt-4">
                            <img
                                alt={user.name}
                                src={user.picture || profileIcon}
                                width="100"
                                className="rounded-circle"
                            />
                            <div className="h3 mt-4">{user.name}</div>
                            <div>{user.email}</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

import { useState, useMemo } from 'react';
import { fromJSON } from './Utils';
import Select from 'react-select';
import { Table } from '@gpg-web/react';
import { utils } from '@gpg-web/utils';
import { EditText } from './EditText';
import { ImportAndMerge } from './ImportAndMerge';

export const Edit = ({ value, content, languages, onChange, selectedLanguage, setSelectedLanguage }) => {
    const [editingText, setEditingText] = useState(null);
    const [importing, setImporting] = useState(false);

    const data = useMemo(() => {
        if (!content || !selectedLanguage) return [];

        const _editText = async (e) => {
            e.stopPropagation();

            const key = e.target.getAttribute('data-id');

            const editText = { key: key };
            Object.assign(editText, content[key]);

            setEditingText(editText);

            // const prevText = json[key][selectedLanguage] || '';

            // let message = await utils.promt('Please, enter your translated text here', {
            //     value: prevText.replaceAll('\n', '\\n'),
            //     label: key,
            //     placeholder: 'Enter text'
            // });

            // if (message === false) return;

            // if (message === '') return utils.hintError("Text can't be empty");

            // message = message.replaceAll('\\n', '\n');

            // if (message !== prevText) {
            //     json[key][selectedLanguage] = message;
            //     setAnyChanges(true);
            //     setContent({ ...json });
            // }
        };

        const _removeKey = async (e) => {
            e.stopPropagation();

            const key = e.target.getAttribute('data-id');

            delete content[key];
            // console.log(json[key]);
            onChange(fromJSON(content));

            // setAnyChanges(true);

            utils.hintOk(`Key "${key}" removed`);
        };

        const result = [];

        Object.keys(content).forEach((key) => {
            const text = content[key][selectedLanguage] || '';

            result.push({
                key: key,
                source: content[key]['en'] || '',
                text: text,
                actions: (
                    <>
                        <button
                            onClick={_editText}
                            data-id={key}
                            title="Edit object"
                            className="btn px-1 py-0 btn-sm"
                        >
                            <i className="fa fa-edit pe-none" />
                        </button>
                        <button
                            onClick={_removeKey}
                            data-id={key}
                            title="Remove key"
                            className="btn ms-1 px-1 py-0 btn-sm"
                        >
                            <i className="fas fa-trash pe-none" />
                        </button>
                    </>
                )
            });
        });

        return result;
    }, [content, selectedLanguage, onChange]);

    return (
        <div className="mt-3 position-relative">
            {!editingText && !importing && (
                <div style={{ filter: editingText ? 'dsadsblur(2px)' : '' }}>
                    <div className="d-flex align-items-end mb-2">
                        <div>
                            <div className="small text-secondary">Display text</div>
                            <Select
                                value={languages && languages.find((e) => e.value === selectedLanguage)}
                                name="languages"
                                isDisabled={!languages}
                                onChange={(e) => {
                                    setSelectedLanguage(e.value);
                                }}
                                isLoading={!languages}
                                options={languages}
                                placeholder="Select language"
                                className="react-select-sm w-250px me-2"
                                classNamePrefix="select"
                            />
                        </div>

                        <button
                            onClick={() => setEditingText({ key: '' })}
                            className="btn btn-outline-primary btn-sm ms-auto"
                        >
                            <i className="fas fa-plus me-1" /> Add Key
                        </button>
                        <button
                            onClick={() => setImporting(true)}
                            className="btn btn-outline-primary btn-sm ms-2"
                        >
                            <i className="fas fa-download fa-sm me-1" /> Import and merge
                        </button>
                    </div>

                    <div className="mt-3">
                        {!!content && (
                            <Table
                                saveStateId="translation-keys-list"
                                columns={columns}
                                data={data}
                                className="table-sm table-striped"
                            />
                        )}
                    </div>
                </div>
            )}

            {!!editingText && (
                <div
                    className={
                        'fade d-flex align-items-center justify-content-center py-3 ' +
                        (!!editingText ? 'show' : 'pe-none hide')
                    }
                >
                    <div className="shadow bg-white rounded-3 border w-100 mx-5">
                        <EditText
                            content={content}
                            inputLanguages={languages}
                            inputData={editingText || {}}
                            onBack={setEditingText}
                            onSave={(data) => {
                                const key = data.key;
                                delete data.key;
                                if (!content[key]) utils.hintOk('Key "' + key + '" added');
                                else utils.hintOk('Key "' + key + '" modified');
                                content[key] = data;

                                onChange(fromJSON(content));
                            }}
                        />
                    </div>
                </div>
            )}

            {!!importing && (
                <div
                    className={
                        'fade d-flex align-items-center justify-content-center py-5 ' +
                        (!!importing ? 'show' : 'pe-none hide')
                    }
                >
                    <div className="shadow bg-white rounded-3 border col-12 col-sm-10 col-md-9 col-lg-8">
                        <ImportAndMerge
                            content={content}
                            inputLanguages={languages}
                            onBack={setImporting}
                            onImport={(data) => {
                                const newValue = fromJSON(data);
                                const delta = newValue.length - value.length;
                                onChange(newValue);
                                utils.hintOk(
                                    'Merged. ' +
                                        (delta >= 0
                                            ? utils.bytesToStr(delta) + ' added'
                                            : utils.bytesToStr(-delta) + ' reduced')
                                );
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const columns = [
    {
        Header: 'Key',
        accessor: 'key',
        cellClass: 'text-break',
        size: '300'
    },
    {
        Header: 'Source',
        accessor: 'source'
    },
    {
        Header: 'Text',
        accessor: 'text'
    },
    {
        accessor: 'actions',
        disableSortBy: true,
        size: '80'
    }
];

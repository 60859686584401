import { Components } from 'consts';
import { Link } from 'react-router-dom';

const _components = {};

Components.forEach((e) => {
    _components[e.id] = e;
});

export const ComponentHeader = (props) => {
    const { id, gameId, url } = props;
    let component = _components[id] || {};

    let breadcrumb = props.breadcrumb ? props.breadcrumb.slice() : [];

    breadcrumb.splice(0, 0, {
        name: component.name,
        url: url
    });

    breadcrumb.splice(0, 0, {
        name: 'Home',
        url: 'game/' + gameId
    });

    let activeBreadcrumbItem = breadcrumb.pop();

    let path = '';

    breadcrumb.forEach((e, index) => {
        path += '/' + e.url;
        e.fullUrl = path + (e.suffix || '');
    });

    return (
        <div style={{ zIndex: 41 }} className="card position-sticky top-0 mb-3 bg-gray-100">
            <div className="card-body d-flex align-items-center">
                <div>
                    <div className="d-flex align-items-center">
                        <h4>{component.name}</h4>
                        <span className="text-secondary small ms-2">v{component.version}.0</span>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-1 small">
                            {breadcrumb.map((item) => (
                                <li key={item.fullUrl} className="breadcrumb-item">
                                    <Link to={item.fullUrl}>{item.name || item.url}</Link>
                                </li>
                            ))}

                            {activeBreadcrumbItem && (
                                <li className="breadcrumb-item active">
                                    {activeBreadcrumbItem.name || activeBreadcrumbItem.url}
                                </li>
                            )}
                        </ol>
                    </nav>
                </div>

                <div className="ms-auto d-flex align-items-center">{props.children}</div>
            </div>
        </div>
    );
};

export const ComponentHeaderSimple = (props) => {
    const { gameId, name } = props;

    return (
        <div className="d-flex align-items-center">
            <div>
                <h4>{name}</h4>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small">
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {name}
                        </li>
                    </ol>
                </nav>
            </div>

            <div className="ms-auto d-flex align-items-center">{props.children}</div>
        </div>
    );
};

import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import className from 'classnames';
import { utils, ms } from '@gpg-web/utils';
import Sidebar from './partials/Sidebar';
import Topbar from './partials/Topbar';
import Footer from './partials/Footer';

import { LoadingPage } from '@gpg-web/react';

// import GameSettings from './pages/game/settings/GameSettings';

import Modals from './modals';

import { ScrollToTop, Spinner } from '@gpg-web/react';

import { getProfile, userContext, userEvents } from 'services/user';
import { organizationContext, getMembers } from 'services/organization';
import { getGames, getGameGroups } from 'services/game';
import { gameContext } from 'services/game';

import socket from 'services/socket';
import TutorialSidebar from 'pages/Tutorials/TutorialSidebar';

let requestProfileData = window.location.pathname !== '/provider/logout';

// const mql = window.matchMedia(`(min-width: 800px)`);
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: ms('5m'),
            retry: 0,
            refetchOnWindowFocus: false
        }
    }
});
window.queryClient = queryClient;

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            // sidebarDocked: mql.matches,
            members: [],
            games: [],
            gameGroups: [],
            sidebarOpen: true,
            game: {},
            loading: requestProfileData,
            loggedIn: false
        };

        this.setSidebarOpen = this.setSidebarOpen.bind(this);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.toogleSidebar = this.toogleSidebar.bind(this);
        this.setGame = this.setGame.bind(this);
        this.setGames = this.setGames.bind(this);
        this.setGameGroups = this.setGameGroups.bind(this);
    }

    setGameGroups(gameGroups) {
        this.setState({ gameGroups });
    }

    setGames(games) {
        this.setState({ games });
    }

    setGame(game) {
        this.setState({ game });
    }

    setSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    mediaQueryChanged() {
        // this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
    }

    toogleSidebar(open) {
        this.setSidebarOpen(!this.state.sidebarOpen);
    }

    // componentWillMount()
    // {
    //     // mql.addListener(this.mediaQueryChanged);
    // }

    componentWillUnmount() {
        // mql.removeListener(this.mediaQueryChanged);
    }

    updateProfile() {
        // this.setState({user: profile});
    }

    componentDidMount() {
        if (requestProfileData) {
            getProfile()
                .then((profile) => {
                    socket.open();
                    // setTimeout(() =>
                    // {

                    this.setState({
                        user: profile,
                        loggedIn: true
                    });

                    getMembers()
                        .then((members) => {
                            getGames()
                                .then((games) => {
                                    getGameGroups()
                                        .then((gameGroups) => {
                                            this.setState({
                                                loading: false,
                                                members: members,
                                                gameGroups: gameGroups.sort((a, b) =>
                                                    a.name > b.name ? 1 : -1
                                                ),
                                                games: games.sort((a, b) => (a.name > b.name ? 1 : -1))
                                            });
                                        })
                                        .catch(utils.hintError);
                                })
                                .catch(utils.hintError);
                        })
                        .catch(utils.hintError);

                    // }, 100);
                })
                .catch(utils.hintError);
        } else {
            socket.open();
        }

        userEvents.onprofileupdate = () => {
            getProfile().then((profile) => {
                this.setState({ user: profile });
            });
        };
    }

    render() {
        if (this.state.loading) {
            return <LoadingPage />;
        }

        return (
            <QueryClientProvider client={queryClient}>
                <userContext.Provider value={this.state.user}>
                    <organizationContext.Provider
                        value={{
                            members: this.state.members,
                            games: this.state.games,
                            setGames: this.setGames,
                            gameGroups: this.state.gameGroups,
                            setGameGroups: this.setGameGroups
                        }}
                    >
                        <gameContext.Provider
                            value={{
                                setGame: this.setGame,
                                game: this.state.game
                            }}
                        >
                            <Topbar toogleSidebar={this.toogleSidebar} />

                            <Sidebar
                                docked={this.state.sidebarOpen && !!this.state.game.id}
                                open={this.state.sidebarOpen && !!this.state.game.id}
                                onSetOpen={this.setSidebarOpen}
                            >
                                <TutorialSidebar>
                                    <div id="wrapper">
                                        <div className="wrapper-content pb-3 pt-4 bg-gray-100 px-4">
                                            <Outlet />
                                        </div>
                                        <Footer />
                                    </div>
                                </TutorialSidebar>
                            </Sidebar>

                            <ScrollToTop container="page-content" />

                            <div id="hintsContainer" className="hints-container" />
                            <div id="connecting" className={className("text-gray-700", {"d-none": socket.connected})}> <Spinner /> Connecting... </div>

                            <Modals />
                        </gameContext.Provider>
                    </organizationContext.Provider>
                </userContext.Provider>
            </QueryClientProvider>
        );
    }
}

export default App;

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useContext } from 'react';
import { getDocs } from 'services/docs';
import { userContext } from 'services/user';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import CreateDoc from './CreateDoc';
import Filter from './Filter';

export const DocsPage = (props) => {
    const [docs, setDocs] = useState(null);
    const [filter, setFilter] = useState({ type: {}, component: {} });
    const [edit, setEdit] = useState(null);
    const { lvl } = useContext(userContext);
    useEffect(() => {
        getDocs().then(setDocs).catch(utils.hintError);
    }, []);

    let filtered;

    if (docs) {
        filtered = docs;

        if (Object.values(filter.type).some((e) => e))
            filtered = filtered.filter((e) => !!filter.type[e.type]);

        if (Object.values(filter.component).some((e) => e))
            filtered = filtered.filter((e) => !!filter.component[e.component]);
    }

    const updateDocs = (doc) => {
        const exists = docs.find((e) => e.id === doc.id);

        if (exists) Object.assign(exists, doc);
        else docs.push(doc);

        setDocs(docs.slice());
    };

    return (
        <>
            <div className="container-lg">
                <div className="row">
                    <div className="col-sm-12 col-md-3">
                        <Filter data={filter} setData={setFilter} />
                    </div>
                    <div className={'col-sm-12 col-md-' + (lvl > 0 ? '6' : '8')}>
                        {docs &&
                            filtered.map((e) => (
                                <div className="mb-3 pb-2 border-bottom" key={e.id}>
                                    <div className="fw-bold">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-decoration-none"
                                            href={e.url}
                                        >
                                            {e.name}
                                        </a>
                                        {lvl > 0 && (
                                            <button onClick={() => setEdit(e)} className="ms-1 btn btn-sm">
                                                <i className="fas fa-edit" />
                                            </button>
                                        )}
                                    </div>
                                    <div className="text-success my-1">
                                        {e.type} - {e.component}
                                    </div>
                                    <div>{e.description}</div>
                                </div>
                            ))}

                        {!docs && (
                            <div className="mt-4">
                                <Skeleton className="m-2" width="100%" height={60} />
                                <Skeleton className="m-2" width="100%" height={60} />
                                <Skeleton className="m-2" width="100%" height={60} />
                                <Skeleton className="m-2" width="100%" height={60} />
                                <Skeleton className="m-2" width="100%" height={60} />
                            </div>
                        )}
                    </div>
                    {lvl > 0 && (
                        <div className="col-sm-12 col-md-3">
                            <CreateDoc edit={edit} setEdit={setEdit} onSave={updateDocs} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

import { useEffect, useState } from 'react';
import cleanupExplanation from '../../../../../img/cleanupExlanation.png';
import { utils } from '@gpg-web/utils';
import { getEnvironments, getVersions } from 'services/settings';
import { Spinner } from '@gpg-web/react';
import { getConfigs } from 'services/config';

function tryJSONParse(jsonstr) {
    try {
        const content = JSON.parse(jsonstr);

        return content;
    } catch (err) {
        return false;
    }
}

function testContentCleanup(contentStr, localEnvironments, gameVersions) {
    const content = tryJSONParse(contentStr);

    if (content) {
        const unusedEnviroments = new Set();
        const unusedGameVersions = new Set();
        const configVesions = content.versions || {};

        for (let configVersion of Object.keys(configVesions)) {
            let shouldRemoveVersion = false;

            if (!localEnvironments.some(({ path }) => configVersion.startsWith(path))) {
                unusedEnviroments.add(configVersion.slice(0, configVersion.indexOf('/') + 1));
                shouldRemoveVersion = true;
            }

            if (!gameVersions.some((version) => configVersion.endsWith(version + '/'))) {
                unusedGameVersions.add(configVersion.slice(configVersion.indexOf('/') + 1, -1));
                shouldRemoveVersion = true;
            }

            if (shouldRemoveVersion) delete configVesions[configVersion];
        }

        if (unusedEnviroments.size || unusedGameVersions.size) {
            return {
                cleanupData: {
                    envs: Array.from(unusedEnviroments),
                    versions: Array.from(unusedGameVersions)
                },
                removedLength: contentStr.length - JSON.stringify(content).length
            };
        }
    }

    return { cleanupData: null, removedLength: 0 };
}

export const CleanupInfo = ({ gameId }) => {
    const [data, setData] = useState(null);

    // useEffect(() => {
    //     if (!gameId) return;

    //     const loadData = async () => {
    //         const localEnvironments = await getEnvironments(gameId);

    //         const gameVersions = await getVersions(gameId);

    //         const configs = await getConfigs(gameId);

    //         const envConfigs = configs.filter((config) => config.contentType === 'environmental');

    //         const cleanupConfigList = [];

    //         let removedSymbols = 0;

    //         for (let config of envConfigs) {
    //             const { cleanupData, removedLength } = testContentCleanup(
    //                 config.content,
    //                 localEnvironments,
    //                 gameVersions
    //             );

    //             cleanupData && cleanupConfigList.push({ name: config.name + ' - Default', ...cleanupData });

    //             removedSymbols += removedLength;

    //             if (config.conditionalValues) {
    //                 for (let conditionKey in config.conditionalValues) {
    //                     const { cleanupData: conditionCleanupData, removedLength: conditionRemovedLength } =
    //                         testContentCleanup(
    //                             config.conditionalValues[conditionKey].value,
    //                             localEnvironments,
    //                             gameVersions
    //                         );

    //                     conditionCleanupData &&
    //                         cleanupConfigList.push({
    //                             name: config.name + ' - ' + conditionKey,
    //                             ...conditionCleanupData
    //                         });

    //                     removedSymbols += conditionRemovedLength;
    //                 }
    //             }
    //         }

    //         return { list: cleanupConfigList, removedSymbols };
    //     };

    //     loadData().then(setData).catch(utils.hintError);
    // }, [gameId]);

    return (
        <div className="row py-3">
            <h6 className="fw-bold">How Cleanup Script Works</h6>
            <div className="w-100 img-thumbnail">
                <div className="text-muted">Example:</div>
                <img className="w-100 " src={cleanupExplanation} alt="Config cleanup example" />
            </div>
            <div className="alert alert-warning mt-3 ">
                All previously deleted Local Environments / Game Versions will be removed from configs
            </div>
            {/* <div className="mt-3">
                <h6 className="fw-bold">Cleanup Details</h6>

                {data ? (
                    <div className="p-2">
                        {data.list && data.list.length !== 0 ? (
                            <>
                                <div className="row text-muted border-bottom py-1 ">
                                    <div className="col-6">Name</div>
                                    <div className="col-3 text-center">Enviroments</div>
                                    <div className="col-3 text-center">Versions</div>
                                </div>
                                {data.list.map(({ name, envs, versions }) => (
                                    <div className="row py-2 border-bottom" key={name}>
                                        <div className="col-6">{name}</div>
                                        <div className="col-3 text-center">
                                            {envs.map((env) => (
                                                <div key={env}>
                                                    <i className="fas fa-minus" /> {env}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-3 text-center">
                                            {versions.map((version) => (
                                                <div key={version}>
                                                    <i className="fas fa-minus" />
                                                    {version}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                                <div className="row text-muted justify-content-end fw-bold mt-2">
                                    This action will reduce overall config size by{' '}
                                    {(data.removedSymbols / 1024).toFixed(2)} KB{' '}
                                </div>
                            </>
                        ) : (
                            <div className="text-muted fw-bod">
                                No possible environments / game versions to cleanup were found.
                                <br /> There is no need to perform this task
                            </div>
                        )}
                    </div>
                ) : (
                    <Spinner />
                )}
            </div> */}
        </div>
    );
};

import { useState, useEffect } from 'react';

const BasicJsonTextEditor = (props) => {
    const { value, onChange, onlyObjectsAllowed } = props;
    const [error, setError] = useState();
    const [validating, setValidating] = useState(false);

    useEffect(() => {
        setValidating(false);
        setError(null);
        let timer;

        if (value) {
            setValidating(true);

            timer = setTimeout(() => {
                try {
                    const parsed = JSON.parse(value);

                    if (onlyObjectsAllowed) {
                        if (typeof parsed !== 'object') setError('JSON is ivalid!');
                    }
                } catch (err) {
                    setError('JSON is ivalid!');
                }

                setValidating(false);
            }, 300);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [value, onlyObjectsAllowed]);

    return (
        <>
            <textarea
                readOnly={props.readOnly}
                name={props.name}
                rows={props.rows}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                onChange={onChange}
                value={value}
                className="form-control"
                id={props.id}
                placeholder={props.placeholder}
            />
            <div className="d-flex">
                {error && (
                    <div className="text-danger small mt-1">
                        <i className="fa fa-exclamation-circle me-2" /> {error}
                    </div>
                )}
                <div className={'text-muted small mt-1' + (validating ? '' : ' invisible')}>
                    Validating...
                </div>
            </div>
        </>
    );
};

export { BasicJsonTextEditor };

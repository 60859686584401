import { semver } from '@gpg-web/utils';

const VerBadge = ({ children }) => <span className="bg-gray-200 rounded-2 fw-bold px-2">v{children}</span>;

export const GameVersionView = ({ value }) => {
    if (!value) {
        return <span className="fw-bold">Not selected</span>;
    }
    if (value === 'all') {
        return <span className="fw-bold">All Game Versions</span>;
    }

    if (Array.isArray(value)) {
        return value
            .sort((a, b) => (semver.gt(a, b) ? -1 : 1))
            .map((val) => (
                <span className="text-nowrap d-inline-block mb-1 me-2" key={val}>
                    <VerBadge>{val}</VerBadge>
                </span>
            ));
    }

    return <VerBadge>{value}</VerBadge>;
};

import { ComponentsMap } from 'consts';
import { TooltipWrapper } from 'components';
import { utils } from '@gpg-web/utils';
import className from 'classnames';

const copyPasteBuffer = {};

const triggersIcons = {
    time: 'fas fa-calendar-alt',
    conditions: 'fas fa-random'
};

export const TriggersDropdown = ({
    path,
    configs,
    componentId,
    variantId,
    configId,
    versions,
    onChange,
    onSetupTriggers,
    asButton
}) => {
    const component = ComponentsMap[componentId];

    if (!(component && component.triggers && component.triggers.length > 0)) return;

    const configUid = componentId + configId;

    const value = versions[path]?.['values'][variantId]?.[configUid];
    const version = value?.version;

    if (!version) return;

    function copy(argument) {
        for (let triggerId of component.triggers) {
            if (value[triggerId]) copyPasteBuffer[triggerId] = JSON.stringify(value[triggerId]);
        }
        utils.hintOk('Copied');
    }

    function paste(argument) {
        for (let triggerId of component.triggers) {
            if (copyPasteBuffer[triggerId]) value[triggerId] = JSON.parse(copyPasteBuffer[triggerId]);
        }
        utils.hintOk('Pasted');
        onChange();
    }

    function snapshotTriggers() {
        const triggers = {};
        for (let triggerId of component.triggers) {
            if (value[triggerId]) triggers[triggerId] = value[triggerId];
        }
        return JSON.stringify(triggers);
    }

    function promoteVariant() {
        const triggers = snapshotTriggers();

        let variants = versions[path]['values'];

        for (let id in variants) {
            if (variantId === id) continue;

            let value = variants[id]?.[configUid];
            if (value.version) {
                Object.assign(value, JSON.parse(triggers));
            }
        }

        onChange();
        utils.hintOk('Successfully promoted for every ' + configId);
    }

    function promoteConfig() {
        const triggers = snapshotTriggers();

        for (let config of configs) {
            const uid = config.component + config.id;
            if (uid === configUid) continue;

            const configComponent = ComponentsMap[config.component];

            if (!(configComponent && configComponent.triggers && configComponent.triggers.length > 0)) {
                continue;
            }

            const value = versions[path]['values'][variantId]?.[uid];

            const hasVersion = !!value?.version;
            if (!hasVersion) continue;

            const triggersClone = JSON.parse(triggers);
            for (let triggerId of configComponent.triggers) {
                if (triggersClone[triggerId]) value[triggerId] = triggersClone[triggerId];
            }
        }

        onChange();
        utils.hintOk('Successfully promoted for every config in ' + variantId);
    }

    function promoteAll() {
        const triggers = snapshotTriggers();

        for (let config of configs) {
            const uid = config.component + config.id;

            const configComponent = ComponentsMap[config.component];

            if (!(configComponent && configComponent.triggers && configComponent.triggers.length > 0)) {
                continue;
            }

            let variants = versions[path]['values'];

            for (let id in variants) {
                if (uid === configUid && variantId === id) continue;

                const value = versions[path]['values'][id]?.[uid];

                const hasVersion = !!value?.version;
                if (!hasVersion) continue;

                const triggersClone = JSON.parse(triggers);
                for (let triggerId of configComponent.triggers) {
                    if (triggersClone[triggerId]) value[triggerId] = triggersClone[triggerId];
                }
            }
        }

        onChange();
        utils.hintOk('Successfully promoted for every config on every variant');
    }

    function clean() {
        if (
            versions &&
            versions[path] &&
            versions[path].values[variantId] &&
            versions[path].values[variantId][configUid]
        ) {
            versions[path].values[variantId][configUid] = {
                version: versions[path].values[variantId][configUid].version
            };
        }
        utils.hintOk('Triggers removed');
        onChange();
    }

    return (
        <span className="dropdown ms-2">
            <button
                className={className('btn btn-sm dropdown-toggle py-0', {
                    'btn-light': asButton,
                    'text-gray-600 border-0 mb-1 ': !asButton
                })}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {component.triggers.map((e) => (
                    <i
                        className={className(
                            triggersIcons[e],
                            'fa-sm me-2',
                            value[e] ? 'text-danger' : 'text-gray-500'
                        )}
                    />
                ))}
                Triggers
            </button>
            <ul className="dropdown-menu">
                <li>
                    <button
                        onClick={() =>
                            onSetupTriggers({
                                value: value,
                                component: componentId,
                                path: path,
                                variant: variantId,
                                config: configUid
                            })
                        }
                        className="dropdown-item"
                        type="button"
                    >
                        Setup
                    </button>
                </li>
                <li>
                    <button onClick={clean} className="dropdown-item" type="button">
                        Clean
                    </button>
                </li>
                <li>
                    <button onClick={copy} className="dropdown-item">
                        Copy
                    </button>
                </li>
                <li>
                    <button onClick={paste} className="dropdown-item">
                        Paste
                    </button>
                </li>
                <li>
                    <TooltipWrapper
                        icon={false}
                        content={
                            'Clone current triggers configuration for ' + configId + ' on each variant'
                        }
                    >
                        <button onClick={promoteVariant} className="dropdown-item">
                            Share for each variant
                        </button>
                    </TooltipWrapper>
                </li>
                <li>
                    <TooltipWrapper
                        icon={false}
                        content={'Clone current triggers configuration for every config on ' + variantId}
                    >
                        <button onClick={promoteConfig} className="dropdown-item">
                            Share for each config
                        </button>
                    </TooltipWrapper>
                </li>
                <li>
                    <TooltipWrapper
                        icon={false}
                        content="Clone current triggers configuration for every config on every variant"
                    >
                        <button onClick={promoteAll} className="dropdown-item">
                            Share for everything
                        </button>
                    </TooltipWrapper>
                </li>
            </ul>
        </span>
    );
};

import { useContext } from 'react';
import { userContext } from 'services/user';
import { useGame } from './UseGame';
import { useRoles } from './UseRoles';

export const useUserIAM = () => {
    const user = useContext(userContext);

    const game = useGame();

    const gameId = game.id;

    const iam = game.iam;

    const roles = useRoles(gameId);

    if (!gameId) return {};

    return { user, userPermissions: game.userPermissions, roles, iam, gameId };
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { runScript } from 'services/automation';
import { Checkbox } from 'components';
import { utils } from '@gpg-web/utils';
import { Modal, Spinner } from '@gpg-web/react';
import AutomationProcessModal from '../../../../modals/AutomationProcess';
import { getScriptData } from './data';
import { InputSelect } from './components/InputSelect';
import { InputSelectButton } from './components/InputSelectButton';
import { InputStorage } from './components/InputStorage';
import { InputRadioGroup } from './components/InputRadioGroup';
import { InputSelectCheck } from './components/InputSelectCheck';

export const SetupScriptModal = (props) => {
    let { game, show, onHide, scriptId, customizeData, customFn, name, onFinish } = props;
    let data = getScriptData()[scriptId] || {};

    if (customizeData && data) {
        data = customizeData(data) || data;
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [options, setOptions] = useState({});
    const [optionsBuilder, setOptionsBuilder] = useState([]);

    const gameId = game.id;

    const catchError = (err) => {
        setError(err);
        setLoading(false);
    };

    useEffect(() => {
        if (!game.id) return;

        let data = getScriptData()[scriptId];

        if (customizeData) {
            data = customizeData(data);
        }

        if (!data) return;

        let queryOptions = null;

        if (window.location.search) {
            const query = new URLSearchParams(window.location.search);
            queryOptions = {};
            for (let opt of data.options) {
                if (query.has(opt.id)) {
                    queryOptions[opt.id] = {
                        value: query.get(opt.id),
                        disabled: true
                    };
                }
            }
        }
        if (queryOptions) {
            data = applyQueryOptions(data, queryOptions);
        }

        async function initOptions() {
            setLoading(true);

            const _options = {};
            for (let opt of data.options) {
                _options[opt.id] = opt.value;

                if (opt.asyncLoad) {
                    opt.options = [];
                    opt.ready = false;
                }

                if (opt.asyncInit) {
                    opt.ready = false;
                }

                // if (opt.type === 'select_button' && opt.asyncLoad) {
                //     opt.options = [];
                //     opt.ready = false;
                // }
                // if (opt.type === 'select_check' && opt.asyncLoad) {
                //     opt.options = [];
                //     opt.ready = false;
                // }
            }

            setOptions(_options);
            setOptionsBuilder(data.options.slice());

            setLoading(false);

            const asyncOptions = { game, scriptId };

            for (let opt of data.options) {
                if (opt.asyncLoad || opt.asyncInit) {
                    if (opt.asyncLoad) {
                        const options = await opt.asyncLoad(game.id, asyncOptions);
                        opt.options = options;
                    }
                    if (opt.asyncInit) {
                        const result = await opt.asyncInit(game.id, asyncOptions);
                        if (result.value !== undefined) {
                            _options[opt.id] = result.value;
                            delete result.value;
                            /**
                             * Don't need to setOptions({..._options});
                             * 
                             * Next code
                             * setOptionsBuilder(data.options.slice());
                             * will rerender page
                             */
                            setOptions(_options);
                        }
                        Object.assign(opt, result);
                    }

                    opt.ready = true;
                    setOptionsBuilder(data.options.slice());
                }
                // if (opt.type === 'select' && opt.asyncLoad) {
                //     const options = await opt.asyncLoad(gameId, scriptId);
                //     opt.options = options;
                //     opt.ready = true;

                //     setOptionsBuilder(data.options.slice());
                // }
                // if (opt.type === 'select_button' && opt.asyncLoad) {
                //     const options = await opt.asyncLoad(gameId, scriptId);
                //     opt.options = options;
                //     opt.ready = true;
                //     setOptionsBuilder(data.options.slice());
                // }
                // if (opt.type === 'select_check' && opt.asyncLoad) {
                //     const options = await opt.asyncLoad(gameId, scriptId);
                //     opt.options = options;
                //     opt.ready = true;
                //     setOptionsBuilder(data.options.slice());
                // }
            }
        }

        initOptions().catch(catchError);
    }, [game, scriptId, customizeData]);

    const _runScript = async () => {
        if (data.validate) {
            try {
                await data.validate(options, gameId);
            } catch (err) {
                return setError(err.message);
            }
        }

        setError(null);

        utils.popup('Running...');

        if (customFn) {
            customFn(gameId, scriptId, options)
                .then((result) => {
                    utils.popup('hide');
                    _onHide();

                    if (result) {
                        result.name = data.name;
                        result.gameId = gameId;
                        AutomationProcessModal.show(result);
                    }

                    onFinish && onFinish();
                })
                .catch(setError);
        } else {
            runScript(gameId, scriptId, options)
                .then((result) => {
                    utils.popup('hide');

                    _onHide();

                    result.name = data.name;
                    result.gameId = gameId;
                    AutomationProcessModal.show(result);

                    onFinish && onFinish();
                })
                .catch(setError);
        }
    };

    const _onHide = () => {
        setLoading(false);
        setError(null);
        onHide(null);
    };

    return (
        <Modal
            show={show}
            onHide={_onHide}
            title={
                <div className="d-flex align-items-center">
                    <div className="service-icon me-3" style={{ backgroundColor: data.color }}>
                        <i className={data.icon || 'fas fa-robot fa-fw'}></i>
                    </div>
                    <div>{data.name + ' options'}</div>
                </div>
            }
        >
            <div className="modal-body px-4">
                {optionsBuilder.length !== 0 && (
                    <div className="row">
                        {optionsBuilder.map((opt, index) => {
                            const { type, id, setupProps } = opt;
                            if (type === 'select') {
                                return (
                                    <InputSelect
                                        {...opt}
                                        value={options[id]}
                                        onChange={(val) => setOptions({ ...options, [id]: val })}
                                        key={id}
                                        gameId={gameId}
                                    />
                                );
                            } else if (type === 'select_button') {
                                return (
                                    <InputSelectButton
                                        {...opt}
                                        value={options[id]}
                                        onChange={(val) => setOptions({ ...options, [id]: val })}
                                        key={id}
                                        parentName={name}
                                        gameId={gameId}
                                    />
                                );
                            } else if (type === 'select_check') {
                                return (
                                    <InputSelectCheck
                                        {...opt}
                                        value={options[id]}
                                        onChange={(val) => setOptions({ ...options, [id]: val })}
                                        key={id}
                                        gameId={gameId}
                                    />
                                );
                            } else if (type === 'storage') {
                                return (
                                    <InputStorage
                                        {...opt}
                                        value={options[id]}
                                        onChange={(val) => setOptions({ ...options, [id]: val })}
                                        key={id}
                                        gameId={gameId}
                                    />
                                );
                            } else if (type === 'radio_group') {
                                return (
                                    <InputRadioGroup
                                        {...opt}
                                        value={options[id]}
                                        onChange={(val) => setOptions({ ...options, [id]: val })}
                                        key={id}
                                        gameId={gameId}
                                    />
                                );
                            } else if (type === 'checkbox') {
                                return (
                                    <div key={id} className="col-md-6 col-sm-12 mb-3">
                                        <Checkbox
                                            {...setupProps}
                                            name={id}
                                            title={opt.name}
                                            value={options[id]}
                                            onChange={(e) =>
                                                setOptions({ ...options, [id]: !!e.target.checked })
                                            }
                                        />
                                    </div>
                                );
                            } else if (type === 'info') {
                                return (
                                    <div key={id} className="col-md-12 col-sm-12 mb-3">
                                        <div className="alert alert-info mb-0">
                                            <i className="fas fa-info-circle fa-sm me-2" />
                                            {opt.text}
                                        </div>
                                    </div>
                                );
                            } else if (typeof opt.render === 'function') {
                                return opt.render({
                                    ...opt,
                                    value: options[id],
                                    key: id,
                                    gameId: gameId,
                                    onChange: (val) => setOptions({ ...options, [id]: val })
                                });
                            }

                            return '';
                        })}
                    </div>
                )}
            </div>
            <div className="modal-footer ps-4">
                {loading && (
                    <div>
                        <Spinner /> Loading ...
                    </div>
                )}
                {error && (
                    <div className="text-danger">
                        <i className="fas fa-exclamation-circle fa-sm me-2" />
                        {error}
                    </div>
                )}
                <button data-bs-dismiss="modal" className="btn btn-secondary btn-sm ms-auto">
                    Cancel
                </button>
                <button disabled={loading} onClick={_runScript} className="btn btn-success btn-sm">
                    <i className="fas fa-play me-2 fa-sm" />
                    Run Process
                </button>
            </div>
        </Modal>
    );
};

export default SetupScriptModal;

function applyQueryOptions(data, options) {
    const newData = { ...data };

    newData.options = [...data.options];

    for (let optionId in options) {
        const optionIndex = newData.options.findIndex((opt) => opt.id === optionId);

        if (optionIndex > -1) {
            newData.options[optionIndex] = {
                ...newData.options[optionIndex],
                ...options[optionId]
            };
        } else {
            newData.options.push(options[optionId]);
        }
    }

    return newData;
}

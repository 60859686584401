import React from 'react';
// style="border-radius: 120px;"
// style="display: flex;height: 100%;align-items: center;margin: 10px;"

const Popup = (props) => {
    return (
        <>
            <div
                className="modal"
                id="center_hint"
                data-backdrop="static"
                data-keyboard="false"
                style={{ backgroundColor: '#00000098' }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div
                        id="center_hint_modal_body"
                        className="text-light d-flex fluid align-items-center justify-content-center"
                    >
                        <div
                            className="spinner-border"
                            role="status"
                            style={{ width: '3rem', height: '3rem' }}
                        >
                            <span className="sr-only">Loading...</span>
                        </div>

                        <div className="m-3 h4" id="center_hint_content" style={{ maxWidth: '85%' }}>
                            Loading...
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Popup;

import Tippy from '@tippyjs/react';
import { useFileVersions, TooltipWrapper } from 'components';
import Skeleton from 'react-loading-skeleton';
import { str } from '@gpg-web/utils';

const FileViewContentPopover = ({ path, version, onSelect, canDiscard = true }) => {
    const { versions, isFetching, error } = useFileVersions(path);

    if (!path) {
        return (
            <div className="alert alert-danger px-2 py-1">
                <i className="fas fa-exclamation-circle me-2" />
                Please select file path first
            </div>
        );
    }

    const setSelectedVersion = (version) => {
        if (!version.id && !canDiscard) return;

        onSelect({
            path: path,
            version: version.id
        });
    };

    return (
        <div className="card" style={{ minWidth: '300px' }}>
            <div title={path} className="card-header p-2 px-3 fw-bold">
                {str.trim(path, 30, true)}
            </div>
            <div className="card-body p-0">
                <div>
                    {isFetching && (
                        <div className="px-2 py-2">
                            <Skeleton height={30} width={280} count={3} />
                        </div>
                    )}

                    {!!error && <div className="alert alert-danger px-2 py-1 small m-2">{error}</div>}

                    {!isFetching && (
                        <ul className="list-group">
                            {version && canDiscard && (
                                <div className="border rounded-pill m-2 d-flex align-items-center px-2 py-1">
                                    <div>#{version} selected</div>
                                    <TooltipWrapper icon={false} content="Discard">
                                        <button
                                            onClick={() => setSelectedVersion({})}
                                            className="btn btn-sm ms-auto px-2 border-0"
                                        >
                                            <i className="fas fa-times text-danger" />
                                        </button>
                                    </TooltipWrapper>
                                </div>
                            )}
                            <ul
                                className="overflow-y-auto list-group list-group-flush"
                                style={{ maxHeight: '245px' }}
                            >
                                {versions.map((e, index) => {
                                    let selected = version === e.id;

                                    if (e.isDraft) return null;

                                    return (
                                        <VersionListItem
                                            key={e.id}
                                            version={e}
                                            selected={selected}
                                            onSelect={setSelectedVersion}
                                        />
                                    );
                                })}
                            </ul>
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

const VersionListItem = (props) => {
    const { selected, onSelect, version } = props;

    const { id, message } = version;

    return (
        <button
            className={
                'list-group-item list-group-item-action' + (selected ? ' list-group-item-success' : '')
            }
            onClick={() => (selected ? onSelect({}) : onSelect(version))}
        >
            <span className="small">
                #{id} - <span className="fw-light fst-italic">"{message}"</span>
            </span>
        </button>
    );
};

export const FileViewPopover = (props) => {
    let appendTo = props.appendTo;

    if (props.show && !appendTo) {
        appendTo =
            document.getElementsByClassName('modal show')[0] || document.getElementById('page-content');
    }

    return (
        <Tippy
            appendTo={appendTo}
            interactive={true}
            reference={props.parent}
            offset={[0, 5]}
            visible={!!props.show}
            placement="bottom"
            delay={[300, 0]}
            animation="shift-away"
            zIndex={1}
            onClickOutside={props.onHide}
            onHide={props.onHide}
            content={!!props.show && <FileViewContentPopover {...props} />}
        />
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { io } from 'socket.io-client';
import { utils } from '@gpg-web/utils';

const socket = io({
    autoConnect: false,
    reconnectionAttempts: 10,
    reconnectionDelay: 10000
});

window.socket = socket;

// socket.on('connect_error', (err) => {
//     utils.hint('Web Socket error: ' + err.message, { alert: 'danger' });
// });

// socket.on('tmp-ws-test', function(data) {
//     console.log("WS TEST:", data);
// })
socket.on('connect', (reason) => {
    document.getElementById('connecting')?.classList.add('d-none');
});

socket.on('disconnect', (reason) => {
    document.getElementById('connecting')?.classList.remove('d-none');
});

export default socket;

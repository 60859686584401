import { memo } from 'react';

const classes = {
    // "application/octet-stream": ""
    'application/javascript': 'file-code',
    'application/json': 'file-code',
    // "application/ld+json": "file-code",
    'application/msword': 'file-word',
    'application/pdf': 'file-pdf',
    'application/sql': 'file-code',
    // "application/vnd.api+json": "",
    'application/vnd.ms-excel': 'file-exel',
    'application/vnd.ms-powerpoint': 'file-powerpoint',
    'application/vnd.oasis.opendocument.text': 'file-word',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'file-powerpoint',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-exel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-word',
    // "application/x-www-form-urlencoded": "",
    'application/xml': 'file-code',
    'application/zip': 'file-archive',

    'audio/mpeg': 'file-audio',
    'audio/webm': 'file-audio',
    'audio/ogg': 'file-audio',
    'audio/mp4': 'file-audio',

    'video/mpeg': 'file-video',
    'video/mp4': 'file-video',
    'video/ogg': 'file-video',
    'video/webm': 'file-video',
    'video/x-msvideo': 'file-video',
    'video/x-flv': 'file-video',

    'image/avif': 'file-image',
    'image/gif': 'file-image',
    'image/jpeg': 'file-image',
    'image/png': 'file-image',
    'image/svg+xml': 'file-image',
    'image/webp': 'file-image',

    'text/css': 'file-code',
    'text/csv': 'file-csv',
    'text/html': 'file-code',
    'text/xml': 'file-code',
    'text/javascript': 'file-code',
    'text/plain': 'file-alt',
    'text/php': 'file-code',

    'Folder': 'folder-open',
    'folder': 'folder',
    'return': 'reply',
    'Translation': 'language',

    'livefire.translation': 'language',
    'livefire.popup': 'file-invoice',
    'livefire.piggy-bank': 'file-invoice-dollar',
    'livefire.daily-reward': 'file-contract',
    'livefire.tle': 'file-contract',
    'livefire.tlo': 'file-contract',
    'livefire.store': 'file-invoice-dollar'
};

const FileIcon = memo(function FileIcon(props) {
    const { mime, contentType, className, size } = props;
    const fileClass = classes[contentType] || classes[mime] || 'file';

    return (
        <span className={className}>
            <i className={'fas fa-w fa-' + fileClass} style={{ fontSize: size }} />
        </span>
    );
});

export { FileIcon };

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { utils } from '@gpg-web/utils';
import { updateProfilePlayerSave } from 'services/players';
// import Select from 'react-select'
import { useGame } from 'components';
import { Spinner } from '@gpg-web/react';

function _getValue(obj, path) {
    if (Array.isArray(path) && path.length > 0) {
        var key = path.shift();

        if (obj[key] !== undefined) {
            return _getValue(obj[key], path);
        } else {
            return null;
        }
    } else {
        return obj;
    }
}

function getValue(obj, path) {
    if (typeof obj !== 'object') return null;

    if (typeof path == 'string') {
        path = path.split('.');
    }

    return _getValue(obj, path);
}

function _setValue(obj, path, value) {
    let ref = obj;
    const lastKey = path.pop();

    for (let key of path) {
        ref = ref[key];
        if (ref === undefined) return null;
    }

    ref[lastKey] = value;

    return value;
}

function setValue(obj, path, value) {
    if (typeof obj !== 'object') return null;

    if (typeof path == 'string') {
        path = path.split('.');
    }

    return _setValue(obj, path, value);
}

const KeyOptionsSetup = (props) => {
    const { saveTypes, saves, profile, setSaves } = props;

    const game = useGame();

    const gameId = game.id;

    const updateSaves = () => {
        setSaves({ ...saves });
    };

    return (
        <div className="row mt-5">
            {saveTypes
                .filter((type) => type.keyOptions && type.keyOptions.length > 0)
                .map((type) => {
                    return (
                        <div key={type.id} className="col-md-6 col-sm-12 mb-2">
                            <div className="small text-muted mb-2">{type.name}:</div>
                            {type.keyOptions.map((e, index) => {
                                return (
                                    <KeyOptionItem
                                        key={type.id + index}
                                        option={e}
                                        save={saves[type.id]}
                                        gameId={gameId}
                                        saveType={type}
                                        profile={profile}
                                        updateSaves={updateSaves}
                                    />
                                );

                                // if (!saves[type.id]) return

                                // const value = getValue(saves[type.id].data, e.path);
                                // let error = null;

                                // if (value === null) error = <div className="text-danger">Value not found by <span className="font-monospace small fw-bold">[{e.path}]</span> path</div>;

                                // return <OptionItem save={saves[type.id]}  />

                                // <div key={type.id + e} className="d-flex my-1">
                                //     <span className="fw-bold">{e.name}</span>
                                //     {
                                //         !error && <>

                                //         </>
                                //     }
                                //     {!!error && error}
                                // </div>
                            })}
                        </div>
                    );
                })}
        </div>
    );
};

const KeyOptionItem = (props) => {
    let { save, option, gameId, profile, saveType, updateSaves } = props;
    const [editing, setEditing] = useState(null);

    let error = null;

    if (typeof save === 'string') {
        error = save;
        save = null;
    }

    let value = null;

    if (save && save.data) value = getValue(JSON.parse(save.data), option.path);

    if (save !== undefined) {
        if (!save.data) error = 'No data';
        else if (value === null)
            error = (
                <span>
                    Value not found by <span className="font-monospace small">[{option.path}]</span> path.{' '}
                    <Link to={'/game/' + gameId + '/save-type/' + saveType.id}>Go to settings</Link>
                </span>
            );
        else if (typeof value !== option.type)
            error = (
                <span>
                    The value does not match the <span className="font-monospace small">[{option.type}]</span>{' '}
                    type. <Link to={'/game/' + gameId + '/save-type/' + saveType.id}>Go to settings</Link>
                </span>
            );
    }

    const saveValue = () => {
        utils.confirm('Are you sure you want to save your changes', (yes) => {
            if (yes) {
                utils.popup('saving');

                let content = JSON.parse(save.data);
                setValue(content, option.path, editing);
                content = JSON.stringify(content);
                save.data = content;

                updateProfilePlayerSave(gameId, profile.id, saveType.id, save)
                    .then((result) => {
                        Object.assign(save, result);
                        updateSaves();

                        utils.popup('hide');
                        setEditing(null);
                    })
                    .catch(utils.hintError);
            }
        });
    };

    return (
        <div className="d-flex my-2 align-items-center">
            <span className="fw-bold text-nowrap me-3">{option.name}: </span>
            {!error && (
                <>
                    {save === undefined ? (
                        <div>
                            <Spinner /> Downloading
                        </div>
                    ) : (
                        <>
                            {editing !== null ? (
                                <>
                                    {option.type === 'string' && (
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            value={editing}
                                            onChange={(e) => setEditing(e.target.value)}
                                        />
                                    )}

                                    {option.type === 'number' && (
                                        <input
                                            className="form-control form-control-sm"
                                            type="number"
                                            value={editing}
                                            onChange={(e) => setEditing(Number(e.target.value))}
                                        />
                                    )}

                                    {option.type === 'boolean' && (
                                        <select
                                            value={editing === true ? 'true' : 'false'}
                                            onChange={(e) =>
                                                e.target.value === 'true'
                                                    ? setEditing(true)
                                                    : setEditing(false)
                                            }
                                            className="form-select form-select-sm"
                                        >
                                            <option disabled value="">
                                                Select option
                                            </option>
                                            <option value="true">true</option>
                                            <option value="false">false</option>
                                        </select>
                                    )}

                                    <button onClick={saveValue} className="btn btn-sm ms-2 text-success">
                                        <i className="fas fa-check" />
                                    </button>
                                    <button
                                        onClick={(e) => setEditing(null)}
                                        className="btn btn-sm ms-2 text-danger"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                </>
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control form-control-sm"
                                        value={value}
                                    />
                                    <button onClick={(e) => setEditing(value)} className="btn btn-sm ms-2">
                                        <i className="fas fa-edit" />
                                    </button>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
            {!!error && <div className="text-danger">ERR: {error}</div>}
        </div>
    );
};

export default KeyOptionsSetup;

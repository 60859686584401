/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useMemo, useRef } from 'react';
import { getStorageFileContent } from 'services/storage';
import { diffLines } from 'diff';
import { utils, json } from '@gpg-web/utils';
import { Spinner } from '@gpg-web/react';
import { VersionLabel } from 'components';

const escapeHtml = (str) => str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

export const FileComparison = (props) => {
    const { gameId, fileName, parseJSON, versions } = props;

    let { prevVersionId, nextVersionId, getContent } = props;

    getContent = getContent || getStorageFileContent;

    // if (Number(prevVersionId) > Number(nextVersionId))
    // {
    //     let tmp = prevVersionId;
    //     prevVersionId = nextVersionId;
    //     nextVersionId = tmp;
    // }

    const [customPrevVersionId, setCustomPrevVersionId] = useState(prevVersionId);
    const [prevContent, setPrevContent] = useState(null);
    const [nextContent, setNextContent] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        if (gameId && fileName && prevVersionId && nextVersionId) {
            setContentLoading(true);

            getContent(gameId, fileName, prevVersionId, [])
                .then((content1) => {
                    if (parseJSON) content1 = JSON.parse(content1);
                    else content1 = escapeHtml(content1);
                    setPrevContent(content1);
                    getContent(gameId, fileName, nextVersionId, [])
                        .then((content2) => {
                            if (parseJSON) content2 = JSON.parse(content2);
                            else content2 = escapeHtml(content2);
                            setNextContent(content2);
                            setContentLoading(false);
                        })
                        .catch(utils.hintError);
                })
                .catch(utils.hintError);
        }
    }, [gameId, fileName, prevVersionId, nextVersionId, parseJSON, getContent]);

    const diffContent = useMemo(() => {
        if (nextContent && prevContent) {
            let htmlContent = nextContent;
            let htmlOlderContent = prevContent;

            if (typeof htmlContent === 'object') htmlContent = json.print(htmlContent);
            if (typeof htmlOlderContent === 'object') htmlOlderContent = json.print(htmlOlderContent);

            let older = '';
            let newer = '';

            // let diff = diffChars(htmlOlderContent, htmlContent);
            // console.log(diff);

            // diff.forEach((part) => {

            //     if (part.removed)
            //     {
            //         if (part.count > 10)
            //         {
            //             older += part.value;
            //         }
            //         else
            //         {
            //             older += '<span class="diff-removed-char">' + part.value + '</span>';
            //         }
            //     }
            //     else if (part.added)
            //     {
            //         if (part.count > 10)
            //         {
            //             newer += part.value;
            //         }
            //         else
            //         {
            //             newer += '<span class="diff-added-char">' + part.value + '</span>';
            //         }
            //     }
            //     else
            //     {
            //         older += part.value;
            //         newer += part.value;
            //     }
            // });

            let diff = diffLines(htmlOlderContent, htmlContent);
            // diff = diffLines(older, newer);
            // older = newer = "";

            // console.log(diff);

            diff.forEach((part) => {
                if (part.removed) {
                    older += '<span class="diff-line diff-removed">' + part.value + '</span>';
                } else if (part.added) {
                    newer += '<span class="diff-line diff-added">' + part.value + '</span>';
                } else {
                    older += part.value;
                    newer += part.value;
                }
            });

            return { older, newer };
        }

        return {};
    }, [nextContent, prevContent]);

    const prevVersionsOptions = useMemo(() => {
        const result = [];

        for (let version of versions) {
            if (isNaN(version.id)) {
                if (version.id === nextVersionId) continue;
                result.push({
                    value: version.id,
                    label: (
                        <>
                            <VersionLabel version={version.id} /> - {version.message}
                        </>
                    )
                });
            } else {
                if (Number(version.id) >= Number(nextVersionId)) continue;
                result.push({
                    value: version.id,
                    label: (
                        <>
                            <VersionLabel version={version.id} /> - {version.message}
                        </>
                    )
                });
            }
        }

        return result;
    }, [versions, nextVersionId]);

    const downloadNewPrevVersion = (e) => {
        const versionId = e.target.value;

        setContentLoading(true);

        getContent(gameId, fileName, versionId, [])
            .then((content1) => {
                if (parseJSON) content1 = JSON.parse(content1);
                else content1 = escapeHtml(content1);
                setPrevContent(content1);
                setCustomPrevVersionId(versionId);
                setContentLoading(false);
            })
            .catch(utils.hintError);
    };

    return (
        <>
            {contentLoading && (
                <div className="d-flex my-5 align-items-center justify-content-center">
                    <Spinner size="70px" width="7px" />
                </div>
            )}

            {!contentLoading && (
                <div className="card shadow-sm">
                    <div className="px-3 row">
                        <div className="col-6 py-3 border-end">
                            <select
                                value={customPrevVersionId}
                                onChange={downloadNewPrevVersion}
                                className="form-select mb-3"
                            >
                                {prevVersionsOptions.map((e) => (
                                    <option key={e.value} value={e.value}>
                                        {e.label}
                                    </option>
                                ))}
                            </select>

                            <pre
                                dangerouslySetInnerHTML={{
                                    __html: diffContent.older
                                }}
                            />
                        </div>

                        <div className="col-6 py-3 border-start">
                            <div className="h5 text-muted mb-3 mt-2">
                                <VersionLabel version={nextVersionId} />
                            </div>
                            <pre
                                dangerouslySetInnerHTML={{
                                    __html: diffContent.newer
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export const FileComparisonModal = (props) => {
    const { onCancel } = props;

    const modalRef = useRef(null);

    useEffect(() => {
        let cb = onCancel;

        const modal = modalRef.current;

        modal.addEventListener('hidden.bs.modal', cb);

        return () => {
            modal.removeEventListener('hidden.bs.modal', cb);
        };
    }, [onCancel]);

    useEffect(() => {
        const modal = new window.bootstrap.Modal(modalRef.current);

        setTimeout(() => {
            modal.show();
        });

        return () => {
            modal.dispose();
        };
    }, []);

    return (
        <div className="modal fade" tabIndex="-1" ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">File Comparison</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <FileComparison {...props} />
                </div>
            </div>
        </div>
    );
};

import { useState, useMemo } from 'react';
import { toJSON } from './Utils';
import Select from 'react-select';
import { getStorageAsset, getStorageFileContent } from 'services/storage';
import { TooltipWrapper, useGame, VersionLabel } from 'components';
import { Table } from '@gpg-web/react';
import { utils } from '@gpg-web/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const View = ({ content, languages, selectedLanguage, setSelectedLanguage, path, versionId }) => {
    const [versionToCompare, setVersionToCompare] = useState(null);
    const [contentToCompare, setContentToCompare] = useState(null);

    const game = useGame();
    const gameId = game.id;
    const queryClient = useQueryClient();

    const {
        isFetching: isFileFetching,
        error: fileError,
        data: file
    } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/')],
        queryFn: () => getStorageAsset(gameId, path),
        enabled: !!(gameId && path)
    });

    const data = useMemo(() => {
        if (!content || !selectedLanguage) return [];

        // console.log('Building table...');

        const result = [];

        if (contentToCompare) {
            const prevKeys = Object.keys(contentToCompare);

            for (let key of prevKeys) {
                if (!content[key]) {
                    const text = contentToCompare[key][selectedLanguage] || '';

                    result.push({
                        _rowAttributes: {
                            className: 'opacity-3'
                        },
                        key: key,
                        select: (
                            <button
                                onClick={() => {}}
                                data-id={key}
                                title="Key details"
                                className="btn btn-sm mx-3"
                            >
                                <i className="fa fa-info-circle pe-none" />
                            </button>
                        ),
                        source: contentToCompare[key]['en'] || '',
                        text: text,
                        size: text.length,
                        changes: <i title="Key was removed" className="fas fa-times-circle text-danger" />,
                        changesLvl: 6
                    });
                }
            }
        }

        Object.keys(content).forEach((key) => {
            const text = content[key][selectedLanguage] || '';

            let changes,
                changesLvl = 0;

            if (contentToCompare) {
                if (!contentToCompare[key]) {
                    changesLvl = 4;
                    changes = <i title="Key was added" className="fas fa-plus-circle text-success" />;
                } else {
                    const prevText = contentToCompare[key][selectedLanguage] || '';

                    if (text !== prevText) {
                        changesLvl = 2;
                        if (text === '')
                            changes = (
                                <i
                                    title="Text removed for this language"
                                    className="fas fa-circle text-danger"
                                />
                            );
                        else if (prevText === '')
                            changes = (
                                <i
                                    title="Text added for this language"
                                    className="fas fa-circle text-success"
                                />
                            );
                        else
                            changes = (
                                <i
                                    title="Text changed for this language"
                                    className="fas fa-circle text-warning"
                                />
                            );
                    } else {
                        let prevLangsAmount = Object.keys(contentToCompare[key]).length;
                        let langsAmount = Object.keys(content[key]).length;

                        if (prevLangsAmount > langsAmount) {
                            changesLvl = 3;
                            changes = (
                                <i
                                    title="Some translations was removed for this key"
                                    className="fas fa-language text-danger"
                                />
                            );
                        } else if (prevLangsAmount < langsAmount) {
                            changesLvl = 1;
                            changes = (
                                <i
                                    title="Some translations was changed for this key"
                                    className="fas fa-circle text-warning"
                                />
                            );
                        }
                    }
                }
            }

            result.push({
                key: key,
                source: content[key]['en'] || '',
                text: text,
                size: text.length,
                changes: changes,
                changesLvl: changesLvl
            });
        });

        return result;
    }, [content, contentToCompare, selectedLanguage]);

    const versionOptions = useMemo(() => {
        setVersionToCompare(null);
        setContentToCompare(null);

        if (!file) return [];

        const options = [];

        for (let version of file.versions) {
            if (versionId === version.id) continue;
            options.push({
                value: version.id,
                label: <VersionLabel version={version.id} />
            });
        }

        return options;
    }, [file, versionId]);

    const handleComparison = (version) => {
        if (!version) {
            setContentToCompare(null);
            return;
        }

        utils.popup('loading');

        queryClient
            .fetchQuery({
                queryKey: ['storage', gameId, ...path.split('/'), version, 'content'],
                queryFn: () => getStorageFileContent(gameId, path, version)
            })
            .then((textContent) => {
                utils.popup('hide');
                const jsonContent = toJSON(textContent);
                setContentToCompare(jsonContent);
            })
            .catch((err) => {
                utils.hintError(err);
                setVersionToCompare(null);
            });
    };

    return (
        <div className="mt-3 ">
            <div className="d-flex align-items-end mb-2">
                <div>
                    <div className="small text-secondary">Display text</div>
                    <Select
                        value={languages && languages.find((e) => e.value === selectedLanguage)}
                        name="languages"
                        isDisabled={!languages}
                        onChange={(e) => {
                            setSelectedLanguage(e.value);
                        }}
                        isLoading={!languages}
                        options={languages}
                        placeholder="Select language"
                        className="react-select-sm w-250px me-2"
                        classNamePrefix="select"
                    />
                </div>
                <div>
                    <TooltipWrapper
                        layout="inline-start"
                        size="xs"
                        tooltipClassName={fileError ? 'tooltip-danger' : null}
                        icon={fileError ? 'fas fa-exclamation-circle' : 'fas fa-info-circle'}
                        content={fileError || 'Just display the differences between 2 versions in this table'}
                    >
                        <div className="small text-secondary">Versions comparison</div>
                    </TooltipWrapper>
                    <Select
                        value={versionToCompare}
                        name="versionToCompare"
                        isDisabled={isFileFetching}
                        isClearable
                        onChange={(e) => {
                            setVersionToCompare(e);
                            handleComparison(e && e.value);
                        }}
                        isLoading={isFileFetching}
                        options={versionOptions}
                        placeholder="Select version to compare"
                        className="react-select-sm w-250px me-2"
                        classNamePrefix="select"
                    />
                </div>
            </div>
            <div className="mt-3">
                {!!content && (
                    <Table
                        saveStateId="translation-keys-list"
                        columns={columns}
                        data={data}
                        className="table-sm table-striped"
                    />
                )}
            </div>
        </div>
    );
};

const columns = [
    {
        Header: <i className="fas fa-search fa-sm ms-2" />,
        cellClass: 'ps-3',
        accessor: 'changes',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.changesLvl > rowB.original.changesLvl ? 1 : -1;
        },
        size: '60'
    },
    {
        Header: 'Key',
        accessor: 'key',
        cellClass: 'text-break',
        size: '300'
    },
    {
        Header: 'Source',
        accessor: 'source'
    },
    {
        Header: 'Text',
        accessor: 'text'
    },
    {
        Header: 'Size',
        accessor: 'size',
        size: '60'
    }
];

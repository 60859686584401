import { Suspense, lazy, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import rehypeSlug from 'rehype-slug';
import { TUTORIALS_LIST } from './TutorialList';

export const TutorialView = (props) => {
    const container = useRef(null);

    const { id, section } = props;

    let tutorial = TUTORIALS_LIST.find((t) => t.id === id);

    const { data, isLoading, isFetched } = useQuery({
        queryKey: ['tutorials', tutorial?.id],
        queryFn: () => axios.get(tutorial.file),
        enabled: !!tutorial
    });

    useLayoutEffect(() => {
        if (isFetched && container.current && section) {
            const el = container.current.querySelector(section);

            if (el) el.scrollIntoView({ behavior: 'smooth' });
        }

        return () => {};
    }, [container, isFetched, section]);

    return (
        <div className="" ref={container}>
            <ThemeSelector>
                <div className="markdown-body" style={{ backgroundColor: 'inherit' }}>
                    <Markdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings]}
                    >
                        {data}
                    </Markdown>
                </div>
            </ThemeSelector>
        </div>
    );
};

const LightTheme = lazy(() => import('./LightTheme'));
const DarkTheme = lazy(() => import('./DarkTheme'));

const ThemeSelector = ({ children }) => {
    const docElement = document.documentElement;

    const [isDark, setDark] = useState(docElement.getAttribute('data-bs-theme') === 'dark');

    useEffect(() => {
        const observer = new MutationObserver((mutationsList) => {
            const changes = mutationsList.find(
                (mutation) => mutation.type === 'attributes' && mutation.attributeName === 'data-bs-theme'
            );

            if (changes) {
                setDark(docElement.getAttribute('data-bs-theme') === 'dark');
            }
        });

        observer.observe(docElement, { attributes: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <>
            <Suspense fallback={null}>
                {!isDark && <LightTheme />}
                {isDark && <DarkTheme />}
            </Suspense>
            {children}
        </>
    );
};

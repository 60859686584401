import { useState, useEffect } from 'react';

const getStoredValue = (key, parseFn) => {
    let storedValue = null;
    try {
        storedValue = JSON.parse(window.localStorage.getItem(key));
    } catch (err) {}

    if (storedValue && parseFn) {
        storedValue = parseFn(storedValue);
    }

    return storedValue;
};

export const useStoredState = (key, initialState, parseFn) => {
    key = 'lf_' + key;
    const stored = getStoredValue(key, parseFn);
    const [state, setState] = useState(stored !== null ? stored : initialState);

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
};

import { FileIcon, TooltipWrapper } from 'components';

export const FileNoPreview = ({ file, version, onDownload }) => (
    <div className="d-flex flex-column align-items-center justify-content-center my-5">
        <div className="my-2 mt-3">
            <TooltipWrapper
                layout="inline-end"
                icon="fas fa-question-circle"
                size="md"
                content="You cannot preview this file. For previewing images, videos, sounds - WIP."
            >
                <span className="h5 my-3 text-muted">Preview is not available</span>
            </TooltipWrapper>
        </div>
        <div className="py-4">
            <FileIcon mime={file.mime} size="100px" />
        </div>
        <div>{file.name}</div>
        {version ? (
            <>
                {typeof version === 'string' ? (
                    <div className="mt-4 px-5 fw-bold">Version #{version}</div>
                ) : null}
                {typeof version.id !== 'undefined' ? (
                    <div className="mt-4 px-5 fw-bold">Version #{version.id}</div>
                ) : null}
                {typeof version.message !== 'undefined' ? (
                    <div className="mt-2 px-5">{version.message}</div>
                ) : null}
            </>
        ) : null}
        {onDownload ? (
            <button onClick={onDownload} className="btn btn-sm shadow-sm btn-success mt-4">
                <i className="fas fa-sm me-2 fa-download" /> Download
            </button>
        ) : null}
    </div>
);

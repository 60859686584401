import { utils } from '@gpg-web/utils';
import { TooltipWrapper } from '../tooltips';

export const InputOption = ({
    title,
    name,
    value,
    onChange,
    type,
    placeholder,
    accept,
    readOnly,
    required,
    tooltip,
    id
}) => (
    <div className="mb-3">
        {title !== false && (
            <TooltipWrapper layout="inline-start" size="xs" content={tooltip}>
                <label className="mb-1 small" htmlFor={utils.generateId('input' + name + id + title + type)}>
                    {title || name} {required && <span className="text-danger">*</span>}
                </label>
            </TooltipWrapper>
        )}

        <input
            accept={accept}
            name={name}
            disabled={readOnly}
            onChange={(e) => onChange(name, e.target.value)}
            type={type || 'text'}
            value={value !== undefined ? value : ''}
            className="form-control"
            placeholder={placeholder}
            id={utils.generateId('input' + name + id + title + type)}
        />
    </div>
);

export const TextOption = ({
    title,
    name,
    value,
    onChange,
    placeholder,
    readOnly,
    rows,
    id,
    tooltip,
    required
}) => (
    <div className="mb-3">
        {title !== false && (
            <TooltipWrapper layout="inline-start" size="xs" content={tooltip}>
                <label className="mb-1 small" htmlFor={utils.generateId('text' + id + name + title)}>
                    {title || name} {required && <span className="text-danger">*</span>}
                </label>
            </TooltipWrapper>
        )}
        <textarea
            name={name}
            rows={rows}
            disabled={readOnly}
            onChange={(e) => onChange(name, e.target.value)}
            value={value !== undefined ? value : ''}
            className="form-control"
            id={utils.generateId('text' + id + name + title)}
            placeholder={placeholder}
        />
    </div>
);

export const BoolOption = ({ title, name, value, onChange, readOnly, tooltip, className, id }) => {
    return (
        <div className={'form-check ' + (className || 'mb-2 pb-2')}>
            <input
                name={name}
                type="checkbox"
                className="form-check-input"
                onChange={(e) => onChange(name, e.target.checked)}
                disabled={readOnly}
                id={utils.generateId('bool' + title + name + id)}
                checked={value || false}
            />

            {title !== false && (
                <TooltipWrapper layout="inline-end" size="xs" content={tooltip}>
                    <label
                        className="form-check-label"
                        disabled={readOnly}
                        htmlFor={utils.generateId('bool' + title + name + id)}
                    >
                        {title || name}
                    </label>
                </TooltipWrapper>
            )}
        </div>
    );
};

export const BoolButtonOption = ({ title, name, value, onChange, readOnly, tooltip, className, id }) => {
    return (
        <div>
            <input
                name={name}
                type="checkbox"
                className="btn-check"
                onChange={(e) => onChange(name, e.target.checked)}
                disabled={readOnly}
                id={utils.generateId('boolb' + title + name + id)}
                checked={value || false}
            />

            {title !== false && (
                <TooltipWrapper content={tooltip}>
                    <label
                        className={'btn btn-outline-primary ' + (className || 'btn-primary')}
                        disabled={readOnly}
                        htmlFor={utils.generateId('boolb' + title + name + id)}
                    >
                        {title || name}
                    </label>
                </TooltipWrapper>
            )}
        </div>
    );
};

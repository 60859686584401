/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useMemo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import {
    getConfigs,
    removeConfig,
    duplicateConfig,
    migrateConfig,
    archiveConfig,
    restoreConfig,
    removeConfigBatch,
    archiveConfigBatch,
    restoreConfigBatch,
    duplicateConfigBatch
} from 'services/config';
import {
    ProcessBlockedWrapper,
    IAMButton,
    IAMButtonSmall,
    DeleteButtonSmall,
    UserBadge,
    ComponentHeader,
    useGame,
    SelectionMenu,
    useSelectList,
    makeSelectColumn
} from 'components';
import { useComponent } from './hooks';
import Skeleton from 'react-loading-skeleton';
import { utils, date } from '@gpg-web/utils';
import { SetupScriptModal } from '../automation/scripts';
import { Navigation } from './Navigation';
import { Table } from '@gpg-web/react';
import { getConfigUsage } from 'services/usage';
import { useRemoveComponent } from '../components/RemoveComponent';
import { useQueryClient } from '@tanstack/react-query';

const columns = [
    {
        Header: '',
        accessor: 'displayStatus',
        sortType: (rowA, rowB) => {
            return rowA.original.status > rowB.original.status ? -1 : 1;
        }
    },
    {
        Header: 'Config',
        accessor: 'displayName',
        cellClass: 'text-nowrap',
        sortType: (rowA, rowB) => {
            return rowA.original.name > rowB.original.name ? -1 : 1;
        }
    },
    {
        Header: 'Last modified',
        accessor: 'displayUpdatedAt',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.updatedAt > rowB.original.updatedAt ? 1 : -1;
        }
    },
    {
        Header: 'Modified By',
        accessor: 'displayUpdatedBy',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.updatedBy > rowB.original.updatedBy ? 1 : -1;
        }
    },
    {
        accessor: 'actions',
        cellClass: 'text-nowrap',
        disableSortBy: true
    }
];

let queueId = 0;

export const ConfigsList = (props) => {
    // let { game, setGame } = useContext(gameContext);
    const component = useComponent();

    const [configs, setConfigs] = useState(null);
    const [migrating, setMigrating] = useState(false);
    const game = useGame();
    const queryClient = useQueryClient();

    const gameId = game.id;

    const [RemoveModal, showRemoveModal] = useRemoveComponent(component.name);

    useEffect(() => {
        if (!gameId) return;

        setConfigs(null);
        queueId++;
        const _queueId = queueId;
        getConfigs(gameId, component.url)
            .then((list) => {
                if (queueId === _queueId) setConfigs(list);
            })
            .catch(utils.hintError);
    }, [gameId, component.url]);

    const [selectableIds, setSelectableIds] = useState([]);

    const onFilterChange = useCallback(
        (rows) => {
            if (configs) setSelectableIds(rows.map((row) => row.original.id));
        },
        [configs]
    );

    const { onSelect, isSelected, selectAll, deselectAll, allSelected, getSelected } =
        useSelectList(selectableIds);

    const data = useMemo(() => {
        if (!configs) return [];

        const _removeConfig = (e) => {
            e.stopPropagation();

            const configId = e.target.getAttribute('data-id');
            const deleteConfig = configs.find((config) => config.id === configId);

            showRemoveModal(getConfigUsage(gameId, component.url, configId), deleteConfig, () => {
                utils.popup('removing');

                removeConfig(gameId, component.url, configId)
                    .then(() => {
                        utils.remove(configs, (e) => e.id === configId);

                        setConfigs(configs.slice());

                        utils.popup('hide');
                    })
                    .catch(utils.hintError);
            });
        };

        const _duplicateConfig = (e) => {
            e.stopPropagation();

            const srcId = e.target.getAttribute('data-id');
            // const remoteConfigName = e.target.getAttribute('data-name');

            utils.promt(
                'Please specify a new "' + srcId + '" ID',
                (dstId) => {
                    if (dstId === false) return;

                    dstId = dstId.trim();

                    if (!dstId) return utils.hintError(srcId + ' ID is required.');

                    if (!utils.isValidId(dstId)) {
                        return utils.hintError(
                            'ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed'
                        );
                    }

                    utils.popup('Duplicating ...');

                    duplicateConfig(gameId, component.url, { srcId, dstId })
                        .then((result) => {
                            configs.push(result);

                            setConfigs(configs.slice());

                            utils.popup('hide');
                        })
                        .catch(utils.hintError);
                },
                {
                    value: srcId + '2',
                    label: 'New Config ID',
                    placeholder: 'ID *'
                }
            );
        };

        const _archiveConfig = (e) => {
            e.stopPropagation();
            const configId = e.target.getAttribute('data-id');

            utils.confirm(
                'Are you sure you want to archive this config? You can still restore the config after archiving it.',
                (yes) => {
                    if (!yes) return;

                    utils.popup('Archiving ...');

                    archiveConfig(gameId, component.url, configId)
                        .then((result) => {
                            const exists = configs.find((e) => e.id === configId);
                            exists.status = 'archive';
                            exists.updatedAt = new Date();

                            setConfigs(configs.slice());

                            utils.popup('hide');
                        })
                        .catch(utils.hintError);
                }
            );
        };

        const _restoreConfig = (e) => {
            e.stopPropagation();
            const configId = e.target.getAttribute('data-id');

            utils.confirm('Are you sure you want to restore this config from archive?', (yes) => {
                if (!yes) return;

                utils.popup('Restoring ...');

                restoreConfig(gameId, component.url, configId)
                    .then((result) => {
                        const exists = configs.find((e) => e.id === configId);
                        exists.status = 'live';
                        exists.updatedAt = new Date();

                        setConfigs(configs.slice());

                        utils.popup('hide');
                    })
                    .catch(utils.hintError);
            });
        };

        return configs.map((e, i) => ({
            _rowAttributes: {
                title: (e.status === 'archive' ? 'Archive' : 'Active') + ' - ' + (e.description || e.id)
            },
            globalFilter: e.name + e.id,
            id: e.id,
            onSelect,
            selected: isSelected(e.id),
            displayStatus: e.status === 'archive' ? '📦' : '🟢',
            status: e.status,
            displayName: (
                <div>
                    <Link
                        className="text-decoration-none"
                        to={'/game/' + gameId + '/config/' + component.url + '/view/' + e.id}
                    >
                        {e.id}
                    </Link>
                    <div className="small text-muted">{e.name}</div>
                </div>
            ),
            name: e.name || e.id,
            updatedBy: e.updatedBy,
            displayUpdatedBy: <UserBadge email={e.updatedBy} />,
            updatedAt: new Date(e.updatedAt),
            displayUpdatedAt: date(e.updatedAt, 'ago-1'),
            actions: (
                <>
                    <IAMButtonSmall permissions={['component.config.create']}>
                        <button
                            onClick={_duplicateConfig}
                            data-name={e.name}
                            data-id={e.id}
                            title="Duplicate object"
                            className="btn btn-sm"
                        >
                            <i className="fa fa-copy pe-none" />
                        </button>
                    </IAMButtonSmall>

                    <IAMButtonSmall permissions={['component.config.update']}>
                        <button
                            onClick={e.status === 'archive' ? _restoreConfig : _archiveConfig}
                            data-id={e.id}
                            title={e.status === 'archive' ? 'Restore object' : 'Archive object'}
                            className="btn btn-sm"
                        >
                            {e.status === 'archive' ? (
                                <i className="fas fa-undo-alt pe-none" />
                            ) : (
                                <i className="fa fa-archive pe-none" />
                            )}
                        </button>
                    </IAMButtonSmall>

                    <DeleteButtonSmall
                        createdAt={e.createdAt}
                        createdBy={e.createdBy}
                        deletePermission={'component.config.delete'}
                    >
                        <button
                            onClick={_removeConfig}
                            data-id={e.id}
                            title="Delete object"
                            className="btn btn-sm"
                        >
                            <i className="fa fa-trash pe-none" />
                        </button>
                    </DeleteButtonSmall>
                </>
            )
        }));
    }, [configs, gameId, showRemoveModal, onSelect, isSelected, component.url]);

    const selected = getSelected();

    // const deleteSelected = makeDeleteAction(
    //     selected,
    //     removeConfig,
    //     gameId,
    //     deselectAll,
    //     configs,
    //     setConfigs
    // );

    // const copySelected = makeCopySelectedAction(
    //     selected,
    //     duplicateConfig,
    //     gameId,
    //     deselectAll,
    //     configs,
    //     setConfigs
    // );

    // const updateSelectedStatus = makeUpdateSelectedStatusesAction(
    //     selected,
    //     updateConfig,
    //     gameId,
    //     deselectAll,
    //     configs,
    //     setConfigs
    // );

    const restoreSelected = () => {
        utils.confirm('Are you sure want to restore selected configs?', async (yes) => {
            if (yes) {
                try {
                    utils.popup('Restoring selected configs...');

                    const selectedIds = selected;

                    const { success, error } = await restoreConfigBatch(gameId, component.url, selectedIds);

                    const updatedConfigs = configs.slice();

                    for (let configId of success) {
                        const config = updatedConfigs.find((c) => c.id === configId);

                        if (config) {
                            config.status = 'live';
                            config.updatedAt = new Date();
                        }
                    }

                    setConfigs(updatedConfigs);

                    deselectAll();

                    utils.popup('hide');

                    for (let configId of error) {
                        utils.hintError('Unable to restore config ' + configId);
                    }
                } catch (err) {
                    utils.popup('hide');
                    utils.hintError('Unable to restore selected configs. ' + err);
                }
            }
        });
    };

    const archiveSelected = () => {
        utils.confirm('Are you sure want to archive selected configs?', async (yes) => {
            if (yes) {
                try {
                    utils.popup('Archiving selected configs...');

                    const selectedIds = selected;

                    const { success, error } = await archiveConfigBatch(gameId, component.url, selectedIds);

                    const updatedConfigs = configs.slice();

                    for (let configId of success) {
                        const config = updatedConfigs.find((c) => c.id === configId);

                        if (config) {
                            config.status = 'archive';
                            config.updatedAt = new Date();
                        }
                    }

                    setConfigs(updatedConfigs);

                    deselectAll();

                    utils.popup('hide');

                    for (let configId of error) {
                        utils.hintError('Unable to archive config ' + configId);
                    }
                } catch (err) {
                    utils.popup('hide');
                    utils.hintError('Unable to archive selected configs. ' + err);
                }
            }
        });
    };

    const deleteSelected = () => {
        utils.confirm('Are you sure want to delete selected configs?', async (yes) => {
            if (yes) {
                try {
                    utils.popup('removing');

                    const selectedIds = selected;
                    const { success, error } = await removeConfigBatch(gameId, component.url, selectedIds);

                    setConfigs(configs.filter((e) => success.indexOf(e.id) === -1));

                    utils.popup('hide');

                    deselectAll();

                    for (let configId of error) {
                        utils.hintError('Unable to delete config ' + configId);
                    }
                } catch (err) {
                    utils.popup('hide');
                    utils.hintError('Unable to delete selected configs. ' + err);
                }
            }
        });
    };

    const duplicateSelected = () => {
        utils.promt(
            'Please specify a ID prefix of each config to duplicate',
            async (prefix) => {
                if (prefix === false) return;

                prefix = prefix.trim();

                if (!prefix) return utils.hintError(prefix + ' ID is required.');

                if (!utils.isValidId(prefix)) {
                    return utils.hintError(
                        'ID prefix is not valid. a-z, A-Z, 0-9 and underscore are only allowed'
                    );
                }
                try {
                    utils.popup('Duplicating ...');
                    const selectedIds = selected;
                    const { success, error, data } = await duplicateConfigBatch(
                        gameId,
                        component.url,
                        selectedIds,
                        prefix
                    );

                    for (let configId of success) {
                        configs.push(data[configId]);
                    }

                    setConfigs(configs.slice());

                    utils.popup('hide');

                    deselectAll();

                    for (let configId of error) {
                        utils.hintError('Unable to duplicate config ' + configId);
                    }
                } catch (err) {
                    utils.popup('hide');
                    utils.hintError('Unable to duplicate selected configs. ' + err);
                }
            },
            {
                value: 'Copy_of_',
                label: 'ID Prefix',
                placeholder: 'duplicate_'
            }
        );
    };

    const _migrateConfig = async (gameId, configId, options) => {
        options.componentId = component.id;
        const result = await migrateConfig(gameId, options, 'configMigrate');
        return result;
    };

    return (
        <>
            <Navigation tab={component.id} gameId={gameId} />

            <div className="container-lg">
                <ComponentHeader gameId={gameId} id={component.id}>
                    <IAMButton permissions={['component.config.create']}>
                        <Link
                            to={'/game/' + gameId + '/config/' + component.url + '/create'}
                            className="btn rounded-pill btn-success shadow px-3"
                        >
                            <i className="fa fa-sm fa-plus" /> Add New
                        </Link>
                    </IAMButton>
                    <div className="ms-3">
                        <IAMButton permissions={['component.config.migrate', 'component.storage.migrate']}>
                            <ProcessBlockedWrapper component={component.id}>
                                <button
                                    onClick={() => setMigrating(true)}
                                    className="btn btn-primary rounded-pill px-3 shadow"
                                >
                                    <i className="fas fa-angle-double-down me-1" /> Migrate From
                                </button>
                            </ProcessBlockedWrapper>
                        </IAMButton>
                    </div>
                </ComponentHeader>

                <div className="mt-2">
                    <div className="mb-3">
                        <ProcessBlockedWrapper left="0" component={component.id}>
                            <SelectionMenu
                                isDisabled={!configs}
                                selectAll={selectAll}
                                deselectAll={deselectAll}
                                allSelected={allSelected}
                                getSelected={getSelected}
                                total={configs ? configs.length : 0}
                            >
                                <IAMButton
                                    permissions={['component.config.create']}
                                    layout="inline-start"
                                    size="md"
                                    wrapperClassName={'d-flex align-items-center'}
                                >
                                    <li className="dropdown-item" onClick={duplicateSelected} role="button">
                                        Duplicate selected
                                    </li>
                                </IAMButton>
                                <IAMButton
                                    permissions={['component.config.delete']}
                                    layout="inline-start"
                                    size="md"
                                    wrapperClassName={'d-flex align-items-center'}
                                >
                                    <li className="dropdown-item" onClick={deleteSelected} role="button">
                                        Delete selected
                                    </li>
                                </IAMButton>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <IAMButton
                                    permissions={['component.config.update']}
                                    layout="inline-start"
                                    size="md"
                                    wrapperClassName={'d-flex align-items-center'}
                                >
                                    <li className="dropdown-item" onClick={restoreSelected} role="button">
                                        Restore selected
                                    </li>
                                </IAMButton>
                                <IAMButton
                                    permissions={['component.config.update']}
                                    layout="inline-start"
                                    size="md"
                                    wrapperClassName={'d-flex align-items-center'}
                                >
                                    <li className="dropdown-item" onClick={archiveSelected} role="button">
                                        Archive selected
                                    </li>
                                </IAMButton>
                            </SelectionMenu>
                        </ProcessBlockedWrapper>
                    </div>
                    {!!configs && (
                        <Table
                            saveStateId="remote-config-list"
                            sortBy={[{ id: 'name', desc: false }]}
                            onFilterChange={onFilterChange}
                            columns={[
                                makeSelectColumn(
                                    onSelect,
                                    selectAll,
                                    deselectAll,
                                    allSelected,
                                    selected.length !== 0
                                ),
                                ...columns
                            ]}
                            data={data}
                            className="table-striped"
                        />
                    )}
                </div>

                <SetupScriptModal
                    show={migrating}
                    scriptId="configMigrate"
                    onFinish={() => {
                        setConfigs(null);
                        getConfigs(gameId, component.url).then(setConfigs).catch(utils.hintError);

                        queryClient.removeQueries({
                            queryKey: ['storage', gameId]
                        });
                    }}
                    onHide={() => setMigrating(false)}
                    customFn={_migrateConfig}
                    game={game}
                />

                {!configs && (
                    <div className="mt-4">
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" count={5} height={60} />
                    </div>
                )}
            </div>

            {createPortal(RemoveModal, document.body)}
        </>
    );
};

import { useEffect, useRef } from 'react';
import { getMember } from 'services/organization';
import { utils } from '@gpg-web/utils';

export const Item = (props) => (
    <div className="mb-1">
        <span className="fw-bold">{props.title}:</span> {props.value}
    </div>
);

export const Backdrop = ({ show, onClick, children, absolute, style, className }) => {
    const opaque = useRef();

    useEffect(() => {
        if (show && opaque.current) {
            const elToReset =
                document.getElementsByClassName('modal show')[0] || document.getElementById('page-content');
            if (elToReset) {
                opaque.current.onwheel = function (e) {
                    const scrollTo = elToReset.scrollTop - e.wheelDelta * 0.4;
                    elToReset.scrollTo({ top: scrollTo });
                };
            }
        }
    }, [show]);

    return (
        <>
            <div
                onClick={onClick}
                ref={opaque}
                className={
                    'position-fixed top-0 start-0 backdrop fluid bg-dark fade opacity-2 ' +
                    (show ? 'show' : 'pe-none hide')
                }
            ></div>
            {((absolute && show) || !absolute) && (
                <div
                    style={style}
                    className={utils.className(
                        absolute ? 'position-absolute' : 'position-relative',
                        show ? 'backdrop-top' : '',
                        className
                    )}
                >
                    {children}
                </div>
            )}
        </>
    );
};

export const Spinner = (props) => (
    <span
        className={'spinner-border spinner-border-sm' + (props.className ? ' ' + props.className : '')}
        style={{
            width: props.size || '1rem',
            height: props.size || '1rem',
            borderWidth: props.width || '0.2em'
        }}
        role="status"
        aria-hidden="true"
    ></span>
);

export const VersionLabel = (props) => {
    if (props.version.startsWith('draft'))
        return (
            <span className={props.styled === undefined ? 'fw-bold text-primary' : props.styled}>
                Draft - {props.version.replace('draft.', '')}
            </span>
        );

    return 'Version #' + props.version;
};

export const UserBadge = (props) => {
    const email = props.email;

    const member = getMember(email);

    return (
        <span
            onClick={() => props.onClick && props.onClick(member)}
            role={props.onClick && 'button'}
            className={
                props.border !== false ? 'badge rounded-pill text-dark bg-white border-bottom border-3' : null
            }
        >
            <img className="icon-5 rounded-circle me-2" alt="Member avatar" src={member.picture} />
            {member.name}
        </span>
    );
};

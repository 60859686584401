/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useRef } from 'react';
import JSONEditor from 'jsoneditor/dist/jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { Backdrop } from '../Items';

let edit_mode_preferrence = localStorage.getItem('jsoneditor_mode_preferrence') || 'tree';
let scrollIntoViewTimeout;

export const JSONOption = (props) => {
    const { value, readOnly, title, name, onChange, onBlock } = props;

    const [editing, setEditing] = useState(false);
    const refContainer = useRef(null);
    const refView = useRef();
    const [editor, setEditor] = useState(null);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    const _setEditing = (editing) => {
        onBlock && onBlock(editing);
        setEditing(editing);
        if (editing) {
            clearTimeout(scrollIntoViewTimeout);
            scrollIntoViewTimeout = setTimeout(() => {
                refView.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    };

    // useEffect(() => {
    //     setEditing(false);
    // }, [resetId]);

    useEffect(() => {
        const editor = new JSONEditor(refContainer.current, {
            enableSort: false,
            enableTransform: true,
            modes: editing ? ['tree', 'code'] : [],
            mode: editing ? edit_mode_preferrence : 'view',
            showErrorTable: ['text', 'preview', 'code'],
            onModeChange: (m) => {
                edit_mode_preferrence = m;
                localStorage.setItem('jsoneditor_mode_preferrence', m);
            }
        });

        setEditor(editor);

        return () => {
            editor.destroy();
            setEditor(null);
        };
    }, [editing]);

    useEffect(() => {
        if (!editor) return;

        if (value) {
            try {
                editor.set(JSON.parse(value));
            } catch (err) {
                editor.set('' + value);
            }
            if (value.length < 600 && editor.expandAll) editor.expandAll();
        }
        // else if (value === '') {
        //     editor.set();
        // } else {
        //     editor.set(value);
        // }
    }, [editor, value]);

    useEffect(() => {
        if (!editor) return;
        editor.setName(name);
    }, [editor, name]);

    useEffect(() => {
        if (!editor) return;
        editor.options.onValidationError = (errors) => {
            if (errors.length === 0) setError(false);
            else setError(errors[0].message);
        };
    }, [editor]);

    function save() {
        if (editor.getText() === '') onChange(name, '');
        else onChange(name, JSON.stringify(editor.get()));

        setSaving(true);
        setTimeout(() => {
            _setEditing(false);
            setSaving(false);
        }, 500);
    }

    return (
        <div ref={refView} className={props.className || 'mb-2'}>
            <Backdrop onClick={() => _setEditing(false)} show={editing}>
                <div className="mb-2 d-flex align-items-center pt-2">
                    <label className="me-auto small">{title || name}</label>
                    {!readOnly && (
                        <>
                            {!editing && (
                                <button
                                    onClick={() => _setEditing(true)}
                                    className="btn rounded-pill btn-light ms-2 btn-sm px-3"
                                >
                                    <i className="fas fa-edit me-2" /> Edit
                                </button>
                            )}
                            {editing && (
                                <button
                                    disabled={saving}
                                    onClick={() => _setEditing(false)}
                                    className="btn rounded-pill btn-light ms-2 px-3"
                                >
                                    <i className="fas fa-times fa-sm me-2" />
                                    Cancel
                                </button>
                            )}
                            {editing && (
                                <button
                                    disabled={error || saving}
                                    onClick={save}
                                    className="btn rounded-pill btn-success ms-2 px-3"
                                >
                                    <i className="fas fa-edit fa-sm me-2" />
                                    Save
                                </button>
                            )}
                        </>
                    )}
                </div>

                <div
                    className={'json-editor-view' + (saving ? ' disabled' : '')}
                    style={{ height: '300px', width: '100%' }}
                    ref={refContainer}
                ></div>
            </Backdrop>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { Link } from 'react-router-dom';
import { ComponentsMap } from 'consts';

const tabs = [
    'experiment',
    'gameBalance',
    'store',
    'tle',
    'tlo',
    'translation',
    'popup',
    'dailyReward',
    'piggyBank',
    'patch'
];

export const Navigation = (props) => {
    const { gameId, tab } = props;

    return (
        <ul className="nav nav-tabs mb-3">
            {tabs.map((id) => (
                <li key={id} className="nav-item">
                    <Link
                        to={'/game/' + gameId + '/config/' + ComponentsMap[id].url}
                        className={'nav-link' + (tab === id ? ' active disabled' : '')}
                    >
                        {ComponentsMap[id].name}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export const DefinedEntityTypes = [
    'Currency',
    'Resource',
    'Item',
    'Lootbox',
    'Chest',
    'Key',
    'Card',
    'Suitcase',
    'Character',
    'Builder',
    'Building',
    'Material',
    'Decoration'
];
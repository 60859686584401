import { BrowseStorageModal } from '../../../storage/BrowseStorage';
import React, { useEffect, useState } from 'react';
import { IAMButtonSmall } from 'components';
import { createPortal } from 'react-dom';
import { getFolderUsage } from 'services/usage';
import { Spinner } from '@gpg-web/react';
import { utils } from '@gpg-web/utils';
import { getStorageAsset } from 'services/storage';
import { getConfigsPaths } from 'services/config';
import { ComponentsMap } from 'consts';

export const StorageCreateGameBalance = ({ value, gameId, disabled, onChange }) => {
    const [configNames, setConfigNames] = useState(null);
    const [remoteConfigs, setConfigs] = useState(null);

    const path = value ? (typeof value === 'object' ? value.path : value) : null;

    useEffect(() => {
        getConfigsPaths(gameId, ComponentsMap['gameBalance'].url, true)
            .then((_configs) => {
                setConfigs(_configs);
                setConfigNames(_configs.map((config) => config.id));
            })
            .catch(utils.hintError);
    }, [gameId]);

    useEffect(() => {
        if (!path || !gameId || !remoteConfigs || disabled) return;

        const loadFiles = async () => {
            const _files = [];

            const isFolder = path.endsWith('/');

            if (isFolder) {
                const asset = await getStorageAsset(gameId, path);
                _files.push(...asset.assets);
            } else {
                const storageFile = await getStorageAsset(gameId, path);
                _files.push(...storageFile);
            }

            const usedConfigFiles = {};

            for (let config of remoteConfigs) {
                // if (config.contentType === 'environmental' || config.contentType === 'file') {
                // const content = config.content;

                const usedFile = _files.find((file) => file.path + file.name === config.path);

                if (usedFile) {
                    usedConfigFiles[config.path] = true;
                }
                // }
            }

            return _files.filter(
                (file) => !usedConfigFiles[file.path + file.name] && file.name.endsWith('.json')
            );
        };

        loadFiles().then((res) => {
            const configs = {};

            res.forEach((file) => {
                configs[file.path + file.name] = {
                    id: file.name
                        .split('.json')[0]
                        .replace(/[^A-Z|^a-z|^0-9]+/gm, '')
                        .toLowerCase(),
                    fileId: file.id,
                    create: true
                };
            });

            onChange({
                path: path,
                configs: configs
            });
        });
        // .catch(utils.hintError);
    }, [gameId, path, disabled, remoteConfigs]);

    if (!path) return null;

    const configs = value ? (typeof value === 'object' ? value.configs : null) : null;

    const isLoading = !configs || !remoteConfigs;

    const configKeys = Object.keys(configs || {});

    return (
        <div className="px-2 mt-2">
            {isLoading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <>
                    {configKeys.length ? (
                        <>
                            <div className="row small fw-bold text-muted opacity-4 mb-2 align-items-center">
                                <div className="col-1">Create</div>
                                <div className="col-6">ID</div>
                                <div className="col-5">File Path</div>
                            </div>
                            {configKeys.map((configKey) => {
                                const config = configs[configKey];

                                const id = config.id.trim();

                                let error = {};

                                if (config.create) {
                                    if (configNames.indexOf(id) > -1) {
                                        error['id'] = 'Config with this ID already exists';
                                    } else if (id.length === 0) {
                                        error['id'] = 'Config ID cannot be empty';
                                    }
                                }

                                return (
                                    <div className="row align-items-center mb-3" key={configKey}>
                                        <div className="col-1 ">
                                            <div className="form-check form-check-reverse">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={config.create}
                                                    onChange={(e) =>
                                                        onChange({
                                                            ...value,
                                                            configs: {
                                                                ...value.configs,
                                                                [configKey]: {
                                                                    ...config,
                                                                    create: e.target.checked
                                                                }
                                                            }
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6 position-relative">
                                            <input
                                                type="text"
                                                value={config.id}
                                                className={'form-control form-control-sm'}
                                                disabled={!config.create}
                                                onChange={(e) =>
                                                    onChange({
                                                        ...value,
                                                        configs: {
                                                            ...value.configs,
                                                            [configKey]: {
                                                                ...config,
                                                                id: e.target.value.trim()
                                                            }
                                                        }
                                                    })
                                                }
                                            />
                                            {error['id'] && (
                                                <span
                                                    className="text-danger position-absolute bg-white"
                                                    style={{
                                                        fontSize: '10px',
                                                        bottom: '-7px',
                                                        left: '20px'
                                                    }}
                                                >
                                                    <i className="fas fa-exclamation-triangle me-1" />
                                                    {error['id']}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-5">
                                            <input
                                                type="text"
                                                defaultValue={configKey}
                                                disabled={true}
                                                className="form-control form-control-sm font-monospace"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <span className="text-muted fw-bold small">
                            Not found JSON files for new Game Balance
                        </span>
                    )}
                </>
            )}
        </div>
    );
};

export const InputStorageUsage = ({ value, gameId, requiredFileVersion }) => {
    const [usage, setUsage] = useState(null);

    useEffect(() => {
        if (!value || !gameId) return;
        setUsage(null);
        const path = requiredFileVersion ? value.path : value;
        getFolderUsage(gameId, path)
            .then((usageData) => {
                setUsage(usageData.filter(({ type }) => type === 'gameBalance'));
            })
            .catch(utils.hintError);
    }, [value, gameId, requiredFileVersion]);

    const isLoading = value && !usage;

    return (
        <div className="px-2 mt-1 small text-muted">
            {isLoading && (
                <div>
                    <Spinner /> Searching for Game Balance usage...
                </div>
            )}

            {!isLoading && value && usage && (
                <div>
                    Found {usage.length} Game Balances - Environmental configs which are using selected files
                    <br />
                </div>
            )}
        </div>
    );
};

export const InputStorage = (props) => {
    const {
        name,
        value,
        onChange,
        placeholder,
        requiredFileVersion,
        isFolderSelect,
        foldersOnly,
        gameId,
        addons,
        disabled
    } = props;
    const [showStorageModal, setShowStorageModal] = useState(false);

    const onStorageSelect = (storageObject) => {
        const selectedValue = requiredFileVersion ? storageObject : storageObject.path;

        onChange(selectedValue);

        // onSelect(selectedValue, { gameId, updateBuildOption: onUpdateBuildOptions });
    };

    return (
        <>
            <div className="col-md-12 col-sm-12 mb-3">
                <div className="fw-bold">{name}</div>
                <div className="mt-2 d-flex align-items-center border-bottom px-2 mx-2">
                    {!value && <span className="text-danger">{placeholder || 'No file selected'}</span>}
                    {!!value && (
                        <>
                            <span className="small font-monospace">
                                {typeof value === 'object' && value.path ? value.path : value}
                            </span>
                            {typeof value === 'object' && value.version && (
                                <span className="ms-2 fw-bold small text-muted">(#{value.version})</span>
                            )}
                        </>
                    )}

                    <div className="ms-auto">
                        <IAMButtonSmall permissions={['component.storage.get', 'component.storage.list']}>
                            <button
                                title="Select Config file"
                                onClick={(e) => setShowStorageModal(true)}
                                disabled={disabled}
                                className="btn text-warning btn-sm border-0"
                            >
                                <i className="fas fa-folder-open" />
                            </button>
                        </IAMButtonSmall>
                    </div>
                </div>
                {addons && addons.map((AddonComponent, index) => <AddonComponent {...props} key={index} />)}
            </div>
            {createPortal(
                <BrowseStorageModal
                    requiredFileVersion={requiredFileVersion === true}
                    isFolderSelect={isFolderSelect === true}
                    foldersOnly={foldersOnly === true}
                    show={showStorageModal}
                    onHide={(e) => setShowStorageModal(false)}
                    onSelect={onStorageSelect}
                    gameId={gameId}
                />,
                document.body
            )}
        </>
    );
};

import { TooltipWrapper } from '../tooltips';
import { utils } from '@gpg-web/utils';

export const SelectOption = ({
    title,
    name,
    value,
    onChange,
    readOnly,
    options,
    disabled,
    placeholder,
    tooltip,
    required,
    id
}) => {
    return (
        <div className="mb-3">
            {title !== false && (
                <TooltipWrapper layout="inline-start" size="xs" content={tooltip}>
                    <label className="mb-1 small" htmlFor={utils.generateId('select' + name + id + title)}>
                        {title || name} {required && <span className="text-danger">*</span>}
                    </label>
                </TooltipWrapper>
            )}

            <select
                id={utils.generateId('select' + name + id + title)}
                value={value || ''}
                onChange={(e) => onChange(name, e.target.value)}
                className="form-select"
                disabled={!options || disabled || readOnly}
            >
                {!options ? (
                    <option disabled value={value || ''}>
                        Loading...
                    </option>
                ) : (
                    <>
                        <option disabled value="">
                            {placeholder || ''}
                        </option>
                        {options.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label || opt.value}
                            </option>
                        ))}
                    </>
                )}
            </select>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getConfig, updateConfig, restoreConfig } from 'services/config';
import {
    IAMButton,
    Input,
    TextArea,
    ComponentHeader,
    ProcessBlockedWrapper,
    useGame,
    usePageBlocker
} from 'components';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import { ContentSetup } from './components/Content';
import { Navigation } from './Navigation';
import { useComponent } from './hooks';
import { Triggers } from './components/Triggers';
import { ChangeHistory } from './components/ChangeHistory';
import { ConfigReadOnlyInfo } from './components/ConfigReadOnlyInfo';
import { ExperimentPreview } from './components/ExperimentPreview';

let queueId = 0;

export const ConfigEdit = (props) => {
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState([]);
    const component = useComponent();
    const { configId } = useParams();
    const game = useGame();
    const gameId = game.id;
    const [updateInitialData, , isBlocked] = usePageBlocker(data);

    useEffect(() => {
        if (!gameId) return;
        setData(null);
        queueId++;
        const _queueId = queueId;
        getConfig(gameId, component.url, configId)
            .then((res) => {
                if (_queueId === queueId) setData(updateInitialData(res));
            })
            .catch(utils.hintError);
    }, [gameId, component.url, configId, updateInitialData]);

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    };

    const _updateConfig = (confirm) => {
        if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

        if (!data.content.path) {
            return utils.hintError('Content path is required. Please select a config file');
        }

        if (data.name) data.name = data.name.trim();

        utils.confirm('Are you sure you want to save changes?', function (yes) {
            if (!yes) return;

            utils.popup('saving');

            updateConfig(gameId, component.url, data)
                .then((result) => {
                    utils.popup('hide');
                    setData(updateInitialData(result));
                })
                .catch(utils.hintError);
        });
    };

    const _restoreConfig = () => {
        utils.confirm('Are you sure you want to restore this config from archive?', function (yes) {
            if (!yes) return;

            utils.popup('saving');

            restoreConfig(gameId, component.url, data.id)
                .then((result) => {
                    utils.popup('hide');
                    data.status = 'live';
                    setData(updateInitialData({ ...data }));
                })
                .catch(utils.hintError);
        });
    };

    const status = data && data.status;

    return (
        <>
            <Navigation tab={component.id} gameId={gameId} />

            <div className="container-lg">
                <ComponentHeader
                    gameId={gameId}
                    url={'config/' + component.url}
                    id={component.id}
                    breadcrumb={[{ name: configId }]}
                >
                    {data && <ExperimentPreview />}

                    {data && <ChangeHistory />}

                    {status === 'archive' && (
                        <IAMButton permissions={['component.config.update']}>
                            <button
                                onClick={_restoreConfig}
                                className="btn btn-light rounded-pill px-4 mx-3 shadow"
                            >
                                Restore
                            </button>
                        </IAMButton>
                    )}
                    {status === 'live' && (
                        <IAMButton permissions={['component.config.update']}>
                            <ProcessBlockedWrapper component={component.id}>
                                <button
                                    disabled={!isBlocked}
                                    onClick={_updateConfig}
                                    className="btn btn-success rounded-pill px-4 ms-3 shadow"
                                >
                                    Publish changes
                                </button>
                            </ProcessBlockedWrapper>
                        </IAMButton>
                    )}
                </ComponentHeader>

                {data && (
                    <div className="mt-4">
                        <div className="card shadow mb-4">
                            <div className="row card-body">
                                <div className="col-lg-6 col-md-12">
                                    <ConfigReadOnlyInfo data={data} />
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <Input
                                        name="name"
                                        title="Display Name"
                                        value={data.name}
                                        onChange={handleChange}
                                    />
                                    <TextArea
                                        name="description"
                                        rows={4}
                                        title="Description"
                                        value={data.description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        {component.triggers &&
                            component.triggers.map((e, index) => {
                                let TriggerComponent = Triggers[e];

                                if (!TriggerComponent) return undefined;

                                return (
                                    <TriggerComponent
                                        key={e}
                                        onError={(err) => {
                                            errors[index] = err;
                                            setErrors(errors.slice());
                                        }}
                                        data={data}
                                        setData={setData}
                                        className="shadow mt-4"
                                    />
                                );
                            })}

                        <ContentSetup id={data.id} className="shadow mt-4" data={data} onChange={setData} />
                    </div>
                )}

                {!data && (
                    <div className="mt-4">
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" height={250} />
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" height={300} />
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" height={80} />
                    </div>
                )}
            </div>
        </>
    );
};

import axios from 'axios';
import { utils } from '@gpg-web/utils';

const SERVICE_ACCOUNT_EMAIL = 'server@livefire-333617.iam.gserviceaccount.com';

const axiosGoogleAuth = axios.create();

class GoogleAuthError extends Error {
    constructor() {
        super('Google Authentication failed');
        this.name = 'GoogleAuthError';
    }
}

axiosGoogleAuth.interceptors.response.use(
    async (response) => {
        let responseData = response.data;

        if (response.data instanceof Blob && response.data.type === 'application/json') {
            responseData = JSON.parse(await response.data.text());
        }

        if (responseData && responseData.auth_url) {
            utils.popup('Waiting for autorization...');

            const authWindow = utils.openUrlWindow(responseData.auth_url, '_blank', 520, 570);

            await new Promise((resolve, reject) => {
                const timer = setInterval(function () {
                    if (authWindow) {
                        if (authWindow.closed) {
                            clearInterval(timer);
                            resolve();
                        }
                    } else {
                        clearInterval(timer);
                        reject();
                    }
                }, 100);
            });

            const authResponse = await axios.get('/api/google/auth');

            utils.popup('hide');
            if (authResponse.auth && response.config.url !== '/api/google/auth') {
                return axiosGoogleAuth(response.config);
            }
            if (response.config.url !== '/api/google/auth') {
                throw new GoogleAuthError();
            }

            return Promise.resolve(authResponse);
        }

        return response.data;
    },
    (error) => {
        utils.popup('hide');

        let err = 'Unknown error';

        if (error.response) {
            err = error.response.statusText;

            if (error.response.data);
            {
                let message = error.response.data.msg || error.response.data.message;

                if (message) err = message;
            }
        }

        return Promise.reject(err);
    }
);

const getFileInfo = (url, client = false) => {
    const query = new URLSearchParams();

    client && query.set('client', client);

    return axiosGoogleAuth
        .post('/api/google/drive/file/info?' + query.toString(), { url: url })
        .then((res) => res);
};

const downloadFile = (fileId, mimeType, client = false) => {
    const query = new URLSearchParams();

    query.set('fileId', fileId);

    mimeType && query.set('mimeType', mimeType);

    client && query.set('client', client);

    return axiosGoogleAuth
        .get('/api/google/drive/file/download?' + query.toString(), { responseType: 'blob' })
        .then((res) => res);
};

const getClientAuth = () => {
    return axiosGoogleAuth.get('/api/google/auth').then((res) => res);
};

const authSignOut = () => {
    return axiosGoogleAuth.get('/api/google/signout').then((res) => res);
};

export { getFileInfo, SERVICE_ACCOUNT_EMAIL, downloadFile, getClientAuth, authSignOut };

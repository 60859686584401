import { useState, useEffect, useRef } from 'react';
import { IAMButtonSmall } from '../buttons';
import { BrowseStorage } from '../../pages/game/storage/BrowseStorage';
import { utils } from '@gpg-web/utils';
import { Backdrop } from '../Items';
import { TooltipWrapper } from '../tooltips';

let scrollIntoViewTimeout;

function trimPath(str, limit) {
    if (!str) return str;

    if (str.length > limit) {
        return '…' + str.substr(-limit);
    }
    return str;
}

export const ImagePathOption = ({
    title,
    name,
    value,
    onChange,
    readOnly,
    gameId,
    placeholder,
    onBlock,
    resetId,
    tooltip,
    required
}) => {
    const [browsingStorage, setBrowsingStorage] = useState(false);
    const refView = useRef();

    const hasValue = !!value;

    const _setBrowsingStorage = (browsingStorage) => {
        onBlock && onBlock(browsingStorage);
        setBrowsingStorage(browsingStorage);
        if (browsingStorage) {
            clearTimeout(scrollIntoViewTimeout);
            scrollIntoViewTimeout = setTimeout(() => {
                refView.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    };

    useEffect(() => {
        setBrowsingStorage(false);
    }, [resetId]);

    return (
        <div className="mb-4">
            {title !== false && (
                <TooltipWrapper layout="inline-start" size="xs" content={tooltip}>
                    <div className="me-2 text-nowrap small">
                        {title || name} {required && <span className="text-danger">*</span>}
                    </div>
                </TooltipWrapper>
            )}
            <div
                role={!readOnly && !hasValue ? 'button' : ''}
                onClick={(e) => {
                    !readOnly && !hasValue && _setBrowsingStorage(!browsingStorage);
                }}
                className="d-flex align-items-center small border-bottom w-100 mb-2"
            >
                {hasValue && (
                    <img
                        alt=""
                        src={utils.gameStoragePath(gameId, value)}
                        width="30"
                        height="30"
                        className="me-2"
                    />
                )}
                {!hasValue && <span className="text-muted">{placeholder || 'Select image'}</span>}
                <span className="text-danger font-monospace">{trimPath(value, 36)}</span>
                {!readOnly && (
                    <>
                        <div className="ms-auto">
                            {hasValue && (
                                <button
                                    onClick={(e) => onChange(name, null)}
                                    className="btn text-secondary btn-sm border-0"
                                >
                                    <i className="fas fa-times" />
                                </button>
                            )}
                        </div>
                        <div className="ms-1">
                            <IAMButtonSmall
                                hide
                                permissions={['component.storage.get', 'component.storage.list']}
                            >
                                <button
                                    disabled={!name}
                                    title="Select file"
                                    onClick={(e) => _setBrowsingStorage(!browsingStorage)}
                                    className="btn text-warning btn-sm border-0"
                                >
                                    <i className="fas fa-folder-open" />
                                </button>
                            </IAMButtonSmall>
                        </div>
                    </>
                )}
            </div>

            <Backdrop
                onClick={() => _setBrowsingStorage(false)}
                show={browsingStorage}
                absolute
                style={{ width: '93%' }}
            >
                <div ref={refView} className="mb-4 shadow">
                    <BrowseStorage
                        accept={['image/png', 'image/jpeg']}
                        simplified
                        requiredFileVersion
                        onSelect={(val) => onChange(name, val)}
                        asStringPath
                        onCancel={(e) => _setBrowsingStorage(false)}
                        gameId={gameId}
                    />
                </div>
            </Backdrop>
        </div>
    );
};

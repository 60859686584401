import { useState, useEffect, useMemo, useCallback } from 'react';
import { StorageFileModal } from '../../storage/FileModal';
import { utils, str } from '@gpg-web/utils';
import { Modal, Spinner } from '@gpg-web/react';
import { StatusLabel, SimpleTable, useFileVersions, useGame } from 'components';

const EnvironmentalVersionCell = (props) => {
    const { row } = props;
    const value = props.value || {};

    const { original } = row;

    const { fileVersionsMap, hasVersions } = original;
    console.log(fileVersionsMap);

    const { isProduction } = useGame();

    const hasValue = !!value.version;

    const versionData = fileVersionsMap[value.version] ? fileVersionsMap[value.version] : {};

    const isDraft = hasValue && value.version.startsWith('draft');

    return (
        <div className={'px-2 position-relative ' + (!hasValue ? 'opacity-1' : '')}>
            {hasValue ? (
                <>
                    <div className="text-start text-nowrap my-1">
                        <span
                            className={utils.className(
                                isDraft ? (isProduction ? 'text-danger' : 'text-primary') : ''
                            )}
                        >
                            <i
                                className={utils.className(
                                    'fa-sm me-2',
                                    isDraft && isProduction ? 'fas fa-exclamation-triangle' : 'fas fa-edit'
                                )}
                            />
                            {isDraft ? <>Draft</> : <>#{value.version}</>}
                        </span>
                        <div
                            className="fw-light small"
                            style={{ maxWidth: '180px' }}
                            title={versionData.message}
                        >
                            {versionData.message || hasVersions ? (
                                versionData.message ? (
                                    str.trim(versionData.message, 29)
                                ) : (
                                    <span className="text-danger">Version not found 🚫</span>
                                )
                            ) : (
                                <>
                                    <Spinner size="10px" />
                                    &nbsp;&nbsp;loading...
                                </>
                            )}
                        </div>
                    </div>
                    <div className="position-absolute translate-middle top-30 start-85 small">
                        <StatusLabel value={value.status || 'draft'} />
                    </div>
                </>
            ) : (
                <div className="text-start text-nowrap small">Select...</div>
            )}
        </div>
    );
};

const EnvironmentCell = (props) => {
    const { row, value } = props;

    const { original } = row;

    const { onPromote, readOnly } = original;

    return (
        <div className="text-nowrap position-relative pe-4" style={{ minWidth: '100px' }}>
            {value}
            <div className="position-absolute end-0 top-0">
                {!readOnly && (
                    <button
                        className="btn btn p-2 py-0 text-muted small"
                        onClick={() => onPromote()}
                        aria-expanded="false"
                    >
                        <i className="fas fa-random fa-sm" />
                    </button>
                )}
            </div>
        </div>
    );
};

const PromoteModal = ({ promote, gameVersions, onChange, environments, onHide, data }) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected([]);
    }, [promote]);

    let type = null;
    let value = null;
    let label = null;
    let onPromote = null;
    let list = [];
    let promoteList = [];

    let versionsList = gameVersions.map((v) => ({ value: v.id, label: 'v' + v.id }));

    let environmentsList = environments.map((e) => ({ value: e.id, label: e.id }));

    const onPromoteEnvironment = async (from, to) => {
        Object.keys(data).forEach((key) => {
            if (key.startsWith(from.path)) {
                let path = key.replace(from.path, to.path);
                data[path] = utils.clone(data[key]);
            }
        });
    };

    const onPromoteVersion = async (from, to) => {
        Object.keys(data).forEach((key) => {
            if (key.endsWith(from + '/')) {
                let path = key.replace(from, to);
                data[path] = utils.clone(data[key]);
            }
        });
    };

    if (promote) {
        type = promote.type;
        value = promote.value;
        label = promote.label;
        if (type === 'version') {
            onPromote = onPromoteVersion;
            list = versionsList;
            promoteList = environmentsList;
        } else {
            onPromote = onPromoteEnvironment;
            list = environmentsList;
            promoteList = versionsList;
        }
    }

    let promoteChanges = [];

    if (selected) {
        promoteChanges = selected.map((selectedItem) => {
            return promoteList
                .map((item) => {
                    const newPath = type === 'version' ? item.value + value + '/' : value + item.value + '/';
                    const beforePath =
                        type === 'version'
                            ? item.value + selectedItem.value + '/'
                            : selectedItem.value + item.value + '/';

                    const fileVersion = data[beforePath]?.version;
                    const newFileVersion = data[newPath]?.version;

                    if (!fileVersion && !newFileVersion) return null;
                    if (fileVersion === (newFileVersion ? newFileVersion : fileVersion)) return null;

                    return (
                        <div className="row flex-nowrap text-nowrap mb-1" key={item.value}>
                            <div className="col-3 text-end fw-bold">{item.label}</div>

                            <div
                                className="col-3 text-end text-muted"
                                style={{ textDecoration: 'line-through' }}
                            >
                                {fileVersion ? <>#{fileVersion}</> : 'Empty'}
                            </div>
                            <div className="col-3 text-center">
                                <i className="fas fa-arrow-right" />
                            </div>

                            <div className="col-3 text-start fw-bold">#{newFileVersion}</div>
                        </div>
                    );
                })
                .filter((item) => item);
        });
    }

    return (
        <Modal
            show={!!promote}
            title={
                <>
                    Promoting {type} -<span className="text-promote-from fw-bold mx-2">{label}</span>
                </>
            }
            onHide={onHide}
        >
            <div className="modal-body row">
                <div className="col-6">
                    <h5>Promote to:</h5>
                    <ul className="list-group" style={{ maxHeight: '60vh', overflow: 'auto' }}>
                        {list.map((item) => {
                            if (item.value === value) return null;

                            const isSelected =
                                selected && selected.find((_item) => _item.value === item.value);

                            return (
                                <li className="list-group-item" key={item.value}>
                                    <input
                                        className="form-check-input me-3"
                                        type="checkbox"
                                        checked={isSelected}
                                        onChange={() =>
                                            isSelected
                                                ? setSelected(
                                                      selected.filter((_item) => _item.value !== item.value)
                                                  )
                                                : setSelected([...selected, item])
                                        }
                                        id={'promote-item-' + item.value}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={'promote-item-' + item.value}
                                    >
                                        {item.label}
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="col-6" style={{ maxHeight: '60vh', overflow: 'auto' }}>
                    <h5>Changes</h5>
                    {selected.length ? (
                        <>
                            {promoteChanges.map((changes, i) => {
                                return changes.length !== 0 ? (
                                    <div key={i} className="mb-3 border-bottom">
                                        <h6 className="">
                                            <span className="text-promote-from mx-2">{label}</span>to
                                            <span className="text-promote-to mx-2">{selected[i].label}</span>
                                        </h6>
                                        <div className="px-2">{changes}</div>
                                    </div>
                                ) : (
                                    <div key={i} className="mb-3 border-bottom">
                                        <h6 className="">
                                            <span className="text-promote-from mx-2">{label}</span>to
                                            <span className="text-promote-to mx-2">{selected[i].label}</span>
                                        </h6>
                                        <div className="text-muted fw-bold opacity-3 px-2">No changes</div>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <div className="text-muted fw-bold opacity-3">Select {type} to view changes</div>
                    )}
                </div>
            </div>
            <div className="modal-footer">
                <button data-bs-dismiss="modal" className="btn btn-secondary ">
                    Cancel
                </button>
                <button
                    disabled={!selected}
                    onClick={() => {
                        if (type === 'version') selected.map((item) => onPromote(value, item.value));
                        else
                            selected.map((item) =>
                                onPromote(
                                    { path: value, name: label },
                                    { path: item.value, name: item.label }
                                )
                            );
                        onChange();
                        onHide();
                    }}
                    className="btn btn-success"
                >
                    Promote
                </button>
            </div>
        </Modal>
    );
};

export const ContentViewTable = (props) => {
    const { content, onChange, readOnly, environments, gameVersions } = props;

    const [editingVersion, setEditingVersion] = useState(false);
    const [promote, setPromote] = useState(null);

    const selectedFile = content.path;
    const selectedVersions = content.versions;

    const { versions } = useFileVersions(selectedFile);

    const handleChangeVersion = (e) => {
        onChange(e.path, e.version);
    };

    const onPromote = useCallback((type, value, label) => {
        setPromote({ type, value, label });
    }, []);

    const tableCols = useMemo(() => {
        const cols = [];

        if (!environments) return cols;

        cols.push({
            Header: '',
            accessor: 'environment',
            cellClass: 'border-end',
            headerClass: '',
            disableSortBy: true,
            size: 50,
            Cell: EnvironmentCell
        });

        cols.push(
            ...gameVersions.map((v, i) => ({
                label: 'v' + v.id,
                accessor: v.id.replaceAll('.', '/'),
                disableSortBy: true,
                size: 100,
                _columnAttributes: { 'data-column-version': v.id },
                cellClass: 'p-0 btn btn-sm btn-outline-secondary animation-none border-end d-table-cell',
                headerClass: 'pe-0 d-table-cell text-start',
                Cell: EnvironmentalVersionCell,
                Header: (props) => {
                    const { column } = props;
                    return (
                        <div className="text-nowrap position-relative pe-4" style={{ minWidth: '100px' }}>
                            {column.label}
                            {!readOnly && (
                                <span className="position-absolute end-0 me-1">
                                    <button
                                        className="btn btn p-2 py-0 text-muted small"
                                        title="Promote Version"
                                        onClick={() => onPromote('version', v.id, column.label)}
                                    >
                                        <i className="fas fa-random fa-sm" />
                                    </button>
                                </span>
                            )}
                        </div>
                    );
                },
                onCellClick: (e) => {
                    const { row, column } = e;
                    const version = column.id.replaceAll('/', '.');

                    const env = row.original._rowAttributes['data-row-env'];
                    const envVersionPath = env + version + '/';

                    setEditingVersion(envVersionPath);
                }
            }))
        );

        return cols;
    }, [gameVersions, environments, onPromote, readOnly]);

    const tableData = useMemo(() => {
        const data = [];

        if (!environments || !gameVersions || !selectedVersions || !versions) return data;

        const fileVersionsMap = {};
        versions.forEach((v) => (fileVersionsMap[v.id] = v));

        for (let env of environments) {
            const row = {
                environment: env.id,
                fileVersionsMap: fileVersionsMap,
                hasVersions: versions.length > 0,
                // environments: environments,
                readOnly: readOnly,
                onPromote: () => onPromote('environment', env.id, env.name),
                _rowAttributes: { 'data-row-env': env.id }
            };
            for (let version of gameVersions) {
                const path = env.id + version.id + '/';
                const selectedVersion = selectedVersions[path];

                row[version.id.replaceAll('.', '/')] = selectedVersion;
            }

            data.push(row);
        }

        return data;
    }, [environments, gameVersions, selectedVersions, versions, onPromote, readOnly]);

    const editVersionSeparatorIndex = editingVersion ? editingVersion.slice(0, -1).lastIndexOf('/') + 1 : 0;

    return (
        <>
            <SimpleTable columns={tableCols} data={tableData} className="table-bordered" />

            <StorageFileModal
                path={selectedFile}
                version={selectedVersions[editingVersion]?.version}
                show={!!editingVersion}
                onHide={(e) => setEditingVersion(false)}
                readOnly={readOnly}
                gameVersion={editingVersion ? editingVersion.slice(editVersionSeparatorIndex, -1) : ''}
                environment={editingVersion ? editingVersion.slice(0, editVersionSeparatorIndex) : ''}
                onSelect={(e) => handleChangeVersion({ path: editingVersion, version: e.version })}
            />

            <PromoteModal
                promote={promote}
                onChange={onChange}
                gameVersions={gameVersions}
                environments={environments}
                data={selectedVersions}
                onHide={() => setPromote(null)}
            />
        </>
    );
};

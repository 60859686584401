import axios from 'axios';

const getAllProfiles = (gameId) => {
    return axios.get('/api/players/' + gameId + '/all');
};

const findProfile = (gameId, search) => {
    return axios.get('/api/players/' + gameId + '/find', { params: { search } });
};

const getProfile = (gameId, profileId) => {
    return axios.get('/api/players/' + gameId + '/profile/' + profileId);
};

const updateProfile = (gameId, profile) => {
    return axios.post('/api/players/' + gameId + '/profile', profile);
};

const getProfilePlayerSave = (gameId, profileId, saveId) => {
    return axios.get('/api/players/' + gameId + '/profile/' + profileId + '/save', { params: { saveId } });
};

const updateProfilePlayerSave = (gameId, profileId, saveId, save) => {
    return axios.post('/api/players/' + gameId + '/profile/' + profileId + '/save?saveId=' + saveId, save);
};

const getPlayerSaves = (gameId) => {
    return axios.get('/api/players/' + gameId + '/playerSave/all');
};

const getPlayerSave = (gameId, playerSaveId) => {
    return axios.get('/api/players/' + gameId + '/playerSave/' + playerSaveId);
};

const addPlayerSave = (gameId, playerSave) => {
    return axios.post('/api/players/' + gameId + '/playerSave', playerSave);
};

const copyPlayerSave = (gameId, options) => {
    return axios.post('/api/players/' + gameId + '/playerSave/copy', options);
};

const updatePlayerSave = (gameId, playerSave) => {
    return axios.put('/api/players/' + gameId + '/playerSave', playerSave);
};

const removePlayerSave = (gameId, playerSaveId, playerSaveName) => {
    return axios.delete('/api/players/' + gameId + '/playerSave/' + playerSaveId, {
        params: {
            name: playerSaveName
        }
    });
};

const migratePlayerSave = (gameId, options) => {
    return axios.post('/api/players/' + gameId + '/playerSave/migrate', options);
};

export {
    getAllProfiles,
    getProfile,
    findProfile,
    updateProfile,
    getProfilePlayerSave,
    updateProfilePlayerSave
};

export {
    getPlayerSaves,
    getPlayerSave,
    addPlayerSave,
    copyPlayerSave,
    updatePlayerSave,
    removePlayerSave,
    migratePlayerSave
};

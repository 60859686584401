import { loadStages } from '../loaders';
import { GameView } from '../optionViews';

export const simpleConfigMigrate = {
    description: <>Config migration</>,
    color: '#4e63df',
    icon: 'fas fa-angle-double-down',
    hideInLibrary: true,
    options: [
        {
            id: 'sourceGame',
            type: 'select',
            name: 'Source Stage',
            options: [],
            placeholder: 'Select stage',
            asyncLoad: loadStages,
            size: 6,
            view: GameView
        },
        {
            id: 'newOnly',
            value: false,
            type: 'checkbox',
            name: 'Migrate new configs only',
            setupProps: {
                className: 'mt-4',
                tooltip: 'All existing configs will be ignored'
            }
        },
        {
            id: 'info3',
            type: 'info',
            text: 'Archived configs will be ignored.'
        }
    ],
    validate: async function (values, gameId) {
        if (!values.sourceGame) throw new Error('Source game should be specified');
    },
    name: 'Migration'
};

import { useMemo } from 'react';
import Sidebar from 'react-sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { TutorialView } from './TutorialView';

const TutorialSidebar = (props) => {
    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    const id = queryParams.get('tutorial');

    const section = hash;

    const closeTutorial = () => {
        queryParams.delete('tutorial');
        navigate(pathname + '?' + queryParams.toString(), { replace: true });
    };

    return (
        <Sidebar
            pullRight={true}
            transitions={true}
            sidebar={
                <>
                    <ul
                        className="navbar-nav sidebar sidebar-dark accordion bg-white p-3 "
                        id="accordionSidebar"
                    >
                        <div
                            className="btn btn-light rounded-circle z-5 position-sticky"
                            onClick={closeTutorial}
                            style={{ top: '20px', left: '100%', width: '40px' }}
                        >
                            <i className="fas fa-times" />
                        </div>
                        <TutorialView id={id} section={section} />
                    </ul>
                </>
            }
            onSetOpen={(open) => {
                if (!open) {
                    closeTutorial();
                }
            }}
            open={!!id}
            docked={false}
            sidebarClassName="sidebar-tutorial"
            contentId="page-content"
            overlayClassName="sidebar-tutorial-overlay"
        >
            {props.children}
        </Sidebar>
    );
};

export default TutorialSidebar;

import { utils } from '@gpg-web/utils';
import { AddressablesFileView } from './AddressablesFileView';

export const ContentView = (props) => {
    const { content, onChange, readOnly, selectedPath, title } = props;

    const selectedFile = content.path;
    const selectedVersions = content.versions;

    const onFileVersionSelect = (e) => {
        onChange(selectedPath, e.version);
    };

    const hasChanges = (e) => {
        console.log(e);
    };

    const fileName = utils.baseName(selectedFile, true);
    const filePath = selectedFile.slice(0, selectedFile.indexOf(fileName));

    if (!selectedPath) return <div className="text-danger">Select environment and game version</div>;

    return (
        <>
            <AddressablesFileView
                title={title}
                editInModal={false}
                gameVersion={props.gameVersion}
                environment={props.environment}
                path={selectedFile}
                defaultFile={{
                    name: fileName,
                    path: filePath,
                    mime: 'application/json',
                    contentType: 'livefire.unity-addressables',
                    versions: []
                }}
                version={selectedVersions[selectedPath]?.version}
                onSelect={onFileVersionSelect}
                hasChanges={hasChanges}
                readOnly={readOnly}
            />
        </>
    );
};

import { useState } from 'react';
import { StatusLabel, useFileVersions, useGame } from 'components';
import { StorageFileModal } from '../../storage/FileModal';
import { utils, str } from '@gpg-web/utils';

export const ContentViewClassic = (props) => {
    const { content, onChange, readOnly, environments, gameVersions } = props;

    const [editingVersion, setEditingVersion] = useState(false);

    const selectedFile = content.path;
    const selectedVersions = content.versions;

    const { isProduction } = useGame();

    const { versions } = useFileVersions(selectedFile);

    const handleChangeVersion = (e) => {
        onChange(editingVersion, e.version);
    };

    const promoteLocalEnv = async (from, to) => {
        const yes = await utils.confirm(
            'Are you sure you want to promote ' + selectedFile + ' from ' + from.name + ' to ' + to.name + '?'
        );
        if (!yes) return;
        Object.keys(selectedVersions).forEach((key) => {
            if (key.startsWith(from.id)) {
                let path = key.replace(from.id, to.id);
                selectedVersions[path] = utils.clone(selectedVersions[key]);
            }
        });

        onChange();
    };

    const editVersionSeparatorIndex = editingVersion ? editingVersion.slice(0, -1).lastIndexOf('/') + 1 : 0;

    return (
        <>
            <StorageFileModal
                path={selectedFile}
                version={selectedVersions[editingVersion]?.version}
                show={!!editingVersion}
                readOnly={readOnly}
                gameVersion={editingVersion ? editingVersion.slice(editVersionSeparatorIndex, -1) : ''}
                environment={editingVersion ? editingVersion.slice(0, editVersionSeparatorIndex) : ''}
                onHide={(e) => setEditingVersion(false)}
                onSelect={handleChangeVersion}
            />

            {environments.map((e) => {
                return (
                    <div className="mb-3" key={e.id}>
                        <div className="d-flex flex-wrap">
                            {!readOnly && (
                                <div className="dropup">
                                    <button
                                        disabled={readOnly}
                                        title="Promote to another environment"
                                        className="btn btn-light btn-sm m-1"
                                        id={'dropdownMenu-promote-to-' + e.id}
                                        data-bs-toggle="dropdown"
                                    >
                                        <i className="fas fa-random" />
                                    </button>
                                    <ul
                                        className="dropdown-menu"
                                        aria-labelledby={'dropdownMenu-promote-to-' + e.id}
                                    >
                                        <li>
                                            <span className="dropdown-item text-muted">Promote to</span>
                                        </li>
                                        {environments.map((_e) => {
                                            if (e.id === _e.id) return undefined;
                                            return (
                                                <li key={_e.id}>
                                                    <button
                                                        onClick={() => promoteLocalEnv(e, _e)}
                                                        className="dropdown-item"
                                                        type="button"
                                                    >
                                                        {_e.id}
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}

                            <div className="dropup me-2">
                                <button
                                    disabled={readOnly}
                                    className="btn btn-light btn-sm m-1 text-nowrap"
                                    id={'dropdownMenu-versions-' + e.id}
                                    data-bs-toggle="dropdown"
                                >
                                    {e.id}
                                    <i className="fas fa-caret-down ms-2" />
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby={'dropdownMenu-versions-' + e.id}
                                >
                                    {gameVersions.length === Object.keys(selectedVersions).length && (
                                        <li className="px-3 text-muted">No versions found</li>
                                    )}
                                    {gameVersions.map((version) => {
                                        const path = e.id + version.id + '/';

                                        if (selectedVersions[path]) return undefined;

                                        // const isDraftSelected =
                                        //     selectedVersions[path].version.startsWith('draft');

                                        return (
                                            <li key={version.id}>
                                                <button
                                                    onClick={() => setEditingVersion(path)}
                                                    className="dropdown-item"
                                                    type="button"
                                                >
                                                    v{version.id}
                                                    {selectedVersions[path] ? (
                                                        ' - Version #' + selectedVersions[path].version
                                                    ) : (
                                                        <span className="text-muted small ms-2">
                                                            (version not set)
                                                        </span>
                                                    )}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            {gameVersions.map((version) => {
                                const path = e.id + version.id + '/';

                                if (!selectedVersions[path] || !selectedVersions[path].version)
                                    return undefined;

                                const fileVersion = versions.find(
                                    (e) => e.id === selectedVersions[path].version
                                );

                                const isDraftSelected = selectedVersions[path].version.startsWith('draft');

                                return (
                                    <button
                                        key={version.id}
                                        onClick={() => setEditingVersion(path)}
                                        className={utils.className(
                                            'btn  btn-sm m-1 text-nowrap text-start position-relative',
                                            isDraftSelected
                                                ? isProduction
                                                    ? 'btn-outline-danger'
                                                    : 'btn-outline-primary'
                                                : 'btn-outline-secondary'
                                        )}
                                    >
                                        <i
                                            className={utils.className(
                                                'me-2 ',
                                                isProduction ? 'fas fa-exclamation-triangle' : 'fas fa-edit'
                                            )}
                                        />
                                        v{version.id} -{' '}
                                        {isDraftSelected ? (
                                            <>
                                                Draft - {selectedVersions[path].version.replace('draft.', '')}
                                            </>
                                        ) : (
                                            <>#{selectedVersions[path].version}</>
                                        )}
                                        {fileVersion && (
                                            <i title={fileVersion.message} className="d-block small">
                                                {str.trim(fileVersion.message, 25)}
                                            </i>
                                        )}
                                        <div className="position-absolute translate-middle top-0 start-85 small">
                                            <StatusLabel value={selectedVersions[path].status || 'draft'} />
                                        </div>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getAllProfiles, findProfile } from 'services/players';
import { Table } from '@gpg-web/react';
import Skeleton from 'react-loading-skeleton';
import { utils, date } from '@gpg-web/utils';
import NavTab from './NavTab';
import { CellLink, useGame } from 'components';

const columns = [
    {
        Header: '#',
        accessor: 'index'
    },
    {
        Header: 'Player UID',
        accessor: 'id',
        Cell: CellLink
    },
    {
        Header: 'Created',
        accessor: 'displayCreationTime',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.creationTime > rowB.original.creationTime ? 1 : -1;
        }
    },
    {
        Header: 'Signed In',
        accessor: 'displayLastSignInTime',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.lastSignInTime > rowB.original.lastSignInTime ? 1 : -1;
        }
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true
    }
];

let filterTimeout;

const PlayersList = (props) => {
    const [players, setPlayers] = useState(null);
    const [player, setPlayer] = useState(null);

    // const history = useNavigate();
    const game = useGame();

    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getAllProfiles(gameId).then(setPlayers).catch(utils.hintError);
    }, [gameId]);

    // const goProfile = (e) => {
    //     history('/game/' + gameId + '/players/' + e.id);
    // };

    const data = useMemo(() => {
        const playerUrl = '/game/' + gameId + '/players/';

        if (player)
            return [
                {
                    id: player.id,
                    id_link: playerUrl + player.id,
                    index: 1,
                    displayCreationTime: date(player.creationTime, 'ago-1'),
                    creationTime: new Date(player.creationTime),
                    displayLastSignInTime: date(player.lastSignInTime, 'ago-1'),
                    lastSignInTime: new Date(player.lastSignInTime),
                    actions: (
                        <>
                            <Link to={playerUrl + player.id}>
                                <button onClick={() => {}} title="Config Details" className="btn btn-sm ms-2">
                                    <i className="fa fa-info me-2"></i> Details
                                </button>
                            </Link>
                        </>
                    )
                }
            ];

        if (!players) return [];

        return players.map((player, i) => ({
            id: player.id,
            id_link: playerUrl + player.id,
            index: i + 1,
            displayCreationTime: date(player.creationTime, 'ago-1'),
            creationTime: new Date(player.creationTime),
            displayLastSignInTime: date(player.lastSignInTime, 'ago-1'),
            lastSignInTime: new Date(player.lastSignInTime),
            actions: (
                <>
                    <Link to={playerUrl + player.id}>
                        <button onClick={() => {}} title="Config Details" className="btn btn-sm ms-2">
                            <i className="fa fa-info me-2"></i> Details
                        </button>
                    </Link>
                </>
            )
        }));
    }, [players, player, gameId]);

    const onSearchChange = useCallback(
        (search) => {
            clearTimeout(filterTimeout);

            search = search.trim();

            if (search.length < 4) return setPlayer(null);

            filterTimeout = setTimeout(() => {
                utils.popup('Search...');

                findProfile(gameId, search)
                    .then((result) => {
                        setPlayer(result);

                        utils.popup('hide');
                    })
                    .catch(utils.hintError);
            }, 800);
        },
        [gameId]
    );

    return (
        <>
            <NavTab gameId={gameId} tab="players" />

            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4>Players</h4>
                </div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small">
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Players
                        </li>
                    </ol>
                </nav>

                <div className="mt-4">
                    <div className="d-flex align-items-center mb-3">
                        <i className="fas fa-search me-3" />
                        <input
                            type="search"
                            onChange={(e) => {
                                onSearchChange(e.target.value);
                            }}
                            className="form-control form-control-sm "
                            placeholder="Search by email address, phone number, or user UID"
                        />
                    </div>

                    {!!players && (
                        <Table
                            saveStateId="players-list"
                            header={false}
                            // onClick={goProfile}
                            columns={columns}
                            data={data}
                            className="table-striped"
                        />
                    )}
                </div>

                {!players && (
                    <div className="mt-4">
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                        <Skeleton
                            className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                            width="100%"
                            height={30}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default PlayersList;

import { useEffect, useState } from 'react';
import { getConfigs } from '../../../../services/config';
import { ComponentsMap } from '../../../../consts';
import { utils } from '@gpg-web/utils';

export const ComponentOption = ({
    title,
    name,
    value,
    onChange,
    readOnly,
    gameId,
    placeholder,
    componentId,
    disabled
}) => {
    const [options, setOptions] = useState(null);

    useEffect(() => {
        setOptions(null);

        const component = ComponentsMap[componentId];

        getConfigs(gameId, component.url)
            .then((configs) => setOptions(configs.map((c) => ({ value: c.id, label: c.id }))))
            .catch(utils.hintError);
    }, [gameId, componentId]);

    return (
        <div className="d-flex align-items-center my-1">
            <div className="me-2 text-nowrap">{title}</div>
            <select
                id={name}
                value={value || ''}
                onChange={(e) => onChange(name, e.target.value)}
                className="form-select form-select-sm"
                disabled={disabled || readOnly}
            >
                {!options ? (
                    <option disabled value="">
                        Loading...
                    </option>
                ) : (
                    <>
                        <option disabled value="">
                            {placeholder || ''}
                        </option>
                        {options.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </>
                )}
            </select>
        </div>
    );
};

export const StorageView = ({ value }) => {
    if (!value) return <span className="fw-bold">Not selected</span>;

    if (typeof value === 'object')
        return (
            <span className="fw-bold font-monospace">
                {value.path}
                {value.version ? ' #' + value.version : null}
            </span>
        );
    return <span className="fw-bold font-monospace">{value}</span>;
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useContext } from 'react';
import { uploadStorageFile } from 'services/storage';
import { userContext } from 'services/user';
import { Views, BaseView } from './views';
import { utils } from '@gpg-web/utils';
import { BrowseStorageModal } from './BrowseStorage';
import { useGame } from 'components';
import { DropZone } from '@gpg-web/react';
import { useQueryClient } from '@tanstack/react-query';

const FileCreation = (props) => {
    const { initialPath, hasChanges, readOnly, onSuccess, mime, contentType, fileName } = props;

    const user = useContext(userContext);
    const game = useGame();
    // const [selectedVersionId] = useState(version || '1');
    const [browsingStorage, setBrowsingStorage] = useState(false);
    const [browsingStorageFolder, setBrowsingStorageFolder] = useState(false);
    const [creation, setCreation] = useState(null);
    const [initialContent, setInitialContent] = useState(null);
    const [path, setPath] = useState(initialPath);
    const queryClient = useQueryClient();
    const gameId = game.id;

    const uploadNewVersion = async (newContent, cb) => {
        try {
            const message = await utils.promt('Are you sure you want to upload a new ' + fileName, {
                value: '',
                label: 'Version description',
                placeholder: 'Initial version'
            });

            if (message === false) return;

            const blob = new Blob([newContent], { type: mime });

            utils.popup('uploading');

            const data = await uploadStorageFile(gameId, blob, {
                path: path,
                name: fileName,
                message,
                contentType,
                authorEmail: user.email,
                authorName: user.name,
                forceCreateNew: true
            });

            utils.popup('hide');
            cb && cb();
            utils.hintOk('File was successfully uploaded!');
            queryClient.removeQueries({
                queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)],
                exact: true
            });

            onSuccess({ path: data.path + data.name });
        } catch (err) {
            utils.hintError(err);
        }
    };

    const acceptNewFile = async (files) => {
        const newFile = files[0];

        if (!newFile) return utils.hintError('Something went wrong');

        if (mime === 'application/json' && newFile.type !== 'application/json') {
            return utils.hintError('Only JSONs files are allowed to be upload');
        }

        if (mime !== newFile.type) {
            return utils.hintError('File content type is mismatch. Only ' + mime + ' is allowed');
        }

        try {
            const text = await utils.load.file(newFile, 'text');
            if (newFile.type === 'application/json') {
                setInitialContent(JSON.parse(text));
            } else {
                setInitialContent(text);
            }
        } catch (err) {
            utils.hintError('File you are trying to open should have a valid JSON format content.');
        }

        setCreation(true);
    };

    const FileView = Views[contentType];

    return (
        <>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    {creation && (
                        <div className="py-3">
                            <div className="d-flex mb-2">
                                <button
                                    onClick={() => setCreation(false)}
                                    className="btn btn-sm shadow btn-light rounded-pill px-4"
                                >
                                    <i className="fas fa-chevron-left me-2" /> Back
                                </button>

                                <div className="ms-auto fw-light text-muted">
                                    <span
                                        role="button"
                                        onClick={() => setBrowsingStorageFolder(true)}
                                        className="bg-gray-100 shadow-sm me-1 px-2 py-1 rounded-2"
                                    >
                                        <i className="fas fa-sm fa-edit me-2" />
                                        {path}
                                    </span>
                                    {fileName ? fileName : <span className="text-danger">name *</span>}
                                </div>
                            </div>

                            {!!FileView && (
                                <BaseView
                                    json={mime === 'application/json'}
                                    uploadNewVersion={fileName ? uploadNewVersion : null}
                                    gameId={gameId}
                                    content={initialContent}
                                    hasChanges={hasChanges}
                                    readOnly={readOnly}
                                    renderAs={FileView}
                                />
                            )}

                            {!FileView && (
                                <div className="d-flex flex-column align-items-center justify-content-center my-5">
                                    <div className="my-2 mt-3">
                                        <div className="h5 my-3 text-muted">
                                            <i className="fas fa-times-circle me-2 fa-sm text-danger" />
                                            Preview is not available
                                        </div>
                                    </div>
                                    <div className="text-muted">
                                        Please upload file from computer or select an existing one
                                    </div>
                                    <button
                                        onClick={() => setCreation(false)}
                                        className="btn btn-sm shadow-sm btn-light rounded-pill mt-4 px-4"
                                    >
                                        <i className="fas fa-chevron-left me-2" /> Back
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {!creation && (
                        <div className="d-flex text-center py-5 flex-column px-5">
                            <div>
                                <button
                                    onClick={() => {
                                        setCreation(true);
                                        setInitialContent(mime === 'application/json' ? {} : '');
                                    }}
                                    className="btn btn-success rounded-pill shadow px-3"
                                >
                                    <i className="fas fa-plus me-2" />
                                    New File
                                </button>
                            </div>
                            <div className="my-3">- or -</div>
                            <div>
                                <button
                                    onClick={() => setBrowsingStorage(true)}
                                    className="btn btn-light btn-sm rounded-pill shadow px-3"
                                >
                                    <i className="fas fa-folder-open text-warning me-2" />
                                    Browse Storage
                                </button>
                            </div>
                            {(contentType === 'json' || contentType === 'text') && (
                                <>
                                    <div className="my-3">- or -</div>
                                    <div style={{ height: '165px' }}>
                                        <DropZone onAccept={acceptNewFile} />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>

                <div className="col-md-4 col-sm-12 border-start"></div>
            </div>

            <BrowseStorageModal
                foldersOnly={true}
                isFolderSelect={true}
                show={browsingStorageFolder}
                onHide={(e) => setBrowsingStorageFolder(false)}
                onSelect={(e) => {
                    setPath(e.path);
                }}
                gameId={gameId}
            />

            <BrowseStorageModal
                accept={contentType === 'json' || contentType === 'text' ? mime : contentType}
                initialPath={initialPath.substr(0, initialPath.length - 1)}
                requiredFileVersion={false}
                show={browsingStorage}
                onHide={(e) => setBrowsingStorage(false)}
                onSelect={onSuccess}
                gameId={gameId}
            />
        </>
    );
};

export { FileCreation };

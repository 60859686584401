import React from 'react';

export const ChangelogPage = (props) => {
    const currentVersion = props.version;

    return (
        <div className="row justify-content-center">
            <div className={props.className || 'col-sm-12 col-md-6'}>
                <Changes version="2.1.0" date="2024-04-29" hideIfNot={currentVersion}>
                    <ul>
                        <li>Draft file version is added</li>
                        <li>
                            Extended Experiments to configure triggers per Variant per each TLE, TLO, Popup
                        </li>
                        <li>Addapted Google Add-on Game Config Exporter to work with Open API V2</li>
                        <li>Added OpenAPI V3 - to work with GPG Dashboard OAuth 2.0</li>
                        <li>Added Unity Addressables component</li>
                        <li>Code refactor & remove all legacy code, components, features</li>
                        <li>Prepared base for tutorials in dashboard, as separate page and as popup</li>
                        <li>
                            Removed Experiment restrictions for the <code>production</code> stage
                        </li>
                        <li>Another improvements from QA and stakeholders</li>
                    </ul>
                    <h5>Bug fixes</h5>
                    <ul>
                        <li>
                            Version and environment selection showing up when editing popup inside
                            experiment
                        </li>
                        <li>Wrong "End Date" display on the migrated Experiment</li>
                        <li>Popup - Bad validation for non-consumable rewards</li>
                    </ul>
                </Changes>
                <Changes version="2.0.0" date="2024-01-22" hideIfNot={currentVersion}>
                    <ul>
                        <li>Livefire 2.0 is here</li>
                        <li>Added versioning possibility on backend and database</li>
                        <li>
                            Global review of all components
                            <ul>
                                <li>Split by global parts: auth, config, player, hosting</li>
                                <li>Configs split by: classic, built-in, experiment and store components</li>
                                <li>Backend splitted by it's logical parts</li>
                                <li>Removed Firebase Remote Config & Audiences as much as possible</li>
                                <li>Moved more logic on backend part</li>
                            </ul>
                        </li>
                        <li>Reworked settings, did it more complete</li>
                        <li>All config components have the same logic</li>
                        <li>Every config component has a strict format and Storage connection</li>
                        <li>Global UX review: did upgrades, taking into account all the people feedback</li>
                        <li>All automation scripts has been adapted for new configs logic</li>
                        <li>Migrations has been reworked for new configs logic</li>
                        <li>Reworked Store components and it UX/UI, added sections level</li>
                        <li>Added Patch component</li>
                        <li>
                            Global rework on Localization (Translation) component - it became a classic config
                        </li>
                        <li>Added configs overview page</li>
                        <li>
                            Added <code>isProduction</code> state for stages: adapted some logic for it
                        </li>
                    </ul>
                </Changes>
                <Changes version="1.2.0" date="2023-08-11" hideIfNot={currentVersion}>
                    <ul>
                        <li>Done a lot of small and non-critical UI/UX improvements</li>
                        <li>Finished QA fixes</li>
                        <li>Added Docs component</li>
                        <li>Added Automation with it Processes and Scripts components</li>
                        <li>Added Joi schema validation for each Livefire component</li>
                    </ul>
                </Changes>
                <Changes version="1.1.2" date="2023-06-09" hideIfNot={currentVersion}>
                    <ul>
                        <li>Added socket connection for Migration service (realtime feature)</li>
                        <li>Added local Notification feature: noticing deletion and migration operations</li>
                        <li>Added socket connection for Notification service (realtime feature)</li>
                        <li>Added Popup component</li>
                        <li>Added Global Search component</li>
                        <li>
                            Another UI/UX improvements: texts, Env File messages reflecting, version defining
                            on translation creation, etc.
                        </li>
                    </ul>
                    <h5>Backend API</h5>
                    <ul>
                        <li>
                            Added <code>popup/</code> component with status check logic
                        </li>
                        <li>
                            Finished Cloud-Based alerts configuration: configured email, slack, dashboard
                            channels for Airplane, ISS, Landlord prods.
                        </li>
                    </ul>
                </Changes>
                <Changes version="1.1.1" date="2023-05-22" hideIfNot={currentVersion}>
                    <ul>
                        <li>
                            Added possibility to preview the real IAP price and name directly on Bundle
                            component
                        </li>
                        <li>Added migration for Player Save Type component</li>
                        <li>
                            Created Migration as different service with it own logic
                            <ul>
                                <li>Notifying if migration has started</li>
                                <li>Block migrations if it has been started already</li>
                                <li>
                                    Keeping all migration details with it errors, warnings, added, modified
                                </li>
                                <li>Possibility to migrate without blocking the page - async migration</li>
                            </ul>
                        </li>
                        <li>
                            Added Storage, Localization, TLE, Game Balance, Experiment, Player Save to
                            Migration service
                        </li>
                    </ul>
                    <h5>Backend API</h5>
                    <ul>
                        <li>Deployed new Conditions logic on prod stages</li>
                        <li>
                            Added <code>server/datetime</code> function
                        </li>
                        <li>
                            Configured primary alert policies for Cloud Run metrics for email notification
                        </li>
                    </ul>
                </Changes>
                <Changes version="1.1.0" date="2023-05-05" hideIfNot={currentVersion}>
                    <ul>
                        <li>Reworked Logs components: moved logs logic to Firestore DB, improved Logs UX</li>
                        <li>Upgrades global Table component - to be able save user customisation</li>
                        <li>Added possibility to delete an object for it creators during next 1 hour</li>
                        <li>
                            Experiment: added more info on List page, added autocorrect for End date if status
                            set to <code>Disabled</code>
                        </li>
                        <li>
                            Reworked Experiments to work without Firebase - but with our own Conditions logic
                        </li>
                        <li>
                            Added <code>Pretest</code> status for each component - uses for GD stages
                        </li>
                        <li>Added Tooltip & Popover components. Added tips on FAQ app blocks/components</li>
                        <li>Added dedicated page for missing permission with it details</li>
                        <li>
                            Applied UI logic for IAM components: disabled buttons + IAM details inside Popover
                        </li>
                        <li>
                            Storage: added possibility to upload JSON directly from JSON Preview component
                        </li>
                        <li>Another small UI/UX fixes: on tables, topbar, etc...</li>
                    </ul>
                    <h5>Backend API</h5>
                    <ul>
                        <li>Added compression component for Storage/Translations</li>
                        <li>
                            Added first server logic for Experiments component: status check, Conditions logic
                        </li>
                    </ul>
                </Changes>
                <Changes version="1.0.5" date="2023-04-21" hideIfNot={currentVersion}>
                    <ul>
                        <li>
                            Localization V2: added possibility use Description field, save versions as Draft,
                            dynamically compare files on Editor itself
                        </li>
                        <li>Settings: reworked Local Enviroments, polished Versions</li>
                        <li>Game Config Exporter: reworked exporter to work with new JSON format</li>
                        <li>Added Audience Targeting validation function</li>
                        <li>Added Migration for Localization</li>
                        <li>
                            Added possibility to automatically create new Versions for each Environmental File
                        </li>
                        <li>Another small UI fixes</li>
                        <li>
                            Backend: moved hosting component to remote Cloud Storage - so made app serverless
                            capable
                        </li>
                    </ul>
                    <h5>Backend API</h5>
                    <ul>
                        <li>Deployed all last fixes and polishes on each prod & dev projects</li>
                        <li>
                            Added <code>/game/config/forcedUpdate</code> - Forced Update API
                        </li>
                    </ul>
                </Changes>
                <Changes version="1.0.4" date="2023-04-07" hideIfNot={currentVersion}>
                    <ul>
                        <li>Added tags system</li>
                        <li>A/B Testing - keep values if you disable and then enable any config</li>
                        <li>Added this awesome Changelog page and modal</li>
                        <li>
                            Added possibility to import Localization directly from Google SpreadSheet by URL
                            <ul>
                                <li>Added 2 ways to import: with personal or service account</li>
                            </ul>
                        </li>
                        <li>Fixed (upgraded) migration for Store, Game Balance, TLE & A/B Tests</li>
                        <li>Reworked Environmental File type</li>
                        <li>
                            Localization V2: added possibility to merge importing files with existing version,
                            compare files, create Keys from dashboard
                        </li>
                    </ul>
                    <h5>Open API</h5>
                    <ul>
                        <li>
                            Added <code>firebase/template</code>
                        </li>
                        <li>
                            Added <code>translations/activeVersion</code>
                        </li>
                        <li>
                            Added <code>config/remoteConfig/files/all</code> - to generate the full list of
                            file using in remote configs
                        </li>
                        <li>
                            Added <code>config/game/localEnvironments</code>
                        </li>
                    </ul>
                    <h5>Backend API</h5>
                    <ul>
                        <li>
                            Added <code>config/storage/lastFileVersion</code> - to find the latest file
                            version on Storage (done especcially for Addressables)
                        </li>
                        <li>
                            Reworked Backend API projects: auth, store, config, storage - removed nodemon & ts
                            from pipeline
                        </li>
                        <li>Fixed Cloud Run & logging setup</li>
                    </ul>
                </Changes>
                <Changes version="1.0.3" date="2023-03-24" hideIfNot={currentVersion}>
                    <ul>
                        <li>Added possibility to Drop files on Storage component</li>
                        <li>Added security warning, if you try to leave page on which you did any change</li>
                        <li>Fixed Storage/Translations folders creating & deleting</li>
                        <li>Reworked sidebar nivagation menu</li>
                        <li>Added Forced Update page as different component/page</li>
                        <li>Added Advanced JSON Editor for Storage files</li>
                        <li>
                            Reworked Environmental File Type design (data model the same). Added possibility
                            to promote files between environments.
                        </li>
                        <li>
                            Fixed (upgraded) migration for Storage (continue working on another components)
                        </li>
                    </ul>
                </Changes>
            </div>
        </div>
    );
};

const Changes = (props) => {
    const { version, date, hideIfNot } = props;

    if (hideIfNot && hideIfNot !== version) return undefined;

    return (
        <div className="mb-4" id={'changelog-version-' + version}>
            <div className="mb-3">
                <div className="h3 mb-0">Changelog v{version}</div>
                <div className="text-secondary">{date}</div>
            </div>
            <div className="mb-4">{props.children}</div>
            <hr />
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getProcesses } from 'services/automation';
import { UserBadge, useGame } from 'components';
import { ComponentsMap } from 'consts';
import Skeleton from 'react-loading-skeleton';
import { utils, date } from '@gpg-web/utils';
import { Table } from '@gpg-web/react';
import { Statuses, getStatus } from './Statuses';
import socket from 'services/socket';

const columns = [
    {
        Header: 'Process',
        accessor: 'displayComponent',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.name > rowB.original.name ? 1 : -1;
        }
    },
    {
        Header: 'Started by',
        accessor: 'user',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.userEmail > rowB.original.userEmail ? 1 : -1;
        }
    },
    {
        Header: 'Started at',
        accessor: 'displayCreatedAt',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.createdAt > rowB.original.createdAt ? 1 : -1;
        }
    },
    {
        Header: 'Duration',
        accessor: 'displayDuration',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.duration > rowB.original.duration ? 1 : -1;
        }
    },
    {
        Header: 'Status',
        accessor: 'displayStatus',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.status > rowB.original.status ? 1 : -1;
        }
    }
];

export const ProcessesList = (props) => {
    const [processes, setProcesses] = useState(null);
    const game = useGame();
    const history = useNavigate();

    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        function socketProcessUpdate(data) {
            if (data.game === gameId) {
                setProcesses((list) => {
                    const exists = list.find((e) => e.id === data.id);

                    if (exists) {
                        Object.assign(exists, {
                            finished: data.finished,
                            hasErrors: data.errors ? data.errors.length > 0 : false,
                            hasWarnings: data.warnings ? data.warnings.length > 0 : false,
                            updatedAt: new Date()
                        });
                    } else {
                        list.push(data);
                    }

                    return list.slice();
                });
            }
        }

        getProcesses(gameId)
            .then((list) => {
                setProcesses(list);
                socket.on('automation-process', socketProcessUpdate);
            })
            .catch(utils.hintError);

        return () => {
            socket.off('automation-process', socketProcessUpdate);
        };
    }, [gameId]);

    const data = useMemo(() => {
        if (!processes) return [];

        const componentsMax = 2;

        return processes.map((e, i) => {
            let status = getStatus(e);
            const duration = new Date(e.updatedAt) - new Date(e.createdAt);

            const components = e.components || [e.component];

            const componentsHidden = components.length - componentsMax;

            return {
                id: e.id,
                displayComponent: (
                    <>
                        <Link to={'/game/' + gameId + '/automation/processes/' + e.id}>
                            {/* {ComponentsMap[e.component] ? ComponentsMap[e.component].name : e.component} -{' '} */}
                            {e.name}
                        </Link>
                        <div className="small text-muted">
                            {components
                                .slice(0, componentsMax)
                                .map((c) => (ComponentsMap[c] ? ComponentsMap[c].name : c))
                                .join(', ')}
                            {componentsHidden > 0 ? (
                                <span
                                    title={components
                                        .slice(componentsMax)
                                        .map((c) => (ComponentsMap[c] ? ComponentsMap[c].name : c))
                                        .join(', ')}
                                >
                                    {' '}
                                    and {componentsHidden} more
                                </span>
                            ) : null}
                        </div>
                    </>
                ),
                name: e.name,
                user: <UserBadge email={e.user} onClick={(e) => history(e.url)} />,
                userEmail: e.user,
                createdAt: new Date(e.createdAt),
                displayCreatedAt: date(e.createdAt),
                updatedAt: new Date(e.updatedAt),
                duration: duration,
                displayDuration: Math.round(duration / 1000) + ' sec.',
                status: status,
                displayStatus: Statuses[status],
                globalFilter:
                    e.name + components.map((c) => (ComponentsMap[c] ? ComponentsMap[c].name : c)).join(' ')
            };
        });
    }, [processes, gameId, history]);

    return (
        <>
            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4>Processes</h4>
                </div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small">
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Processes
                        </li>
                    </ol>
                </nav>

                <div className="mt-4">
                    {!!processes && (
                        <Table
                            saveStateId="processes-list"
                            sortBy={[{ id: 'displayCreatedAt', desc: true }]}
                            columns={columns}
                            data={data}
                            className="table-striped"
                        />
                    )}
                </div>

                {!processes && (
                    <div className="mt-4">
                        <Skeleton className="m-2 mx-1 mx-sm-2" count={5} height={40} />
                    </div>
                )}
            </div>
        </>
    );
};

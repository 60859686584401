/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
    getConfigs,
    removeConfig,
    duplicateConfig,
    // archiveConfig,
    // restoreConfig,
    // restoreConfigBatch,
    // archiveConfigBatch,
    duplicateConfigBatch,
    removeConfigBatch,
    migrateConfig
} from 'services/config';
import {
    StatusLabel,
    ProcessBlockedWrapper,
    DeleteButtonSmall,
    IAMButtonSmall,
    IAMButton,
    ComponentHeader,
    useGame,
    SelectionMenu,
    useSelectList,
    makeSelectColumn,
    TooltipWrapper
} from 'components';
import Skeleton from 'react-loading-skeleton';
import { utils, date } from '@gpg-web/utils';
import { SetupScriptModal } from '../../automation/scripts';
import { Table } from '@gpg-web/react';
import { Navigation } from '../Navigation';
import { useComponent } from '../hooks';
import { useQueryClient } from '@tanstack/react-query';

const columns = [
    {
        Header: '',
        accessor: 'displayStatus',
        sortType: (rowA, rowB) => {
            return rowA.original.status > rowB.original.status ? -1 : 1;
        }
    },
    {
        Header: 'Experiment',
        accessor: 'displayName',
        cellClass: 'text-nowrap',
        sortType: (rowA, rowB) => {
            return rowA.original.name > rowB.original.name ? -1 : 1;
        }
    },
    {
        Header: 'Scheduler',
        accessor: 'displayScheduler',
        sortType: (rowA, rowB) => {
            return rowA.original.scheduler > rowB.original.scheduler ? -1 : 1;
        }
    },
    {
        Header: 'Start',
        accessor: 'displayStart',
        sortType: (rowA, rowB) => {
            return rowA.original.start > rowB.original.start ? 1 : -1;
        }
    },
    {
        Header: 'End',
        accessor: 'displayEnd',
        sortType: (rowA, rowB) => {
            return rowA.original.end > rowB.original.end ? 1 : -1;
        }
    },
    {
        Header: 'Last modified',
        accessor: 'displayUpdatedAt',
        sortType: (rowA, rowB) => {
            return rowA.original.updatedAt > rowB.original.updatedAt ? 1 : -1;
        }
    },
    {
        accessor: 'actions',
        disableSortBy: true
    }
];

export const ExperimentsList = () => {
    // let { game, setGame } = useContext(gameContext);
    const [experiments, setExperiments] = useState(null);
    const [migrating, setMigrating] = useState(false);
    const game = useGame();
    const queryClient = useQueryClient();
    const component = useComponent('experiment');

    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getConfigs(gameId, component.url).then(setExperiments).catch(utils.hintError);
    }, [gameId, component.url]);

    const [selectableIds, setSelectableIds] = useState([]);

    const onFilterChange = useCallback(
        (rows) => {
            if (experiments) setSelectableIds(rows.map((row) => row.original.id));
        },
        [experiments]
    );

    const { onSelect, isSelected, selectAll, deselectAll, allSelected, getSelected } =
        useSelectList(selectableIds);

    const data = useMemo(() => {
        if (!experiments) return [];

        const _removeConfig = (e) => {
            e.stopPropagation();

            const experimentId = e.target.getAttribute('data-id');

            utils.confirm('Are you sure want to remove this experiment - ' + experimentId + '?', (yes) => {
                if (yes) {
                    utils.popup('removing');

                    removeConfig(gameId, component.url, experimentId)
                        .then(() => {
                            utils.remove(experiments, (e) => e.id === experimentId);

                            setExperiments(experiments.slice());

                            utils.popup('hide');
                        })
                        .catch(utils.hintError);
                }
            });
        };

        const _duplicateConfig = (e) => {
            e.stopPropagation();

            const srcId = e.target.getAttribute('data-id');
            // const remoteConfigName = e.target.getAttribute('data-name');

            utils.promt(
                'Please specify a new "' + srcId + '" ID',
                (dstId) => {
                    if (dstId === false) return;

                    dstId = dstId.trim();

                    if (!dstId) return utils.hintError(srcId + ' ID is required.');

                    if (!utils.isValidId(dstId)) {
                        return utils.hintError(
                            'ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed'
                        );
                    }

                    utils.popup('Duplicating ...');

                    duplicateConfig(gameId, component.url, { srcId, dstId })
                        .then((result) => {
                            experiments.push(result);

                            setExperiments(experiments.slice());

                            utils.popup('hide');
                        })
                        .catch(utils.hintError);
                },
                {
                    value: srcId + '2',
                    label: 'New Config ID',
                    placeholder: 'ID *'
                }
            );
        };

        // const _archiveConfig = (e) => {
        //     e.stopPropagation();
        //     const experimentId = e.target.getAttribute('data-id');

        //     utils.confirm(
        //         'Are you sure you want to archive this experiment? You can still restore the experiment after archiving it.',
        //         (yes) => {
        //             if (!yes) return;

        //             utils.popup('Archiving ...');

        //             archiveConfig(gameId, component.url, experimentId)
        //                 .then((result) => {
        //                     const exists = experiments.find((e) => e.id === experimentId);
        //                     exists.status = 'archive';
        //                     exists.updatedAt = new Date();

        //                     setExperiments(experiments.slice());

        //                     utils.popup('hide');
        //                 })
        //                 .catch(utils.hintError);
        //         }
        //     );
        // };

        // const _restoreConfig = (e) => {
        //     e.stopPropagation();
        //     const experimentId = e.target.getAttribute('data-id');

        //     utils.confirm('Are you sure you want to restore this experiment from archive?', (yes) => {
        //         if (!yes) return;

        //         utils.popup('Restoring ...');

        //         restoreConfig(gameId, component.url, experimentId)
        //             .then((result) => {
        //                 const exists = experiments.find((e) => e.id === experimentId);
        //                 exists.status = 'draft';
        //                 exists.updatedAt = new Date();

        //                 setExperiments(experiments.slice());

        //                 utils.popup('hide');
        //             })
        //             .catch(utils.hintError);
        //     });
        // };

        let now = new Date();

        return experiments.map((e, i) => {
            const status = e.status;

            let start = null;
            let end = null;

            if (e.time && e.time.data) {
                if (e.time.data.start) {
                    start = new Date(e.time.data.start);
                    if (!e.time.global) {
                        start.setTime(start.getTime() + start.getTimezoneOffset() * 60 * 1000);
                    }
                }
                if (e.time.data.end) {
                    end = new Date(e.time.data.end);
                    if (!e.time.global) {
                        end.setTime(end.getTime() + end.getTimezoneOffset() * 60 * 1000);
                    }
                }
            }

            let scheduler = status;

            if (status === 'live') {
                if (start && end) {
                    if (now >= end) {
                        if (status === 'disabled') {
                            scheduler = 'stopped';
                        } else {
                            scheduler = 'finished';
                        }
                    } else if (now >= start) {
                        if (status === 'live') {
                            scheduler = 'ongoing';
                        } else {
                            scheduler = status;
                        }
                    } else {
                        scheduler = 'pending';
                    }
                } else {
                    scheduler = 'error';
                }
            }

            return {
                globalFilter: e.id + status + scheduler,
                _rowAttributes: {
                    title: (status === 'archive' ? 'In archive - ' : '') + (e.description || e.id)
                },
                id: e.id,
                onSelect,
                selected: isSelected(e.id),
                displayStatus:
                    status === 'archive' ? (
                        '📦'
                    ) : status === 'live' ? (
                        scheduler === 'ongoing' ? (
                            '🟢'
                        ) : scheduler === 'finished' ? (
                            '☑️'
                        ) : scheduler === 'error' ? (
                            <TooltipWrapper icon={false} content="Error: start & end should be specififed">
                                <span>🔴</span>
                            </TooltipWrapper>
                        ) : (
                            '🕑'
                        )
                    ) : status === 'disabled' ? (
                        '⛔️'
                    ) : (
                        '📝'
                    ),
                status: status + scheduler,
                name: e.name,
                displayName: (
                    <div>
                        <Link
                            className="text-decoration-none"
                            to={'/game/' + gameId + '/config/' + component.url + '/view/' + e.id}
                        >
                            {e.id}
                        </Link>
                        <div className="small text-muted">{e.name}</div>
                    </div>
                ),
                start: start || 0,
                displayStart: start && (
                    <>
                        {date(start, 'date')}
                        <span className="small">{e.time.global && ' (GMT)'}</span>
                    </>
                ),
                end: end || 0,
                displayEnd: end && (
                    <>
                        {date(end, 'date')}
                        <span className="small">{e.time.global && ' (GMT)'}</span>
                    </>
                ),
                updatedAt: new Date(e.updatedAt),
                displayUpdatedAt: date(e.updatedAt, 'ago-1'),
                displayScheduler: scheduler && status !== 'archive' && <StatusLabel value={scheduler} />,
                scheduler: scheduler,
                actions: (
                    <>
                        <IAMButtonSmall permissions={['component.experiment.create']}>
                            <button
                                onClick={_duplicateConfig}
                                data-id={e.id}
                                title="Duplicate object"
                                className="btn btn-sm"
                            >
                                <i className="fa fa-copy pe-none" />
                            </button>
                        </IAMButtonSmall>

                        {/* <IAMButtonSmall permissions={['component.experiment.update']}>
                            <button
                                onClick={e.status === 'archive' ? _restoreConfig : _archiveConfig}
                                data-id={e.id}
                                title={e.status === 'archive' ? 'Restore object' : 'Archive object'}
                                className="btn btn-sm"
                            >
                                {e.status === 'archive' ? (
                                    <i className="fas fa-undo-alt pe-none" />
                                ) : (
                                    <i className="fa fa-archive pe-none" />
                                )}
                            </button>
                        </IAMButtonSmall> */}

                        <DeleteButtonSmall
                            createdAt={e.createdAt}
                            createdBy={e.createdBy}
                            deletePermission={'component.experiment.delete'}
                        >
                            <button
                                onClick={_removeConfig}
                                data-id={e.id}
                                title="Delete object"
                                className="btn btn-sm"
                            >
                                <i className="fa fa-trash pe-none" />
                            </button>
                        </DeleteButtonSmall>
                    </>
                )
            };
        });
    }, [experiments, gameId, onSelect, isSelected, component.url]);

    const selected = getSelected();

    // const restoreSelected = () => {
    //     utils.confirm('Are you sure want to restore selected configs?', async (yes) => {
    //         if (yes) {
    //             try {
    //                 utils.popup('Restoring selected configs...');

    //                 const selectedIds = selected;

    //                 const { success, error } = await restoreConfigBatch(gameId, component.url, selectedIds);

    //                 const updatedConfigs = experiments.slice();

    //                 for (let configId of success) {
    //                     const config = updatedConfigs.find((c) => c.id === configId);

    //                     if (config) {
    //                         config.status = 'live';
    //                         config.updatedAt = new Date();
    //                     }
    //                 }

    //                 setExperiments(updatedConfigs);

    //                 deselectAll();

    //                 utils.popup('hide');

    //                 for (let configId of error) {
    //                     utils.hintError('Unable to restore config ' + configId);
    //                 }
    //             } catch (err) {
    //                 utils.popup('hide');
    //                 utils.hintError('Unable to restore selected configs. ' + err);
    //             }
    //         }
    //     });
    // };

    // const archiveSelected = () => {
    //     utils.confirm('Are you sure want to archive selected configs?', async (yes) => {
    //         if (yes) {
    //             try {
    //                 utils.popup('Archiving selected configs...');

    //                 const selectedIds = selected;

    //                 const { success, error } = await archiveConfigBatch(gameId, component.url, selectedIds);

    //                 const updatedConfigs = experiments.slice();

    //                 for (let configId of success) {
    //                     const config = updatedConfigs.find((c) => c.id === configId);

    //                     if (config) {
    //                         config.status = 'archive';
    //                         config.updatedAt = new Date();
    //                     }
    //                 }

    //                 setExperiments(updatedConfigs);

    //                 deselectAll();

    //                 utils.popup('hide');

    //                 for (let configId of error) {
    //                     utils.hintError('Unable to archive config ' + configId);
    //                 }
    //             } catch (err) {
    //                 utils.popup('hide');
    //                 utils.hintError('Unable to archive selected configs. ' + err);
    //             }
    //         }
    //     });
    // };

    const deleteSelected = () => {
        utils.confirm('Are you sure want to delete selected configs?', async (yes) => {
            if (yes) {
                try {
                    utils.popup('removing');

                    const selectedIds = selected;
                    const { success, error } = await removeConfigBatch(gameId, component.url, selectedIds);

                    setExperiments(experiments.filter((e) => success.indexOf(e.id) === -1));

                    utils.popup('hide');

                    deselectAll();

                    for (let configId of error) {
                        utils.hintError('Unable to delete config ' + configId);
                    }
                } catch (err) {
                    utils.popup('hide');
                    utils.hintError('Unable to delete selected configs. ' + err);
                }
            }
        });
    };

    const duplicateSelected = () => {
        utils.promt(
            'Please specify a ID prefix of each config to duplicate',
            async (prefix) => {
                if (prefix === false) return;

                prefix = prefix.trim();

                if (!prefix) return utils.hintError(prefix + ' ID is required.');

                if (!utils.isValidId(prefix)) {
                    return utils.hintError(
                        'ID prefix is not valid. a-z, A-Z, 0-9 and underscore are only allowed'
                    );
                }
                try {
                    utils.popup('Duplicating ...');
                    const selectedIds = selected;
                    const { success, error, data } = await duplicateConfigBatch(
                        gameId,
                        component.url,
                        selectedIds,
                        prefix
                    );

                    for (let configId of success) {
                        experiments.push(data[configId]);
                    }

                    setExperiments(experiments.slice());

                    utils.popup('hide');

                    deselectAll();

                    for (let configId of error) {
                        utils.hintError('Unable to duplicate config ' + configId);
                    }
                } catch (err) {
                    utils.popup('hide');
                    utils.hintError('Unable to duplicate selected configs. ' + err);
                }
            },
            {
                value: 'Copy_of_',
                label: 'ID Prefix',
                placeholder: 'duplicate_'
            }
        );
    };

    const _migrateConfig = async (gameId, scriptId, options) => {
        options.componentId = component.id;
        const result = await migrateConfig(gameId, options, scriptId);
        return result;
    };

    return (
        <>
            <Navigation gameId={gameId} tab="experiment" />

            <div className="container-lg">
                <ComponentHeader gameId={gameId} url={'config/' + component.url} id={component.id}>
                    <div>
                        <IAMButton permissions={['component.experiment.create']}>
                            <Link
                                to={'/game/' + gameId + '/config/experiments/create'}
                                className="btn btn-success shadow rounded-pill px-3"
                            >
                                <i className="fa fa-sm fa-plus" /> Add New
                            </Link>
                        </IAMButton>
                    </div>

                    <div className="ms-3">
                        <IAMButton permissions={['component.experiment.migrate']}>
                            <ProcessBlockedWrapper component={component.id}>
                                <button
                                    onClick={() => setMigrating(true)}
                                    className="btn btn-primary rounded-pill px-3 shadow"
                                >
                                    <i className="fas fa-angle-double-down me-1" /> Migrate From
                                </button>
                            </ProcessBlockedWrapper>
                        </IAMButton>
                    </div>
                </ComponentHeader>

                <div className="mt-2">
                    <div className="mb-3">
                        <ProcessBlockedWrapper component={component.id}>
                            <SelectionMenu
                                isDisabled={!experiments}
                                selectAll={selectAll}
                                deselectAll={deselectAll}
                                allSelected={allSelected}
                                getSelected={getSelected}
                                total={experiments ? experiments.length : 0}
                            >
                                <IAMButton
                                    permissions={['component.experiment.create']}
                                    layout="inline-start"
                                    size="md"
                                    wrapperClassName={'d-flex align-items-center'}
                                >
                                    <li className="dropdown-item" onClick={duplicateSelected} role="button">
                                        Duplicate selected
                                    </li>
                                </IAMButton>
                                <IAMButton
                                    permissions={['component.experiment.delete']}
                                    layout="inline-start"
                                    size="md"
                                    wrapperClassName={'d-flex align-items-center'}
                                >
                                    <li className="dropdown-item" onClick={deleteSelected} role="button">
                                        Delete selected
                                    </li>
                                </IAMButton>
                                {/* <li>
                                    <hr className="dropdown-divider" />
                                </li> */}
                                {/* <IAMButton
                                    permissions={['component.experiment.update']}
                                    layout="inline-start"
                                    size="md"
                                    wrapperClassName={'d-flex align-items-center'}
                                >
                                    <li className="dropdown-item" onClick={restoreSelected} role="button">
                                        Restore selected
                                    </li>
                                </IAMButton>
                                <IAMButton
                                    permissions={['component.experiment.update']}
                                    layout="inline-start"
                                    size="md"
                                    wrapperClassName={'d-flex align-items-center'}
                                >
                                    <li className="dropdown-item" onClick={archiveSelected} role="button">
                                        Archive selected
                                    </li>
                                </IAMButton> */}
                            </SelectionMenu>
                        </ProcessBlockedWrapper>
                    </div>
                    {!!experiments && (
                        <Table
                            onFilterChange={onFilterChange}
                            saveStateId="experiment-list"
                            sortBy={[{ id: 'displayUpdatedAt', desc: true }]}
                            columns={[
                                makeSelectColumn(
                                    onSelect,
                                    selectAll,
                                    deselectAll,
                                    allSelected,
                                    selected.length !== 0
                                ),
                                ...columns
                            ]}
                            data={data}
                            className="table-striped"
                        />
                    )}
                </div>

                <SetupScriptModal
                    show={migrating}
                    scriptId="experimentMigrate"
                    onFinish={() => {
                        setExperiments(null);
                        getConfigs(gameId, component.url).then(setExperiments).catch(utils.hintError);

                        queryClient.removeQueries({
                            queryKey: ['storage', gameId]
                        });
                    }}
                    onHide={() => setMigrating(false)}
                    customizeData={(data) => {
                        const options = data.options.find((opt) => opt.id === 'newOnly');

                        options.value = true;

                        return data;
                    }}
                    customFn={_migrateConfig}
                    game={game}
                />

                {!experiments && (
                    <div className="mt-4">
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" count={5} height={60} />
                    </div>
                )}
            </div>
        </>
    );
};

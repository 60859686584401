import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IAMButton, Input, ComponentHeaderSimple, useGame } from 'components';
import { gameContext, updateGameGroup } from 'services/game';
import { utils } from '@gpg-web/utils';
import { SettingsNavigation } from './Navigation';

import gameIcon from '../../../img/game-icon.png';

export const GameSettings = () => {
    const game = useGame();
    let { setGame } = useContext(gameContext);
    let [pictureSrc, setPictureSrc] = useState(null);

    const gameId = game.id;
    const loaded = !!gameId;

    let _pictureSrc = game && game.group && game.group.pictureSrc;

    useEffect(() => {
        if (!_pictureSrc) return;

        utils.load.file(_pictureSrc).then(setPictureSrc);

        return () => {
            delete game.group.pictureSrc;
            setGame({ ...game });
        };
    }, [_pictureSrc, setGame, game]);

    async function updateGroupInformation() {
        try {
            if (!game.group.name.trim()) return utils.hintError('Game Name is required');

            const yes = await utils.confirm('Are you sure you want to update game information?');

            if (!yes) return;

            utils.popup('saving');

            const formData = new FormData();

            formData.append('id', game.group.id);
            formData.append('name', game.group.name.trim());
            if (game.group.pictureSrc) formData.append('pictureSrc', game.group.pictureSrc);

            const result = await updateGameGroup(gameId, formData);

            game.group = result;
            setGame({ ...game });
            setPictureSrc(null);

            utils.popup('hide');
        } catch (err) {
            utils.hintError(err);
        }
    }

    async function updateGroupStoreInformation() {
        try {
            const yes = await utils.confirm('Are you sure you want to update game store information?');

            if (!yes) return;

            utils.popup('saving');

            // const formData = new FormData();
            // formData.append('apple', game.apple)
            // formData.append('google', game.google)

            // for (let key in game.group) {
            //     formData.append(key, game.group[key]);
            // }

            const result = await updateGameGroup(gameId, {
                id: game.group.id,
                apple: game.apple,
                google: game.google
            });

            game.apple = result.apple;
            game.google = result.google;
            setGame({ ...game });

            utils.popup('hide');
        } catch (err) {
            utils.hintError(err);
        }
    }

    if (!loaded) return null;

    return (
        <>
            <SettingsNavigation gameId={gameId} tab="game" />
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-8">
                    <h6 className="fw-bold text-muted mb-3">General</h6>
                    <div className="card shadow mb-5">
                        <div className="card-body row">
                            <div className="col-sm-12 col-md-3">
                                <img
                                    alt="Game Logo"
                                    width="100%"
                                    src={pictureSrc || game.group.picture || gameIcon}
                                />
                            </div>
                            <div className="col-sm-12 col-md-9">
                                <Input
                                    title="Name"
                                    name="name"
                                    value={game.group.name}
                                    onChange={(e) => {
                                        game.group.name = e.target.value;
                                        setGame({ ...game });
                                    }}
                                />

                                <div className="mb-3">
                                    <label className="mb-1">(optional) Set New Picture</label>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="picture"
                                        onChange={(e) => {
                                            game.group.pictureSrc = e.target.files[0];
                                            setGame({ ...game });
                                        }}
                                        className="form-control"
                                    />
                                </div>
                                <div className="text-end mt-4">
                                    <IAMButton permissions={['resourcemanager.projects.update']}>
                                        <button onClick={updateGroupInformation} className="btn btn-success">
                                            <i className="fas fa-check me-1" /> Save
                                        </button>
                                    </IAMButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="fw-bold text-muted mb-3">Stores</h6>

                    <div className="card shadow">
                        <div className="card-body">
                            <h5 className="fw-bold mb-3">
                                <i className="fab fa-apple me-3" />
                                Apple
                            </h5>
                            <Input
                                title="URL"
                                name="url"
                                value={game.apple.url}
                                onChange={(e) => {
                                    game.apple.url = e.target.value;
                                    setGame({ ...game });
                                }}
                            />
                            <Input
                                title="Package Name"
                                name="packageName"
                                value={game.apple.packageName}
                                onChange={(e) => {
                                    game.apple.packageName = e.target.value;
                                    setGame({ ...game });
                                }}
                            />

                            <h5 className="fw-bold mb-3 mt-5">
                                <i className="fab fa-google-play me-3" />
                                Google
                            </h5>
                            <Input
                                title="URL"
                                name="url"
                                value={game.google.url}
                                onChange={(e) => {
                                    game.google.url = e.target.value;
                                    setGame({ ...game });
                                }}
                            />
                            <Input
                                title="Package Name"
                                name="packageName"
                                value={game.google.packageName}
                                onChange={(e) => {
                                    game.google.packageName = e.target.value;
                                    setGame({ ...game });
                                }}
                            />
                            <div className="text-end mt-4">
                                <IAMButton permissions={['resourcemanager.projects.update']}>
                                    <button onClick={updateGroupStoreInformation} className="btn btn-success">
                                        <i className="fas fa-check me-1" /> Save
                                    </button>
                                </IAMButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { getConfig, updateConfig } from 'services/config';
import {
    InputOption,
    Alert,
    IAMButton,
    useModalBlocker,
    ProcessBlockedWrapper,
    useGame,
    Spinner,
    ImagePathOption,
    JSONOption,
    TextOption,
    EntitiesUsageOption
} from 'components';
import { useComponent } from '../hooks/UseComponent';
import { utils } from '@gpg-web/utils';
import { Modal } from '@gpg-web/react';
import { useQueryClient } from '@tanstack/react-query';
import { ConfigReadOnlyInfo } from '../components/ConfigReadOnlyInfo';
import Skeleton from 'react-loading-skeleton';
import { ChangeHistory } from '../components/ChangeHistory';

let queueId = 0;

export const ProductEditModal = (props) => {
    const { onHide, show, configId } = props;
    const [data, setData] = useState(null);
    const component = useComponent('product');

    const [saving, setSaving] = useState(false);
    const [saveBlock, setSaveBlock] = useState('00');
    const [error, setError] = useState(null);
    const [updateInitialData, onBeforeClose, isBlocked] = useModalBlocker(data);
    const queryClient = useQueryClient();
    const game = useGame();
    const gameId = game.id;

    useEffect(() => {
        if (!(gameId && configId)) return;
        setData(null);
        queueId++;
        const _queueId = queueId;
        getConfig(gameId, component.url, configId)
            .then((res) => {
                if (_queueId === queueId) setData(updateInitialData(res));
            })
            .catch(utils.hintError);
    }, [gameId, component.url, configId, updateInitialData]);

    const reset = () => {
        setError(null);
        setSaving(false);
        setData(updateInitialData(null));
        setSaveBlock('00');

        if (show) {
            onHide();
        }
    };

    const handleChange = (name, value) => setData({ ...data, [name]: value });

    async function handleSave() {
        try {
            // if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

            let id = data.id.trim();

            if (!id) return setError('ID field is required');

            if (!utils.isValidId(id)) {
                return setError('ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed');
            }

            data.id = id;
            setSaving(true);
            await updateConfig(gameId, component.url, data);

            queryClient.refetchQueries({
                queryKey: [component.id, gameId],
                exact: true
            });

            onHide();
        } catch (err) {
            setSaving(false);
            setError(err);
        }
    }

    return (
        <Modal
            title=<div className="d-flex align-items-center">
                <ChangeHistory /> <div className="ms-2">Edit product "{configId}"</div>
            </div>
            show={show}
            onHide={reset}
            onBeforeClose={onBeforeClose}
        >
            <div className="modal-body">
                {data && (
                    <>
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <ConfigReadOnlyInfo data={data} />
                                {data.isSubscription && (
                                    <div className="py-1 fw-bold text-center bg-info-subtle text-info-emphasis rounded-2 border mt-4">
                                        <i className="fas fa-tags fa-sm me-1"/> Subscription
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <InputOption
                                    name="name"
                                    title="Display name"
                                    tooltip="Visible to Livefire only"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                                <TextOption
                                    name="description"
                                    title="Description"
                                    tooltip="Visible to Livefire only"
                                    value={data.description}
                                    onChange={handleChange}
                                />
                                <ImagePathOption
                                    key={show}
                                    onBlock={(e) => setSaveBlock(saveBlock[0] + Number(e))}
                                    name="icon"
                                    tooltip="Visible to Livefire only"
                                    gameId={gameId}
                                    title="Display icon"
                                    value={data.icon}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <JSONOption
                            key={show}
                            onBlock={(e) => setSaveBlock(Number(e) + saveBlock[1])}
                            value={data.customData}
                            title="Custom data"
                            name="customData"
                            className="mb-4"
                            onChange={handleChange}
                        />

                        <EntitiesUsageOption
                            title="Prices"
                            gameId={gameId}
                            name="prices"
                            fetchPrices={!data.isSubscription}
                            value={data.prices}
                            max={1}
                            time={false}
                            includeIAP
                            className="mb-4"
                            filter={{ isConsumable: true }}
                            onChange={handleChange}
                        />

                        <EntitiesUsageOption
                            title="Content"
                            gameId={gameId}
                            name="content"
                            value={data.content}
                            onChange={handleChange}
                        />
                    </>
                )}

                {!data && (
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <Skeleton className="mb-3" height="30px" />
                            <Skeleton className="mb-2" count="2" height="50px" />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <Skeleton className="mb-2" height="60px" />
                            <Skeleton className="mb-3 mt-3" height="80px" />
                            <Skeleton className="mb-2" height="30px" />
                        </div>
                        <Skeleton className="mt-4" height="200px" />
                        <Skeleton className="mt-4" height="100px" />
                        <Skeleton className="mt-4" height="150px" />
                    </div>
                )}
            </div>

            <div className="modal-footer py-3">
                {error && <Alert text={error} />}
                <button
                    disabled={saving}
                    className="btn rounded-pill ms-auto shadow btn-light px-3"
                    data-bs-dismiss="modal"
                >
                    Cancel
                </button>

                <IAMButton permissions={['component.config.update']}>
                    <ProcessBlockedWrapper component={component.id}>
                        <button
                            disabled={saving || saveBlock !== '00' || !data || !isBlocked}
                            onClick={handleSave}
                            className="btn btn-success rounded-pill px-4 ms-3 shadow"
                        >
                            {saving && <Spinner className="me-2" />}Publish changes
                        </button>
                    </ProcessBlockedWrapper>
                </IAMButton>
            </div>
        </Modal>
    );
};

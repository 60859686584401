
const Alert = function (msg, cb) {
    const el = document.getElementById('#alertModal');
    el.modal('show');

    function ok() {
        cb && cb(true);
    }

    const okB = el[0].querySelector('#alertModalOkButton');

    el[0].querySelector('#alertModalLabel').innerHTML = msg;

    okB.addEventListener('click', ok, { once: true });

    el.one('hidden.bs.modal', function (e) {
        okB.removeEventListener('click', ok);
    });
};

export default Alert;

import { Link } from 'react-router-dom';
import { ComponentsMap } from 'consts';
import { TooltipWrapper } from 'components';
import Select from 'react-select';
import { str, utils } from '@gpg-web/utils';
import className from 'classnames';

export const SelectedConfig = ({
    value,
    index,
    removeConfig,
    originalContent,
    selectFile,
    selectVersion,
    gameId,
    readOnly
}) => {
    const component = ComponentsMap[value.component] || { name: value.component };

    return (
        <tr className="align-middle">
            <td>
                <div>
                    <Link
                        to={'/game/' + gameId + '/config/' + component.url + '/view/' + value.id}
                        className="fw-bold text-decoration-none"
                    >
                        {value.id}
                    </Link>
                </div>
                <div className="me-1 fw-light small">{component.name}</div>
            </td>
            <td className="text-nowrap">
                <div className="font-monospace" title={value.src.path}>
                    <TooltipWrapper
                        icon="fas fa-exclamation-triangle text-warning"
                        layout="inline-start"
                        content={
                            !!(
                                value.src.path &&
                                originalContent.path &&
                                value.src.path !== originalContent.path
                            ) && 'Selected file path is different from the original config path'
                        }
                    >
                        {str.trim(value.src.path, 30, true)}
                    </TooltipWrapper>
                </div>

                {!value.src.version && readOnly ? (
                    <div className="text-danger">
                        <i className="fas fa-sm fa-exclamation-circle me-1" />
                        File versions is not selected
                    </div>
                ) : (
                    <TooltipWrapper
                        icon="fas fa-exclamation-triangle text-warning"
                        layout="inline-start"
                        content={
                            !!(
                                value.src.version &&
                                originalContent.version &&
                                value.src.version !== originalContent.version
                            ) &&
                            'File version mismatch for selected environment & game version. Actual version is ' +
                                originalContent.version
                        }
                    >
                        {readOnly ? (
                            <div className="font-monospace">#{value.src.version}</div>
                        ) : (
                            <button
                                onClick={(e) =>
                                    selectVersion({
                                        index: index,
                                        type: 'src',
                                        path: value.src.path,
                                        parent: e.target,
                                        version: value.src.version
                                    })
                                }
                                className="btn btn-sm btn-light py-0"
                            >
                                <i className="fas fa-edit me-1 fa-sm" />{' '}
                                {value.src.version ? (
                                    '#' + value.src.version
                                ) : (
                                    <span className="text-danger">Select version</span>
                                )}
                            </button>
                        )}
                    </TooltipWrapper>
                )}
            </td>

            <td className="text-nowrap">
                {readOnly ? (
                    <>
                        {value.dst.path ? (
                            <>
                                <span title={value.dst.path} className="font-monospace">
                                    {str.trim(value.dst.path, 30, true)}
                                </span>

                                {value.dst.version ? (
                                    <div className="font-monospace">#{value.dst.version}</div>
                                ) : (
                                    <div className="text-danger">
                                        <i className="fas fa-sm fa-exclamation-circle me-1" />
                                        File versions is not selected
                                    </div>
                                )}
                            </>
                        ) : (
                            <span className="text-danger">
                                <i className="fas fa-sm fa-exclamation-circle me-1" />
                                File is not selected
                            </span>
                        )}
                    </>
                ) : (
                    <>
                        <div>
                            <button
                                disabled={readOnly}
                                title="Select file"
                                onClick={() => selectFile(index)}
                                className="btn btn-sm border-0"
                            >
                                <i className="fas fa-folder-open text-warning" />
                                {!value.dst.path && <span className="ms-2 text-danger">Select file</span>}
                            </button>

                            <span className="font-monospace" title={value.dst.path}>
                                {str.trim(value.dst.path, 30, true)}
                            </span>
                        </div>

                        {value.dst.path && (
                            <>
                                <button
                                    onClick={(e) =>
                                        selectVersion({
                                            index: index,
                                            type: 'dst',
                                            path: value.dst.path,
                                            parent: e.target,
                                            version: value.dst.version
                                        })
                                    }
                                    className="btn btn-sm btn-light py-0"
                                >
                                    <i className="fas fa-edit me-1 fa-sm" />{' '}
                                    {value.dst.version ? (
                                        '#' + value.dst.version
                                    ) : (
                                        <span className="text-danger">Select version</span>
                                    )}
                                </button>
                            </>
                        )}
                    </>
                )}
            </td>

            <td className="text-nowrap">
                {value.patch ? (
                    <div className={className({ 'text-danger': value.patch.length === 0 })}>
                        <div>
                            Patched values: <b>{value.patch.length}</b>
                        </div>
                        <div>
                            Size: <b>{utils.bytesToStr(JSON.stringify(value.patch).length - 2)}</b>
                        </div>
                    </div>
                ) : readOnly ? (
                    <span className="text-danger">
                        <i className="fas fa-sm fa-exclamation-circle me-1" />
                        Patch is not provided
                    </span>
                ) : (
                    <span className="text-gray-500">
                        <i className="fas fa-sm fa-exclamation-triangle me-1" />
                        Not provided
                    </span>
                )}
            </td>

            <td>
                {!readOnly && (
                    <button onClick={() => removeConfig(index)} className="btn btn-sm">
                        <i className="fas fa-lg fa-times-circle pe-none" />
                    </button>
                )}
            </td>
        </tr>
    );
};

const FileData = (props) => {};

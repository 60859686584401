import { useState, useEffect } from 'react';
import { getNotifications } from 'services/notification';
import { Alert } from 'components';
import { NotificationAlertItem } from 'components';
// import { utils } from '@gpg-web/utils';

const NotificationsModal = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const oppened = function () {
            getNotifications().then(setNotifications).catch(setError);
        };

        const element = document.getElementById('notifications-modal');

        element.addEventListener('show.bs.modal', oppened);

        return () => {
            element.removeEventListener('show.bs.modal', oppened);
        };
    }, []);

    return (
        <>
            <div className="modal fade" id="notifications-modal">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title fw-bold">ALERTS CENTER</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>

                        <div className="mx-4 mt-3 mb-3">
                            {notifications.map((e) => (
                                <NotificationAlertItem key={e.id} modal={true} data={e} />
                            ))}

                            {error && <Alert text={error} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotificationsModal;

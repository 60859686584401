import React from 'react';

const PromtModal = (props) => {
    return (
        <>
            <div
                className="modal fade"
                id="promtModal"
                data-bs-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="promtModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title text-dark" id="promtModalLabel">
                                Confirm Action
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>

                        <div className="form-group mx-4 mt-2 mb-3">
                            <label htmlFor="promtInputField" id="promtInputFieldLabel"></label>
                            <input
                                id="promtInputField"
                                autoComplete="off"
                                type="text"
                                className="form-control mt-1"
                                placeholder="Type here"
                            />
                        </div>

                        <div className="modal-footer">
                            <button
                                id="promtModalCancelButton"
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                id="promtModalOkButton"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                                type="button"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PromtModal;

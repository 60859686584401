/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';
import { Link } from 'react-router-dom';
import { IAMComponentWrapper, useGame } from 'components';
import { ComponentsMap } from 'consts';
import { useQuery } from '@tanstack/react-query';
import { getConfigsPaths } from 'services/config';
import Select from 'react-select';

const COMPONENTS = ['gameBalance', 'store', 'tle', 'tlo', 'translation', 'popup', 'dailyReward', 'piggyBank'];

export const ConfigsSetup = (props) => {
    const { data, setData } = props;

    const game = useGame();
    const gameId = game.id;

    const selectedConfigs = data.configs;

    const addConfig = (config) => {
        selectedConfigs.push(config);

        // let content = null;

        // if (
        //     config.type === 'TLE' ||
        //     config.type === 'PiggyBank' ||
        //     config.type === 'DailyReward' ||
        //     config.contentType === 'file' ||
        //     config.contentType === 'environmental'
        // ) {
        //     content = config.content;
        // }

        // for (let variant of variants) {
        //     variant.values[id] = content;
        // }

        setData({ ...data, configs: selectedConfigs.slice() });
    };

    const removeConfig = (e) => {
        utils.confirm('Are you sure you want to remove this config from the list?', (yes) => {
            if (!yes) return;
            const configIndex = e.target.getAttribute('data-index');
            selectedConfigs.splice(configIndex, 1);
            setData({ ...data, configs: selectedConfigs.slice() });
        });
    };

    return (
        <div className={utils.className('card', props.className)}>
            <div className="card-header py-3">
                <h6 className="m-0 fw-bold text-primary">Configs</h6>
            </div>

            <div className="card-body">
                <div>
                    {data.configs.map((e, index) => (
                        <Config
                            key={index}
                            index={index}
                            gameId={gameId}
                            value={e}
                            removeConfig={removeConfig}
                            readOnly={props.readOnly}
                        />
                    ))}
                </div>

                <div className={utils.className(props.readOnly ? 'd-none' : '')}>
                    <IAMComponentWrapper permissions={['component.config.list']}>
                        <AddConfig selectedConfigs={selectedConfigs} gameId={gameId} addConfig={addConfig} />
                    </IAMComponentWrapper>
                </div>
            </div>
        </div>
    );
};

const AddConfig = (props) => {
    const { selectedConfigs, gameId } = props;

    const {
        isFetching,
        error,
        data: configs
    } = useQuery({
        queryKey: ['experiment', 'configs', gameId],
        queryFn: async () => {
            // console.log('Loading');

            let configs = [];

            for (let componentId of COMPONENTS) {
                const component = ComponentsMap[componentId];
                const loaded = await getConfigsPaths(gameId, component.url);
                configs = configs.concat(
                    loaded.map((e) => {
                        e.uid = component.id + e.id;
                        e.component = component.id;
                        return e;
                    })
                );
            }

            return configs;
        },
        enabled: !!gameId
    });

    const addConfig = (uid) => {
        const config = configs.find((e) => e.uid === uid);

        props.addConfig({
            id: config.id,
            path: config.path,
            component: config.component
        });
    };

    let options = [];

    if (configs) {
        const categories = {};

        for (let config of configs) {
            const componentId = config.component;

            if (selectedConfigs.findIndex((e) => e.id === config.id && e.component === componentId) > -1)
                continue;

            categories[componentId] = categories[componentId] || [];
            categories[componentId].push({ label: config.id, value: config.uid });
        }

        options = Object.keys(categories).map((componentId) => {
            const component = ComponentsMap[componentId] || { name: componentId };

            return {
                label: component.name,
                options: categories[componentId].sort((a, b) => a.label.localeCompare(b.label))
            };
        });
    }

    return (
        <div className="row mt-3">
            <div className="col-xl-4 col-lg-6 col-md-12">
                <Select
                    value={null}
                    name="experiment-config-select"
                    isClearable={false}
                    placeholder="Choose config"
                    isLoading={isFetching}
                    backspaceRemovesValue={false}
                    options={options}
                    className="react-select"
                    classNamePrefix="select"
                    isDisabled={props.readOnly}
                    onChange={(e) => addConfig(e.value)}
                    components={{
                        IndicatorSeparator: null
                    }}
                    styles={{
                        valueContainer: (base) => ({ ...base, padding: '2px 8px' })
                    }}
                />
            </div>
            {error && (
                <div className="col-12 mt-3">
                    <div className=" text-danger">
                        <i className="fas fa-exclamation-circle me-2" />
                        {error}
                    </div>
                </div>
            )}
        </div>
    );
};

const Config = (props) => {
    const { value, index, removeConfig, gameId } = props;

    const component = ComponentsMap[value.component] || { name: value.component };

    return (
        <div className={'card mb-2 ' + (index % 2 ? 'bg-white' : 'bg-gray-100')}>
            <div className="py-1 px-3 d-flex align-items-center small">
                <div className="me-1">{component.name} |</div>
                <Link
                    to={'/game/' + gameId + '/config/' + component.url + '/view/' + value.id}
                    className="fw-bold text-decoration-none"
                >
                    {value.id}
                </Link>
                <div className="mx-2">-</div>
                {!value.path && (
                    <div className="text-danger">
                        <i className="fas fa-sm fa-exclamation-circle me-2" />
                        ERROR: File is not selected
                    </div>
                )}
                <div className="font-monospace text-danger">{value.path}</div>

                <div className="ms-auto">
                    <button
                        data-index={index}
                        onClick={removeConfig}
                        disabled={props.readOnly}
                        className={utils.className('btn btn-sm', props.readOnly ? 'invisible' : '')}
                    >
                        <i className="fas fa-lg fa-times-circle pe-none" />
                    </button>
                </div>
            </div>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { getScripts } from 'services/automation';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import { useGame } from 'components';
import Card from './Card';
import SetupModal from './SetupModal';
import scriptsData from './data';

export const ScriptsLibrary = (props) => {
    const [scripts, setScripts] = useState(null);
    // const [setupScriptId, setSetupScriptId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const game = useGame();
    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getScripts(gameId)
            .then((list) => {
                list.forEach((e) => {
                    if (scriptsData[e.id]) {
                        scriptsData[e.id].name = e.name;
                        scriptsData[e.id].components = e.components;
                    }
                });
                setScripts(list);
            })
            .catch(utils.hintError);
    }, [gameId]);

    let setupScriptId = null;

    if (scripts) {
        if (location.hash.length) {
            const scriptIdHash = location.hash.slice(1);

            if (scripts.find((sc) => sc.id === scriptIdHash)) {
                setupScriptId = scriptIdHash;
            } else {
                utils.hintError('Script ' + scriptIdHash + ' not found');
                return <Navigate to={''} relative="route" replace={true} />;
            }
        }
    }

    const setSetupScriptId = (scriptId) => {
        if (scriptId) {
            return navigate('#' + scriptId, { relative: 'route' });
        } else {
            return navigate('', { relative: 'route' });
        }
    };

    return (
        <>
            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4>Scripts Library</h4>
                </div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small">
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Scripts
                        </li>
                    </ol>
                </nav>

                <SetupModal
                    game={game}
                    show={scripts && !!setupScriptId}
                    scriptId={setupScriptId}
                    onHide={setSetupScriptId}
                />

                {!!scripts && (
                    <div className="mt-3 row">
                        {scripts.map((e) => {
                            if (scriptsData[e.id]?.hideInLibrary) return null;

                            return (
                                <div key={e.id} className="col-xxl-4 col-md-6 col-12 p-2">
                                    <Card
                                        id={e.id}
                                        name={e.name}
                                        components={e.components}
                                        onSetup={setSetupScriptId}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}

                {!scripts && (
                    <Skeleton
                        containerClassName="mt-3 row"
                        wrapper={LoadingCard}
                        className="shadow-sm"
                        height="180px"
                        width="100%"
                        count={6}
                    />
                )}
            </div>
        </>
    );
};

function LoadingCard(props) {
    return <div className="col-xxl-4 col-md-6 col-12 p-2">{props.children}</div>;
}

import { StatusLabel } from 'components';

export const StatusView = ({ value, options }) => {
    if (!value) {
        const option = options.find((opt) => opt.value === value);

        if (option && option.label) return <span className="fw-bold">{option.label}</span>;

        return <span className="fw-bold">Not selected</span>;
    }

    if (value === 'all') {
        return <span className="fw-bold">All Statuses</span>;
    }

    if (value === '') {
        return <span className="fw-bold">Don't change</span>;
    }

    if (Array.isArray(value)) {
        return value.map((val) => (
            <span className="me-2 d-inline-block mb-1" key={val}>
                <StatusLabel value={val} />
            </span>
        ));
    }

    return <StatusLabel value={value} />;
};

let modal = null;

const Confirm = function (msg, cb, options) {
    if (typeof cb === 'object') {
        options = cb;
        cb = null;
    }

    return new Promise((resolve, reject) => {
        options = options || {};

        const el = document.getElementById('confirmModal');

        if (modal === null) {
            modal = new window.bootstrap.Modal(el);
        }

        const okB = el.querySelector('#confirmModalOkButton');
        const cancelButton = el.querySelector('#confirmModalCancelButton');

        if (options.buttons) {
            if (options.buttons.ok) okB.innerText = options.buttons.ok;
            if (options.buttons.cancel) cancelButton.innerText = options.buttons.cancel;
        }

        el.querySelector('#confirmModalLabel').innerHTML = msg;

        let result = false;

        okB.onclick = function () {
            result = true;
        };

        el.addEventListener(
            'hidden.bs.modal',
            function () {
                cb && cb(result);
                resolve(result);
            },
            { once: true }
        );

        modal.show();
    });
};

export default Confirm;

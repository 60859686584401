import { useState } from 'react';
import { utils, date as dateUtils } from '@gpg-web/utils';

function convertDate(date, shift) {
    date = new Date(date);

    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    if (shift) date.setTime(date.getTime() + shift * 60 * 60 * 1000);

    return dateUtils(date, 'local');
}

export function generateExample(time, tz, turns, city) {
    tz = -tz * 60;
    let data = time.data;

    if (!data.start) return;

    let preEvent = '';
    let postEvent = '';
    let startDate, endDate, dateForIcon;
    const tzOffset = time.global ? tz : 0;

    if (time.mode === 'default') {
        startDate = new Date(data.start);
        endDate = new Date(data.end);

        if (tzOffset) {
            startDate.setTime(startDate.getTime() - tzOffset * 60 * 1000);
            endDate.setTime(endDate.getTime() - tzOffset * 60 * 1000);
        }

        dateForIcon = new Date(startDate);
        dateForIcon.setTime(dateForIcon.getTime() + dateForIcon.getTimezoneOffset() * 60 * 1000);
    } else {
        const now = new Date();
        if (!time.global) now.setTime(now.getTime() - tz * 60 * 1000);
        startDate = utils.timeTrigger.getRepeatStartDate(now, -tzOffset, data, turns);
        endDate = utils.timeTrigger.getRepeatEndDate(startDate, -tzOffset, data);
        dateForIcon = new Date(startDate);
        dateForIcon.setTime(dateForIcon.getTime() + dateForIcon.getTimezoneOffset() * 60 * 1000);

        const end = new Date(endDate);
        // if (tzOffset) {
        //     end.setTime(end.getTime() + tzOffset * 60 * 1000);
        // }
        if (data.postEvent) end.setTime(end.getTime() + data.postEvent * 60 * 60 * 1000);
        if (startDate > end) {
            return (
                <tr>
                    <td>✅ {city} - Finished</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            );
        }

        const start = new Date(data.start);

        if (data.startTime) {
            const splitted = data.startTime.split(':');
            start.setUTCHours(splitted[0], splitted[1]);
        }

        if (tzOffset) {
            start.setTime(start.getTime() + tzOffset * 60 * 1000);
        }

        if (startDate < start && end < start) {
            return (
                <tr>
                    <td>{utils.hourIcon(dateForIcon) + ' ' + city} - No recent events</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            );
        }
    }

    if (data.preEvent) {
        preEvent = <>{convertDate(startDate, -data.preEvent)}</>;
    }

    if (data.postEvent) {
        postEvent = <>{convertDate(endDate, data.postEvent)}</>;
    }

    return (
        <tr>
            <td>{utils.hourIcon(dateForIcon) + ' ' + city}</td>
            {!!preEvent && <td>{preEvent}</td>}
            <td>{convertDate(startDate)}</td>
            <td>{convertDate(endDate)}</td>
            {!!postEvent && <td>{postEvent}</td>}
        </tr>
    );
}

export const TimeExamples = ({ time }) => {
    const [showMoreExamples, setShowMoreExamples] = useState(false);
    const [turnExamples, setTurnExamples] = useState(0);

    return (
        <div className="alert alert-info">
            <div className="d-flex">
                <span>Examples: (what time on player's devices)</span>
                {time.mode === 'repeat' && (
                    <>
                        {' '}
                        <button
                            disabled={turnExamples === 0}
                            className="btn btn-sm py-0 btn-outline-primary ms-auto"
                            onClick={() => setTurnExamples(turnExamples - 1)}
                        >
                            &lt;
                        </button>
                        <button
                            disabled={turnExamples === 10}
                            className="btn btn-sm py-0 btn-outline-primary ms-2"
                            onClick={() => setTurnExamples(turnExamples + 1)}
                        >
                            &gt;
                        </button>
                    </>
                )}
            </div>
            <table className="table table-sm table-striped bordered mt-1 small mb-1">
                <thead>
                    <tr>
                        <th>City/Time Zone</th>
                        {!!time.data.preEvent && <th>Pre-event datetime</th>}
                        <th>Start datetime</th>
                        <th>End datetime</th>
                        {!!time.data.postEvent && <th>Post-event datetime</th>}
                    </tr>
                </thead>
                <tbody>
                    {generateExample(time, -5, turnExamples, 'New York (GMT-5)')}
                    {generateExample(time, 0, turnExamples, 'London (GMT)')}
                    {generateExample(time, 9, turnExamples, 'Tokyo (GMT+9)')}

                    {showMoreExamples && (
                        <>
                            {generateExample(time, 1, turnExamples, 'Paris (GMT+1)')}
                            {generateExample(time, 2, turnExamples, 'Kyiv (GMT+2)')}
                            {generateExample(time, 5.5, turnExamples, 'New Delhi (GMT+5:30)')}
                            {generateExample(time, 8, turnExamples, 'Philippines (GMT+8)')}
                        </>
                    )}
                </tbody>
            </table>
            {!showMoreExamples && (
                <button className="btn btn-sm" onClick={() => setShowMoreExamples(true)}>
                    Show more...
                </button>
            )}
        </div>
    );
};

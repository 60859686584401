import { TooltipWrapper } from 'components';

export const NumberOption = ({
    title,
    name,
    value,
    onChange,
    readOnly,
    min,
    max,
    description,
    disabled,
    error,
    children
}) => {
    return (
        <div className="my-1">
            <div className="d-flex align-items-center">
                <div className="me-2 text-nowrap">{title}</div>
                <div className="d-flex align-items-center position-relative">
                    <div className="input-group">
                        {children && <div className="input-group-text">{children}</div>}
                        <input
                            className="form-control error"
                            type="number"
                            disabled={disabled || readOnly}
                            style={{ paddingTop: '2px', paddingBottom: '2px', paddingRight: '30px' }}
                            min={typeof min === 'number' ? min : undefined}
                            max={typeof max === 'number' ? max : undefined}
                            value={typeof value === 'number' ? value : 0}
                            onChange={(e) => onChange(name, Number(e.target.value))}
                            // readOnly={readOnly}
                        />
                    </div>
                    {!!description && (
                        <span
                            className="position-absolute text-gray-600 text-nowrap z-6"
                            style={{ right: '4%', top: '3px' }}
                        >
                            <TooltipWrapper icon="false" content={description ? description : null}>
                                <i className="fas fa-info-circle text-dark" />
                            </TooltipWrapper>
                        </span>
                    )}
                </div>
            </div>
            {error && (
                <div className="small text-danger text-wrap">
                    <i className="fa fa-exclamation-circle me-1" />
                    {error}
                </div>
            )}
        </div>
    );
};

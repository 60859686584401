const breakpoints = {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0
};

function getSize(className) {
    let classes = className.split(' ');
    let width = window.innerWidth;
    let size = 1;
    for (let key in breakpoints) {
        if (breakpoints[key] < width) {
            let indexOf = className.indexOf('col-' + key);
            if (indexOf > -1) {
                size = Number(className.substr(indexOf + key.length + 5, 1)) || 1;
                break;
            }
        }
    }

    size = 12 / size;

    if (size > 6) size = 1;

    return size;
}

export const CardsContainer = ({ list, card, className = 'col-lg-6 col-md-12' }) => {
    const count = getSize(className);
    const columns = [];

    for (let i = 0; i < list.length; i++) {
        let index = i % count;
        if (!columns[index]) columns[index] = [];
        columns[index].push(list[i]);
    }

    return (
        <div className="row">
            {columns.map((cards, index) => (
                <div key={index} className={className}>{cards.map(card)}</div>
            ))}
        </div>
    );
};

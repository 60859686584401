import { Navigate, useLocation, useParams } from 'react-router-dom';
import { TutorialView } from './TutorialView';

const base_url = '/tutorials';

export const TutorialsPage = () => {
    const { category, title } = useParams();
    const { hash } = useLocation();

    let tutorial = null;

    if (!category && !title) {
        tutorial = 'Home';
    }

    if ((category && !title) || (!category && title)) {
        return <Navigate to={base_url} replace={true} />;
    }

    return (
        <div className="container-lg">
            <TutorialView id={tutorial} section={hash} />
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useMemo } from 'react';
import { InputOption, BoolOption, Alert, TextOption } from 'components';
import { addGame } from 'services/game';
import { utils } from '@gpg-web/utils';
import Select from 'react-select';
import gameIcon from '../../img/game-icon.png';
import { Modal, Spinner } from '@gpg-web/react';

const AddNewGame = (props) => {
    const { groups } = props;
    const [show, setShow] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        group: null,
        id: '',
        name: '',
        gcp_service_account: '',
        isProduction: false
    });

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const handleChangeJSON = (name, value) => {
        setError(null);

        try {
            if (value) {
                value = JSON.stringify(JSON.parse(value));
            }

            setData({ ...data, [name]: value });
        } catch (err) {
            setError('GCP Service Account is not a valid JSON');
        }
    };

    const _addGame = () => {
        setError(null);

        data.name = data.name.trim();
        data.id = data.id.trim();

        if (!data.group) return setError('Game group is required');
        if (!data.id) return setError('ID is required');
        if (!utils.isValidGameId(data.id)) {
            return setError(
                'ID is not valid. a-z, A-Z, 0-9, underscore and dash are only allowed. ID should not starts and ends with dash.'
            );
        }
        if (!data.name) return setError('Name is required');
        if (!data.gcp_service_account) return setError('GCP Service Account is required');

        setLoading(true);

        addGame(data)
            .then((result) => {
                setData({});
                setLoading(false);
                props.onAdd(result);
                setShow(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    let gcp_service_account = '';

    if (data.gcp_service_account) {
        try {
            gcp_service_account = JSON.stringify(JSON.parse(data.gcp_service_account), null, 4);
        } catch (err) {}
    }

    const groupsOptions = useMemo(() => {
        if (!groups) return [];

        return groups.map((group) => {
            return {
                value: group.id,
                label: (
                    <>
                        <img
                            alt=""
                            src={group.picture || gameIcon}
                            className="rounded-circle"
                            height="25px"
                            width="25px"
                        />
                        <span className="ms-2">{group.name}</span>
                    </>
                )
            };
        });
    }, [groups]);

    function reset() {
        setShow(false);
        setData({});
        setError(null);
        setLoading(false);
    }

    return (
        <>
            <button className="btn btn-sm btn-primary ms-3" onClick={() => setShow(true)}>
                <i className="fas me-2 fa-plus" /> New stage
            </button>
            <Modal show={show} title="New stage" onHide={reset}>
                <div className="modal-body">
                    <Select
                        value={groupsOptions.find((e) => e.value === data.group) || null}
                        name="group"
                        isSearchable={false}
                        onChange={(e) => {
                            setData({ ...data, group: e.value });
                        }}
                        options={groupsOptions}
                        placeholder="Choose game group *"
                        className="react-select mb-4"
                        classNamePrefix="select"
                    />
                    <InputOption
                        name="id"
                        tooltip={
                            <>
                                REQUIRED
                                <br />
                                Please provide an unique game id.
                                <br />
                                For example: "my-game-dev".
                            </>
                        }
                        title="ID"
                        required
                        value={data.id}
                        onChange={handleChange}
                    />
                    <InputOption
                        name="name"
                        title="Name"
                        required
                        value={data.name}
                        onChange={handleChange}
                    />
                    <BoolOption
                        name="isProduction"
                        title="Is production"
                        value={data.isProduction}
                        onChange={handleChange}
                    />
                    <InputOption
                        name="backend_api_url"
                        title="Backend API URL"
                        value={data.backend_api_url}
                        onChange={handleChange}
                    />
                    <TextOption
                        rows={6}
                        required
                        tooltip="Please, paste service account JSON here, instead of typing it manually"
                        title="GCP Service Account"
                        name="gcp_service_account"
                        value={gcp_service_account}
                        onChange={handleChangeJSON}
                    />
                    {error && <Alert text={error} />}
                </div>
                <div className="modal-footer">
                    <button disabled={loading} onClick={_addGame} className="btn btn-success">
                        {loading && <Spinner />} <i className="fas fa-check me-1" /> Create Stage
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default AddNewGame;

import { useState } from 'react';
import { utils } from '@gpg-web/utils';
import { getConfig, updateConfig } from 'services/config';
import { IAMButton, ComponentHeaderSimple, useGame, usePageBlocker } from 'components';
import { ChangeHistory } from '../../components/ChangeHistory';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentSetup } from './Content';

export const ConfigBuiltInForcedUpdate = () => {
    const game = useGame();

    const [content, setContent] = useState(null);

    const gameId = game.id;

    const [updateInitialData, , isBlocked] = usePageBlocker(content);

    const queryClient = useQueryClient();

    const {
        data: forceUpdateData,
        isFetching,
        error,
        isFetched,
        isSuccess
    } = useQuery({
        queryKey: ['forced-update', 'built-in', gameId],
        queryFn: () => getConfig(gameId, 'built-in', 'forced-update'),
        enabled: !!gameId && !content
    });

    if (!content && !!forceUpdateData && isFetched && isSuccess) {
        setContent(updateInitialData(forceUpdateData.content));
    }

    const saveChanges = async () => {
        if (!(gameId && content)) return;

        try {
            const yes = await utils.confirm('Are you sure you want to update forced update config?');

            if (!yes) return;

            utils.popup('saving');

            queryClient.invalidateQueries({ queryKey: ['forced-update'] });

            const result = await updateConfig(gameId, 'built-in', {
                id: 'forced-update',
                content: content
            });

            setContent(updateInitialData(result.content));

            utils.popup('hide');
        } catch (err) {
            utils.hintError(err);
        }
    };

    return (
        <div className="container-lg">
            <ComponentHeaderSimple gameId={gameId} name="Forced Update">
                {forceUpdateData && <ChangeHistory />}
                <IAMButton permissions={['resourcemanager.projects.update']}>
                    <button
                        disabled={!isBlocked || isFetching}
                        className="btn rounded-pill px-4 ms-3 btn-success shadow"
                        onClick={saveChanges}
                    >
                        Publish changes
                    </button>
                </IAMButton>
            </ComponentHeaderSimple>

            {error && (
                <div className="alert alert-danger mt-4">
                    <i className="fas fa-exclamation-circle fa-sm me-2" />
                    {error}
                </div>
            )}

            <ContentSetup
                content={content}
                isReady={isFetched && isSuccess}
                isLoading={!content || isFetching}
                gameId={gameId}
                onChange={setContent}
            />
        </div>
    );
};

import axios from 'axios';

const setIAMPolicy = (gameId, iam) => {
    return axios.post('/api/project/' + gameId + '/iam', iam);
};

const rolesCache = new Map();

const getRoles = (gameId) => {
    const roles = rolesCache.get(gameId);

    if (roles) {
        return Promise.resolve(roles);
    }

    return axios.get('/api/project/' + gameId + '/iam/roles').then((list) => {
        const object = {};

        list.forEach((e) => {
            object[e.id] = e;
        });

        rolesCache.set(gameId, object);

        return object;
    });
};

export { setIAMPolicy, getRoles };

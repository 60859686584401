import { useCallback, useEffect, useRef, useState } from 'react';
import { utils } from '@gpg-web/utils';
import { Status } from 'consts';
import { IAMButton, IAMComponentWrapper } from 'components';

const headerStyles = {
    transform: 'translate(-10%, -100%)',
    position: 'absolute'
};

export const makeSelectColumn = (onSelect, selectAll, deselectAll, allSelected, someSelected) => ({
    accessor: 'selected',
    disableSortBy: true,
    size: 60,
    Cell: ({ value, row }) => {
        // const onChange = row.original.onSelect;

        return (
            <input
                type="checkbox"
                className="form-check-input"
                onChange={() => onSelect(row.original.id)}
                checked={value || false}
                title={value ? 'Discard' : 'Select'}
                onClick={(e) => e.stopPropagation()}
            />
        );
    },
    Header: () => {
        const cRef = useRef();

        const canSelectAll = someSelected && !allSelected;

        useEffect(() => {
            cRef.current.indeterminate = canSelectAll;
        }, [cRef, canSelectAll]);
        return (
            <div className="btn-group pos" style={headerStyles} role="group">
                <button
                    style={{ outline: 0, border: 0 }}
                    type="button"
                    className="btn p-0 pe-1 dropdown-toggle text-gray-600"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <input
                        ref={cRef}
                        type="checkbox"
                        className="form-check-input me-2"
                        onChange={() =>
                            someSelected && !allSelected
                                ? selectAll()
                                : !allSelected
                                ? selectAll()
                                : deselectAll()
                        }
                        checked={canSelectAll || allSelected}
                        title={
                            someSelected && !allSelected
                                ? 'Select All'
                                : !allSelected
                                ? 'Select All'
                                : 'Discard All'
                        }
                    />
                </button>
                <ul className="dropdown-menu ">
                    <li role="button" className="dropdown-item" onClick={selectAll}>
                        Select All
                    </li>
                    <li role="button" className="dropdown-item" onClick={deselectAll}>
                        Discard All
                    </li>
                </ul>
            </div>
        );
    }
});

export const useSelectList = (list) => {
    const [selected, setSelected] = useState({});

    useEffect(() => {
        const ids = list || [];

        setSelected((prevSelected) => {
            const newSelected = {};

            for (let id in ids) {
                newSelected[id] = prevSelected[id];
            }

            return newSelected;
        });
    }, [list]);

    const isSelected = useCallback(
        (id) => {
            return selected[id];
        },
        [selected]
    );

    const onSelect = useCallback(
        (id) => {
            setSelected((prevSelected) => ({ ...prevSelected, [id]: !prevSelected[id] }));
        },
        [setSelected]
    );

    const selectAll = useCallback(() => {
        const allSelected = {};

        (list || []).forEach((id) => {
            allSelected[id] = true;
        });

        setSelected(allSelected);
    }, [list, setSelected]);

    const deselectAll = useCallback(() => {
        setSelected({});
    }, []);

    const allSelected = Object.keys(selected).filter((id) => selected[id]).length === (list || []).length;

    const getSelected = useCallback(() => {
        return Object.keys(selected).filter((id) => selected[id]);
    }, [selected]);

    return { onSelect, isSelected, selectAll, deselectAll, allSelected, getSelected };
};

export const SelectionMenu = ({ isDisabled, getSelected, children, total }) => {
    const numSelected = getSelected().length;

    return (
        <div className="d-flex align-items-center">
            <div className="dropdown">
                <button
                    className="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    disabled={isDisabled}
                >
                    Actions
                </button>
                <ul className="dropdown-menu">{children}</ul>
            </div>
            {numSelected ? (
                <div
                    className=" text-muted text-nowrap opacity-3 ms-2"
                    style={{ bottom: '-22px', left: '5px' }}
                >
                    Selected {numSelected} of {total} entries
                </div>
            ) : null}
        </div>
    );
};

export const SelectionMenuConfigOptions = ({
    onCopy,
    onDelete,
    onUpdateStatus,
    copyPermissions,
    deletePermissions,
    updatePermissions,
    hideStatus
}) => {
    return (
        <>
            <IAMButton
                permissions={copyPermissions}
                layout="inline-start"
                size="md"
                wrapperClassName={'d-flex align-items-center'}
            >
                <li className="dropdown-item" onClick={onCopy} role="button">
                    Duplicate selected
                </li>
            </IAMButton>
            <IAMButton
                permissions={deletePermissions}
                layout="inline-start"
                size="md"
                wrapperClassName={'d-flex align-items-center'}
            >
                <li className="dropdown-item" onClick={onDelete} role="button">
                    Delete selected
                </li>
            </IAMButton>

            {!hideStatus && (
                <>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li className="dropend dropdown-hover" role="button">
                        <IAMButton
                            permissions={updatePermissions}
                            layout="inline-start"
                            size="md"
                            wrapperClassName={'d-flex align-items-center'}
                        >
                            <span className="dropdown-item dropdown-toggle pe-none">
                                Set status for selected{' '}
                            </span>
                        </IAMButton>
                        <IAMComponentWrapper permissions={updatePermissions} fallback={null}>
                            <ul className="dropdown-menu" data-popper-placement="right-start">
                                {Object.keys(Status).map((statusKey) => (
                                    <li
                                        className="dropdown-item"
                                        key={statusKey}
                                        role="button"
                                        onClick={() => onUpdateStatus(statusKey)}
                                    >
                                        {Status[statusKey]}
                                    </li>
                                ))}
                            </ul>
                        </IAMComponentWrapper>
                    </li>
                </>
            )}
        </>
    );
};

export const makeUpdateSelectedStatusesAction = (
    selected,
    updateFunc,
    gameId,
    deselectAll,
    configs,
    setConfigs
) => {
    return (_status) => {
        utils.confirm('Are you sure want to update statuses for selected configs?', async (yes) => {
            if (yes) {
                utils.popup('Updating statuses...');

                const selectedIds = selected;

                for (let remoteConfigId of selectedIds) {
                    const remoteConfig = configs.find((e) => e.id === remoteConfigId);

                    remoteConfig.status = _status;
                    try {
                        await updateFunc(gameId, remoteConfig);
                    } catch (err) {
                        utils.hintError(err);
                    }
                }
                deselectAll();

                setConfigs([...configs]);
                utils.popup('hide');
            }
        });
    };
};
export const makeCopySelectedAction = (selected, copyFunc, gameId, deselectAll, configs, setConfigs) => {
    return () => {
        utils.confirm('Are you sure want to make a copy of selected configs?', async (yes) => {
            if (yes) {
                utils.popup('Cloning...');

                const selectedIds = selected;

                const addedConfigs = [];

                for (let remoteConfigId of selectedIds) {
                    const remoteConfig = configs.find((e) => e.id === remoteConfigId);

                    try {
                        const result = await copyFunc(gameId, {
                            id: remoteConfigId,
                            name: 'Copy of ' + remoteConfig.name
                        });
                        addedConfigs.push(result);
                    } catch (err) {
                        utils.hintError(err);
                    }
                }
                deselectAll();

                setConfigs([...configs, ...addedConfigs]);

                utils.popup('hide');
            }
        });
    };
};
export const makeDeleteAction = (selected, deleteFunc, gameId, deselectAll, configs, setConfigs) => {
    return () => {
        utils.confirm('Are you sure want to remove selected configs?', async (yes) => {
            if (yes) {
                utils.popup('removing');

                const selectedIds = selected;

                const removedIds = [];

                for (let remoteConfigId of selectedIds) {
                    const remoteConfig = configs.find((e) => e.id === remoteConfigId);
                    try {
                        await deleteFunc(gameId, remoteConfigId, remoteConfig.name);
                        removedIds.push(remoteConfigId);
                    } catch (err) {
                        utils.hintError(err);
                    }
                }
                deselectAll();

                setConfigs(configs.filter((e) => removedIds.indexOf(e.id) === -1));
                utils.popup('hide');
            }
        });
    };
};

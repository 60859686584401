/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */
import { useCallback, useState } from 'react';
// import { FileCreation } from '../../storage/FileCreation';
import { ContentViewTable } from './ContentViewTable';
import { ContentView } from './ContentView';
import { utils } from '@gpg-web/utils';
import Skeleton from 'react-loading-skeleton';
import { StatusLabel, useStoredState, useEnvironmental } from 'components';
import { TriggersModal } from './TriggersModal';

export const ContentSetup = (props) => {
    const {
        data,
        setData,
        readOnly
        //  id
    } = props;
    const [setupTriggers, setSetupTriggers] = useState(null);
    const [view, setView] = useStoredState('config_experiment_content_view_id', 'content');

    const {
        environments,
        gameVersions,
        selectedEnvironment,
        selectedGameVersion,
        selectedPath,
        setSelectedEnvironment,
        setSelectedGameVersion
    } = useEnvironmental();

    // const component = useComponent();

    const versions = data.versions;

    // function handleFileChange(e) {
    // content.path = e.path;
    // content.versions = {};
    // setData({ ...data, content: content });
    // }

    // function cleanContent() {
    // utils.confirm(
    //     'Are you sure you want to clean up the file path along with the configuration?',
    //     function (yes) {
    //         if (yes) {
    //             delete content.path;
    //             content.versions = {};
    //             setData({ ...data, content: content });
    //         }
    //     }
    // );
    // }

    const handleChangeVersion = (path, options, status) => {
        if (path) {
            versions[path] = versions[path] || { values: {} };
            if (options !== null) {
                const { variant, config, value } = options;
                // console.log(value);

                versions[path]['values'][variant] = versions[path]['values'][variant] || {};
                versions[path]['values'][variant][config] = versions[path]['values'][variant][config] || {};

                // if (fileVersion) versions[path]['values'][variant][config].version = fileVersion;
                Object.assign(versions[path]['values'][variant][config], value);

                const oldStatus = versions[path].status;
                if (oldStatus !== 'disabled' && oldStatus !== 'test') {
                    utils.hintOk('Status changed to "Test" for ' + path);
                    versions[path].status = 'test';
                }
            }
            if (status) versions[path].status = status;
        }
        setData({ ...data, versions: { ...versions } });
    };

    const onTableExpand = useCallback(
        (environment, gameVersion) => {
            setView('content');
            setSelectedEnvironment(environment);
            setSelectedGameVersion(gameVersion);
        },
        [setSelectedEnvironment, setSelectedGameVersion, setView]
    );

    const dataReady = environments && gameVersions;
    const isLoading = !dataReady;

    return (
        <div className={utils.className('card position-static', props.className)}>
            <div className="card-header py-3">
                <h6 className="m-0 fw-bold text-primary">Content</h6>
            </div>

            <div className="card-body">
                {dataReady && (
                    <>
                        <div className="mb-3 d-flex border-bottom pb-2">
                            {view === 'content' && (
                                <div className="d-flex align-items-center position-relative">
                                    <select
                                        value={selectedEnvironment}
                                        className="form-select form-select-sm w-150px"
                                        onChange={(e) => setSelectedEnvironment(e.target.value)}
                                    >
                                        {environments.map((e) => (
                                            <option key={e.id} value={e.id}>
                                                {e.id}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        value={selectedGameVersion}
                                        className="form-select form-select-sm ms-3 w-150px"
                                        onChange={(e) => setSelectedGameVersion(e.target.value)}
                                    >
                                        {gameVersions.map((e) => (
                                            <option key={e.id} value={e.id}>
                                                {e.id}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="ms-3">
                                        <StatusLabel value={versions[selectedPath]?.status || 'draft'} />
                                    </div>
                                </div>
                            )}
                            <div className="ms-auto">
                                <button
                                    title="File content preview"
                                    onClick={(e) => setView('content')}
                                    className={
                                        'btn btn-sm mx-1 ' +
                                        (view === 'content' ? 'text-dark' : 'text-gray-500')
                                    }
                                >
                                    <i className="fas fa-folder-open" />
                                </button>
                                <button
                                    title="Table content preview"
                                    onClick={(e) => setView('table')}
                                    className={
                                        'btn btn-sm mx-1 ' +
                                        (view === 'table' ? 'text-dark' : 'text-gray-500')
                                    }
                                >
                                    <i className="fas fa-table" />
                                </button>
                            </div>
                        </div>

                        <TriggersModal
                            show={setupTriggers}
                            onHide={setSetupTriggers}
                            options={setupTriggers}
                            onChange={handleChangeVersion}
                        />

                        {view === 'content' && (
                            <ContentView
                                selectedPath={selectedPath}
                                readOnly={readOnly}
                                gameVersion={selectedGameVersion}
                                environment={selectedEnvironment}
                                variants={data.variants}
                                gameVersion={selectedGameVersion}
                                environment={selectedEnvironment}
                                configs={data.configs}
                                versions={versions}
                                onChange={handleChangeVersion}
                                onSetupTriggers={setSetupTriggers}
                            />
                        )}

                        {view === 'table' && (
                            <ContentViewTable
                                readOnly={readOnly}
                                environments={environments}
                                gameVersions={gameVersions}
                                variants={data.variants}
                                configs={data.configs}
                                versions={versions}
                                onChange={handleChangeVersion}
                                onExpand={onTableExpand}
                                onSetupTriggers={setSetupTriggers}
                                data={data}
                                setData={setData}
                            />
                        )}
                    </>
                )}

                {isLoading && (
                    <>
                        <Skeleton className="my-2" width="60%" height={30} />
                        <Skeleton className="my-2" width="30%" height={60} />
                        <Skeleton className="my-2" width="100%" height={50} count={2} />
                        <Skeleton className="my-2" width="90%" height={90} />
                    </>
                )}
            </div>
        </div>
    );
};

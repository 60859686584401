/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getStorageAsset,
    removeFolder,
    removeFile,
    copyStorageObject,
    downloadStorageFile
} from 'services/storage';
import { IAMButton, useGame, SelectionMenu, useSelectList } from 'components';
import { utils } from '@gpg-web/utils';
import Migration from './Migration';
import { CollectionModel } from './CollectionModel';
import { BrowseStorageModal } from './BrowseStorage';
import { getFilePathsUsage } from 'services/usage';
import { useRemoveMultipleFiles } from './RemoveMultipleFiles';
import { DropZone } from '@gpg-web/react';
// import PromoteLocalEnvironmentsModal from './PromoteLocalEnvironmentsModal';
// import SetupModal from '../automation/scripts/SetupModal';

export const Collection = (props) => {
    const { path, acceptNewFile, assets, setAssets, migrating, setMigrating } = props;

    const [browsingStorage, setBrowsingStorage] = useState(false);
    // const [selected, setSelected] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    // const [promotingLocalEnvironments, setPromotingLocalEnvironments] = useState(null);
    // const [newFileConfigs, setNewFileConfigs] = useState(null);
    const game = useGame();
    const history = useNavigate();

    const gameId = game.id;

    // let params = useParams();
    // let path = params['*'];

    // let isCollection = path.endsWith('/') || path === '';

    // let params = useParams();
    // let path = params['*'];
    // if (path) path = path + '/';
    // path = path || '';

    const [RemoveModal, showRemoveModal] = useRemoveMultipleFiles();

    const [selectableIds, setSelectableIds] = useState([]);

    const onFilterChange = useCallback((rows) => {
        setSelectableIds(rows.map((row) => row.original.id));
    }, []);

    const { onSelect, isSelected, selectAll, deselectAll, allSelected, getSelected } =
        useSelectList(selectableIds);

    const selected = getSelected();

    // const openObject = (e) => {
    //     if (e.mime === 'Folder') {
    //         history('/game/' + gameId + '/storage/collection/' + e.path + e.name);
    //     } else {
    //         history('/game/' + gameId + '/storage/file/' + e.path + e.id);
    //     }
    // };

    const deleteSelectedObjects = async () => {
        if (selected.length === 0) return;

        try {
            // const selectedIds = selected.map((obj) => obj.id);

            const selectedObjs = assets.filter((obj) => selected.indexOf(obj.id) > -1);

            showRemoveModal(
                getFilePathsUsage(
                    gameId,
                    selectedObjs.map(
                        (obj) => (obj.mime === 'Folder' ? 'folder:' : 'file:') + obj.path + obj.name
                    )
                ),
                selectedObjs,
                async () => {
                    utils.popup('removing');
                    for (let obj of selectedObjs) {
                        console.log(obj);
                        const fn = obj.mime === 'Folder' ? removeFolder : removeFile;

                        await fn(gameId, obj.id, obj.path + obj.name);
                        utils.remove(assets, (e) => e.id === obj.id);
                    }
                    deselectAll();
                    setAssets(assets.slice());
                    utils.popup('hide');
                }
            );
        } catch (err) {
            utils.hintError(err);
        }
    };

    const downloadSelectedObjects = async () => {
        if (selected.length === 0) return;

        try {
            utils.popup('downloading');

            for (let selectedId of selected) {
                let file = assets.find((e) => e.id === selectedId);
                if (file.isFolder) continue;

                downloadStorageFile(gameId, file.path + file.name, file.lastVersionId);
            }

            utils.popup('hide');
        } catch (err) {
            utils.hintError(err);
        }
    };

    const copyToSelectedObjects = async (path) => {
        if (selected.length === 0) return;

        try {
            utils.popup('Copying ...');

            for (let selectedId of selected) {
                try {
                    let params = { to: path.path };

                    let file = assets.find((e) => e.id === selectedId);

                    if (file.isFolder) params.path = file.id;
                    else params.fileId = file.id;

                    const result = await copyStorageObject(gameId, params);
                    utils.hintOk(result.path + result.name + ' copied!');
                } catch (err) {
                    utils.popup('Copying ...');
                    utils.hintError(err);
                }
            }

            utils.popup('hide');
        } catch (err) {
            utils.hintError(err);
        }
    };

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if ((e.type === 'dragenter' || e.type === 'dragover') && e.dataTransfer.types.includes('Files')) {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            acceptNewFile(e.dataTransfer.files);
        }
    };

    return (
        <>
            <div className="row align-items-center">
                <div className="d-flex">
                    <div className="me-auto mb-3">
                        <SelectionMenu
                            isDisabled={!assets}
                            selectAll={selectAll}
                            deselectAll={deselectAll}
                            allSelected={allSelected}
                            getSelected={getSelected}
                            total={assets ? assets.length : 0}
                        >
                            <button onClick={downloadSelectedObjects} className="btn dropdown-item">
                                Download Files
                            </button>
                            <div className="btn dropdown-item">
                                <IAMButton left={100} top={5} permissions={['component.storage.upload']}>
                                    <div onClick={() => setBrowsingStorage(true)}>Copy Files To</div>
                                </IAMButton>
                            </div>
                            <div className="btn dropdown-item">
                                <IAMButton left={100} top={5} permissions={['component.storage.delete']}>
                                    <div onClick={deleteSelectedObjects}>Delete Selected</div>
                                </IAMButton>
                            </div>
                        </SelectionMenu>
                    </div>
                </div>
            </div>

            <div>
                {assets && assets.length === 0 && <DropZone onAccept={acceptNewFile} />}

                <div
                    onDragEnter={handleDrag}
                    className={assets && assets.length === 0 ? 'd-none' : 'position-relative'}
                >
                    {dragActive && (
                        <div
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                            className="position-absolute w-100 h-100 d-flex align-items-center opacity-3 bg-white backdrop-top justify-content-center fw-bold h3"
                        >
                            <i className="fas fa-file-download me-3" /> Drop the files here ...
                        </div>
                    )}
                    <CollectionModel
                        onClick={(e) => history(e.path + e.name + (e.mime === 'Folder' ? '/' : ''))}
                        gameId={gameId}
                        path={path}
                        storage={assets}
                        setStorage={setAssets}
                        selected={selected}
                        deselectAll={deselectAll}
                        allSelected={allSelected}
                        selectAll={selectAll}
                        onSelect={onSelect}
                        isSelected={isSelected}
                        onFilterChange={onFilterChange}
                        // setSelected={setSelected}
                    />
                </div>
            </div>
            {RemoveModal}
            <BrowseStorageModal
                isFolderSelect
                foldersOnly
                show={browsingStorage}
                onHide={(e) => setBrowsingStorage(false)}
                onSelect={copyToSelectedObjects}
                gameId={gameId}
            />

            <Migration
                show={migrating}
                onFinish={() => {
                    setAssets(null);
                    getStorageAsset(gameId, path)
                        .then((asset) => setAssets(asset.assets))
                        .catch(utils.hintError);
                }}
                dstPath={path}
                onHide={() => setMigrating(false)}
                game={game}
            />
        </>
    );
};

import { InputStorageUsage } from '../components/InputStorage';
import { loadEnvironments, loadGameVersions } from '../loaders';
import { EnvironmentView, GameVersionView, StatusView, StorageView } from '../optionViews';

export const configSyncStorageFile = {
    description: (
        <>
            Synchronize file('s) for every Game Balance config to latest version
            <br />
            Select specific environments and game version to promote.
            <br />
            Optionally can change status of every changed config.
        </>
    ),
    color: '#6173dd',
    icon: 'fas fa-folder-open',
    options: [
        {
            id: 'storagePath',
            name: 'Storage path (file/folder) to promote',
            type: 'storage',
            requiredFileVersion: false,
            isFolderSelect: true,
            foldersOnly: false,
            value: null,
            addons: [InputStorageUsage],
            view: StorageView
        },
        {
            id: 'environments',
            name: 'Environments',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: [],
            asyncLoad: async (gameId) => {
                const result = await loadEnvironments(gameId);
                return [{ value: 'all', label: 'All Environments' }, ...result];
            },
            view: EnvironmentView
        },
        {
            id: 'versions',
            name: 'Game Versions',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: 'all',
            asyncLoad: async (gameId) => {
                const result = await loadGameVersions(gameId);
                return [{ value: 'all', label: 'All Versions' }, ...result];
            },
            view: GameVersionView
        },
        {
            id: 'setStatus',
            name: 'Set Status for every changed config',
            value: 'test',
            type: 'select_button',
            options: [
                { value: 'draft', label: 'Draft' },
                { value: 'test', label: 'Test' },
                { value: 'live', label: 'Live' }
            ],
            view: StatusView
        }
    ],
    validate: function (values) {
        const { storagePath, environments, versions } = values;

        if (!storagePath) throw new Error('Storage path should be specified');
        if (!environments || environments.length === 0)
            throw new Error('At least one Environment should be selected');
        if (!versions || versions.length === 0)
            throw new Error('At least one Game Version should be selected');

        return true;
    },
    name: 'Promote Environmental File'
};

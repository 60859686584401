/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getLogs } from 'services/logs';
import { Spinner } from '@gpg-web/react';
import { utils, date } from '@gpg-web/utils';
import { ActionLabel, useGame } from 'components';

export const ChangeHistory = (props) => {
    const [logPages, setLogPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [shown, setShown] = useState(false);
    const dropdownRef = useRef();
    const loadLimit = 10;

    const game = useGame();
    const gameId = game.id;
    const { pathname } = useLocation();
    const searchUrl = pathname.replace('/game/' + gameId, '');

    useEffect(() => {
        if (!shown) return;
        if (!gameId) return;

        setLoading(true);
        getLogs(gameId, {
            page: page,
            limit: loadLimit,
            url: searchUrl
        })
            .then((result) => {
                setLogPages((prevLogs) => {
                    const newLogs = prevLogs.slice();
                    if (result.length) newLogs[page] = result;
                    return newLogs;
                });

                if (result.length < loadLimit) setHasMore(false);
                setLoading(false);
            })
            .catch(utils.hintError);
    }, [gameId, searchUrl, page, shown]);

    useEffect(() => {
        if (!dropdownRef.current) return;

        function resetLogs() {
            setShown(false);
            setPage(0);
            setLogPages([]);
        }

        const el = dropdownRef.current;
        el.addEventListener('hidden.bs.dropdown', resetLogs);

        return () => {
            el.removeEventListener('hidden.bs.dropdown', resetLogs);
        };
    }, []);

    return (
        <div className="dropdown no-arrow" ref={dropdownRef}>
            <button
                type="button"
                title="Change history"
                onClick={() => setShown(!shown)}
                className="btn btn-light rounded-circle dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
            >
                <i className="fas fa-history" />
            </button>
            <div
                className="dropdown-menu p-4 pe-3 dropdown-menu-end shadow"
                style={{ width: '600px', maxHeight: '400px', overflowY: 'auto' }}
            >
                <h6 className="mb-3">Change history</h6>
                <div>
                    {logPages.map((log, pageIndex) =>
                        log.map((e, index) => <LogItem key={pageIndex + e.createdAt + index} data={e} />)
                    )}

                    {!loading && (logPages.length === 0 || logPages[0].length === 0) && (
                        <div className="text-muted fst-italic">No logs found for the last 180 days</div>
                    )}

                    {!loading && hasMore && (
                        <button onClick={() => setPage(page + 1)} className="btn btn-sm">
                            Load older
                        </button>
                    )}
                    {loading && (
                        <div className="fw-light">
                            <Spinner size={12} />
                            <span className="ms-2">Loading</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const LogItem = ({ data }) => {
    return (
        <div className="mb-3 fw-light">
            <ActionLabel value={data.action} /> by <b>{data.user}</b> at <b>{date(data.createdAt)}</b>
        </div>
    );
};

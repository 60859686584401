/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { LimitedLogs } from './Logs';
import { IAMComponentWrapper, useGame } from 'components';

export const GamePage = (props) => {
    const game = useGame();

    return (
        <>
            <div className="container-lg">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="card">
                            <div className="card-header fw-bold">Economy</div>
                            <div className="card-body">
                                <div className="card-text mb-2">
                                    This component contains game shop and it components configuration
                                </div>

                                <div>
                                    <Link to={'config/stores'} className="d-block btn-sm btn-link my-1">
                                        <i className="fa fa-cogs me-2" /> Store
                                    </Link>
                                    <Link to={'config/products'} className="d-block btn-sm btn-link my-1">
                                        <i className="fa fa-cogs me-2" />
                                        Products
                                    </Link>
                                    <Link to={'config/entities'} className="d-block btn-sm btn-link my-1">
                                        <i className="fa fa-cogs me-2" />
                                        Entities
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-header fw-bold">Remote Config</div>
                            <div className="card-body">
                                <div className="card-text mb-2">
                                    This component contains game configs configuration which are dynamic
                                    configs.
                                </div>

                                <div className="d-flex flex-wrap">
                                    <Link
                                        to={'config/experiments'}
                                        className="d-block btn-sm btn-link my-1 me-3"
                                    >
                                        <i className="fa fa-cogs me-2" /> A/B Testing
                                    </Link>
                                    <Link
                                        to={'config/game-balances'}
                                        className="d-block btn-sm btn-link my-1 me-3"
                                    >
                                        <i className="fa fa-cogs me-2" /> Game Balance
                                    </Link>
                                    <Link
                                        to={'config/time-limited-events'}
                                        className="d-block btn-sm btn-link my-1 me-3"
                                    >
                                        <i className="fa fa-cogs me-2" /> TLE
                                    </Link>
                                    <Link
                                        to={'config/time-limited-offers'}
                                        className="d-block btn-sm btn-link my-1 me-3"
                                    >
                                        <i className="fa fa-cogs me-2" /> TLO
                                    </Link>
                                    <Link
                                        to={'config/translations'}
                                        className="d-block btn-sm btn-link my-1 me-3"
                                    >
                                        <i className="fa fa-cogs me-2" /> Translations
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-header fw-bold">Storage</div>
                            <div className="card-body">
                                <div className="card-text mb-2">
                                    This component contains game storage configuration.
                                </div>

                                <div>
                                    <Link to={'storage'} className="d-block btn-sm btn-link my-1">
                                        <i className="fa fa-cogs me-2" /> Storage
                                    </Link>
                                    <Link
                                        to={'storage/PlayerSaves-CheatPanel/'}
                                        className="d-block btn-sm btn-link my-1"
                                    >
                                        <i className="fa fa-cogs me-2" /> Cheat Panel Player Saves
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-header fw-bold">Players</div>
                            <div className="card-body">
                                <div className="card-text mb-2">
                                    This component contains players configuration
                                </div>

                                <div>
                                    <Link to={'players'} className="d-block btn-sm btn-link my-1">
                                        <i className="fa fa-cogs me-2" /> Players
                                    </Link>
                                    <Link to={'save-type'} className="d-block btn-sm btn-link my-1">
                                        <i className="fa fa-cogs me-2" /> Save Types
                                    </Link>

                                    <Link to={'config/popups'} className="d-block btn-sm btn-link my-1">
                                        <i className="fa fa-cogs me-2" /> Popup
                                    </Link>
                                    <Link
                                        to={'config/daily-rewards'}
                                        className="d-block btn-sm btn-link my-1"
                                    >
                                        <i className="fa fa-cogs me-2" /> Daily Reward
                                    </Link>
                                    <Link to={'config/piggy-banks'} className="d-block btn-sm btn-link my-1">
                                        <i className="fa fa-cogs me-2" /> Piggy Bank
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-header fw-bold">Settings</div>
                            <div className="card-body">
                                <div className="card-text mb-2">This component contains project settings</div>

                                <Link to={'settings/game'} className="btn-sm btn-link my-1 me-3">
                                    <i className="fa fa-cogs me-2" /> Game
                                </Link>

                                <Link to={'settings/stage'} className="btn-sm btn-link my-1 me-3">
                                    <i className="fa fa-cogs me-2" /> Stage
                                </Link>

                                <Link to={'settings/environments'} className="btn-sm btn-link my-1 me-3">
                                    <i className="fa fa-cogs me-2" /> Environments
                                </Link>

                                <Link to={'settings/versions'} className="btn-sm btn-link my-1 me-3">
                                    <i className="fa fa-cogs me-2" /> Versions
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <h5>Recent changes</h5>

                        <IAMComponentWrapper permissions={['resourcemanager.projects.get']}>
                            {!!game.id && <LimitedLogs limit={9} />}
                            <Link to={'logs'} className="btn btn-light ps-0 pt-0 text-secondary">
                                Show all...
                            </Link>
                        </IAMComponentWrapper>
                    </div>
                </div>
            </div>
        </>
    );
};

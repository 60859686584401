/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useMemo, useState, useEffect, useRef } from 'react';
import { utils } from '@gpg-web/utils';
import { Modal } from '@gpg-web/react';
import { getVersions } from 'services/settings';
import { Languages, Countries } from 'consts';
import { useGame, useModalBlocker } from 'components';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

function formatDateToIntput(date) {
    if (date) {
        date = new Date(date);

        const isoString = date.toISOString();

        return isoString.substring(0, ((isoString.indexOf('T') | 0) + 6) | 0);
    }

    return null;
}

export const CONDITION_ELEMENTS = [
    {
        id: 'os',
        name: 'Platform',
        isMulti: true,
        limit: 1,
        parse: function (value) {
            let values = utils.substr(value, "in \\['", "'\\]");

            if (values) values = values.split("', '");
            else values = [];

            return {
                value: values,
                operator: 'in'
            };
        },
        build: function (operator, values) {
            let value = '[]';

            if (values.length > 0) value = "['" + values.join("', '") + "']";

            return this.id + ' in ' + value;
        },
        operators: [
            { value: 'in', label: 'is in' },
            { value: 'notIn', label: 'not in' }
        ],
        options: [
            { value: 'ios', label: 'iOS' },
            { value: 'android', label: 'Android' },
            { value: 'web', label: 'Web' },
            { value: 'windows', label: 'Windows' },
            { value: 'macos', label: 'macOS' }
        ],
        placeholder: 'Select platform'
    },
    {
        id: 'version',
        name: 'App version',
        isMulti: true,
        limit: 1,
        loadOptions: function (gameId) {
            return getVersions(gameId).then((list) => {
                return list.map((opt) => ({ value: opt.id, label: opt.id }));
            });
        },
        operators: [
            { value: 'in', label: 'is in' },
            { value: 'notIn', label: 'not in' }
        ]
    },
    {
        id: 'build',
        name: 'Build number',
        type: 'number',
        options: [],
        isMulti: true,
        isCreatable: true,
        limit: 1,
        operators: [
            { value: 'in', label: 'is in' },
            { value: 'notIn', label: 'not in' }
        ]
    },
    {
        id: 'authTime',
        name: 'Auth Time',
        type: 'datetime-local',
        limit: 2,
        uniqueOperators: true,
        operators: [
            { value: '>', label: 'After' },
            { value: '<=', label: 'Before' }
        ]
    },
    {
        id: 'language',
        name: 'Languages',
        isMulti: true,
        limit: 1,
        operators: [
            { value: 'in', label: 'is in' },
            { value: 'notIn', label: 'not in' }
        ],
        options: Object.keys(Languages).map((e) => {
            return { value: e, label: Languages[e] };
        }),
        placeholder: 'Select languages'
    },
    {
        id: 'country',
        name: 'Country/Region',
        isMulti: true,
        limit: 1,
        operators: [
            { value: 'in', label: 'is in' },
            { value: 'notIn', label: 'not in' }
        ],
        options: Countries.map((e) => {
            return { value: e.code, label: e.name };
        }),
        placeholder: 'Select countries/regions'
    },
    {
        id: 'percent',
        name: 'User in random percentile',
        type: 'number',
        limit: 1,
        operators: [{ value: '<=', label: 'Between 0 and' }],
        placeholder: 'Example: 10.25'
    },
    {
        id: 'uid',
        name: 'Livefire user id',
        limit: 1,
        operators: [{ value: 'in', label: 'is in' }]
    }
];

const AudienceEditingModal = (props) => {
    const { conditions, editCondition, setEditCondition, template } = props;
    const game = useGame();

    const [show, setShow] = useState(false);
    const [nameError, setNameError] = useState(null);
    const [data, setData] = useState({
        tagColor: 'BLUE',
        name: '',
        expression: ''
    });
    const [validating, setValidating] = useState([]);

    const [firebaseValidating, setFirebaseValidating] = useState(false);
    const [validationError, setValidationError] = useState(null);
    const validationRequestId = useRef(0);

    function resetAll() {
        setNameError(null);
        setEditCondition(null);
        setShow(false);
        setValidating([]);
        setData({
            tagColor: 'BLUE',
            name: '',
            expression: ''
        });
    }

    const [updateInitalData, onBeforeClose] = useModalBlocker(data);

    useEffect(() => {
        if (editCondition) {
            setShow(true);
            setData(updateInitalData({ ...editCondition }));
        }
    }, [editCondition, updateInitalData]);

    const expressions = useMemo(() => {
        return data.expression.split(' && ');
    }, [data.expression]);

    function saveCondition() {
        props.onSave && props.onSave(data, editCondition);
        setShow(false);
    }

    function addNewCondition() {
        setShow(true);
    }

    function handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'name') {
            if (value === '') {
                setNameError('A condition name is required');
            } else if (editCondition && value === editCondition.name) {
            } else {
                const found = conditions.findIndex((e) => e.name === value);

                if (found > -1) {
                    setNameError('Condition names must be unique');
                } else {
                    setNameError(null);
                }
            }
        }

        setData({ ...data, [name]: value });
    }

    function changeExpression(value, index, isValid) {
        expressions.splice(index, 1, value);
        validating[index] = isValid;
        setValidating(validating.slice());

        setData({ ...data, expression: expressions.join(' && ') });
    }

    function addNewExpression() {
        if (validating.length < expressions.length) {
            let delta = expressions.length - validating.length;
            for (let i = 0; i < delta; i++) {
                validating.push(true);
            }
        }

        validating.push(false);
        setValidating(validating.slice());

        setData({ ...data, expression: data.expression + ' && ' });
    }

    function removeExpression(index) {
        validating.splice(index, 1);
        setValidating(validating.slice());

        if (expressions.length === 1) {
            expressions[0] = '';
        } else {
            expressions.splice(index, 1);
        }

        setData({ ...data, expression: expressions.join(' && ') });
    }

    const canSave =
        !nameError &&
        !!data.name &&
        !!data.expression &&
        !expressions.some((e) => e === '') &&
        !validating.some((e) => !e);

    const gameId = game.id;

    const validateConditions = useMemo(
        () =>
            utils.debounce((gameId, template, requestId) => {
                setValidationError(null);
                // validateTemplate(gameId, template)
                //     .then(() => {
                //         if (validationRequestId.current === requestId) setValidationError(null);
                //     })
                //     .catch((err) => {
                //         if (validationRequestId.current === requestId) setValidationError(err);
                //     })
                //     .finally(() => {
                //         if (validationRequestId.current === requestId) setFirebaseValidating(false);
                //     });
            }, 500),
        []
    );

    useEffect(() => {
        setValidationError(null);
        setFirebaseValidating(true);

        if (canSave && gameId) {
            const validationTemplate = { ...template, parameters: {}, parameterGroups: {} };

            validationTemplate.conditions = [data];

            let requestId = ++validationRequestId.current;

            validateConditions(gameId, validationTemplate, requestId);
        }
    }, [canSave, data, gameId, template, validateConditions]);

    return (
        <div>
            <button
                onClick={addNewCondition}
                className="btn btn-sm btn-outline-primary rounded-pill shadow-sm"
            >
                <i className="fa fa-sm me-1 fa-plus-circle" /> Add New
            </button>

            <Modal
                size="xl"
                title={editCondition ? 'Edit condition' : 'Define a new condition'}
                show={show}
                onHide={resetAll}
                onBeforeClose={onBeforeClose}
            >
                <div className="modal-body audience-editing">
                    <p className="text-muted small">
                        Any changes made to this condition will apply to all parameters using this condition.
                    </p>

                    <div className="row mt-4">
                        <div className="col-md-5 col-sm-8">
                            <div className="mb-2">
                                <label className="mb-1" htmlFor="condition-settings-name">
                                    Name
                                </label>
                                <input
                                    id="condition-settings-name"
                                    name="name"
                                    onChange={handleChange}
                                    type="text"
                                    value={data.name}
                                    className="form-control"
                                />
                            </div>
                            <div className={'text-danger small' + (nameError ? '' : ' invisible')}>
                                <i className="fa fa-exclamation-circle me-1" />
                                {nameError}
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-8">
                            <label className="mb-1">Color</label>
                            <select
                                name="tagColor"
                                value={data.tagColor}
                                onChange={handleChange}
                                className="form-select"
                            >
                                <option value="ORANGE">ORANGE</option>
                                <option value="DEEP_ORANGE">DEEP ORANGE</option>
                                <option value="PINK">PINK</option>
                                <option value="PURPLE">PURPLE</option>
                                <option value="INDIGO">INDIGO</option>
                                <option value="BLUE">BLUE</option>
                                <option value="CYAN">CYAN</option>
                                <option value="TEAL">TEAL</option>
                                <option value="GREEN">GREEN</option>
                                <option value="LIME">LIME</option>
                                <option value="BROWN">BROWN</option>
                            </select>
                        </div>
                    </div>

                    <div className="mt-3 expressions">
                        <div className="text-muted small mb-2">Applies if...</div>

                        {expressions.map((expression, index) => {
                            const elementId = CONDITION_ELEMENTS.find((e) => expression.startsWith(e.id))?.id;
                            return (
                                <Expression
                                    key={elementId + index}
                                    gameId={game.id}
                                    isAllValid={canSave}
                                    onRemove={removeExpression}
                                    onAdd={addNewExpression}
                                    canAdd={index === expressions.length - 1}
                                    onChange={changeExpression}
                                    index={index}
                                    expression={expression}
                                />
                            );
                        })}
                    </div>

                    {validationError && (
                        <div className="alert alert-danger mb-2 mt-4" role="alert">
                            <i className="fas fa-exclamation-circle me-2" />
                            {validationError}
                        </div>
                    )}
                </div>

                <div className="modal-footer">
                    {firebaseValidating && canSave && (
                        <div className="me-auto ms-2 text-muted">
                            <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Validating...
                        </div>
                    )}

                    <button className="btn btn-outline-secondary" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button
                        disabled={!canSave || firebaseValidating || validationError}
                        onClick={saveCondition}
                        className="btn btn-primary"
                    >
                        Save condition
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export { AudienceEditingModal };

const Expression = (props) => {
    const {
        conditions,
        expression,
        index,
        onChange,
        onAdd,
        onRemove,
        isAllValid,
        gameId,
        customElements,
        disabled,
        readOnly,
        canRemove = true,
        canUpdateLive
    } = props;

    const canAdd = index === conditions.length - 1;

    const elements = customElements || CONDITION_ELEMENTS;

    const elementId = expression.id;

    const element = elements.find((e) => e.id === elementId);

    let operators = null;
    let options;
    let loading = false;

    const [asyncOptions, setAsyncOptions] = useState(null);

    let value = expression.val || '';

    if (element) {
        operators = element.operators;

        if (elementId && element.uniqueOperators)
            operators = operators.map((op) => ({
                ...op,
                isDisabled: conditions.findIndex((c) => c.id === elementId && c.op === op.value) !== -1
            }));

        if (operators && operators.length === 1) expression.op = operators[0].value;

        if (typeof element.options === 'function') options = element.options();
        else if (element.options) options = [...element.options];
        else options = asyncOptions;

        if (Array.isArray(options) && element.isCreatable) {
            if (Array.isArray(value)) options.push(...value.map((v) => ({ value: v, label: v })));
            else if (!!value) options.push({ value: value, label: value });
        }

        if (element.loadOptions) {
            if (!options) options = [];

            if (!asyncOptions) {
                loading = true;

                element
                    .loadOptions(gameId)
                    .then((options) => {
                        setAsyncOptions(options);
                    })
                    .catch((err) => utils.hintError(err));
            }
        }
    }

    let operator = expression.op || '';

    function handleChangeElement(e) {
        expression.id = e.target.value;
        expression.op = '';
        expression.val = '';
        onChange(index, expression);
    }

    function handleChangeOperator(e) {
        expression.op = e.target.value;
        onChange(index, expression);
    }

    function handleChangeValue(e) {
        const target = e.target;
        let value;

        if (target.type === 'datetime-local' || target.type === 'datetime') {
            if (target.value) {
                const date = new Date(target.value);
                if (!isNaN(date) && date > 0) {
                    date.setTime(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
                    value = date.toISOString();
                }
            }
        } else {
            value = target.value;
        }

        expression.val = value;
        onChange(index, expression);
    }

    return (
        <div className="row m-0 my-2 rounded-1 border border-1">
            <div className="col-4 border-end p-0">
                <Select
                    options={elements.map((e) => {
                        let disabled = false;
                        if (e.limit) {
                            const condElements = conditions.filter((c) => c.id === e.id);

                            if (condElements.length >= e.limit) disabled = true;
                        }
                        return {
                            value: e.id,
                            label: e.name,
                            isDisabled: disabled
                        };
                    })}
                    value={element ? { value: element.id, label: element.name } : null}
                    name="condition-element-multiselect"
                    isClearable={false}
                    isSearchable={false}
                    isDisabled={disabled || readOnly}
                    placeholder="Select..."
                    onChange={(opt, b) => {
                        handleChangeElement({
                            target: {
                                name: 'devices',
                                value: opt.value
                            }
                        });
                    }}
                    className="react-select-sm react-select-no-border react-select-rounded-sm"
                    classNamePrefix="select"
                />
            </div>

            {operators && (
                <div className="col-3 border-end p-0">
                    <Select
                        options={operators}
                        value={operators.find((op) => op.value === operator)}
                        name="condition-operator-multiselect"
                        isClearable={false}
                        isSearchable={false}
                        isDisabled={operators.length === 1 || disabled || readOnly}
                        placeholder="Select operator..."
                        onChange={(opt, b) => {
                            handleChangeOperator({
                                target: {
                                    name: 'devices',
                                    value: opt.value
                                }
                            });
                        }}
                        className="react-select-sm react-select-no-border"
                        classNamePrefix="select"
                    />
                </div>
            )}

            <div className="col border-end p-0">
                {element && (
                    <>
                        {!!options && !element.isMulti && (
                            <select
                                value={options.find((e) => e.value === value) ? value : ''}
                                disabled={disabled || (readOnly && !canUpdateLive)}
                                onChange={handleChangeValue}
                                className="fluid form-select form-select-sm border-0"
                            >
                                <option value="" disabled hidden>
                                    {element.placeholder || 'Select'}
                                </option>
                                {options.map((e) => (
                                    <option key={e.value} value={e.value}>
                                        {e.label || e.value}
                                    </option>
                                ))}
                            </select>
                        )}

                        {!!options && !element.isCreatable && element.isMulti && (
                            <Select
                                value={options.filter((e) => value.indexOf(e.value) > -1)}
                                isMulti
                                isLoading={loading}
                                name="condition-exp-multiselect"
                                isClearable={false}
                                isDisabled={disabled || (readOnly && !canUpdateLive)}
                                placeholder={element.placeholder}
                                onChange={(arr, b) => {
                                    handleChangeValue({
                                        target: {
                                            name: 'devices',
                                            value: arr.map((e) => e.value)
                                        }
                                    });
                                }}
                                options={options}
                                className="react-select-sm react-select-no-border"
                                classNamePrefix="select"
                            />
                        )}

                        {!!options && element.isCreatable && element.isMulti && (
                            <CreatableSelect
                                value={options.filter((e) =>
                                    Array.isArray(value) ? value.indexOf(e.value) > -1 : e.value === value
                                )}
                                isMulti
                                isDisabled={disabled || (readOnly && !canUpdateLive)}
                                isLoading={loading}
                                name="condition-exp-multiselect"
                                isClearable={false}
                                placeholder={element.placeholder}
                                onCreateOption={(inputVal) =>
                                    handleChangeValue({
                                        target: {
                                            name: 'de;vices',
                                            value: [...value, inputVal]
                                        }
                                    })
                                }
                                onChange={(arr, b) => {
                                    handleChangeValue({
                                        target: {
                                            name: 'de;vices',
                                            value: arr.map((e) => e.value)
                                        }
                                    });
                                }}
                                options={options}
                                className="react-select-sm react-select-no-border"
                                classNamePrefix="select"
                            />
                        )}

                        {!options &&
                            (element.type === 'datetime-local' ? (
                                <div
                                    className={
                                        'd-flex align-items-center' +
                                        (disabled || readOnly ? '  bg-gray-200' : '')
                                    }
                                >
                                    <input
                                        disabled={disabled || (readOnly && !canUpdateLive)}
                                        type="datetime-local"
                                        onChange={handleChangeValue}
                                        className="fluid form-control form-control-sm border-0"
                                        placeholder={element.placeholder || 'Enter value...'}
                                        value={formatDateToIntput(value)}
                                    />
                                    <span className="small px-2 rounded-1">(GMT)</span>
                                </div>
                            ) : (
                                <input
                                    disabled={disabled || (readOnly && !canUpdateLive)}
                                    type={element.type || 'text'}
                                    onChange={handleChangeValue}
                                    className="fluid form-control form-control-sm border-0"
                                    placeholder={element.placeholder || 'Enter value...'}
                                    value={value}
                                />
                            ))}
                    </>
                )}
            </div>

            <button
                onClick={onAdd}
                disabled={readOnly || !canAdd || !isAllValid}
                className={utils.className(
                    'btn btn-sm btn-link fw-bold border-0 col-1 p-0',
                    canAdd ? '' : 'invisible',
                    readOnly ? 'd-none' : ''
                )}
                type="button"
            >
                and
            </button>
            <button
                disabled={disabled || readOnly}
                onClick={(e) => onRemove(index)}
                className={utils.className(
                    'btn btn-sm btn-outline-light text-gray-600 border-0 col-1 p-0',
                    readOnly ? 'd-none' : '',
                    canRemove ? '' : 'invisible'
                )}
                type="button"
            >
                <i className="fa fa-times-circle pe-none" />
            </button>
        </div>
    );
};

export { Expression };

function validateConditions(conditions) {
    let index = conditions.length;

    if (index === 0) return null;

    while (index--) {
        const cond = conditions[index];

        const id = cond.id;
        const val = cond.val;
        const element = CONDITION_ELEMENTS.find((e) => e.id === id);

        if (!element.operators.find((op) => op.value === cond.op)) {
            return 'Please select a valid operator for ' + element.name;
        }

        if (id === 'authTime') {
            const filtered = conditions.filter((e) => e.id === 'authTime');
            if (filtered.length > 1) {
                if (filtered.length > 2) return 'You can have only 2 Auth Time fields per condition';

                if (filtered[0].op === filtered[1].op)
                    return 'You cannot have 2 Auth Time fields with the same operator';

                let start = filtered[0].op === '>' ? filtered[0].val : filtered[1].val;
                let end = filtered[0].op === '<=' ? filtered[0].val : filtered[1].val;
                if (new Date(start) > new Date(end)) {
                    return 'After timestamp should be less than Before timestamp';
                }
            }
        } else if (id === 'percent') {
            if (val < 1 || val > 99) {
                return 'Percent value should be between 1 and 99';
            }
        }

        if (['os', 'language', 'country', 'percent', 'uid'].includes(id)) {
            const indexOf = conditions.findIndex((e) => e.id === id);

            if (indexOf > -1 && indexOf !== index) {
                return 'You can have only one ' + element.name + ' field per condition';
            }
        }
    }

    return null;
}

export { validateConditions };

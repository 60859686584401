import { utils } from '@gpg-web/utils';
import { useQuery } from '@tanstack/react-query';
import { ComponentsMap } from 'consts';
import Select from 'react-select';
import { getConfigs } from 'services/config';
import Skeleton from 'react-loading-skeleton';
import { EntityEditUsage, IAPEntityEditUsage, EntityListItem } from '../economy';
import DefaultEntityIcon from 'img/entity-default-icon-min.png';

const component = ComponentsMap['entity'];

export const EntitiesUsageOption = ({
    title,
    name,
    value = [],
    onChange,
    fetchPrices,
    readOnly,
    gameId,
    className,
    placeholder,
    includeIAP,
    filter,
    error,
    time = true,
    max = Infinity
}) => {
    // const [, setEntitys] = useState(null);

    // const queryClient = useQueryClient();
    let {
        data: entities,
        isFetching,
        error: fetchError
    } = useQuery({
        queryKey: [component.id, gameId],
        queryFn: () => getConfigs(gameId, component.url),
        enabled: !!gameId
    });

    const updateEntityValue = (entityId, entityName, entityValue) => {
        const entity = value.find((e) => e.id === entityId);
        if (entity) {
            entity[entityName] = entityValue;
            onChange(name, value);
        }
    };

    const removeEntity = (entityId) => {
        utils.remove(value, (e) => e.id === entityId);
        onChange(name, value);
    };

    const options = [];

    if (includeIAP) {
        options.push({
            label: (
                <>
                    <i className="fab fa-app-store-ios fa-lg me-3 text-dark-emphasis" />
                    Real price <span className="fw-light">| Apple & Google IAPs</span>
                </>
            ),
            value: '#iap'
        });
    }

    if (entities) {
        entities.forEach((e) => {
            if (value.findIndex((c) => c.id === e.id) > -1) return;

            if (filter) {
                for (let key in filter) {
                    if (e[key] !== filter[key]) return;
                }
            }

            options.push({
                label: <EntityListItem gameId={gameId} entity={e} />,
                value: e.id,
                isConsumable: e.isConsumable
            });
        });
    }

    return (
        <div className={utils.className('card mb-2', className)}>
            <div className="card-header">
                <h6 className="m-0 fw-bold small">{title || 'Entities'}</h6>
            </div>

            <div className="card-body">
                {fetchError && (
                    <div className="alert alert-danger">
                        <i className="fas fa-exclamation-circle fa-sm me-2" />
                        {fetchError}
                    </div>
                )}
                <div>
                    {value.map((e) => {
                        if (e.type === 'iap') {
                            return (
                                <IAPEntityEditUsage
                                    fetchPrices={fetchPrices}
                                    onRemove={removeEntity}
                                    onChange={(entityName, entityValue) => {
                                        e[entityName] = entityValue;
                                        onChange(name, value);
                                    }}
                                    value={e}
                                    className="mb-3"
                                    gameId={gameId}
                                    readOnly={readOnly}
                                />
                            );
                        }

                        if (isFetching || !entities)
                            return (
                                <div key={e.id} className="mb-3">
                                    <Skeleton height="27px" />
                                </div>
                            );

                        const entity = entities.find((c) => c.id === e.id) || {
                            id: e.id,
                            name: '',
                            _error: true
                        };

                        return (
                            <div key={e.id} className="mb-3">
                                <EntityEditUsage
                                    onRemove={removeEntity}
                                    onChange={updateEntityValue}
                                    entity={entity}
                                    value={e}
                                    time={time}
                                    gameId={gameId}
                                    readOnly={readOnly}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-7 col-sm-12">
                        {!isFetching && entities && options.length > 0 && value.length < max && !readOnly && (
                            <Select
                                value={null}
                                name="entityId"
                                onChange={(e) => {
                                    if (e.value === '#iap') {
                                        value.push({ type: 'iap', google: '', apple: '' });
                                    } else {
                                        value.push({ id: e.value, isConsumable: e.isConsumable });
                                    }
                                    onChange(name, value.slice());
                                }}
                                options={options}
                                placeholder={placeholder || 'Select entity'}
                                className="react-select"
                                classNamePrefix="select"
                            />
                        )}
                        {isFetching && (
                            <div>
                                <Skeleton height="34px" />
                            </div>
                        )}
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-12">
                        {error && (
                            <div className="alert alert-danger mb-0 py-1 small mt-sm-3 mt-md-0 mt-lg-0">
                                <i className="fas fa-exclamation-circle fa-sm me-2" />
                                {error}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

import React from 'react';

const AlertModal = (props) => {
    return (
        <>
            <div
                className="modal fade"
                id="alertModal"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="alertModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title text-dark" id="alertModalLabel">
                                Confirm Action
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                                &times;
                            </button>
                        </div>

                        <div className="modal-footer">
                            <button
                                id="alertModalOkButton"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                type="button"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AlertModal;

import { FileView } from '../../storage/FileView';
import { StatusSetup } from './Status';

export const ContentView = (props) => {
    const { content, onChange, readOnly, selectedPath } = props;

    const selectedFile = content.path;
    const selectedVersions = content.versions;

    // const handleChangeVersion = (e) => {
    //     onChange(editingVersion, e.version);
    // };

    // const promoteLocalEnv = async (from, to) => {
    //     const yes = await utils.confirm(
    //         'Are you sure you want to promote ' + selectedFile + ' from ' + from.name + ' to ' + to.name + '?'
    //     );
    //     if (!yes) return;
    //     Object.keys(selectedVersions).forEach((key) => {
    //         if (key.startsWith(from.path)) {
    //             let path = key.replace(from.path, to.path);
    //             selectedVersions[path] = utils.clone(selectedVersions[key]);
    //         }
    //     });

    //     onChange();
    // };

    const onFileVersionSelect = (e) => {
        onChange(selectedPath, e.version);
    };

    const hasChanges = (e) => {
        console.log(e);
    };

    const handleChangeStatus = (status) => {
        onChange(selectedPath, null, status);
    };

    if (!selectedPath) return <div className="text-danger">Select environment and game version</div>;

    const status = content.versions[selectedPath]?.status || 'draft';

    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <StatusSetup value={status} onChange={handleChangeStatus} />

                {(!content.versions[selectedPath]?.version || status === 'draft') && (
                    <div className="ms-auto small text-muted bg-warning-subtle px-3 py-1 rounded-2 fst-italic">
                        Built-in or cached config will be taken
                        <i className="fas fa-info-circle fa-sm ms-2" />
                    </div>
                )}
            </div>
            <FileView
                editInModal
                gameVersion={props.gameVersion}
                environment={props.environment}
                path={selectedFile}
                version={selectedVersions[selectedPath]?.version}
                onSelect={onFileVersionSelect}
                hasChanges={hasChanges}
                readOnly={readOnly}
            />
        </>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Modal, Spinner } from '@gpg-web/react';
import { getVersions, addVersion, removeVersion } from 'services/settings';
import { utils, date } from '@gpg-web/utils';
import { IAMButton, IAMButtonSmall, UserBadge, Input, useGame } from 'components';
import { SettingsNavigation } from './Navigation';
import Skeleton from 'react-loading-skeleton';
import semver from 'semver';
import { QueryClient } from '@tanstack/react-query';
const columns = [
    {
        Header: '#',
        accessor: 'index'
    },
    {
        Header: 'Name',
        accessor: 'displayName',
        sortType: (rowA, rowB) => {
            return rowA.original.id > rowB.original.id ? -1 : 1;
        }
    },
    {
        Header: 'Created By',
        accessor: 'displayAuthor',
        sortType: (rowA, rowB) => {
            return rowA.original.user > rowB.original.user ? -1 : 1;
        }
    },
    {
        Header: 'Created At',
        accessor: 'displayCreatedAt',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.createdAt > rowB.original.createdAt ? 1 : -1;
        }
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true
    }
];

const queryClient = new QueryClient({});

export const VersionsSettings = (props) => {
    const [adding, setAdding] = useState({});
    const [isAdding, setIsAdding] = useState(false);
    const [saving, setSaving] = useState(false);
    const [versions, setVersions] = useState(null);
    const game = useGame();
    const history = useNavigate();

    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getVersions(gameId)
            .then((list) => setVersions(list.slice().reverse()))
            .catch(utils.hintError);
    }, [gameId]);

    const data = useMemo(() => {
        if (!versions) return [];

        const _removeVersion = (e) => {
            e.stopPropagation();

            const id = e.target.getAttribute('data-id');

            utils.confirm('Are you sure want to remove this version?', (yes) => {
                if (yes) {
                    utils.popup('removing');

                    removeVersion(gameId, id)
                        .then(async () => {
                            utils.remove(versions, (e) => e.id === id);

                            setVersions(versions.slice());

                            await queryClient.invalidateQueries([gameId, 'versions']);

                            utils.popup('hide');
                        })
                        .catch(utils.hintError);
                }
            });
        };

        return versions.map((e, index) => {
            return {
                index: index + 1,
                id: e.id,
                displayName: (
                    <>
                        <div className="text-primary">{e.id}</div>
                        <div className="small">{e.name}</div>
                    </>
                ),
                name: e.name,
                displayAuthor: <UserBadge email={e.user} onClick={(e) => history(e.url)} />,
                user: e.user,
                createdAt: new Date(e.date),
                displayCreatedAt: date(e.date, 'ago-1'),
                actions: (
                    <>
                        <IAMButtonSmall permissions={['settings.versions.update']}>
                            <button
                                title="Delete version"
                                data-id={e.id}
                                onClick={_removeVersion}
                                className="btn btn-sm"
                            >
                                <i className="fa fa-trash pe-none" />
                            </button>
                        </IAMButtonSmall>
                    </>
                )
            };
        });
    }, [versions, gameId, history]);

    const _addVersion = async () => {
        try {
            let id = adding.id.trim();

            if (!id) return utils.hintError('Version field is required');

            if (!semver.valid(id))
                return utils.hintError('Please provide a valid semantic version Major.Minor.Patch');

            setSaving(true);

            const result = await addVersion(gameId, {
                id: id,
                name: adding.name
            });

            await queryClient.invalidateQueries([gameId, 'versions']);

            utils.hintOk(result.id + ' is added');
            versions.push(result);

            setVersions(versions.slice());
            setSaving(false);
            setIsAdding(false);
            setAdding({});

            const initializeAfter = await utils.confirm(
                "Do you want to initialize <span class='fw-bold'>" +
                    result.id +
                    '</span> from any existing version?'
            );

            if (initializeAfter) {
                const query = new URLSearchParams({
                    dst: result.id
                });

                const scriptId = 'configPromoteVersion';

                history(`/game/${gameId}/automation/scripts?${query.toString()}#${scriptId}`);
            }
        } catch (err) {
            utils.hintError(err);
            setSaving(false);
        }
    };

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setAdding({ ...adding, [name]: value });
    };

    return (
        <>
            <SettingsNavigation gameId={gameId} tab="versions" />
            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4 className="mb-0 me-3">Versions</h4>
                    <IAMButton permissions={['settings.versions.update']}>
                        <button onClick={() => setIsAdding({})} className="btn btn-light text-primary">
                            <i className="fa fa-plus me-1" /> ADD
                        </button>
                    </IAMButton>
                </div>

                <Modal title="Add new game version" show={isAdding} onHide={() => setIsAdding(false)}>
                    <div className="modal-body">
                        <Input
                            name="id"
                            required
                            tooltip="REQUIRED: specify unique and valid semantic version"
                            title="Version"
                            value={adding.id}
                            onChange={handleChange}
                        />
                        <Input name="name" title="Display name" value={adding.name} onChange={handleChange} />
                    </div>
                    <div className="modal-footer">
                        <button disabled={saving} className="btn btn-secondary" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            disabled={saving || !versions}
                            onClick={_addVersion}
                            className="btn btn-success"
                        >
                            {saving ? <Spinner /> : <i className="fa fa-sm fa-plus me-1" />} Add
                        </button>
                    </div>
                </Modal>

                <div className="mt-4">
                    {!!versions && (
                        <Table
                            columns={columns}
                            sortBy={[{ id: 'displayCreatedAt', desc: true }]}
                            data={data}
                            className="table-striped"
                        />
                    )}
                    {!versions && <Skeleton className="mb-2" width="100%" count={5} height={60} />}
                </div>
            </div>
        </>
    );
};

import { Link } from 'react-router-dom';

const settings = [
    { id: 'game', name: 'Game' },
    { id: 'stage', name: 'Stage' },
    { id: 'environments', name: 'Environments' },
    { id: 'versions', name: 'Versions' },
    { id: 'iam', name: 'Users and permissions' },
    { id: 'open-api', name: 'Open Api' }
];

export const SettingsNavigation = (props) => {
    const { gameId, tab } = props;

    return (
        <ul className="nav nav-tabs mb-4">
            {settings.map((e) => (
                <li className="nav-item" key={e.id}>
                    <Link
                        to={'/game/' + gameId + '/settings/' + e.id}
                        className={'nav-link' + (tab === e.id ? ' active disabled' : '')}
                    >
                        {e.name}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import axios from 'axios';

const getAllLogs = () => {
    return axios.get('/api/logs/all');
};

const getLogs = (gameId, options) => {
    return axios.post('/api/logs/' + gameId, options);
};

export { getAllLogs, getLogs };

import { useParams } from 'react-router-dom';
import { Components, ComponentsMap } from 'consts';

const _componentsURLs = {};

Components.forEach((e) => {
    _componentsURLs[e.url] = e;
});

// function getComponentByURL(url) {
//     const component = _componentsURLs[url];

//     if (!component) throw new HTTPError('Component ' + url + ' not found', HTTPStatus.NotFound);

//     return component;
// }

function useComponent(id) {
    const { componentURL } = useParams();

    if (id) return ComponentsMap[id] || { id: id };

    return _componentsURLs[componentURL] || { url: componentURL };
}

export { useComponent };

import { useQuery } from '@tanstack/react-query';
import { useGame } from 'components';
import { getStorageAsset } from 'services/storage';

export function useFileVersions(path) {
    const game = useGame();
    const gameId = game.id;
    path = path || '';

    const { isFetching, error, data } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/')],
        queryFn: () => getStorageAsset(gameId, path),
        enabled: !!(gameId && path)
    });

    const versions = (data && data.versions) || [];

    return { versions, isFetching, error };
}

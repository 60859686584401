import { utils } from '@gpg-web/utils';
import { TooltipWrapper } from 'components';

export const AddressableFileOption = ({ title, value, readOnly, placeholder, tooltip }) => {
    const hasValue = !!value;

    return (
        <div className="mb-4">
            {title !== false && (
                <TooltipWrapper layout="inline-start" size="xs" content={tooltip}>
                    <div className="me-2 text-nowrap">{title}</div>
                </TooltipWrapper>
            )}
            <div
                role={!readOnly && !hasValue ? 'button' : ''}
                className="d-flex align-items-center small border-bottom w-100 mb-2"
            >
                {!hasValue && <span className="text-muted">{placeholder || 'No file selected'}</span>}
                {hasValue && (
                    <span className="text-muted font-monospace" title={value.path}>
                        {utils.baseName(value.path, true)}
                        <span className="fw-bold ms-1">#{value.version}</span>
                    </span>
                )}
            </div>
        </div>
    );
};

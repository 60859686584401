export const InputRadioGroup = ({ id, name, value, onChange, options }) => {
    return (
        <div className="col-md-12 col-sm-12 mb-3">
            <div className="mb-1 fw-bold">{name}</div>
            <div className="form-check form-switch d-flex">
                {options.map(({ value: optionValue, label }, index) => (
                    <div className="col-md-3 col-sm-6" key={index}>
                        <input
                            className="form-check-input"
                            name={id}
                            type="radio"
                            id={id + '_' + index}
                            onChange={(e) => onChange(optionValue)}
                            checked={value === optionValue}
                        />
                        <label className="form-check-label small" htmlFor={id + '_' + index}>
                            {label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FileView } from '../../storage/FileView';
import { StorageFileModal } from '../../storage/FileModal';
import { StatusSetup } from '../components/Status';
import { ComponentsMap } from 'consts';
import { useGame, useFileVersions } from 'components';
import Select from 'react-select';
import { Triggers } from '../components/Triggers';
import { Spinner } from '@gpg-web/react';
import { TriggersDropdown } from './TriggersDropdown';
import { utils } from '@gpg-web/utils';

export const ContentView = (props) => {
    const { versions, variants, configs, onChange, readOnly, selectedPath, onSetupTriggers, data, setData } =
        props;

    const [selectedVariant, setSelectedVariant] = useState(null);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [editingConfig, setEditingConfig] = useState({});

    const game = useGame();
    const gameId = game.id;

    useEffect(() => {
        setSelectedVariant(null);
        setSelectedConfig(null);
    }, [variants, configs]);

    const handleChangeVersion = (e) => {
        if (editingConfig.filePath) {
            editingConfig.fileVersion = e.version;
            setEditingConfig({ ...editingConfig });
        }

        onChange(selectedPath, {
            variant: editingConfig.variant,
            config: editingConfig.uid,
            value: {
                version: e.version
            }
        });
    };

    const hasChanges = (e) => {
        console.log(e);
    };

    const handleChangeStatus = (status) => {
        onChange(selectedPath, null, status);
    };

    const selectVariantOptions = useMemo(() => {
        return variants.map((e) => ({ label: e.id, value: e.id }));
    }, [variants]);

    const selectConfigOptions = useMemo(() => {
        return configs.map((e) => {
            const component = ComponentsMap[e.component] || { name: e.component };
            return {
                label: component.name + ' | ' + e.id,
                id: e.id,
                path: e.path,
                component: e.component,
                value: e.component + e.id
            };
        });
    }, [configs]);

    if (!selectedPath) return <div className="text-danger">Select environment and game version</div>;

    return (
        <>
            <div className="d-flex flex-wrap align-items-center mb-3">
                <div className={utils.className(readOnly ? 'd-none' : '')}>
                    <StatusSetup
                        value={versions[selectedPath]?.status || 'draft'}
                        onChange={handleChangeStatus}
                    />
                </div>
                <div className="ms-auto d-flex">
                    <Select
                        value={selectVariantOptions.find((e) => e.value === selectedVariant) || null}
                        placeholder="Set variant filter"
                        className="small w-200px"
                        classNamePrefix="select"
                        isClearable
                        onChange={(e) => setSelectedVariant(e && e.value)}
                        options={selectVariantOptions}
                    />

                    <Select
                        value={selectedConfig}
                        placeholder="Set config filter"
                        className="small ms-3 w-350px"
                        classNamePrefix="select"
                        isClearable
                        onChange={(e) => setSelectedConfig(e)}
                        options={selectConfigOptions}
                    />
                </div>
            </div>

            <div className="variants">
                {selectedVariant && selectedConfig && (
                    <>
                        <div
                            className={
                                'rounded-pill px-3 py-2 mb-3 variant variant-color-' +
                                variants.findIndex((e) => e.id === selectedVariant)
                            }
                        >
                            <b>{selectedVariant}</b> - {ComponentsMap[selectedConfig.component]?.name} |{' '}
                            <span className="fw-bold">{selectedConfig.id}</span>
                            {!readOnly && (
                                <TriggersDropdown
                                    path={selectedPath}
                                    configs={configs}
                                    versions={versions}
                                    onChange={onChange}
                                    componentId={selectedConfig.component}
                                    variantId={selectedVariant}
                                    configId={selectedConfig.id}
                                    onSetupTriggers={onSetupTriggers}
                                />
                            )}
                        </div>
                        <FileView
                            editInModal
                            path={selectedConfig.path}
                            gameVersion={props.gameVersion}
                            environment={props.environment}
                            version={
                                versions[selectedPath]?.['values'][selectedVariant]?.[selectedConfig.value]
                                    ?.version
                            }
                            onSelect={(e) => {
                                onChange(selectedPath, {
                                    variant: selectedVariant,
                                    config: selectedConfig.value,
                                    value: {
                                        version: e.version
                                    }
                                });
                            }}
                            hasChanges={hasChanges}
                            readOnly={readOnly}
                        />
                    </>
                )}
                {selectedVariant &&
                    !selectedConfig &&
                    configs.map((config, index) => {
                        const component = ComponentsMap[config.component] || {
                            name: config.component
                        };
                        const configUid = config.component + config.id;
                        const value = versions[selectedPath]?.['values'][selectedVariant]?.[configUid];
                        const version = value?.version;
                        const variantIndex = variants.findIndex((e) => e.id === selectedVariant);

                        return (
                            <div key={index} className="px-2 pb-2 mb-2 border-bottom">
                                <div
                                    className={
                                        'rounded-pill px-3 py-2 mb-3 variant variant-color-' + variantIndex
                                    }
                                >
                                    {component.name} | <span className="fw-bold">{config.id}</span>
                                    {!readOnly && (
                                        <TriggersDropdown
                                            path={selectedPath}
                                            configs={configs}
                                            versions={versions}
                                            onChange={onChange}
                                            componentId={component.id}
                                            variantId={selectedVariant}
                                            configId={config.id}
                                            onSetupTriggers={onSetupTriggers}
                                        />
                                    )}
                                </div>
                                <FileView
                                    editInModal
                                    path={config.path}
                                    gameVersion={props.gameVersion}
                                    environment={props.environment}
                                    version={version}
                                    onSelect={(e) => {
                                        onChange(selectedPath, {
                                            variant: selectedVariant,
                                            config: configUid,
                                            value: {
                                                version: e.version
                                            }
                                        });
                                    }}
                                    gameVersion={props.gameVersion}
                                    environment={props.environment}
                                    hasChanges={hasChanges}
                                    readOnly={readOnly}
                                />
                            </div>
                        );
                    })}

                {!selectedVariant &&
                    selectedConfig &&
                    variants.map((variant, index) => {
                        const configUid = selectedConfig.value;
                        const value = versions[selectedPath]?.['values'][variant.id]?.[configUid];
                        const version = value?.version;

                        return (
                            <div
                                key={index}
                                className={'pb-1' + (index === variants.length - 1 ? '' : ' mb-3')}
                            >
                                <div
                                    className={
                                        'rounded-pill px-3 py-2 mb-3 fw-bold variant variant-color-' + index
                                    }
                                >
                                    {variant.id}
                                    {!readOnly && (
                                        <TriggersDropdown
                                            path={selectedPath}
                                            configs={configs}
                                            versions={versions}
                                            onChange={onChange}
                                            componentId={selectedConfig.component}
                                            variantId={variant.id}
                                            configId={selectedConfig.id}
                                            onSetupTriggers={onSetupTriggers}
                                        />
                                    )}
                                </div>

                                <FileView
                                    editInModal
                                    path={selectedConfig.path}
                                    version={version}
                                    gameVersion={props.gameVersion}
                                    environment={props.environment}
                                    onSelect={(e) => {
                                        onChange(selectedPath, {
                                            variant: variant.id,
                                            config: configUid,
                                            value: {
                                                version: e.version
                                            }
                                        });
                                    }}
                                    gameVersion={props.gameVersion}
                                    environment={props.environment}
                                    hasChanges={hasChanges}
                                    readOnly={readOnly}
                                />
                            </div>
                        );
                    })}

                {!selectedVariant &&
                    !selectedConfig &&
                    variants.map((variant, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    'text-nowrap pb-1' + (index === variants.length - 1 ? '' : ' mb-3')
                                }
                            >
                                <div
                                    className={
                                        'rounded-pill px-3 py-2 mb-3 fw-bold variant variant-color-' + index
                                    }
                                >
                                    {variant.id}
                                </div>

                                {configs.map((config, index) => {
                                    const component = ComponentsMap[config.component] || {
                                        name: config.component
                                    };
                                    const configUid = config.component + config.id;
                                    const value = versions[selectedPath]?.['values'][variant.id]?.[configUid];
                                    const version = value?.version;

                                    const isDraft = version && version.startsWith('draft');

                                    return (
                                        <div
                                            key={index}
                                            className="small d-flex align-items-center px-2 pb-2 mb-2 border-bottom"
                                        >
                                            <div className={!version ? 'opacity-2' : ''}>
                                                <span>{component.name} | </span>
                                                <span className="fw-bold">{config.id}</span>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    setEditingConfig({
                                                        filePath: config.path,
                                                        fileVersion: version,
                                                        variant: variant.id,
                                                        uid: configUid
                                                    });
                                                }}
                                                className={utils.className(
                                                    'btn btn-sm btn-light ms-4 py-0',
                                                    readOnly && !version ? 'disabled' : '',
                                                    isDraft
                                                        ? game.isProduction
                                                            ? 'text-danger'
                                                            : 'text-primary'
                                                        : ''
                                                )}
                                            >
                                                <i
                                                    className={utils.className(
                                                        'fa-sm me-2',
                                                        isDraft && game.isProduction
                                                            ? 'fas fa-exclamation-triangle'
                                                            : readOnly
                                                            ? 'fas fa-file'
                                                            : 'fas fa-edit'
                                                    )}
                                                />
                                                {version ? (
                                                    <>
                                                        {isDraft
                                                            ? 'Draft - ' + version.replace('draft.', '')
                                                            : '#' + version}
                                                    </>
                                                ) : readOnly ? (
                                                    'Version is not selected'
                                                ) : (
                                                    'Select version'
                                                )}
                                            </button>
                                            {!readOnly && (
                                                <TriggersDropdown
                                                    path={selectedPath}
                                                    configs={configs}
                                                    versions={versions}
                                                    onChange={onChange}
                                                    componentId={component.id}
                                                    variantId={variant.id}
                                                    configId={config.id}
                                                    onSetupTriggers={onSetupTriggers}
                                                    asButton
                                                />
                                            )}
                                            {version && (
                                                <span className="ms-auto text-muted bg-secondary-subtle px-3 py-1 rounded-2 fst-italic">
                                                    <VersionComment path={config.path} version={version} />
                                                </span>
                                            )}
                                            {!version && (
                                                <span className="ms-auto text-muted bg-warning-subtle px-3 py-1 rounded-2 fst-italic">
                                                    Regular version will be taken (check in
                                                    <Link
                                                        className="ms-1"
                                                        to={
                                                            '/game/' +
                                                            gameId +
                                                            '/config/' +
                                                            component.url +
                                                            '/view/' +
                                                            config.id
                                                        }
                                                    >
                                                        {component.name}
                                                    </Link>
                                                    )
                                                    <i className="fas fa-info-circle fa-sm ms-2" />
                                                </span>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
            </div>

            <StorageFileModal
                path={editingConfig.filePath}
                version={editingConfig.fileVersion}
                show={!!editingConfig.filePath}
                gameVersion={props.gameVersion}
                environment={props.environment}
                onHide={(e) => setEditingConfig({})}
                readOnly={readOnly}
                onSelect={handleChangeVersion}
            />
        </>
    );
};

const VersionComment = (props) => {
    const { path, version } = props;

    const { versions } = useFileVersions(path);

    const versionData = versions.find((e) => e.id === version);
    console.log(versions, versionData);

    let message = versionData && versionData.message;

    return versionData || versions.length > 0 ? (
        <>
            {message ? (
                <>
                    {message} <i className="fas fa-sm ms-2 fa-comment" />
                </>
            ) : (
                <span className="text-danger">
                    Version not found <i className="fas fa-sm ms-2 fa-exclamation-circle" />
                </span>
            )}
        </>
    ) : (
        <>
            <Spinner size="10px" />
            &nbsp;&nbsp;loading...
        </>
    );
};

import { useEffect, useState } from 'react';
import { utils } from '@gpg-web/utils';
import { InputOption, TooltipWrapper, useStoredState, Spinner } from 'components';
import {
    downloadFile,
    getFileInfo as getGoogleFileInfo,
    SERVICE_ACCOUNT_EMAIL,
    getClientAuth,
    authSignOut
} from 'services/google';

const getFileInfo = utils.debounce((url, thenCb, catchCb, client = false) => {
    return getGoogleFileInfo(url, client).then(thenCb).catch(catchCb);
}, 500);

export const ImportFromGoogleDrive = ({ show, onCancel, onAccept, hasCancel = true }) => {
    const [usePersonalAccount, setUsePersonalAccount] = useStoredState(
        'localisation_import_use_personal_acc',
        false
    );

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isAuth, setAuth] = useState(false);
    const [inputUrl, setInputUrl] = useState('');
    const [fileInfo, setFileInfo] = useState(null);
    const isValidFile = fileInfo && fileInfo.mimeType === 'application/vnd.google-apps.spreadsheet';

    const reset = () => {
        setError(null);
        setInputUrl('');
        setFileInfo(null);
        setAuth(false);
        onCancel && onCancel();
    };

    const onImportClick = () => {
        if (isValidFile) {
            setLoading(true);

            downloadFile(
                fileInfo.id,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                usePersonalAccount
            )
                .then((blob) => {
                    blob.name = fileInfo.name;
                    onAccept(blob);
                    setLoading(false);
                    reset();
                })
                .catch((err) => {
                    if (err.name === 'GoogleAuthError') {
                        setInputUrl('');
                        setFileInfo(null);
                        setAuth(false);
                    }
                    setError(err.message || err);
                    setLoading(false);
                });
        }
    };

    const onInputUrlChange = (name, value) => {
        setInputUrl(value);
        setFileInfo(null);
        setError(null);
        if (value.length > 10 && value.startsWith('http')) {
            getFileInfo(
                value,
                (data) => setFileInfo(data),
                (err) => {
                    setInputUrl('');
                    setError(err.message || err);
                },
                usePersonalAccount
            );
        }
    };

    return (
        <div className="p-3">
            <div className="mb-3">
                <button
                    onClick={() => {
                        setUsePersonalAccount(!usePersonalAccount);
                        setInputUrl('');
                        setFileInfo(null);
                        setError(null);
                    }}
                    className="btn btn-light btn-sm shadow rounded-pill"
                >
                    {usePersonalAccount ? 'Use service account' : 'Use personal account'}
                </button>
            </div>

            {!usePersonalAccount && <ImportUsingServiceAccount />}

            {!!usePersonalAccount && false && (
                <ImportUsingPersonalAccount isAuth={isAuth} setAuth={setAuth} />
            )}

            {
                <InputOption
                    name="url"
                    title="Google Spreadsheet URL"
                    value={inputUrl}
                    onChange={onInputUrlChange}
                />
            }

            {fileInfo && (
                <div className="mt-2">
                    {isValidFile ? (
                        <div className="alert alert-success p-2 px-3" role="alert">
                            <i className="fab fa-google-drive me-2" />
                            Found spreadsheet: <strong>{fileInfo.name}</strong>
                        </div>
                    ) : (
                        <div className="alert alert-danger p-2 px-3" role="alert">
                            <i className="fas fa-sm fa-exclamation-circle me-2" />
                            File <strong>{fileInfo.name}</strong> has invalid type. Please provide a
                            spreadsheet.
                        </div>
                    )}
                </div>
            )}

            <div className="d-flex align-items-center">
                {!fileInfo && inputUrl && inputUrl.length > 10 && (
                    <div>
                        <Spinner className="me-1" /> Getting file info
                    </div>
                )}
                {error && (
                    <div className="text-danger">
                        <i className="fas fa-exclamation-circle fa-sm me-2" />
                        {error}
                    </div>
                )}
                <div className="ms-auto" />
                {hasCancel && (
                    <button onClick={reset} className="btn btn-sm btn-light rounded-pill px-3 shadow">
                        Cancel
                    </button>
                )}
                <button
                    className="btn btn-sm btn-primary ms-2 rounded-pill px-3 shadow"
                    disabled={!isValidFile || loading}
                    onClick={onImportClick}
                >
                    {loading && <Spinner size={14} className="me-2" />} Import
                </button>
            </div>
        </div>
    );
};

const ImportUsingServiceAccount = () => {
    const onCopyServiceAccount = () => {
        navigator.clipboard
            .writeText(SERVICE_ACCOUNT_EMAIL)
            .then(() =>
                utils.hint('Copied', { bg: 'success', color: 'white', icon: 'fas fa-copy', duration: 500 })
            );
    };

    return (
        <div className="alert alert-info d-flex align-items-center" role="alert">
            <div className="me-3">
                <i className="fas fa-info-circle" />
            </div>
            <div>
                Please make sure that{' '}
                <strong className="text-decoration-underline">
                    {SERVICE_ACCOUNT_EMAIL}
                    <button
                        type="button"
                        className="btn btn-sm text-info-emphasis p-0 d-inline-block"
                        onClick={onCopyServiceAccount}
                    >
                        <i className="p-1 fas fa-copy" />
                    </button>
                </strong>{' '}
                has access to the file as Viewer
            </div>
        </div>
    );
};

const ImportUsingPersonalAccount = ({ show, isAuth, setAuth }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            getClientAuth()
                .then((res) => setAuth(res.auth || false))
                .catch(utils.hintError);
        }
    }, [show, setAuth]);

    function setError(err) {
        utils.hintError(err);
        setLoading(false);
    }

    const onAuth = () => {
        getClientAuth()
            .then((res) => {
                setAuth(res.auth || false);
                setLoading(false);
            })
            .catch(setError);
    };

    const onSignOut = () => {
        setLoading(true);
        authSignOut()
            .then((res) => {
                setAuth(res.auth || false);
                setLoading(false);
            })
            .catch(setError);
    };

    return (
        <>
            {isAuth ? (
                <div className="alert alert-success d-flex align-items-center" role="alert">
                    <div className="me-auto">
                        <i className="fas fa-check-circle me-2" />
                        You are logged in.
                    </div>
                    <TooltipWrapper content="Clear the access token from our database. The access token is alive only for 1 hour.">
                        <button disabled={loading} className="btn btn-primary px-3" onClick={onSignOut}>
                            {loading && <Spinner className="me-2" />} Sign Out
                        </button>
                    </TooltipWrapper>
                </div>
            ) : (
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                    <div>Please sign in using your Google Account</div>
                    <button disabled={loading} className="ms-auto btn btn-primary" onClick={onAuth}>
                        {loading ? <Spinner className="me-2" /> : <i className="fab fa-google fa-sm me-2" />}{' '}
                        Sign In
                    </button>
                </div>
            )}
        </>
    );
};

import { useContext, useState } from 'react';
import {
    BasicJsonTextEditor,
    IAMButton,
    InputOption,
    useGame,
    BoolOption
} from 'components';
import { SettingsNavigation } from './Navigation';
import { utils } from '@gpg-web/utils';
import { gameContext, updateGame } from 'services/game';

export const StageSettings = () => {
    const game = useGame();
    let { setGame } = useContext(gameContext);

    let [newGCPServiceAccount, setNewGCPServiceAccount] = useState('');

    const gameId = game.id;
    const loaded = !!gameId;

    if (!loaded) return null;

    const handleChange = (name, value) => {
        setGame({ ...game, [name]: value });
    };

    async function updateGameInformation() {
        try {
            if (!game.name.trim()) return utils.hintError('Stage Name is required');
            if (!game.backend_api_url.trim()) return utils.hintError('Backend API URL is required');

            const data = {
                name: game.name.trim(),
                isProduction: game.isProduction,
                backend_api_url: game.backend_api_url.trim()
            };

            if (newGCPServiceAccount) {
                try {
                    let parsed = JSON.parse(newGCPServiceAccount);

                    if (parsed.client_email) {
                        data.gcp_service_account = JSON.stringify(parsed);
                    } else {
                        return utils.hintError('Bad Firebase-GCP serive account provided');
                    }
                } catch (err) {
                    return utils.hintError('Bad Firebase-GCP serive account provided');
                }
            }

            const yes = await utils.confirm('Are you sure you want to update stage information?');

            if (!yes) return;

            utils.popup('saving');

            const result = await updateGame(gameId, data);

            Object.assign(game, result);

            setGame({ ...game });
            setNewGCPServiceAccount('');

            utils.popup('hide');
        } catch (err) {
            utils.hintError(err);
        }
    }

    return (
        <>
            <SettingsNavigation gameId={gameId} tab="stage" />
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-8">
                    <div className="card shadow">
                        <div className="card-body">
                            <InputOption title="Name" name="name" value={game.name} onChange={handleChange} />
                            <InputOption
                                title="Backend API URL"
                                name="backend_api_url"
                                value={game.backend_api_url}
                                onChange={handleChange}
                            />
                            <BoolOption
                                name="isProduction"
                                title="Is production"
                                value={game.isProduction}
                                onChange={handleChange}
                            />
                            <div className="alert alert-info fw-light">
                                <div>Firebase / GCP service account</div>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                        'https://console.cloud.google.com/iam-admin/serviceaccounts?project=' +
                                        game.project_id
                                    }
                                >
                                    {game.service_account_email}
                                    <i className="fas fa-external-link-alt fa-xs ms-2" />
                                </a>
                                <div className="mt-3">
                                    Firebase project id:
                                    <span className="font-monospace fw-normal ms-2">{game.project_id}</span>
                                </div>
                                <div className="mt-1">
                                    <a
                                        target="_blank"
                                        className="ms-2 text-decoration-none"
                                        rel="noreferrer"
                                        href={
                                            'https://console.firebase.google.com/project/' +
                                            game.project_id +
                                            '/overview'
                                        }
                                    >
                                        - Firebase Console
                                        <i className="fas fa-external-link-alt fa-xs ms-2" />
                                    </a>
                                </div>
                                <div className="mt-1">
                                    <a
                                        target="_blank"
                                        className="ms-2 text-decoration-none"
                                        rel="noreferrer"
                                        href={
                                            'https://console.cloud.google.com/welcome?project=' +
                                            game.project_id
                                        }
                                    >
                                        - Google Cloud Console
                                        <i className="fas fa-external-link-alt fa-xs ms-2" />
                                    </a>
                                </div>
                            </div>

                            <div className="mt-2 mb-2">
                                (optional) Set New Firebase-GCP service account key
                            </div>

                            <BasicJsonTextEditor
                                rows="5"
                                name="gcp_service_account"
                                value={newGCPServiceAccount}
                                onChange={(e) => setNewGCPServiceAccount(e.target.value)}
                            />

                            <div className="text-end">
                                <IAMButton permissions={['resourcemanager.projects.update']}>
                                    <button onClick={updateGameInformation} className="btn btn-success">
                                        <i className="fas fa-check me-1" /> Save
                                    </button>
                                </IAMButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */
import { Languages } from 'consts';

export function languageName(code) {
    return Languages[code] || code;
}

export class InvalidKeyError extends Error {
    constructor(key) {
        super(`"${key}" is not a valid key. a-z, A-Z, 0-9, underscore, slash and point are only allowed`);
    }
}

function fromJSON(jsonContent) {
    let textContent = '';

    for (let key in jsonContent) {
        textContent += ':' + key + '\n';

        for (let lang in jsonContent[key]) {
            textContent += lang + ':' + jsonContent[key][lang].replaceAll('\n', '\\n') + '\n';
        }
    }

    return textContent;
}

function toJSON(textContent, langs) {
    const jsonContent = {};

    const lines = textContent.split('\n');

    let tmpKey = null;
    let tmpObj = null;
    for (let line of lines) {
        if (line.length === 0) continue;

        if (line.startsWith(':')) {
            if (tmpKey !== null && tmpObj !== null) jsonContent[tmpKey] = tmpObj;

            tmpKey = line.slice(1);

            const validatedKey = tmpKey.trim();

            // if (!utils.isValidKeyName(validatedKey)) throw new Error(`'${tmpKey}' is not a valid key`);

            tmpKey = validatedKey;

            tmpObj = {};
        } else {
            if (tmpObj !== null) {
                const keyLang = line.split(':');

                const lang = keyLang.shift();
                const text = keyLang.join(':');

                if (langs) langs[lang] = true;

                tmpObj[lang] = text.replaceAll('\\n', '\n');
                // tmpObj[lang] = text;
            }
        }
    }

    if (tmpKey !== null && tmpObj !== null) jsonContent[tmpKey] = tmpObj;

    return jsonContent;
}

function getInfo(jsonContent) {
    const info = {};

    const keys = Object.keys(jsonContent);

    info.keys = keys.length;
    info.texts = 0;

    let langs = {};

    for (let key in jsonContent) {
        for (let lang in jsonContent[key]) {
            langs[lang] = true;
            info.texts++;
        }
    }

    info.langs = Object.keys(langs).length;

    return info;
}

export { toJSON, fromJSON, getInfo };

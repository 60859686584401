import React from 'react';

import Logout from './Logout';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';
import PromtModal from './PromtModal';
import Popup from './Popup';
import Changelog from './Changelog';
import AutomationProcess from './AutomationProcess';
import ContactUsModal from './ContactUsModal';
import NotificationsModal from './NotificationsModal';

const Modals = (props) => {
    return (
        <>
            <Logout />
            <ContactUsModal />
            <NotificationsModal />
            <AlertModal />
            <ConfirmModal />
            <PromtModal />
            <Popup />
            <AutomationProcess />
            <Changelog />
        </>
    );
};

export default Modals;

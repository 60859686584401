import { useState } from 'react';
import { IAMButtonSmall } from '../../../../components';
import { BrowseStorageModal } from '../../storage/BrowseStorage';
import { StorageFileModal } from '../../storage/FileModal';

export const StorageOption = ({
    title,
    name,
    value,
    onChange,
    readOnly,
    gameId,
    requiredFileVersion = true,
    isFolderSelect = false,
    foldersOnly = false,
    canEdit = true
}) => {
    const [browsingStorage, setBrowsingStorage] = useState(false);

    const [editingFile, setEditingFile] = useState(false);

    const hasValue = !!value;

    return (
        <>
            <div className="d-flex align-items-center my-1">
                <div className="me-2 text-nowrap">{title}</div>
                <div className="d-flex align-items-center small border-bottom w-100">
                    {!hasValue && <span className="small text-muted">No assets selected</span>}
                    {hasValue && value.path && (
                        <>
                            <span className="small font-monospace">{value.path}</span>
                            <span className="ms-2 fw-bold small text-muted">(#{value.version})</span>
                        </>
                    )}
                    {!readOnly && (
                        <>
                            <div className="ms-auto">
                                {hasValue && (
                                    <button
                                        onClick={(e) => onChange(name, null)}
                                        className="btn text-secondary btn-sm border-0"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                )}
                            </div>
                            {!!canEdit && (
                                <div className="ms-1">
                                    <IAMButtonSmall permissions={['component.storage.get']}>
                                        <button
                                            disabled={!hasValue}
                                            onClick={(e) => setEditingFile(name)}
                                            className="btn text-primary btn-sm border-0"
                                        >
                                            <i className="fas fa-edit" />
                                        </button>
                                    </IAMButtonSmall>
                                </div>
                            )}
                            <div className="ms-1">
                                <IAMButtonSmall
                                    permissions={['component.storage.get', 'component.storage.list']}
                                >
                                    <button
                                        title="Select file"
                                        onClick={(e) => setBrowsingStorage(name)}
                                        className="btn text-warning btn-sm border-0"
                                    >
                                        <i className="fas fa-folder-open" />
                                    </button>
                                </IAMButtonSmall>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <BrowseStorageModal
                requiredFileVersion={requiredFileVersion}
                isFolderSelect={isFolderSelect}
                foldersOnly={foldersOnly}
                show={!!browsingStorage}
                onHide={(e) => setBrowsingStorage(false)}
                onSelect={(val) => onChange(name, val)}
                gameId={gameId}
            />

            {hasValue && (
                <StorageFileModal
                    path={editingFile && hasValue ? value.path : null}
                    version={editingFile && hasValue ? value.version : null}
                    show={!!editingFile}
                    onHide={(e) => setEditingFile(false)}
                    onSelect={(val) => onChange(name, val)}
                />
            )}
        </>
    );
};

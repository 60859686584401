import { Spinner } from '@gpg-web/react';
import { useEffect } from 'react';

export const InputSelectButton = ({
    id,
    parentName,
    name,
    value,
    onChange,
    placeholder,
    options,
    ready,
    asyncLoad,
    asyncInit,
    isMulti,
    size = 12,
    disabled
}) => {
    const optionValue = typeof value === 'undefined' ? (isMulti ? [] : undefined) : value;

    const isLoading = !ready && (asyncLoad || asyncInit);

    const isAllMultiSelect = value === 'all' && isMulti;

    useEffect(() => {
        if (!isLoading && isAllMultiSelect) {
            const newOptions = options.filter((opt) => opt.value !== 'all').map((opt) => opt.value);
            onChange(newOptions);
        }
    }, [isLoading, isAllMultiSelect, options, onChange]);

    return (
        <div className={'col-md-' + size + ' col-sm-12 mb-3'}>
            <div className="fw-bold">{name}</div>
            <div className="mt-2">
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        {options.length === 0 ? (
                            <>{placeholder}</>
                        ) : (
                            <>
                                {options.map((e, i) => (
                                    <span key={e.value}>
                                        <input
                                            disabled={!!disabled}
                                            checked={
                                                isMulti
                                                    ? e.value === 'all'
                                                        ? optionValue.length === options.length - 1
                                                        : optionValue.indexOf(e.value) > -1
                                                    : optionValue === e.value
                                            }
                                            onChange={() => {
                                                if (isMulti) {
                                                    if (e.value === 'all') {
                                                        if (optionValue.length === options.length - 1) {
                                                            onChange([]);
                                                        } else {
                                                            const newOptions = options.map(
                                                                (opt) => opt.value
                                                            );
                                                            newOptions.splice(i, 1);
                                                            onChange(newOptions);
                                                        }
                                                    } else {
                                                        onChange(
                                                            optionValue.indexOf(e.value) > -1
                                                                ? optionValue.filter((val) => val !== e.value)
                                                                : [...optionValue, e.value]
                                                        );
                                                    }
                                                } else {
                                                    onChange(e.value);
                                                }
                                            }}
                                            type="checkbox"
                                            className="btn-check"
                                            id={parentName + id + '-' + e.value}
                                            autoComplete="off"
                                        />
                                        <label
                                            className="btn btn-sm btn-outline-primary m-1"
                                            htmlFor={parentName + id + '-' + e.value}
                                        >
                                            {e.label}
                                        </label>
                                    </span>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

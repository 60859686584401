import { ComponentsMap } from 'consts';
import Select from 'react-select';

export const ConfigsSelect = ({ configs, selectedConfigs, addConfig, containerRef }) => {
    let options = [];

    if (configs) {
        const categories = {};

        for (let config of configs) {
            const componentId = config.component;

            if (selectedConfigs.findIndex((e) => e.id === config.id && e.component === componentId) > -1)
                continue;

            categories[componentId] = categories[componentId] || [];
            categories[componentId].push({ label: config.id, id: config.id, component: config.component });
        }

        options = Object.keys(categories).map((componentId) => {
            const component = ComponentsMap[componentId] || { name: componentId };

            return {
                label: component.name,
                options: categories[componentId].sort((a, b) => a.label.localeCompare(b.label))
            };
        });
    }

    return (
        <Select
            value={null}
            name="patch-config-select"
            isClearable={false}
            placeholder="Choose config"
            backspaceRemovesValue={false}
            options={options}
            menuPortalTarget={containerRef.current}
            menuPosition={'fixed'}
            className="react-select my-3"
            classNamePrefix="select"
            onChange={addConfig}
            styles={{
                valueContainer: (base) => ({ ...base, padding: '2px 8px' })
            }}
        />
    );
};

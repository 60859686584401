/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useCallback } from 'react';

import { Modal } from '@gpg-web/react';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import { ComponentsMap } from 'consts';

export const RemoveComponentModal = (props) => {
    const { onHide, show, isLoading, usage, onConfirm, deleteComponent, componentName = '' } = props;

    const [confirm, setConfirm] = useState(false);

    let deleteName = '';
    if (deleteComponent) {
        if (componentName === 'Folder' || componentName === 'File') {
            deleteName = deleteComponent.name;
        } else {
            deleteName = deleteComponent.id;
        }
    }

    const canConfirm = usage && usage.length ? !confirm : isLoading;

    return (
        <Modal
            title={
                <span>
                    {'Deleting ' + componentName}
                    {deleteName && deleteName.length ? (
                        <span className="fw-bold text-break">&nbsp;"{deleteName}"</span>
                    ) : null}
                </span>
            }
            size="xs"
            show={show}
            onHide={onHide}
        >
            {isLoading ? (
                <div className="modal-body">
                    <Skeleton className="shadow-sm mb-2" height={30} />
                    <Skeleton className="shadow-sm mb-2" height={30} />
                    <Skeleton className="shadow-sm mb-1" height={30} />
                </div>
            ) : (
                <div className="modal-body">
                    {usage && usage.length ? (
                        <>
                            <div className="text-muted">This component is being used by: </div>

                            {usage.map((component, i) => (
                                <div className="fw-bold mx-2 mt-2" key={i}>
                                    -{' '}
                                    {component.type
                                        ? '(' +
                                          (ComponentsMap[component.type]
                                              ? ComponentsMap[component.type].name
                                              : component.type) +
                                          ') '
                                        : null}
                                    {component.id + (component.name ? ' - ' + component.name : '')}
                                </div>
                            ))}

                            <div className="m-2 mt-4 mx-0">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkbox-confirm-delete"
                                        checked={confirm}
                                        onChange={(e) => setConfirm(e.target.checked)}
                                    />
                                    <label
                                        className="form-check-label text-muted"
                                        htmlFor="checkbox-confirm-delete"
                                    >
                                        I still want to remove <span className="fw-bold">"{deleteName}"</span>
                                    </label>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                Are you sure want to remove this {componentName.toLowerCase()} - {deleteName}?
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className="modal-footer">
                <button className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                </button>
                <button disabled={canConfirm} onClick={onConfirm} className="btn btn-danger">
                    Confirm
                </button>
            </div>
        </Modal>
    );
};

export const useRemoveComponent = (componentName) => {
    const [deleteModal, setDeleteModal] = useState({
        show: false
    });

    const showRemoveModal = useCallback((getUsage, deleteComponent, onRemove) => {
        setDeleteModal({
            show: true,
            isLoading: true,
            usage: null,
            deleteComponent: deleteComponent
        });

        getUsage
            .then((usage) => {
                setDeleteModal({
                    show: true,
                    isLoading: false,
                    usage: usage,
                    deleteComponent: deleteComponent,
                    onConfirm: () => {
                        onRemove();
                        setDeleteModal({ show: false });
                    }
                });
            })
            .catch((e) => {
                console.log(e);
                setDeleteModal({ show: false });
                utils.hintError(e);
            });
    }, []);

    const RemoveModal = (
        <RemoveComponentModal
            key={deleteModal.deleteComponent ? deleteModal.deleteComponent.id : componentName}
            componentName={componentName}
            onHide={() => setDeleteModal({ show: false })}
            {...deleteModal}
        />
    );

    return [RemoveModal, showRemoveModal];
};

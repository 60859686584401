/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useRef } from 'react';
import { ButtonFileInput } from 'components';
import { utils } from '@gpg-web/utils';
import JSONEditor from 'jsoneditor/dist/jsoneditor';

let edit_mode_preferrence = localStorage.getItem('jsoneditor_mode_preferrence') || 'tree';

const JSONView = (props) => {
    const { initialValue, editing, fileName, onChange, onError } = props;

    const refContainer = useRef(null);
    const [editor, setEditor] = useState(null);

    useEffect(() => {
        const editor = new JSONEditor(refContainer.current, {
            enableSort: false,
            enableTransform: true,
            modes: editing ? ['tree', 'code'] : [],
            mode: editing ? edit_mode_preferrence : 'view',
            showErrorTable: ['text', 'preview', 'code'],
            onModeChange: (m) => {
                edit_mode_preferrence = m;
                localStorage.setItem('jsoneditor_mode_preferrence', m);
            }
        });

        setEditor(editor);

        return () => {
            editor.destroy();
            setEditor(null);
        };
    }, [editing]);

    useEffect(() => {
        if (!editor) return;

        editor.set(initialValue);
        if (JSON.stringify(initialValue).length < 600 && editor.expandAll) editor.expandAll();
    }, [editor, initialValue]);

    useEffect(() => {
        if (!editor) return;
        editor.setName(fileName);
    }, [editor, fileName]);

    useEffect(() => {
        if (!editor) return;
        editor.options.onChange = () => {
            onChange && onChange(editor.get());
        };
    }, [editor, onChange]);

    useEffect(() => {
        if (!editor) return;
        editor.options.onValidationError = (errors) => {
            if (!onError) return;

            if (errors.length === 0) onError(false);
            else onError(errors[0].message);
        };
    }, [editor, onError]);

    return (
        <div
            className={utils.className('json-editor-view', !props.fluid && 'storage-view-box')}
            ref={refContainer}
        ></div>
    );
};

JSONView.Toolbar = ({ onUpload }) => {
    const acceptNewFile = async (file) => {
        try {
            let content = await utils.load.file(file, 'text');
            const parsed = JSON.parse(content);
            content = JSON.stringify(parsed);
            onUpload(content);
        } catch (err) {
            utils.hintError('File you are trying to open should have a valid JSON format content.');
        }
    };

    return (
        <>
            <ButtonFileInput
                accept="application/json"
                className="rounded-pill px-3"
                onAccept={acceptNewFile}
            />
        </>
    );
};

export { JSONView };

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@gpg-web/react';
import socket from 'services/socket';

const AutomationProcess = (props) => {
    let _modal = useRef(null);
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        const cb = function () {
            setData({});
        };

        const dom = _modal.current;

        const modal = new window.bootstrap.Modal(dom);
        setModal(modal);

        dom.addEventListener('hidden.bs.modal', cb);

        return () => {
            // dom.removeEventListener('hidden.bs.modal', cb);
            modal.dispose();
        };
    }, []);

    useEffect(() => {
        if (!(data.gameId && data.process && data.async)) return;

        function socketProcessUpdate(res) {
            if (res.game === data.gameId && res.id === data.process) {
                if (res.finished) {
                    setData({
                        gameId: res.game,
                        name: res.name,
                        process: res.id,
                        errors: res.errors.length,
                        warnings: res.warnings.length,
                        added: res.added.length,
                        modified: res.modified.length
                    });
                }
            }
        }

        socket.on('automation-process', socketProcessUpdate);

        return () => {
            socket.off('automation-process', socketProcessUpdate);
        };
    }, [data]);

    AutomationProcess.show = function (data) {
        if (modal) {
            setData(data);
            modal.show();
        }
    };

    const history = useNavigate();

    const seeMore = () => {
        history('/game/' + data.gameId + '/automation/processes/' + data.process);
    };

    return (
        <>
            <div ref={_modal} className="modal fade" id="changelog-modal" data-bs-backdrop="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{data.name || 'Unknown'} process</h5>
                        </div>
                        <div className="modal-body">
                            {data.async ? (
                                <div>
                                    <div>
                                        <Spinner /> Process is running...
                                    </div>
                                    <div className="mt-3">
                                        You can close this window, and continue your business.
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="h5">
                                        {data.errors ? (
                                            <>
                                                <i className="fas fa-exclamation-circle text-danger me-2" />{' '}
                                                Process has been finished with errors.
                                            </>
                                        ) : data.warnings ? (
                                            <>
                                                <i className="fas fa-exclamation-triangle text-warning me-2" />{' '}
                                                Process has been finished with warnings.
                                            </>
                                        ) : data.added || data.modified ? (
                                            <>
                                                <i className="fas fa-check-circle me-2 text-success" />{' '}
                                                Process has been successfully finished !
                                            </>
                                        ) : (
                                            <>
                                                <i className="fas fa-exclamation-triangle text-warning me-2" />{' '}
                                                Process has been finished. Nothing has been added or modified.
                                            </>
                                        )}
                                    </div>
                                    <div className="small fw-light mt-3">
                                        <span>Added: {data.added || 0}</span>
                                        <span className="ms-3">Modified: {data.modified || 0}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="d-flex align-items-center px-4 pb-4">
                            <button
                                data-bs-dismiss="modal"
                                onClick={seeMore}
                                className="btn btn-link ms-auto"
                            >
                                Go to details
                            </button>
                            <button className="btn btn-secondary ms-3" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AutomationProcess.show = function () {};

export default AutomationProcess;

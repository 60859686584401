import { useContext, useMemo, useEffect, useState } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import profileIcon from '../img/undraw_profile.svg';
import logo from '../img/logo.svg';
import { userContext } from 'services/user';
import { Version } from 'consts';
import { gameContext } from 'services/game';
import { NotificationAlertItem } from 'components';
import { organizationContext } from 'services/organization';
import { getNotifications, readAllNotifications, events } from 'services/notification';
import { utils } from '@gpg-web/utils';
import { ThemeButton } from '@gpg-web/react';
import Select from 'react-select';

const MAX_SHOW_NOTIFICATIONS = 6;

const Topbar = (props) => {
    const [searchParams] = useSearchParams();
    const { email, name, picture, provider } = useContext(userContext);
    const { game, setGame } = useContext(gameContext);
    const { games, gameGroups } = useContext(organizationContext);
    const [notifications, setNotifications] = useState([]);

    const history = useNavigate();
    // const location = useLocation();

    let options = useMemo(() => {
        return games
            .map((e) => {
                var group = gameGroups.find((g) => g.id === e.group) || {};
                return { value: e.id, label: (group.name || 'Unknown group') + ' - ' + e.name };
            })
            .sort((a, b) => (a.label > b.label ? 1 : -1));
    }, [gameGroups, games]);

    useEffect(() => {
        events.onupdate = function () {
            getNotifications().then(setNotifications).catch(utils.hintError);
        };

        getNotifications().then(setNotifications).catch(utils.hintError);
    }, []);

    let _notifications = [];

    if (notifications.length > 0) {
        _notifications = notifications.filter((e) => !e.read).slice(0, MAX_SHOW_NOTIFICATIONS);
    }

    // const _notifications_render =
    //     _notifications.length > 0 ? _notifications : notifications.slice(0, MAX_SHOW_NOTIFICATIONS);
    const _notifications_render = notifications.slice(0, MAX_SHOW_NOTIFICATIONS);

    const _readAllNotifications = () => {
        readAllNotifications().catch(utils.hintError);
        getNotifications().then(setNotifications).catch(utils.hintError);
    };

    return (
        <>
            <nav className="navbar navbar-expand navbar-dark text-light bg-dark topbar sticky-top shadow px-3">
                {!!game && (
                    <button onClick={props.toogleSidebar} className="btn text-light rounded-circle me-2">
                        <i className="fa fa-bars"></i>
                    </button>
                )}

                <div
                    onClick={() => {
                        history('/');
                    }}
                    role="button"
                    className="brand ms-2"
                >
                    <img alt="Application logo" src={logo} />
                    <span className="d-none d-sm-block ms-2"> Livefire</span>
                </div>

                <div className="w-300px ms-4 me-2 text-dark d-none d-sm-block">
                    <Select
                        options={options}
                        value={options.find((e) => e.value === game.id) || null}
                        placeholder="Select game"
                        className="react-select-sm w-100"
                        classNamePrefix="select"
                        onChange={(e) => {
                            setGame({});
                            // let path = location.pathname.replace('/game/' + game.id, '');
                            history('/game/' + e.value);
                        }}
                    />
                </div>

                <div className="w-25 ms-auto d-none d-sm-block">
                    <div className="input-group input-group-sm">
                        <input
                            type="text"
                            id="global-search-value"
                            defaultValue={searchParams.get('q') || ''}
                            className="form-control"
                            placeholder="Search for resources, docs, products ..."
                        />

                        <button
                            onClick={() => {
                                const searchValue = document
                                    .getElementById('global-search-value')
                                    .value.trim();

                                if (searchValue.length > 2) history('/search?q=' + searchValue);
                            }}
                            className="btn btn-secondary"
                            type="button"
                        >
                            <i className="fas fa-search me-1" /> Search
                        </button>
                    </div>
                </div>

                <ul className="navbar-nav ms-auto">
                    <li className="nav-item dropdown no-arrow mx-1">
                        <div className="nav-link">
                            <ThemeButton />
                        </div>
                    </li>
                    <li className="nav-item dropdown no-arrow mx-1">
                        <div
                            className="nav-link dropdown-toggle"
                            onClick={_readAllNotifications}
                            id="alertsDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="fas fa-bell fa-fw"></i>
                            {_notifications.length > 0 && (
                                <span className="badge bg-danger fw-bold badge-counter">
                                    {notifications.length > _notifications.length
                                        ? _notifications.length + '+'
                                        : _notifications.length}
                                </span>
                            )}
                        </div>
                        <div
                            className="dropdown-list dropdown-menu shadow animated--grow-in overflow-y-auto"
                            style={{ maxHeight: '85vh' }}
                            aria-labelledby="alertsDropdown"
                        >
                            <h6 className="dropdown-header">Alerts Center</h6>

                            {_notifications_render.map((e) => (
                                <NotificationAlertItem key={e.id} data={e} />
                            ))}
                            {notifications.length > 0 && (
                                <a
                                    className="dropdown-item text-center small text-gray-500"
                                    href="#notifications-modal"
                                    data-bs-toggle="modal"
                                    data-bs-target="#notifications-modal"
                                >
                                    SEE ALL ACTIVITIES
                                </a>
                            )}
                            {notifications.length === 0 && (
                                <div className="text-center p-3 small text-gray-500">
                                    NO ANY ACTIVITY FOR THE LAST 1 MONTH
                                </div>
                            )}
                        </div>
                    </li>

                    <li className="nav-item mx-1 d-none d-sm-block">
                        <a
                            className="nav-link"
                            target="_blank"
                            rel="noreferrer"
                            href="https://greenpandagames.atlassian.net/wiki/spaces/RD/pages/2514616332"
                            id="alertsDropdown"
                        >
                            <i className="fa fa-book fa-fw"></i>
                        </a>
                    </li>

                    <li className="nav-item dropdown no-arrow mx-1">
                        <div
                            className="nav-link dropdown-toggle"
                            id="mobileMenuDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i style={{ fontSize: '22px' }} className="fas fa-ellipsis-h"></i>
                        </div>
                        <div
                            className="dropdown-menu shadow animated--grow-in"
                            aria-labelledby="mobileMenuDropdown"
                        >
                            <a
                                className="dropdown-item d-flex align-items-center"
                                href="mailto:livefire-support@greenpandagames.com"
                            >
                                <i className="fa fa-comment fa-sm fa-fw me-2 text-gray-400" />
                                Give Feedback
                            </a>
                            <a
                                className="dropdown-item d-flex align-items-center d-sm-none"
                                target="_blank"
                                rel="noreferrer"
                                href="https://greenpandagames.atlassian.net/wiki/spaces/RD/pages/2514616332"
                            >
                                <i className="fa fa-book fa-sm fa-fw me-2 text-gray-400" />
                                Tutorial
                            </a>
                            <Link className="dropdown-item d-flex align-items-center" to="/docs">
                                <i className="fa fa-book fa-sm fa-fw me-2 text-gray-400" />
                                Docs
                            </Link>
                            <Link className="dropdown-item d-flex align-items-center" to="/changelog">
                                <i className="fas fa-list-alt fa-fw fa-sm me-2 text-gray-400" />
                                Changelog
                            </Link>
                            <hr className="dropdown-divider" />
                            <a
                                className="dropdown-item d-flex align-items-center"
                                target="_blank"
                                rel="noreferrer"
                                href="https://app.slack.com/client/T03DY5L8Y/C053SKRAJDN"
                            >
                                <i className="fas fa-comment-alt fa-sm fa-fw me-2 text-gray-400" />
                                Chat
                            </a>
                            <a
                                className="dropdown-item"
                                href="#contact-us-modal"
                                data-bs-toggle="modal"
                                data-bs-target="#contact-us-modal"
                            >
                                <i className="fas fa-headset fa-sm fa-fw me-2 text-gray-400"></i>
                                Support
                            </a>
                            <a
                                className="dropdown-item d-flex align-items-center"
                                target="_blank"
                                rel="noreferrer"
                                href="https://greenpandagames.atlassian.net/wiki/spaces/RD/pages/2523070526"
                            >
                                <i className="fas fa-fire fa-fw fa-sm me-2 text-gray-400" />
                                About Livefire v{Version}
                            </a>
                        </div>
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li className="nav-item dropdown no-arrow">
                        <div
                            className="nav-link dropdown-toggle"
                            id="userDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <div className="me-3 d-none d-sm-block">
                                <span>{name || email}</span>
                            </div>
                            <img className="img-profile rounded-circle" src={picture || profileIcon} alt="" />
                        </div>
                        <div
                            className="dropdown-menu shadow animated--grow-in"
                            aria-labelledby="userDropdown"
                        >
                            <a className="dropdown-item" href={provider + '/profile'}>
                                <i className="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i>
                                Profile
                            </a>
                            <a className="dropdown-item" href={provider + '/account/settings'}>
                                <i className="fas fa-cogs fa-sm fa-fw me-2 text-gray-400"></i>
                                Settings
                            </a>
                            <a className="dropdown-item" href={provider + '/account/settings/password'}>
                                <i className="fas fa-key fa-sm fa-fw me-2 text-gray-400"></i>
                                Change Password
                            </a>
                            <a className="dropdown-item" href={provider + '/organization/members'}>
                                <i className="fas fa-users fa-sm fa-fw me-2 text-gray-400"></i>
                                Organization
                            </a>
                            <hr className="dropdown-divider" />
                            <a className="dropdown-item" href={provider}>
                                <i className="fas fa-home fa-sm fa-fw me-2 text-gray-400"></i>
                                Dashboard
                            </a>
                            <hr className="dropdown-divider" />
                            <a
                                className="dropdown-item"
                                href="#logoutModal"
                                data-bs-toggle="modal"
                                data-bs-target="#logoutModal"
                            >
                                <i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Topbar;

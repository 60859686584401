import { useQuery } from '@tanstack/react-query';
import { useGame } from 'components';
import { getStorageAsset } from 'services/storage';

export function useFileVersionsMap(path) {
    const game = useGame();
    const gameId = game.id;

    const { isFetching, error, data } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/')],
        queryFn: () => getStorageAsset(gameId, path),
        enabled: !!(gameId && path),
        select: (data) =>
            data.versions.reduce((prev, val) => {
                prev[val.id] = val;
                return prev;
            }, {})
    });

    const versions = data || {};

    return { versions, isFetching, error };
}

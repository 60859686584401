import { useTable } from 'react-table';

export const SimpleTable = (props) => {
    const { columns, data, loading } = props;

    const options = {
        columns,
        data,
        disableSortRemove: true
    };

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(options);

    const onClick = (value) => {
        props.onClick && props.onClick(value);
    };

    return (
        <div className={'datatable' + (loading ? ' disabled' : '')}>
            <div className="row datatable-row">
                <div className="col-sm-12">
                    <table
                        {...getTableProps({
                            className: 'table w-100' + (props.className ? ' ' + props.className : '')
                        })}
                    >
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps({
                                                style: { width: column.size && column.size + 'px' },
                                                className: column.headerClass + ' fw-normal'
                                            })}
                                            {...column._columnAttributes}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        onClick={() => onClick(row.original)}
                                        {...row.original._rowAttributes}
                                        {...row.getRowProps()}
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        scope: 'row',
                                                        className: cell.column.cellClass
                                                    })}
                                                    onClick={() =>
                                                        cell.column.onCellClick &&
                                                        cell.column.onCellClick(cell)
                                                    }
                                                    {...cell.column._columnAttributes}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

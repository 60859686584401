import { date } from '@gpg-web/utils';
import { useQuery } from '@tanstack/react-query';
import { SimpleTable, StatusLabel, useComponent, useGame } from 'components';
import { ComponentsMap } from 'consts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getConfigs } from 'services/config';

const experimentComponent = ComponentsMap['experiment'];

const columns = [
    {
        Header: 'Scheduler',
        accessor: 'displayScheduler',
        headerClass: 'd-none'
    },
    {
        Header: 'Experiment',
        accessor: 'displayName',
        cellClass: 'text-nowrap',
        headerClass: 'd-none'
    },
    {
        Header: 'Start',
        accessor: 'displayStart',
        headerClass: 'd-none'
    },
    {
        Header: 'End',
        accessor: 'displayEnd',
        headerClass: 'd-none'
    }
];

export const ExperimentPreview = () => {
    const [shown, setShown] = useState(false);
    const dropdownRef = useRef();

    const game = useGame();
    const gameId = game.id;

    const component = useComponent();
    const { configId } = useParams();

    const {
        isLoading,
        data = [],
        error
    } = useQuery({
        queryKey: [experimentComponent.url, gameId],
        queryFn: () => getConfigs(gameId, experimentComponent.url),
        enabled: !!gameId,
        select: (data) =>
            data.filter(
                (experiment) =>
                    experiment.status === 'live' &&
                    experiment.configs.find((c) => c.component === component.id && c.id === configId)
            )
    });

    useEffect(() => {
        if (!dropdownRef.current) return;

        function reset() {
            setShown(false);
        }

        const el = dropdownRef.current;
        el.addEventListener('hidden.bs.dropdown', reset);

        return () => {
            el.removeEventListener('hidden.bs.dropdown', reset);
        };
    }, []);

    const list = useMemo(() => {
        if (!data) return [];

        let now = new Date();

        return data
            .map((e, i) => {
                const status = e.status;

                let start = null;
                let end = null;

                if (e.time && e.time.data) {
                    if (e.time.data.start) {
                        start = new Date(e.time.data.start);
                        if (!e.time.global) {
                            start.setTime(start.getTime() + start.getTimezoneOffset() * 60 * 1000);
                        }
                    }
                    if (e.time.data.end) {
                        end = new Date(e.time.data.end);
                        if (!e.time.global) {
                            end.setTime(end.getTime() + end.getTimezoneOffset() * 60 * 1000);
                        }
                    }
                }

                let scheduler = status;

                let order = 0;

                if (status === 'live') {
                    if (start && end) {
                        if (now >= end) {
                            if (status === 'disabled') {
                                scheduler = 'stopped';
                                order = 2;
                            } else {
                                scheduler = 'finished';
                                order = 3;
                            }
                        } else if (now >= start) {
                            if (status === 'live') {
                                scheduler = 'ongoing';
                                order = 5;
                            } else {
                                scheduler = status;
                                order = 1;
                            }
                        } else {
                            scheduler = 'pending';
                            order = 4;
                        }
                    } else {
                        scheduler = 'error';
                    }
                }

                return {
                    id: e.id,
                    displayName: (
                        <div>
                            <Link
                                className="text-decoration-none"
                                to={
                                    '/game/' + gameId + '/config/' + experimentComponent.url + '/view/' + e.id
                                }
                            >
                                {e.id}
                            </Link>
                            <div className="small text-muted">{e.name}</div>
                        </div>
                    ),
                    displayStart: start && (
                        <>
                            {date(start, 'date')}
                            <span className="small">{e.time.global && ' (GMT)'}</span>
                        </>
                    ),
                    displayEnd: end && (
                        <>
                            {date(end, 'date')}
                            <span className="small">{e.time.global && ' (GMT)'}</span>
                        </>
                    ),
                    displayScheduler: scheduler && status !== 'archive' && <StatusLabel value={scheduler} />,
                    order: order
                };
            })
            .sort((a, b) => b.order - a.order);
    }, [gameId, data]);

    if (isLoading || data.length === 0 || error) return null;

    return (
        <div className="dropdown no-arrow me-2" ref={dropdownRef}>
            <button
                type="button"
                title="Experiments preview"
                onClick={() => setShown(!shown)}
                className="btn btn-light rounded-circle dropdown-toggle position-relative text-primary"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
            >
                <i className="fas fa-flask" />

                <span class="position-absolute translate-middle badge badge-sm rounded-pill bg-light border text-primary top-40 start-80">
                    {data.length}
                    <span class="visually-hidden">live experiments that include this config</span>
                </span>
            </button>
            <div
                className="dropdown-menu p-4 dropdown-menu-end shadow"
                style={{ width: '550px', maxHeight: '400px', overflowY: 'auto' }}
            >
                <h6 className="mb-2">This config is included in the following live experiments:</h6>
                <div className="ps-2">
                    <SimpleTable loading={isLoading} columns={columns} data={list} className="" />
                </div>
            </div>
        </div>
    );
};

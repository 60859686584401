import { useState, useEffect } from 'react';
import { Edit } from './Edit';
import { View } from './View';
import { toJSON, languageName } from './Utils';
import { utils } from '@gpg-web/utils';
import { ButtonFileInput } from 'components';
import { FileImporter } from './FileImporter';
import { FileExporter } from './FileExporter';
import { ImportFromGoogleDrive } from './ImportFromGoogleDrive';

export const TranslationView = (props) => {
    const [languages, setLanguages] = useState(null);
    const [json, setJSON] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    useEffect(() => {
        if (typeof props.value !== 'string') {
            setLanguages(null);
            setJSON(null);
            return;
        }

        const langs = {};

        const result = toJSON(props.value, langs);

        const languages = Object.keys(langs).map((key) => {
            return { value: key, label: languageName(key) };
        });

        setLanguages(languages);
        setJSON(result);
    }, [props.value]);

    return props.editing ? (
        <Edit
            {...props}
            content={json}
            languages={languages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
        />
    ) : (
        <View
            {...props}
            content={json}
            languages={languages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
        />
    );
};

TranslationView.Toolbar = function Toolbar({ onUpload, content, path, versionId }) {
    const [json, setJSON] = useState(null);

    const acceptNewFile = async (file) => {
        try {
            const parsed = await FileImporter(file);
            onUpload(parsed.content);
        } catch (err) {
            utils.hintError(err.message || err);
        }
    };

    useEffect(() => {
        if (typeof content !== 'string') return;

        setJSON(toJSON(content));
    }, [content]);

    const fileName = path ? utils.baseName(path) : 'new';
    const exportName = versionId + '-' + fileName;

    return (
        <>
            <div className="dropdown no-arrow">
                <button
                    disabled={!json || !path}
                    className="btn btn-sm btn-secondary rounded-pill shadow px-3"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <i className="fa fa-sm fa-download me-1" /> Export
                </button>

                <div className="dropdown-menu shadow" style={{ width: '300px' }}>
                    <div className="dropdown-header text-center mb-2 border-bottom">
                        <strong>Select file format</strong>
                    </div>
                    <div>
                        <button
                            onClick={() => FileExporter(json, 'xlsx', exportName)}
                            className="btn dropdown-item"
                        >
                            Microsoft Excel (.xlsx)
                        </button>
                        <button
                            onClick={() => FileExporter(json, 'ods', exportName)}
                            className="btn dropdown-item"
                        >
                            OpenDocument (.ods)
                        </button>
                        {/* <button
                                    onClick={() => FileExporter(json, 'html', exportName)}
                                    className="btn dropdown-item"
                                >
                                    Web Page (.html)
                                </button> */}
                        <button
                            onClick={() => FileExporter(json, 'json', exportName)}
                            className="btn dropdown-item"
                        >
                            JSON (.json)
                        </button>
                        <button
                            onClick={() => FileExporter(json, 'json-table', exportName)}
                            className="btn dropdown-item"
                        >
                            JSON Spreadsheet (.json)
                        </button>
                        {/* <button
                                    onClick={() => FileExporter(json, 'csv', exportName)}
                                    className="btn dropdown-item"
                                >
                                    Comma Separated Values (.csv)
                                </button> */}
                        <button
                            onClick={() => FileExporter(json, 'txt', exportName)}
                            className="btn dropdown-item"
                        >
                            Source Text (.txt)
                        </button>
                    </div>
                </div>
            </div>

            <ButtonFileInput
                title="Import from file"
                accept="application/json, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet, text/plain"
                className="rounded-pill px-3 ms-2"
                onAccept={acceptNewFile}
            />

            <div className="dropdown no-arrow ms-2">
                <button
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    className="btn btn-sm btn-primary rounded-pill shadow px-3"
                >
                    <i className="fab fa-google-drive me-1" /> Import from Google Drive
                </button>
                <div className="dropdown-menu dropdown-menu-lg-end shadow" style={{ width: '500px' }}>
                    <ImportFromGoogleDrive hasCancel={false} onAccept={acceptNewFile} />
                </div>
            </div>
        </>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { Link } from 'react-router-dom';

const NavTab = (props) => {
    const { gameId, tab } = props;

    return (
        <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
                <Link
                    to={'/game/' + gameId + '/players'}
                    className={'nav-link' + (tab === 'players' ? ' active disabled' : '')}
                >
                    Players
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    to={'/game/' + gameId + '/save-type'}
                    className={'nav-link' + (tab === 'save-type' ? ' active disabled' : '')}
                >
                    Save Types
                </Link>
            </li>
            {/* <li className="nav-item">
                <Link
                    to={'/game/' + gameId + '/welcome-popup'}
                    className={'nav-link' + (tab === 'welcome-popup' ? ' active disabled' : '')}
                >
                    Welcome Popup
                </Link>
            </li> */}
            {/* <li className="nav-item">
                <Link
                    to={'/game/' + gameId + '/popup'}
                    className={'nav-link' + (tab === 'popup' ? ' active disabled' : '')}
                >
                    Popup
                </Link>
            </li> */}
        </ul>
    );
};

export default NavTab;

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { utils } from '@gpg-web/utils';
import { InputOption, Alert } from 'components';
import { addGameGroup } from 'services/game';
import { Modal, Spinner } from '@gpg-web/react';

const AddNewGameGroup = (props) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const handleChangeRaw = (e) => {
        const target = e.target;
        let value;

        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type === 'file') {
            value = target.files[0];
        } else {
            value = target.value;
        }

        const name = target.name;

        setData({ ...data, [name]: value });
    };

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const _addGame = () => {
        data.name = data.name.trim();
        data.id = data.id.trim();

        if (!data.id) return setError('ID is required');
        if (!utils.isValidGameId(data.id)) {
            return setError(
                'ID is not valid. a-z, A-Z, 0-9, underscore and dash are only allowed. ID should not starts and ends with dash.'
            );
        }
        if (!data.name) return setError('Name is required');

        setError(null);
        setLoading(true);

        const formData = new FormData();

        for (let key in data) {
            formData.append(key, data[key]);
        }

        // formData.append('picture', picture);

        addGameGroup(formData)
            .then((result) => {
                setData({});
                setLoading(false);
                props.onAdd(result);
                setShow(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    function reset() {
        setShow(false);
        setData({});
        setError(null);
        setLoading(false);
    }

    return (
        <>
            <button className="btn btn-sm btn-secondary" onClick={() => setShow(true)}>
                <i className="fas me-2 fa-plus" />
                New game
            </button>
            <Modal show={show} title="New game" onHide={reset}>
                <div className="modal-body">
                    <InputOption
                        name="id"
                        tooltip={
                            <>
                                REQUIRED
                                <br />
                                Please provide an unique game group id.
                                <br />
                                For example: "my-game".
                            </>
                        }
                        title="ID"
                        required
                        value={data.id}
                        onChange={handleChange}
                    />
                    <InputOption name="name" title="Name" value={data.name} onChange={handleChange} />
                    <div className="mb-3">
                        <label className="mb-1 small">Picture</label>
                        <input
                            accept="image/*"
                            type="file"
                            name="picture"
                            onChange={handleChangeRaw}
                            className="form-control"
                        />
                    </div>
                    {error && <Alert text={error} />}
                </div>
                <div className="modal-footer">
                    <button disabled={loading} onClick={_addGame} className="btn btn-success">
                        {loading && <Spinner />} <i className="fas fa-check me-1" /> Create Game
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default AddNewGameGroup;

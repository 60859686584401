/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProfile, getPlayerSaves, getProfilePlayerSave } from 'services/players';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import { useGame } from 'components';
import profileIcon from '../../../img/undraw_profile.svg';
import PlayerSave from './PlayerSave';
import NavTab from './NavTab';
import KeyOptionsSetup from './KeyOptionsSetup';

const ProfilePage = (props) => {
    const [profile, setProfile] = useState(null);
    const [saveTypes, setSaveTypes] = useState(null);
    const [saves, setSaves] = useState({});

    const { profileId } = useParams();

    const game = useGame();
    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        utils.popup('loading');

        getPlayerSaves(gameId)
            .then((saveTypes) => {
                setSaveTypes(saveTypes);

                getProfile(gameId, profileId)
                    .then((profile) => {
                        setProfile(profile);
                        utils.popup('hide');

                        saveTypes.forEach((saveType) => {
                            getProfilePlayerSave(gameId, profile.id, saveType.id)
                                .then((save) => {
                                    setSaves((saves) => {
                                        saves[saveType.id] = save;
                                        return { ...saves };
                                    });
                                })
                                .catch((err) => {
                                    setSaves((saves) => {
                                        saves[saveType.id] = err;
                                        return { ...saves };
                                    });
                                });
                        });
                    })
                    .catch(utils.hintError);
            })
            .catch(utils.hintError);
    }, [profileId, gameId]);

    // const handleChange = (e) =>
    // {
    //     const target = e.target;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     const name = target.name;

    //     setData({...data, [name]: value });
    // }

    return (
        <>
            <NavTab gameId={gameId} tab="players" />

            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4>Players</h4>
                </div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small">
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId}>Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={'/game/' + gameId + '/players'}>Players</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {profileId}
                        </li>
                    </ol>
                </nav>

                {!!profile && (
                    <>
                        <div>
                            <ul className="nav nav-tabs nav-fill">
                                <li className="nav-item">
                                    <button
                                        className="nav-link active"
                                        id="player-overview-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#player-overview"
                                        type="button"
                                        role="tab"
                                        aria-controls="player-overview"
                                        aria-selected="true"
                                    >
                                        Overview
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className="nav-link"
                                        id="player-statistics-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#player-statistics"
                                        type="button"
                                        role="tab"
                                        aria-controls="player-statistics"
                                        aria-selected="false"
                                    >
                                        Statistics
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className="nav-link"
                                        id="player-data-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#player-data"
                                        type="button"
                                        role="tab"
                                        aria-controls="player-data"
                                        aria-selected="false"
                                    >
                                        Player Saves
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content mt-3" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="player-overview"
                                    role="tabpanel"
                                    aria-labelledby="player-overview-tab"
                                >
                                    <div className="row pt-4">
                                        <div className="col-md-2 col-sm-12 text-center">
                                            <img
                                                style={{ maxHeight: '120px' }}
                                                alt="profile avatar"
                                                src={profileIcon}
                                            />
                                        </div>
                                        <div className="col-md-10 col-sm-12">
                                            <div className="h3 text-muted mt-3 mb-1">Unknown</div>
                                            <div className="mb-3">{profile.id}</div>
                                            <div className="text-secondary">
                                                Last Sign In: {profile.lastSignInTime}
                                            </div>
                                        </div>
                                    </div>
                                    <KeyOptionsSetup
                                        profile={profile}
                                        saveTypes={saveTypes}
                                        saves={saves}
                                        setSaves={setSaves}
                                    />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="player-statistics"
                                    role="tabpanel"
                                    aria-labelledby="player-statistics-tab"
                                >
                                    Statistics page
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="player-data"
                                    role="tabpanel"
                                    aria-labelledby="player-data-tab"
                                >
                                    <PlayerSave
                                        profile={profile}
                                        saveTypes={saveTypes}
                                        saves={saves}
                                        setSaves={setSaves}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {!profile && (
                    <div className="row">
                        <div className="col-md-2 col-sm-12">
                            <Skeleton className="service-card shadow-sm m-2 mx-1 mx-sm-2" height={180} />
                        </div>
                        <div className="col-md-10 col-sm-12">
                            <Skeleton
                                className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                                width="60%"
                                height={60}
                            />
                            <Skeleton
                                className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                                width="80%"
                                height={60}
                            />
                            <Skeleton
                                className="service-card shadow-sm m-2 mx-1 mx-sm-2"
                                width="50%"
                                height={30}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ProfilePage;

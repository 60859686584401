/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getConfigs, removeConfig, duplicateConfig, migrateConfig } from 'services/config';
import {
    ProcessBlockedWrapper,
    IAMButton,
    ComponentHeader,
    useGame,
    DeleteButton,
    Product,
    CardsContainer
} from 'components';
import { useComponent } from '../hooks';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import { SetupScriptModal } from '../../automation/scripts';
import { ProductCreateModal } from './CreateModal';
import { ProductEditModal } from './EditModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const ProductsList = (props) => {
    const component = useComponent('product');

    const [migrating, setMigrating] = useState(false);
    const [filter, setFilter] = useState('');
    const game = useGame();
    const navigate = useNavigate();
    const { configId } = useParams();

    const gameId = game.id;

    const queryClient = useQueryClient();
    let {
        data: configs,
        isFetching,
        error,
        refetch
    } = useQuery({
        queryKey: [component.id, gameId],
        queryFn: () => getConfigs(gameId, component.url),
        enabled: !!(gameId && component.url)
    });

    configs = configs || [];

    if (filter && filter.length > 2) {
        let _filter = filter.toLowerCase();
        configs = configs.filter((e) => (e.id + e.type + e.name).toLowerCase().includes(_filter));
    }

    const _migrateConfig = async (gameId, scriptId, options) => {
        options.componentId = component.id;
        const result = await migrateConfig(gameId, options, scriptId);
        return result;
    };

    const _editConfig = (e) => {
        const configId = e.target.getAttribute('data-id');

        navigate('/game/' + gameId + '/config/' + component.url + '/view/' + configId);
    };

    const _removeConfig = (e) => {
        const configId = e.target.getAttribute('data-id');

        utils.confirm(
            "Are you sure you want to remove this product? Make sure you don't use it on another configs.",
            (yes) => {
                if (!yes) return;

                utils.popup('removing');

                removeConfig(gameId, component.url, configId)
                    .then(() => {
                        refetch();

                        utils.popup('hide');
                        utils.hintOk('Config has been deleted');
                    })
                    .catch(utils.hintError);
            }
        );
    };

    const _duplicateConfig = (e) => {
        const srcId = e.target.getAttribute('data-id');

        utils.promt(
            'Please specify a new "' + srcId + '" ID',
            (dstId) => {
                if (dstId === false) return;

                dstId = dstId.trim();

                if (!dstId) return utils.hintError(srcId + ' ID is required.');

                if (!utils.isValidId(dstId)) {
                    return utils.hintError('ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed');
                }

                utils.popup('Duplicating ...');

                duplicateConfig(gameId, component.url, { srcId, dstId })
                    .then(() => {
                        refetch();

                        utils.popup('hide');
                    })
                    .catch(utils.hintError);
            },
            {
                value: srcId + '2',
                label: 'New product ID',
                placeholder: 'ID *'
            }
        );
    };

    return (
        <>
            <div className="container-lg">
                <ComponentHeader gameId={gameId} id={component.id}>
                    <IAMButton permissions={['component.config.create']}>
                        <ProcessBlockedWrapper component={component.id}>
                            <Link
                                to={'/game/' + gameId + '/config/' + component.url + '/create'}
                                className="btn rounded-pill btn-success shadow px-3"
                            >
                                <i className="fa fa-sm fa-plus" /> Add New
                            </Link>
                        </ProcessBlockedWrapper>
                    </IAMButton>
                    <div className="ms-3">
                        <IAMButton permissions={['component.config.migrate', 'component.storage.migrate']}>
                            <ProcessBlockedWrapper component={component.id}>
                                <button
                                    onClick={() => setMigrating(true)}
                                    className="btn btn-primary rounded-pill px-3 shadow"
                                >
                                    <i className="fas fa-angle-double-down me-1" /> Migrate From
                                </button>
                            </ProcessBlockedWrapper>
                        </IAMButton>
                    </div>
                </ComponentHeader>

                <div className="mt-2 d-flex align-items-center">
                    <Link className="" to={'/game/' + gameId + '/config/stores'}>
                        Setup stores
                    </Link>
                    <Link className="ms-3" to={'/game/' + gameId + '/config/entities'}>
                        Setup entities
                    </Link>
                    <div className="input-group w-300px ms-auto">
                        <input
                            className="form-control border-end-0 border rounded-pill"
                            type="search"
                            placeholder="Search"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        <span className="input-group-append">
                            <div
                                style={{ marginLeft: '-40px', zIndex: 40 }}
                                className="btn btn-light border-start-0 border-bottom-0 rounded-pill border"
                            >
                                <i className="fa fa-search" />
                            </div>
                        </span>
                    </div>
                </div>

                <ProductCreateModal
                    show={props.creating && gameId}
                    onHide={() => navigate('/game/' + gameId + '/config/' + component.url)}
                />

                <ProductEditModal
                    show={props.editing && gameId && configId}
                    configId={configId}
                    onHide={() => navigate('/game/' + gameId + '/config/' + component.url)}
                />

                <SetupScriptModal
                    show={migrating}
                    scriptId="simpleConfigMigrate"
                    onFinish={() => {
                        queryClient.removeQueries({
                            queryKey: [component.id, gameId],
                            exact: true
                        });

                        queryClient.removeQueries({
                            queryKey: ['storage', gameId]
                        });
                    }}
                    onHide={() => setMigrating(false)}
                    customFn={_migrateConfig}
                    game={game}
                />

                {error && <div className="alert alert-danger mt-3">{error}</div>}

                <div className="row mt-4">
                    {!isFetching && (
                        <CardsContainer
                            className="col-xl-4 col-lg-6 col-md-12"
                            list={configs}
                            card={(data) => {
                                return (
                                    <Product
                                        gameId={gameId}
                                        data={data}
                                        key={data.id}
                                        className="mb-3"
                                        actions={
                                            <>
                                                <li>
                                                    <button
                                                        data-id={data.id}
                                                        onClick={_editConfig}
                                                        className="dropdown-item"
                                                        type="button"
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                                <li>
                                                    <DeleteButton
                                                        left={40}
                                                        top={30}
                                                        createdAt={data.createdAt}
                                                        createdBy={data.createdBy}
                                                        deletePermission={'component.config.delete'}
                                                    >
                                                        <button
                                                            data-id={data.id}
                                                            onClick={_removeConfig}
                                                            className="dropdown-item"
                                                            type="button"
                                                        >
                                                            Delete
                                                        </button>
                                                    </DeleteButton>
                                                </li>
                                                <li>
                                                    <button
                                                        data-id={data.id}
                                                        onClick={_duplicateConfig}
                                                        className="dropdown-item"
                                                        type="button"
                                                    >
                                                        Duplicate
                                                    </button>
                                                </li>
                                            </>
                                        }
                                    />
                                );
                            }}
                        />
                    )}
                </div>

                {filter.length > 2 && configs.length === 0 && (
                    <div className="mt-5 text-center">
                        <i className="fas fa-search fa-sm me-2" /> Nothing has been found
                    </div>
                )}

                {!isFetching && configs.length === 0 && !filter && (
                    <div className="mt-5 text-center">
                        <ProcessBlockedWrapper component={component.id}>
                            <Link
                                to={'/game/' + gameId + '/config/' + component.url + '/create'}
                                className="btn rounded-pill btn-light shadow px-3"
                            >
                                <i className="fas fa-plus fa-sm me-2" /> Create first game product
                            </Link>
                        </ProcessBlockedWrapper>
                    </div>
                )}

                {!isFetching && configs.length > 1 && (
                    <sub className="text-gray-500">{configs.length} products</sub>
                )}

                {isFetching && (
                    <div className="mt-4 row">
                        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                            <Skeleton className="m-2 mx-1 mx-sm-2" count={2} height={250} />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                            <Skeleton className="m-2 mx-1 mx-sm-2" count={2} height={250} />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                            <Skeleton className="m-2 mx-1 mx-sm-2" count={1} height={250} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useContext, useRef, useEffect } from 'react';
import {
    getStorageAsset,
    uploadStorageFile,
    getStorageFileContent,
    downloadStorageFile
} from 'services/storage';
import { userContext } from 'services/user';
import { TextMimes } from 'consts';
import { utils } from '@gpg-web/utils';
import { useGame } from 'components';
import { Spinner, DropZone } from '@gpg-web/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UnityAddressablesView } from 'pages/game/storage/views/UnityAddressables';
import { VersionSmall } from 'pages/game/storage/FileVersions';
import { parseAddressablesCatalog } from './parseAddressablesCatalog';

const AddressablesFileView = (props) => {
    const { version, onSelect, readOnly, defaultFile, gameVersion } = props;

    const path = props.path || '';

    const user = useContext(userContext);
    const game = useGame();
    const gameId = game.id;

    const queryClient = useQueryClient();

    const { isFetching, error, data, isFetched } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/')],
        queryFn: () => getStorageAsset(gameId, path),
        enabled: !!(gameId && path)
    });

    let file = null;

    if (isFetched) {
        if (data) {
            file = data;
        } else if (defaultFile) {
            file = defaultFile;
        }
    }

    let previewType = null;

    if (file) {
        if (file.mime === 'text/html') previewType = 'html';
        else if (file.mime === 'application/json') previewType = 'json';
        else if (TextMimes.includes(file.mime)) previewType = 'text';
    }

    const [versionsBtn, setVersionsBtn] = useState(null);

    const setSelectedVersion = (version) => {
        onSelect({
            path: path,
            version: version.id
        });

        versionsBtn && versionsBtn.hide();
    };
    const selectedVersion = file && file.versions && file.versions.find((v) => v.id === version);

    let versions = file?.versions || [];

    if (!isFetching && !!file) {
        versions = file.versions;

        versions = versions.filter((e) => !e.archived && !e.isDraft);
    }

    const versionId = selectedVersion?.id;

    let {
        isFetched: contentLoaded,
        isFetching: contentLoading,
        // error: fileContentError,
        data: loadedFileContent
    } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/'), versionId, 'content'],
        queryFn: () => getStorageFileContent(gameId, path, versionId),
        enabled: !!(gameId && path && file && versionId)
    });

    let fileContent = null;

    if (loadedFileContent || loadedFileContent === '') fileContent = loadedFileContent;

    const isContentEmpty = !versionId || (contentLoaded && !fileContent);

    const [uploading, setUploading] = useState(null);

    const onUploadAddressables = async (files) => {
        try {
            const baseUrl = (url) => {
                const baseUrl = url.replace('https', '').replace('http', '');
                return baseUrl;
            };

            versionsBtn && versionsBtn.hide();

            setUploading({ current: 0, total: files.length + 1, message: 'Validating catalog' });

            const catalogIndex = files.findIndex(
                (f) => f.name.includes('catalog') && f.name.endsWith('.json')
            );

            const catalogHashIndex = files.findIndex(
                (f) => f.name.includes('catalog') && f.name.endsWith('.hash')
            );

            if (catalogIndex === -1) throw new Error('Catalog json is missing');

            if (catalogHashIndex === -1) throw new Error('Catalog hash is missing');

            const catalogMap = await parseAddressablesCatalog(files[catalogIndex], game.backend_api_url);
            const catalogFiles = Object.keys(catalogMap);

            console.log(catalogMap);

            if (Object.keys(catalogFiles).length === 0) throw new Error('Bundle files not found');

            const baseBackendUrl = baseUrl(game.backend_api_url);

            const uploadUrl = catalogMap[catalogFiles[0]]
                .replace('http' + baseBackendUrl + '/hosting/', '')
                .replace('https' + baseBackendUrl + '/hosting/', '')
                .replace(catalogFiles[0], '');
            console.log(uploadUrl);

            let isFileMissing = false;

            for (let catalogFile of catalogFiles) {
                if (!files.find((f) => f.name === catalogFile)) {
                    utils.hintError(catalogFile + ' is missing');
                    isFileMissing = true;
                }
            }

            if (isFileMissing) throw new Error('Some files are missing');

            const uploadMap = {
                [files[catalogIndex].name]: uploadUrl,
                [files[catalogHashIndex].name]: uploadUrl,
                ...catalogMap
            };

            const uploadFiles = Object.keys(uploadMap);

            if (uploadFiles.length === 0) throw new Error('No catalog files found');

            const uploaded = [];

            for (let uploadFileName of uploadFiles) {
                try {
                    const uploadFile = files.find((f) => f.name === uploadFileName);

                    setUploading((prev) => ({
                        current: prev.current + 1,
                        total: uploadFiles.length + 1,
                        message: uploadFileName
                    }));
                    console.log(
                        uploadMap[uploadFileName]
                            .replace('http' + baseBackendUrl + '/hosting/', '')
                            .replace('https' + baseBackendUrl + '/hosting/', '')
                            .replace(uploadFileName, '')
                    );
                    const uploadedData = await uploadAddressableFile(
                        gameId,
                        uploadFile,
                        uploadMap[uploadFileName]
                            .replace('http' + baseBackendUrl + '/hosting/', '')
                            .replace('https' + baseBackendUrl + '/hosting/', '')
                            .replace(uploadFileName, ''),
                        user,
                        'Uploaded for ' + gameVersion + ' via Unity-Adrressables'
                    );

                    uploaded.push({
                        path: uploadedData.data.path + uploadedData.data.name,
                        version: uploadedData.version
                    });
                } catch (err) {
                    throw new Error('Unable to upload ' + uploadFileName + ': ' + err.message);
                }
            }

            try {
                const blob = new Blob([JSON.stringify(uploaded)], { type: file.mime });

                blob.name = file.name;

                setUploading((prev) => ({
                    current: prev.current + 1,
                    total: uploadFiles.length + 1,
                    message: file.name
                }));

                const message = await utils.promt(
                    'Are you sure you want to upload a new version of ' +
                        props.title +
                        ' Unity Addressables?',
                    undefined,
                    { value: '', label: 'Version description', placeholder: file.name + ' updated' }
                );

                if (message !== false) {
                    const uploadData = await uploadAddressableFile(
                        gameId,
                        blob,
                        file.path,
                        user,
                        message || '',
                        file.contentType,
                        (exists, versionId) => {
                            utils.hintOk(
                                exists
                                    ? 'This Unity Addressables already exists. (Version #' + versionId + ')'
                                    : 'Unity Addressables were successfully uploaded.'
                            );
                        }
                    );

                    queryClient.invalidateQueries({
                        queryKey: ['storage', gameId, ...path.split('/')],
                        exact: true
                    });

                    Object.assign(file, uploadData.data);

                    setSelectedVersion(uploadData.data.versions.find((v) => v.id === uploadData.version));
                }

                setUploading(null);
            } catch (err) {
                throw new Error('Unable to upload ' + file.name + ': ' + err.message);
            }
        } catch (err) {
            setUploading(null);

            utils.hintError(err.message);
        }
    };

    return (
        <div className="position-relative">
            {!!uploading && (
                <>
                    <div className="position-absolute rounded w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                        <div>
                            <Spinner size={'5rem'} width={'0.5rem'} />
                        </div>
                        <div>
                            Uploading... [{uploading.current}/{uploading.total}]
                        </div>
                        <div className="small font-monospace text-muted">{uploading.message}</div>
                    </div>

                    <div className="mt-3 storage-view-box"></div>
                </>
            )}

            {!uploading && (
                <div>
                    <div className="d-flex align-items-center">
                        <div className="small me-2">{!versionId ? '🔴' : '🟢'}</div>
                        {props.title}
                        {!!versionId && (
                            <div className="ms-2 small text-muted bg-primary-subtle px-3 py-1 rounded-2 fst-italic">
                                #{versionId} selected
                                <i className="fas fa-info-circle fa-sm ms-2" />
                            </div>
                        )}
                        {!versionId && (
                            <div className="ms-2 small text-muted bg-warning-subtle px-3 py-1 rounded-2 fst-italic">
                                Unity addressables are disabled
                                <i className="fas fa-info-circle fa-sm ms-2" />
                            </div>
                        )}
                        <div className="ms-auto d-flex">
                            <div>
                                {!!versionId && (
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={() => setSelectedVersion({ id: null })}
                                    >
                                        Discard
                                    </button>
                                )}
                            </div>
                            <VersionListDropdown
                                setController={setVersionsBtn}
                                versions={versions}
                                selectedVersionId={version}
                                onSelect={setSelectedVersion}
                                gameId={gameId}
                                readOnly={readOnly}
                                filePath={file ? file.path + file.name : ''}
                            />
                        </div>
                    </div>
                    {!isFetching && !contentLoading && !!file && (
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                {isContentEmpty ? (
                                    <div className="mt-3 storage-view-box d-flex">
                                        <DropZone onAccept={onUploadAddressables} />
                                    </div>
                                ) : (
                                    <DragAndDropWrapper onAccept={onUploadAddressables}>
                                        <div className="py-2">
                                            {previewType !== null && (
                                                <UnityAddressablesView
                                                    fluid={false}
                                                    initialValue={fileContent}
                                                    value={fileContent}
                                                    editing={false}
                                                    error={error}
                                                    path={path}
                                                    onChange={() => {}}
                                                    fileName={file.name}
                                                    versionId={versionId}
                                                    gameVersion={props.gameVersion}
                                                    environment={props.environment}
                                                />
                                            )}
                                        </div>
                                    </DragAndDropWrapper>
                                )}
                            </div>
                        </div>
                    )}

                    {(isFetching || contentLoading) && (
                        <div className="d-flex my-5 align-items-center justify-content-center">
                            <Spinner size="100px" width="8px" />
                        </div>
                    )}

                    {error && !file && <div className="alert alert-danger">{error}</div>}
                </div>
            )}
        </div>
    );
};

export { AddressablesFileView };

const VersionListDropdown = ({
    versions,
    selectedVersionId,
    gameId,
    filePath,
    readOnly,
    onSelect,
    setController
}) => {
    const versionsBtnRef = useRef(null);

    useEffect(() => {
        const dom = versionsBtnRef.current;

        const dropdown = new window.bootstrap.Dropdown(dom);
        setController(dropdown);

        return () => {
            dropdown.dispose();
        };
    }, [setController]);

    const downloadVersion = (versionId) => {
        versionId = versionId !== undefined ? versionId : selectedVersionId;
        downloadStorageFile(gameId, filePath, versionId);
    };
    const versionProps = {
        onSelect: onSelect,
        canSelect: !readOnly,
        onDownload: downloadVersion,
        canCompare: false
    };
    return (
        <div className="ms-1 dropdown">
            <button
                type="button"
                disabled={versions.length === 0}
                title="View versions"
                className="btn btn-primary btn-sm dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                ref={versionsBtnRef}
            >
                Versions
            </button>
            <div
                className="dropdown-menu p-4 px-2 pb-2 dropdown-menu-end shadow"
                style={{
                    width: '350px',
                    maxHeight: '400px',
                    overflowY: 'auto',
                    fontSize: 'inherit'
                }}
            >
                <h6 className="mb-3 px-2">Version history</h6>
                <div id="file-version-list">
                    {versions.map((e) => {
                        let selected = selectedVersionId === e.id;
                        let showed = selectedVersionId === e.id;

                        return (
                            <VersionSmall
                                {...versionProps}
                                key={e.id}
                                version={e}
                                showed={showed}
                                selected={selected}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const DragAndDropWrapper = ({ onAccept, children }) => {
    const [dragActive, setDragActive] = useState(false);

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if ((e.type === 'dragenter' || e.type === 'dragover') && e.dataTransfer.types.includes('Files')) {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            onAccept([...e.dataTransfer.files]);
        }
    };

    return (
        <div onDragEnter={handleDrag} className="position-relative">
            {dragActive && (
                <div
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    className="position-absolute w-100 h-100 d-flex align-items-center opacity-3 bg-white backdrop-top justify-content-center fw-bold h3"
                >
                    <i className="fas fa-file-download me-3" /> Drop the files here ...
                </div>
            )}
            <div
                title="Upload Addressables"
                className="position-relative d-flex align-items-center rounded-2 justify-content-center bg-gray-200 py-2 mt-3 small"
            >
                <i className="fas fa-upload me-2" />
                <span className="text-muted"> -or- Drag and drop here</span>
                <input
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => onAccept([...e.target.files])}
                    multiple={true}
                    type="file"
                    className="fluid top-0 start-0 position-absolute opacity-0"
                />
            </div>

            {children}
        </div>
    );
};

export const uploadAddressableFile = async (
    gameId,
    file,
    uploadPath,
    user,
    message = '',
    contentType,
    existsCb
) => {
    const fileName = file.name;

    try {
        const data = await uploadStorageFile(gameId, file, {
            path: uploadPath,
            name: file.name,
            message,
            contentType,
            authorEmail: user.email,
            authorName: user.name
        });

        existsCb && existsCb(false);

        return { data, version: data.lastVersionId };
    } catch (err) {
        if (err.startsWith('This version already exists')) {
            try {
                const data = await getStorageAsset(gameId, uploadPath + fileName);

                const versionErrorTextStart = 'Check ';
                const versionErrorTextEnd = ' version.';
                const existsVersionId = err.slice(
                    err.indexOf(versionErrorTextStart) + versionErrorTextStart.length,
                    err.indexOf(versionErrorTextEnd)
                );

                const version = data.versions.find((v) => v.id === existsVersionId);

                if (version) {
                    existsCb && existsCb(true, version.id);
                    return { data, version: version.id };
                } else throw new Error(err);
            } catch (err) {
                throw new Error(fileName + ': ' + err);
            }
        } else {
            throw new Error(fileName + ': ' + err);
        }
    }
};

import axios from 'axios';
import { utils } from '@gpg-web/utils';

export const getStorageAsset = (gameId, path) => {
    return axios.post('/api/storage/' + gameId + '/get', { path });
};

export const getStorageAssetRecursively = (gameId, path) => {
    return axios.post('/api/storage/' + gameId + '/get-recursively', { path });
};

export const getStorageFileVersions = (gameId, path) => {
    return axios.post('/api/storage/' + gameId + '/versions', { path });
};

export const getStorageFileContent = (gameId, filePath, version, transformResponse) => {
    filePath = utils.generateStoragePath(filePath, version);
    return axios.get('/api/storage/' + gameId + '/content/' + filePath, {
        transformResponse
    });
};

export const downloadStorageFile = (gameId, filePath, version) => {
    const fileName = utils.baseName(filePath, true);

    filePath = utils.generateStoragePath(filePath, version);
    // window.open('/api/storage/' + gameId + '/download/' + filePath, '_blank');
    const a = document.createElement('a');
    a.setAttribute('href', '/api/storage/' + gameId + '/download/' + filePath);
    a.setAttribute('download', fileName);
    // a.setAttribute('target', '_blank');
    a.click();
};

// const getStorage = (gameId, version, gameContentId, variant) =>
// {
//     return axios.get('/api/content/' + gameId + '/game-content/download?version=' + version + '&config=' + gameContentId + '&variant=' + variant);
// }

// const getStorageVariants = (gameId, version, gameContentId) =>
// {
//     return axios.get('/api/content/' + gameId + '/game-content/variants?version=' + version + '&config=' + gameContentId);
// }

export const createStorageFolder = (user, gameId, path, folderName) => {
    return axios.post('/api/storage/' + gameId + '/folder/' + path + folderName);
};

export const copyStorageObject = (gameId, params) => {
    return axios.post('/api/storage/' + gameId + '/copy', params);
};

export const uploadStorageFile = (gameId, file, options) => {
    const formData = new FormData();

    formData.append('file', file);

    for (let key in options) {
        formData.append(key, options[key]);
    }

    return axios.post('/api/storage/' + gameId + '/upload', formData);
};

export const uploadStorageFileDraft = (gameId, file, options) => {
    const formData = new FormData();

    formData.append('file', file);

    for (let key in options) {
        formData.append(key, options[key]);
    }

    return axios.post('/api/storage/' + gameId + '/upload-draft', formData);
};

export const deleteStorageFileDraft = (gameId, options) => {
    return axios.delete(
        '/api/storage/' + gameId + '/delete-draft/' + options.path + options.name + '?version=' + options.id
    );
};

export const updateFile = (gameId, fileId, data) => {
    return axios.put('/api/storage/' + gameId + '/update/' + fileId, data);
};

export const removeFile = (gameId, fileId, fileName) => {
    return axios.delete('/api/storage/' + gameId + '/' + fileId, {
        params: {
            name: fileName
        }
    });
};

export const removeFolder = (gameId, folderPath) => {
    return axios.delete('/api/storage/' + gameId + '/folder/' + folderPath);
};

export const migrateStorage = (gameId, options) => {
    return axios.post('/api/storage/' + gameId + '/migrate', options);
};

export const generatePatch = (gameId, files) => {
    return axios.post('/api/storage/' + gameId + '/generate-patch', files);
};

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { organizationContext } from 'services/organization';

export const GameView = ({ value, options }) => {
    const { games } = useContext(organizationContext);

    const foundGame = games.find((game) => game.id === value) || { name: 'Unknown' };

    return (
        <Link className="fw-bold" to={'/game/' + value}>
            {foundGame.name}
        </Link>
    );
};
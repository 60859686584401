import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ComponentsMap } from 'consts';
import { getConfigs } from 'services/config';
import Skeleton from 'react-loading-skeleton';
import { utils, str } from '@gpg-web/utils';
import { DeleteButton } from 'components';
import DefaultProductIcon from 'img/product-default-icon-min.png';
import { EntityUsage } from './Entity';
import { IAPEntityUsage } from './IAPEntity';
import classnames from 'classnames';

const component = ComponentsMap['product'];

export const Product = ({ data, gameId, id, className, actions, header = true }) => {
    let {
        data: products,
        isFetching,
        error
    } = useQuery({
        queryKey: [component.id, gameId],
        queryFn: () => getConfigs(gameId, component.url),
        enabled: !!(gameId && !data)
    });

    // error = 'Error while fetching data for producst';

    if (!data && isFetching) return <Skeleton height="230px" className={className} />;

    if (!data) {
        data = (products && products.find((e) => e.id === id)) || {
            id: id,
            description: '',
            prices: [],
            content: []
        };
    }

    return (
        <div className={classnames('card shadow', className)}>
            <div
                className={classnames(
                    'd-flex align-items-center p-2 border border-bottom',
                    data.isSubscription
                        ? 'bg-info-subtle text-info-emphasis'
                        : 'bg-primary-subtle text-primary-emphasis'
                )}
            >
                <div className="fw-bold small">{data.isSubscription && <i className="fas fa-tags fa-sm ms-1 me-2"/> }{data.id}</div>
                {actions && (
                    <div className="dropdown no-arrow ms-auto">
                        <button
                            className="btn btn-sm text-gray-600 rounded-circle dropdown-toggle border-0"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fas fa-ellipsis-h" />
                        </button>
                        <ul className="dropdown-menu">{actions}</ul>
                    </div>
                )}
            </div>
            <div className="card-body pt-2">
                {error && (
                    <div className="alert alert-danger">
                        <i className="fas fa-exclamation-circle fa-sm me-2" />
                        {error}
                    </div>
                )}
                {header && (
                    <div className="d-flex align-items-center" title={data.description}>
                        <img
                            alt=""
                            width="70"
                            className="me-3"
                            src={data.icon ? utils.gameStoragePath(gameId, data.icon) : DefaultProductIcon}
                        />
                        <div>
                            <span>{data.name || data.id}</span>
                            <div className="small text-muted fw-light">{str.trim(data.description, 85)}</div>
                        </div>
                    </div>
                )}

                {header && <hr className="my-2" />}

                <div className="p-2">
                    <div className="small fw-bold text-primary mb-2">Prices</div>
                    {data.prices.map((e, index) => {
                        if (e.type === 'iap')
                            return (
                                <IAPEntityUsage
                                    key={e.id + index}
                                    fetchPrices={!data.isSubscription}
                                    className="mt-2"
                                    gameId={gameId}
                                    value={e}
                                />
                            );
                        return <EntityUsage key={e.id + index} className="mt-2" gameId={gameId} value={e} />;
                    })}
                    {data.prices.length === 0 && <div className="small text-gray-500">Nothing specified</div>}
                </div>
                <hr className="my-2" />
                <div className="p-2">
                    <div className="small fw-bold text-primary mb-2">Content</div>
                    {data.content.map((e, index) => (
                        <EntityUsage key={e.id + index} className="mt-2" gameId={gameId} value={e} />
                    ))}
                    {data.content.length === 0 && (
                        <div className="small text-gray-500">Nothing specified</div>
                    )}
                </div>
            </div>
        </div>
    );
};

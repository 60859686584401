import axios from 'axios';

let cacheScripts = null;

export const getScripts = (gameId) => {
    if (cacheScripts) return Promise.resolve(cacheScripts);

    return axios.get('/api/automation/' + gameId + '/scripts').then((result) => {
        cacheScripts = result;
        return result;
    });
};

export const runScript = (gameId, scriptId, options) => {
    return axios.post('/api/automation/' + gameId + '/scripts/run/' + scriptId, options);
};

export const getProcesses = (gameId) => {
    return axios.get('/api/automation/' + gameId + '/processes');
};

export const getProcess = (gameId, processId) => {
    return axios.get('/api/automation/' + gameId + '/processes/' + processId);
};

const cache = {};

export const getComponentsStatuses = (gameId) => {
    if (cache[gameId]) return Promise.resolve(cache[gameId]);

    return axios.get('/api/automation/' + gameId + '/componentsStatuses').then((list) => {
        cache[gameId] = list;
        return list;
    });
};

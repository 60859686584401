/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { ComponentsMap } from 'consts';
import { useModalBlocker } from 'components';
import { Modal } from '@gpg-web/react';
import { Triggers } from '../components/Triggers';
import { utils } from '@gpg-web/utils';

export const TriggersModal = ({ onHide, show, options, onChange }) => {
    const [data, setData] = useState({});
    const [componentId, setComponentId] = useState(null);
    const [errors, setErrors] = useState([]);
    const [updateInitialData, onBeforeClose, isBlocked] = useModalBlocker(data);

    useEffect(() => {
        if (options) {
            console.log(options);
            setData(updateInitialData(utils.clone(options.value)));
            setComponentId(options.component);
        }
    }, [options]);

    const reset = () => {
        setErrors([]);
        setData({});

        if (show) {
            onHide(null);
        }
    };

    async function handleSave() {
        if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

        onChange(options.path, {
            variant: options.variant,
            config: options.config,
            value: data
        });

        onHide(null);
        utils.hintOk('Triggers saved');
    }

    let component,
        valid = false;

    if (componentId) {
        component = ComponentsMap[componentId];
        valid = !!(component && component.triggers && component.triggers.length > 0);
    }

    const hasError = errors.some((e) => !!e);

    return (
        <Modal title="Triggers" show={show} onHide={reset} size="xl" onBeforeClose={onBeforeClose}>
            <div className="modal-body">
                {!valid && (
                    <div className="alert alert-secondary">
                        <i className="fas fa-info-circle fa-sm me-2" /> This component doesn't have any
                        triggers
                    </div>
                )}

                {valid &&
                    component.triggers.map((triggerId, index) => {
                        let TriggerComponent = Triggers[triggerId];

                        if (!TriggerComponent) return undefined;

                        return (
                            <TriggerComponent
                                key={!!show + triggerId}
                                onError={(err) => {
                                    errors[index] = err;
                                    setErrors(errors.slice());
                                }}
                                data={data}
                                setData={setData}
                                className="shadow mt-2 mb-4"
                            />
                        );
                    })}
            </div>

            <div className="modal-footer py-3">
                <button className="btn rounded-pill ms-auto shadow btn-light px-3" data-bs-dismiss="modal">
                    Cancel
                </button>

                {valid && (
                    <button
                        disabled={hasError || !isBlocked}
                        onClick={handleSave}
                        className="ms-3 btn rounded-pill shadow btn-success px-4"
                    >
                        Save
                    </button>
                )}
            </div>
        </Modal>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { createConfig } from 'services/config';
import {
    InputOption,
    Alert,
    useModalBlocker,
    BoolOption,
    useGame,
    Spinner,
    ImagePathOption,
    JSONOption,
    TextOption,
    EntitiesUsageOption
} from 'components';
import { useComponent } from '../hooks/UseComponent';
import { utils } from '@gpg-web/utils';
import { Modal } from '@gpg-web/react';
import { useQueryClient } from '@tanstack/react-query';
import CreatableSelect from 'react-select/creatable';

const defaultData = () => ({
    id: '',
    name: '',
    isSubscription: false,
    description: '',
    icon: '',
    customData: '',
    prices: [],
    content: []
});

export const ProductCreateModal = (props) => {
    const { onHide, show } = props;
    const [data, setData] = useState(defaultData());
    const component = useComponent('product');

    const [saving, setSaving] = useState(false);
    const [saveBlock, setSaveBlock] = useState('00');
    const [error, setError] = useState(null);
    const [, onBeforeClose] = useModalBlocker(data);
    const queryClient = useQueryClient();
    const game = useGame();
    const gameId = game.id;

    const reset = () => {
        setError(null);
        setSaving(false);
        setData(defaultData());
        setSaveBlock('00');

        if (show) {
            onHide();
        }
    };

    const handleChange = (name, value) => setData({ ...data, [name]: value });

    async function handleSave() {
        try {
            // if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

            let id = data.id.trim();

            if (!id) return setError('ID field is required');

            if (!utils.isValidId(id)) {
                return setError('ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed');
            }

            data.id = id;
            setSaving(true);
            await createConfig(gameId, component.url, data);

            queryClient.refetchQueries({
                queryKey: [component.id, gameId],
                exact: true
            });

            onHide();
        } catch (err) {
            setSaving(false);
            setError(err);
        }
    }

    return (
        <Modal title="New product" show={show} onHide={reset} onBeforeClose={onBeforeClose}>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <InputOption
                            id="product-create-id"
                            name="id"
                            required
                            tooltip="REQUIRED: specify unique product identifier"
                            title="ID"
                            value={data.id}
                            onChange={handleChange}
                        />
                        <ImagePathOption
                            key={show}
                            onBlock={(e) => setSaveBlock(saveBlock[0] + Number(e))}
                            name="icon"
                            tooltip="Visible to Livefire only"
                            gameId={gameId}
                            title="Display icon"
                            value={data.icon}
                            onChange={handleChange}
                        />
                        <BoolOption
                            id="product-create-issubscription"
                            tooltip="Is your product is subscription, that charge users for content or services on a recurring basis?"
                            name="isSubscription"
                            className="mb-3"
                            title="Is subscription"
                            value={data.isSubscription}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <InputOption
                            id="product-create-name"
                            name="name"
                            tooltip="Visible to Livefire only"
                            title="Display name"
                            value={data.name}
                            onChange={handleChange}
                        />
                        <TextOption
                            id="product-create-description"
                            name="description"
                            tooltip="Visible to Livefire only"
                            title="Description"
                            value={data.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <JSONOption
                    key={show}
                    onBlock={(e) => setSaveBlock(Number(e) + saveBlock[1])}
                    value={data.customData}
                    title="Custom data"
                    className="mb-4"
                    name="customData"
                    onChange={handleChange}
                />

                <EntitiesUsageOption
                    title="Prices"
                    gameId={gameId}
                    name="prices"
                    fetchPrices={!data.isSubscription}
                    value={data.prices}
                    max={1}
                    time={false}
                    includeIAP
                    className="mb-4"
                    filter={{ isConsumable: true }}
                    onChange={handleChange}
                />

                <EntitiesUsageOption
                    title="Content"
                    gameId={gameId}
                    name="content"
                    value={data.content}
                    onChange={handleChange}
                />
            </div>

            <div className="modal-footer py-3">
                {error && <Alert text={error} />}
                <button
                    disabled={saving}
                    className="btn rounded-pill ms-auto shadow btn-light px-3"
                    data-bs-dismiss="modal"
                >
                    Cancel
                </button>

                <button
                    disabled={saving || saveBlock !== '00'}
                    onClick={handleSave}
                    className="ms-3 btn rounded-pill shadow btn-success px-4"
                >
                    {saving && <Spinner className="me-2" />}Create
                </button>
            </div>
        </Modal>
    );
};

import React from 'react';
import axios from 'axios';

const gameContext = React.createContext({});

let groups = null;

const getGameGroups = () => {
    if (groups) {
        return Promise.resolve(groups);
    }

    return axios.get('/api/game/group/list').then((list) => {
        groups = list;
        return list;
    });
};

const getGameGroup = (id) => {
    return axios.get('/api/game/group/' + id);
};

const addGameGroup = (data) => {
    return axios.post('/api/game/group', data).then((result) => {
        if (groups) {
            groups.push(result);
        }

        return result;
    });
};

const gamesCache = {};

const getGames = (groupId) => {
    if (gamesCache[groupId]) return Promise.resolve(gamesCache[groupId]);

    return axios.get('/api/game/list', { params: { group: groupId } }).then((result) => {
        gamesCache[groupId] = result;
        return result;
    });
};

const addGame = (data) => {
    return axios.post('/api/game', data);
};

const getGame = (gameId) => {
    return axios.get('/api/project/' + gameId + '/game');
};

const updateGame = (gameId, data) => {
    return axios.put('/api/project/' + gameId + '/game', data);
};

const updateGameGroup = (gameId, data) => {
    return axios.put('/api/project/' + gameId + '/group', data);
};

export {
    getGameGroup,
    addGame,
    getGameGroups,
    addGameGroup,
    getGames,
    getGame,
    updateGame,
    updateGameGroup,
    gameContext
};

import { utils } from '@gpg-web/utils';

const startsWithUrl = (str, url) => {
    const baseUrl = url.replace('https', '').replace('http', '');

    const baseStr = str.replace('https', '').replace('http', '');

    return baseStr.startsWith(baseUrl);
};

export const parseAddressablesCatalog = async (file, url) => {
    try {
        const content = await utils.load.file(file, 'text');

        const parsed = JSON.parse(content);

        if (typeof parsed !== 'object') throw new Error('JSON file is invalid');

        const compressed = JSON.stringify(parsed);

        file = new Blob([compressed], { type: file.type });

        const prefixes = {};

        (parsed.m_InternalIdPrefixes || []).forEach((p, i) => {
            if (p.includes('greenpandagames.com')) {
                if (startsWithUrl(p, url)) {
                    prefixes[i + '#/'] = p;
                } else throw new Error('Wrong remote api url - ' + p);
            }
        });

        const files = {};

        (parsed.m_InternalIds || []).forEach((id, i) => {
            if (id.includes('greenpandagames.com')) {
                if (startsWithUrl(id, url)) {
                    files[utils.baseName(id, true)] = id;
                } else throw new Error('Wrong api url - ' + id);
            } else {
                const prefix = Object.keys(prefixes).find((p) => id.startsWith(p));

                if (prefix) {
                    const path = id.replace(prefix, prefixes[prefix] + '/');

                    files[utils.baseName(path, true)] = path;
                }
            }
        });

        return files;
    } catch (err) {
        throw new Error('Unable to parse catalog: ' + err.message);
    }
};

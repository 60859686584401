/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';
import { TooltipWrapper } from 'components';

const configLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

export const VariantsSetup = (props) => {
    const { data, setData } = props;

    const variants = data.variants;

    const addVariant = () => {
        let letter = 'X';
        for (let l of configLetters) {
            let nameExists = variants.find((e) => e.id === 'Variant ' + l);

            if (!nameExists) {
                letter = l;
                break;
            }
        }

        variants.push({ id: 'Variant ' + (letter || 'X'), weight: 1, description: '' });
        setData({ ...data, variants: variants.slice() });
    };

    const removeVariant = (e) => {
        utils.confirm('Are you sure you want to remove this variant from the list?', (yes) => {
            if (!yes) return;
            const variantIndex = e.target.getAttribute('data-index');
            variants.splice(variantIndex, 1);
            setData({ ...data, variants: variants.slice() });
        });
    };

    const setWeight = (e) => {
        const variantIndex = e.target.getAttribute('data-index');
        let value = Number(e.target.value);

        if (value < 1) value = 1;

        variants[variantIndex].weight = Number(value);
        setData({ ...data, variants: variants.slice() });
    };

    const setDescription = (e) => {
        const variantIndex = e.target.getAttribute('data-index');
        const value = e.target.value;

        variants[variantIndex].description = value;
        setData({ ...data, variants: variants.slice() });
    };

    let totalWeight = 0;
    for (let variant of variants) totalWeight += variant.weight || 0.0001;

    const canAddVariants = variants.length < configLetters.length + 1;

    return (
        <div className={utils.className('card', props.className)}>
            <div className="card-header py-3">
                <h6 className="m-0 fw-bold text-primary">Variants</h6>
            </div>

            <div className="card-body variants">
                {variants.map((e, index) => (
                    <Variant
                        key={index}
                        index={index}
                        value={e}
                        canRemove={index !== 0 && variants.length > 2}
                        removeVariant={removeVariant}
                        setWeight={setWeight}
                        setDescription={setDescription}
                        totalWeight={totalWeight}
                        readOnly={props.readOnly}
                        canUpdateLive={props.canUpdateLive}
                    />
                ))}
                <TooltipWrapper
                    content={canAddVariants ? false : 'You have reached a maximum number of variants'}
                    wrapperClasses="w-100"
                    type="none"
                    className="w-100"
                >
                    <button
                        onClick={canAddVariants ? addVariant : () => {}}
                        disabled={props.readOnly || !canAddVariants}
                        className={utils.className(
                            'btn btn-sm btn-outline-primary fw-bold w-100',
                            props.readOnly ? 'd-none' : ''
                        )}
                    >
                        <i className="fas fa-plus-circle me-1" /> Add another variant
                    </button>
                </TooltipWrapper>
            </div>
        </div>
    );
};

const Variant = (props) => {
    const { value, index, removeVariant, setDescription, canRemove, setWeight, totalWeight } = props;

    return (
        <div className="card mb-3">
            <div className={'py-2 px-3 d-flex align-items-center variant variant-color-' + index}>
                <div className="m-0 fw-bold my-1">{value.id}</div>
                <TooltipWrapper content="Weight" icon="none">
                    <input
                        type="number"
                        min="1"
                        placeholder="weight"
                        value={value.weight || ''}
                        data-index={index}
                        onChange={setWeight}
                        disabled={props.readOnly && !props.canUpdateLive}
                        className="form-control form-control-sm ms-3 w-100px"
                    />
                </TooltipWrapper>
                <input
                    type="text"
                    rows={1}
                    placeholder="Description"
                    value={value.description || ''}
                    data-index={index}
                    onChange={setDescription}
                    disabled={props.readOnly && !props.canUpdateLive}
                    className="form-control form-control-sm ms-2 w-50"
                />

                <div className="ms-3 opacity-3">
                    {Math.round((value.weight / totalWeight) * 100)}% chance of shown
                </div>

                {canRemove && (
                    <div className="ms-auto">
                        <button
                            data-index={index}
                            onClick={removeVariant}
                            disabled={props.readOnly}
                            className={utils.className('btn btn-sm', props.readOnly ? 'd-none' : '')}
                        >
                            <i className="fas fa-lg fa-times-circle pe-none" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

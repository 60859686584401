import { Link } from 'react-router-dom';
import { useUserIAM } from '../hooks/UseUserIAM';

export const IAMComponentWrapper = (props) => {
    const permissions = props.permissions || [];

    const { userPermissions, gameId } = useUserIAM();

    if (!userPermissions) return null;

    let neededPermissions = [];

    if (permissions.length === 1 && userPermissions.indexOf(permissions[0]) === -1) {
        neededPermissions = [permissions[0]];
    } else {
        neededPermissions = permissions.filter((p) => userPermissions.indexOf(p) === -1);
    }

    if (neededPermissions.length === 0) {
        return props.children;
    }

    return typeof props.fallback !== 'undefined' ? (
        props.fallbackBack
    ) : (
        <div className="alert alert-warning d-flex align-items-center small p-2">
            <i className="fas fa-exclamation-triangle me-2" />
            <span>
                You don't have permissions to view this component.
                {/* Missing permissions:
                <span className="fw-bold ms-1">{neededPermissions.join(', ')}</span> */}
            </span>
            <Link
                to={'/game/' + gameId + '/missing-permissions?permissions=' + permissions.join(',')}
                className="btn btn-primary ms-auto text-nowrap"
            >
                Learn more
            </Link>
        </div>
    );
};

const TextMimes = [
    'application/xml',
    'application/javascript',
    'text/csv',
    'text/css',
    'text/xml',
    'text/plain',
    'text/calendar',
    'text/javascript',
    'text/php'
];

export { TextMimes };

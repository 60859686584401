import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';

let timeout;

export const Tooltip = (props) => {
    const childRef = useRef();

    useEffect(() => {
        let el = props.content;
        let html = false;

        if (typeof props.content === 'object') {
            html = true;
            el = document.createElement('div');
            const root = ReactDOM.createRoot(el);

            root.render(props.content);
        }

        let options = {
            title: el,
            html: html,
            // placement: 'left',
            // trigger: 'focus',
            delay: props.delay ? { hide: props.delay } : 0
        };

        if (props.trigger) options.trigger = props.trigger;

        const t = new window.bootstrap.Tooltip(childRef.current, options);
        function checkClassName() {
            if (props.className) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    t._popper && (t._popper.state.elements.popper.className += ' ' + props.className);
                }, 0);
            }
        }

        t._element.addEventListener('show.bs.tooltip', checkClassName);

        return () => {
            t._element.removeEventListener('show.bs.tooltip', checkClassName);
            // root.unmount();
            t.dispose();
        };
    }, [props.content, props.delay, props.trigger, props.className]);

    return React.cloneElement(props.children, { ref: childRef });
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { utils } from '@gpg-web/utils';
import { formatDateToIntput } from '../components/Time';
import { TimeExamples } from '../components/TimeExamples';

export const TimeTrigger = (props) => {
    const { data, setData, readOnly, onError, errorId, canUpdateLive } = props;
    const [error, setError] = useState(null);

    const time = data.time;
    const status = data.status;

    useEffect(() => {
        function _setError(err) {
            setError(err);
            onError && onError(errorId, err);
        }

        const time = data.time;

        let from = time.data['start'];
        let to = time.data['end'];

        if (!from) {
            if (status === 'live' || status === 'disabled') {
                return _setError('Please specify epxeriment "Start on" date');
            }
        }

        if (!to) {
            if (status === 'live' || status === 'disabled') {
                return _setError('Please specify epxeriment "Ends on" date');
            }
        }

        from = new Date(from);
        to = new Date(to);
        if (from && to && from > to) {
            return _setError('Event "Start on" date should be less than "Ends on" date');
        }

        _setError(null);
    }, [data, errorId, onError, status]);

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;

        let value;

        if (target.type === 'checkbox') value = target.checked;
        else if (target.type === 'number') value = Number(target.value);
        else if (target.type === 'datetime-local' || target.type === 'datetime') {
            if (target.value) {
                let date = new Date(target.value);
                date.setTime(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
                value = date.toISOString();
            }
        } else value = target.value || undefined;

        time.data[name] = value;

        setData({ ...data });

        // validate();
    };

    const setValue = (name, value) => {
        time[name] = value;
        setData({ ...data });
    };

    let warning;

    if (time.data['start'] && new Date(time.data['start']) < new Date()) {
        warning = 'Start date set earlier than now, are you sure you want to continue?';
    }

    return (
        <div className={utils.className('card', props.className)}>
            <div className="card-header py-3 d-flex align-items-center">
                <h6 className="m-0 fw-bold text-primary">Time trigger</h6>
            </div>

            <div className={'card-body' + (data.time ? '' : ' d-none')}>
                <div className="mb-4 pb-3 border-bottom d-flex">
                    <div className="d-flex align-items-center ms-auto">
                        <label htmlFor="time-local-global-mode">Trigger by</label>
                        <select
                            id="time-local-global-mode"
                            onChange={(e) => setValue('global', e.target.value === 'global')}
                            value={time.global ? 'global' : 'local'}
                            name="triggerByMode"
                            className="form-select form-select-sm w-150px mx-2"
                            disabled={readOnly}
                        >
                            <option value="local">Player local</option>
                            <option value="global">GMT</option>
                        </select>
                        <label htmlFor="time-local-global-mode">time zone</label>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-6 col-md-12">
                        <div className="d-flex align-items-center">
                            <span className="me-1 small fw-bold">Starts on</span>
                            <input
                                disabled={readOnly}
                                onChange={handleChange}
                                name="start"
                                value={formatDateToIntput(time.data['start'])}
                                type="datetime-local"
                                className="form-control form-control-sm ms-3 me-2 w-300px"
                            />
                            <span className="small">(GMT)</span>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="d-flex align-items-center">
                            <span className="me-1 small fw-bold">Ends on</span>
                            <input
                                disabled={readOnly && !canUpdateLive}
                                onChange={handleChange}
                                name="end"
                                value={formatDateToIntput(time.data['end'])}
                                type="datetime-local"
                                className="form-control form-control-sm ms-3 me-2 w-300px"
                            />
                            <span className="small">(GMT)</span>
                        </div>
                    </div>
                </div>

                <div className="mx-2">
                    {error && (
                        <div className="alert alert-danger mt-3" role="alert">
                            <i className="fas fa-exclamation-circle me-2" />
                            {error}
                        </div>
                    )}

                    {!error && !!warning && (
                        <div className="alert alert-warning">
                            <i className="fas fa-exclamation-triangle me-2" />
                            {warning}
                        </div>
                    )}

                    {!error && time && time.data && time.data.start && time.data.end && (
                        <TimeExamples time={time} />
                    )}
                </div>
            </div>
        </div>
    );
};

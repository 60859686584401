/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, Component, useRef, useMemo } from 'react';
import { InputOption, TextOption, useStoredState } from 'components';

import { utils } from '@gpg-web/utils';

import { Editor, EditorState, RichUtils, Modifier } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import Select from 'react-select';
import { Languages } from 'consts';
import { InvalidKeyError } from './Utils';

function componentFromStr(numStr, percent) {
    var num = Math.max(0, parseInt(numStr, 10));
    return percent ? Math.floor((255 * Math.min(100, num)) / 100) : Math.min(255, num);
}

function rgbToHex(rgb) {
    var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
    var result,
        r,
        g,
        b,
        hex = '';
    if ((result = rgbRegex.exec(rgb))) {
        r = componentFromStr(result[1], result[2]);
        g = componentFromStr(result[3], result[4]);
        b = componentFromStr(result[5], result[6]);

        hex = '#' + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }
    return hex;
}

const convertToHTML = (contentState) => {
    const options = {
        defaultBlockTag: null,
        inlineStyles: {
            BOLD: {
                element: 'b'
            },
            ITALIC: {
                element: 'i'
            }
        },
        inlineStyleFn: (styles) => {
            let key = 'COLOR_';
            let color = styles.filter((value) => value.startsWith(key)).first();

            if (color) {
                return {
                    element: 'color=' + color.split('_')[1]
                };
            }
        }
    };

    let htmlText = stateToHTML(contentState, options).replaceAll(/<\/color=#\S{6}>/gi, '</color>');

    return htmlText === '<br>' ? '' : htmlText;
};

const htmlToContent = (htmlText) => {
    let index = 0;

    const colorStyles = {};
    const elementStyles = {};

    while (index > -1) {
        const openColorTag = '<color=#';

        const startIndex = htmlText.indexOf(openColorTag, index);

        if (startIndex > -1) {
            const endIndex = htmlText.indexOf('>', startIndex);

            const colorValue = htmlText.slice(startIndex + openColorTag.length, endIndex);

            colorStyles['COLOR_#' + colorValue] = { color: '#' + colorValue };

            htmlText = htmlText.replace(
                '<color=#' + colorValue + '>',
                "<span class='COLOR_#" + colorValue + "'>"
            );
            htmlText = htmlText.replace('</color>', '</span>');

            index = startIndex + openColorTag.length;
        } else {
            index = startIndex;
        }
    }

    const options = {
        elementStyles: {
            b: 'BOLD',
            i: 'ITALIC',
            ...elementStyles
        },
        customInlineFn: (element, { Style }) => {
            if (element.tagName === 'SPAN' && element.className.startsWith('COLOR_')) {
                const color = element.className.split('COLOR_#')[1];
                return Style('COLOR_#' + color);
            }
        }
    };
    const state = stateFromHTML(htmlText, options);

    return [state, colorStyles];
};

const TextColorInput = ({ value, onChange }) => {
    const colorInput = useRef(null);
    const colorBlock = useRef(null);

    const debounceChange = utils.debounce((val) => onChange(val), 50);

    return (
        <>
            <div
                className="btn btn-sm py-0 d-flex px-2 flex-column border-0"
                title="Text color"
                onMouseDown={(e) => {
                    e.preventDefault();
                    onChange(colorInput.current ? rgbToHex(colorBlock.current.style.backgroundColor) : value);
                }}
            >
                <i className="fas fa-font" style={{ fontSize: '12px' }} />
                <div
                    className="w-100"
                    ref={colorBlock}
                    style={{ height: '4px', backgroundColor: value }}
                ></div>
            </div>
            <div
                className="btn btn-sm py-0 px-2 position-relative border-0"
                title="Pick color of text"
                onMouseDown={(e) => {
                    e.preventDefault();
                    if (colorInput.current) {
                        colorInput.current.click();
                    }
                }}
            >
                <i className="fas fa-eye-dropper" />
                <input
                    type="color"
                    className="position-absolute"
                    style={{ width: '0px', height: '0px', visibility: 'hidden' }}
                    value={value}
                    onChange={(e) => {
                        if (colorBlock.current) {
                            colorBlock.current.style.backgroundColor = e.target.value;
                        }
                        debounceChange(e.target.value);
                    }}
                    ref={colorInput}
                />
            </div>
        </>
    );
};

class UnityRichTextEditor extends Component {
    constructor(props) {
        super(props);

        const [content, styles] = htmlToContent(props.text || '');

        this.state = {
            editorState: EditorState.createWithContent(content),
            color: null,
            styles: {
                BOLD: {
                    fontWeight: 800
                },
                ITALIC: {
                    fontStyle: 'italic'
                },
                ...styles
            }
        };
        this.onChange = (editorState) => {
            this.setState({ editorState });
            // props.onChange(convertToHTML(this.state.editorState.getCurrentContent()));
        };
        this.setEditor = (editor) => {
            this.editor = editor;
        };

        // this.setColorInput = (colorInput) => {
        //     this.colorInput = colorInput;
        // };

        this.focusEditor = () => {
            if (this.editor) {
                this.editor.focus();
            }
        };

        this.applyColor = (color) =>
            this.setState((prevState) => ({ ...prevState, editorState: this._applyColor(color) }));
        this.setColor = (color) => {
            this.setState((prevState) => ({ ...prevState, color: color }));

            let colorStyle = null;
            if (color !== '#000000') colorStyle = 'COLOR_' + color;

            if (colorStyle && !this.state.styles[color]) {
                this.setState((prevState) => ({
                    ...prevState,
                    styles: { ...prevState.styles, [colorStyle]: { color: color } }
                }));
            }

            this.applyColor(colorStyle);
        };
    }

    _applyColor(toggledColor) {
        const { editorState, styles } = this.state;
        const selection = editorState.getSelection();

        const nextContentState = Object.keys(styles).reduce((contentState, style) => {
            if (style.startsWith('COLOR_')) return Modifier.removeInlineStyle(contentState, selection, style);
            return contentState;
        }, editorState.getCurrentContent());

        let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

        if (toggledColor) {
            const currentStyle = editorState.getCurrentInlineStyle();

            if (selection.isCollapsed()) {
                nextEditorState = currentStyle.reduce((state, color) => {
                    return RichUtils.toggleInlineStyle(state, color);
                }, nextEditorState);
            }

            if (!currentStyle.has(toggledColor)) {
                nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor);
            }
        }

        return nextEditorState;
    }

    handleStyleClick(style) {
        this.setState((prevState) => ({
            ...prevState,
            editorState: RichUtils.toggleInlineStyle(prevState.editorState, style)
        }));
    }

    componentDidMount() {}
    componentDidUpdate(prevProps) {
        const selectionStyles = this.state.editorState.getCurrentInlineStyle().toJS();

        let selectionColor = '#000000';

        const colorStyle = selectionStyles.find((style) => style && style.startsWith('COLOR_'));

        if (colorStyle) {
            selectionColor = colorStyle.replace('COLOR_', '');
        }

        if (this.state.color !== selectionColor) {
            this.setState((prevState) => ({ ...prevState, color: selectionColor }));
        }

        if (this.props.text !== prevProps.text) {
            const [content, styles] = htmlToContent(this.props.text || '');
            const newEditorState = EditorState.createWithContent(content);

            this.setState((prevState) => ({
                ...prevState,
                editorState: newEditorState,
                styles: {
                    ...prevState.styles,
                    ...styles
                }
            }));
        }
    }

    render() {
        const selectionStyles = this.state.editorState.getCurrentInlineStyle();

        return (
            <div>
                <div className="d-flex align-items-center">
                    <div
                        className={
                            'btn btn-sm border-0 rounded py-0 px-2 ' +
                            (selectionStyles.has('ITALIC') ? 'bg-primary-subtle' : '')
                        }
                        onMouseDown={(e) => {
                            e.preventDefault();
                            this.handleStyleClick('ITALIC');
                        }}
                    >
                        <i className="fas fa-italic" />
                    </div>
                    <div
                        className={
                            'btn btn-sm border-0 rounded py-0 px-2 mx-1 ' +
                            (selectionStyles.has('BOLD') ? 'bg-primary-subtle' : '')
                        }
                        onMouseDown={(e) => {
                            e.preventDefault();
                            this.handleStyleClick('BOLD');
                        }}
                    >
                        <i className="fas fa-bold" />
                    </div>

                    <TextColorInput value={this.state.color || '#000000'} onChange={this.setColor} />
                </div>
                <div
                    className="form-control"
                    style={{ minHeight: 'calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2))' }}
                    onClick={this.focusEditor}
                >
                    <Editor
                        customStyleMap={this.state.styles}
                        editorState={this.state.editorState}
                        onChange={this.onChange}
                        onBlur={() =>
                            this.props.onChange(convertToHTML(this.state.editorState.getCurrentContent()))
                        }
                        ref={this.setEditor}
                    />
                </div>
            </div>
        );
    }
}

const LanguageText = ({ name, label, value, onChange, isRich }) => {
    return (
        <div className="mb-3 px-1">
            <label className="mb-1 small">{label}</label>
            {isRich ? (
                <UnityRichTextEditor
                    key={name + label}
                    text={value}
                    onChange={(value) => onChange(name, value)}
                />
            ) : (
                <TextOption title={false} rows={2} name={name} value={value || ''} onChange={onChange} />
            )}
        </div>
    );
};

export const EditText = (props) => {
    const { onBack, inputData, onSave, inputLanguages, content } = props;

    // const [adding, setAdding] = useState(false);
    const [data, setData] = useState({});
    const [languages, setLanguages] = useState([]);
    const [error, setError] = useState(null);
    // const [updateInitialData, onBeforeClose] = useModalBlocker(data);

    const [isRichEditor, setRichEditor] = useStoredState('translation_is_rich_text_editor', true);

    // useEffect(() => {
    //     if (inputData) setData(updateInitialData(inputData));
    // }, [inputData, updateInitialData]);

    const newKey = !inputData.key;

    useEffect(() => {
        inputData && setData(inputData);
    }, [inputData]);

    useEffect(() => {
        if (inputLanguages) {
            if (inputLanguages.length > 0) setLanguages(inputLanguages.slice());
            else setLanguages([{ value: 'en', label: Languages['en'] }]);
        }
    }, [inputLanguages]);

    const reset = () => {
        setError(null);
        setData({});
        setLanguages([]);
        onBack(null);
    };

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const saveChanges = () => {
        setError(null);

        let key = data['key'].trim();
        if (!key) return setError('Key is required');
        if (content[key] && key !== inputData.key) return setError('This key already in use');
        if (!utils.isValidKeyName(key)) {
            return setError(new InvalidKeyError(key).message);
        }

        if (!data['en']) return setError('English translation is required');

        onSave(data);
        reset();
    };

    const LanguagesOptions = useMemo(() => {
        const result = [];

        for (let key in Languages) {
            if (languages.find((e) => e.value === key)) continue;

            result.push({ value: key, label: Languages[key] });
        }

        return result;
    }, [languages]);

    return (
        <div className="p-3">
            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <InputOption
                        required
                        tooltip="REQUIRED: Unique translation key"
                        title="Key"
                        name="key"
                        onChange={handleChange}
                        readOnly={!newKey}
                        value={data.key}
                    />

                    <TextOption
                        readOnly
                        tooltip="REQUIRED: Set english translation"
                        title="Source Text"
                        rows={3}
                        name="en"
                        value={data['en'] || ''}
                        onChange={handleChange}
                    />
                </div>

                <div className="col-md-12 col-lg-6">
                    <div className="d-flex mb-2">
                        <div
                            className="btn-group ms-auto"
                            role="group"
                            aria-label="Basic radio toggle button group"
                        >
                            <input
                                type="radio"
                                className="btn-check"
                                name="editor"
                                id="editor_raw"
                                checked={!isRichEditor}
                                onChange={(e) => {
                                    setRichEditor(!e.target.checked);
                                    // setData((prev) => ({ ...prev }));
                                }}
                            />
                            <label className="btn btn-outline-primary btn-sm p-0 px-2" htmlFor="editor_raw">
                                RAW
                            </label>

                            <input
                                type="radio"
                                className="btn-check"
                                name="editor"
                                id="editor_rich"
                                checked={isRichEditor}
                                onChange={(e) => {
                                    setRichEditor(e.target.checked);
                                    // setData((prev) => ({ ...prev }));
                                }}
                            />
                            <label className="btn btn-outline-primary btn-sm p-0 px-2" htmlFor="editor_rich">
                                RICH
                            </label>
                        </div>
                    </div>
                    <div style={{ overflowY: 'auto', height: '350px', maxHeight: '350px' }}>
                        <Select
                            value={null}
                            name="language"
                            onChange={(e) => {
                                languages.push(e);
                                setLanguages(languages.slice());
                            }}
                            options={LanguagesOptions}
                            placeholder="Add language"
                            className="react-select-sm mt-1 mx-2"
                            classNamePrefix="select"
                            styles={{
                                menuList: (base) => ({
                                    ...base,
                                    maxHeight: 250
                                })
                            }}
                        />
                        {languages && languages.length > 0 && <hr />}

                        {languages &&
                            languages.map((e) => {
                                return (
                                    <LanguageText
                                        key={e.value}
                                        label={e.label}
                                        name={e.value}
                                        value={data[e.value]}
                                        isRich={isRichEditor}
                                        onChange={handleChange}
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>

            <hr />
            <div className="d-flex align-items-center">
                {error && (
                    <div className="text-danger">
                        <i className="fas fa-exclamation-circle fa-sm me-2" />
                        {error}
                    </div>
                )}
                <button onClick={reset} className="btn rounded-pill btn-light shadow px-3 ms-auto">
                    Cancel
                </button>
                <button onClick={saveChanges} className="btn rounded-pill btn-success shadow px-3 ms-2">
                    Save
                </button>
            </div>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    getConfig,
    updateConfig,
    updateLiveConfig,
    updateConfigStatus,
    // restoreConfig,
    checkExperimentOverlap
} from 'services/config';
import {
    IAMButton,
    Input,
    TextArea,
    ComponentHeader,
    ProcessBlockedWrapper,
    useGame,
    usePageBlocker,
    useUserIAM,
    TooltipWrapper
} from 'components';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import { Navigation } from '../Navigation';
import { useComponent } from '../hooks';
import { TimeTrigger } from './Time';
import { ConditionsTrigger } from './Conditions';
import { VariantsSetup } from './Variants';
import { ConfigsSetup } from './Configs';
import { ContentSetup } from './Content';
import { ChangeHistory } from '../components/ChangeHistory';
import { ConfigReadOnlyInfo } from '../components/ConfigReadOnlyInfo';
import { Modal } from '@gpg-web/react';
import { ComponentsMap } from 'consts';

let queueId = 0;
export const ExperimentEdit = (props) => {
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState([]);
    const component = useComponent('experiment');
    const { configId } = useParams();
    const game = useGame();
    const gameId = game.id;
    const [updateInitialData, , isBlocked] = usePageBlocker(data);

    const onError = useCallback((id, err) => {
        setErrors((errors) => {
            errors[id] = err;
            return errors.slice();
        });
    }, []);

    useEffect(() => {
        if (!gameId) return;
        setData(null);
        queueId++;
        const _queueId = queueId;
        getConfig(gameId, component.url, configId)
            .then((res) => {
                res.conditions = res.conditions || [];
                res.time = res.time || { global: false, mode: 'default', data: {} };
                res.time.global = res.time.global || false;
                res.time.mode = res.time.mode || 'default';
                res.time.data = res.time.data || {};
                if (_queueId === queueId) setData(updateInitialData(res));
            })
            .catch(utils.hintError);
    }, [gameId, component.url, configId, updateInitialData]);

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    };

    const [checkOverlap, OverlapModal] = useOverlap(gameId, component, data);

    const _updateConfig = () => {
        if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

        if (data.name) data.name = data.name.trim();

        utils.confirm('Are you sure you want to save changes?', async function (yes) {
            if (!yes) return;

            utils.popup('saving');

            if (data.status === 'live') {
                checkOverlap(() => {
                    updateConfig(gameId, component.url, data)
                        .then((result) => {
                            utils.popup('hide');
                            setData(updateInitialData(result));
                        })
                        .catch(utils.hintError);
                });
            } else {
                updateConfig(gameId, component.url, data)
                    .then((result) => {
                        utils.popup('hide');
                        setData(updateInitialData(result));
                    })
                    .catch(utils.hintError);
            }
        });
    };

    const _updateLive = () => {
        if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

        if (data.name) data.name = data.name.trim();

        utils.confirm('Are you sure you want to make changes to live experiment?', async function (yes) {
            if (!yes) return;

            utils.popup('saving');

            checkOverlap(() => {
                updateLiveConfig(gameId, component.url, data)
                    .then((result) => {
                        utils.popup('hide');
                        setData(updateInitialData(result));
                    })
                    .catch(utils.hintError);
            });
        });
    };

    const _enableConfig = () => {
        utils.confirm(
            'Are you sure you want to enable this experiment? The experiment will be running for "live" players, within configured dates.',
            async function (yes) {
                if (!yes) return;

                utils.popup('saving');

                checkOverlap(() =>
                    updateConfigStatus(gameId, component.url, data.id, 'live')
                        .then((result) => {
                            utils.popup('hide');
                            data.status = 'live';
                            setData(updateInitialData({ ...data }));
                        })
                        .catch(utils.hintError)
                );
            }
        );
    };

    const _disableConfig = () => {
        utils.confirm(
            'Are you sure you want to disable this experiment? The experiment will be stopped immediately.',
            function (yes) {
                if (!yes) return;

                utils.popup('saving');

                updateConfigStatus(gameId, component.url, data.id, 'disabled')
                    .then((result) => {
                        utils.popup('hide');
                        data.status = 'disabled';
                        setData(updateInitialData({ ...data }));
                    })
                    .catch(utils.hintError);
            }
        );
    };

    // const _restoreConfig = () => {
    //     utils.confirm('Are you sure you want to restore this experiment from archive?', function (yes) {
    //         if (!yes) return;

    //         utils.popup('saving');

    //         restoreConfig(gameId, component.url, data.id)
    //             .then((result) => {
    //                 utils.popup('hide');
    //                 data.status = 'draft';
    //                 setData(updateInitialData({ ...data }));
    //             })
    //             .catch(utils.hintError);
    //     });
    // };

    const status = data && data.status;

    const [readOnly, canUpdateLive] = useEditLock(status);

    return (
        <>
            <Navigation tab={component.id} gameId={gameId} />
            {OverlapModal}
            <div className="container-lg">
                <ComponentHeader
                    gameId={gameId}
                    url={'config/' + component.url}
                    id={component.id}
                    breadcrumb={[{ name: configId }]}
                >
                    {data && <ChangeHistory />}
                    {status === 'live' && (
                        <IAMButton permissions={['component.experiment.update']}>
                            <button
                                onClick={_disableConfig}
                                className="btn btn-danger rounded-pill px-4 ms-3 shadow"
                            >
                                Stop
                            </button>
                        </IAMButton>
                    )}
                    {(status === 'draft' || status === 'disabled') && !readOnly && (
                        <IAMButton permissions={['component.experiment.update']}>
                            <button
                                onClick={_enableConfig}
                                className="btn btn-light rounded-pill px-4 ms-3 shadow"
                            >
                                {status === 'disabled' ? 'Restart' : 'Start'}
                            </button>
                        </IAMButton>
                    )}
                    {/* {status === 'archive' && (
                        <IAMButton permissions={['component.experiment.update']}>
                            <button
                                onClick={_restoreConfig}
                                className="btn btn-light rounded-pill px-4 mx-3 shadow"
                            >
                                Restore
                            </button>
                        </IAMButton>
                    )} */}
                    {readOnly && !canUpdateLive ? (
                        <TooltipWrapper
                            content={'You cannot make any changes to ' + (status || '') + ' experiments'}
                            icon="none"
                        >
                            <div className="px-3 py-1 border border-warning bg-warning-subtle ms-3 rounded-2">
                                <i className="fas fa-sm fa-info-circle me-3" />
                                Read only
                            </div>
                        </TooltipWrapper>
                    ) : (
                        (status === 'live' || status === 'disabled' || status === 'draft') && (
                            <IAMButton permissions={['component.experiment.update']}>
                                <ProcessBlockedWrapper component={component.id}>
                                    <button
                                        disabled={!isBlocked}
                                        onClick={readOnly && canUpdateLive ? _updateLive : _updateConfig}
                                        className="btn btn-success rounded-pill ms-3 px-4 shadow"
                                    >
                                        Publish changes
                                    </button>
                                </ProcessBlockedWrapper>
                            </IAMButton>
                        )
                    )}
                </ComponentHeader>

                {data && (
                    <div className="mt-4">
                        <div className="card shadow mb-4">
                            <div className="row card-body">
                                <div className="col-lg-6 col-md-12">
                                    <ConfigReadOnlyInfo data={data} />
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <Input
                                        name="name"
                                        title="Display Name"
                                        value={data.name}
                                        disabled={readOnly && !canUpdateLive}
                                        onChange={handleChange}
                                    />
                                    <TextArea
                                        name="description"
                                        rows={4}
                                        title="Description"
                                        value={data.description}
                                        disabled={readOnly && !canUpdateLive}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <TimeTrigger
                            onError={onError}
                            errorId={0}
                            mode="default"
                            className="shadow mt-4"
                            readOnly={readOnly}
                            canUpdateLive={canUpdateLive}
                            data={data}
                            setData={setData}
                        />

                        <ConditionsTrigger
                            onError={onError}
                            readOnly={readOnly}
                            canUpdateLive={canUpdateLive}
                            errorId={1}
                            className="shadow mt-4"
                            data={data}
                            setData={setData}
                        />

                        <VariantsSetup
                            className="shadow mt-4"
                            data={data}
                            setData={setData}
                            readOnly={readOnly}
                            canUpdateLive={canUpdateLive}
                        />
                        <ConfigsSetup
                            className="shadow mt-4"
                            data={data}
                            setData={setData}
                            readOnly={readOnly}
                        />
                        <ContentSetup
                            className="shadow mt-4"
                            data={data}
                            setData={setData}
                            readOnly={readOnly && !canUpdateLive}
                        />
                    </div>
                )}

                {!data && (
                    <div className="mt-4">
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" height={220} />
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" height={250} />
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" height={250} />
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" height={300} />
                    </div>
                )}
            </div>
        </>
    );
};

const useOverlap = (gameId, component, data) => {
    const [overlaps, setOverlaps] = useState([]);
    // const [cb, setCb] = useState([]);

    const cb = useRef(null);

    const checkOverlap = async (_cb) => {
        try {
            const result = await checkExperimentOverlap(gameId, component.url, {
                id: data.id,
                configs: data.configs,
                time: data.time,
                conditions: data.conditions
            });

            if (result.length) {
                utils.popup('hide');
                setOverlaps(result);
                cb.current = _cb;
            } else {
                _cb();
            }
        } catch (err) {
            utils.hintError(err);
            setOverlaps([]);
            _cb();
        }
    };

    const onHide = () => {
        setOverlaps([]);
        cb.current = null;
    };

    const onConfirm = () => {
        setOverlaps([]);
        utils.popup('saving');
        cb.current();
        cb.current = null;
    };

    const OverlapModal = (
        <Modal
            title={
                <>
                    <i className="fas fa-exclamation-triangle text-warning me-2" />
                    Experiment overlap
                </>
            }
            size="xs"
            show={!!overlaps.length}
            onHide={onHide}
        >
            <div className="modal-body">
                <div className="text-muted">
                    This experiment overlaps with the following running experiments:{' '}
                </div>

                <ul>
                    {overlaps.map((e, i) => (
                        <li className="fw-bold mx-2 mt-2" key={i}>
                            <Link to={'/game/' + gameId + '/config/' + component.url + '/view/' + e.id}>
                                {e.id}
                            </Link>
                            :
                            <ul>
                                {e.configs.map((c, i) => (
                                    <li>
                                        {ComponentsMap[c.component].name} - {c.id}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
                <div className="alert alert-info">
                    Experiment can run for same users as other experiments and may produce unexpected results
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                </button>
                <button onClick={onConfirm} className="btn btn-danger">
                    Continue
                </button>
            </div>
        </Modal>
    );

    return [checkOverlap, OverlapModal];
};

const useEditLock = (status) => {
    const game = useGame();

    const isProd = game.isProduction;

    const { userPermissions } = useUserIAM();

    if (isProd && status === 'live') {
        const canUpdateLive = (userPermissions || []).indexOf('component.experiment.updatelive') > -1;

        return [true, canUpdateLive];
    } else if (isProd && status === 'disabled') {
        return [true, false];
    } else return [false, false];
};

import { CountriesObj, Languages, LanguagesInverted } from 'consts';
import { utils, M } from '@gpg-web/utils';
import Colors from './Colors';

const $ = window.$;

const Utils = {

    simpleHashCode: function(text, limit = 1000) {
        let hashCode = 0;
        for (let i = 0; i < text.length; i++) {
            hashCode += text.charCodeAt(i) * 67;
        }

        if (limit === false) return hashCode;

        return hashCode % limit;
    },

    hashColor: function(text, luminance = 25) {
        const hashCode = Utils.simpleHashCode(text, false);
        const color = Math.floor(((hashCode % 9999) / 9999) * 360);
        const saturation = M.clamp(20, 30, hashCode % 30); // 20

        return Colors.HSLToRGB(color, saturation, luminance)
    },

    parsePath: function (inputPath) {
        const splited = inputPath.split('/');
        const name = splited.pop();
        let path = splited.join('/');
        if (path) path += '/';

        return { path, name };
    },

    generateStoragePath: function (inputPath, version) {
        const { path, name } = this.parsePath(inputPath);
        return path + version + '-' + name;
    },

    gameStoragePath: function (gameId, assetPath) {
        return '/api/storage/' + gameId + '/content/' + assetPath;
    },

    gameHostingPath: function (gameId, assetPath) {
        return '/api/game-hosting/' + gameId + '/' + assetPath;
    },

    countryName: function (code) {
        return CountriesObj[code] || code;
    },

    languageName: function (code) {
        return Languages[code] || code;
    },

    languageCode: function (name) {
        return LanguagesInverted[name];
    },

    className: function (c1, c2, c3) {
        if (c3) return c1 + ' ' + c2 + ' ' + c3;
        if (c2) return c1 + ' ' + c2;
        return c1;
    },

    formatDateToIntput: function (date) {
        if (date) {
            date = new Date(date);

            date.setTime(date.getTime() - date.getTimezoneOffset() * 1000 * 60);

            const isoString = date.toISOString();

            return isoString.substring(0, ((isoString.indexOf('T') | 0) + 6) | 0);
        }
    },

    formatDatetoISOString: function (date) {
        if (!date) return '';

        date = new Date(date);

        date.setTime(date.getTime() - date.getTimezoneOffset() * 1000 * 60);

        var str = date.toISOString();
        return str.substring(0, ((str.indexOf('T') | 0) + 9) | 0);
    },

    // parseFirebaseRCDate: function (expression) {
    //     if (expression.indexOf('dateTime') === -1) return null;

    //     const operator = utils.substr(expression, 'dateTime ', ' ') || '<';
    //     let dateSrc = utils.substr(expression, "dateTime\\('", "'");
    //     let timezone = utils.substr(expression, ", '", "'\\)");

    //     if (!dateSrc) {
    //         return {
    //             src: dateSrc,
    //             input: '',
    //             operator: operator
    //         };
    //     }

    //     let date = new Date(dateSrc);

    //     if (date.toString() !== 'Invalid Date') {
    //         if (timezone) {
    //             date = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
    //             // date.setTime(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
    //         }
    //     }

    //     return {
    //         src: dateSrc,
    //         date: date,
    //         input: Utils.formatDateToIntput(date),
    //         timezone: timezone,
    //         operator: operator
    //     };
    // },

    load: function (loading) {
        if (loading) {
            $('#loadingPage').show();
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
            $('#loadingPage').fadeOut(500);
        }
    },

    clone: function (obj) {
        return JSON.parse(JSON.stringify(obj));
    },

    getDate: function (date) {
        return date.toISOString().split('T')[0];
    },

    inputToNum: function (e) {
        return {
            target: {
                name: e.target.name,
                value: e.target.value ? Number(e.target.value) : void 0
            }
        };
    },

    hide: function (id) {
        $('#' + id).hide();
    },

    show: function (id) {
        $('#' + id).show();
    },

    el: function (id) {
        return document.getElementById(id);
    },

    ev: function (id) {
        return Utils.el(id).value;
    },

    popupCenter: function (url, options) {
        options = options || {};

        let { title, w, h } = options;

        w = w || window.screen.width * 0.6;
        h = h || window.screen.height * 0.6;

        const left = window.screen.width / 2 - w / 2;
        const top = window.screen.height / 2 - h / 2;

        const newWindow = window.open(
            url,
            title,
            'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
                w +
                ', height=' +
                h +
                ', top=' +
                top +
                ', left=' +
                left
        );

        if (window.focus) newWindow.focus();

        return newWindow;
    },

    openUrlWindow: function (url, title, w, h) {
        const top = window.outerHeight / 2 + window.screenY - h / 2;
        const left = window.outerWidth / 2 + window.screenX - w / 2;
        return window.open(
            url,
            title,
            `
              scrollbars=yes,
              width=${w}, 
              height=${h}, 
              top=${top}, 
              left=${left}
            `
        );
    },

    isValidKeyName: function (folderName) {
        return folderName && folderName.length > 1 && /^[a-zA-Z0-9/._]+$/.test(folderName);
    },

    isValidFolderName: function (folderName) {
        return folderName && folderName.length > 2 && /^[a-zA-Z0-9._ -]+$/.test(folderName);
    },

    isValidId: function (id) {
        return id && id.length > 2 && /^[a-zA-Z0-9_]+$/.test(id);
    },

    isValidGameId: function (id) {
        if (id && id.length > 2 && /^[a-zA-Z0-9_-]+$/.test(id)) {
            return id[0] !== '-' && id[id.length - 1] !== '-';
        }
        return false;
    }
};

const hourIcons = ['🕛', '🕐', '🕑', '🕒', '🕓', '🕔', '🕕', '🕖', '🕗', '🕘', '🕙', '🕚'];
const hourIconsHalf = ['🕧', '🕜', '🕝', '🕞', '🕟', '🕠', '🕡', '🕢', '🕣', '🕤', '🕥', '🕦'];

Utils.hourIcon = function hourIcon(date) {
    let hours = date.getHours();
    if (hours > 12) hours -= 12;
    if (hours === 12) hours = 0;

    return date.getMinutes() > 30 ? hourIconsHalf[hours] : hourIcons[hours];
};

export default Utils;

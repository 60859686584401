/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import './banner';
import './scss/index.scss';
import utils from './utils';
import bootstrap from '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-loading-skeleton/dist/skeleton.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { logout } from 'services/user';
import { RouterProvider } from 'react-router-dom';
import { AppRouter } from './AppRouter';

window.u = utils;
window.axios = axios;
window.bootstrap = bootstrap;

axios.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (error) {
        const statusCode = error.response && error.response.status;

        if (statusCode === 401) {
            const redirect = window.location.pathname + window.location.search;

            logout(redirect);
        }

        utils.popup('hide');

        let err = 'Unknown error';

        if (error.response) {
            err = error.response.statusText;

            if (error.response.data);
            {
                let message = error.response.data.msg || error.response.data.message;

                if (error.response.data.redirect) {
                    if (statusCode === 404) {
                        AppRouter.navigate('/page-or-object-not-found?text=' + message);
                    } else if (statusCode === 403) {
                        const { missingPermissions, gameId } = error.response.data.details;

                        if (window.location.pathname.startsWith('/game/' + gameId)) {
                            message = 'clear';

                            AppRouter.navigate(
                                '/game/' +
                                    gameId +
                                    '/missing-permissions?permissions=' +
                                    missingPermissions.join(',')
                            );
                        }
                    } else {
                        AppRouter.navigate('/error?text=' + message);
                    }
                }

                if (message) err = message;
            }
        }

        return Promise.reject(err);
    }
);

document.addEventListener('wheel', function (event) {
    if (document.activeElement.type === 'number') {
        document.activeElement.blur();
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <RouterProvider router={AppRouter} />
    </React.StrictMode>
);

import { gameBalanceCreate } from './gameBalanceCreate';
import { configCleanup } from './configCleanup';
import { configMigrate } from './configMigrate';
import { experimentMigrate } from './experimentMigrate';
import { configPromoteEnvironment } from './configPromoteEnvironment';
import { configPromoteVersion } from './configPromoteVersion';
import { configSyncStorageFile } from './configSyncStorageFile';
import { simpleConfigMigrate } from './simpleConfigMigrate';
import { migrateAll } from './migrateAll';

export const getScriptData = () => ({
    configPromoteEnvironment,
    configPromoteVersion,
    configSyncStorageFile,
    gameBalanceCreate,
    configCleanup,
    configMigrate,
    experimentMigrate,
    simpleConfigMigrate,
    migrateAll
});

const list = getScriptData();

export default list;

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useCallback } from 'react';

import { Modal } from '@gpg-web/react';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';

export const RemoveMultipleFilesModal = (props) => {
    const { onHide, show, isLoading, usage, onConfirm, deleteComponents } = props;

    const [confirm, setConfirm] = useState(false);

    const canConfirm =
        usage && Object.keys(usage).some((key) => usage[key].length !== 0) ? !confirm : isLoading;

    const needToConfirm = usage && Object.keys(usage).some((key) => usage[key].length !== 0);
    return (
        <Modal
            title={
                <span>
                    Deleting &nbsp;
                    <span className="fw-bold text-warning">
                        {deleteComponents && Array.isArray(deleteComponents) ? deleteComponents.length : null}
                    </span>
                    &nbsp; Files/Folders
                </span>
            }
            size="xs"
            show={show}
            onHide={onHide}
        >
            {isLoading ? (
                <div className="modal-body">
                    <Skeleton className="shadow-sm mb-2" height={30} />
                    <Skeleton className="shadow-sm mb-2" height={30} />
                    <Skeleton className="shadow-sm mb-1" height={30} />
                </div>
            ) : (
                <div className="modal-body">
                    <h6 className="text-muted fw-bold mb-2">
                        Are you sure you want to remove all selected objects?
                    </h6>

                    {usage && (
                        <>
                            {deleteComponents.map((obj) =>
                                usage[obj.path + obj.name].length !== 0 ? (
                                    <div key={obj.id} className="mb-2 mx-2">
                                        <div className="text-muted">
                                            {obj.mime === 'Folder' ? '- Folder ' : '- File '}
                                            <span className="fw-bold text-danger">"{obj.name}"</span> is being
                                            used by:
                                        </div>
                                        {usage[obj.path + obj.name].map((component, i) => (
                                            <div className="fw-bold text-warning mx-2 mt-1" key={i}>
                                                - {component.type ? '(' + component.type + ') ' : null}
                                                {component.name || component.id}
                                            </div>
                                        ))}
                                    </div>
                                ) : null
                            )}

                            {deleteComponents.map((obj) =>
                                usage[obj.path + obj.name].length === 0 ? (
                                    <div key={obj.id} className="mb-2 mx-2">
                                        <div className="text-muted">
                                            {obj.mime === 'Folder' ? '- Folder ' : '- File '}
                                            <span className="fw-bold">"{obj.name}"</span>
                                        </div>
                                    </div>
                                ) : null
                            )}
                            {!needToConfirm ? null : (
                                <div className="m-2 mt-4 mx-0">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="checkbox-confirm-delete"
                                            checked={confirm}
                                            onChange={(e) => setConfirm(e.target.checked)}
                                        />
                                        <label
                                            className="form-check-label text-muted"
                                            htmlFor="checkbox-confirm-delete"
                                        >
                                            I still want to remove these{' '}
                                            <span className="fw-bold">
                                                {deleteComponents && Array.isArray(deleteComponents)
                                                    ? deleteComponents.length
                                                    : null}{' '}
                                                files
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            <div className="modal-footer">
                <button className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                </button>
                <button disabled={canConfirm} onClick={onConfirm} className="btn btn-danger">
                    {'Confirm'}
                </button>
            </div>
        </Modal>
    );
};

export const useRemoveMultipleFiles = () => {
    const [deleteModal, setDeleteModal] = useState({
        show: false
    });

    const showRemoveModal = useCallback((getUsage, deleteComponents, onRemove) => {
        setDeleteModal({
            show: true,
            isLoading: true,
            usage: null,
            deleteComponents: deleteComponents
        });

        getUsage
            .then((usage) => {
                setDeleteModal({
                    show: true,
                    isLoading: false,
                    usage: usage,
                    deleteComponents: deleteComponents,
                    onConfirm: () => {
                        onRemove();
                        setDeleteModal({ show: false });
                    }
                });
            })
            .catch((e) => {
                setDeleteModal({ show: false });
                utils.hintError(e);
            });
    }, []);

    const RemoveModal = (
        <RemoveMultipleFilesModal
            key={
                deleteModal.deleteComponents
                    ? deleteModal.deleteComponents.map((obj) => obj.id + obj.name).join('-')
                    : 'RemoveMultipleModal'
            }
            onHide={() => setDeleteModal({ show: false })}
            {...deleteModal}
        />
    );

    return [RemoveModal, showRemoveModal];
};

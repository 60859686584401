import { useState } from 'react';
import { utils } from '@gpg-web/utils';
import { getConfig, updateConfig } from 'services/config';
import { IAMButton, ComponentHeaderSimple, useGame, usePageBlocker, useEnvironmental } from 'components';
import { ChangeHistory } from '../../components/ChangeHistory';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ContentView } from './Content';
import Skeleton from 'react-loading-skeleton';

export const ConfigBuiltInUnityAddressables = () => {
    const game = useGame();

    const [content, setContent] = useState(null);

    const gameId = game.id;

    const [updateInitialData, , isBlocked] = usePageBlocker(content);

    const queryClient = useQueryClient();

    const {
        environments,
        selectedEnvironment,
        setSelectedEnvironment,
        gameVersions,
        selectedGameVersion,
        setSelectedGameVersion,
        selectedPath
    } = useEnvironmental();

    const { data, isFetching, error, isFetched, isSuccess } = useQuery({
        queryKey: ['unity-addressables', 'built-in', gameId],
        queryFn: () => getConfig(gameId, 'built-in', 'unity-addressables'),
        enabled: !!gameId && !content
    });

    if (!content && !!data && isFetched && isSuccess) {
        setContent(updateInitialData(data?.content || {}));
    }

    const saveChanges = async () => {
        if (!(gameId && content)) return;

        try {
            const yes = await utils.confirm('Are you sure you want to update unity addressables config?');
            if (!yes) return;

            utils.popup('saving');

            const result = await updateConfig(gameId, 'built-in', {
                id: 'unity-addressables',
                content: content
            });

            queryClient.invalidateQueries({
                queryKey: ['unity-addressables', 'built-in', gameId],
                exact: true
            });

            setContent(updateInitialData(result.content));

            utils.popup('hide');
        } catch (err) {
            utils.hintError(err);
        }
    };

    const platformContent = {
        ios: content?.ios || {
            path: 'configs/v2/built-in/unity-addressables/ios/unity-addressables-ios.json',
            versions: {}
        },
        android: content?.android || {
            path: 'configs/v2/built-in/unity-addressables/android/unity-addressables-android.json',
            versions: {}
        }
    };

    const isReady = isFetched && isSuccess;

    const isLoading = !content || isFetching;

    const onChange = (platform, path, ver) => {
        setContent({
            ...content,
            [platform]: {
                ...platformContent[platform],
                versions: {
                    ...platformContent[platform].versions,
                    [path]: { version: ver }
                }
            }
        });
    };

    return (
        <div className="container-lg">
            <ComponentHeaderSimple gameId={gameId} name="Unity Addressables">
                {data && <ChangeHistory />}
                <IAMButton permissions={['resourcemanager.projects.update']}>
                    <button
                        disabled={!isBlocked || isFetching}
                        className="btn rounded-pill px-4 ms-3 btn-success shadow"
                        onClick={saveChanges}
                    >
                        Publish changes
                    </button>
                </IAMButton>
            </ComponentHeaderSimple>

            {error && (
                <div className="alert alert-danger mt-4">
                    <i className="fas fa-exclamation-circle fa-sm me-2" />
                    {error}
                </div>
            )}

            <div className="card position-static">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                    <h6 className="m-0 fw-bold text-primary">Content</h6>
                </div>
                <div className="card-body position-relative">
                    {isReady && (
                        <>
                            <div className="mb-3 d-flex border-bottom pb-3">
                                <div className="d-flex align-items-center position-relative">
                                    <select
                                        value={selectedEnvironment}
                                        className="form-select form-select-sm w-150px"
                                        onChange={(e) => setSelectedEnvironment(e.target.value)}
                                    >
                                        {!!environments ? (
                                            environments.map((e) => (
                                                <option key={e.id} value={e.id}>
                                                    {e.id}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>Loading..</option>
                                        )}
                                    </select>
                                    <select
                                        value={selectedGameVersion}
                                        className="form-select form-select-sm w-150px ms-3"
                                        onChange={(e) => setSelectedGameVersion(e.target.value)}
                                    >
                                        {!!gameVersions ? (
                                            gameVersions.map((e) => (
                                                <option key={e.id} value={e.id}>
                                                    {e.id}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>Loading..</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <div className="p-2 w-100">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-6 border-lg-end border-md-bottom border-lg-bottom-0">
                                            <ContentView
                                                selectedPath={selectedPath}
                                                gameVersion={selectedGameVersion}
                                                environment={selectedEnvironment}
                                                readOnly={false}
                                                content={platformContent['android']}
                                                onChange={(path, ver) => onChange('android', path, ver)}
                                                title={'Android'}
                                            />
                                        </div>
                                        <div className="col-md-12 col-lg-6">
                                            <ContentView
                                                title={'iOS'}
                                                selectedPath={selectedPath}
                                                gameVersion={selectedGameVersion}
                                                environment={selectedEnvironment}
                                                readOnly={false}
                                                content={platformContent['ios']}
                                                onChange={(path, ver) => onChange('ios', path, ver)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {isLoading && (
                        <>
                            <Skeleton className="my-2" width="60%" height={30} />
                            <Skeleton className="my-2" width="30%" height={60} />
                            <Skeleton className="my-2" width="100%" height={50} count={2} />
                            <Skeleton className="my-2" width="90%" height={90} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

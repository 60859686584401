import { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ComponentsMap } from 'consts';
import { useGame, Backdrop, useEnvironmental, TooltipWrapper } from 'components';
import { getConfigsWithContent } from 'services/config';
import { getStorageAssetRecursively, generatePatch } from 'services/storage';
import className from 'classnames';
import { ConfigsSelect } from './ConfigsSelect';
import { SelectedConfig } from './SelectedConfig';
import Skeleton from 'react-loading-skeleton';
import { utils } from '@gpg-web/utils';
import { BrowseStorage } from '../../BrowseStorage';
import { FileViewPopover } from '../../FileViewPopover';

const gameBalanceComponent = ComponentsMap['gameBalance'];
let scrollIntoViewTimeout;

export const PatchView = (props) => {
    const { value, editing, onChange, onError, error: shouldGeneratePatch } = props;

    const [selectingDestinationFile, setSelectingDestinationFile] = useState(-1);
    const [selectingFolder, setSelectingFolder] = useState(false);
    const [selectingVersion, setSelectingVersion] = useState({});

    let { gameVersion, environment } = props;
    const containerRef = useRef();
    const browserStorageRef = useRef();
    const browserStorageFolderRef = useRef();
    const errorRef = useRef();

    const queryClient = useQueryClient();
    const game = useGame();
    const gameId = game.id;

    /**
     * Validation start
     */
    useEffect(() => {
        if (!value) onChange([]);
        else if (!Array.isArray(value)) onChange([]);
        else if (
            value[0] &&
            (typeof value[0] !== 'object' || !(value[0].id && value[0].src && value[0].dst))
        ) {
            onChange([]);
        }
    }, [value, onChange]);
    /**
     * Validation end
     */

    let selectedConfigs = value;
    if (!Array.isArray(selectedConfigs)) selectedConfigs = [];

    let {
        data: configs,
        isFetching,
        error: configsError
    } = useQuery({
        queryKey: [gameBalanceComponent.id, gameId, 'content'],
        queryFn: () => getConfigsWithContent(gameId, gameBalanceComponent.url),
        enabled: !!gameId
    });

    if (configs) {
        configs.forEach((config) => {
            config.component = 'gameBalance';
        });
    }

    const { selectedEnvironment, selectedGameVersion } = useEnvironmental();

    if (!gameVersion) gameVersion = selectedGameVersion;
    if (!environment) environment = selectedEnvironment;

    const selectedPath = environment + gameVersion + '/';

    function refresh() {
        let globalError = false;
        let error = false;

        for (let config of selectedConfigs) {
            globalError = globalError || !config.patch;
            error =
                error || !(config.src.path && config.src.version && config.dst.path && config.dst.version);
            if (globalError && error) break;
        }

        if (error) {
            errorRef.current = 'You should fill all the sources and destination files and it versions';
        } else {
            errorRef.current = false;
        }
        onError(globalError);

        onChange(selectedConfigs.slice());
    }

    function addConfig(data) {
        const config = configs.find((e) => e.id === data.id && e.component === data.component);
        if (!config) return utils.hintError('Config not found');

        const content = config.content || {};

        if (!content.path) {
            return utils.hintError('Invalid config: content path is not specified');
        }

        const src = { path: content.path };

        if (content.versions && content.versions[selectedPath] && content.versions[selectedPath].version) {
            src.version = content.versions[selectedPath].version;
        }

        selectedConfigs.push({
            id: config.id,
            component: config.component,
            src: src,
            dst: {},
            patch: null
        });
        refresh();
    }

    function onDestinationFileSelect(data) {
        if (!~selectingDestinationFile) return;

        selectedConfigs[selectingDestinationFile].dst = data;
        selectedConfigs[selectingDestinationFile].patch = null;
        refresh();
    }

    function onVersionSelect(data) {
        selectedConfigs[selectingVersion.index][selectingVersion.type].version = data.version;
        selectedConfigs[selectingVersion.index].patch = null;
        setSelectingVersion({});
        refresh();
    }

    function selectFile(configIndex) {
        // onBlock && onBlock(browsingStorage);
        setSelectingDestinationFile(configIndex);

        clearTimeout(scrollIntoViewTimeout);
        scrollIntoViewTimeout = setTimeout(() => {
            browserStorageRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }

    function selectFolder() {
        // onBlock && onBlock(browsingStorage);
        setSelectingFolder(true);

        clearTimeout(scrollIntoViewTimeout);
        scrollIntoViewTimeout = setTimeout(() => {
            browserStorageFolderRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }

    function removeConfig(index) {
        selectedConfigs.splice(index, 1);
        refresh();
    }

    function tryFillConfigs(path) {
        utils.popup('loading');

        queryClient
            .fetchQuery({
                queryKey: ['storage', 'recursively', gameId, ...path.split('/').slice(0, -1)],
                queryFn: () => getStorageAssetRecursively(gameId, path)
            })
            .then((data) => {
                utils.popup('hide');
                let foundCount = 0;
                if (!data.directory) return utils.hintError("Can't find assets");

                let assets = data.assets.filter((a) => a.mime === 'application/json');

                for (let config of selectedConfigs) {
                    if (config.dst.path) continue;

                    const sourceName = utils.baseName(config.src.path);
                    const sourceId = config.id;
                    const lsourceName = sourceName.toLowerCase();
                    const lsourceId = sourceId.toLowerCase();

                    let found;

                    for (let asset of assets) {
                        const name = utils.baseName(asset.name);

                        if (sourceId === name || sourceName === name) {
                            found = asset;
                            break;
                        }

                        /**
                         * Let's try to match with less accuracy
                         */
                        const lname = name.toLowerCase();

                        if (lsourceId === lname || lsourceName === lname) {
                            found = asset;
                        }
                    }

                    if (found) {
                        config.dst.path = found.path + found.name;
                        config.dst.version = found.lastVersionId;
                        foundCount++;
                    }
                }

                if (foundCount > 0) {
                    utils.hintOk(foundCount + ' configs have been updated');
                    refresh();
                } else {
                    utils.hint("Can't find any addition matches. Please double check your configs", {
                        alert: 'warning'
                    });
                }
            })
            .catch((err) => {
                utils.hintError(err);
            });
    }

    function generatePatches() {
        const files = [];

        utils.popup('Generating ...');

        for (let config of selectedConfigs) {
            if (!config.patch) {
                files.push({ src: config.src, dst: config.dst });
            }
        }

        generatePatch(gameId, files)
            .then((resultPatches) => {
                utils.popup('hide');
                for (let config of selectedConfigs) {
                    if (config.patch) continue;

                    const result = resultPatches.find(
                        (e) =>
                            e.src.path === config.src.path &&
                            e.src.version === config.src.version &&
                            e.dst.path === config.dst.path &&
                            e.dst.version === config.dst.version
                    );
                    if (!result) {
                        utils.hintError("Patch wan't generated for " + config.id);
                        continue;
                    }

                    config.patch = result.patch;
                }
                utils.hintOk('Done');
                refresh();
            })
            .catch(utils.hintError);
    }

    if (isFetching) {
        return (
            <div ref={containerRef} className="mt-4 px-3">
                <div className="row mb-2">
                    <div className="col-lg-6 col-md-12">
                        <Skeleton height={120} />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <Skeleton height={60} width="60%" />
                        <Skeleton height={20} width="90%" />
                        <Skeleton height={30} width="70%" />
                    </div>
                </div>
                <Skeleton count={5} height={40} className="mb-2" />
            </div>
        );
    }

    return (
        <div ref={containerRef} className="mt-4 px-3">
            {configsError && <div className="alert alert-danger">{configsError}</div>}

            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="alert alert-info mb-2 px-2 py-2 small">
                        <div>
                            Configs selected: <b>{selectedConfigs.length}</b>
                        </div>
                        <div>
                            Patched values in total:{' '}
                            <b>
                                {selectedConfigs.reduce(
                                    (accum, config) => accum + (config.patch?.length || 0),
                                    0
                                )}
                            </b>
                        </div>
                        <div>
                            File size: <b>{utils.bytesToStr(JSON.stringify(value).length)}</b>
                        </div>
                    </div>
                </div>
                {editing && (
                    <div className="col-lg-6 col-md-12">
                        {errorRef.current && (
                            <div className="alert alert-danger px-2 py-1 small">
                                <i className="fas fa-exclamation-circle me-2 fa-sm" />
                                {errorRef.current}
                            </div>
                        )}
                        {!errorRef.current && shouldGeneratePatch && (
                            <>
                                <div className="alert alert-warning px-2 py-1 mb-2 small">
                                    <i className="fas fa-exclamation-triangle me-2 fa-sm" />
                                    You should generate patch before publishing a new version
                                </div>
                                <div className="text-center">
                                    <button
                                        onClick={generatePatches}
                                        className="btn btn-success shadow rounded-pill px-4"
                                    >
                                        Generate patches
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            <Backdrop
                onClick={() => setSelectingDestinationFile(-1)}
                show={!!~selectingDestinationFile}
                absolute
                style={{ minWidth: '80%' }}
            >
                <div ref={browserStorageRef} className="mb-4 shadow">
                    <BrowseStorage
                        accept={'application/json'}
                        simplified
                        requiredFileVersion
                        onSelect={onDestinationFileSelect}
                        onCancel={(e) => setSelectingDestinationFile(-1)}
                        gameId={gameId}
                    />
                </div>
            </Backdrop>

            <Backdrop
                onClick={() => setSelectingFolder(false)}
                show={selectingFolder}
                absolute
                style={{ minWidth: '80%' }}
            >
                <div ref={browserStorageFolderRef} className="mb-4 shadow">
                    <BrowseStorage
                        foldersOnly
                        isFolderSelect
                        simplified
                        asStringPath
                        onSelect={tryFillConfigs}
                        onCancel={(e) => setSelectingFolder(false)}
                        gameId={gameId}
                    />
                </div>
            </Backdrop>

            <div
                style={{ maxHeight: props.fluid ? undefined : '50vh' }}
                className={className('mt-3 overflow-x-auto', { 'overflow-y-auto': !props.fluid })}
            >
                <table className="table table-sm table-striped">
                    <thead className="small">
                        <tr>
                            <th>Config</th>
                            <th>Source file</th>
                            <th>
                                Destination file{' '}
                                {editing && (
                                    <TooltipWrapper
                                        icon={false}
                                        content="Select folder with configs. System will try to find and fill the destination files by config id or source file name. Last file versions will be selected."
                                    >
                                        <button
                                            title="Select file"
                                            onClick={selectFolder}
                                            className="btn btn-sm border-0"
                                        >
                                            <i className="fas fa-folder-open text-warning" />
                                        </button>
                                    </TooltipWrapper>
                                )}
                            </th>
                            <th>Patch</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody className="small">
                        {selectedConfigs.map((e, index) => {
                            const config = configs.find(
                                (config) => config.id === e.id && config.component === e.component
                            );

                            const originalContent = {};

                            if (config && config.content) {
                                originalContent.path = config.content.path;
                                originalContent.version = config.content.versions?.[selectedPath]?.version;
                            }

                            return (
                                <SelectedConfig
                                    key={index}
                                    index={index}
                                    gameId={gameId}
                                    removeConfig={removeConfig}
                                    selectFile={selectFile}
                                    selectVersion={setSelectingVersion}
                                    value={e}
                                    originalContent={originalContent}
                                    readOnly={!editing}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <FileViewPopover
                key={editing}
                editInModal={true}
                appendTo={containerRef.current}
                parent={selectingVersion.parent}
                show={!!selectingVersion.path}
                path={selectingVersion.path}
                onSelect={onVersionSelect}
                canDiscard={false}
                version={selectingVersion.version}
                onHide={() => setSelectingVersion({})}
            />

            {selectedConfigs.length === 0 && <div className="text-gray-500 mt-2">Nothing selected</div>}

            {editing && (
                <ConfigsSelect
                    containerRef={containerRef}
                    configs={configs}
                    selectedConfigs={selectedConfigs}
                    addConfig={addConfig}
                />
            )}
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useMemo, useState, useEffect } from 'react';
import { useGame } from 'components';
import { Table } from '@gpg-web/react';
import { getRoles } from 'services/iam';
import { utils } from '@gpg-web/utils';

const columns = [
    {
        Header: 'Title',
        accessor: 'title'
    },
    {
        Header: 'Group',
        accessor: 'groupTitle'
    },
    {
        Header: 'Description',
        accessor: 'description'
    }
];

export const RolesSettings = (props) => {
    const [roles, setRoles] = useState(null);
    const game = useGame();

    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getRoles(gameId).then(setRoles).catch(utils.hintError);
    }, [gameId]);

    const data = useMemo(() => {
        if (!roles) return null;

        return Object.keys(roles).map((key) => {
            return {
                id: roles[key].id,
                title: roles[key].title,
                groupTitle: roles[key].groupTitle,
                description: roles[key].description
            };
        });
    }, [roles]);

    return (
        <div className="container-lg">
            <div className="d-flex align-items-center">
                <h4 className="mb-0 me-3">Roles</h4>
                <button disabled className="btn btn-light text-primary">
                    {' '}
                    <i className="fa fa-plus me-1" /> CREATE ROLE
                </button>
            </div>

            <div className="mt-4">{!!data && <Table columns={columns} data={data} pageSize={50} />}</div>
        </div>
    );
};

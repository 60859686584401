/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getStorageAsset,
    downloadStorageFile,
    uploadStorageFile,
    updateFile,
    uploadStorageFileDraft,
    deleteStorageFileDraft
} from 'services/storage';
import { userContext } from 'services/user';
import { FileIcon, TooltipWrapper, useGame, usePageBlocker } from 'components';
import { TextMimes } from 'consts';
import { BaseView } from './views';
import { FileComparisonModal } from './FileComparison';
import { utils, date as dateUtils } from '@gpg-web/utils';
import { Spinner } from '@gpg-web/react';
import { VersionList } from './FileVersions';
import { FileNoPreview } from './FileNoPreview';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const File = (props) => {
    /**
     * because of useQuery: path.split function
     */
    const path = props.path || '';
    const user = useContext(userContext);
    const game = useGame();
    let [versions, setVersions] = useState(null);
    let [selectedVersion, setSelectedVersion] = useState(null);
    const [editing, setEditing] = useState(false);
    const [filterVersions, setFilterVersions] = useState('');
    const [comparing, setComparing] = useState(null);
    const history = useNavigate();
    const gameId = game.id;

    const [hasChanges, setChanges] = useState(false);
    usePageBlocker(hasChanges);
    const queryClient = useQueryClient();
    const {
        isFetching,
        error,
        data: file
    } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/')],
        queryFn: () => getStorageAsset(gameId, path),
        enabled: !!(gameId && path)
    });

    if (file) {
        if (!versions) versions = file.versions;
        if (!selectedVersion) selectedVersion = file.versions[0];
    }

    let previewType = null;

    if (file) {
        if (file.mime === 'text/html') previewType = 'html';
        else if (file.mime === 'application/json') previewType = 'json';
        else if (TextMimes.includes(file.mime)) previewType = 'text';
    }

    const toggleVersionArchive = (version, val = false) => {
        utils.popup('saving');
        updateFile(gameId, file.id, { versions: [{ ...version, archived: val }] })
            .then((data) => {
                file.versions = data.versions;
                setVersions(file.versions);

                utils.hintOk(
                    !val
                        ? '#' + version.id + ' was restored from archive'
                        : '#' + version.id + ' was archived'
                );
                utils.popup('hide');
            })
            .catch(utils.hintError);
    };

    const uploadNewVersion = (newContent, cb) => {
        utils.promt(
            'Are you sure you want to upload a new version of ' + file.name,
            function (message) {
                if (message !== false) {
                    utils.popup('uploading');

                    const blob = new Blob([newContent], { type: file.mime });

                    uploadStorageFile(gameId, blob, {
                        path: file.path,
                        name: file.name,
                        message,
                        authorEmail: user.email,
                        authorName: user.name
                    })
                        .then((data) => {
                            utils.popup('hide');

                            queryClient.removeQueries({
                                queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)],
                                exact: true
                            });

                            Object.assign(file, data);
                            setVersions(file.versions);
                            setSelectedVersion(file.versions[0]);
                            // setFile();

                            utils.hintOk(file.name + ' file history updated');

                            cb && cb();
                        })
                        .catch(utils.hintError);
                }
            },
            { value: '', label: 'Version description', placeholder: file.name + ' updated' }
        );
    };

    const uploadDraftVersion = (newContent, cb) => {
        const uploadContent = (draftId) => {
            utils.popup('uploading');

            const blob = new Blob([newContent], { type: file.mime });

            uploadStorageFileDraft(gameId, blob, {
                path: file.path,
                name: file.name,
                id: draftId,
                // message,
                authorEmail: user.email,
                authorName: user.name
            })
                .then((data) => {
                    utils.popup('hide');

                    queryClient.removeQueries({
                        queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)],
                        exact: true
                    });
                    // queryClient.invalidateQueries({
                    //     queryKey: ['storage', gameId, ...path.split('/')],
                    //     exact: true
                    // });

                    Object.assign(file, data);
                    setVersions(file.versions);
                    setSelectedVersion(file.versions.find((v) => v.id === draftId));
                    // setFile();

                    utils.hintOk(file.name + ' file history updated');

                    cb && cb();
                })
                .catch(utils.hintError);
        };

        if (selectedVersion && selectedVersion.isDraft) {
            const draftId = selectedVersion.id;

            uploadContent(draftId);
        } else {
            utils.promt(
                'Are you sure you want to upload a draft version of ' + file.name,
                function (draftId) {
                    if (draftId === false) return;

                    if (utils.isValidId(draftId)) {
                        uploadContent('draft.' + draftId);
                    } else {
                        utils.hintError(
                            'Draft ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed'
                        );
                    }
                },
                { value: '', label: 'Draft ID', placeholder: '' }
            );
        }
    };

    const deleteDraftVersion = (draftVersion, cb) => {
        const deleteDraft = (draftId) => {
            utils.popup('removing');

            deleteStorageFileDraft(gameId, {
                path: file.path,
                name: file.name,
                authorEmail: user.email,
                authorName: user.name,
                id: draftId
            })
                .then((data) => {
                    utils.popup('hide');

                    queryClient.removeQueries({
                        queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)],
                        exact: true
                    });

                    queryClient.invalidateQueries({
                        queryKey: ['storage', gameId, ...path.split('/'), draftId, 'content'],
                        exact: true
                    });

                    Object.assign(file, data);

                    setVersions(file.versions);

                    const nextSelectedVersion =
                        selectedVersion &&
                        selectedVersion.id &&
                        file.versions.find((v) => v.id === selectedVersion.id);

                    setSelectedVersion(nextSelectedVersion ? nextSelectedVersion : null);

                    utils.hintOk(file.name + ' file history updated');

                    cb && cb();
                })
                .catch(utils.hintError);
        };

        utils.confirm(
            'Are you sure you want to delete draft - "' + draftVersion.id.replace('draft.', '') + '"?',
            (yes) => {
                if (yes) {
                    deleteDraft(draftVersion.id);
                }
            }
        );

        // if (selectedVersion && selectedVersion.isDraft) {
        // const draftId = selectedVersion.id.replace('draft_', '');

        // } else {
        // utils.promt(
        //     'Are you sure you want to upload a draft version of ' + file.name,
        //     function (draftId) {
        //         if (draftId !== false && utils.isValidId(draftId)) {
        //             uploadContent(draftId);
        //         } else {
        //             utils.hintError('Draft ID is not valid');
        //         }
        //     },
        //     { value: '', label: 'Draft ID', placeholder: '' }
        // );
        // }
    };
    const _renameFile = () => {
        const splitted = file.name.split('.');
        const extension = splitted.pop();

        utils.promt(
            'Are you sure you want to rename this file?',
            function (message) {
                if (message !== false) {
                    if (!message) return utils.hintError('Filename cannot be empty!');

                    if (extension) message = message + '.' + extension;

                    if (message === file.name) return;

                    utils.popup('saving');

                    updateFile(gameId, file.id, { name: message, path: file.path })
                        .then((data) => {
                            queryClient.removeQueries({
                                queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)]
                            });

                            utils.popup('hide');
                            history('/game/' + gameId + '/storage/' + file.path + data.name);
                        })
                        .catch(utils.hintError);
                }
            },
            { value: splitted.join('.'), label: 'Enter filename', placeholder: 'Filename *' }
        );
    };

    const downloadVersion = (versionId) => {
        versionId = versionId !== undefined ? versionId : selectedVersion.id;
        downloadStorageFile(gameId, file.path + file.name, versionId);
    };

    const downloadCurrentVersion = () => {
        downloadVersion(selectedVersion.id);
    };

    const versionProps = {
        canCompare: previewType !== null,
        onShow: setSelectedVersion,
        // onSelect: setSelectedVersion,
        onDownload: downloadVersion,
        onCompare: setComparing,
        onDeleteDraft: deleteDraftVersion,
        canSelect: false,
        canArchive: true,
        canShow: true,
        onArchive: toggleVersionArchive
    };
    return (
        <>
            {!isFetching && !!file && !!comparing && (
                <FileComparisonModal
                    gameId={gameId}
                    fileName={file.path + file.name}
                    versions={versions}
                    parseJSON={previewType === 'json'}
                    nextVersionId={comparing.id}
                    prevVersionId={comparing.parentId}
                    onCancel={() => setComparing(null)}
                />
            )}

            {!isFetching && !!file && (
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div style={{ maxHeight: 'calc(98vh - 250px)', overflowY: 'auto' }} className="pe-3">
                            {previewType !== null && (
                                <BaseView
                                    fluid
                                    json={previewType === 'json'}
                                    gameVersion={props.gameVersion}
                                    environment={props.environment}
                                    uploadNewVersion={uploadNewVersion}
                                    uploadDraftVersion={uploadDraftVersion}
                                    onEditing={setEditing}
                                    editing={editing}
                                    gameId={gameId}
                                    isDraft={selectedVersion.isDraft}
                                    path={file.path + file.name}
                                    versionId={selectedVersion.id}
                                    hasChanges={setChanges}
                                    renderAs={file.contentType || previewType}
                                />
                            )}

                            {previewType === null && (
                                <FileNoPreview
                                    file={file}
                                    version={selectedVersion}
                                    onDownload={downloadCurrentVersion}
                                />
                            )}
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-12 border-start' + (editing ? ' disabled' : '')}>
                        <div
                            id="file-metadata"
                            style={{ height: 'calc(98vh - 250px)', minHeight: '100%', overflowY: 'auto' }}
                        >
                            <TabView
                                tabs={['Details', 'Versions', 'Archive']}
                                panes={[
                                    <FileDetails
                                        file={file}
                                        version={selectedVersion}
                                        onDownload={downloadCurrentVersion}
                                        onRename={_renameFile}
                                    />,
                                    <>
                                        <input
                                            className="form-control mt-3"
                                            type="search"
                                            placeholder="Search..."
                                            value={filterVersions}
                                            onChange={(e) => setFilterVersions(e.target.value)}
                                        />
                                        <VersionList
                                            versions={versions}
                                            showVersionId={selectedVersion.id}
                                            lastVersionId={file.lastVersionId}
                                            // selectedVersionId={selectedVersionId}
                                            versionProps={versionProps}
                                            // scrollParentQuery={scrollParentQuery}
                                            searchFilter={(e) =>
                                                !e.archived &&
                                                (e.id + e.author.name + e.message)
                                                    .toLowerCase()
                                                    .indexOf(filterVersions.toLowerCase()) > -1
                                            }
                                        />
                                    </>,
                                    <>
                                        <VersionList
                                            versions={versions}
                                            showVersionId={selectedVersion.id}
                                            lastVersionId={file.lastVersionId}
                                            //    selectedVersionId={selectedVersionId}
                                            versionProps={versionProps}
                                            // scrollParentQuery={scrollParentQuery}
                                            searchFilter={(e) => e.archived}
                                        />
                                    </>
                                ]}
                            />
                        </div>
                    </div>
                </div>
            )}

            {isFetching && (
                <div className="d-flex my-5 align-items-center justify-content-center">
                    <Spinner size="100px" width="8px" />
                </div>
            )}

            {error && <div className="alert alert-danger">{error}</div>}
        </>
    );
};

const TabView = ({ tabs, panes }) => {
    const [active, setActive] = useState(0);

    return (
        <>
            <ul className="nav nav-tabs" role="tablist">
                {tabs.map((tab, i) => (
                    <li key={i} className="nav-item" role="presentation">
                        <button
                            className={'nav-link' + (i === active ? ' active' : '')}
                            // id={'file-tab-' + key}
                            // data-bs-toggle="tab"
                            // data-bs-target={'#file-' + key}
                            type="button"
                            role="tab"
                            // aria-controls={'file-' + key}
                            aria-selected="true"
                            onClick={() => setActive(i)}
                        >
                            {tab}
                        </button>
                    </li>
                ))}
            </ul>

            <div className="tab-content">
                <div className={'tab-pane show active'} role="tabpanel">
                    {panes[active]}
                </div>
            </div>
        </>
    );
};

const FileDetails = ({ file, version, onRename, onDownload }) => (
    <div className="p-2">
        <div className="d-flex my-2 py-4 justify-content-center">
            <FileIcon mime={file.mime} contentType={file.contentType} size="50px" className="text-muted" />
        </div>
        <div className="d-flex my-2 py-2 border-bottom justify-content-between align-items-center">
            <span className="fw-bold me-auto">Name</span>
            <span className="ms-2 o-hidden text-end">{file.name}</span>
            {file.path === 'PlayerSaves-CheatPanel/' && (
                <button onClick={onRename} title="Rename file" className="btn btn-sm ms-2">
                    <i className="fas fa-pen pe-none" />
                </button>
            )}
        </div>
        <div className="d-flex my-2 py-2 border-bottom justify-content-between">
            <span className="fw-bold">Type</span> {file.mime}
        </div>
        <div className="d-flex my-2 py-2 border-bottom justify-content-between">
            <span className="fw-bold">Size</span> {utils.bytesToStr(file.size)}
        </div>
        <div className="d-flex my-2 py-2 border-bottom justify-content-between">
            <span className="fw-bold">Created</span> {dateUtils(file.createdAt)}
        </div>
        <div className="d-flex my-2 py-2 justify-content-between">
            <span className="fw-bold">Last modified</span> {dateUtils(file.updatedAt)}
        </div>
        <div className="d-flex my-2 py-3 justify-content-center border-bottom">
            <button onClick={onDownload} className="btn btn-sm shadow-sm btn-success">
                <i className="fas fa-sm me-2 fa-download" /> Download
            </button>
        </div>

        <div className="d-flex my-2 pt-2 justify-content-between">
            <span className="fw-bold">Version ID</span> {version.id}
        </div>
        <div className="d-flex pb-2 justify-content-between border-bottom small text-muted">
            <TooltipWrapper
                layout="inline-end"
                icon="fas fa-question-circle"
                content="This is md5 checksum of the file. Using by system to check for storage duplicates"
            >
                #{version.hash}
            </TooltipWrapper>
        </div>
        <div className="d-flex my-2 py-2 justify-content-between">{version.message}</div>
        <div className="d-flex mt-3 small text-muted">
            {version.author.name + ' <' + version.author.email + '>'}
        </div>
        <div className="d-flex mt-1 small text-muted">{dateUtils(version.date)}</div>
    </div>
);

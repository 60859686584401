/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useCallback } from 'react';
import { getStorageFileContent } from 'services/storage';
import { utils } from '@gpg-web/utils';
import { Spinner, Modal } from '@gpg-web/react';
import { IAMButton, ProductionLockWrapper } from 'components';
import { Views } from './list';
import { JSONView } from './JSON';
import { TextView } from './Text';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const BaseView = (props) => {
    const {
        gameId,
        json,
        fluid,
        versionId,
        isDraft,
        uploadNewVersion,
        uploadDraftVersion,
        editOnly,
        content,
        editing,
        onEditing,
        hasChanges,
        readOnly,
        editInModal
    } = props;

    // const [fileContent, setFileContent] = useState(null);
    // const [contentLoading, setContentLoading] = useState(true);
    // const [editing, setEditing] = useState(false);
    const [editContent, setEditContent] = useState(null);
    const [error, setError] = useState(false);

    const localContent = content != null;

    /**
     * because of useQuery: path.split function
     */
    const path = props.path || '';

    let {
        isFetching: contentLoading,
        error: fileContentError,
        data: loadedFileContent
    } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/'), versionId, 'content'],
        queryFn: () => getStorageFileContent(gameId, path, versionId),
        enabled: !!(gameId && path && versionId)
    });

    const queryClient = useQueryClient();

    let fileContent = null;
    if (localContent) fileContent = content;
    if (loadedFileContent || loadedFileContent === '') fileContent = loadedFileContent;

    const _setEditing = (value) => {
        // setEditing(value);
        onEditing && onEditing(value);

        if (!value) {
            hasChanges && hasChanges(false);
        }
    };

    useEffect(() => {
        if ((editing || editOnly || localContent) && fileContent != null) {
            setEditContent(JSON.parse(JSON.stringify(fileContent)));
            setError(false);
        } else {
            setEditContent(null);
        }
    }, [fileContent, editing, editOnly, localContent]);

    const pushNewVersion = () => {
        try {
            let content = editContent;

            if (json) content = JSON.stringify(editContent);

            uploadNewVersion(content, () => {
                _setEditing(false);
            });
        } catch (e) {
            utils.hintError(e);
        }
    };

    const pushDraftVersion = () => {
        try {
            let content = editContent;

            if (json) content = JSON.stringify(editContent);

            uploadDraftVersion(content, () => {
                _setEditing(false);
                if (versionId.startsWith('draft'))
                    queryClient.invalidateQueries({
                        queryKey: ['storage', gameId, ...path.split('/'), versionId, 'content'],
                        exact: true
                    });
            });
        } catch (e) {
            utils.hintError(e);
        }
    };

    const publishDraftVersion = () => {
        try {
            let content = fileContent;

            if (json) content = JSON.stringify(fileContent);

            uploadNewVersion(content, () => {
                _setEditing(false);
            });
        } catch (e) {
            utils.hintError(e);
        }
    };

    const fileContentStr = JSON.stringify(fileContent);
    const onEditContentChange = useCallback(
        (val) => {
            setEditContent(val);
            hasChanges && hasChanges(fileContentStr !== JSON.stringify(val));
        },
        [hasChanges, fileContentStr]
    );

    let RenderAsComponent = props.renderAs;
    if (typeof RenderAsComponent === 'string') {
        RenderAsComponent = Views[RenderAsComponent] || (json ? JSONView : TextView);
    }

    const editingWithoutModal = editing && !editInModal;

    if (contentLoading) {
        return (
            <div className="my-5 text-center">
                <Spinner size="60px" width="7px" />
                <div className="mt-4">Content loading...</div>
            </div>
        );
    }

    if (fileContentError) {
        return (
            <div className="alert alert-danger">
                <i className="fas fa-exclamation-circle me-2 fa-sm" />
                {fileContentError}
            </div>
        );
    }

    if (fileContent === null) {
        return (
            <div className="alert alert-danger">
                <i className="fas fa-exclamation-circle me-2 fa-sm" />
                File content not found
            </div>
        );
    }

    const fileName = path ? utils.baseName(path, true) : 'new';

    const isChanged = JSON.stringify(fileContent) !== JSON.stringify(editContent);

    return (
        <>
            {!readOnly && !editOnly && !(editingWithoutModal || localContent) && (
                <div className="d-flex justify-content-end align-items-center mb-2">
                    <IAMButton permissions={['component.storage.upload']}>
                        {isDraft && (
                            <ProductionLockWrapper>
                                <button
                                    onClick={publishDraftVersion}
                                    disabled={!isDraft}
                                    className="btn me-3 rounded-pill px-3 btn-sm btn-success shadow"
                                >
                                    Publish new version
                                </button>
                            </ProductionLockWrapper>
                        )}

                        {RenderAsComponent.Toolbar && (
                            <RenderAsComponent.Toolbar
                                path={path}
                                content={fileContent}
                                versionId={versionId}
                                onUpload={uploadNewVersion}
                            />
                        )}
                        <button
                            onClick={() => _setEditing(true)}
                            className="btn btn-sm btn-primary rounded-pill px-3 shadow ms-2"
                        >
                            <i className="fa fa-sm fa-edit me-1" /> Edit
                        </button>
                    </IAMButton>
                </div>
            )}

            {!readOnly && !editOnly && (editingWithoutModal || localContent) && (
                <>
                    <div className="mb-2 text-end">
                        {!isDraft && (
                            <ProductionLockWrapper>
                                <button
                                    onClick={pushDraftVersion}
                                    disabled={error !== false || !pushDraftVersion || !isChanged}
                                    className="btn me-4 rounded-pill px-3 btn-sm btn-primary shadow"
                                >
                                    <i className="fas fa-edit me-1 fa-sm" />
                                    Save as draft
                                </button>
                            </ProductionLockWrapper>
                        )}
                        {!localContent && (
                            <button
                                onClick={() => _setEditing(false)}
                                className="btn btn-sm btn-light rounded-pill px-3 shadow"
                            >
                                <i className="fa fa-sm fa-times me-1" /> Cancel
                            </button>
                        )}
                        {isDraft && (
                            <ProductionLockWrapper>
                                <button
                                    onClick={pushDraftVersion}
                                    disabled={error !== false || !pushDraftVersion || !isChanged}
                                    className="btn ms-2 rounded-pill px-3 btn-sm btn-primary shadow"
                                >
                                    <i className="fas fa-edit me-1 fa-sm" />
                                    Save draft
                                </button>
                            </ProductionLockWrapper>
                        )}
                        {!isDraft && (
                            <button
                                onClick={pushNewVersion}
                                disabled={error !== false || !uploadNewVersion || !isChanged}
                                className="btn ms-2 rounded-pill px-3 btn-sm btn-success shadow"
                            >
                                Save as new version
                            </button>
                        )}
                    </div>
                </>
            )}

            <RenderAsComponent
                fluid={fluid}
                initialValue={fileContent}
                value={editingWithoutModal || localContent ? editContent || fileContent : fileContent}
                onChange={onEditContentChange}
                editing={readOnly ? false : editingWithoutModal || localContent}
                onError={setError}
                error={error}
                path={path}
                fileName={fileName}
                versionId={versionId}
                gameVersion={props.gameVersion}
                environment={props.environment}
            />

            {!editingWithoutModal && (
                <Modal size="xl" header={false} show={editing} onHide={() => _setEditing(false)}>
                    <div className="modal-body">
                        <div className="mb-2 text-end">
                            {!isDraft && (
                                <ProductionLockWrapper>
                                    <button
                                        onClick={pushDraftVersion}
                                        disabled={error !== false || !pushDraftVersion || !isChanged}
                                        className="btn me-4 rounded-pill px-3 btn-sm btn-primary shadow"
                                    >
                                        <i className="fas fa-edit me-1 fa-sm" />
                                        Save as draft
                                    </button>
                                </ProductionLockWrapper>
                            )}
                            {!localContent && (
                                <button
                                    data-bs-dismiss="modal"
                                    className="btn btn-sm btn-light rounded-pill px-3 shadow-sm"
                                >
                                    <i className="fa fa-sm fa-times me-1" /> Cancel
                                </button>
                            )}
                            {isDraft && (
                                <ProductionLockWrapper>
                                    <button
                                        onClick={pushDraftVersion}
                                        disabled={error !== false || !pushDraftVersion || !isChanged}
                                        className="btn ms-2 rounded-pill px-3 btn-sm btn-primary shadow"
                                    >
                                        <i className="fas fa-edit me-1 fa-sm" />
                                        Save draft
                                    </button>
                                </ProductionLockWrapper>
                            )}
                            {!isDraft && (
                                <>
                                    <button
                                        onClick={pushNewVersion}
                                        disabled={error !== false || !uploadNewVersion || !isChanged}
                                        className="btn ms-2 rounded-pill px-3 btn-sm btn-success shadow"
                                    >
                                        Save as new version
                                    </button>
                                </>
                            )}
                        </div>
                        <hr />
                        {editing && (
                            <RenderAsComponent
                                fluid={fluid}
                                initialValue={fileContent}
                                value={editContent || fileContent}
                                onChange={onEditContentChange}
                                editing={true}
                                onError={setError}
                                error={error}
                                path={path}
                                fileName={fileName}
                                versionId={versionId}
                                gameVersion={props.gameVersion}
                                environment={props.environment}
                            />
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

import { useQuery } from '@tanstack/react-query';
import { ComponentsMap } from 'consts';
import { getConfigs } from 'services/config';
import Skeleton from 'react-loading-skeleton';
import { utils, str } from '@gpg-web/utils';
import { TooltipWrapper } from '../tooltips';
import DefaultEntityIcon from 'img/entity-default-icon-min.png';

const component = ComponentsMap['entity'];

function getValue(e) {
    if (e.target.value) {
        let num = Number(e.target.value);
        if (num < 0) num = 0;

        return num;
    }
    return undefined;
}

/**
 * value: { id, time, amount }
 */
export const EntityEditUsage = ({
    gameId,
    entity,
    value,
    onChange,
    onRemove,
    time = true,
    className,
    readOnly
}) => {
    const {
        data: entities,
        isFetching,
        error
    } = useQuery({
        queryKey: [component.id, gameId],
        queryFn: () => getConfigs(gameId, component.url),
        enabled: !!(gameId && !entity)
    });

    if (error) {
        return (
            <div className="alert alert-danger mb-0">
                <i className="fas fa-exclamation-circle fa-sm me-2" />
                {error}
            </div>
        );
    }

    if (!entity && (isFetching || !entities)) return <Skeleton height="51px" />;

    if (!entity) {
        entity = entities.find((e) => e.id === value.id) || { id: value.id, name: '', _error: true };
    }

    return (
        <div title={entity.description}>
            <div
                title={entity.id + '\n' + entity.description}
                className={utils.className(
                    'd-flex align-items-center rounded-pill p-2 border-3 border-bottom',
                    entity._error
                        ? 'bg-danger-subtle text-danger-emphasis'
                        : !entity.isConsumable
                        ? 'bg-primary-subtle text-primary-emphasis'
                        : 'bg-gray-100',
                    className
                )}
            >
                <img
                    alt=""
                    width="35"
                    height="35"
                    className="me-2 rounded-circle"
                    src={entity.icon ? utils.gameStoragePath(gameId, entity.icon) : DefaultEntityIcon}
                />
                <div className="text-nowrap me-auto">
                    <div className="d-none d-sm-block">
                        <span>{entity.id}</span>
                        {entity.type && <span className="ms-1 fw-light">| {entity.type}</span>}
                        <div className="small text-muted fw-light lh-1">{str.trim(entity.name, 40)}</div>
                    </div>
                </div>

                {entity.isConsumable && (
                    <>
                        <input
                            type="number"
                            placeholder="by amount"
                            className="form-control rounded-pill text-end mx-2 border-1 w-150px"
                            value={value.amount}
                            name="amount"
                            disabled={readOnly}
                            onChange={(e) => onChange(value.id, 'amount', getValue(e))}
                        />
                        {time && (
                            <div className="w-150px position-relative me-3">
                                <input
                                    type="number"
                                    placeholder="by time in "
                                    className="form-control rounded-pill pe-5 border-1 text-end me-1"
                                    value={value.time}
                                    name="time"
                                    disabled={readOnly}
                                    onChange={(e) => onChange(value.id, 'time', getValue(e))}
                                />
                                <span className="position-absolute text-gray-500 translate-middle top-50 start-85">
                                    sec.
                                </span>
                            </div>
                        )}
                    </>
                )}

                {!readOnly && (
                    <button
                        onClick={() => onRemove(value.id)}
                        className="btn btn-sm border-1 rounded-circle text-danger"
                    >
                        <i className="fa fa-times pe-none" />
                    </button>
                )}
            </div>
        </div>
    );
};

export const EntityUsage = ({ gameId, value, entity, className }) => {
    const {
        data: entities,
        isFetching,
        error
    } = useQuery({
        queryKey: [component.id, gameId],
        queryFn: () => getConfigs(gameId, component.url),
        enabled: !!(gameId && !entity)
    });

    if (error) {
        return (
            <div className="alert alert-danger mb-0">
                <i className="fas fa-exclamation-circle fa-sm me-2" />
                {error}
            </div>
        );
    }

    if (!entity && (isFetching || !entities)) return <Skeleton height="30px" />;

    if (!entity) {
        entity = entities.find((e) => e.id === value.id) || { id: value.id, _error: true };
    }

    return (
        <div title={entity.description}>
            <div
                className={utils.className(
                    'd-flex align-items-center rounded-pill p-1 pe-2',
                    entity._error
                        ? 'bg-danger-subtle text-danger-emphasis'
                        : !entity.isConsumable
                        ? 'bg-primary-subtle text-primary-emphasis'
                        : 'bg-gray-100',
                    className
                )}
            >
                <img
                    alt=""
                    width="20"
                    height="20"
                    className="me-2 rounded-circle"
                    src={entity.icon ? utils.gameStoragePath(gameId, entity.icon) : DefaultEntityIcon}
                />
                <div className="small">
                    <span>{entity.id}</span>
                    {entity.type && <span className="ms-1 fw-light d-none d-md-inline">| {entity.type}</span>}
                </div>
                {entity.isConsumable && (
                    <div className="ms-auto small text-primary text-nowrap">
                        {value.amount && value.time ? (
                            <>
                                {value.amount.toLocaleString()} 🕑
                                <span className="text-success-emphasis">{value.time} sec</span>
                            </>
                        ) : value.amount ? (
                            value.amount.toLocaleString()
                        ) : value.time ? (
                            <span className="text-success-emphasis">🕑{value.time} sec</span>
                        ) : (
                            <TooltipWrapper
                                icon={false}
                                content="You didn't specified any amount or time limitation for this position"
                            >
                                <span className="text-warning-emphasis">
                                    Nothing specified <i className="fas fa-exclamation-triangle ms-1 fa-xs" />
                                </span>
                            </TooltipWrapper>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export const EntityListItem = ({ gameId, entity }) => {
    return (
        <>
            <img
                alt=""
                width="20"
                height="20"
                className="me-2 rounded-circle"
                src={entity.icon ? utils.gameStoragePath(gameId, entity.icon) : DefaultEntityIcon}
            />
            {entity.id} {entity.type && <span className="fw-light">| {entity.type}</span>}
        </>
    );
};

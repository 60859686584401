import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const ErrorPage = (props) => {
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    let errorText = params.get('text') || 'Unknown error';

    return (
        <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
                <div className="card o-hidden border-0 shadow my-5 mx-2">
                    <div className="card-body p-0">
                        <div className="p-sm-5 p-3">
                            <div className="text-center">
                                <h6 className="text-danger mb-4">
                                    <i className="fa fa-exclamation-circle me-2" />
                                    Whoops, looks like something went wrong.
                                </h6>
                                <h4 className="h4 text-danger mb-5">{errorText}</h4>
                                <Link to="/">&larr; Back to Main</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
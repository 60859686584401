/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { Checkbox } from 'components';

const contentTypes = ['API', 'How to Use'];
const components = [
    'General',
    'Storage',
    'Experiment',
    'Store',
    'Translation',
    'Remote Config',
    'Player Save',
    'Scheduler',
    'TLE'
];

const FilterForm = (props) => {
    const { data, setData } = props;

    const changeType = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        data.type[name] = value;
        setData({ ...data });
    };

    const changeComponent = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        data.component[name] = value;
        setData({ ...data });
    };

    return (
        <div className="card">
            <div className="card-header">FILTER BY</div>
            <div className="card-body">
                <div className="h6 fw-bold">Choose a content type</div>
                {contentTypes.map((e) => (
                    <Checkbox key={e} value={!!data.type[e]} name={e} title={e} onChange={changeType} />
                ))}

                <div className="mt-4 h6 fw-bold">Choose a component</div>
                {components.map((e) => (
                    <Checkbox key={e} value={!!data.component[e]} name={e} title={e} onChange={changeComponent} />
                ))}
            </div>
        </div>
    );
};

export default FilterForm;

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */
import { FileCreation } from '../../storage/FileCreation';
import { ContentViewClassic } from './ContentViewClassic';
import { ContentViewTable } from './ContentViewTable';
import { ContentView } from './ContentView';
import { utils } from '@gpg-web/utils';
import Skeleton from 'react-loading-skeleton';
import { useComponent } from '../hooks';
import { StatusLabel, useStoredState, useEnvironmental } from 'components';

export const ContentSetup = (props) => {
    const { data, onChange, readOnly, id } = props;
    const [view, setView] = useStoredState('config_content_view_id', 'content');

    const {
        environments,
        gameVersions,
        selectedEnvironment,
        selectedGameVersion,
        selectedPath,
        setSelectedEnvironment,
        setSelectedGameVersion
    } = useEnvironmental();

    const component = useComponent();

    const content = data.content || {};
    content.versions = content.versions || {};

    const selectedFile = content.path;

    function handleFileChange(e) {
        content.path = e.path;
        content.versions = {};
        onChange({ ...data, content: content });
    }

    function cleanContent() {
        utils.confirm(
            'Are you sure you want to clean up the file path along with the configuration?',
            function (yes) {
                if (yes) {
                    delete content.path;
                    content.versions = {};
                    onChange({ ...data, content: content });
                }
            }
        );
    }

    const handleChangeVersion = (path, fileVersion, status) => {
        if (path) {
            content.versions[path] = content.versions[path] || {};

            if (fileVersion !== null) {
                content.versions[path].version = fileVersion;

                const oldStatus = content.versions[path].status;
                if (oldStatus !== 'disabled' && oldStatus !== 'test') {
                    utils.hintOk('Status changed to "Test" for ' + path);
                    content.versions[path].status = 'test';
                }
            }
            if (status) content.versions[path].status = status;
        }

        content.versions = { ...content.versions };

        onChange({ ...data, content: content });
    };

    const dataReady = selectedFile && environments && gameVersions;
    const isLoading = selectedFile && !dataReady;

    let fileCreationName = null;
    if (id) {
        fileCreationName = utils.unifyStr(id);
        if (fileCreationName && fileCreationName.length > 0) {
            fileCreationName = fileCreationName + component.fileExtension;
        }
    }

    return (
        <div className={utils.className('card position-static', props.className)}>
            <div className="card-header py-3 d-flex justify-content-between align-items-center">
                <h6 className="m-0 fw-bold text-primary">{props.title || 'Content'}</h6>
                {selectedFile && (
                    <div>
                        <span className="small font-monospace me-2">{selectedFile}</span>

                        {!readOnly && (
                            <button onClick={cleanContent} className="btn text-danger btn-sm border-0 py-0">
                                <i className="fas fa-times" />
                            </button>
                        )}
                    </div>
                )}
                {!selectedFile && (
                    <span className="text-muted small">
                        <i className="fas fa-exclamation-circle me-2" /> Please select file
                    </span>
                )}
            </div>

            <div className="card-body">
                {!selectedFile && (
                    <div className="mb-3">
                        {!!readOnly && (
                            <span className="text-danger">
                                <i className="fas fa-times-circle me-2" />
                                No selected file
                            </span>
                        )}

                        {!readOnly && (
                            <FileCreation
                                initialPath={'configs/v' + component.version + '/' + component.url + '/'}
                                fileName={fileCreationName}
                                mime={component.fileMime}
                                contentType={component.contentType}
                                onSuccess={handleFileChange}
                            />
                        )}
                    </div>
                )}

                {dataReady && (
                    <>
                        <div className="mb-3 d-flex border-bottom pb-2">
                            {view === 'content' && (
                                <div className="d-flex align-items-center position-relative">
                                    <select
                                        value={selectedEnvironment}
                                        className="form-select form-select-sm w-150px"
                                        onChange={(e) => setSelectedEnvironment(e.target.value)}
                                    >
                                        {environments.map((e) => (
                                            <option key={e.id} value={e.id}>
                                                {e.id}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        value={selectedGameVersion}
                                        className="form-select form-select-sm ms-3 w-150px"
                                        onChange={(e) => setSelectedGameVersion(e.target.value)}
                                    >
                                        {gameVersions.map((e) => (
                                            <option key={e.id} value={e.id}>
                                                {e.id}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="ms-3">
                                        <StatusLabel
                                            value={content.versions[selectedPath]?.status || 'draft'}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="ms-auto">
                                <button
                                    title="File content preview"
                                    onClick={(e) => setView('content')}
                                    className={
                                        'btn btn-sm mx-1 ' +
                                        (view === 'content' ? 'text-dark' : 'text-gray-500')
                                    }
                                >
                                    <i className="fas fa-folder-open" />
                                </button>
                                <button
                                    title="List content preview"
                                    onClick={(e) => setView('classic')}
                                    className={
                                        'btn btn-sm mx-1 ' +
                                        (view === 'classic' ? 'text-dark' : 'text-gray-500')
                                    }
                                >
                                    <i className="fas fa-th-list" />
                                </button>
                                <button
                                    title="Table content preview"
                                    onClick={(e) => setView('table')}
                                    className={
                                        'btn btn-sm mx-1 ' +
                                        (view === 'table' ? 'text-dark' : 'text-gray-500')
                                    }
                                >
                                    <i className="fas fa-table" />
                                </button>
                            </div>
                        </div>

                        {view === 'content' && (
                            <ContentView
                                selectedPath={selectedPath}
                                gameVersion={selectedGameVersion}
                                environment={selectedEnvironment}
                                readOnly={readOnly}
                                content={content}
                                onChange={handleChangeVersion}
                            />
                        )}

                        {view === 'table' && (
                            <ContentViewTable
                                readOnly={readOnly}
                                environments={environments}
                                gameVersions={gameVersions}
                                content={content}
                                onChange={handleChangeVersion}
                            />
                        )}

                        {view === 'classic' && (
                            <ContentViewClassic
                                readOnly={readOnly}
                                environments={environments}
                                gameVersions={gameVersions}
                                content={content}
                                onChange={handleChangeVersion}
                            />
                        )}
                    </>
                )}

                {isLoading && (
                    <>
                        <Skeleton className="my-2" width="60%" height={30} />
                        <Skeleton className="my-2" width="30%" height={60} />
                        <Skeleton className="my-2" width="100%" height={50} count={2} />
                        <Skeleton className="my-2" width="90%" height={90} />
                    </>
                )}
            </div>
        </div>
    );
};

import axios from 'axios';
import { utils } from '@gpg-web/utils';

const environmentsCache = {};

export const getEnvironments = (gameId) => {
    if (environmentsCache[gameId]) {
        return Promise.resolve(environmentsCache[gameId]);
    }
    return axios.get('/api/settings/' + gameId + '/environments').then((list) => {
        environmentsCache[gameId] = list;

        return list;
    });
};

export const addEnvironment = (gameId, data) => {
    return axios.post('/api/settings/' + gameId + '/environments/add', data).then((result) => {
        let list = environmentsCache[gameId];

        if (list) list.push(result);

        window.dispatchEvent(
            new CustomEvent('livefire_envs_update', {
                detail: { gameId: gameId }
            })
        );

        return result;
    });
};

export const removeEnvironment = (gameId, id) => {
    return axios.post('/api/settings/' + gameId + '/environments/remove', { id }).then(() => {
        let list = environmentsCache[gameId];

        if (list) utils.remove(list, (e) => e.id === id);

        window.dispatchEvent(
            new CustomEvent('livefire_envs_update', {
                detail: { gameId: gameId }
            })
        );
    });
};

const versionsCache = {};

export const getVersions = (gameId) => {
    if (versionsCache[gameId]) {
        return Promise.resolve(versionsCache[gameId]);
    }
    return axios.get('/api/settings/' + gameId + '/versions').then((list) => {
        versionsCache[gameId] = list;

        return list;
    });
};

export const addVersion = (gameId, data) => {
    return axios.post('/api/settings/' + gameId + '/versions/add', data).then((result) => {
        let list = versionsCache[gameId];

        if (list) list.unshift(result);

        window.dispatchEvent(
            new CustomEvent('livefire_versions_update', {
                detail: { gameId: gameId }
            })
        );

        return result;
    });
};

export const removeVersion = (gameId, id) => {
    return axios.post('/api/settings/' + gameId + '/versions/remove', { id }).then(() => {
        let list = versionsCache[gameId];

        if (list) utils.remove(list, (e) => e.id === id);

        window.dispatchEvent(
            new CustomEvent('livefire_versions_update', {
                detail: { gameId: gameId }
            })
        );
    });
};

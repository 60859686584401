function el(id) {
    return document.getElementById(id);
}

function readFile(file, readAs) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            resolve(reader.result);
        };
        reader.onerror = reject;

        if (readAs === 'text') {
            reader.readAsText(file);
        } else {
            reader.readAsDataURL(file);
        }
    });
}

const Loader = {
    file: async function (file, readAs) {
        if (typeof file == 'string') {
            file = el(file);
        }

        if (file instanceof HTMLElement) {
            file = file.files;
        }

        if (file instanceof FileList) {
            file = file[0];
        }

        if (!file) {
            return null;
        }

        return readFile(file, readAs);
    }
};

export default Loader;

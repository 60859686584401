import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangelogPage } from '../pages/Changelog';
import { Version } from 'consts';

const storageKey = 'checked-livefire-versions';

let checkedVersions = [];
const checkedVersionsStr = localStorage.getItem(storageKey);
if (checkedVersionsStr) checkedVersions = checkedVersionsStr.split('|');

const shouldDisplay = !checkedVersions.includes(Version);

const ChanelogModal = (props) => {
    let _modal = useRef(null);

    useEffect(() => {
        if (!shouldDisplay) return;

        const modal = new window.bootstrap.Modal(_modal.current);
        modal.show();

        return () => {
            modal.dispose();
        };
    }, []);

    const history = useNavigate();

    const applyChanges = () => {
        checkedVersions.push(Version);
        localStorage.setItem(storageKey, checkedVersions.join('|'));
    };

    const seeMore = () => {
        applyChanges();
        history('/changelog');
    };

    if (!shouldDisplay) return;

    return (
        <>
            <div ref={_modal} className="modal fade" id="changelog-modal" data-bs-backdrop="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header ps-5">
                            <span className="badge bg-success me-2">NEW</span>
                            <h4 className="modal-title">Livefire version is avaiable</h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body pb-0">
                            <ChangelogPage className="col-sm-12 col-md-11" version={Version} />
                        </div>
                        <div className="d-flex align-items-center px-4 pb-4">
                            <button
                                data-bs-dismiss="modal"
                                onClick={seeMore}
                                className="btn btn-link ms-auto"
                            >
                                See more
                            </button>
                            <button
                                className="btn btn-success ms-3"
                                data-bs-dismiss="modal"
                                onClick={applyChanges}
                            >
                                Nice !
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChanelogModal;

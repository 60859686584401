/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

export const HTMLView = (props) => {
    const { value, editing, onChange } = props;

    return (
        <div>
            {!editing && (
                <div className="card">
                    <div className="card-body">
                        <iframe
                            style={{ height: '500px' }}
                            className="w-100"
                            srcDoc={value}
                            title="HTMLPreview"
                        />
                    </div>
                </div>
            )}

            {editing && (
                <textarea
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    className="form-control"
                    rows="20"
                    placeholder="Edit content ..."
                />
            )}
        </div>
    );
};

import { StatusLabel } from 'components';
import { loadEnvironments, loadGameVersions, loadStages } from '../loaders';
import { EnvironmentView, GameVersionView, GameView, StatusView } from '../optionViews';
import { getScripts } from 'services/automation';
import { Components } from 'consts';

export const migrateAll = {
    description: (
        <>
            Migrates multiple components at once - Game Balance, TLE, TLO, Store, Translation, Popup, Daily
            Reward, Piggy Bank, Patch
        </>
    ),
    color: '#4e63df',
    icon: 'fas fa-angle-double-down',
    options: [
        {
            id: 'components',
            name: 'Components',
            value: 'all',
            type: 'select_button',
            placeholder: 'Select component',
            size: 12,
            options: [],
            isMulti: true,
            asyncLoad: async (gameId) => {
                const scripts = await getScripts(gameId);
                const script = scripts.find((_script) => _script.id === 'migrateAll');

                const scriptComponents = script ? script.components : [];

                let componentOptions = scriptComponents.reduce((prev, val) => {
                    const component = Components.find((_component) => _component.id === val);

                    prev.push({ value: val, label: component.name });

                    return prev;
                }, []);

                return componentOptions;
            }
        },
        {
            id: 'sourceGame',
            type: 'select',
            name: 'Source Stage',
            options: [],
            placeholder: 'Select stage',
            asyncLoad: loadStages,
            size: 6,
            view: GameView
        },
        {
            id: 'newOnly',
            value: false,
            type: 'checkbox',
            name: 'Migrate new components only',
            setupProps: {
                className: 'mt-4',
                tooltip: 'All existing components will be ignored'
            }
        },
        {
            id: 'liveOnly',
            value: false,
            type: 'checkbox',
            name: (
                <>
                    Migrate <StatusLabel value="live" /> components only
                </>
            ),
            setupProps: {
                className: 'mt-2'
            }
        },
        {
            id: 'environments',
            name: 'Environments',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: [],
            asyncLoad: async (gameId) => {
                const result = await loadEnvironments(gameId);
                return [{ value: 'all', label: 'All Environments' }, ...result];
            },
            view: EnvironmentView
        },
        {
            id: 'versions',
            name: 'Game Versions',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: [],
            asyncInit: async (gameId) => {
                const gameVersions = await loadGameVersions(gameId);
                return {
                    value: [gameVersions[0].value],
                    options: [{ value: 'all', label: 'All Versions' }, ...gameVersions]
                };
            },
            view: GameVersionView
        },
        {
            id: 'setStatus',
            name: 'Set Status for every added or modified components',
            value: 'test',
            type: 'select_button',
            options: [
                { value: '', label: "Don't change" },
                { value: 'live', label: 'Live' },
                { value: 'test', label: 'Test' }
            ],
            view: StatusView
        },
        {
            id: 'info1',
            type: 'info',
            text: 'Make sure, all the selected environments and game versions are exists on the source stage.'
        },
        {
            id: 'info2',
            type: 'info',
            text: 'Take into account that triggers will NOT be migrated. You should setup time, conditions, etc. by hand.'
        },
        {
            id: 'info3',
            type: 'info',
            text: 'Archived components will be ignored.'
        }
    ],
    validate: async function (values, gameId) {
        const { environments, versions } = values;
        if (!values.sourceGame) throw new Error('Source game should be specified');

        if (!environments || environments.length === 0)
            throw new Error('At least one Environment should be selected');
        if (!versions || versions.length === 0)
            throw new Error('At least one Game Version should be selected');
    },
    name: 'Migrate All'
};

import { useEffect, useMemo, useState, useContext } from 'react';
import { Spinner } from '@gpg-web/react';
import { useNavigate } from 'react-router-dom';
import { getLogs } from 'services/logs';
import { organizationContext } from 'services/organization';
import { getRoles } from 'services/iam';
import { utils } from '@gpg-web/utils';
import { useGame, ActionLabel } from 'components';
import InfiniteScroll from 'react-infinite-scroller';
import socket from 'services/socket';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { date } from '@gpg-web/utils';
import { ACTIONS } from 'consts';

const useRoles = (gameId, iams) => {
    let [roles, setRoles] = useState(null);

    useEffect(() => {
        if (!gameId) return;

        getRoles(gameId)
            .then((_roles) => {
                let roles = {};

                for (let iam of iams) {
                    roles[iam.principal] = roles[iam.principal] || [];

                    roles[iam.principal].push((_roles[iam.role] && _roles[iam.role].title) || iam.role);
                }

                for (let key in roles) {
                    roles[key] = roles[key].join(', ');
                }

                setRoles(roles);
            })
            .catch(utils.hintError);
    }, [gameId, iams]);

    return [roles];
};

export const LimitedLogs = ({ limit }) => {
    const game = useGame();

    const gameId = game.id;

    const iams = game.iam;

    const [roles] = useRoles(gameId, iams);

    let [logs, setLogs] = useState(null);

    useEffect(() => {
        setLogs(null);
        if (!gameId) return;

        const socketLogEvent = 'logs-' + gameId;

        const socketLogUpdate = (newLog) => setLogs((oldLogs) => (oldLogs ? [newLog, ...oldLogs] : oldLogs));

        getLogs(gameId, { page: 0, limit: limit })
            .then((data) => {
                setLogs(data);
                socket.on(socketLogEvent, socketLogUpdate);
            })
            .catch(utils.hintError);

        return () => {
            socket.off(socketLogEvent, socketLogUpdate);
        };
    }, [gameId, limit]);

    if (logs) {
        if (limit) {
            logs = logs.slice(0, limit);
        }
    }

    return (
        <pre className="text-monospace">
            <div
                className="d-none position-absolute"
                style={{
                    background: 'linear-gradient(180deg, #f8f9fc, #f8f9fc00)',
                    width: '100%',
                    height: '100px'
                }}
            ></div>

            {!!logs &&
                logs.map((e, index) => (
                    <LogItem
                        {...e}
                        index={index}
                        gameId={gameId}
                        role={roles ? roles[e.user] : <span className="opacity-2">Loading roles...</span>}
                        key={e.createdAt + index}
                    />
                ))}

            {!!logs && logs.length === 0 && <div className="text-muted">Log is empty</div>}

            {!logs && <Skeleton height={70} count={5} className="my-2" />}
        </pre>
    );
};

export const LogsPage = (props) => {
    const limit = props.limit || 25;

    let [logPages, setLogPages] = useState([]);

    const [search, setSearch] = useState('');

    const [hasMore, setHasMore] = useState(true);

    const [actions, setActions] = useState(ACTIONS.map((a) => false));

    const [users, setUsers] = useState([]);

    const [resetScroller, setResetScroller] = useState(0);

    const [skipLogs, setSkipLogs] = useState(0);

    const [filterChanged, setFilterChanged] = useState(false);

    const game = useGame();

    const gameId = game.id;
    const iams = game.iam;
    const [roles] = useRoles(gameId, iams);

    const searchFilter = search.length > 3 ? search : undefined;

    const usersFilter = users.length ? users : undefined;

    const actionFilter = actions.every((sel) => !sel)
        ? undefined
        : actions.map((action, i) => (action ? ACTIONS[i] : false)).filter((action) => action);

    const hasFiltersSetUp =
        searchFilter !== undefined || actionFilter !== undefined || usersFilter !== undefined;

    useEffect(() => {
        if (!gameId || hasFiltersSetUp) return;

        const socketLogEvent = 'logs-' + gameId;

        const socketLogUpdate = (newLog) => {
            setSkipLogs((p) => p + 1);

            setLogPages((oldLogPages) => {
                const newLogPages = oldLogPages.slice();

                newLogPages[0] = newLogPages[0] || [];

                newLogPages[0] = [newLog, ...newLogPages[0]];

                return newLogPages;
            });
        };

        socket.on(socketLogEvent, socketLogUpdate);

        return () => {
            socket.off(socketLogEvent, socketLogUpdate);
        };
    }, [gameId, hasFiltersSetUp]);

    const filtersChanged = useMemo(
        () =>
            utils.debounce(() => {
                setFilterChanged(false);
                setHasMore(true);
                setLogPages([]);
                setSkipLogs(0);
                setResetScroller((p) => ++p);
            }, 1000),
        []
    );

    useEffect(() => {
        if (gameId) return;

        setFilterChanged(false);
        setHasMore(true);
        setLogPages([]);
        setSkipLogs(0);
        setResetScroller((p) => ++p);
    }, [gameId]);

    console.log(gameId);

    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <h4 className="text-muted m-0">All Logs</h4>
                <label className="ms-auto me-2">Search</label>
                <input
                    type="search"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        filtersChanged();
                        setFilterChanged(true);
                    }}
                    className="form-control form-control-sm w-auto"
                />
            </div>
            <div className="mb-3">
                <LogFilters
                    selection={actions}
                    updateSelection={(d) => {
                        setActions(d);
                        filtersChanged();
                        setFilterChanged(true);
                    }}
                />
            </div>
            <LogUserFilter
                selection={users}
                updateSelection={(d) => {
                    setUsers(d);
                    filtersChanged();
                    setFilterChanged(true);
                }}
            />
            <hr />
            <pre className={'text-monospace overflow-hidden' + (filterChanged ? ' disabled' : '')}>
                <InfiniteScroll
                    key={resetScroller}
                    pageStart={-1}
                    initialLoad={!!gameId}
                    getScrollParent={() => document.getElementById('page-content')}
                    loadMore={(page) => {
                        return getLogs(gameId, {
                            page: page,
                            limit: limit,
                            search: searchFilter,
                            actions: actionFilter,
                            skip: skipLogs,
                            users: usersFilter
                        }).then((result) => {
                            setLogPages((prevLogs) => {
                                const newLogs = prevLogs.slice();
                                if (result.length) newLogs[page] = result;
                                return newLogs;
                            });

                            if (result.length < limit) setHasMore(false);
                        });
                    }}
                    hasMore={hasMore}
                    loader={
                        <div className="d-flex justify-content-center p-3" key="spinner">
                            <Spinner size="3rem" />
                        </div>
                    }
                    useWindow={false}
                >
                    {logPages.map((page, index) =>
                        page.map((e, logIndex) => (
                            <LogItem
                                {...e}
                                role={
                                    roles ? (
                                        roles[e.user]
                                    ) : (
                                        <span className="opacity-2">Loading roles...</span>
                                    )
                                }
                                gameId={gameId}
                                key={e.createdAt + index + logIndex}
                                index={index + logIndex}
                            />
                        ))
                    )}
                </InfiniteScroll>

                {!hasMore && logPages.length === 0 && <div className="text-muted">Log is empty</div>}
            </pre>
        </>
    );
};

const LogFilters = ({ selection, updateSelection }) => {
    const toogleOne = (i) => {
        const newSelection = selection.slice();

        newSelection[i] = !newSelection[i];

        updateSelection(newSelection);
    };

    return (
        <div className="d-flex flex-wrap align-items-center">
            <span className="me-2">Actions:</span>

            {ACTIONS.map((action, i) => (
                <span
                    key={action}
                    role="button"
                    onClick={() => toogleOne(i)}
                    className={selection[i] ? 'me-1' : 'me-1 opacity-3'}
                >
                    <ActionLabel value={action} />
                </span>
            ))}
        </div>
    );
};

const LogUserFilter = ({ selection, updateSelection }) => {
    const { members } = useContext(organizationContext);
    // const [members, setMembers] = useState(null);

    // useEffect(() => {
    //     getMembers().then(setMembers).catch(utils.hintError);
    // }, []);

    const isLoading = !members;

    return (
        <div className="">
            <div className="d-flex flex-wrap align-items-center">
                <span className="me-2">Users:</span>
                <div style={{ width: '350px' }}>
                    <Select
                        isLoading={isLoading}
                        options={
                            members &&
                            members
                                .filter((m) => selection.indexOf(m.email) === -1)
                                .map((m) => ({ value: m.email, label: m.name || m.email }))
                        }
                        isSearchable={true}
                        value={null}
                        styles={{
                            menuList: (baseStyles) => ({ ...baseStyles, overflowX: 'hidden' })
                        }}
                        placeholder={isLoading ? 'Loading users...' : 'Type user here...'}
                        classNamePrefix="react-select"
                        isDisabled={isLoading}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(e) => updateSelection([...selection, e.value])}
                    />
                </div>
            </div>

            <div className="pt-3 ms-5 ps-1 d-flex flex-wrap align-items-center">
                {selection.map((email, i) => (
                    <span
                        className="badge badge-lg me-2 btn btn-secondary mb-2 "
                        key={email}
                        onClick={() => {
                            selection.splice(i, 1);
                            updateSelection([...selection]);
                        }}
                    >
                        {email}
                        <i className="ms-2 fas fa-times" />
                    </span>
                ))}
            </div>
        </div>
    );
};

const LogItem = (props) => {
    let url = props.url;

    if (url) {
        url = '/game/' + props.gameId + url;
    }
    const history = useNavigate();

    let linkParams = {};

    if (url) {
        linkParams = {
            role: 'button',
            onClick: () => history(url),
            title: url
        };
    }

    const odd = props.index % 2 === 0;

    return (
        <div className={'p-2 mb-2 text-wrap' + (odd ? ' bg-gray-200' : '')} {...linkParams}>
            <div className="small text-muted mb-1 fw-bold">
                {props.user} | {props.role || 'Service or Deleted IAM'}{' '}
                {props.o && <span className="badge badge-lg bg-secondary">{props.o.m}</span>}
            </div>
            <div className="mb-1">
                <ActionLabel value={props.action} />
                {props.tags &&
                    props.tags.map((tag, i) => (
                        <span key={tag + i} className="badge badge-lg bg-secondary ms-1">
                            {tag}
                        </span>
                    ))}
                {' ' + props.msg}
            </div>
            <div className="text-muted mt-1">{date(props.createdAt, 'local')}</div>
        </div>
    );
};

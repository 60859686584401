import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { organizationContext } from 'services/organization';
import { date } from '@gpg-web/utils';
import { Spinner } from '@gpg-web/react';

const NotificationTypes = {
    danger: {
        component: <i className="fas fa-info-circle fa-lg text-danger" />
    },
    error: {
        component: <i className="fas fa-exclamation-circle fa-lg text-danger" />,
        text: 'danger'
    },
    success: {
        component: <i className="fas fa-check-circle fa-lg text-success" />
    },
    info: {
        component: <i className="fas fa-info-circle fa-lg text-primary" />
    },
    warning: {
        component: <i className="fas fa-exclamation-triangle text-warning" />
    },
    loading: {
        component: <Spinner />
    }
};

const NotificationAlertItem = (props) => {
    const history = useNavigate();
    const { games, gameGroups } = useContext(organizationContext);

    const data = props.data;

    const icon = NotificationTypes[data.type] || NotificationTypes.info;

    let foundGame = null;
    let foundGameName = '';
    if (games && gameGroups) {
        foundGame = games.find((e) => e.id === data.game);
        if (foundGame) {
            foundGameName =
                (gameGroups.find((e) => e.id === foundGame.group)?.name || 'Unknown') +
                ' - ' +
                foundGame.name;
        }
    }

    const read = () => {
        if (data.path && (data.path.startsWith('http://') || data.path.startsWith('https://'))) {
            window.open(data.path, '_blank');
            return;
        }

        if (foundGame) {
            if (data.path && foundGame) history('/game/' + foundGame.id + data.path);
            else history('/game/' + foundGame.id + '/logs');
        }
    };

    return (
        <div onClick={read} data-bs-dismiss={props.modal ? "modal" : undefined} role="button" className={props.modal ? "d-flex mb-4 border-bottom pb-2" : "dropdown-item d-flex"}>
            <div className="me-3 mt-1">{icon.component}</div>
            <div className="text-break w-100">
                <div className="d-flex justify-content-between">
                    <span className="fw-bold">{data.title}</span>
                    <span className="small text-gray-500">{date(data.createdAt, 'ago')}</span>
                </div>
                {foundGameName && <div className="small text-muted lh-1 mb-2">{foundGameName}</div>}
                <span className={icon.text && 'text-' + icon.text}>{data.text}</span>
            </div>
        </div>
    );
};

export { NotificationAlertItem };

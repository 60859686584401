/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect, useMemo } from 'react';
import { getStorageAsset, removeFile, removeFolder } from 'services/storage';
import { FileIcon, IAMButtonSmall, makeSelectColumn } from 'components';
import Skeleton from 'react-loading-skeleton';
import { utils, date } from '@gpg-web/utils';
import { Table } from '@gpg-web/react';
import { useRemoveComponent } from '../components/RemoveComponent';
import { getFileUsage, getFolderUsage } from 'services/usage';
import { useQuery } from '@tanstack/react-query';

const columns = [
    {
        Header: '#',
        accessor: 'index',
        cellClass: 'text-nowrap'
        // size: "50"
    },
    {
        Header: 'Name',
        accessor: 'displayName',
        cellClass: 'text-nowrap',
        size: 370,
        sortType: (rowA, rowB) => {
            return rowA.original.name > rowB.original.name ? 1 : -1;
        }
    },
    {
        Header: 'Size',
        accessor: 'displaySize',
        sortType: (rowA, rowB) => {
            return rowA.original.size > rowB.original.size ? 1 : -1;
        }
    },
    {
        Header: 'Version',
        accessor: 'versionId'
    },
    {
        Header: 'Created',
        accessor: 'displayCreatedAt',
        sortType: (rowA, rowB) => {
            return rowA.original.createdAt > rowB.original.createdAt ? 1 : -1;
        }
    },
    {
        Header: 'Last modified',
        accessor: 'displayUpdatedAt',
        sortType: (rowA, rowB) => {
            return rowA.original.updatedAt > rowB.original.updatedAt ? 1 : -1;
        }
    },
    {
        accessor: 'actions',
        disableSortBy: true
    }
    // {
    //     Header: 'Last modified',
    //     accessor: 'updated_at',
    // }
];

// let queueId = 0;
const simplifiedColumns = [
    {
        Header: 'Name',
        accessor: 'displayName',
        cellClass: 'text-nowrap',
        size: 370,
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.name > rowB.original.name ? 1 : -1;
        }
    },
    {
        Header: 'Size',
        accessor: 'displaySize',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.size > rowB.original.size ? 1 : -1;
        }
    },
    {
        Header: 'Version',
        accessor: 'versionId'
    }
];

export const CollectionModel = (props) => {
    const {
        gameId,
        storage,
        setStorage,
        selected,
        deselectAll,
        allSelected,
        selectAll,
        onSelect,
        isSelected,
        onFilterChange
    } = props;

    const [RemoveFolderModal, showRemoveFolderModal] = useRemoveComponent('Folder');
    const [RemoveFileModal, showRemoveFileModal] = useRemoveComponent('File');

    const path = props.path || '';
    const {
        isFetching,
        error,
        data: fetchedAsset
    } = useQuery({
        queryKey: ['storage', gameId, ...path.split('/').slice(0, -1)],
        queryFn: () => getStorageAsset(gameId, path),
        enabled: !!gameId
    });

    useEffect(() => {
        if (fetchedAsset && fetchedAsset.directory) setStorage(fetchedAsset.assets);
        else setStorage(null);

        if (deselectAll) deselectAll();
    }, [fetchedAsset, setStorage, deselectAll]);

    const data = useMemo(() => {
        if (!storage) return [];

        const deleteObject = (e) => {
            e.stopPropagation();

            let isFolder = e.target.getAttribute('data-mime') === 'Folder';
            let objectId = e.target.getAttribute('data-id');

            const object = storage.find((obj) => obj.id === objectId);

            const showModal = isFolder ? showRemoveFolderModal : showRemoveFileModal;

            const getUsage = isFolder ? getFolderUsage : getFileUsage;

            const objectFullPath = object.path + object.name;

            showModal(getUsage(gameId, objectFullPath), object, () => {
                utils.popup('removing');
                const fn = isFolder ? removeFolder : removeFile;

                fn(gameId, objectId, object.path + object.name)
                    .then(() => {
                        utils.remove(storage, (e) => e.id === objectId);

                        setStorage(storage.slice());

                        utils.popup('hide');
                    })
                    .catch(utils.hintError);
            });
        };

        const copyLinkToClipboard = (e) => {
            e.stopPropagation();
            let path = e.target.getAttribute('data-path');

            utils.copyTextToClipboard(path).then(() => {
                utils.hintOk('Link copied to clipboard');
            });
        };

        // const selectObject = (e) => {
        //     e.stopPropagation();

        //     let isFolder = e.target.getAttribute('data-mime') === 'Folder';
        //     let objectId = e.target.getAttribute('data-id');

        //     if (e.target.checked) {
        //         selected.push({ id: objectId, isFolder: isFolder });
        //     } else {
        //         utils.remove(selected, (e) => e.id === objectId);
        //     }

        //     setSelected(selected.slice());
        // };

        let _storage = storage;

        if (props.foldersOnly) {
            _storage = _storage.filter((e) => e.mime === 'Folder');
        }

        if (props.filter) {
            if (Array.isArray(props.filter)) {
                _storage = _storage.filter((e) => {
                    if (e.mime === 'Folder') return true;

                    for (let filter of props.filter) {
                        if (e.contentType === filter || e.mime === filter) return true;
                    }

                    return false;
                });
            } else {
                _storage = _storage.filter(
                    (e) => e.contentType === props.filter || e.mime === props.filter || e.mime === 'Folder'
                );
            }
        }

        return _storage.map((file, i) => ({
            id: file.id,
            onSelect: onSelect,
            selected: isSelected && isSelected(file.id),
            index: i + 1,
            globalFilter: file.name + file.mime + file.lastVersionId,
            name: file.name,
            displayName: (
                <>
                    <FileIcon className="me-2" mime={file.mime} contentType={file.contentType} />
                    <span title={file.contentType} className={'text-break text-wrap' + (file.virtual ? ' opacity-4' : '')}>
                        {file.name || file.displayName}
                    </span>
                </>
            ),
            versionId: file.lastVersionId,
            size: file.size,
            mime: file.mime,
            path: file.path,
            displaySize: utils.bytesToStr(file.size),
            createdAt: new Date(file.createdAt),
            displayCreatedAt: date(file.createdAt, 'ago-1'),
            updatedAt: new Date(file.updatedAt),
            displayUpdatedAt: date(file.updatedAt, 'ago-1'),
            actions: !file.virtual && (
                <>
                    <button
                        onClick={copyLinkToClipboard}
                        data-path={file.path + (file.mime === 'Folder' ? file.name + '/' : file.name)}
                        title="Copy link to clipboard"
                        className="btn btn-sm"
                    >
                        <i className="fas fa-link pe-none" />
                    </button>
                    <IAMButtonSmall permissions={['component.storage.delete']}>
                        <button
                            onClick={deleteObject}
                            data-mime={file.mime}
                            data-id={file.id}
                            title="Delete object"
                            className="btn btn-sm"
                        >
                            <i className="fa fa-trash pe-none" />
                        </button>
                    </IAMButtonSmall>
                </>
            )
        }));
    }, [
        storage,
        gameId,
        setStorage,
        props.foldersOnly,
        props.filter,
        isSelected,
        onSelect,
        showRemoveFolderModal,
        showRemoveFileModal
    ]);

    return (
        <>
            {!isFetching && !!storage && (
                <>
                    <Table
                        onFilterChange={onFilterChange}
                        saveStateId="storage-collection-list"
                        onClick={props.onClick}
                        onDoubleClick={props.onDoubleClick}
                        columns={
                            selected
                                ? [
                                      makeSelectColumn(
                                          onSelect,
                                          selectAll,
                                          deselectAll,
                                          allSelected,
                                          selected.length !== 0
                                      ),
                                      ...(props.simplified ? simplifiedColumns : columns)
                                  ]
                                : props.simplified
                                ? simplifiedColumns
                                : columns
                        }
                        data={data}
                        className={'table-hover table-striped' + (props.smallTable ? ' table-sm' : '')}
                    />
                    {RemoveFileModal}
                    {RemoveFolderModal}
                </>
            )}

            {isFetching && (
                <div>
                    <Skeleton className="m-1 mb-2 mx-sm-2" height={50} />
                    <Skeleton className="m-1 mb-0 mx-sm-2" height={30} />
                    <Skeleton className="m-1 mx-sm-2" count={7} height={40} />
                </div>
            )}

            {error && <div className="alert alert-danger">{error}</div>}
        </>
    );
};

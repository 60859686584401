const { useState, useCallback } = require('react');

export const useValidation = (validations) => {
    const [errors, setErrors] = useState({});
    const validate = useCallback(
        (data) => {
            if (validations) {
                let valid = true;
                const newErrors = {};
                for (const key in validations) {
                    if (typeof data[key] === 'string') {
                        data[key] = data[key].trim();
                        if (data[key] === '') data[key] = null;
                    }

                    const value = data[key];
                    const validation = validations[key];
                    if (validation?.required?.value) {
                        if (value === undefined || value === null) {
                            valid = false;
                            newErrors[key] = validation?.required?.message;
                        }
                    }

                    const pattern = validation?.pattern;
                    if (pattern?.value && !RegExp(pattern.value).test(value)) {
                        valid = false;
                        newErrors[key] = pattern.message;
                    }

                    const custom = validation?.custom;
                    if (custom?.isValid && !custom.isValid(value, data)) {
                        valid = false;
                        newErrors[key] = custom.message;
                    }
                }

                if (!valid) {
                    setErrors(newErrors);
                    return false;
                }
            }

            setErrors({});
            return true;
        },
        [validations]
    );

    return {
        errors,
        validate
    };
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { Alert } from 'components';
import { utils, ms } from '@gpg-web/utils';
import { TimeExamples } from './TimeExamples';
import className from 'classnames';
// import Select from 'react-select'

export function formatDateToIntput(date) {
    if (date) {
        date = new Date(date);

        const isoString = date.toISOString();

        return isoString.substring(0, ((isoString.indexOf('T') | 0) + 6) | 0);
    }

    return null;
}

const weekDays = [
    { value: 0, label: 'S' },
    { value: 1, label: 'M' },
    { value: 2, label: 'T' },
    { value: 3, label: 'W' },
    { value: 4, label: 'T' },
    { value: 5, label: 'F' },
    { value: 6, label: 'S' }
];

export const TimeTrigger = (props) => {
    const { data, setData, readOnly, onError } = props;
    const [error, setError] = useState(null);

    const time = data.time || {};

    time.mode = time.mode || props.mode || 'default';
    time.global = time.global || false;
    time.data = time.data || {};

    if (time.mode === 'default') {
        // time.data.preEvent = time.data.preEvent || 0;
        // time.data.postEvent = time.data.postEvent || 0;
    } else {
        time.data.frequency = time.data.frequency || 'day';
        time.data.startTime = time.data.startTime || '00:00';
        time.data.weekDays = time.data.weekDays || {};
        time.data.duration = time.data.duration || 24;
        time.data.repeatEvery = time.data.repeatEvery || 1;
        time.data.endType = time.data.endType || 'never';
    }

    time.data.preEvent = time.data.preEvent || 0;
    time.data.postEvent = time.data.postEvent || 0;

    useEffect(() => {
        onError && onError(error);
    }, [error]);

    function validate() {
        let from = time.data['start'];
        let to = time.data['end'];

        if (!from) return setError('Please specify event "Start on" date');

        if (!to && (time.mode === 'default' || (time.mode === 'repeat' && time.data.endType === 'date'))) {
            return setError('Please specify event "Ends on" date');
        }

        if (to) {
            if (time.mode === 'default' || (time.mode === 'repeat' && time.data.endType === 'date')) {
                from = new Date(from);
                to = new Date(to);
                if (from > to) {
                    return setError('Event "Start on" date should be less than "Ends on" date');
                }

                if (time.mode === 'repeat' && time.data.endType === 'date') {
                    if (to.getTime() - from.getTime() < ms('24h')) {
                        return setError(
                            'Please set at least 24h between "Starts on" and "Ends on" for repeat event'
                        );
                    }
                }
            }
        }

        // if (time.mode === 'repeat' && !time.data.startTime) {
        //     return setError('Please specify repeat event "Start at" time');
        // }
    }

    const handleChange = (e) => {
        setError(null);
        const target = e.target;
        const name = target.name;

        let value;

        if (target.type === 'checkbox') value = target.checked;
        else if (target.type === 'number') value = Number(target.value);
        else if (target.type === 'datetime-local' || target.type === 'datetime') {
            if (target.value) {
                let date = new Date(target.value);
                if (!isNaN(date) && date > 0) {
                    date.setTime(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
                    value = date.toISOString();
                }
            }
        } else value = target.value || undefined;

        time.data[name] = value;

        setData({ ...data });

        validate();
    };

    const setValue = (name, value) => {
        setError(null);

        time[name] = value;
        if (name === 'mode') {
            time.data = {};
        }

        setData({ ...data });

        validate();

        // let from = time.data['start'];
        // let to = time.data['end'];
        // if (from && to) {
        //     if (time.mode === 'default' || (time.mode === 'repeat' && time.data.endType === 'end')) {
        //         if (new Date(from) > new Date(to))
        //             return setError('Event "Start on" date should be less than "Ends on" date');
        //     }
        // }
    };

    let warning;

    if (
        time.mode === 'default' &&
        time.data['start'] &&
        new Date(time.data['start']) < new Date() &&
        time.global
    ) {
        warning = 'Start date set earlier than now, are you sure you want to continue?';
    }

    return (
        <div className={className('card', props.className)}>
            <div className="card-header py-3 d-flex align-items-center">
                <h6 className="m-0 fw-bold text-primary">Time trigger</h6>
                <div className="form-check form-switch ms-auto form-check-reverse">
                    <input
                        className="form-check-input"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                utils.confirm(
                                    'Are you sure you want to disable time trigger? The current setup will be reset.',
                                    (yes) => {
                                        if (yes) {
                                            setData({ ...data, time: null });
                                            setError(null);
                                        }
                                    }
                                );
                            } else {
                                setData({ ...data, time: {} });
                                validate();
                            }
                        }}
                        disabled={readOnly}
                        checked={!!data.time}
                        type="checkbox"
                        id="time-trigger-enabled"
                    />
                    <label className="form-check-label me-2" htmlFor="time-trigger-enabled">
                        Enable
                    </label>
                </div>
            </div>

            {!!data.time && (
                <div className="card-body">
                    <div className="mb-4 pb-3 border-bottom d-flex">
                        {!props.mode && (
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    onChange={(e) => {
                                        setValue('mode', e.target.checked ? 'repeat' : 'default');
                                    }}
                                    disabled={readOnly}
                                    checked={time.mode === 'repeat'}
                                    type="checkbox"
                                    id="scheduler-repeating-mode"
                                />
                                <label className="form-check-label" htmlFor="scheduler-repeating-mode">
                                    Repeating
                                </label>
                            </div>
                        )}
                        <div className="d-flex align-items-center ms-auto">
                            <label htmlFor="time-local-global-mode">Trigger by</label>
                            <select
                                id="time-local-global-mode"
                                onChange={(e) => setValue('global', e.target.value === 'global')}
                                value={time.global ? 'global' : 'local'}
                                name="triggerByMode"
                                className="form-select form-select-sm w-150px mx-2"
                                disabled={readOnly}
                            >
                                <option value="local">Player local</option>
                                <option value="global">GMT</option>
                            </select>
                            <label htmlFor="time-local-global-mode">time zone</label>
                        </div>
                    </div>
                    <div className="row">
                        {time.mode === 'repeat' && (
                            <>
                                <div className="col-xl-6 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <span className="me-2 small">Repeat every </span>
                                        <input
                                            type="number"
                                            min="1"
                                            name="repeatEvery"
                                            onChange={handleChange}
                                            disabled={readOnly}
                                            value={time.data.repeatEvery}
                                            className="form-control form-control-sm me-2 w-100px"
                                        />
                                        <select
                                            onChange={handleChange}
                                            value={time.data.frequency}
                                            name="frequency"
                                            className="form-select form-select-sm w-150px"
                                            disabled={readOnly}
                                        >
                                            <option value="day">
                                                Day{time.data.repeatEvery > 1 ? 's' : ''}
                                            </option>
                                            <option value="week">
                                                Week{time.data.repeatEvery > 1 ? 's' : ''}
                                            </option>
                                            <option value="month">
                                                Month{time.data.repeatEvery > 1 ? 's' : ''}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mt-3 d-flex align-items-center">
                                        {time.data.frequency === 'week' && (
                                            <>
                                                <span className="me-2 small">Repeat on </span>
                                                {weekDays.map((e) => {
                                                    return (
                                                        <span className="small" key={e.value}>
                                                            <input
                                                                onChange={(el) => {
                                                                    time.data.weekDays[e.value] =
                                                                        !!el.target.checked;
                                                                    setData({ ...data });
                                                                }}
                                                                type="checkbox"
                                                                checked={!!time.data.weekDays[e.value]}
                                                                className="btn-check"
                                                                id={'scheduler_week_repeat_days_' + e.value}
                                                                disabled={readOnly}
                                                                autoComplete="off"
                                                            />
                                                            <label
                                                                className="btn btn-outline-primary border-2 rounded-circle m-1 fw-bold"
                                                                style={{
                                                                    width: '27px',
                                                                    height: '27px',
                                                                    fontSize: '12px',
                                                                    padding: '3px'
                                                                }}
                                                                htmlFor={
                                                                    'scheduler_week_repeat_days_' + e.value
                                                                }
                                                            >
                                                                {e.label}
                                                            </label>
                                                        </span>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </div>
                                    <div className="mt-3 d-flex align-items-center">
                                        <span className="me-1 small">Start at</span>
                                        <input
                                            disabled={readOnly}
                                            onChange={handleChange}
                                            name="startTime"
                                            value={time.data.startTime}
                                            type="time"
                                            className="form-control form-control-sm ms-3 me-2 w-150px"
                                        />
                                        <span className="small">(GMT)</span>
                                    </div>
                                    <div className="d-flex align-items-center mt-3">
                                        <span className="me-2 small">Duration</span>
                                        <div className="input-group p-0 input-group-sm w-150px">
                                            <input
                                                onChange={handleChange}
                                                name="duration"
                                                value={time.data.duration}
                                                disabled={readOnly}
                                                min={1}
                                                type="number"
                                                className="form-control"
                                            />
                                            <span className="input-group-text">hrs.</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mt-3 mb-4">
                                        <span className="me-2 small">Pre-event</span>
                                        <div className="input-group p-0 input-group-sm w-150px">
                                            <input
                                                onChange={handleChange}
                                                name="preEvent"
                                                value={time.data.preEvent}
                                                disabled={readOnly}
                                                min={0}
                                                type="number"
                                                className="form-control"
                                            />
                                            <span className="input-group-text">hrs.</span>
                                        </div>
                                        <span className="me-2 small ms-3">Post-event</span>
                                        <div className="input-group p-0 input-group-sm w-150px">
                                            <input
                                                onChange={handleChange}
                                                name="postEvent"
                                                value={time.data.postEvent}
                                                disabled={readOnly}
                                                min={0}
                                                type="number"
                                                className="form-control"
                                            />
                                            <span className="input-group-text">hrs.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12">
                                    <div>
                                        <div className="fw-bold small">Starts</div>
                                        <div className="row align-items-center mt-2">
                                            <div className="col-3">
                                                <div className="form-check me-4">
                                                    <input
                                                        checked={true}
                                                        readOnly
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="scheduler-starts-on"
                                                        disabled={readOnly}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="scheduler-starts-on"
                                                    >
                                                        After
                                                    </label>
                                                </div>
                                            </div>

                                            <input
                                                name="start"
                                                type="date"
                                                value={time.data['start']}
                                                onChange={handleChange}
                                                disabled={readOnly}
                                                className="form-control form-control-sm w-200px"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-4">
                                        <div className="fw-bold small">Ends</div>
                                        <div className="row align-items-center mt-2">
                                            <div className="col-3">
                                                <div className="form-check me-4">
                                                    <input
                                                        onChange={(e) =>
                                                            e.target.checked &&
                                                            handleChange({
                                                                target: { name: 'endType', value: 'never' }
                                                            })
                                                        }
                                                        checked={time.data.endType === 'never'}
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="scheduler-ends-never"
                                                        disabled={readOnly}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="scheduler-ends-never"
                                                    >
                                                        Never
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-2">
                                            <div className="col-3">
                                                <div className="form-check me-4">
                                                    <input
                                                        onChange={(e) =>
                                                            e.target.checked &&
                                                            handleChange({
                                                                target: { name: 'endType', value: 'date' }
                                                            })
                                                        }
                                                        checked={time.data.endType === 'date'}
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="scheduler-ends-on"
                                                        disabled={readOnly}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="scheduler-ends-on"
                                                    >
                                                        On
                                                    </label>
                                                </div>
                                            </div>
                                            <input
                                                name="end"
                                                type="datetime-local"
                                                disabled={readOnly || time.data.endType !== 'date'}
                                                value={formatDateToIntput(time.data['end'])}
                                                onChange={handleChange}
                                                className="form-control form-control-sm w-200px me-2"
                                            />
                                            <span className="small col-2">(GMT)</span>
                                        </div>
                                        <div className="row align-items-center mt-2 d-none">
                                            <div className="col-3">
                                                <div className="form-check me-4">
                                                    <input
                                                        onChange={(e) =>
                                                            e.target.checked &&
                                                            handleChange({
                                                                target: {
                                                                    name: 'endType',
                                                                    value: 'occurrences'
                                                                }
                                                            })
                                                        }
                                                        checked={time.data.endType === 'occurrences'}
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="scheduler-ends-after"
                                                        disabled={readOnly}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="scheduler-ends-after"
                                                    >
                                                        After
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-group p-0 input-group-sm w-200px">
                                                <input
                                                    min="1"
                                                    disabled={readOnly || time.data.endType !== 'occurrences'}
                                                    name="occurrences"
                                                    value={time.data.occurrences}
                                                    onChange={handleChange}
                                                    type="number"
                                                    className="form-control"
                                                />
                                                <span className="input-group-text">occurrences</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {time.mode === 'default' && (
                            <>
                                <div className="col-lg-6 col-md-12">
                                    <div className="d-flex align-items-center">
                                        <span className="me-1 small fw-bold">Starts on</span>
                                        <input
                                            disabled={readOnly}
                                            onChange={handleChange}
                                            name="start"
                                            value={formatDateToIntput(time.data['start'])}
                                            type="datetime-local"
                                            className="form-control form-control-sm ms-3 me-2 w-300px"
                                        />
                                        <span className="small">(GMT)</span>
                                    </div>

                                    <div className="d-flex align-items-center mt-3 mb-4">
                                        <span className="me-2 small">Pre-event</span>
                                        <div className="input-group p-0 input-group-sm w-150px">
                                            <input
                                                onChange={handleChange}
                                                name="preEvent"
                                                value={time.data.preEvent}
                                                disabled={readOnly}
                                                min={0}
                                                type="number"
                                                className="form-control"
                                            />
                                            <span className="input-group-text">hrs.</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="d-flex align-items-center">
                                        <span className="me-1 small fw-bold">Ends on</span>
                                        <input
                                            disabled={readOnly}
                                            onChange={handleChange}
                                            name="end"
                                            value={formatDateToIntput(time.data['end'])}
                                            type="datetime-local"
                                            className="form-control form-control-sm ms-3 me-2 w-300px"
                                        />
                                        <span className="small">(GMT)</span>
                                    </div>

                                    <div className="d-flex align-items-center mt-3 mb-4">
                                        <span className="me-2 small">Post-event</span>
                                        <div className="input-group p-0 input-group-sm w-150px">
                                            <input
                                                onChange={handleChange}
                                                name="postEvent"
                                                value={time.data.postEvent}
                                                disabled={readOnly}
                                                min={0}
                                                type="number"
                                                className="form-control"
                                            />
                                            <span className="input-group-text">hrs.</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="mx-2">
                        {!!error && <Alert text={error} />}

                        {!error && !!warning && (
                            <div className="alert alert-warning">
                                <i className="fas fa-exclamation-triangle me-2" />
                                {warning}
                            </div>
                        )}

                        {!error && <TimeExamples time={time} />}
                    </div>
                </div>
            )}
        </div>
    );
};

const EnvBadge = ({ children }) => <span className="bg-gray-200 rounded-2 fw-bold px-2">{children}</span>;

export const EnvironmentView = ({ value }) => {
    if (!value) {
        return <span className="fw-bold">Not selected</span>;
    }
    if (value === 'all') {
        return <span className="fw-bold">All Environments</span>;
    }

    if (Array.isArray(value)) {
        return value.map((val) => (
            <span className="text-nowrap d-inline-block mb-1 me-2" key={val}>
                <EnvBadge>{val}</EnvBadge>
            </span>
        ));
    }

    return <EnvBadge>{value}</EnvBadge>;
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { Modal } from '@gpg-web/react';
import { useModalBlocker } from 'components';
import { FileView } from './FileView';

export const StorageFileModal = (props) => {
    const [canClose, setCanClose] = useState(true);

    const [, onBeforeClose] = useModalBlocker(canClose);

    return (
        <Modal
            size="xl"
            title={
                <>
                    Storage File <span className="ms-3 text-muted">{props.path}</span>
                </>
            }
            show={props.show}
            onHide={(e) => {
                setCanClose(true);
                props.onHide(e);
            }}
            onBeforeClose={onBeforeClose}
        >
            {props.show && (
                <div className="modal-body">
                    <FileView {...props} hasChanges={(val) => setCanClose(!val)} />
                </div>
            )}
        </Modal>
    );
};

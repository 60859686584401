import React from 'react';
import { utils } from '@gpg-web/utils';
import { TooltipWrapper } from './tooltips';

const Input = (props) => (
    <div className="mb-3">
        {props.title !== false && (
            <TooltipWrapper layout="inline-start" content={props.tooltip}>
                <label className="mb-1" htmlFor={props.id}>
                    {props.title || props.name} {props.required && <span className="text-danger">*</span>}
                </label>
            </TooltipWrapper>
        )}

        <input
            accept={props.accept}
            name={props.name}
            disabled={props.disabled}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            type={props.type || 'text'}
            value={props.value !== undefined ? props.value : ''}
            className="form-control"
            placeholder={props.placeholder}
            id={props.id}
        />
    </div>
);

const Checkbox = (props) => {
    return (
        <div className={'form-check ' + (props.className || 'mb-2')}>
            <input
                name={props.name}
                type="checkbox"
                className="form-check-input"
                onChange={props.onChange}
                disabled={props.disabled}
                id={utils.generateId(props.title + props.name + props.id)}
                defaultChecked={props.defaultValue}
                checked={props.value || false}
            />

            {props.title !== false && (
                <TooltipWrapper layout="inline-end" size="xs" content={props.tooltip}>
                    <label
                        className="form-check-label"
                        disabled={props.disabled}
                        htmlFor={utils.generateId(props.title + props.name + props.id)}
                    >
                        {props.title || props.name}
                    </label>
                </TooltipWrapper>
            )}
        </div>
    );
};

const TextArea = (props) => (
    <div className="mb-3">
        <label className="mb-1" htmlFor={props.id}>
            {props.title || props.name}
        </label>
        <textarea
            name={props.name}
            rows={props.rows}
            disabled={props.disabled}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            value={props.value !== undefined ? props.value : ''}
            className="form-control"
            id={props.id}
            placeholder={props.placeholder}
        />
    </div>
);

const BaseTextArea = (props) => (
    <div className="mb-2">
        <label className="mb-1" htmlFor={props.id}>
            {props.title || props.name}
        </label>
        <textarea
            name={props.name}
            disabled={props.disabled}
            defaultValue={props.defaultValue}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value !== undefined ? props.value : ''}
            className="form-control"
            id={props.id}
        />
    </div>
);

export { Input, TextArea, BaseTextArea, Checkbox };

import { Components } from 'consts';
import { getScripts } from 'services/automation';
import { loadEnvironments, loadGameVersions } from '../loaders';
import { EnvironmentView, GameVersionView, StatusView } from '../optionViews';

export const configPromoteVersion = {
    description: (
        <>
            For every config - promote Game Version from X to Y.
            <br />
            Optionally can change status of every changed config.
        </>
    ),
    color: '#6173dd',
    icon: 'fas fa-copy',
    options: [
        {
            id: 'components',
            name: 'Component',
            value: 'all',
            type: 'select_button',
            placeholder: 'Select component',
            size: 12,
            options: [],
            isMulti: true,
            asyncLoad: async (gameId) => {
                const scripts = await getScripts(gameId);
                const script = scripts.find((_script) => _script.id === 'configPromoteVersion');

                const scriptComponents = script ? script.components : [];

                let componentOptions = scriptComponents.reduce((prev, val) => {
                    const component = Components.find((_component) => _component.id === val);

                    prev.push({ value: val, label: component.name });

                    return prev;
                }, []);

                return componentOptions;
            }
        },
        {
            id: 'src',
            name: 'From Game Version',
            type: 'select',
            options: [],
            placeholder: 'Select version',
            asyncLoad: loadGameVersions,
            view: GameVersionView
        },
        {
            id: 'dst',
            name: 'To Game Version',
            type: 'select',
            options: [],
            placeholder: 'Select version',
            asyncLoad: loadGameVersions,
            view: GameVersionView
        },
        {
            id: 'env',
            name: 'Promote Specific Environment',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: [],
            asyncLoad: async (gameId) => {
                const result = await loadEnvironments(gameId);
                return [{ value: 'all', label: 'All Environments' }, ...result];
            },
            view: EnvironmentView
        },
        {
            id: 'setStatus',
            name: 'Set Status for every changed config',
            value: 'test',
            type: 'select_button',
            options: [
                { value: 'draft', label: 'Draft' },
                { value: 'test', label: 'Test' },
                { value: 'live', label: 'Live' }
            ],
            view: StatusView
        }
    ],
    validate: function (values) {
        if (!values.components || values.components.length === 0)
            throw new Error('Component should be specified');
        if (!values.src) throw new Error('From Game Version should be specified');
        if (!values.dst) throw new Error('To Game Version should be specified');

        return true;
    },
    name: 'Promote Game Version'
};

import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { ErrorPage } from './pages/Error';
import { GamesPage } from './pages/GamesList';
import { NotFoundPage } from '@gpg-web/react';
import { LogsPage } from './pages/game/Logs';
import { ChangelogPage } from './pages/Changelog';
import { ProfilePage } from './pages/Profile';

import { GamePage } from './pages/game';
import { ExperimentsList } from './pages/game/config/experiment/List';
import { ExperimentCreate } from './pages/game/config/experiment/Create';
import { ExperimentEdit } from './pages/game/config/experiment/Edit';
import { ConfigsList } from './pages/game/config/List';
import { ConfigCreate } from './pages/game/config/Create';
import { ConfigEdit } from './pages/game/config/Edit';
import { ConfigBuiltInForcedUpdate, ConfigBuiltInUnityAddressables } from './pages/game/config/builtIn';
import { EntitiesList } from './pages/game/config/entity/List';
import { ProductsList } from './pages/game/config/product/List';
import { StoragePage } from './pages/game/storage';
import PlayersList from './pages/game/players/List';
import PlayerPage from './pages/game/players/Profile';
import PlayerSavesList from './pages/game/playerSave/List';
import PlayerSaveEdit from './pages/game/playerSave/Edit';
import { IAMSettings } from './pages/game/settings/iam';
import { RolesSettings } from './pages/game/settings/iam/Roles';
import { GameSettings } from './pages/game/settings/Game';
import { StageSettings } from './pages/game/settings/Stage';
import { EnvironmentsSettings } from './pages/game/settings/Environments';
import { OpenAPISettings } from './pages/game/settings/OpenAPI';
import { VersionsSettings } from './pages/game/settings/Versions';
import { ProcessesList } from './pages/game/automation/Processes';
import { ProcessDetails } from './pages/game/automation/ProcessDetails';
import { ScriptsLibrary } from './pages/game/automation/scripts/Library';
import { MissingPermissionsPage } from './pages/MissingPermissions';
import { SearchPage } from './pages/Search';
import { DocsPage } from './pages/Docs';
import { TutorialsPage } from './pages/Tutorials';
import { Overview } from './pages/game/overview/Overview';

import App from './App';

export const AppRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route exact path="/" element={<GamesPage />} />

            <Route exact path="/error" element={<ErrorPage />} />

            <Route exact path="/changelog" element={<ChangelogPage />} />

            <Route exact path="/search" element={<SearchPage />} />

            <Route exact path="/docs" element={<DocsPage />} />

            <Route exact path="/tutorials/:category?/:title?" element={<TutorialsPage />} />

            <Route exact path="/profile/:id" element={<ProfilePage />} />

            <Route exact path="/game/:id" element={<GamePage />} />

            <Route exact path="/game/:id/logs" element={<LogsPage />} />

            <Route exact path="/game/:id/players" element={<PlayersList />} />
            <Route exact path="/game/:id/players/:profileId" element={<PlayerPage />} />

            <Route exact path="/game/:id/save-type" element={<PlayerSavesList />} />
            <Route exact path="/game/:id/save-type/:saveTypeId" element={<PlayerSaveEdit />} />

            <Route exact path="/game/:id/config/experiments" element={<ExperimentsList />} />
            <Route exact path="/game/:id/config/experiments/create" element={<ExperimentCreate />} />
            <Route exact path="/game/:id/config/experiments/view/:configId" element={<ExperimentEdit />} />
            <Route exact path="/game/:id/config/experiments/edit/:configId" element={<ExperimentEdit />} />

            <Route
                exact
                path="/game/:id/config/built-in/view/forced-update"
                element={<ConfigBuiltInForcedUpdate />}
            />
            <Route
                exact
                path="/game/:id/config/built-in/view/unity-addressables"
                element={<ConfigBuiltInUnityAddressables />}
            />

            <Route exact path="/game/:id/config/overview/:componentURL" element={<Overview />} />
            <Route exact path="/game/:id/config/overview/:componentURL/:filterType" element={<Overview />} />
            <Route
                exact
                path="/game/:id/config/overview/:componentURL/:filterType/*"
                element={<Overview />}
            />
            <Route exact path="/game/:id/config/overview" element={<Overview />} />

            <Route exact path="/game/:id/config/entities" element={<EntitiesList />} />
            <Route exact path="/game/:id/config/entities/create" element={<EntitiesList creating />} />
            <Route exact path="/game/:id/config/entities/view/:configId" element={<EntitiesList editing />} />
            <Route exact path="/game/:id/config/entities/edit/:configId" element={<EntitiesList editing />} />

            <Route exact path="/game/:id/config/products" element={<ProductsList />} />
            <Route exact path="/game/:id/config/products/create" element={<ProductsList creating />} />
            <Route exact path="/game/:id/config/products/view/:configId" element={<ProductsList editing />} />
            <Route exact path="/game/:id/config/products/edit/:configId" element={<ProductsList editing />} />

            <Route exact path="/game/:id/config/:componentURL" element={<ConfigsList />} />
            <Route exact path="/game/:id/config/:componentURL/create" element={<ConfigCreate />} />
            <Route exact path="/game/:id/config/:componentURL/view/:configId" element={<ConfigEdit />} />
            <Route exact path="/game/:id/config/:componentURL/edit/:configId" element={<ConfigEdit />} />
            <Route exact path="/game/:id/storage/*" element={<StoragePage />} />

            <Route exact path="/game/:id/settings/iam" element={<IAMSettings />} />
            <Route exact path="/game/:id/settings/roles" element={<RolesSettings />} />
            <Route exact path="/game/:id/settings/game" element={<GameSettings />} />
            <Route exact path="/game/:id/settings/stage" element={<StageSettings />} />
            <Route exact path="/game/:id/settings/environments" element={<EnvironmentsSettings />} />
            <Route exact path="/game/:id/settings/versions" element={<VersionsSettings />} />
            <Route exact path="/game/:id/settings/open-api" element={<OpenAPISettings />} />

            <Route exact path="/game/:id/missing-permissions" element={<MissingPermissionsPage />} />
            <Route exact path="/missing-permissions" element={<MissingPermissionsPage />} />

            <Route exact path="/game/:id/automation/scripts" element={<ScriptsLibrary />} />
            <Route exact path="/game/:id/automation/processes" element={<ProcessesList />} />
            <Route exact path="/game/:id/automation/processes/:processId" element={<ProcessDetails />} />

            <Route path="/login" element={<Navigate to="/" />} />
            <Route path="/oauth2-success" element={<Navigate to="/" />} />

            <Route path="*" element={<NotFoundPage />} />
        </Route>
    )
);

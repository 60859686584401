/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { ButtonFileInput } from 'components';
import { FileImporter } from './FileImporter';
import { ImportFromGoogleDrive } from './ImportFromGoogleDrive';

export const ImportAndMerge = (props) => {
    const { onBack, onImport, content } = props;

    const [error, setError] = useState(null);
    const [warningMessage, setWarningMessage] = useState(null);
    const [imported, setImported] = useState(null);
    const [priority, setPriority] = useState('file');
    const [googleDriveImporting, setGoogleDriveImporting] = useState(false);
    const [textxWillBeIgnored, setTextxWillBeIgnored] = useState(0);

    useEffect(() => {
        if (!content || !imported) {
            setWarningMessage(null);
            return;
        }

        const newContent = imported.json;

        let keysWillBeAdded = 0;
        let textsWillBeIgnored = 0;
        let languagesWillBeAdded = 0;

        const existsLangs = {};

        for (let key in content) {
            for (let lang in content[key]) {
                existsLangs[lang] = true;
            }
        }

        for (let key in newContent) {
            const obj1 = newContent[key];

            for (let lang in obj1) {
                if (!existsLangs[lang]) {
                    existsLangs[lang] = true;
                    languagesWillBeAdded++;
                }
            }

            if (!content[key]) {
                keysWillBeAdded++;
                continue;
            }

            const obj2 = content[key];

            for (let lang in obj2) {
                if (obj1[lang] && obj1[lang] !== obj2[lang]) textsWillBeIgnored++;
            }
        }

        let warning = `${keysWillBeAdded} keys will be added, ${languagesWillBeAdded} languges will be added.`;

        setTextxWillBeIgnored(textsWillBeIgnored);
        if (textsWillBeIgnored > 0) {
            let _priority = priority === 'file' ? 'exists' : 'imported';
            warning += ` ⚠️ ${textsWillBeIgnored} ${_priority} values will be lost !`;
        }

        setWarningMessage(warning);
    }, [content, imported, priority]);

    const reset = () => {
        setError(null);
        setImported(null);
        onBack(null);
    };

    const importAndMerge = () => {
        const newContent = imported.json; //JSON.parse(JSON.stringify(imported.json));

        for (let key in content) {
            if (!newContent[key]) {
                newContent[key] = content[key];
                continue;
            }

            for (let lang in content[key]) {
                if (!newContent[key][lang]) {
                    newContent[key][lang] = content[key][lang];
                    continue;
                }

                if (priority === 'exists') newContent[key][lang] = content[key][lang];
            }
        }

        onImport(newContent);
        reset();
    };

    const acceptNewFile = async (files) => {
        setError(null);
        setGoogleDriveImporting(false);

        try {
            const parsed = await FileImporter(files);
            setImported(parsed);
        } catch (err) {
            setImported(null);
            setError(err.message || err);
        }
    };

    return (
        <div className="p-3">
            <div>
                <ButtonFileInput
                    title="From file"
                    icon="fas fa-file-excel"
                    onAccept={acceptNewFile}
                    accept="application/json, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet, text/plain"
                />
                <button
                    className="btn btn-sm btn-primary ms-2"
                    onClick={() => setGoogleDriveImporting(!googleDriveImporting)}
                >
                    <i className="fab fa-google-drive me-1" /> From Google Drive
                </button>

                {googleDriveImporting && (
                    <div className="shadow border rounded-3 mb-5">
                        <ImportFromGoogleDrive
                            onAccept={acceptNewFile}
                            onCancel={setGoogleDriveImporting}
                            show={googleDriveImporting}
                        />
                    </div>
                )}
            </div>

            <hr />

            {imported && (
                <div className="my-3 fw-light">
                    Found {imported.info.keys} keys, {imported.info.langs} languages and {imported.info.texts}{' '}
                    values.
                </div>
            )}

            {textxWillBeIgnored > 0 && (
                <div className="row py-2">
                    <div className="mb-2 small">What to do with the same key / translation values?</div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                name="same-cell-action"
                                type="radio"
                                id="same-cell-file"
                                onChange={(e) => setPriority('file')}
                                checked={priority === 'file'}
                            />
                            <label className="form-check-label" htmlFor="same-cell-file">
                                Replace with imported values
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                name="same-cell-action"
                                type="radio"
                                id="same-cell-exists"
                                onChange={(e) => setPriority('exists')}
                                checked={priority === 'exists'}
                            />
                            <label className="form-check-label" htmlFor="same-cell-exists">
                                Keep exists values
                            </label>
                        </div>
                    </div>
                </div>
            )}
            <div className="mt-3">
                {warningMessage && (
                    <div className="alert alert-warning">
                        <i className="fas fa-exclamation-triangle fa-sm me-2" /> {warningMessage}
                    </div>
                )}
            </div>
            {imported && <hr />}
            <div className="d-flex align-items-center">
                {error && (
                    <div className="text-danger me-2">
                        <i className="fas fa-exclamation-circle fa-sm me-2" />
                        {error}
                    </div>
                )}
                <button onClick={reset} className="btn rounded-pill btn-light shadow px-3 ms-auto">
                    Cancel
                </button>
                <button
                    disabled={!imported}
                    onClick={importAndMerge}
                    className="btn rounded-pill btn-success shadow px-3 ms-2"
                >
                    Merge
                </button>
            </div>
        </div>
    );
};

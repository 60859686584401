export const InputSelect = ({
    id,
    name,
    value,
    onChange,
    placeholder,
    options,
    hidden,
    disabled,
    size = 6
}) => {
    return (
        <div className={'col-md-' + size + ' col-sm-12 mb-3' + (hidden ? ' d-none' : '')}>
            <label className="mb-1 fw-bold" htmlFor={id}>
                {name}
            </label>
            <select
                id={id}
                value={value || ''}
                onChange={(e) => onChange(e.target.value)}
                className="form-select"
                disabled={disabled}
            >
                {placeholder && (
                    <option disabled value="">
                        {placeholder}
                    </option>
                )}
                {options.map((e) => (
                    <option key={e.value} value={e.value}>
                        {e.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Modal, Spinner } from '@gpg-web/react';
import { getEnvironments, addEnvironment, removeEnvironment } from 'services/settings';
import { utils, date } from '@gpg-web/utils';
import { IAMButton, IAMButtonSmall, UserBadge, Input, useGame } from 'components';
import { SettingsNavigation } from './Navigation';
import Skeleton from 'react-loading-skeleton';

const columns = [
    {
        Header: '#',
        accessor: 'index'
    },
    {
        Header: 'Name',
        accessor: 'displayName',
        sortType: (rowA, rowB) => {
            return rowA.original.id > rowB.original.id ? -1 : 1;
        }
    },
    {
        Header: 'Created By',
        accessor: 'displayAuthor',
        sortType: (rowA, rowB) => {
            return rowA.original.user > rowB.original.user ? -1 : 1;
        }
    },
    {
        Header: 'Created At',
        accessor: 'displayCreatedAt',
        sortType: (rowA, rowB, columnId, desc) => {
            return rowA.original.createdAt > rowB.original.createdAt ? 1 : -1;
        }
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true
    }
];

export const EnvironmentsSettings = (props) => {
    const [adding, setAdding] = useState({});
    const [isAdding, setIsAdding] = useState(false);
    const [saving, setSaving] = useState(false);
    const [environments, setEnvironments] = useState(null);
    const game = useGame();
    const history = useNavigate();

    const gameId = game.id;

    useEffect(() => {
        if (!gameId) return;

        getEnvironments(gameId)
            .then((list) => setEnvironments(list.slice()))
            .catch(utils.hintError);
    }, [gameId]);

    const data = useMemo(() => {
        if (!environments) return [];

        const _removeEnvironment = (e) => {
            e.stopPropagation();

            const id = e.target.getAttribute('data-id');

            utils.confirm('Are you sure want to remove this environment?', (yes) => {
                if (yes) {
                    utils.popup('removing');

                    removeEnvironment(gameId, id)
                        .then(() => {
                            utils.remove(environments, (e) => e.id === id);

                            setEnvironments(environments.slice());

                            utils.popup('hide');
                        })
                        .catch(utils.hintError);
                }
            });
        };

        return environments.map((e, index) => {
            return {
                index: index + 1,
                id: e.id,
                displayName: (
                    <>
                        <div className="text-primary">{e.id}</div>
                        <div className="small">{e.name}</div>
                    </>
                ),
                name: e.name,
                displayAuthor: <UserBadge email={e.user} onClick={(e) => history(e.url)} />,
                user: e.user,
                createdAt: new Date(e.date),
                displayCreatedAt: date(e.date, 'ago-1'),
                actions: (
                    <>
                        <IAMButtonSmall permissions={['settings.environments.update']}>
                            <button
                                title="Delete environment"
                                data-id={e.id}
                                onClick={_removeEnvironment}
                                className="btn btn-sm"
                            >
                                <i className="fa fa-trash pe-none" />
                            </button>
                        </IAMButtonSmall>
                    </>
                )
            };
        });
    }, [environments, gameId, history]);

    const _addEnvironment = async () => {
        try {
            let id = adding.id.trim();

            if (!id) return utils.hintError('ID field is required');

            if (!utils.isValidKeyName(id)) {
                return utils.hintError(
                    'ID is not valid. a-z, A-Z, 0-9, slash and underscore are only allowed'
                );
            }

            if (id[id.length - 1] !== '/') id += '/';

            setSaving(true);
            const result = await addEnvironment(gameId, {
                id: id,
                name: adding.name
            });
            utils.hintOk(result.id + ' is added');
            environments.push(result);
            setEnvironments(environments.slice());
            setSaving(false);
            setIsAdding(false);
            setAdding({});
        } catch (err) {
            utils.hintError(err);
            setSaving(false);
        }
    };

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setAdding({ ...adding, [name]: value });
    };

    return (
        <>
            <SettingsNavigation gameId={gameId} tab="environments" />
            <div className="container-lg">
                <div className="d-flex align-items-center">
                    <h4 className="mb-0 me-3">Environments</h4>
                    <IAMButton permissions={['settings.environments.update']}>
                        <button onClick={() => setIsAdding({})} className="btn btn-light text-primary">
                            <i className="fa fa-plus me-1" /> ADD
                        </button>
                    </IAMButton>
                </div>

                <Modal title="Add new game environment" show={isAdding} onHide={() => setIsAdding(false)}>
                    <div className="modal-body">
                        <Input
                            name="id"
                            required
                            tooltip="REQUIRED: specify unique environment ID"
                            title="ID (path)"
                            value={adding.id}
                            onChange={handleChange}
                        />
                        <Input name="name" title="Display name" value={adding.name} onChange={handleChange} />
                    </div>
                    <div className="modal-footer">
                        <button disabled={saving} className="btn btn-secondary" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            disabled={saving || !environments}
                            onClick={_addEnvironment}
                            className="btn btn-success"
                        >
                            {saving ? <Spinner /> : <i className="fa fa-sm fa-plus me-1" />} Add
                        </button>
                    </div>
                </Modal>

                <div className="mt-4">
                    {!!environments && (
                        <Table
                            columns={columns}
                            sortBy={[{ id: 'displayCreatedAt', desc: true }]}
                            data={data}
                            className="table-striped"
                        />
                    )}
                    {!environments && <Skeleton className="mb-2" width="100%" count={5} height={60} />}
                </div>
            </div>
        </>
    );
};

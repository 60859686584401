/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */
import axios from 'axios';
import socket from './socket';

let notifications = null;

export const events = {};

socket.on('notification', function (notification) {
    const exists = notifications.find((e) => e.id === notification.id);

    if (exists) {
        Object.assign(exists, notification);
    } else {
        notifications.splice(0, 0, notification);
    }

    events.onupdate && events.onupdate(notifications);
});

// const startNotificationsUpdate = () => {
//     if (timer !== null) return;

//     timer = setInterval(() => {
//         let checkFrom = 0;

//         const last = notifications[0];

//         if (last) {
//             checkFrom = new Date(last.created_at).getTime();
//         }

//         return axios
//             .get('/api/notification/updates', {
//                 params: {
//                     from: checkFrom
//                 }
//             })
//             .then((data) => {
//                 if (data.length > 0) {
//                     for (let i = data.length - 1; i >= 0; i--) {
//                         utils.notify(data[i]);
//                         utils.sfx('hint');
//                         notifications.splice(0, 0, data[i]);
//                     }

//                     events.onupdate && events.onupdate(notifications);
//                 }
//             })
//             .catch((err) => {
//                 console.log('Notifications update error: ' + err);
//             });
//     }, 15000);
// };

export const getNotifications = () => {
    if (notifications !== null) {
        return Promise.resolve(notifications.slice());
    }

    return axios.get('/api/notification/list').then((data) => {
        notifications = data.sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1));

        // startNotificationsUpdate();

        return notifications;
    });
};

export const readAllNotifications = () => {
    notifications.forEach((notification) => {
        notification.read = true;
    });

    return axios.post('/api/notification/read-all');
};

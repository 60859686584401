export const ACTIONS = [
    'Add',
    'Update',
    'Delete',
    'Automation',
    'Disable',
    'Enable',
    'Archive',
    'Restore',
    'Update:audience',
    'Update:scheduler',
    'Update:title',
    'Update:status'
];

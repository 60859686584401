/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect } from 'react';
import { utils } from '@gpg-web/utils';
import DisabledConfigLabel from '../../components/DisabledConfigLabel';
import { IAPEntityEditUsage, useGame, useValidation } from 'components';

import { RadioOption, NumberOption } from '../../components/contentOptions';

const ExpirationTypes = {
    resetMilestone: {
        label: 'Milestone',
        description: 'The piggy bank reward is reset to this instances first milestones value'
    },
    resetStreak: {
        label: 'Streak',
        description: 'The piggy bank is reset to the very first piggy bank (the one with the lowest level)'
    }
};

export const DefaultContentValue = JSON.stringify({
    // disabled: false,
    order: 1,
    countdownTimer: 1,
    milestones: [],
    expirationType: 'resetMilestone',
    purchaseMilestone: 0,
    timerMilestone: 0,
    iap: {}
});
const getDefaultValue = () => JSON.parse(DefaultContentValue);

const PiggyBankView = (props) => {
    const { value, editing, fileName, onChange, onError } = props;

    const game = useGame();
    const gameId = game.id;

    const { errors, validate } = useValidation(ContentValidationConfig);

    /**
     * Validation start
     */
    useEffect(() => {
        if (!value) onChange(getDefaultValue());
        else if (value === '::disabled') return;
        else if (typeof value !== 'object') onChange(getDefaultValue());
        else if (typeof value === 'object' && Object.keys(value).length === 0) onChange(getDefaultValue());
        else if (!Array.isArray(value.milestones))
            onChange({ ...value, milestones: getDefaultValue().milestones });
        else if (
            typeof value.expirationType !== 'string' ||
            Object.keys(ExpirationTypes).indexOf(value.expirationType) === -1
        )
            onChange({ ...value, expirationType: getDefaultValue().expirationType });
        else if (value) {
            const isValid = validate(value);
            onError(!isValid);
        }
    }, [value, onChange, validate, onError]);
    /**
     * Validation end
     */
    if (value === '::disabled') return <DisabledConfigLabel />;

    const onChangeOption = (key, val) => {
        const prevValue = value || getDefaultValue();

        const nextValue = { ...prevValue, [key]: val };

        const isValid = validate(nextValue);

        onError(!isValid);

        onChange(nextValue);
    };

    const { order, countdownTimer, milestones, expirationType, purchaseMilestone, timerMilestone } =
        value || {};

    return (
        <div className={utils.className('mt-4 pt-2', !props.fluid && 'storage-view-box')}>
            <div className="mb-4 d-flex align-items-start">
                <div className="col-4">
                    <NumberOption
                        title={'Order'}
                        name="order"
                        value={order}
                        readOnly={!editing}
                        error={errors['order']}
                        onChange={onChangeOption}
                    />
                </div>
            </div>
            <div className="mb-4">
                <NumberOption
                    title={'Expiration Timer'}
                    name="countdownTimer"
                    description={'The time (in minutes) before the Piggy Bank reward expires'}
                    error={errors['countdownTimer']}
                    value={countdownTimer}
                    readOnly={!editing}
                    onChange={onChangeOption}
                />
            </div>

            <div className="mb-4">
                <RadioOption
                    title={'Expiration Type'}
                    name="expirationType"
                    value={expirationType}
                    readOnly={!editing}
                    onChange={onChangeOption}
                    options={Object.keys(ExpirationTypes).map((o) => ({
                        value: o,
                        key: editing + fileName + o,
                        label: ExpirationTypes[o].label,
                        description: ExpirationTypes[o].description
                    }))}
                />
            </div>
            <div className="mb-3 d-flex align-items-start justify-content-between">
                <div className="col-6">
                    <NumberOption
                        title={'Purchase milestone'}
                        name="purchaseMilestone"
                        description={'The milestone index a player must reach to purchase the IAP'}
                        error={errors['purchaseMilestone']}
                        value={purchaseMilestone}
                        readOnly={!editing}
                        onChange={onChangeOption}
                    />
                </div>
                <div className="col-6">
                    <NumberOption
                        title={'Timer milestone'}
                        name="timerMilestone"
                        error={errors['timerMilestone']}
                        description={'The milestone index a player must reach to trigger the countdown timer'}
                        value={timerMilestone}
                        readOnly={!editing}
                        onChange={onChangeOption}
                    />
                </div>
            </div>

            <MilestoneOption
                title={'Milestones'}
                name="milestones"
                onChange={onChangeOption}
                value={milestones}
                readOnly={!editing}
                gameId={gameId}
                error={errors['milestones'] || errors['milestones_value']}
                purchaseIndex={purchaseMilestone}
                timerIndex={timerMilestone}
            />

            <div className="mt-4">
                <div className="d-flex border-top justify-content-between mb-2">
                    <div className="mx-auto mt-3">
                        <IAPEntityEditUsage
                            gameId={gameId}
                            value={value.iap || {}}
                            readOnly={!editing}
                            onChange={(iapName, iapValue) => {
                                const iap = value.iap || {};
                                iap[iapName] = iapValue;
                                onChangeOption('iap', iap);
                            }}
                            canRemove={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const isPositiveInteger = (val, min = 0) => typeof val === 'number' && Number.isInteger(val) && val >= min;

const isMilestoneIndex = (index, data) => {
    const array = data.milestones;
    return isPositiveInteger(index, 0) && (index === 0 || index < array.length);
};

const ContentValidationConfig = {
    order: {
        required: {
            value: true,
            message: 'Order cannot be empty'
        },
        custom: {
            isValid: (val) => isPositiveInteger(val, 1),
            message: 'Order has to be a positive integer number'
        }
    },
    countdownTimer: {
        required: {
            value: true,
            message: 'Expiration Timer cannot be empty'
        },
        custom: {
            isValid: (val) => isPositiveInteger(val, 1),
            message: 'Expiration Timer has to be a positive integer number'
        }
    },
    milestones: {
        custom: {
            isValid: (val) => Array.isArray(val) && val.length > 0,
            message: 'At least one milestone should be specified'
        }
    },

    milestones_value: {
        custom: {
            isValid: (val, d) => Array.isArray(d.milestones) && d.milestones.every((val) => val > 0),
            message: 'Milestone quantity should positive number'
        }
    },

    purchaseMilestone: {
        required: {
            value: true,
            message: 'Purchase Milestone cannot be empty'
        },
        custom: {
            isValid: isMilestoneIndex,
            message: 'Purchase Milestone has to be an index of milestone'
        }
    },
    timerMilestone: {
        required: {
            value: true,
            message: 'Timer Milestone cannot be empty'
        },
        custom: {
            isValid: isMilestoneIndex,
            message: 'Timer Milestone has to be an index of milestone'
        }
    }
};

const MilestoneOption = ({ title, name, value, onChange, readOnly, purchaseIndex, timerIndex, error }) => {
    const milestones = value || [];

    const addMilestone = (e) => {
        onChange(name, [...milestones, 1]);
    };

    return (
        <div className="my-1">
            <div className="d-flex border-top justify-content-between">
                <div className="mx-2 mt-1 text-nowrap">{title}</div>
            </div>
            {!!error && (
                <span className="small text-danger mx-2 mb-2">
                    <i className="fas fa-exclamation-circle me-1" />
                    {error}
                </span>
            )}
            {milestones.length > 0 ? (
                <div className="mx-4 mt-2 small">
                    <div className="row fw-bold text-muted">
                        <div className="col-2"></div>
                        <div className="col-4">Quantity</div>
                        <div className="col-4"></div>
                    </div>
                    {milestones.map((v, i) => (
                        <div className="row align-items-center my-2" key={i}>
                            <div className="col-2 px-0 text-end fw-bold text-muted">Milestone {i}</div>
                            <div className="col-4">
                                <input
                                    type="number"
                                    disabled={readOnly}
                                    className="form-control"
                                    min="1"
                                    title="Reward item quantity"
                                    value={v && typeof v === 'number' ? v : 0}
                                    style={{ paddingTop: '2px', paddingBottom: '2px' }}
                                    onChange={(e) => {
                                        const newList = milestones.slice();

                                        newList[i] = Number(e.target.value);

                                        onChange(name, newList);
                                    }}
                                />
                            </div>
                            <div className="col-4 flex-nowrap text-start px-0">
                                {!readOnly && (
                                    <>
                                        <div
                                            className="btn text-secondary btn-sm p-0 px-1 me-2"
                                            title="Remove milestone"
                                            onClick={() => {
                                                const newList = milestones.slice();

                                                newList.splice(i, 1);

                                                onChange(name, newList);
                                            }}
                                        >
                                            <i className="fas fa-times-circle" />
                                        </div>
                                    </>
                                )}
                                {purchaseIndex === i && (
                                    <span className="badge bg-primary mx-1 ">Purchase</span>
                                )}
                                {timerIndex === i && <span className="badge bg-primary">Timer</span>}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-muted small fw-bold opacity-3 mx-2 mt-2 mb-0">No milestones added</p>
            )}
            {!readOnly && (
                <div className="btn btn-sm btn-outline-primary fw-bold w-100 mt-2" onClick={addMilestone}>
                    <i className="fas fa-plus-circle me-1" />
                    Add milestone
                </div>
            )}
        </div>
    );
};

export { PiggyBankView };

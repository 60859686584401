const ContactUsModal = (props) => {
    return (
        <>
            <div className="modal fade" id="contact-us-modal" data-bs-backdrop="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Contact us</h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                Via email:
                                <a
                                    className="ms-2"
                                    href="mailto:livefire-support@greenpandagames.com?subject=Subject&body=Ask your question here"
                                >
                                    livefire-support@greenpandagames.com
                                </a>
                            </div>
                            <div className="mt-3">
                                Via public slack channel:
                                <a
                                    className="ms-2"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://app.slack.com/client/T03DY5L8Y/C053SKRAJDN"
                                >
                                    link
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button data-bs-dismiss="modal" className="btn btn-secondary ms-auto">
                                Close
                            </button>
                            <a
                                className="btn btn-primary ms-2"
                                href="mailto:livefire-support@greenpandagames.com?subject=Subject&body=Ask your question here"
                            >
                                <i className="fas fa-envelope me-1" /> Write email
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUsModal;

import { Components } from 'consts';
import { getScripts } from 'services/automation';
import { loadEnvironments, loadGameVersions } from '../loaders';
import { EnvironmentView, GameVersionView, StatusView } from '../optionViews';

export const configPromoteEnvironment = {
    description: (
        <>
            For every config - promote Environments from X to Y.
            <br />
            Optionally can change status of every changed config.
        </>
    ),
    color: '#6173dd',
    icon: 'fas fa-leaf',
    options: [
        {
            id: 'components',
            name: 'Component',
            value: 'all',
            type: 'select_button',
            placeholder: 'Select component',
            size: 12,
            options: [],
            isMulti: true,
            asyncLoad: async (gameId) => {
                const scripts = await getScripts(gameId);
                const script = scripts.find((_script) => _script.id === 'configPromoteEnvironment');

                const scriptComponents = script ? script.components : [];

                let componentOptions = scriptComponents.reduce((prev, val) => {
                    const component = Components.find((_component) => _component.id === val);

                    prev.push({ value: val, label: component.name });

                    return prev;
                }, []);

                return componentOptions;
            }
        },
        {
            id: 'src',
            name: 'From Environment',
            type: 'select',
            options: [],
            placeholder: 'Select environment',
            asyncLoad: loadEnvironments,
            view: EnvironmentView
        },
        {
            id: 'dst',
            name: 'To Environment',
            type: 'select',
            options: [],
            placeholder: 'Select environment',
            asyncLoad: loadEnvironments,
            view: EnvironmentView
        },
        {
            id: 'version',
            name: 'Promote Specific Game Version',
            type: 'select_button',
            isMulti: true,
            value: [],
            asyncLoad: async (gameId) => {
                const result = await loadGameVersions(gameId);
                return [{ value: 'all', label: 'All Versions' }, ...result];
            },
            view: GameVersionView
        },

        {
            id: 'setStatus',
            name: 'Set Status for every changed config',
            value: 'test',
            type: 'select_button',
            options: [
                { value: 'draft', label: 'Draft' },
                { value: 'test', label: 'Test' },
                { value: 'live', label: 'Live' }
            ],
            view: StatusView
        }
    ],
    validate: function (values) {
        if (!values.components || values.components.length === 0)
            throw new Error('Component should be specified');
        if (!values.src) throw new Error('From Environment should be specified');
        if (!values.dst) throw new Error('To Environment should be specified');

        return true;
    },
    name: 'Promote Environment'
};

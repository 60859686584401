import { ComponentsMap } from 'consts';
import { getConfigs } from 'services/config';
import { StorageCreateGameBalance } from '../components/InputStorage';
import { loadEnvironments, loadGameVersions } from '../loaders';
import { EnvironmentView, GameVersionView, StatusView, CreateConfigView } from '../optionViews';

export const gameBalanceCreate = {
    description: (
        <>
            Create Game Balance for every unused file in selected path
            <br />
            Select specific environments and game version to set up.
        </>
    ),
    color: '#4bab4b',
    icon: 'fas fa-plus',
    options: [
        {
            id: 'gameBalanceConfigs',
            name: 'Storage path (file/folder)',
            type: 'storage',
            requiredFileVersion: false,
            isFolderSelect: true,
            foldersOnly: false,
            value: null,
            addons: [StorageCreateGameBalance],
            view: CreateConfigView
        },
        {
            id: 'environments',
            name: 'Environments',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: [],
            asyncLoad: async (gameId) => {
                const result = await loadEnvironments(gameId);
                return [{ value: 'all', label: 'All Environments' }, ...result];
            },
            view: EnvironmentView
        },
        {
            id: 'versions',
            name: 'Game Versions',
            type: 'select_button',
            isMulti: true,
            options: [],
            value: 'all',
            asyncLoad: async (gameId) => {
                const result = await loadGameVersions(gameId);
                return [{ value: 'all', label: 'All Versions' }, ...result];
            },
            view: GameVersionView
        },
        {
            id: 'setStatus',
            name: 'Set Status for every changed config',
            value: 'draft',
            type: 'select_button',
            options: [
                { value: 'draft', label: 'Draft' },
                { value: 'test', label: 'Test' },
                { value: 'live', label: 'Live' }
            ],
            view: StatusView
        }
    ],
    validate: async function (values, gameId) {
        const { gameBalanceConfigs, environments, versions } = values;

        if (!gameBalanceConfigs) throw new Error('Storage path should be specified');

        const configs = await getConfigs(gameId, ComponentsMap['gameBalance'].url);

        const configNames = configs.map((config) => config.id);

        let atLeastOne = false;

        for (let configKey in gameBalanceConfigs.configs) {
            const config = gameBalanceConfigs.configs[configKey];

            atLeastOne = atLeastOne || config.create;

            if (config.create) {
                if (configNames.indexOf(config.id) > -1 || config.id.length === 0) {
                    throw new Error('There are some invalid config ids');
                }
            }
        }

        if (!atLeastOne) throw new Error('There should be at least one new config to create');

        if (!environments || environments.length === 0)
            throw new Error('At least one Environment should be selected');

        if (!versions || versions.length === 0)
            throw new Error('At least one Game Version should be selected');

        return true;
    },
    name: 'Create Environmental configs'
};

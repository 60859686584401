export const PromotePopup = (props) => {
    const { configs, version, environment, environments, versions, onChange, isVersionView, onHide } = props;

    const env = (environments || []).find((e) => e.id === environment) || {};
    const ver = (versions || []).find((e) => e.id === version) || {};

    const list = isVersionView ? environments : versions;

    const selected = isVersionView ? env : ver;

    const onPromoteSelect = async (id) => {
        const updatedConfigs = configs.slice();

        const srcPath = environment + version + '/';
        const dstPath = isVersionView ? id + version + '/' : environment + id + '/';

        for (let config of updatedConfigs) {
            if (config.content && config.content.path && config.content.versions) {
                const src = config.content.versions[srcPath];
                const dst = config.content.versions[dstPath];

                if (src && src.version) {
                    config.content.versions[dstPath] = dst
                        ? { ...dst, version: src.version }
                        : { version: src.version };
                }
            }
        }

        onChange(updatedConfigs);
        onHide();
    };

    return (
        <div className="card" style={{ minWidth: '250px' }}>
            <div className="card-header p-1 px-3 fw-bold">
                <div className="modal-title">
                    Promote {isVersionView ? selected.name || selected.id : selected.id} to:
                </div>
            </div>
            <div className="card-body p-0 overflow-y-auto">
                <ul className="list-group list-group-flush">
                    {list.map((e) => {
                        if (e.id === environment || e.id === version) return null;

                        return (
                            <button
                                key={e.id}
                                className="list-group-item list-group-item-action"
                                onClick={() => onPromoteSelect(e.id)}
                            >
                                {isVersionView ? e.name || e.id : e.id}
                            </button>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

import { useEffect, useState } from 'react';
import { PopoverWrapper } from '../tooltips/PopoverWrapper';
import moment from 'moment';
import { IAMButton } from './IAMButton';
import { useUserIAM } from '../hooks/UseUserIAM';

const DeleteTooltipContent = ({ createdAt }) => {
    const [dateNow, setDateNow] = useState(Date.now());

    useEffect(() => {
        let t = setInterval(() => {
            setDateNow(Date.now());
        }, 1000);

        return () => {
            clearInterval(t);
        };
    }, []);

    return (
        <div className="card small p-1 px-2 border-0 shadow-sm text-muted d-flex ">
            {dateNow - createdAt < 60 * 60 * 1000 ? (
                <span>
                    You have{' '}
                    <span className="fw-bold">
                        {moment(createdAt).add(1, 'hour').subtract(moment(dateNow)).format('mm:ss')}
                    </span>{' '}
                    minutes to perform this action
                </span>
            ) : (
                <span>You don't have permissions to perform this action</span>
            )}
        </div>
    );
};

export const DeleteButton = (props) => {
    const { deletePermission, createdBy } = props;

    const createdAt = new Date(props.createdAt);

    const { userPermissions, user } = useUserIAM();

    const isLoading = !userPermissions;

    if (isLoading) return null;

    const canDelete = userPermissions.indexOf(deletePermission) > -1;

    if (!canDelete && createdBy === user.email && Date.now() - createdAt < 1000 * 60 * 60)
        return (
            <PopoverWrapper
                {...props}
                icon="fas fa-clock"
                content={<DeleteTooltipContent createdAt={createdAt} />}
            />
        );

    return <IAMButton {...props} permissions={[deletePermission]} />;
};

export const DeleteButtonSmall = (props) => <DeleteButton {...props} top={20} left={80} size={'xs'} />;

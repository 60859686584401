import { List, AutoSizer, WindowScroller } from 'react-virtualized';
import { date as dateUtils, utils } from '@gpg-web/utils';
import { useGame } from 'components';

export const Version = (props) => {
    const {
        canSelect,
        current,
        selected,
        showed,
        onSelect,
        onShow,
        onDownload,
        onCompare,
        version,
        canCompare,
        parentId,
        onArchive,
        canArchive,
        canShow,
        onDeleteDraft
    } = props;

    const { id, author, message, date, archived, isDraft } = version;

    const { isProduction } = useGame();

    const canDeleteDraft = isDraft && onDeleteDraft;

    return (
        <div
            className={utils.className(
                'card mt-1',
                isDraft
                    ? isProduction
                        ? 'border-danger-subtle bg-danger-subtle text-danger-emphasis'
                        : 'border-primary-subtle'
                    : '',
                showed
                    ? isDraft
                        ? 'border-0 border-bottom border-primary border-5'
                        : 'border-0 border-bottom border-success border-5'
                    : ''
            )}
        >
            <div className="card-body">
                <div className="d-flex">
                    <div className="h5 text-muted">
                        {isDraft ? (
                            <span className={utils.className(isProduction ? 'text-danger' : 'text-primary')}>
                                <i
                                    className={utils.className(
                                        isProduction ? 'fas fa-exclamation-triangle' : 'fas fa-edit',
                                        'me-1 fa-sm'
                                    )}
                                />
                                Draft - {id.replace('draft.', '')}
                            </span>
                        ) : (
                            <>#{id}</>
                        )}
                        {current ? '(latest)' : ''} {archived ? '(ARCHIVED)' : ''}{' '}
                        {canSelect && selected ? (
                            <span className="text-success small">[CURRENT VERSION]</span>
                        ) : (
                            ''
                        )}
                    </div>
                    {canArchive && !isDraft ? (
                        <>
                            {!archived ? (
                                <button
                                    onClick={() => onArchive(version, true)}
                                    className="btn btn-sm ms-auto btn-white shadow-sm small border-1 mb-2"
                                    title="Archive"
                                >
                                    <i className="fas fa-archive" />
                                </button>
                            ) : null}
                        </>
                    ) : null}
                </div>
                <p className="mb-2">{message}</p>
                <div className="d-flex small align-items-center pt-3 border-1 border-top text-muted">
                    <span> by {author.name} </span>
                    <span className="ms-auto">{dateUtils(date)}</span>
                </div>
            </div>

            <div className="card-footer bg-white text-end border-top border-1">
                {canSelect ? (
                    <>
                        {selected ? (
                            <button onClick={() => onSelect({})} className="btn btn-sm me-1 btn-danger">
                                Discard
                            </button>
                        ) : (
                            <button onClick={() => onSelect(version)} className="btn btn-sm me-1 btn-success">
                                Select
                            </button>
                        )}
                    </>
                ) : null}
                {canShow ? (
                    <button
                        onClick={() => onShow(version)}
                        disabled={showed}
                        className="btn btn-sm btn-primary me-1"
                    >
                        Show
                    </button>
                ) : null}
                {canArchive && !isDraft ? (
                    <>
                        {archived ? (
                            <button
                                onClick={() => onArchive(version, false)}
                                className="btn btn-sm me-1 btn-secondary"
                            >
                                Restore
                            </button>
                        ) : null}
                    </>
                ) : null}
                <button onClick={() => onDownload(id)} className="btn btn-sm btn-secondary me-1">
                    Download
                </button>
                {canCompare && parentId && (
                    <button
                        onClick={() => onCompare({ id, parentId })}
                        className="btn btn-sm btn-secondary me-1"
                    >
                        Changes
                    </button>
                )}
                {!selected && canDeleteDraft ? (
                    <button onClick={() => onDeleteDraft(version)} className="btn btn-sm btn-danger me-1">
                        Remove
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export const VersionSmall = (props) => {
    const {
        canSelect,
        selected,
        showed,
        onSelect,
        onShow,
        onDownload,
        onCompare,
        version,
        canCompare,
        parentId,
        canShow,
        onDeleteDraft,
        showAuthor = true,
        showDate = true
    } = props;

    const { id, author, message, date, size, isDraft } = version;

    const { isProduction } = useGame();

    const canDeleteDraft = isDraft && onDeleteDraft;
    return (
        <div
            className={utils.className(
                'card mb-3',
                isDraft
                    ? isProduction
                        ? 'border-danger-subtle bg-danger-subtle text-danger-emphasis'
                        : 'border-primary-subtle'
                    : '',
                showed
                    ? isDraft
                        ? 'bg-primary-subtle text-primary-emphasis'
                        : 'bg-success-subtle text-success-emphasis'
                    : ''
            )}
        >
            <div className="p-2">
                <div className="small">
                    <div>
                        {isDraft ? (
                            <span className={utils.className(isProduction ? 'text-danger' : 'text-primary')}>
                                <i
                                    className={utils.className(
                                        isProduction ? 'fas fa-exclamation-triangle' : 'fas fa-edit',
                                        'me-1 fa-sm'
                                    )}
                                />
                                Draft - {id.replace('draft.', '')}
                            </span>
                        ) : (
                            <>
                                #{id} - <span className="fw-light fst-italic">"{message}"</span>
                            </>
                        )}
                    </div>
                    {!!showAuthor && <div className="mt-1 fw-light">by {author.name}</div>}
                    {!!showDate && <div className="fw-light">at {dateUtils(date)}</div>}
                </div>
            </div>

            <div className="d-flex p-2 pt-1">
                <div className="dropdown me-auto no-arrow">
                    <button
                        className="btn btn-sm border-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                    >
                        <i className="fas fa-ellipsis-h" />
                    </button>
                    <ul className="dropdown-menu shadow">
                        <li
                            role="button"
                            className="dropdown-item"
                            onClick={() => onDownload(id)}
                            title={utils.bytesToStr(size)}
                        >
                            Download
                        </li>
                        {canCompare && parentId && (
                            <li
                                role="button"
                                className="dropdown-item"
                                onClick={() => onCompare({ id, parentId })}
                            >
                                Changes
                            </li>
                        )}
                        {!selected && canDeleteDraft && (
                            <li
                                role="button"
                                className="dropdown-item"
                                onClick={() => onDeleteDraft(version)}
                                disabled={showed}
                            >
                                Remove
                            </li>
                        )}
                    </ul>
                </div>
                {/* <button
                    title={'Download ' + utils.bytesToStr(size)}
                    onClick={() => onDownload(id)}
                    className="btn btn-sm small text-muted me-auto"
                >
                    <i className="fas fa-download" />
                </button>
                {canCompare && parentId && (
                    <button
                        onClick={() => onCompare({ id, parentId })}
                        className="btn btn-sm small rounded-pill btn-light me-1"
                    >
                        Changes
                    </button>
                )} */}
                {canSelect && (
                    <>
                        {selected ? (
                            <button
                                onClick={() => onSelect({})}
                                className="btn btn-sm small rounded-pill me-1 btn-danger"
                            >
                                Discard
                            </button>
                        ) : (
                            <button
                                onClick={() => onSelect(version)}
                                className={utils.className(
                                    'btn btn-sm small rounded-pill me-1 btn-success',
                                    isDraft && isProduction ? 'd-none' : ''
                                )}
                            >
                                Select
                            </button>
                        )}
                    </>
                )}
                {/* {!selected && canDeleteDraft ? (
                    <button
                        onClick={() => onDeleteDraft(version)}
                        disabled={showed}
                        className="btn btn-sm small rounded-pill btn-danger me-1"
                    >
                        Remove
                    </button>
                ) : null} */}
                {canShow && (
                    <button
                        onClick={() => onShow(version)}
                        disabled={showed}
                        className="btn btn-sm small rounded-pill btn-primary me-1"
                    >
                        Show
                    </button>
                )}
            </div>
        </div>
    );
};

export const VersionList = ({
    lastVersionId,
    showVersionId,
    selectedVersionId,
    versions,
    versionProps,
    scrollParent,
    searchFilter = false
}) => {
    // const scrollParent = useRef(
    //     scrollParentQuery
    //         ? document.querySelector(scrollParentQuery)
    //         : document.getElementById('file-metadata')
    // );

    const noVersionRowRenderer = () => (
        <div className="text-center fw-light text-muted mt-4">List is empty</div>
    );

    let displayVersions = [];

    if (searchFilter) {
        versions.reduce((val, e, i) => {
            if (searchFilter(e, i)) {
                val.push(i);
            }
            return val;
        }, displayVersions);
    } else {
        displayVersions = versions.map((e, i) => i);
    }

    const versionRowRenderer = ({ index, key, style }) => {
        const versionIndex = displayVersions[index];
        const version = versions[versionIndex];
        const current = version.id === lastVersionId;
        const showed = showVersionId === version.id;
        const selected = selectedVersionId === version.id;

        let parentId = null;
        // console.log(index);
        if (versionIndex < versions.length - 1) parentId = versions[versionIndex + 1].id;
        return (
            <div key={key} style={style}>
                <Version
                    {...versionProps}
                    version={version}
                    parentId={parentId}
                    showed={showed}
                    selected={selected}
                    current={current}
                />
            </div>
        );
    };

    return (
        <WindowScroller scrollElement={scrollParent || document.getElementById('file-metadata')}>
            {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                <div>
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <div
                                ref={(element) => {
                                    if (element && registerChild) {
                                        registerChild(element);
                                    }
                                }}
                            >
                                <List
                                    autoHeight
                                    height={
                                        height ||
                                        (scrollParent
                                            ? scrollParent.getBoundingClientRect().height
                                            : document.getElementById('file-metadata').getBoundingClientRect()
                                                  .height)
                                    }
                                    isScrolling={isScrolling}
                                    onScroll={onChildScroll}
                                    overscanRowCount={1}
                                    rowCount={displayVersions.length}
                                    rowHeight={200}
                                    noRowsRenderer={noVersionRowRenderer}
                                    rowRenderer={versionRowRenderer}
                                    scrollTop={scrollTop}
                                    width={width}
                                />
                            </div>
                        )}
                    </AutoSizer>
                </div>
            )}
        </WindowScroller>
    );
};

import { useState, useEffect, useMemo, useCallback } from 'react';
import { StorageFileModal } from '../../storage/FileModal';
import { utils } from '@gpg-web/utils';
import { Modal } from '@gpg-web/react';
import { StatusLabel, SimpleTable, useGame } from 'components';
import { ComponentsMap } from 'consts';

const EnvironmentalVersionCell = (props) => {
    const { column } = props;

    const hasValue = !!props.value.versions;
    const value = props.value || {};

    const { isProduction } = useGame();

    return (
        <div className="px-2 variants">
            <div className="mb-2 ps-1">
                <span className={'small ' + (!hasValue ? 'opacity-1' : '')}>
                    <StatusLabel value={value.versions?.status || 'draft'} />
                </span>
                {hasValue && (
                    <button
                        onClick={() => column.expandVersions(value.environment, value.gameVersion)}
                        title="Expand"
                        className="btn btn-sm float-end"
                    >
                        <i className="fas fa-expand" />
                    </button>
                )}
            </div>

            {hasValue &&
                column.variants.map((variant, index) => {
                    return (
                        <div
                            key={index}
                            className={'text-nowrap' + (index === column.variants.length - 1 ? '' : ' mb-3')}
                        >
                            <div
                                className={
                                    'small rounded-pill px-2 py-1 mb-1 fw-bold variant variant-color-' + index
                                }
                            >
                                {variant.id}
                            </div>

                            {column.configs.map((config, index) => {
                                const configUid = config.component + config.id;
                                let valueContent = value.versions?.['values'][variant.id]?.[configUid];
                                const version = valueContent?.version;
                                const component = ComponentsMap[config.component] || {
                                    name: config.component
                                };

                                if (!version) return undefined;

                                const isDraft = version.startsWith('draft');

                                return (
                                    <div key={index} className="small px-2 py-1 mb-1 border-bottom">
                                        {component.name + ' | ' + config.id}
                                        <button
                                            onClick={() =>
                                                column.editConfig({
                                                    filePath: config.path,
                                                    fileVersion: version,
                                                    path: value.path,
                                                    variant: variant.id,
                                                    uid: configUid
                                                })
                                            }
                                            className={utils.className(
                                                'btn btn-sm mx-3 py-0',
                                                isDraft
                                                    ? isProduction
                                                        ? 'btn-danger'
                                                        : 'btn-primary'
                                                    : 'btn-light'
                                            )}
                                        >
                                            <i
                                                className={utils.className(
                                                    'me-1',
                                                    isDraft && isProduction
                                                        ? 'fas fa-sm fa-exclamation-triangle'
                                                        : column.readOnly
                                                        ? 'fas fa-file fa-sm'
                                                        : 'fas fa-edit'
                                                )}
                                            />
                                            {isDraft ? 'Draft' : '#' + version}
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
};

const EnvironmentCell = (props) => {
    const { row, value } = props;

    const { original } = row;

    const { onPromote, readOnly } = original;

    return (
        <div className="text-nowrap position-relative pe-4" style={{ minWidth: '100px' }}>
            {value}
            <div className="position-absolute end-0 top-0">
                {!readOnly && (
                    <button
                        className="btn btn p-2 py-0 text-muted small"
                        onClick={() => onPromote()}
                        aria-expanded="false"
                    >
                        <i className="fas fa-random fa-sm" />
                    </button>
                )}
            </div>
        </div>
    );
};

const PromoteModal = ({ promote, gameVersions, onChange, environments, onHide, data }) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected([]);
    }, [promote]);

    let type = null;
    let value = null;
    let label = null;
    let onPromote = null;
    let list = [];

    let versionsList = gameVersions.map((v) => ({ value: v.id, label: 'v' + v.id }));

    let environmentsList = environments.map((e) => ({ value: e.id, label: e.id }));

    const onPromoteEnvironment = async (from, to) => {
        Object.keys(data).forEach((key) => {
            if (key.startsWith(from.path)) {
                let path = key.replace(from.path, to.path);
                data[path] = utils.clone(data[key]);
            }
        });
    };

    const onPromoteVersion = async (from, to) => {
        Object.keys(data).forEach((key) => {
            if (key.endsWith(from + '/')) {
                let path = key.replace(from, to);
                data[path] = utils.clone(data[key]);
            }
        });
    };

    if (promote) {
        type = promote.type;
        value = promote.value;
        label = promote.label;
        if (type === 'version') {
            onPromote = onPromoteVersion;
            list = versionsList;
        } else {
            onPromote = onPromoteEnvironment;
            list = environmentsList;
        }
    }

    return (
        <Modal
            show={!!promote}
            title={
                <>
                    Promoting {type} -<span className="text-promote-from ms-2">{label}</span>
                </>
            }
            onHide={onHide}
        >
            <div className="modal-body row">
                <div className="col-6">
                    <h6>Select {type}s</h6>
                    <ul className="list-group" style={{ maxHeight: '60vh', overflow: 'auto' }}>
                        {list.map((item) => {
                            if (item.value === value) return null;

                            const isSelected =
                                selected && selected.find((_item) => _item.value === item.value);

                            return (
                                <li className="list-group-item" key={item.value}>
                                    <input
                                        className="form-check-input me-3"
                                        type="checkbox"
                                        checked={isSelected}
                                        onChange={() =>
                                            isSelected
                                                ? setSelected(
                                                      selected.filter((_item) => _item.value !== item.value)
                                                  )
                                                : setSelected([...selected, item])
                                        }
                                        id={'promote-item-' + item.value}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={'promote-item-' + item.value}
                                    >
                                        {item.label}
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="col-6">
                    <h6>Will be promoted</h6>
                    <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                        {selected.length ? (
                            <>
                                {selected.map((changes, i) => {
                                    return (
                                        <div key={i} className="my-2 pb-2 border-bottom">
                                            <div>
                                                <span className="text-promote-from mx-2">{label}</span>to
                                                <span className="text-promote-to mx-2">
                                                    {selected[i].label}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="text-muted opacity-3">Nothing selected</div>
                        )}
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button data-bs-dismiss="modal" className="btn btn-secondary ">
                    Cancel
                </button>
                <button
                    disabled={!selected}
                    onClick={() => {
                        if (type === 'version') selected.map((item) => onPromote(value, item.value));
                        else
                            selected.map((item) =>
                                onPromote(
                                    { path: value, name: label },
                                    { path: item.value, name: item.label }
                                )
                            );
                        onChange();
                        onHide();
                    }}
                    className="btn btn-success"
                >
                    Promote
                </button>
            </div>
        </Modal>
    );
};

export const ContentViewTable = (props) => {
    const { versions, variants, configs, onChange, readOnly, environments, gameVersions, onExpand } = props;

    const [promote, setPromote] = useState(null);
    const [editingConfig, setEditingConfig] = useState({});

    const handleChangeVersion = (e) => {
        if (editingConfig.filePath) {
            editingConfig.fileVersion = e.version;
            setEditingConfig({ ...editingConfig });
        }

        onChange(editingConfig.path, {
            variant: editingConfig.variant,
            config: editingConfig.uid,
            value: { version: e.version }
        });
    };

    const onPromote = useCallback((type, value, label) => {
        setPromote({ type, value, label });
    }, []);

    const tableCols = useMemo(() => {
        console.log('Render tableCols');
        const cols = [];

        if (!environments) return cols;

        cols.push({
            Header: '',
            accessor: 'environment',
            cellClass: 'border-end',
            headerClass: '',
            disableSortBy: true,
            size: 50,
            Cell: EnvironmentCell
        });

        cols.push(
            ...gameVersions.map((v, i) => ({
                label: 'v' + v.id,
                accessor: v.id.replaceAll('.', '/'),
                disableSortBy: true,
                size: 100,
                _columnAttributes: { 'data-column-version': v.id },
                cellClass: 'border-end d-table-cell',
                headerClass: 'pe-0 d-table-cell text-start',
                Cell: EnvironmentalVersionCell,
                variants: variants,
                configs: configs,
                expandVersions: onExpand,
                readOnly: readOnly,
                editConfig: setEditingConfig,
                Header: (props) => {
                    const { column } = props;
                    return (
                        <div className="text-nowrap position-relative pe-4" style={{ minWidth: '140px' }}>
                            {column.label}
                            {!readOnly && (
                                <span className="position-absolute end-0 me-1">
                                    <button
                                        className="btn btn p-2 py-0 text-muted small"
                                        title="Promote Version"
                                        onClick={() => onPromote('version', v.id, column.label)}
                                    >
                                        <i className="fas fa-random fa-sm" />
                                    </button>
                                </span>
                            )}
                        </div>
                    );
                }
            }))
        );

        return cols;
    }, [gameVersions, environments, onPromote, readOnly, variants, configs, onExpand]);

    const tableData = useMemo(() => {
        const data = [];

        if (!environments || !gameVersions || !versions) return data;

        for (let env of environments) {
            const row = {
                environment: env.id,
                readOnly: readOnly,
                onPromote: () => onPromote('environment', env.id, env.name),
                _rowAttributes: { 'data-row-env': env.id }
            };

            for (let version of gameVersions) {
                const path = env.id + version.id + '/';

                row[version.id.replaceAll('.', '/')] = {
                    environment: env.id,
                    gameVersion: version.id,
                    path: path,
                    versions: versions[path]
                };
            }

            data.push(row);
        }

        return data;
    }, [environments, gameVersions, versions, onPromote, readOnly]);

    const editVersionSeparatorIndex = editingConfig.path
        ? editingConfig.path.slice(0, -1).lastIndexOf('/') + 1
        : 0;

    return (
        <>
            <SimpleTable columns={tableCols} data={tableData} className="table-bordered table-striped" />

            <StorageFileModal
                path={editingConfig.filePath}
                version={editingConfig.fileVersion}
                show={!!editingConfig.filePath}
                gameVersion={editingConfig?.path?.slice(editVersionSeparatorIndex, -1) || ''}
                environment={editingConfig?.path?.slice(0, editVersionSeparatorIndex) || ''}
                onHide={(e) => setEditingConfig({})}
                gameVersion={
                    editingConfig.path ? editingConfig.path.slice(editVersionSeparatorIndex, -1) : ''
                }
                environment={editingConfig.path ? editingConfig.path.slice(0, editVersionSeparatorIndex) : ''}
                readOnly={readOnly}
                onSelect={handleChangeVersion}
            />

            <PromoteModal
                promote={promote}
                onChange={onChange}
                gameVersions={gameVersions}
                environments={environments}
                data={versions}
                onHide={() => setPromote(null)}
            />
        </>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { setIAMPolicy } from 'services/iam';
import { gameContext } from 'services/game';
import { organizationContext } from 'services/organization';
import { Alert, useModalBlocker } from 'components';
import { utils } from '@gpg-web/utils';
import Select from 'react-select';
import { Modal, Spinner } from '@gpg-web/react';
import CreatableSelect from 'react-select/creatable';

const EditIAM = (props) => {
    const { setGame } = useContext(gameContext);
    const { members } = useContext(organizationContext);

    const [selectingRoleAt, setSelectingRoleAt] = useState(-1);

    const { onHide, game, show, inputData, roles } = props;

    const iam = game.iam;

    // const [adding, setAdding] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    // const [principal, setPrincipal] = useState('');
    const [principals, setPrincipals] = useState([]);
    const [customMembers, setCustomMembers] = useState(members);
    const [rolesList, setRolesList] = useState(['']);
    const [updateInitalData, onBeforeClose] = useModalBlocker({ principals: principals, rolesList: rolesList });

    const editing = !!inputData;

    const reset = () => {
        setError(null);
        setSaving(false);
        setPrincipals([]);
        // setPrincipal('');
        setRolesList(['']);
        setCustomMembers(members);
        updateInitalData({ principals: [], rolesList: [''] });

        if (show) {
            onHide();
        }
    };

    useEffect(() => {
        if (inputData) {
            // setPrincipal(inputData);
            setPrincipals([inputData]);
            if (!members.find(e => e.email === inputData)) {
                setCustomMembers([...members, {name: inputData, email: inputData}]);
            }

            const rolesList = iam.filter((e) => e.principal === inputData).map((e) => e.role);

            setRolesList(rolesList);

            updateInitalData({ principals: [inputData], rolesList: rolesList });
        }
    }, [inputData, iam, updateInitalData, members]);

    const save = () => {
        setError(null);

        for (let principal of principals) {
            if (principal.length < 4 || principal.indexOf('@') === -1)
                return setError('Principal field should contain a correct email address.');
        }

        if (rolesList.length === 0) return setError('Specify at least one role for selected principals.');

        if (rolesList.some((e) => e === '')) return setError('Make sure all fields are correct to continue');

        let _rolesList = utils.unique(rolesList);

        if (_rolesList.length !== rolesList.length)
            return setError('Make sure all fields are correct to continue. Do you have recurring roles?');

        /**
         *
         * Function to setup onlu single principal
         *
         * let newIam = iam.filter((e) => e.principal !== principal);
         *
         * for (let role of _rolesList) {
         *     newIam.push({
         *         role: role,
         *         principal: principal
         *     });
         * }
         *
         */

        let newIam = iam.filter((e) => principals.indexOf(e.principal) === -1);

        for (let principal of principals) {
            for (let role of _rolesList) {
                newIam.push({
                    role: role,
                    principal: principal
                });
            }
        }

        utils.confirm('Are you sure you want to save changes?', function (yes) {
            if (yes) {
                setSaving(true);

                setIAMPolicy(game.id, newIam)
                    .then(() => {
                        setSaving(false);

                        game.iam = newIam;

                        setGame({ ...game });

                        onHide();
                    })
                    .catch((err) => {
                        setSaving(false);
                        setError(err);
                    });
            }
        });
    };

    const canAddMoreRoles = !rolesList.some((e) => e === '');

    const principalOptions = useMemo(() => {
        if (!customMembers) return [];

        return customMembers.map((e) => {
            return {
                value: e.email,
                label: e.name
            };
        });
    }, [customMembers]);

    return (
        <Modal
            title={
                editing
                    ? 'Edit permissions'
                    : 'Add principals to "' + game.group.name + ' - ' + game.name + '"'
            }
            show={show}
            onHide={reset}
            onBeforeClose={onBeforeClose}
        >
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12 col-lg-8">
                        <div className="mb-3">
                            <div className="mb-2">{editing ? 'Principal' : 'New principals *'}</div>
                            <CreatableSelect
                                value={principalOptions.filter((e) => principals.indexOf(e.value) > -1)}
                                isMulti
                                name="principals-multiselect"
                                isClearable
                                isDisabled={editing}
                                placeholder="Select or create principal"
                                onChange={(arr, b) => {
                                    let shouldUpdateOptions = false;

                                    let newPrincipals = [];

                                    for (let element of arr) {
                                        let value = element.value;

                                        value = value.replace(/[^a-zA-Z0-9@_.-]/g, ' ');

                                        let splited = value.split(' ');

                                        for (let email of splited) {
                                            if (!customMembers.find((m) => m.email === email)) {
                                                customMembers.push({ email: email, name: email });
                                                shouldUpdateOptions = true;
                                            }

                                            if (newPrincipals.indexOf(email) === -1)
                                                newPrincipals.push(email);
                                        }
                                    }

                                    setPrincipals(newPrincipals);
                                    if (shouldUpdateOptions) setCustomMembers(customMembers.slice());
                                }}
                                options={principalOptions}
                                className="basic-multi-select"
                                classNamePrefix=""
                            />
                        </div>
                    </div>
                </div>

                {selectingRoleAt !== -1 && (
                    <div onClick={(e) => setSelectingRoleAt(-1)} className="backdrop" />
                )}

                {rolesList.map((role, index) => {
                    return (
                        <div key={'index-' + index} className="d-flex align-items-end mb-3 position-relative">
                            <div className="w-50">
                                <label htmlFor={'role-name-' + index}>Role</label>
                                <button
                                    id={'role-name-' + index}
                                    className="form-select text-start"
                                    onFocus={() => setSelectingRoleAt(index)}
                                >
                                    {role ? (
                                        roles[role].title
                                    ) : (
                                        <span className="text-muted">Select Role</span>
                                    )}
                                </button>
                            </div>
                            <button
                                onClick={() => {
                                    rolesList.splice(index, 1);
                                    setRolesList(rolesList.slice());
                                }}
                                className="btn btn-sm btn-light ms-5"
                            >
                                <i className="fa fa-sm fa-trash" />
                            </button>

                            {selectingRoleAt === index && (
                                <SelectRole
                                    roles={roles}
                                    game={game}
                                    onSelect={(selected) => {
                                        rolesList[index] = selected;
                                        setRolesList(rolesList.slice());
                                        setSelectingRoleAt(-1);
                                    }}
                                />
                            )}
                        </div>
                    );
                })}

                <button
                    onClick={(e) => {
                        rolesList.push('');
                        setRolesList(rolesList.slice());
                    }}
                    className="btn btn-sm btn-light text-primary"
                    disabled={!canAddMoreRoles}
                >
                    <i className="fa fa-plus me-1" />{' '}
                    {rolesList.length === 0 ? 'ADD ROLE' : 'ADD ANOTHER ROLE'}
                </button>

                {error && <Alert text={error} />}
            </div>

            <div className="modal-footer">
                <button disabled={saving} className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                </button>
                <button
                    disabled={saving || !canAddMoreRoles || rolesList.length === 0}
                    onClick={save}
                    className="btn btn-success"
                >
                    {saving ? <Spinner /> : <i className="fa fa-sm fa-check me-1" />} Save
                </button>
            </div>
        </Modal>
    );
};

const SelectRole = (props) => {
    const { onSelect, game, roles } = props;

    const options = useMemo(() => {
        const tmpObj = {};

        for (let key in roles) {
            const role = roles[key];

            tmpObj[role.groupId] = tmpObj[role.groupId] || {
                label: role.groupTitle,
                options: []
            };

            tmpObj[role.groupId].options.push({
                value: role.id,
                label: role.title
            });
        }

        return Object.keys(tmpObj).map((e) => {
            return {
                label: tmpObj[e].label,
                options: tmpObj[e].options
            };
        });
    }, [roles]);

    return (
        <div
            className="dropdown-menu dropdown-menu-end shadow show backdrop-top"
            style={{ width: '460px', top: '32px', left: '7px' }}
        >
            <div className="dropdown-header text-center mb-2 border-bottom">
                <strong>Role selecting</strong>
            </div>

            <div className="row px-3 mb-3">
                <div className="col-md-12 col-lg-9">
                    <label className="mb-2">Find a role</label>
                    <Select
                        name="role-selector"
                        placeholder="Find a role"
                        onChange={(e) => {
                            onSelect(e.value);

                            // handleChangeValue({
                            //     target: {
                            //         name: 'devices',
                            //         value: arr.map((e) => e.value)
                            //     }
                            // });
                        }}
                        options={options}
                        className="basic-select"
                        classNamePrefix="select"
                    />
                </div>
            </div>

            <div className="dropdown-divider" />

            <Link className="btn btn-link btn-sm ms-4" to={'/game/' + game.id + '/iam-admin/roles'}>
                MANAGE ROLES
            </Link>
        </div>
    );
};

export default EditIAM;

import Skeleton from 'react-loading-skeleton';
import { BoolButtonOption, TextOption, SelectOption, TooltipWrapper, useEnvironmental } from 'components';
import { getVersions } from 'services/settings';
import { getConfigs } from 'services/config';
import { ComponentsMap } from 'consts';
import { useQuery } from '@tanstack/react-query';
import { semver } from '@gpg-web/utils';

const popupComponent = ComponentsMap['popup'];

const DefaultValue = {
    isDisabled: true,
    popup: null,
    version: null,
    message: null
};

export const ContentSetup = ({ content, isLoading, isReady, gameId, onChange }) => {
    const { environments, selectedEnvironment, setSelectedEnvironment } = useEnvironmental();

    // const [selectedPlatform, setSelectedPlatform] = useStoredState(
    //     'forced_updated_default_selected_platform',
    //     'ios'
    // );

    const data = content || {};

    const environmentData = data[selectedEnvironment] || {};

    const onChangeData = (platform, name, val) => {
        const platformData = environmentData[platform] || {};

        onChange({
            ...data,
            [selectedEnvironment]: {
                ...environmentData,
                [platform]: { ...DefaultValue, ...platformData, [name]: val }
            }
        });
    };

    return (
        <div className="card position-static">
            <div className="card-header py-3 d-flex justify-content-between align-items-center">
                <h6 className="m-0 fw-bold text-primary">Content</h6>
            </div>
            <div className="card-body">
                {isReady && (
                    <>
                        <div className="mb-3 d-flex border-bottom pb-3">
                            <div className="d-flex align-items-center position-relative">
                                <select
                                    value={selectedEnvironment}
                                    className="form-select form-select-sm w-150px"
                                    onChange={(e) => setSelectedEnvironment(e.target.value)}
                                >
                                    {!!environments ? (
                                        environments.map((e) => (
                                            <option key={e.id} value={e.id}>
                                                {e.id}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>Loading..</option>
                                    )}
                                </select>
                                {/* <select
                                    value={selectedPlatform}
                                    className="form-select form-select-sm w-150px ms-2"
                                    onChange={(e) => setSelectedPlatform(e.target.value)}
                                >
                                    <option value={'android'}>Android</option>
                                    <option value={'ios'}>iOS</option>
                                </select> */}
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <div className="p-2 w-100">
                                <div className="row">
                                    <div className="col-md-12 col-lg-6 border-lg-end border-md-bottom border-lg-bottom-0 pe-4 my-2 py-2">
                                        <Options
                                            id={'android'}
                                            gameId={gameId}
                                            title="Android"
                                            value={environmentData['android'] || {}}
                                            onChange={onChangeData.bind(undefined, 'android')}
                                        />
                                    </div>
                                    <div className="col-md-12 col-lg-6 ps-4 my-2 py-2">
                                        <Options
                                            title="iOS"
                                            id={'ios'}
                                            gameId={gameId}
                                            value={environmentData['ios'] || {}}
                                            onChange={onChangeData.bind(undefined, 'ios')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {isLoading && (
                    <>
                        <Skeleton className="my-2" width="60%" height={30} />
                        <Skeleton className="my-2" width="30%" height={60} />
                        <Skeleton className="my-2" width="100%" height={50} count={2} />
                        <Skeleton className="my-2" width="90%" height={90} />
                    </>
                )}
            </div>
        </div>
    );
};

const Options = ({ gameId, onChange, value, id, title }) => {
    const { data: versions } = useQuery({
        queryKey: ['options', 'versions', gameId],
        queryFn: () => getVersions(gameId),
        enabled: !!gameId,
        select: (versions) => versions.map((v) => ({ value: v.id, label: v.id }))
    });
    const { data: popups } = useQuery({
        queryKey: [popupComponent.url, gameId],
        queryFn: () => getConfigs(gameId, popupComponent.url),
        enabled: !!gameId,
        select: (popups) => [
            { value: false, label: 'No popup' },
            ...popups.map((p) => ({ value: p.id, label: p.id }))
        ]
    });

    const isDisabled = typeof value['isDisabled'] !== 'undefined' ? value['isDisabled'] : true;

    let warning = '';
    let error = '';
    let info = '';

    if (!isDisabled && value['version']) {
        if (versions) {
            const lowerVersionIndex = versions.findIndex((v) => semver.gt(value['version'], v.value));

            if (lowerVersionIndex > -1) {
                const forceUpdateVersions = versions.slice(lowerVersionIndex);

                info = (
                    <TooltipWrapper
                        layout="inline-end"
                        content={
                            <span className="text-start">
                                Forced update is enabled for the following versions:{' '}
                                <ul>
                                    {forceUpdateVersions.slice(0, 5).map((v) => (
                                        <li className="text-start small" key={v.value}>
                                            {v.value}
                                        </li>
                                    ))}
                                    {forceUpdateVersions.length > 5 && (
                                        <span className="text-start small">...</span>
                                    )}
                                </ul>
                            </span>
                        }
                        icon="fas fa-info-circle ms-2"
                    >
                        Forced update is enabled
                    </TooltipWrapper>
                );
            } else {
                warning = (
                    <TooltipWrapper
                        layout="inline-end"
                        content={'Game versions lower than ' + value['version'] + ' not found'}
                        icon="fas fa-info-circle ms-2"
                    >
                        Forced update is enabled
                    </TooltipWrapper>
                );
            }
        } else {
            info = (
                <>
                    Forced update is enabled
                    <i className="fas fa-info-circle fa-sm ms-2" />
                </>
            );
        }
    } else if (!isDisabled && !value['version']) {
        error = (
            <>
                Forced update version is not selected
                <i className="fas fa-info-circle fa-sm ms-2" />
            </>
        );
    } else if (isDisabled) {
        warning = (
            <>
                Forced update is disabled
                <i className="fas fa-info-circle fa-sm ms-2" />
            </>
        );
    }

    const isVersionSetup = !!value['version'];

    return (
        <>
            <div className="mb-4 d-flex align-items-center">
                <div className="small me-2">{isDisabled ? '🔴' : '🟢'}</div>
                {title}
                <div className="ms-3">
                    <BoolButtonOption
                        tooltip={
                            !isVersionSetup && 'Please select version number before turning on forced update'
                        }
                        className={'btn-sm' + (isVersionSetup ? '' : ' disabled')}
                        id={id}
                        title={isDisabled ? 'Turn On' : 'Turn Off'}
                        name="isDisabled"
                        onChange={(name, val) => onChange(name, !val)}
                        value={!isDisabled}
                    />
                </div>
                {!!info && (
                    <div className="ms-auto small text-muted bg-primary-subtle px-3 py-1 rounded-2 fst-italic">
                        {info}
                    </div>
                )}
                {!!warning && (
                    <div className="ms-auto small text-muted bg-warning-subtle px-3 py-1 rounded-2 fst-italic">
                        {warning}
                    </div>
                )}
                {!!error && (
                    <div className="ms-auto small text-danger bg-danger-subtle px-3 py-1 rounded-2 fst-italic">
                        {error}
                        <i className="fas fa-info-circle fa-sm ms-2" />
                    </div>
                )}
            </div>

            <div className="mb-4">
                <SelectOption
                    tooltip="Users with game versions below selected will be forced to update to the latest version of the game"
                    options={versions}
                    title="Version Number"
                    name="version"
                    placeholder="Select version"
                    onChange={onChange}
                    value={value['version']}
                />
            </div>
            <div className="mb-4">
                <SelectOption
                    tooltip="This popup will be shown when user opens the game after updating to the latest version"
                    options={popups}
                    title="Welcome back popup"
                    placeholder="Select popup"
                    name="popup"
                    onChange={(name, val) => onChange(name, val === 'false' ? null : val)}
                    value={value['popup'] || 'false'}
                />
            </div>
            <div className="mb-4">
                <TextOption
                    title={'Message'}
                    name={'message'}
                    onChange={onChange}
                    value={value['message'] || ''}
                />
            </div>
        </>
    );
};

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PopoverWrapper } from '../tooltips';
import { useGame } from '../hooks';
import { getComponentsStatuses } from 'services/automation';
import { utils, date, ms } from '@gpg-web/utils';
import socket from 'services/socket';

let STATUSES_CACHE = {};

const getCachedComponentsStatuses = (gameId) => {
    if (STATUSES_CACHE[gameId]) return STATUSES_CACHE[gameId];

    STATUSES_CACHE[gameId] = getComponentsStatuses(gameId);

    return STATUSES_CACHE[gameId];
};

setInterval(() => {
    STATUSES_CACHE = {};
}, ms('1m'));

const PopoverContent = (props) => {
    const proc = props.proc;

    return (
        <div className="card shadow-sm border-0 small px-2">
            <div className="p-2">
                <div>
                    Another automation process is running already by {proc.user} at {date(proc.createdAt)}
                    <br />
                    Please wait until current process is done
                </div>
                <div className="d-flex mt-1  justify-content-end">
                    <Link
                        to={'/game/' + props.gameId + '/automation/processes/' + proc.process}
                        className="btn btn-link btn-sm"
                    >
                        See more details
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const ProcessBlockedWrapper = (props) => {
    const game = useGame();
    const [activeProcesses, setActiveProcesses] = useState();

    const gameId = game.id;

    const component = props.component;

    useEffect(() => {
        if (!gameId || !component) return;

        function socketProcessUpdate(data) {
            if (data.game === gameId && data.components && data.components.indexOf(component) > -1) {
                if (data.finished) {
                    setActiveProcesses({});
                } else {
                    setActiveProcesses({
                        [component]: {
                            user: data.user,
                            process: data.id,
                            createdAt: data.createdAt
                        }
                    });
                }
            }
        }

        getCachedComponentsStatuses(gameId)
            .then((list) => {
                setActiveProcesses(list);

                socket.on('automation-process', socketProcessUpdate);
            })
            .catch(utils.hintError);

        return () => {
            socket.off('automation-process', socketProcessUpdate);
        };
    }, [gameId, component]);

    if (!activeProcesses) {
        return <div className="disabled">{props.children}</div>;
    }

    if (component && activeProcesses[component]) {
        return (
            <PopoverWrapper
                {...props}
                className="disabled"
                content={<PopoverContent gameId={gameId} proc={activeProcesses[component]} />}
            ></PopoverWrapper>
        );
    }

    return props.children;
};

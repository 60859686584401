/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import axios from 'axios';

export const getAppleIAP = (gameId, packageName, productId) => {
    return axios.get('/api/iap/' + gameId + '/apple', {
        params: { packageName, productId }
    });
};

export const getGoogleIAP = (gameId, packageName, productId) => {
    return axios.get('/api/iap/' + gameId + '/google', {
        params: { packageName, productId }
    });
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import axios from 'axios';

export const searchAll = (params) => {
    return axios.get('/api/search/all', { params: params });
};

export const loadNext = (token) => {
    return axios.get('/api/search/next', { params: {token} });
}
/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';
// import Select from 'react-select'

const SetupTags = (props) => {
    const { setList } = props;

    const list = props.list || [];

    const editingIndex = list.length - 1;

    const addKeyOption = () => {
        list.push({
            name: '',
            path: '',
            type: 'string' // string, number, boolean
        });
        setList(list.slice());
    };

    const removeKeyOption = (e) => {
        const tagIndex = e.target.getAttribute('data-index');

        list.splice(tagIndex, 1);

        setList(list.slice());
    };

    const updateKeyOption = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let index = target.getAttribute('data-index');

        if (index === null) return;

        list[index][name] = value;

        setList(list.slice());
    };

    let canAddMore = true;

    if (editingIndex > -1) {
        if (!(list[editingIndex].name && list[editingIndex].path)) canAddMore = false;
    }

    return (
        <div className={utils.className('card', props.className)}>
            <div className="card-header py-3">
                <h6 className="m-0 fw-bold text-primary">Key Options</h6>
            </div>

            <div className="card-body">
                {list.length !== 0 && (
                    <div className="row">
                        <div className="mb-2 col-md-7 col-sm-12 d-flex">
                            <span className="col-4 ps-4">Name</span>
                            <span className="col-4 ps-2">Path</span>
                            <span className="col-4">Type</span>
                        </div>

                        {list.map((e, index) => {
                            return (
                                <div key={index} className="mb-2 col-md-7 col-sm-12">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name *"
                                            name="name"
                                            value={e.name}
                                            data-index={index}
                                            onChange={updateKeyOption}
                                        />
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="path.to.option *"
                                            name="path"
                                            value={e.path}
                                            data-index={index}
                                            onChange={updateKeyOption}
                                        />
                                        <select
                                            name="type"
                                            value={e.type}
                                            data-index={index}
                                            onChange={updateKeyOption}
                                            className="form-select"
                                        >
                                            <option value="string">String</option>
                                            <option value="number">Number</option>
                                            <option value="boolean">Boolean</option>
                                        </select>
                                        <button
                                            onClick={removeKeyOption}
                                            data-index={index}
                                            className="btn btn-outline-danger"
                                            type="button"
                                        >
                                            <i className="fa fa-times fa-sm pe-none" />
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                {list.length === 0 && (
                    <div className="text-muted">
                        <button onClick={addKeyOption} className="btn btn-sm btn-link">
                            <i className="fa fa-sm fa-plus me-1" /> Add first Key Option
                        </button>
                    </div>
                )}

                {list.length !== 0 && (
                    <button
                        disabled={!canAddMore}
                        onClick={addKeyOption}
                        className="btn btn-sm btn-success mt-3"
                    >
                        <i className="fa fa-sm fa-plus me-2" />
                        Add another option
                    </button>
                )}
            </div>
        </div>
    );
};

export default SetupTags;

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getConfigs } from 'services/config';
import { useGame, Product } from 'components';
import { utils } from '@gpg-web/utils';
import Select from 'react-select';
import { ComponentsMap } from 'consts';
import { useQuery } from '@tanstack/react-query';
import DefaultProductIcon from 'img/product-default-icon-min.png';
import classnames from 'classnames';

const productComponent = ComponentsMap['product'];

const StoreView = (props) => {
    const {
        value,
        editing,
        // fileName,
        onChange
        //   onError
    } = props;

    const game = useGame();
    const gameId = game.id;

    const {
        data: products,
        isFetching,
        error: configsError
    } = useQuery({
        queryKey: [productComponent.id, gameId],
        queryFn: () => getConfigs(gameId, productComponent.url),
        enabled: !!gameId
    });

    /**
     * Validation start
     */
    useEffect(() => {
        if (!value) onChange([]);
        else if (!Array.isArray(value)) onChange([{ name: '', products: [] }]);
        else if (value[0] && typeof value[0] !== 'object') onChange([]);
    }, [value, onChange]);
    /**
     * Validation end
     */

    let sections = value;
    if (!Array.isArray(sections)) sections = [];

    const usedProductsIds = sections.map((e) => e.products.map((e) => e.id).join(',')).join(',');

    const options = useMemo(() => {
        const result = [];

        if (!products) return result;

        for (let e of products) {
            if (usedProductsIds.includes(e.id)) continue;
            result.push({
                value: e.id,
                label: (
                    <>
                        <img
                            alt=""
                            width="25"
                            height="25"
                            className="me-2 rounded-circle"
                            src={e.icon ? utils.gameStoragePath(gameId, e.icon) : DefaultProductIcon}
                        />
                        {e.isSubscription ? (
                            <span className="text-primary">
                                
                                {e.id}
                                <i className="fas fa-tags fa-xs ms-1" />
                            </span>
                        ) : (
                            e.id
                        )}
                        {e.name && <span className="fw-light"> - {e.name}</span>}
                    </>
                )
            });
        }

        return result;
    }, [products, usedProductsIds, gameId]);

    // if (value === '::disabled') return <DisabledConfigLabel />;

    function addSection() {
        sections.push({ name: '', products: [] });
        refresh();
    }

    function addSectionShift() {
        sections.unshift({ name: '', products: [] });
        refresh();
    }

    function swapElements(arr, index, index2) {
        if (!(index2 > -1 && index2 < arr.length)) return;
        const tmp = arr[index2];
        arr[index2] = arr[index];
        arr[index] = tmp;

        refresh();
    }

    function moveSectionUp(e) {
        const index = Number(e.target.getAttribute('data-index'));
        swapElements(sections, index, index - 1);
    }

    function moveSectionDown(e) {
        const index = Number(e.target.getAttribute('data-index'));
        swapElements(sections, index, index + 1);
    }

    function removeSection(e) {
        const index = Number(e.target.getAttribute('data-index'));

        sections.splice(index, 1);
        refresh();
    }

    function moveProductNext(e) {
        const sectionIndex = Number(e.target.getAttribute('section-index'));
        const productIndex = Number(e.target.getAttribute('product-index'));
        swapElements(sections[sectionIndex].products, productIndex, productIndex + 1);
    }

    function moveProductBack(e) {
        const sectionIndex = Number(e.target.getAttribute('section-index'));
        const productIndex = Number(e.target.getAttribute('product-index'));
        swapElements(sections[sectionIndex].products, productIndex, productIndex - 1);
    }

    function removeProduct(e) {
        const sectionIndex = Number(e.target.getAttribute('section-index'));
        const productIndex = Number(e.target.getAttribute('product-index'));

        sections[sectionIndex].products.splice(productIndex, 1);
        refresh();
    }

    function addProduct(index, productId) {
        sections[index].products.push({ id: productId });
        refresh();
    }

    function refresh() {
        onChange(sections.slice());
    }

    return (
        <div className={utils.className('mt-4 px-3', !props.fluid && 'storage-view-box')}>
            {configsError && <div className="alert alert-danger">{configsError}</div>}
            {sections.length > 0 && editing && (
                <div className="text-center my-3">
                    <button className="btn btn-light rounded-pill px-3 btn-sm" onClick={addSectionShift}>
                        <i className="fas fa-plus me-2" />
                        Add section
                    </button>
                </div>
            )}

            {sections.map((section, index) => {
                return (
                    <div key={index} className="card mb-4 shadow">
                        <div className="card-header d-flex align-items-center bg-secondary-subtle text-secondary-emphasis">
                            <div className="fw-bold me-3">#{index + 1}</div>
                            {editing ? (
                                <input
                                    value={section.name}
                                    placeholder="Section name"
                                    onChange={(e) => {
                                        section.name = e.target.value;
                                        refresh();
                                    }}
                                    className="form-control form-control-sm w-300px"
                                />
                            ) : (
                                <div className="fw-bold">{section.name}</div>
                            )}

                            {editing && (
                                <div className="dropdown no-arrow ms-auto">
                                    <button
                                        className="btn btn-sm text-gray-600 rounded-circle dropdown-toggle border-0"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-ellipsis-h" />
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <button
                                                data-index={index}
                                                onClick={moveSectionUp}
                                                className="dropdown-item"
                                                type="button"
                                            >
                                                Move up
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                data-index={index}
                                                onClick={moveSectionDown}
                                                className="dropdown-item"
                                                type="button"
                                            >
                                                Move down
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                data-index={index}
                                                onClick={removeSection}
                                                className="dropdown-item"
                                                type="button"
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {section.products.map((product, productIndex) => (
                                    <div key={productIndex} className="col-lg-6 col-md-12">
                                        <Product
                                            header={false}
                                            gameId={gameId}
                                            id={product.id}
                                            className="mb-3"
                                            actions={
                                                editing && (
                                                    <>
                                                        <li>
                                                            <button
                                                                section-index={index}
                                                                product-index={productIndex}
                                                                onClick={moveProductNext}
                                                                className="dropdown-item"
                                                                type="button"
                                                            >
                                                                Move next
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                section-index={index}
                                                                product-index={productIndex}
                                                                onClick={moveProductBack}
                                                                className="dropdown-item"
                                                                type="button"
                                                            >
                                                                Move back
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={
                                                                    '/game/' +
                                                                    gameId +
                                                                    '/config/' +
                                                                    productComponent.url +
                                                                    '/view/' +
                                                                    product.id
                                                                }
                                                                className="dropdown-item"
                                                            >
                                                                Edit
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <button
                                                                section-index={index}
                                                                product-index={productIndex}
                                                                onClick={removeProduct}
                                                                className="dropdown-item"
                                                                type="button"
                                                            >
                                                                Remove
                                                            </button>
                                                        </li>
                                                    </>
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                            </div>

                            {editing && (
                                <Select
                                    value={null}
                                    name="product-select"
                                    placeholder={
                                        isFetching
                                            ? 'Loading...'
                                            : editing
                                            ? 'Select product'
                                            : 'No any product has selected'
                                    }
                                    isLoading={isFetching}
                                    onChange={(e) => {
                                        addProduct(index, e.value);
                                    }}
                                    options={options}
                                    className="react-select mt-3"
                                    classNamePrefix="select"
                                />
                            )}
                        </div>
                    </div>
                );
            })}
            {editing && (
                <div className="text-center my-3">
                    <button className="btn btn-light rounded-pill px-3 btn-sm" onClick={addSection}>
                        <i className="fas fa-plus me-2" />
                        Add section
                    </button>
                </div>
            )}
        </div>
    );
};

export { StoreView };

import { useEffect } from 'react';

import { useState } from 'react';
import Select, { components as selectComponents, defaultTheme } from 'react-select';
const { colors } = defaultTheme;

const selectStyles = {
    control: (provided) => ({
        ...provided,
        minWidth: 240,
        margin: 8
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
};

const Menu = (props) => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 4,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                marginTop: 8,
                position: 'absolute',
                zIndex: 2,
                minWidth: '500px'
            }}
            {...props}
        />
    );
};
const Blanket = (props) => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1
        }}
        {...props}
    />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);

const DropdownIndicator = ({ clearValue, setValue, options }) => {
    const handleSelectAll = () => {
        setValue(options);
    };
    const handleClearAll = () => {
        clearValue();
    };

    return (
        <>
            <div onClick={handleSelectAll} className="btn btn-sm btn-light mx-2">
                <i className="fas fa-check me-1" />
                <span>Select All</span>
            </div>
            <div onClick={handleClearAll} className="btn btn-sm btn-light">
                <i className="fas fa-times me-1" />
                <span>Clear All</span>
            </div>
        </>
    );
};

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = 'transparent';
    if (isFocused) bg = '#eee';
    if (isActive) bg = '#B2D4FF';

    const style = {
        alignItems: 'center',
        backgroundColor: bg,
        color: 'inherit',
        display: 'flex '
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <selectComponents.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" className="me-2" readOnly={true} checked={isSelected} />
            {children}
        </selectComponents.Option>
    );
};

const Control = ({ children, ...rest }) => {
    return (
        <selectComponents.Control {...rest}>
            <div style={{ color: colors.neutral20 }} className="ms-2">
                <i className="fas fa-search" />
            </div>
            {children}
        </selectComponents.Control>
    );
};

export const InputSelectCheck = ({
    name,
    value,
    onChange,
    options,
    ready,
    asyncLoad,
    asyncInit,
    itemName
}) => {
    const optionValue = value || [];
    const [isOpen, setIsOpen] = useState(false);

    const isLoading = !ready && (asyncLoad || asyncInit);

    const isAllMultiSelect = value === 'all';

    useEffect(() => {
        if (!isLoading && isAllMultiSelect) {
            const newOptions = options.filter((opt) => opt.value !== 'all').map((opt) => opt.value);
            onChange(newOptions);
        }
    }, [isLoading, isAllMultiSelect, options, onChange]);

    let btnText = 'No ' + itemName;
    if (optionValue.length === options.length) {
        btnText = 'All ' + itemName;
    } else if (optionValue.length === 1) {
        const selectedOpt = options.find((opt) => opt.value === optionValue[0]);
        if (selectedOpt) btnText = selectedOpt.label;
        else btnText = optionValue[0];
    } else if (optionValue.length) {
        btnText = optionValue.length + ' ' + itemName;
    }

    return (
        <div className="col-md-12 col-sm-12 mb-3">
            <div className="fw-bold">{name}</div>
            <div className="mt-2">
                <Dropdown
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    target={
                        <div
                            className={'btn btn-light border shadow-sm ' + (isOpen ? 'active' : '')}
                            onClick={() => setIsOpen((prev) => !prev)}
                        >
                            {btnText}
                            <i className="fas fa-chevron-down ms-4" />
                        </div>
                    }
                >
                    <Select
                        autoFocus
                        backspaceRemovesValue={false}
                        components={{
                            DropdownIndicator,
                            IndicatorSeparator: null,
                            // MenuList: MenuList,
                            Option: InputOption,
                            Control: Control
                        }}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        isClearable={false}
                        closeMenuOnSelect={false}
                        menuIsOpen
                        isLoading={isLoading}
                        isMulti={true}
                        options={options}
                        value={options.filter((opt) => optionValue.indexOf(opt.value) > -1)}
                        // defaultValue={options.find(e => e.value === selected)}
                        onChange={(e) => {
                            onChange(e.map((e) => e.value));
                            // setIsOpen(false);
                        }}
                        placeholder="Search..."
                        styles={selectStyles}
                        tabSelectsValue={false}
                        className="input-select-check"
                        classNamePrefix="select"
                    />
                </Dropdown>

                {/* <Select
                    isLoading={isLoading}
                    isMulti={true}
                    options={options}
                    isClearable={false}
                    value={options.filter((opt) => optionValue.indexOf(opt.value) > -1)}
                    // defaultValue={options.find(e => e.value === selected)}
                    onChange={(e) => {
                        onChange(e.map((e) => e.value));
                    }}
                    placeholder=""
                    controlShouldRenderValue={false}
                    itemName={itemName || 'Items'}
                    className="input-select-check"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    // openMenuOnClick={true}
                    hideSelectedOptions={false}
                    components={{
                        Option: InputOption,
                        MultiValue: () => null,
                        Input: Input,
                        MenuList: MenuList
                    }}
                /> */}
            </div>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { userContext } from 'services/user';
// import { getGameGroups, getGames } from 'services/game';
import { organizationContext } from 'services/organization';
import AddNewGameGroup from './AddNewGameGroup';
import AddNewGame from './AddNewGame';
import Skeleton from 'react-loading-skeleton';
// import { utils } from '@gpg-web/utils';
import gameIcon from '../../img/game-icon.png';
import logo from '../../img/logo.svg';
import { useGame } from 'components';

export const GamesPage = (props) => {
    const { email, name } = useContext(userContext);
    const { games, setGames, gameGroups, setGameGroups } = useContext(organizationContext);
    // const [gameGroups, setGameGroups] = useState(null);
    // const [games, setGames] = useState(null);

    const game = useGame();
    let gameId = game.id;

    // useEffect(() => {
    //     getGameGroups()
    //         .then((groups) => {
    //             setGameGroups(groups);

    //             getGames()
    //                 .then((list) => setGames(list.sort((a, b) => (a.name > b.name ? 1 : -1))))
    //                 .catch(utils.hintError);
    //         })
    //         .catch(utils.hintError);
    // }, []);

    // const services = _services.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))

    return (
        <>
            <div className="container-lg">
                <div className="p-4 bg-gray-200 d-flex">
                    <img alt="" src={logo} width="67" />
                    <div className="ms-4">
                        <h3 className="h3">Welcome</h3>
                        {gameId ? (
                            <div className="fw-light">
                                You're working in{' '}
                                <Link to={'/game/' + gameId}>
                                    {game.group.name} - {game.name}
                                </Link>
                            </div>
                        ) : (
                            <div className="fw-light"> {name || email} </div>
                        )}
                    </div>
                </div>

                <div className="mt-4">
                    <div className="d-flex align-items-center mb-3">
                        <h5 className="h5 fw-bold me-0 me-auto">Our Projects</h5>
                        <AddNewGameGroup onAdd={() => setGameGroups([...gameGroups])} />
                        <AddNewGame onAdd={(game) => setGames([...games, game])} groups={gameGroups} />
                    </div>

                    {!!games && (
                        <div className="row">
                            {gameGroups.map((e) => {
                                const _games = games.filter((g) => g.group === e.id);

                                return (
                                    <div key={e.id} className="col-sm-12 col-md-6 mb-3">
                                        <div className="d-flex border-top pt-3 align-items-center">
                                            <img
                                                alt="Game logo"
                                                className="rounded-2"
                                                width={30}
                                                src={e.picture || gameIcon}
                                            />
                                            <span className="ms-2">{e.name}</span>
                                        </div>

                                        <div>
                                            {_games.map((g) => {
                                                return (
                                                    <div className="mt-2">
                                                        <Link
                                                            key={g.id}
                                                            to={'/game/' + g.id}
                                                            className="my-2 text-decoration-none"
                                                        >
                                                            {g.name}
                                                        </Link>
                                                        {g.isProduction && (
                                                            <span className="ms-2 py-0 lh-sm badge text-danger bg-danger-subtle">
                                                                Production
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {!games && (
                        <div className="row">
                            <div className="col-sm-12 col-md-6 mb-3">
                                <Skeleton height={30} count={3} />
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <Skeleton height={30} count={3} />
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <Skeleton height={30} count={3} />
                            </div>
                            <div className="col-sm-12 col-md-6 mb-3">
                                <Skeleton height={30} count={3} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

import _package from '../package.json';

var styles = [
    'font-size: 13px',
    'border-radius: 20px',
    'color: white',
    'display: block',
    'text-shadow: 0px 1px 5px rgba(0, 0, 0, 1)',
    'line-height: 29px',
    'text-align: center',
    'font-weight: bold'
].join(';');

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(
        '%c  Livefire v' + _package.version + ' [development]  ',
        'background-color: #e8ab21;' + styles
    );
} else {
    console.log('%c  Livefire v' + _package.version + ' [live]  ', 'background-color: #1bcd21;' + styles);
}

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2023 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createConfig } from 'services/config';
import {
    Input,
    TextArea,
    ComponentHeader,
    ProcessBlockedWrapper,
    useGame,
    usePageBlocker
} from 'components';
import { useComponent } from './hooks';
import { utils } from '@gpg-web/utils';
import { Navigation } from './Navigation';
import { ContentSetup } from './components/Content';
import { Triggers } from './components/Triggers';

export const ConfigCreate = (props) => {
    const game = useGame();
    const component = useComponent();
    const [data, setData] = useState({ id: '', content: { versions: {} } });
    const [errors, setErrors] = useState([]);
    const gameId = game.id;
    const history = useNavigate();

    const [, unBlockRedirect, ,] = usePageBlocker(data);

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    };

    async function handleSave() {
        try {
            if (errors.some((e) => !!e)) return utils.hintError('Please, fix the errors below');

            let id = data.id.trim();

            if (!id) return utils.hintError('ID field is required');

            if (!utils.isValidId(id)) {
                return utils.hintError('ID is not valid. a-z, A-Z, 0-9 and underscore are only allowed');
            }

            if (!(data.content && data.content.path)) {
                return utils.hintError('Content path is required. Please select a config file');
            }

            data.id = id;
            utils.popup('saving');
            const result = await createConfig(gameId, component.url, data);
            utils.popup('hide');

            unBlockRedirect();
            history('/game/' + gameId + '/config/' + component.url + '/view/' + result.id);
        } catch (err) {
            utils.hintError(err);
        }
    }

    return (
        <>
            <Navigation tab={component.id} gameId={gameId} />

            <div className="container-lg">
                <ComponentHeader
                    gameId={gameId}
                    url={'config/' + component.url}
                    id={component.id}
                    breadcrumb={[{ name: 'New Config' }]}
                >
                    <ProcessBlockedWrapper component={component.id}>
                        <button onClick={handleSave} className="btn btn-success rounded-pill px-4 shadow">
                            Create
                        </button>
                    </ProcessBlockedWrapper>
                </ComponentHeader>

                <div className="mt-4">
                    <div className="card shadow mb-4">
                        <div className="row card-body">
                            <div className="col-lg-6 col-md-12">
                                <Input
                                    required
                                    name="id"
                                    title="ID (key)"
                                    tooltip={
                                        <>
                                            REQUIRED
                                            <br />
                                            This is the key you'll pass to the Remote Config component. <br />
                                            This key should be unique.
                                            <br />
                                            For example: "Feature1Config"
                                        </>
                                    }
                                    value={data.id}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <Input
                                    name="name"
                                    title="Display Name"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                                <TextArea
                                    name="description"
                                    rows={4}
                                    title="Description"
                                    value={data.description}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    {component.triggers &&
                        component.triggers.map((e, index) => {
                            let TriggerComponent = Triggers[e];

                            if (!TriggerComponent) return undefined;

                            return (
                                <TriggerComponent
                                    key={e}
                                    onError={(err) => {
                                        errors[index] = err;
                                        setErrors(errors.slice());
                                    }}
                                    data={data}
                                    setData={setData}
                                    className="shadow mt-4"
                                />
                            );
                        })}

                    <ContentSetup id={data.id} className="shadow mt-4" data={data} onChange={setData} />
                </div>
            </div>
        </>
    );
};

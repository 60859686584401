import React from 'react';

const ButtonFileInput = (props) => {
    const { onAccept } = props;

    function onChange(e) {
        const target = e.target;

        onAccept(target.files);
    }

    return (
        <button
            disabled={props.disabled}
            className={
                'btn btn-primary btn-sm shadow position-relative o-hidden' +
                (props.className ? ' ' + props.className : '')
            }
        >
            <i className={(props.icon || 'fa fa-upload') + ' fa-sm me-1'} /> {props.title || 'Upload File'}
            <input
                onClick={(e) => (e.target.value = null)}
                onChange={onChange}
                multiple={props.multiple}
                type="file"
                accept={props.accept || ''}
                className="w-100 h-100 top-0 start-0 position-absolute opacity-0"
            />
        </button>
    );
};

export { ButtonFileInput };

import { utils } from '@gpg-web/utils';
import { IAPIdOption } from '../options/IAPIdOption';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@gpg-web/react';
import { getAppleIAP, getGoogleIAP } from 'services/iap';
import { useGame } from '../hooks';
import { TooltipWrapper } from '../tooltips';

/**
 * value: { type, google, apple }
 */
export const IAPEntityEditUsage = ({
    gameId,
    value,
    fetchPrices,
    onChange,
    onRemove,
    className,
    canRemove = true,
    readOnly
}) => {
    return (
        <div>
            <div
                className={utils.className(
                    'd-flex align-items-center rounded-4 px-3 pt-2 pb-3 border-3 border-bottom bg-gray-100',
                    className
                )}
            >
                <div>
                    <div className="d-flex align-items-center mb-1">
                        <i className="fab fa-google-play fa-sm me-1" />
                        <div className="ms-2 small fw-bold">Google Store ID</div>
                    </div>
                    <IAPIdOption
                        store="google"
                        name="google"
                        fetchPrices={fetchPrices}
                        value={value.google}
                        onChange={onChange}
                        readOnly={readOnly}
                    />
                </div>

                <div>
                    <div className="d-flex align-items-center mb-1">
                        <i className="fab fa-app-store-ios fa-sm me-1" />
                        <div className="ms-2 small fw-bold">Apple Store ID</div>
                    </div>
                    <IAPIdOption
                        store="apple"
                        name="apple"
                        fetchPrices={fetchPrices}
                        value={value.apple}
                        onChange={onChange}
                        readOnly={readOnly}
                    />
                </div>

                {!!canRemove && !readOnly && (
                    <button
                        onClick={() => onRemove(value.id)}
                        className="btn btn-sm border-1 rounded-circle ms-auto text-danger"
                    >
                        <i className="fa fa-times pe-none" />
                    </button>
                )}
            </div>
        </div>
    );
};

export const IAPEntityUsage = ({ value, className, fetchPrices }) => {
    const game = useGame();
    const gameId = game.id;
    const packageNameApple = game['apple'] && game['apple'].packageName;
    const packageNameGoogle = game['google'] && game['google'].packageName;

    const {
        data: appleIAP,
        error: errorApple,
        isFetching: isFetchingApple
    } = useQuery({
        queryKey: ['iap:apple', gameId, packageNameApple, value.apple],
        retryOnMount: false,
        queryFn: () => getAppleIAP(gameId, packageNameApple, value.apple),
        enabled: !!(gameId && packageNameApple && value.apple && fetchPrices)
    });

    const {
        data: googleIAP,
        error: errorGoogle,
        isFetching: isFetchingGoogle
    } = useQuery({
        queryKey: ['iap:google', gameId, packageNameGoogle, value.google],
        retryOnMount: false,
        queryFn: () => getGoogleIAP(gameId, packageNameGoogle, value.google),
        enabled: !!(gameId && packageNameGoogle && value.google && fetchPrices)
    });

    // if (errorApple || errorGoogle) {
    //     return (
    //         <div className="alert alert-danger mb-0">
    //             <i className="fas fa-exclamation-circle fa-sm me-2" />
    //             {errorApple || errorGoogle}
    //         </div>
    //     );
    // }

    return (
        <div title="Real store prices specififed">
            <div
                className={utils.className(
                    'd-flex align-items-center rounded-pill p-1 pe-2 bg-gray-100',
                    className
                )}
            >
                <i className="fab fa-app-store-ios me-2 text-dark-emphasis" />
                <div className="small">Real prices</div>
                <div className="ms-auto small text-primary text-nowrap">
                    {!(packageNameApple || packageNameGoogle) && <>{value.apple || value.google}</>}
                    {(appleIAP || googleIAP) && (
                        <TooltipWrapper
                            icon={false}
                            content={
                                (appleIAP && googleIAP && appleIAP.price === googleIAP.price
                                    ? 'Same price for both stores: '
                                    : '') +
                                value.google +
                                ' ' +
                                value.apple
                            }
                        >
                            <span className="ms-2">
                                {appleIAP && googleIAP ? (
                                    appleIAP.price === googleIAP.price ? (
                                        appleIAP.price
                                    ) : (
                                        <>
                                            <i className="fab fa-google-play fa-xs me-1" />
                                            {googleIAP.price} |
                                            <i className="fab fa-app-store-ios fa-xs ms-2 me-1" />
                                            {appleIAP.price}
                                        </>
                                    )
                                ) : (
                                    (appleIAP || googleIAP).price
                                )}
                            </span>
                        </TooltipWrapper>
                    )}
                    {!(value.google && value.apple) && (
                        <TooltipWrapper
                            icon={false}
                            content={
                                !(value.google || value.apple)
                                    ? "You didn't specified any of store ID"
                                    : !value.google
                                    ? "You didn't specified Google Store ID"
                                    : "You didn't specified Apple Store ID"
                            }
                        >
                            <span className="text-warning-emphasis ms-2">
                                {!(value.google || value.apple) && 'Nothing specified '}
                                <i className="fas fa-exclamation-triangle ms-1 fa-xs" />
                            </span>
                        </TooltipWrapper>
                    )}
                    {(errorApple || errorGoogle) && (
                        <TooltipWrapper
                            icon="false"
                            tooltipClassName="tooltip-danger"
                            content={
                                <>
                                    <div className="my-1">{errorApple && 'Apple: ' + errorApple}</div>
                                    <hr />
                                    <div className="my-1">{errorGoogle && 'Google: ' + errorGoogle}</div>
                                </>
                            }
                        >
                            <i className="fas fa-exclamation-circle text-danger ms-2" />
                        </TooltipWrapper>
                    )}
                    {(isFetchingApple || isFetchingGoogle) && (
                        <span className="ms-2">
                            <Spinner />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { addDoc } from 'services/docs';
import { Input, TextArea } from 'components';
import { utils } from '@gpg-web/utils';

const DocCreationForm = (props) => {
    const { edit, setEdit, onSave } = props;
    const [data, setData] = useState({});

    useEffect(() => {
        if (edit) setData(edit);
    }, [edit]);

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    };

    const _addDoc = () => {
        utils.popup('adding');

        addDoc(data)
            .then((doc) => {
                utils.popup('hide');
                utils.hintOk(edit ? 'Saved' : 'Added');
                setData({});
                setEdit(null);

                onSave(doc);
            })
            .catch((err) => {
                utils.popup('hide');
                utils.hintError(err);
            });
    };

    return (
        <div className="card">
            <div className="card-header">Create Doc</div>
            <div className="card-body">
                <Input title="Name" name="name" value={data.name} onChange={handleChange} />
                <TextArea
                    title="Description"
                    name="description"
                    value={data.description}
                    onChange={handleChange}
                />
                <Input title="URL" name="url" value={data.url} onChange={handleChange} />
                <Input title="Type" name="type" value={data.type} onChange={handleChange} />
                <Input title="Component" name="component" value={data.component} onChange={handleChange} />
            </div>
            <div className="card-footer text-end">
                <button onClick={_addDoc} className="btn btn-success">
                    <i className="fas fa-check me-1" /> {edit ? 'Save' : 'Add'}
                </button>
            </div>
        </div>
    );
};

export default DocCreationForm;

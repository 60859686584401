import { useContext } from 'react';
import { organizationContext } from 'services/organization';
import profileIcon from '../../img/undraw_profile.svg';

export function useMember(memberEmail, id) {
    const { members } = useContext(organizationContext);

    // console.log(members);

    if (!memberEmail && !id) return null;

    let member;

    if (id) {
        member = members.find((e) => e.id === id);
    } else {
        member = members.find((e) => e.email === memberEmail);
    }

    if (!member) member = { name: memberEmail, email: memberEmail, url: "/member-not-found" };
    else member.url = "/profile/" + member.id;

    member.picture = member.picture || profileIcon;

    return member;
}

export default useMember;

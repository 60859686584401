const hourMs = 60 * 60 * 1000;
const dayMs = hourMs * 24;
function getRepeatStartDate(now, startOffset, time, skipTurns) {
    now = new Date(now);
    const start = new Date(time.start);
    const repeatEvery = time.repeatEvery;

    if (time.startTime) {
        const splitted = time.startTime.split(':');
        start.setUTCHours(splitted[0], splitted[1]);
    }

    if (startOffset) start.setTime(start.getTime() + startOffset * 60 * 1000);

    /**
     * We skip this check id "skipTurns" is not 0
     * because we know if "skipTurns" >= 1 - it's always next event - always later than "now".
     *
     * We also skip this check is "time.frequency" !== 'week'
     * because this logic included in the week frequency logic (see below)
     */
    if (start > now && skipTurns === 0 && time.frequency !== 'week') {
        /**
         * Set a day for the weekly frequency from the allowed list
         */
        // if (time.frequency === 'week') {
        //     let newDay = -1;
        //     const startDay = start.getUTCDay();
        //     for (let day = 0; day < 7; day++) {
        //         if (time.weekDays[day]) {
        //             if (newDay === -1) newDay = 7 + day;

        //             if (day >= startDay) {
        //                 newDay = day;
        //                 break;
        //             }
        //         }
        //     }

        //     /**
        //      * Update day, only if anything were specified in the allowed list
        //      */
        //     if (~newDay) start.setTime(start.getTime() + (newDay - startDay) * dayMs);
        //     else start.setTime(start.getTime() - repeatEvery * 7 * dayMs);
        // }

        return start;
    }

    /**
     * For the week frequency logic - replace "now" with "start"
     * in case if event planed for the future date and we set to skip some turns
     * we always should have the date for the future events (when "skipTurns" > 0)
     */
    if (start > now && skipTurns > 0) now = new Date(start);

    // if (time.preEvent) now.setTime(now.getTime() + time.preEvent * hourMs);
    // if (time.preEvent) start.setTime(start.getTime() - time.preEvent * hourMs);

    if (time.frequency === 'day') {
        let deltaDays = (now.getTime() - start.getTime()) / dayMs;
        deltaDays = Math.trunc(deltaDays / repeatEvery) * repeatEvery;
        if (skipTurns) deltaDays += repeatEvery * skipTurns;
        start.setTime(start.getTime() + deltaDays * dayMs);
    } else if (time.frequency === 'month') {
        const startMonth = start.getUTCMonth();
        const deltaYear = now.getUTCFullYear() - start.getUTCFullYear();
        let deltaMonths = now.getUTCMonth() - startMonth + deltaYear * 12;
        deltaMonths = Math.trunc(deltaMonths / repeatEvery) * repeatEvery;

        /**
         * Reset date - for the case when "start" set to 31 of any month
         * but "now" is Feb (with 28 days max)
         */
        const originalDate = start.getUTCDate();
        start.setUTCDate(1);
        start.setUTCMonth(startMonth + deltaMonths);
        /**
         * Return date back to normal
         * check if the month was changed
         */
        let months = start.getUTCMonth();
        start.setUTCDate(originalDate);
        if (start.getUTCMonth() !== months) {
            /**
             * Return month to normal
             */
            start.setUTCDate(0);
        }

        /**
         * Test if "now" date is lower than next one
         * Then decrement result month number
         */
        if (time.preEvent) now.setTime(now.getTime() + time.preEvent * hourMs);
        if (now < start) {
            start.setUTCDate(1);
            start.setUTCMonth(-repeatEvery);
            months = start.getUTCMonth();
            start.setUTCDate(originalDate);
            if (start.getUTCMonth() !== months) start.setUTCDate(0);
        }

        if (skipTurns > 0) {
            if (skipTurns) deltaMonths += repeatEvery * skipTurns;

            start.setUTCDate(1);
            start.setUTCMonth(start.getUTCMonth() + skipTurns * repeatEvery);
            months = start.getUTCMonth();
            start.setUTCDate(originalDate);
            if (start.getUTCMonth() !== months) start.setUTCDate(0);
        }
    } else if (time.frequency === 'week') {
        const startDay = start.getUTCDay();
        start.setTime(start.getTime() - startDay * dayMs);
        let deltaWeeks = (now.getTime() - start.getTime()) / (7 * dayMs);
        deltaWeeks = Math.trunc(deltaWeeks / repeatEvery) * repeatEvery;
        start.setTime(start.getTime() + deltaWeeks * 7 * dayMs);

        let correctDay = -1;
        let fixDayForShiftedStart = 0;
        const endOfStartWeek = new Date(start);
        endOfStartWeek.setTime(endOfStartWeek.getTime() + 6 * dayMs);
        endOfStartWeek.setUTCHours(23, 59, 59, 999);
        /**
         * Continue test with shifted "now" (shifted with preEvent)
         */
        if (time.preEvent) now.setTime(now.getTime() + time.preEvent * hourMs);
        let prevWeek = now > endOfStartWeek;

        for (let day = 0; day < 7; day++) {
            const startOffseted = new Date(start);

            if (startOffset) {
                startOffseted.setTime(startOffseted.getTime() - startOffset * 60 * 1000);
                const originalDay = startOffseted.getUTCDay();
                startOffseted.setTime(startOffseted.getTime() + startOffset * 60 * 1000);

                if (originalDay !== 0) {
                    /**
                     * If shifted is 0 and original is 1 - then we should decrement/fix one day
                     * Otherwise if original is 6 (previous day) - then we incerement/fix one day
                     *
                     * Shifted - means "start" shiftted with "startOffset" (input parameter) value.
                     */
                    fixDayForShiftedStart = originalDay === 1 ? -1 : 1;
                }
            }

            startOffseted.setTime(startOffseted.getTime() + day * dayMs);

            if (startOffseted > now) {
                if (correctDay !== -1 && !prevWeek) break;
                else prevWeek = true;
            }
            if (time.weekDays[day]) {
                correctDay = day;
            }
        }

        /**
         * In cases if nothing has been specified in "weekDays"
         * take day of week of "start" date
         * and reset "fixDayForShiftedStart" because "correctDay" is the correct day
         */
        if (correctDay === -1) {
            correctDay = startDay;
            fixDayForShiftedStart = 0;
        }

        start.setTime(start.getTime() + (correctDay + fixDayForShiftedStart) * dayMs);

        /**
         * If the same day of week, but "start" at this stage should be always the current/previous week
         */
        if (start > now) {
            start.setTime(start.getTime() - repeatEvery * 7 * dayMs);
        }

        if (skipTurns) {
            let offsetDays = 0;
            let selectedDays = 0;
            for (let day = 0; day < 7; day++) {
                if (time.weekDays[day]) selectedDays++;
            }

            let shiftedWeeks;
            let omittedTurns;

            if (selectedDays > 0) {
                shiftedWeeks = Math.trunc((skipTurns - 1) / selectedDays);
                omittedTurns = skipTurns - shiftedWeeks * selectedDays;
            } else {
                shiftedWeeks = skipTurns;
                omittedTurns = 0;
            }

            offsetDays += shiftedWeeks * repeatEvery * 7;

            if (omittedTurns > 0) {
                for (let day = correctDay; day < correctDay + 8; day++) {
                    let _day = day >= 7 ? day - 7 : day;
                    if (time.weekDays[_day]) {
                        if (omittedTurns === 0) {
                            if (_day > correctDay) offsetDays += _day - correctDay;
                            else offsetDays += 7 + _day - correctDay;
                            if (day >= 7) {
                                offsetDays += (repeatEvery - 1) * 7;
                            }
                            break;
                        } else {
                            omittedTurns--;
                        }
                    }
                }
            }

            if (offsetDays === 0) offsetDays = 7;

            /**
             * Offset days with calculated value by skipped turns
             */
            if (offsetDays) start.setTime(start.getTime() + offsetDays * dayMs);
        }
    }

    // if (time.preEvent) start.setTime(start.getTime() + time.preEvent * hourMs);

    return start;
}

function getRepeatEndDate(start, endOffset, time) {
    let end = new Date(start);
    end.setTime(end.getTime() + time.duration * hourMs);

    if (time.endType === 'date') {
        const _end = new Date(time.end);
        if (endOffset) _end.setTime(_end.getTime() + endOffset * 60 * 1000);
        if (end > _end) end = _end;
    }

    return end;
}

export default { getRepeatStartDate, getRepeatEndDate };

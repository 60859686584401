import { IAMButton, ProcessBlockedWrapper, useGame, usePageBlocker } from 'components';
import { Link, useNavigate, useParams, Navigate } from 'react-router-dom';
import { useComponent } from '../config/hooks';
import { ComponentsMap } from 'consts';
import Select from 'react-select';
import { useQuery } from '@tanstack/react-query';
import { getEnvironments, getVersions } from 'services/settings';
import { EditTable } from './EditTable';
import { useState, useEffect } from 'react';
import { getConfigsWithContent, updateConfigBatch } from 'services/config';
import { utils } from '@gpg-web/utils';
import Skeleton from 'react-loading-skeleton';

const OVERVIEW_COMPONENTS = ['gameBalance', 'tle', 'tlo', 'popup', 'dailyReward', 'piggyBank', 'translation'];

const FILTER_TYPES = [
    { value: 'version', label: 'Game Version' },
    { value: 'environment', label: 'Environment' }
];

let queueId = 0;

export const Overview = (props) => {
    const navigate = useNavigate();

    const game = useGame();

    const gameId = game.id;

    let breadcrumb = props.breadcrumb ? props.breadcrumb.slice() : [];

    let { componentURL, filterType, '*': filterId } = useParams();
    const component = useComponent();

    const environmentQuery = useQuery({
        queryKey: ['environments', 'overview', gameId],
        queryFn: () => getEnvironments(gameId),
        enabled: !!gameId
    });

    const versionQuery = useQuery({
        queryKey: ['version', 'overview', gameId],
        queryFn: () => getVersions(gameId),
        enabled: !!gameId
    });

    const environments = environmentQuery.data || [];
    const environmentOptions = environments.map((opt) => ({
        value: opt.id,
        label: opt.id
    }));

    const versions = versionQuery.data || [];
    const versionOptions = versions.map((opt) => ({
        value: opt.id,
        label: opt.id
    }));

    const url = '/game/' + gameId + '/config/overview';

    const [configs, setConfigs] = useState(null);

    const [updateInitialData, , isBlocked, initialData, initialDataStr] = usePageBlocker(configs);

    useEffect(() => {
        if (!gameId || !component.url) return;
        setConfigs(null);
        queueId++;
        const _queueId = queueId;
        getConfigsWithContent(gameId, component.url)
            .then((res) => {
                if (_queueId === queueId)
                    setConfigs(updateInitialData(res.filter((c) => c.status !== 'archive')));
            })
            .catch(utils.hintError);
    }, [gameId, component.url, updateInitialData]);

    useEffect(() => {
        setConfigs(updateInitialData(JSON.parse(initialDataStr)));
    }, [filterType, filterId, initialDataStr, updateInitialData]);

    const saveConfigs = () => {
        const updates = {};

        for (let config of configs) {
            const initConfig = initialData.find((c) => c.id === config.id);

            if (JSON.stringify(config.content) !== JSON.stringify(initConfig.content)) {
                updates[config.id] = { content: config.content };
            }
        }

        if (Object.keys(updates).length !== 0) {
            utils.popup('Saving...');

            updateConfigBatch(gameId, component.url, updates)
                .then(({ success, error, data }) => {
                    const newConfigs = initialData.slice();

                    for (let id of success) {
                        const index = newConfigs.findIndex((c) => c.id === id);

                        if (index !== -1) newConfigs[index] = data[id];
                        else newConfigs.push(data[id]);
                    }

                    error.map((id) => utils.hintError(`Cannot save config ${id}`));

                    setConfigs(updateInitialData(newConfigs));

                    success.length !== 0 &&
                        utils.hintOk(
                            'Successfully updated ' + success.length + ' ' + component.name + ' Configs'
                        );

                    utils.popup('hide');
                })
                .catch(utils.hintError);
        } else utils.hint('No changes to save');
    };

    if (gameId) {
        if (!componentURL) {
            const storedComponentURL = localStorage.getItem('overview_component');

            if (storedComponentURL) return <Navigate to={url + '/' + storedComponentURL} replace={true} />;
            else
                return <Navigate to={url + '/' + ComponentsMap[OVERVIEW_COMPONENTS[0]].url} replace={true} />;
        }
        if (!filterType) {
            const storedFilterType = localStorage.getItem('overview_filter_type');

            if (storedFilterType)
                return <Navigate to={url + '/' + componentURL + '/' + storedFilterType} replace={true} />;
            else
                return (
                    <Navigate to={url + '/' + componentURL + '/' + FILTER_TYPES[0].value} replace={true} />
                );
        }

        if (!filterId && environmentQuery.isFetched && versionQuery.isFetched) {
            if (filterType === 'version') {
                const storedFilterVersion = localStorage.getItem('overview_filter_version');
                if (storedFilterVersion && versions.find((e) => e.id === storedFilterVersion))
                    return (
                        <Navigate
                            to={url + '/' + componentURL + '/' + filterType + '/' + storedFilterVersion}
                            replace={true}
                        />
                    );
                else if (versions[0]) {
                    return (
                        <Navigate
                            to={url + '/' + componentURL + '/' + filterType + '/' + versions[0].id}
                            replace={true}
                        />
                    );
                } else {
                    utils.hintError('You need to setup at least one version before using this page');
                    return <Navigate to={'/page-not-found'} replace={true} />;
                }
            } else if (filterType === 'environment') {
                const storedFilterEnvironment = localStorage.getItem('overview_filter_environment');

                if (storedFilterEnvironment && environments.find((e) => e.id === storedFilterEnvironment))
                    return (
                        <Navigate
                            to={url + '/' + componentURL + '/' + filterType + '/' + storedFilterEnvironment}
                            replace={true}
                        />
                    );
                else if (environments[0]) {
                    return (
                        <Navigate
                            to={url + '/' + componentURL + '/' + filterType + '/' + environments[0].id}
                            replace={true}
                        />
                    );
                } else {
                    utils.hintError('You need to setup at least one environment before using this page');
                    return <Navigate to={'/page-not-found'} replace={true} />;
                }
            }
        }

        if (componentURL) {
            if (!component.id) {
                return <Navigate to={'/page-not-found'} replace={true} />;
            }
        }
    }

    breadcrumb.splice(0, 0, {
        name: 'Overview',
        url: 'config/overview'
    });

    breadcrumb.splice(0, 0, {
        name: 'Home',
        url: 'game/' + gameId
    });

    let activeBreadcrumbItem = breadcrumb.pop();

    let path = '';

    breadcrumb.forEach((e, index) => {
        path += '/' + e.url;
        e.fullUrl = path + (e.suffix || '');
    });

    const isReady = !!configs && !!versions && !!environments;

    return (
        <>
            <div style={{ zIndex: 2 }} className="card position-sticky top-0 mb-3 bg-gray-100">
                <div className="card-body d-flex align-items-center">
                    <div>
                        <div className="d-flex align-items-center">
                            <h4>Overview</h4>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-1 small">
                                {breadcrumb.map((item) => (
                                    <li key={item.fullUrl} className="breadcrumb-item">
                                        <Link to={item.fullUrl}>{item.name || item.url}</Link>
                                    </li>
                                ))}

                                {activeBreadcrumbItem && (
                                    <li className="breadcrumb-item active">
                                        {activeBreadcrumbItem.name || activeBreadcrumbItem.url}
                                    </li>
                                )}
                            </ol>
                        </nav>
                    </div>

                    <div className="ms-auto d-flex align-items-center">
                        <Select
                            value={component.id ? { label: component.name, value: component.id } : null}
                            placeholder="Select component"
                            classNamePrefix="select"
                            className="react-select-sm w-200px me-4"
                            isClearable={false}
                            onChange={(e) => {
                                if (e && ComponentsMap[e.value]) {
                                    localStorage.setItem('overview_component', ComponentsMap[e.value].url);

                                    let goto = url + '/' + ComponentsMap[e.value].url;

                                    if (filterType) {
                                        goto += '/' + filterType;
                                    }
                                    if (filterId) {
                                        goto += '/' + filterId;
                                    }

                                    navigate(goto);
                                }
                            }}
                            options={OVERVIEW_COMPONENTS.map((cId) => ({
                                label: ComponentsMap[cId].name,
                                value: ComponentsMap[cId].id
                            }))}
                        />
                        <Select
                            value={FILTER_TYPES.find((opt) => opt.value === filterType) || null}
                            placeholder="Select view type"
                            classNamePrefix="select"
                            className="react-select-sm w-200px me-2"
                            isClearable={false}
                            isDisabled={!isReady}
                            onChange={(e) => {
                                if (e) {
                                    localStorage.setItem('overview_filter_type', e.value);

                                    if (component.url) {
                                        let goto = url + '/' + component.url + '/' + e.value;

                                        if (e.value === 'version') {
                                            const versionId =
                                                localStorage.getItem(['overview_filter_version']) ||
                                                versions[0].id;

                                            goto += '/' + versionId;
                                        } else if (e.value === 'environment') {
                                            const environmentId =
                                                localStorage.getItem(['overview_filter_environment']) ||
                                                environments[0].id;

                                            goto += '/' + environmentId;
                                        }

                                        navigate(goto);
                                    }
                                }
                            }}
                            options={FILTER_TYPES}
                        />
                        {filterType === 'version' ? (
                            <Select
                                value={versionOptions.find((opt) => opt.value === filterId) || null}
                                placeholder="Select game version"
                                classNamePrefix="select"
                                className="react-select-sm w-200px me-4"
                                isLoading={versionQuery.isFetching}
                                isClearable={false}
                                isDisabled={!isReady}
                                onChange={(e) => {
                                    if (e) {
                                        localStorage.setItem('overview_filter_version', e.value);

                                        if (component.url && filterType) {
                                            navigate(
                                                url + '/' + component.url + '/' + filterType + '/' + e.value
                                            );
                                        }
                                    }
                                }}
                                options={versionOptions}
                            />
                        ) : null}
                        {filterType === 'environment' ? (
                            <Select
                                value={environmentOptions.find((opt) => opt.value === filterId) || null}
                                placeholder="Select environment"
                                classNamePrefix="select"
                                className="react-select-sm w-200px me-4"
                                isLoading={environmentOptions.isFetching}
                                isClearable={false}
                                isDisabled={!isReady}
                                onChange={(e) => {
                                    if (e) {
                                        localStorage.setItem('overview_filter_environment', e.value);

                                        if (component.url && filterType) {
                                            navigate(
                                                url + '/' + component.url + '/' + filterType + '/' + e.value
                                            );
                                        }
                                    }
                                }}
                                options={environmentOptions}
                            />
                        ) : null}

                        <IAMButton permissions={['component.config.update']}>
                            <ProcessBlockedWrapper component={component.id}>
                                <button
                                    disabled={!isBlocked}
                                    onClick={saveConfigs}
                                    className="btn btn-success rounded-pill px-4 ms-3 shadow"
                                >
                                    Publish changes
                                </button>
                            </ProcessBlockedWrapper>
                        </IAMButton>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                {isReady ? (
                    configs.length !== 0 ? (
                        <EditTable
                            environments={environments}
                            configs={configs}
                            updateConfigs={setConfigs}
                            versions={versions}
                            view={filterType}
                            selected={filterId}
                            initConfigs={initialData}
                            component={component}
                            gameId={gameId}
                        />
                    ) : (
                        <div className="mt-5 d-flex flex-column">
                            <h6 className="text-muted text-center">There are no {component.name} configs</h6>
                            <Link
                                to={'/game/' + gameId + '/config/' + component.url + '/create'}
                                className="mx-auto btn rounded-pill btn-success shadow px-3 mt-3"
                            >
                                <i className="fas fa-plus fa-sm" /> Add new config
                            </Link>
                        </div>
                    )
                ) : (
                    <div className="mt-4">
                        <Skeleton className="m-2 mx-1 mx-sm-2" width="100%" count={5} height={60} />
                    </div>
                )}
            </div>
        </>
    );
};

import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGame } from '../hooks';
import { TooltipWrapper } from '../tooltips';
import { Spinner } from '@gpg-web/react';
import { getAppleIAP, getGoogleIAP } from 'services/iap';

const stores = {
    google: {
        fn: getGoogleIAP,
        name: 'Play Console'
    },
    apple: {
        fn: getAppleIAP,
        name: 'App Store Connect'
    }
};

export const IAPIdOption = ({ value, onChange, fetchPrices, name, store, title, apiName, readOnly }) => {
    const game = useGame();
    const timer = useRef();
    const [iap, setIAP] = useState(null);
    const [error, setError] = useState(null);
    const gameId = game.id;
    const packageName = game[store] && game[store].packageName;

    useEffect(() => {
        setIAP(null);
        setError(null);

        if (timer.current) clearTimeout(timer.current);

        if (gameId && value && packageName && fetchPrices) {
            timer.current = setTimeout(() => {
                stores[store].fn(gameId, packageName, value).then(setIAP).catch(setError);
            }, 1000);
        }

        return () => {
            clearTimeout(timer.current);
        };
    }, [packageName, gameId, value, store, fetchPrices]);

    return (
        <div className="w-300px position-relative me-3">
            <input
                type="text"
                placeholder="com.game.product.id"
                className="form-control rounded-pill pe-5 border-1 me-1"
                value={value}
                name={name}
                disabled={readOnly}
                onChange={(e) => onChange(name, e.target.value)}
            />

            {fetchPrices && (
                <span
                    className="position-absolute text-gray-600 text-nowrap"
                    style={{ right: '4%', top: '8px' }}
                >
                    {!packageName && (
                        <TooltipWrapper
                            icon="false"
                            content="Package name is not specified. You can specify it on game settings."
                        >
                            <i className="fas fa-question-circle text-dark" />
                        </TooltipWrapper>
                    )}
                    {packageName && !value && (
                        <TooltipWrapper icon="false" content="Please specify a product ID">
                            <i className="fas fa-question-circle text-dark" />
                        </TooltipWrapper>
                    )}
                    {!iap && !error && value && packageName && (
                        <>
                            <Spinner />
                        </>
                    )}
                    {!!iap && (
                        <TooltipWrapper
                            icon="false"
                            content={
                                <>
                                    This information taken directly from {stores[store].name} dashboard as
                                    current price for USA. <div className="my-2">Product ID: {iap.name}</div>
                                </>
                            }
                        >
                            <span className="fw-bold small text-primary-emphasis">{iap.price}</span>
                        </TooltipWrapper>
                    )}
                    {!!error && (
                        <TooltipWrapper icon="false" tooltipClassName="tooltip-danger" content={error}>
                            <i className="fas fa-exclamation-circle text-danger" />
                        </TooltipWrapper>
                    )}
                </span>
            )}
        </div>
    );
};

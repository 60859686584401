import { useRef } from 'react';
import { useFileVersionsMap } from './UseFileVersionsMap';
import { Spinner, StatusLabel, useGame } from 'components';
import { str, utils } from '@gpg-web/utils';

export const EditContentCell = (props) => {
    const { row, cell } = props;
    const value = props.value || {};
    const init = value.init || {};

    const { original } = row;

    const hasValue = !!value.version;

    const { versions, error, isFetching } = useFileVersionsMap(original.path);

    const versionData = versions[value.version] || {};
    const initData = versions[init.version] || {};

    const msg = versionData.message;

    let errorMsg = error;

    if (!errorMsg && !msg) {
        errorMsg = "File version doesn't exist";
    }

    const elementRef = useRef();

    cell.ref = elementRef.current;

    const isNew = !init.version && !!value.version;

    const isDiscarded = !!init.version && !value.version;

    const hasChanges =
        !isDiscarded &&
        !isNew &&
        ((!!init.version && init.version !== value.version) ||
            (!!init.status && init.status !== value.status));

    const { isProduction } = useGame();

    return (
        <div
            className={
                'd-flex h-100' +
                (hasChanges ? ' bg-primary-subtle' : '') +
                (isNew ? ' bg-success-subtle' : '') +
                (isDiscarded ? ' bg-danger-subtle' : '')
            }
        >
            <div
                className={'px-2 position-relative d-flex justify-content-center'}
                ref={elementRef}
                style={{ flex: '1' }}
            >
                {hasValue ? (
                    <>
                        <div className="text-start text-nowrap my-auto w-100">
                            <div className="d-flex mb-1">
                                <div className="me-2">
                                    {!!init.version && init.version !== value.version && (
                                        <span className="small align-middle opacity-3">
                                            <span className="text-decoration-line-through">
                                                {initData.isDraft ? (
                                                    <span>Draft - {init.version.replace('draft.', '')}</span>
                                                ) : (
                                                    <>#{init.version}</>
                                                )}
                                            </span>
                                            <i className="fas fa-long-arrow-alt-right mx-1" />
                                        </span>
                                    )}
                                    {versionData.isDraft ? (
                                        <span
                                            className={utils.className(
                                                isProduction ? 'text-danger' : 'text-primary'
                                            )}
                                        >
                                            <i
                                                className={utils.className(
                                                    'me-1 fa-sm',
                                                    isProduction
                                                        ? 'fas fa-exclamation-triangle'
                                                        : 'fas fa-edit'
                                                )}
                                            />
                                            Draft - {value.version.replace('draft.', '')}
                                        </span>
                                    ) : (
                                        <>#{value.version}</>
                                    )}
                                </div>
                                <div className="small d-flex my-auto ms-auto">
                                    {!!init.status && init.status !== value.status && (
                                        <div className="text-decoration-line-through align-middle d-flex opacity-3">
                                            <StatusLabel value={init.status || 'draft'} />
                                            <i className="fas fa-long-arrow-alt-right mx-1" />
                                        </div>
                                    )}

                                    <StatusLabel value={value.status || 'draft'} />
                                </div>
                            </div>

                            <div
                                className="fw-light small"
                                style={{ maxWidth: '180px' }}
                                title={msg || errorMsg}
                            >
                                {!isFetching ? (
                                    msg ? (
                                        <span className="fst-italic">"{str.trim(msg, 29)}"</span>
                                    ) : (
                                        <span className="text-danger">
                                            <i className="fas fa-exclamation-triangle me-1 fa-sm" />
                                            {str.trim(errorMsg, 29)}
                                        </span>
                                    )
                                ) : (
                                    <>
                                        <Spinner size="10px" />
                                        &nbsp;&nbsp;loading...
                                    </>
                                )}
                            </div>
                        </div>
                        {/* <div className="position-absolute translate-middle top-0 start-75 small d-flex">
                        {!!init.status && init.status !== value.status && (
                            <div className="text-decoration-line-through align-middle d-flex opacity-3">
                                <StatusLabel value={init.status || 'draft'} />
                                <i className="fas fa-long-arrow-alt-right mx-1" />
                            </div>
                        )}
                        <StatusLabel value={value.status || 'draft'} />
                    </div> */}
                    </>
                ) : (
                    <div className="text-start text-nowrap small my-auto 'opacity-1">Select...</div>
                )}
            </div>
        </div>
    );
};
